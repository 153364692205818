import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AppService } from "../../../../app.service";
import { LifestyleService } from "../../../../rest-api/lifestyle.service";
import { RestAPIService } from "../../../../rest-api/rest-api.service";
import { RoofTopPageModel } from "../../../roof-top/roof-top.model";
import { CommunitySolarSettingModel } from "../../energy-choices.model";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "yt-community-solar",
  templateUrl: "./community-solar.component.html",
  styleUrls: ["./community-solar.component.scss"],
})
export class CommunitySolarComponent implements OnInit {
  isEnabled: boolean = false;
  updateMode: boolean;
  private alive = true;
  communitySolarForm: FormGroup;
  roofTopPageModel: RoofTopPageModel;
  roofTopDetails: CommunitySolarSettingModel[];
  userRoofTopDetails: CommunitySolarSettingModel[];
  @Output() saveCommunitySolar = new EventEmitter<void>();
  initialUserDetailsValue: CommunitySolarSettingModel[] = [
    {
      own: "",
    },
  ];

  constructor(
    private restAPIService: RestAPIService,
    private appService: AppService,
    private lifeStyleService: LifestyleService,
    private fb: FormBuilder
  ) {
    this.communitySolarForm = this.fb.group({
      own: [""],
    });
  }

  ngOnInit(): void {
    this.getRoofTopQA();
    this.userRoofTopDetails = this.lifeStyleService.getUserProvidedSolarInfo();
    this.roofTopDetails =
      this.userRoofTopDetails !== undefined
        ? this.userRoofTopDetails
        : this.initialUserDetailsValue;
    this.updateMode = this.userRoofTopDetails !== undefined ? true : false;
    this.communitySolarForm.get("own")?.setValue(this.roofTopDetails[0].own);
  }

  getRoofTopQA() {
    const vendorName = this.appService.getVendorName();
    this.restAPIService
      .getAllRoofTopQA(vendorName)
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => {
        this.roofTopPageModel = data;
      });
  }

  onBuldingTypeQuestion(own: string) {
    this.isEnabled = true;
  }

  onSaveCommunitySolar(): void {
    this.saveCommunitySolar.emit();
  }
}
