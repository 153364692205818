import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BatteryDataModel } from "../../../../model/userdata.model";
import { DashboardAPIService } from "../../../../../../rest-api/dashboard/dashboard-api.service";
import { RadioOptionsModel } from "../../../../../../rest-api/dashboard/dashboard-api.model";
import { MatRadioChange } from "@angular/material/radio";
import { DropDownModel } from "../../../../../../rest-api/rest-api.model";
import { LearnMoreService } from "../../../../../learn-more/learn-more.service";
import { SettingsDialogData } from "../../setting-overlay.component.model";
import { SettingsOverlayService } from "../../settings-overlay.service";
import { Subject } from "rxjs";
import { DataModel } from "src/app/homeowner-dashboard/overview/model/dashboard.model";
import { OverviewService } from "src/app/homeowner-dashboard/overview/overview.service";

@Component({
  selector: "yt-battery",
  templateUrl: "battery.component.html",
  styleUrls: ["../../common-tech.scss"],
})
export class BatteryComponent implements OnInit {
  panelOpenState: boolean = false;
  batteryForm: FormGroup;
  disableDatePicker: boolean;
  batteryDataModel: BatteryDataModel[];
  buildingType: string;
  batteryInfoModel: DataModel;
  header: string;
  isPortable: boolean;
  ownValue: string;
  selectedRadioButton: string;
  financeList: DropDownModel[];
  radioButtonOptions: RadioOptionsModel[];
  hideFormField: boolean;
  batterySettings: BatteryDataModel[];
  backupHourLabel: string;
  backupHourToolTip: string;
  isLoadShift: boolean;
  subject: Subject<any> = new Subject();
  @Input() batteryDetails;
  @Input() activeIcon: string;
  @Output() techSave: EventEmitter<SettingsDialogData> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private dashboardAPIService: DashboardAPIService,
    private overviewService: OverviewService,
    private learnMoreService: LearnMoreService,
    private settingService: SettingsOverlayService
  ) {
    this.financeList = this.learnMoreService.financeList.filter(
      (finance) => finance.value !== "lease"
    );
    this.batteryForm = this.fb.group({
      own: ["", Validators.required],
      hrs: [
        "10",
        [
          Validators.required,
          Validators.pattern(/^(?:[1-9]|[1-2][0-9]|3[0-5])$/),
          Validators.min(1),
          Validators.max(35),
        ],
      ],
      finance: ["cash", Validators.required],
    });
  }

  ngOnInit() {
    this.getBatteryInfo();
    this.dashboardAPIService.getRadioButtonDetails().subscribe((data) => {
      this.radioButtonOptions = data.radioButtons;
    });
    this.batteryDataModel = this.settingService.checkSettingsInfoError(
      "battery"
    )
      ? this.settingService.settingsInfoErrors.solarBattery?.battery!
      : this.settingService.checkSettingsInfo("battery")
      ? this.settingService.settingsInfo.solarBattery?.battery!
      : this.dashboardAPIService.energyChoicesData.solarBattery.battery!;

    this.batteryDataModel.forEach((battery: BatteryDataModel) => {
      this.ownValue = battery.own!;
      this.isLoadShift =
        this.dashboardAPIService.energyChoicesData.solarBattery.battery![0].loadShiftStatus!;
      this.backupHourLabel = "Backup hours";
      this.backupHourToolTip =
        "Hours of backup you want from a fully charged battery. This assumes no recharge from rooftop solar and that usage during an outage is your average hourly usage throughout the year. This value will determine the estimated capacity and cost of the battery system.";
      this.setSelectedRadioValues(this.ownValue);
      this.batteryForm.patchValue(battery);
      if (this.ownValue === "yes") {
        this.hideFormField = false;
      } else if (this.ownValue === "yesPlan") {
        this.hideFormField = true;
        this.disableDatePicker = true;
        this.batteryForm.get("hrs")?.enable();
        this.batteryForm.get("finance")?.enable();
        this.batteryForm.get("finance")?.setValue(battery.financial);
      } else if (this.ownValue === "noPlan") {
        this.hideFormField = true;
        this.disableDatePicker = true;
        this.batteryForm.get("hrs")?.enable();
        this.batteryForm.get("finance")?.enable();
        this.batteryForm.get("finance")?.setValue(battery.financial);
      }
    });
    if (this.activeIcon) {
      if (this.activeIcon === "favorite") {
        this.batteryForm.get("own")?.setValue("yesPlan");
      } else if (this.activeIcon === "alreadyHave") {
        this.batteryForm.get("own")?.setValue("yes");
      }
      this.onValueChanges();
      this.setSelectedRadioValues(this.batteryForm.get("own")?.value);
      this.condition();
    } else {
      this.onValueChanges();
    }
  }

  onRadioChange(event: MatRadioChange) {
    this.setSelectedRadioValues(event.value);
  }

  setSelectedRadioValues(value: string): void {
    this.selectedRadioButton = value;
  }

  getBatteryInfo(): void {
    this.batteryInfoModel = this.overviewService.techInfo.battery;
    this.buildingType = this.dashboardAPIService.buildType;
    this.isPortable = false;
    if (this.overviewService.updateEnergyChoiceInfo(this.buildingType)) {
      this.isPortable = true;
    }
    this.header = this.batteryInfoModel.title;
  }

  condition() {
    if (this.batteryForm.get("own")?.value === "yes") {
      this.hideFormField = false;
    } else if (this.batteryForm.get("own")?.value === "yesPlan") {
      this.hideFormField = true;
      this.batteryForm.enable();
      this.batteryForm.get("hrs")?.enable();
      this.batteryForm.get("hrs")?.enable();
      this.batteryForm.get("finance")?.enable();
    } else if (this.batteryForm.get("own")?.value === "noPlan") {
      this.hideFormField = true;
      this.batteryForm.get("hrs")?.enable();
      this.batteryForm.get("hrs")?.enable();
      this.batteryForm.get("finance")?.enable();
    }
  }

  onValueChanges(): void {
    this.batteryForm.valueChanges.subscribe((data) => {
      this.subject.pipe().subscribe(() => {
        if (data.own === "yes") {
          this.batterySettings = [
            {
              own: data.own,
            },
          ];
        } else if (data.own === "yesPlan" || data.own === "noPlan") {
          this.batterySettings = [
            {
              own: data.own,
              hrs: this.batteryForm.get("hrs")?.value || data.hrs,
              financial: data.finance,
            },
          ];
        }
        if (this.batteryForm.valid) {
          this.settingService.updateSettingsInfo(
            this.dashboardAPIService.energyChoicesData.solarBattery,
            this.batterySettings,
            "solarBattery",
            "battery"
          );
        } else {
          this.settingService.updateSettingsInfoErrors(
            this.dashboardAPIService.energyChoicesData.solarBattery,
            this.batterySettings,
            "solarBattery",
            "battery"
          );
        }
      });
      this.subject.next();
    });
  }

  onSaveBATTERY() {
    this.settingService.putSettingsInfo(this.getSettingsInfo);
  }

  getSettingsInfo = () => {
    if (this.activeIcon) {
      this.techSave.emit({
        own: this.batteryForm.get("own")?.value,
        tech: "battery",
      });
    }
  };
}
