import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { TechNamePipeModule } from "../../utils/tech.pipe";
import { FAQOverlayComponent } from "./faq-overlay.component";
import { BatteryFAQComponent } from "./battery-faq/battery-faq.component";
import { PortableBatteryFAQComponent } from "./portable-battery/portable-battery-faq.component";
import { SolarFAQComponent } from "./solar-faq/solar-faq.component";
import { EvFAQComponent } from "./ev-faq/ev-faq.component";
import { EbikeFAQComponent } from "./ebike-faq/ebike-faq.component";
import { HpdFAQComponent } from "./hpd-faq/hpd-faq.component";
import { HpscFAQComponent } from "./hpsc-faq/hpsc-faq.component";
import { HpwhFAQComponent } from "./hpwh-faq/hpwh-faq.component";
import { IcFAQComponent } from "./ic-faq/ic-faq.component";
import { LedBulbsFAQComponent } from "./led-bulbs-faq/led-bulbs-faq.component";
import { ShowerHeadsAeratorsFAQComponent } from "./shower-heads-aerators-faq/shower-heads-aerators-faq.component";
import { SmartPowerStripFAQComponent } from "./smart-power-strip-faq/smart-power-strip-faq.component";
import { WeatherStripsFAQComponent } from "./weather-strips-faq/weather-strips-faq.component";
import { SmartThermostatFAQComponent } from "./smart-thermostat-faq/smart-thermostat-faq.component";
import { EvChargersFaqComponent } from "./ev-chargers-faq/ev-chargers-faq.component";
import { CommunitySolarFaqComponent } from "./community-solar-faq/community-solar-faq.component";
import { EvergreenFaqComponent } from "./evergreen-faq/evergreen-faq.component";
import { PortableICFaqComponent } from "./portable-ic-faq/portable-ic-faq.component";

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatDialogModule,
    MatIconModule,
    TechNamePipeModule,
  ],
  declarations: [
    FAQOverlayComponent,
    BatteryFAQComponent,
    PortableBatteryFAQComponent,
    SolarFAQComponent,
    EvFAQComponent,
    EbikeFAQComponent,
    HpdFAQComponent,
    HpscFAQComponent,
    HpwhFAQComponent,
    IcFAQComponent,
    LedBulbsFAQComponent,
    ShowerHeadsAeratorsFAQComponent,
    SmartPowerStripFAQComponent,
    WeatherStripsFAQComponent,
    SmartThermostatFAQComponent,
    EvChargersFaqComponent,
    CommunitySolarFaqComponent,
    EvergreenFaqComponent,
    PortableICFaqComponent,
  ],
  exports: [
    FAQOverlayComponent,
    BatteryFAQComponent,
    PortableBatteryFAQComponent,
    SolarFAQComponent,
    EvFAQComponent,
    EbikeFAQComponent,
    HpdFAQComponent,
    HpscFAQComponent,
    HpwhFAQComponent,
    IcFAQComponent,
    LedBulbsFAQComponent,
    ShowerHeadsAeratorsFAQComponent,
    SmartPowerStripFAQComponent,
    WeatherStripsFAQComponent,
    SmartThermostatFAQComponent,
    EvChargersFaqComponent,
    CommunitySolarFaqComponent,
    EvergreenFaqComponent,
    PortableICFaqComponent,
  ],
})
export class FAQOverlayModule {}
