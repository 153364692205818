<div>
  <div class="tech__summary">
    <div class="heading">Electric Bike</div>
    <div
      class="paragraph"
      [innerHTML]="'homeOwenerDashboard.learnMore.eBike.paragraph' | translate"
    ></div>
  </div>
</div>

<form [formGroup]="eBikeForm" class="form__container">
  <div class="form__wrapper">
    <div>
      <mat-radio-group
        class="radioButton-flex"
        formControlName="own"
        (change)="condition()"
      >
        <div class="cards" *ngFor="let radioOptions of radioButtonOptions">
          <label>
            <yt-radio-buttons
              [viewValue]="radioOptions.viewValue"
              [icon]="radioOptions.icon"
              [default]="selectedRadioButton"
              [value]="radioOptions.value"
            ></yt-radio-buttons>
            <mat-radio-button
              class="hidden_button"
              [value]="radioOptions.value"
              (change)="onRadioChange($event)"
            >
            </mat-radio-button>
          </label>
        </div>
      </mat-radio-group>
      <div class="form__field-wrapper">
        <section class="form__field-section">
          <div class="lables">
            Planning to use your electric bike instead of the car?
            <span class="gray-text"
              >If yes, provide displaced vehicle miles per week</span
            >
            <mat-icon
              class="pointer yt_pl4 h-16"
              matTooltip="This is the number of miles ridden per week on an electric bike that would have been driven 
                    by a car instead (commuting, running errands, etc.)."
            >
              help_outline
            </mat-icon>
          </div>
          <mat-form-field
            hideRequiredMarker="true"
            floatLabel="auto"
            appearance="outline"
          >
            <input
              matInput
              type="text"
              required
              class="quantity"
              placeholder="Enter 0-75"
              formControlName="displacedMiles"
              maxlength="3"
            />
            <mat-error
              *ngIf="
                eBikeForm.controls['displacedMiles'].hasError('pattern') ||
                eBikeForm.controls['displacedMiles'].hasError('min') ||
                eBikeForm.controls['displacedMiles'].hasError('max')
              "
            >
              Please enter value between 0-75
            </mat-error>
            <mat-error
              *ngIf="eBikeForm.controls['displacedMiles'].hasError('required')"
            >
              Required!
            </mat-error>
          </mat-form-field>
        </section>

        <section class="form__field-section" *ngIf="showDatePicker">
          <div class="field__label">Purchased month & year</div>
          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker="true"
            appearance="outline"
          >
            <mat-select
              placeholder="Select"
              formControlName="datePurchased"
              required
            >
              <mat-option *ngFor="let month of monthList" [value]="month"
                >{{ month | titlecase }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            *ngIf="
              (isDatePurchasedEmpty() &&
                eBikeForm.controls['datePurchased'].touched) ||
              haveInputError
            "
          >
            Required!
          </mat-error>
        </section>
      </div>
    </div>
  </div>

  <div class="action__wrapper">
    <button
      mat-raised-button
      color="accent"
      (click)="onSaveEBikeSettings()"
      [disabled]="eBikeForm.invalid || isDatePurchasedEmpty()"
      class="primary__button"
    >
      SAVE
    </button>
  </div>

  <div class="note__message">
    NOTE: The above fields have been pre-filled with default values.
  </div>
</form>
