import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LifestyleService } from "../../../rest-api/lifestyle.service";
import { Utils } from "../../../utils/util";
import { EBikeSettingModel } from "../energy-choices.model";

@Component({
  selector: "yt-e-bike",
  templateUrl: "e-bike.component.html",
  styleUrls: ["../energy-choices-common.scss"],
})
export class EBikeComponent implements OnInit {
  monthList: string[];
  eBikeForm: FormGroup;
  showDatePurchased: boolean;
  panelOpenState: boolean = false;
  eBikeData: EBikeSettingModel[];
  updateMode: boolean;
  lastTwelthMonth: string;
  @Output() saveEBike = new EventEmitter<void>();

  constructor(
    private fb: FormBuilder,
    private lifeStyleService: LifestyleService,
    private utils: Utils
  ) {
    this.eBikeForm = this.fb.group({
      own: ["yesPlan", Validators.required],
      displacedMiles: [
        "5",
        [
          Validators.required,
          Validators.pattern("^([0-9]|[0-7][0-9])$"),
          Validators.min(0),
          Validators.max(75),
        ],
      ],
      bought: [""],
      datePurchased: ["", Validators.required],
    });
  }

  ngOnInit() {
    this.monthList = this.utils.monthList;
    this.lastTwelthMonth = this.utils.lastTwelthMonth;
    if (this.lifeStyleService.getEBikeInfo() !== undefined) {
      this.eBikeData = this.lifeStyleService.getEBikeInfo();
      this.checkInUpdateView(this.eBikeData[0]);
    }
    if (this.eBikeForm.get("own")?.value !== "yes") {
      this.eBikeForm.get("datePurchased")?.disable();
    }
  }

  isDatePurchasedEmpty() {
    if (this.eBikeForm.get("own")?.value === "yes") {
      if (!this.eBikeForm.get("datePurchased")?.value) {
        return true;
      }
    }
    return false;
  }

  condition() {
    if (this.eBikeForm.get("own")?.value === "yes") {
      this.eBikeForm.enable();
      this.showDatePurchased = true;
    } else if (this.eBikeForm.get("own")?.value === "yesPlan") {
      this.eBikeForm.get("bought")?.disable();
      this.eBikeForm.get("displacedMiles")?.enable();
      this.eBikeForm.get("datePurchased")?.disable();
      this.showDatePurchased = false;
    } else if (this.eBikeForm.get("own")?.value === "no") {
      this.eBikeForm.disable();
      this.eBikeForm.get("own")?.enable();
      this.showDatePurchased = false;
    }
  }

  checkInUpdateView(data) {
    this.updateMode = true;
    this.eBikeForm.controls["own"].setValue(data.own);
    if (data.displacedMiles) {
      this.eBikeForm.controls["displacedMiles"].setValue(data.displacedMiles);
    } else {
      this.eBikeForm.get("displacedMiles")?.disable();
    }
    if (data.bought) {
      this.eBikeForm.controls["bought"].setValue(data.bought);
      this.eBikeForm.controls["datePurchased"].setValue(data.datePurchased);
    } else {
      if (data.own === "yes") {
        this.eBikeForm.controls["bought"].setValue(false);
        this.eBikeForm.controls["datePurchased"].setValue(this.lastTwelthMonth);
      } else {
        this.eBikeForm.get("bought")?.disable();
        this.eBikeForm.get("datePurchased")?.disable();
      }
    }
    this.showDatePurchased = data.own === "yes";
  }

  onSaveEBike() {
    const eBikeInfo: {
      energyChoices: {
        eBike: {
          own: string;
          displacedMiles?: string;
          bought?: string;
          datePurchased?: string;
        }[];
      };
    } = {
      energyChoices: {
        eBike: [],
      },
    };
    if (this.eBikeForm.get("own")?.value === "yes") {
      const datePurchased = this.eBikeForm.get("datePurchased")?.value;
      const bought = this.utils.isDatePurchaseSelected(datePurchased);
      this.eBikeForm.get("bought")?.setValue(bought);
      eBikeInfo.energyChoices.eBike[0] = {
        own: this.eBikeForm.get("own")?.value,
        displacedMiles: this.eBikeForm.get("displacedMiles")?.value,
        bought: this.eBikeForm.get("bought")?.value,
        datePurchased: !this.eBikeForm.get("bought")?.value
          ? null
          : this.eBikeForm.get("datePurchased")?.value,
      };
    } else if (this.eBikeForm.get("own")?.value === "yesPlan") {
      eBikeInfo.energyChoices.eBike[0] = {
        own: this.eBikeForm.get("own")?.value,
        displacedMiles: this.eBikeForm.get("displacedMiles")?.value,
      };
    } else if (this.eBikeForm.get("own")?.value === "no") {
      eBikeInfo.energyChoices.eBike[0] = {
        own: this.eBikeForm.get("own")?.value,
      };
    }
    this.lifeStyleService
      .onSaveLifestyleInfo(eBikeInfo)
      .subscribe((response) => {
        if (response.status === 200) {
          this.lifeStyleService.postEBikeInfo(eBikeInfo);
          this.saveEBike.emit();
        }
      });
  }
}
