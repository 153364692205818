<scroll-top></scroll-top>
<div class="page-container yt__my_info">
  <div class="flex padding-93-class fl-direction">
    <div class="flex formSection yt_maxWidth center">
      <form [formGroup]="userDetailsForm">
        <div class="heading_text">My Information</div>
        <div class="subheading_text">
          Please update your information so your profile is up to date.
        </div>
        <div class="flex-ns justify-between name_wrapper">
          <div class="form_field_padding2">
            <mat-form-field
              class="w-100"
              hideRequiredMarker="true"
              floatLabel="auto"
              appearance="outline"
            >
              <mat-label>First Name</mat-label>
              <input
                matInput
                formControlName="firstName"
                autocomplete="off"
                type="text"
              />
              <mat-error *ngIf="userDetailsFormValidation.firstName">
                {{ userDetailsFormValidation.firstName }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form_field_padding2">
            <mat-form-field
              class="w-100"
              hideRequiredMarker="true"
              floatLabel="auto"
              appearance="outline"
            >
              <mat-label>Last Name</mat-label>
              <input
                matInput
                formControlName="lastName"
                autocomplete="off"
                type="text"
              />
              <mat-error *ngIf="userDetailsFormValidation.lastName">
                {{ userDetailsFormValidation.lastName }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="form_field_padding">
          <mat-form-field
            class="w-100 disable-field"
            hideRequiredMarker="true"
            floatLabel="auto"
            appearance="outline"
          >
            <mat-label>Email</mat-label>
            <input
              matInput
              readonly
              formControlName="email"
              autocomplete="off"
              type="text"
            />
          </mat-form-field>
        </div>
        <div class="form_field_padding">
          <mat-form-field
            class="w-100 disable-field"
            hideRequiredMarker="true"
            floatLabel="auto"
            appearance="outline"
          >
            <mat-label>{{ this.formLabel }}</mat-label>
            <input
              matInput
              readonly
              formControlName="address"
              autocomplete="off"
              type="text"
            />
          </mat-form-field>
        </div>
        <div class="form_field_padding1">
          <mat-form-field
            class="w-100 disable-field"
            hideRequiredMarker="true"
            floatLabel="auto"
            appearance="outline"
          >
            <mat-label>Select Building Type and Ownership</mat-label>
            <input
              matInput
              readonly
              [value]="buildingType"
              formControlName="buildingType"
              autocomplete="off"
              type="text"
            />
          </mat-form-field>
        </div>
        <div class="form_field_padding" *ngIf="onSolutionType">
          <mat-form-field
            class="w-100"
            hideRequiredMarker="true"
            floatLabel="auto"
            appearance="outline"
          >
            <mat-label>Phone number {{ mobilePlaceHolder }}</mat-label>
            <input
              matInput
              formControlName="contactNumber"
              type="text"
              maxlength="12"
              #mobileNumber
              (input)="formatMobileNumber(mobileNumber.value)"
              [required]="mobileNumberMandatory"
            />
            <mat-error
              *ngIf="
                userDetailsForm.controls['contactNumber'].hasError(
                  'required'
                ) && userDetailsForm.controls['contactNumber'].touched
              "
            >
              Required!
            </mat-error>
            <mat-error
              *ngIf="
                userDetailsForm.controls['contactNumber'].hasError(
                  'minlength'
                ) &&
                !userDetailsForm.controls['contactNumber'].hasError('required')
              "
            >
              Invalid phone number!
            </mat-error>
          </mat-form-field>
        </div>
        <div class="w-100 save_button">
          <button
            mat-raised-button
            [disabled]="userDetailsForm.invalid"
            color="accent"
            (click)="onContinue()"
          >
            SAVE
          </button>
        </div>
      </form>
    </div>
    <div class="support_text">
      Need to update the building type,
      {{ infoText }} or email address? Please contact
      <a
        class="link_text no-underline pointer"
        href="mailto:support@yellowtin.com"
        >support@yellowtin.com</a
      >
      with your first name, last name and email address.
    </div>
  </div>
</div>
