import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DashboardAPIService } from "../../../../../../../rest-api/dashboard/dashboard-api.service";
import { SettingsOverlayService } from "../../../settings-overlay.service";

@Component({
  selector: "yt-manual-estimate",
  templateUrl: "./manual-estimate.component.html",
  styleUrls: ["./manual-estimate.component.scss"],
})
export class ManualEstimateComponent implements OnInit {
  estimateForm: FormGroup;
  @Input() revokedAuth: boolean;
  @Input() smdStatus: boolean;
  @Output() averageBill: EventEmitter<string> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private dashboardAPI: DashboardAPIService,
    private settingService: SettingsOverlayService
  ) {
    this.estimateForm = this.formBuilder.group({
      sliderValue: [],
    });
  }

  ngOnInit(): void {
    let avgMonthlyBill;
    if (this.settingService.checkSettingsInfoError("general")) {
      avgMonthlyBill =
        this.settingService.settingsInfoErrors.general?.energyInfo
          .avgMonthlyBill;
    } else if (this.settingService.checkSettingsInfo("general")) {
      avgMonthlyBill =
        this.settingService.settingsInfo.general?.energyInfo.avgMonthlyBill;
    } else {
      avgMonthlyBill =
        this.dashboardAPI.energyChoicesData.general.energyInfo.avgMonthlyBill;
    }
    this.estimateForm.get("sliderValue")?.setValue(avgMonthlyBill);
  }

  onSliderChange(event): void {
    this.averageBill.emit(event.target.value);
  }
}
