import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { ConfirmPopupComponent } from "./confirm-popup.component";

@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule],
  exports: [ConfirmPopupComponent],
  declarations: [ConfirmPopupComponent],
})
export class ConfirmPopupModule {}
