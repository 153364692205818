import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { DashboardAPIService } from "../../../../../rest-api/dashboard/dashboard-api.service";
import { TechDetailsModel } from "../../../model/dashboard.init.model";

@Component({
  selector: "yt-already-tech-card",
  templateUrl: "./already-tech-card.component.html",
  styleUrls: ["./already-tech-card.component.scss"],
})
export class AlreadyTechCardComponent implements OnInit {
  removeAlreadyHaveTech: string;
  @Input() techList: TechDetailsModel[];
  @Input() everGreenStatus: boolean;
  @Output() removeTech: EventEmitter<string> = new EventEmitter();
  @Output() removeEverGreen: EventEmitter<void> = new EventEmitter();

  constructor(private dashboardAPI: DashboardAPIService) {}

  ngOnInit(): void {
    this.dashboardAPI.removeAlreadyHave.subscribe((response) => {
      this.removeAlreadyHaveTech = response;
    });
  }

  onRemoveTech(tech: TechDetailsModel) {
    this.dashboardAPI.removeAlreadyHave.next(tech.techParameter);
    this.removeTech.emit(tech.techParameter);
  }

  onRemoveEverGreen() {
    this.everGreenStatus = false;
    this.dashboardAPI.haveEverGreen = false;
    this.removeEverGreen.emit();
  }

  checkTechListLength(): boolean {
    return this.techList.length === 0 && !this.everGreenStatus;
  }
}
