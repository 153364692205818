<div class="faq_container">
  <div class="about_technology" *ngIf="showAboutTech">
    <div class="header">Overview</div>
    <p>
      <strong>Is it New?:</strong> Heat pumps have been used for heating and
      cooling for decades in some areas. However, with improved technology (such
      as low-temperature heat pumps), they’re becoming more popular than ever
      and expanding into new regions.
      <span
        class="link_text"
        *ngIf="showReadMore"
        (click)="onReadContent('readMore')"
      >
        Read more...
      </span>
    </p>

    <div *ngIf="showReadLess">
      <p class="pt3">
        <strong>How it Works:</strong> Heat pumps use electricity to move heat
        from one place to another, instead of creating the heat directly, as in
        a gas furnace or boiler. This is what makes them so efficient when
        compared to conventional heating systems.
      </p>

      <p class="pt3">
        <strong>Applicable Types of Heat Pumps:</strong> Various types of heat
        pumps are available to match a home’s particular needs. For example,
        air-to-air heat pumps (which can also provide cooling) can be used in
        many types of homes, including those with forced-air ventilation
        systems. Air-to-water heat pumps are more compatible with homes
        containing hydronic heating systems that employ radiators or underfloor
        heating.
      </p>

      <p class="pt3">
        <strong>Economics:</strong> Heat pumps are generally more expensive to
        install than a conventional space conditioning system, but incentives
        are sometimes available that can help with this higher cost. Also, you
        can sometimes save money on your energy bills going forward, especially
        if you’re switching from heating with electricity, propane/LPG, or oil.
        If you combine a heat pump with rooftop solar, the economics can improve
        even more.
      </p>

      <p class="pt3">
        <strong>Environment:</strong> Heat pumps almost always help to reduce
        your greenhouse gas emissions. This is because they use electricity, and
        in a very efficient way. Also, as grid-supplied electricity becomes
        cleaner over time, heat pumps will have an increasing environmental
        advantage over conventional heating systems.
        <span class="link_text" (click)="onReadContent('readLess')">
          Read less
        </span>
      </p>
    </div>
  </div>
  <div *ngIf="!showAboutTech">
    <div *ngIf="isActive(0)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. What is a heat pump?</h5>
        <p class="answer_text">
          A heat pump, such as a common refrigerator, is a device that moves
          heat from one place to another but does not generate any heat of its
          own. Usually the flow of heat follows the temperature gradient from
          higher to lower temperature. However, a heat pump uses a compressor,
          heat exchangers, and other components to evaporate and condense a
          refrigerant at selective places to move the heat against a temperature
          gradient, much like a water pump moves water against the force of
          gravity. So it is called a heat pump.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. What are some everyday uses of heat pumps?
        </h5>
        <p class="answer_text">
          A refrigerator uses a small heat pump to move heat from the inside of
          the refrigerator to the surroundings. Thus the food inside the
          refrigerator stays cool. An air conditioner (AC), which moves heat
          from inside a house to the outside against a temperature gradient, is
          another example. Similarly, an air source heat pump (ASHP) moves heat
          from inside to outside during the summer, functioning as an AC. In the
          winter, it has a mechanism to reverse the flow of heat and move heat
          from the cold outside to the warm inside, acting as a space heater.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">3. What are the major components?</h5>
        <p class="answer_text">
          A system has various components, including a compressor, outside and
          indoor heat exchangers, refrigerant (with associated piping), valves,
          and controls/wiring.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          4. What types of air source heat pumps are available?
        </h5>
        <p class="answer_text">
          The most common forms of air source heat pumps are: 1. “split” systems
          that use air ducts inside the house and a central heat exchanger to
          condition the air flowing through the ducts, and 2. ductless
          “mini-split” systems that use one or more registers inside the house
          to condition the space directly without using air ducts. There are
          also other variations, such as ducted mini-splits, that can sometimes
          offer advantages.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          5. How does an air source heat pump differ from a gas furnace?
        </h5>
        <p class="answer_text">
          First, a heat pump provides not only space heating, but also cooling.
          By reversing the thermodynamic cycle, this one device can help keep
          you comfortable in both the winter and summer. A heat pump is also
          more efficient because it’s designed to move heat, not create it.
          Operationally, a heat pump takes longer to heat a home from a low
          temperature than does a natural gas furnace. Accordingly, they’re
          generally used in a way that keeps a home at a more even temperature
          throughout the day.
        </p>
        <p class="pt3 answer_text">
          When it comes to carbon pollution, air source heat pumps have an
          advantage over other types of space heaters because of their greater
          efficiency. The amount of the carbon reduction depends on the carbon
          intensity of the electricity used to power your heat pump and the
          energy source (such as natural gas, electricity, etc.) you’re
          switching from. If you add rooftop solar at the same time, the carbon
          footprint of running a heat pump is drastically reduced.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          6. Can you set back the temperature when heating with an air source
          heat pump?
        </h5>
        <p class="answer_text">
          It is advisable to use small setbacks with a heat pump since the
          heating process takes longer than with other forms of heating, such as
          a natural gas furnace. By maintaining a relatively uniform
          temperature, the temperature recovery time is reduced.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          7. Are mini-split systems more efficient than split systems?
        </h5>
        <p class="answer_text">
          In general, ductless mini-split systems are more efficient than split
          systems. This is partly because they avoid the energy losses
          associated with ducting, which can be substantial in some cases. Also,
          the temperature setting of each register inside the house can be
          controlled separately, so the heating or cooling of the house can be
          more easily fine-tuned, thereby saving energy. The efficiency of a
          ducted mini-split system generally falls in between a split system and
          a ductless mini-split.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(1)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How expensive are heat pumps?</h5>
        <p class="answer_text">
          The installed costs of air source heat pumps depend on a variety of
          factors, such as the type of heat pump, home size, location, need for
          additional wiring, etc. While they are typically more expensive than
          conventional heating or cooling systems, an advantage is that you only
          need one heat pump to cover both heating and cooling.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. Will I save money compared to a gas furnace for heating?
        </h5>
        <p class="answer_text">
          This depends on the prices of natural gas and electricity in your
          area, your typical winter temperatures, etc. In many cases (especially
          in areas with high electricity prices), despite their high efficiency,
          a heat pump system can cost more to operate.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(2)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. What kind of regular maintenance is needed?
        </h5>
        <p class="answer_text">
          The maintenance needed is similar to an AC. It consists of cleaning
          the coils from time to time, changing the air filters in ducts or
          registers, etc.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. If the heat pump cools and heats year round, does it wear out more
          quickly than an air conditioner?
        </h5>
        <p class="answer_text">
          Covering both winter and summer means that a heat pump will log more
          operating hours than a traditional AC system. Accordingly, it would
          normally have a shorter expected lifespan.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(3)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. Do heat pumps reduce greenhouse gases?</h5>
        <p class="answer_text">
          Air source heat pumps almost always help to reduce GHG emissions. This
          is because they use electricity, and in a very efficient way. Also, as
          grid-supplied electricity becomes cleaner over time, heat pumps will
          have an increasing environmental advantage over conventional heating
          systems.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(4)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How is a heat pump installed?</h5>
        <p class="answer_text">
          This will depend on the type of heat pump being installed. A central
          (split-system) heat pump’s installation is generally similar to that
          of a central AC system. A ductless mini-split will require the
          additional step of mounting one or more indoor registers inside your
          home. A ducted mini-split will involve the installation of some
          additional ducting. Depending on the complexity of the system,
          projects typically take 1 day to several days.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. How many indoor registers are needed with a ductless mini-split
          system?
        </h5>
        <p class="answer_text">
          In answering this question, a number of factors come into play such as
          house construction/orientation, number of rooms, and specific
          heating/cooling needs associated with each room. In some cases, a
          register will be needed in each room, but this is not always the case.
          Multiple registers (as many as eight) can be linked to an outside
          unit, and in larger homes, two outside units are sometimes employed.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(5)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. What types of incentives are available?</h5>
        <p class="answer_text">
          Many utilities around the country offer rebates for air source heat
          pumps. In addition, state- and county-level incentives are also
          sometimes available.
        </p>
      </div>
    </div>
  </div>
</div>
