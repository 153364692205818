import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MediaMatcher, BreakpointObserver } from "@angular/cdk/layout";
import { HomeOwnerProductComponent } from "./homeowner-product.component";
import { MyInfoComponent } from "./my-info/my-info.component";
import { ErrorDialogComponent } from "./my-info/error-dialog/error-dialog.component";
import { EnergyChangesComponent } from "./energy-changes/energy-changes.component";
import { ThankYouComponent } from "./thank-you/thank-you.component";
import { SideNavComponent } from "./shared/side-nav/side-nav.component";
import { LayoutComponent } from "./shared/layout/layout.component";
import { EnergyChoicesComponent } from "./energy-choices/energy-choices.component";
import { LifeStyleOverlayComponent } from "./energy-choices/lifestyle-overlay/lifestyle-overlay.component";
import { EVComponent } from "./energy-choices/ev/ev.component";
import { ACComponent } from "./energy-choices/ac/ac.component";
import { BatteryComponent } from "./energy-choices/battery/battery.component";
import { HPWHComponent } from "./energy-choices/hpwh/hpwh.component";
import { HPSCComponent } from "./energy-choices/hpsc/hpsc.component";
import { ICComponent } from "./energy-choices/ic/ic.component";
import { HPDComponent } from "./energy-choices/hpd/hpd.component";
import { SolarComponent } from "./energy-choices/solar/solar.component";
import { PoolComponent } from "./energy-choices/pool/pool.component";
import { SpaComponent } from "./energy-choices/spa/spa.component";
import { EBikeComponent } from "./energy-choices/e-bike/e-bike.component";
import { AdditionalCommentsComponent } from "./additional-comments/additional-comments.component";
import { RoofTopComponent } from "./roof-top/roof-top.component";
import { EnergyInfoComponent } from "./energy-info/energy-info.component";
import { FooterModule } from "./shared/footer/index";
import { ScrollTopModule } from "../shared/scroll-top/index";
import { ToggleButtonModule } from "./shared/toggle-button/index";
import { LifestyleBoxModule } from "../shared/lifestyle-box/index";
import { CalculateMilesModule } from "../shared/calculate-miles/index";
import { CaptchaModule } from "../shared/captcha";
import { LearnMoreService } from "../homeowner-dashboard/learn-more/learn-more.service";
import { HomeOwnerProductRoutingModule } from "./homeowner-product-routing.module";
import { RoofTopModule } from "../shared/roof-top/index";
import { DialogOverviewModule } from "../shared/dialog-overview/index";
import { EnergyInfoModule } from "../shared/energy-info/index";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { MatCardModule } from "@angular/material/card";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatRadioModule } from "@angular/material/radio";
import { MatSliderModule } from "@angular/material/slider";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { CommunitySolarComponent } from "./energy-choices/solar/community-solar/community-solar.component";
import { Utils } from "../utils/util";

@NgModule({
  imports: [
    CommonModule,
    HomeOwnerProductRoutingModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatSidenavModule,
    MatDialogModule,
    FooterModule,
    ScrollTopModule,
    ToggleButtonModule,
    MatButtonToggleModule,
    MatExpansionModule,
    FormsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTooltipModule,
    MatTabsModule,
    MatSliderModule,
    MatSnackBarModule,
    LifestyleBoxModule,
    CalculateMilesModule,
    RoofTopModule,
    DialogOverviewModule,
    EnergyInfoModule,
    CaptchaModule,
  ],
  declarations: [
    HomeOwnerProductComponent,
    MyInfoComponent,
    EnergyChangesComponent,
    ThankYouComponent,
    ErrorDialogComponent,
    SideNavComponent,
    LayoutComponent,
    EnergyChoicesComponent,
    LifeStyleOverlayComponent,
    ACComponent,
    EVComponent,
    SpaComponent,
    BatteryComponent,
    PoolComponent,
    HPWHComponent,
    ICComponent,
    HPDComponent,
    HPSCComponent,
    SolarComponent,
    CommunitySolarComponent,
    EBikeComponent,
    AdditionalCommentsComponent,
    RoofTopComponent,
    EnergyInfoComponent,
  ],
  providers: [BreakpointObserver, MediaMatcher, Utils, LearnMoreService],
  exports: [HomeOwnerProductComponent],
})
export class HomeOwnerProductModule {}
