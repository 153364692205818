<div class="faq_container">
  <div class="about_technology" *ngIf="showAboutTech">
    <div class="header">Overview</div>
    <p>
      <strong>Is it New?:</strong> Not really — heat pumps have been around for
      years. For example, your refrigerator is one, and air conditioners are
      also heat pumps. However, the widespread application to home water heating
      is somewhat recent in many areas.
      <span
        class="link_text"
        *ngIf="showReadMore"
        (click)="onReadContent('readMore')"
      >
        Read more...
      </span>
    </p>
    <div *ngIf="showReadLess">
      <p class="pt3">
        <strong>How it Works:</strong> Heat pumps use electricity to move heat
        from one place to another, instead of creating the heat directly. This
        is what makes them so efficient when compared to conventional heating
        systems.
      </p>

      <p class="pt3">
        <strong>Applicable Types of Heat Pumps:</strong> Various types of heat
        pumps are available to match a home’s particular needs. For example,
        some draw heat from the ground, some from well water, and some (the
        great majority) from the ambient air. Additionally, some are designed to
        just supply domestic hot water (such as for showers, etc.) and others
        also supply hot water for home heating through radiators or underfloor
        heating.
      </p>

      <p class="pt3">
        <strong>Economics:</strong> Heat pumps are generally more expensive to
        install than a conventional system. In many areas, however, incentives
        are available that can defray this additional cost. Additionally, you
        can often save money on your energy bills going forward, especially if
        you’re switching from heating with electricity, propane/LPG, or oil.
      </p>

      <p class="pt3">
        <strong>Environment:</strong> Heat pumps are a great way to reduce your
        carbon footprint. Their excellent efficiency, combined with the fact
        that they don’t burn fossil fuels, often lets them cut greenhouse gas
        emissions by 75% or more. Plus, as grid-supplied electricity becomes
        cleaner, the percentage reduction will grow over time.
        <span class="link_text" (click)="onReadContent('readLess')">
          Read less
        </span>
      </p>
    </div>
  </div>

  <div *ngIf="!showAboutTech">
    <div *ngIf="isActive(0)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. What is a heat pump water heater (HPWH)?
        </h5>
        <p class="answer_text">
          Heat pump water heaters use electricity to move heat from surrounding
          air to the water tank water instead of heating the water directly.
          This process makes them very efficient — three to four times more
          energy efficient than standard electric water heaters — and can
          significantly cut water heating costs in many cases. Heat pump water
          heaters have tank capacities similar to standard models, with the same
          range of temperature adjustments.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">2. What types of HPWHs are available?</h5>
        <p class="answer_text">
          Heat pump water heaters generally fall into two categories – unitary
          and split-system models. Both use storage tanks, unlike the tankless
          water heaters that are sometimes used in homes. Unitary systems are
          “drop-in” units that have approximately the same shape as a
          conventional tank water heater, but are somewhat taller to accommodate
          the heat pump. The split systems consist of an indoor storage tank and
          an outside unit with a compressor (and associated equipment) that
          captures heat from the outside air. Split systems require additional
          plumbing between the indoor and outdoor units.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">3. Can a HPWH function in any climate?</h5>
        <p class="answer_text">
          Yes, they can, but you need to consider the type of HPWH and where
          it’ll be located. Most HPWHs function well if the temperature in the
          area they’re located remains above freezing the great majority of the
          time. In colder climates this can be the case in many basements, in
          many garages, and certainly in closets inside the house. If the
          temperature becomes too cold for the heat pump to operate efficiently,
          most HPWHs have backup resistance heating elements that can provide
          the needed hot water, but with greatly reduced efficiency. It should
          also be kept in mind that a HPWH will cool the area in which it’s
          located and this could result in increased heating bills. Some split
          HPWH systems, where the compressor unit is located outside, can
          operate in heat pump mode even down to very low temperatures (but at a
          reduced efficiency). In such situations you wouldn’t have to worry
          about cooling your conditioned space during winter.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          4. How much hot water is available for the household?
        </h5>
        <p class="answer_text">
          Heat pump water heaters come with different tank sizes. The most
          common sizes currently on the market are 50, 65, and 80 gallon units.
          After being depleted of hot water, HPWHs (while operating in their
          efficient, heat pump mode) take longer to recharge than conventional
          fossil-fueled and electric water heaters. Some professionals suggest
          replacing your old water heater with a somewhat larger HPWH to
          compensate for this. For example, you might replace a conventional 50
          gallon water heater with a 65 or 80 gallon HPWH.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          5. What are the advantages of using a HPWH?
        </h5>
        <p class="answer_text">
          Heat pump water heaters use a proven energy-efficient technology. If
          you are switching from an electric water heater, a HPWH can reduce
          your water heating costs by up to 70%. If replacing a natural gas
          water heater, you’ll likely reduce your monthly water-heating costs,
          but the savings may not be substantial (depending on the energy prices
          in your area). Pairing the HPWH with a rooftop solar system can
          generate substantial savings when compared with a conventional water
          heater. Also, there’s an environmental advantage to using a HPWH. If a
          water heater fueled by natural gas, propane, or fuel oil is replaced
          with a HPWH powered by clean electricity, your carbon footprint is
          greatly reduced.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          6. Is a tankless electric water heater more efficient?
        </h5>
        <p class="answer_text">
          No. Tankless electric water heaters can be at most 100% efficient
          since they directly heat the water with electricity. A HPWH just moves
          heat (instead of creating it) and can be effectively 300-400%
          efficient.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(1)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How much does a HPWH cost?</h5>
        <p class="answer_text">
          The purchase price of a heat pump water heater is higher than the cost
          of a conventional electric or fossil-fueled water heater, but utility
          rebates, tax credits, and promotions can significantly reduce the
          purchase price. Plus, the unit will generally have lower operating
          costs, cutting your monthly bills right away. Numerous factors can
          affect the installed price, including geographic location,
          installation complexity, model selected, and local labor costs.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. How much can I save on energy costs with a heat pump water heater?
        </h5>
        <p class="answer_text">
          It depends on a number of factors, especially the type of water heater
          you’re switching from and the energy prices in your area. If you’re
          replacing an electric water heater, you can cut your water heating
          costs by up to 70%. If replacing a natural gas water heater, you’ll
          likely reduce your monthly water-heating costs, but the savings may
          not be large. If replacing propane or fuel oil as your water heating
          fuel, your savings can be substantial. Pairing the HPWH with a rooftop
          solar system can generate very large savings since the solar power
          will be less expensive.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(2)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. What are the steps needed to install a HPWH?
        </h5>
        <p class="answer_text">
          If you’re replacing an electric water heater with a unitary HPWH, the
          process is generally pretty straightforward. As with any unitary HPWH,
          a drain line would need to be installed to handle the condensate from
          the heat pump. When switching from a fossil-fueled water heater, an
          electrician may need to run a 240V line from the main electrical
          service panel (or a sub-panel) to where the HPWH is installed.
          However, HPWHs that run on 120V power are becoming more available, so
          this should become somewhat less of a problem in the future.
          Regardless of the type of water heater being replaced, a split HPWH
          requires additional work since an outdoor component must be installed
          as well as the indoor component.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">2. Where can I install a HPWH?</h5>
        <p class="answer_text">
          Heat pump water heaters can be installed in a variety of locations,
          from an unheated garage or basement, to a heated utility room, to an
          indoor closet. With a unitary HPWH, the compressor is located just
          above the storage tank, making it noisier than a conventional water
          heater. This must be considered for any indoor (closet) location.
          These HPWHs also release cold air as they capture heat from their
          surroundings, which can be helpful during the summer and a problem
          during the winter. Split HPWHs don’t present the same issues as indoor
          installations since the noise and cool air are associated with the
          outdoor component of the water heater. However, split HPWHs require
          additional plumbing to connect the indoor and outdoor components and
          are generally more expensive.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">3. What is the recommended tank size?</h5>
        <p class="answer_text">
          A normal rule of thumb is the use of a 50 gallon tank for 3 people or
          fewer and an 80 gallon tank for 4 or more people in the household.
          However, the optimal size depends a lot on the water usage patterns of
          the household.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(3)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. What maintenance does a HPWH require?</h5>
        <p class="answer_text">
          Unlike conventional water heaters, heat pump water heaters use an air
          filter that needs to be cleaned periodically to ensure efficient
          operation. Other maintenance needs are similar to those of standard
          tank water heaters that operate on electricity or fossil-fuel.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(4)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. Can I save on carbon emissions if I switch to a HPWH?
        </h5>
        <p class="answer_text">
          Yes, it will reduce your carbon footprint. The amount of the reduction
          depends on the carbon intensity of the electricity used to power your
          HPWH and the energy source (such as natural gas, electricity, etc.)
          you’re switching from. If you add rooftop solar at the same time, the
          carbon footprint of running a HPWH is drastically reduced.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(5)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How do I operate a HPWH?</h5>
        <p class="answer_text">
          Heat pump water heaters are more interactive than conventional water
          heaters. Most models feature a control panel that allows homeowners to
          select water temperature and operation mode. Some are WiFi connected
          and come with a smartphone app. The app lets you control the HPWH and
          examine energy consumption patterns that can then be used to optimize
          the usage.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">2. What is the warranty on a HPWH?</h5>
        <p class="answer_text">
          Major manufacturers of heat pump water heaters typically offer 6-year
          or 10-year warranties on their products.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(6)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. What types of incentives are available?</h5>
        <p class="answer_text">
          Many utilities around the country offer rebates for heat pump water
          heaters. In addition, state- and county-level incentives are also
          sometimes available.
        </p>
      </div>
    </div>
  </div>
</div>
