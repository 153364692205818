<div class="tabs-group" (window:resize)="onResize()">
  <div class="headers-wrapper">
    <div
      class="prev-button"
      *ngIf="showScrollButton"
      (click)="handleScroll('prev')"
      [ngClass]="{ 'disable-button': isButtonDisabled('prev') }"
    >
      <mat-icon>chevron_left</mat-icon>
    </div>
    <div class="tab-headers">
      <ng-container *ngFor="let tabsList of tabHeadersList; index as i">
        <div
          class="tab-header"
          [ngStyle]="{ minWidth: minWidth ? minWidth : '160px' }"
          [ngClass]="{ 'active-header': i === tabIndex }"
          (click)="tabChanged(tabsList.value, i)"
        >
          <div class="tab-label">{{ tabsList.viewValue }}</div>
        </div>
      </ng-container>
    </div>
    <div
      class="next-button"
      *ngIf="showScrollButton"
      (click)="handleScroll('next')"
      [ngClass]="{ 'disable-button': isButtonDisabled('next') }"
    >
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
</div>
