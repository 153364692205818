<mat-card class="pointer" [ngClass]="{ 'yt__mat-border': isSelected }">
  <div class="container">
    <div *ngIf="image">
      <img
        mat-card-image
        [src]="image"
        loading="lazy"
        [alt]="displayValue"
        class="yt__mat-card-img height-160 width-150"
      />
    </div>
    <div class="content">
      <div class="flex justify-center yt__dg tc">
        <div class="yt__f4 fw6">{{ displayValue }}</div>
      </div>
    </div>
  </div>
</mat-card>
