<div class="container_section">
  <div class="header">
    {{ "homeOwenerDashboard.dashboard.overview.recommendations" | translate }}
    <span class="renterText" *ngIf="showWhatChoices" (click)="openDialog()"
      >&nbsp; What choices apply to me?</span
    >
  </div>
  <div class="tabs-group" (window:resize)="onResize()">
    <div class="headers-wrapper">
      <div
        class="prev-button"
        (click)="handleScroll('prev')"
        *ngIf="showScrollButton"
        [ngClass]="{ 'disable-button': isButtonDisabled('prev') }"
      >
        <mat-icon>chevron_left</mat-icon>
      </div>
      <div class="tab_headers">
        <div *ngFor="let item of tabList; index as i">
          <div
            class="tab-header"
            [id]="item.value"
            [ngClass]="{ 'active-header': i === selectedIndex }"
            (click)="onTabChange(item.value, i)"
          >
            <img
              [src]="item.img"
              [alt]="item.altText"
              width="33px"
              height="35px"
            />
            <div class="tab-label">{{ getTranslateText(item.value) }}</div>
          </div>
        </div>
      </div>
      <div
        class="next-button"
        (click)="handleScroll('next')"
        *ngIf="showScrollButton"
        [ngClass]="{ 'disable-button': isButtonDisabled('next') }"
      >
        <mat-icon>chevron_right</mat-icon>
      </div>
    </div>
    <div class="content-wrapper">
      <!-- Simple Low Cost Tab -->
      <div *ngIf="tabStatus('simpleLowCost')">
        <div class="saving_info" *ngIf="!onTechListLength">
          {{ translateText("before")
          }}<span
            class="saving_value"
            [ngClass]="{ value_color: lowCostSavingsValue < 0 }"
            >{{
              lowCostSavingsValue | currency : "$" : "symbol" : "1.0-0"
            }}</span
          >{{ translateText("after") }}
        </div>

        <!-- <yt-tech-cards
          [tabLabel]="tabLabel"
          [techList]="simpleLowCost"
          [timeFrame]="timeFrame"
          (onAdd)="onAdd($event)"
          (onRemove)="onRemove($event)"
          (alreadyHaveTech)="onAlreadyHave($event)"
          (techListLength)="checkTechList($event)"
          (updateSavings)="updateSavingsDetails()"
        ></yt-tech-cards> -->
      </div>
      <!-- Transportation Tab -->
      <div *ngIf="tabStatus('transportation')">
        <div class="saving_info" *ngIf="!onTechListLength">
          {{ translateText("before") }}
          <span
            class="saving_value"
            [ngClass]="{ value_color: transportSavingsValue < 0 }"
            >{{
              transportSavingsValue | currency : "$" : "symbol" : "1.0-0"
            }}</span
          >
          {{ translateText("after") }}
        </div>

        <div
          class="card_container"
          *ngIf="getApproach1Status()"
          [ngClass]="{ mt_24: onTechListLength }"
        >
          <div class="image_container">
            <img src="assets/lifestyle/MSP.png" />
          </div>
          <div class="content_wrapper">
            <div class="text_content">
              As electricity-using devices (such as electric vehicles, water
              heaters, etc.) are adopted, it is possible that older homes might
              reach the capacity of their electrical service panels. In such
              cases, steps need to be taken to either replace the service panel
              with a higher capacity or introduce power-sharing devices that
              sometimes produce the same result at a lower cost.
            </div>
            <div class="read_more_link">
              <span (click)="showServicePanelInfo()">Learn more</span>
            </div>
          </div>
        </div>

        <!-- <yt-tech-cards
          [tabLabel]="tabLabel"
          [techList]="transportation"
          [timeFrame]="timeFrame"
          (techListLength)="checkTechList($event)"
          (onAdd)="onAdd($event)"
          (alreadyHaveTech)="onAlreadyHave($event)"
          (onRemove)="onRemove($event)"
          (updateSavings)="updateSavingsDetails()"
        ></yt-tech-cards> -->
      </div>
      <!-- Solar Battery Tab -->
      <div *ngIf="tabStatus('solarBattery')">
        <div class="saving_info" *ngIf="!onTechListLength">
          {{ translateText("before") }}
          <span
            class="saving_value"
            [ngClass]="{ value_color: solarBatterySavingsValue < 0 }"
            >{{
              solarBatterySavingsValue | currency : "$" : "symbol" : "1.0-0"
            }}</span
          >
          {{ translateText("after") }}
        </div>

        <div
          class="card_container"
          *ngIf="getApproach1Status()"
          [ngClass]="{ mt_24: onTechListLength }"
        >
          <div class="image_container">
            <img src="assets/lifestyle/MSP.png" />
          </div>
          <div class="content_wrapper">
            <div class="text_content">
              As electricity-using devices (such as electric vehicles, water
              heaters, etc.) are adopted, it is possible that older homes might
              reach the capacity of their electrical service panels. In such
              cases, steps need to be taken to either replace the service panel
              with a higher capacity or introduce power-sharing devices that
              sometimes produce the same result at a lower cost.
            </div>
            <div class="read_more_link">
              <span (click)="showServicePanelInfo()">Learn more</span>
            </div>
          </div>
        </div>

        <div class="yt_popUp" id="techAlert" *ngIf="errorMsg">
          <div class="solar_alert">
            <div class="flex justify-between popUp_padding">
              {{ solarErrorMessage }}
            </div>
          </div>
        </div>
        <!-- <yt-tech-cards
          [tabLabel]="tabLabel"
          (savingsDialog)="openSavingsDialog($event)"
          [techList]="solarBattery"
          [timeFrame]="timeFrame"
          (techListLength)="checkTechList($event)"
          (onAdd)="onAdd($event)"
          (alreadyHaveTech)="onAlreadyHave($event)"
          (onRemove)="onRemove($event)"
          (updateSavings)="updateSavingsDetails()"
        ></yt-tech-cards> -->
      </div>
      <!-- Home Appliances -->
      <div *ngIf="tabStatus('appliances')">
        <div class="saving_info" *ngIf="!onTechListLength">
          {{ translateText("before") }}
          <span
            class="saving_value"
            [ngClass]="{ value_color: appliancesSavingsValue < 0 }"
            >{{
              appliancesSavingsValue | currency : "$" : "symbol" : "1.0-0"
            }}</span
          >
          {{ translateText("after") }}
        </div>

        <div
          class="card_container"
          *ngIf="getApproach1Status()"
          [ngClass]="{ mt_24: onTechListLength }"
        >
          <div class="image_container">
            <img src="assets/lifestyle/MSP.png" />
          </div>
          <div class="content_wrapper">
            <div class="text_content">
              As electricity-using devices (such as electric vehicles, water
              heaters, etc.) are adopted, it is possible that older homes might
              reach the capacity of their electrical service panels. In such
              cases, steps need to be taken to either replace the service panel
              with a higher capacity or introduce power-sharing devices that
              sometimes produce the same result at a lower cost.
            </div>
            <div class="read_more_link">
              <span (click)="showServicePanelInfo()">Learn more</span>
            </div>
          </div>
        </div>

        <!-- <yt-tech-cards
          [tabLabel]="tabLabel"
          [techList]="appliances"
          [timeFrame]="timeFrame"
          (techListLength)="checkTechList($event)"
          (onAdd)="onAdd($event)"
          (alreadyHaveTech)="onAlreadyHave($event)"
          (onRemove)="onRemove($event)"
          (updateSavings)="updateSavingsDetails()"
        ></yt-tech-cards> -->
      </div>
      <!-- favorites -->
      <div *ngIf="tabStatus('favorites')">
        <div
          class="saving_info"
          *ngIf="!!recommendationDetails.favorite.length"
        >
          {{ translateText("before") }}
          <span
            class="saving_value"
            [ngClass]="{ value_color: favoriteSavingsValue < 0 }"
            >{{
              favoriteSavingsValue | currency : "$" : "symbol" : "1.0-0"
            }}</span
          >
          {{ translateText("after") }}
        </div>
        <div>
          <!-- <yt-tech-cards
            [tabLabel]="tabLabel"
            [techList]="favorite"
            [timeFrame]="timeFrame"
            (onRemove)="onRemove($event)"
            (alreadyHaveTech)="onAlreadyHave($event)"
            (updateSavings)="updateSavingsDetails()"
          >
          </yt-tech-cards> -->
        </div>
      </div>
    </div>
  </div>
</div>
