import { map } from "rxjs/operators/map";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs/Observable";
import { startWith } from "rxjs/operators/startWith";
// import { empty } from "rxjs/Observer";
import { RestAPIService } from "../../rest-api/rest-api.service";
import { AppService } from "../../app.service";
import { LayoutService } from "../shared/layout/layout.service";
import { ElecUtilityProvidersModel } from "../../shared/energy-info/energy-info.model";
import { takeWhile } from "rxjs/operators/takeWhile";

@Component({
  selector: "yt-energy-info",
  templateUrl: "energy-info.component.html",
  styleUrls: ["./energy-info.component.scss"],
})
export class EnergyInfoComponent implements OnInit, OnDestroy {
  authorizationSection: boolean;
  authorizationSectionPGE: boolean = false;
  smdLink: boolean;
  private alive = true;
  authorizeEnergyUsageUrl: string;
  header: string;
  subHeader: string;
  energyProviderGbc: boolean;
  utilityName: string;
  utilityId: number;
  utilityGbc: string;
  utilityForm: FormGroup;
  isDisableButton: boolean = true;
  isUtilitySelected = false;
  cceOptOut = false;
  cceFullForm: string;
  cceTooltip: string;
  utilityProvidersModel: Observable<ElecUtilityProvidersModel[]>;
  utilityProvidersList: ElecUtilityProvidersModel[];
  vendorCode: string;
  isSkyTech: boolean;
  haveResponse: boolean = false;
  showSMDTab: boolean;
  haveAutoLogin: boolean;
  showRedirectLoader: boolean;

  constructor(
    private layoutServiceV2: LayoutService,
    private restAPIService: RestAPIService,
    private appService: AppService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.utilityForm = this.fb.group({
      utilityId: ["", [Validators.required]],
      checkCceOptOut: [false],
    });
    this.restAPIService.setDestination(5);
    this.layoutServiceV2.hideSidenavSubject.next(false);
    this.restAPIService.getSolarVendorDescrption();
    this.vendorCode = this.appService.getVendorName();
    this.header = "Energy Info";
    this.restAPIService
      .getSolarVendorDetails(this.vendorCode)
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {
        if (data.solutionType === "broader" || data.solutionType === "cce") {
          this.subHeader =
            "Your information will help determine the right clean energy solutions.";
        } else if (data.solutionType === "vendor") {
          this.subHeader =
            "Your information will help determine the right system size.";
        }
        this.showSMDTab = data.accessConfig.showSMDTab;
        this.haveAutoLogin = data.accessConfig.autoLogin;
      });
    this.smdLink = this.restAPIService.getSmdLink();
    this.restAPIService.getEnergyProvider().subscribe((response) => {
      this.utilityProvidersList = response.elecUtilityProviders;
      if (response.elecUtilityProviders.length === 1) {
        this.utilityId = response.elecUtilityProviders[0].utilityId;
        this.utilityName = response.elecUtilityProviders[0].utilityName;
        this.utilityForm.controls["utilityId"].setValue(this.utilityName);
        this.isDisableButton = false;
        this.isUtilitySelected = true;
        this.restAPIService.setEnergyUtility(
          this.utilityId,
          this.utilityName,
          this.cceOptOut
        );
        this.getUtilityProvider(this.utilityId);
        if (this.showSMDTab) {
          if (response.elecUtilityProviders[0].gbc === "Yes") {
            this.energyProviderGbc = true;
          }
        } else {
          this.energyProviderGbc = false;
        }
      } else {
        this.isUtilitySelected = false;
      }
      this.utilityProvidersModel = this.utilityForm
        .get("utilityId")
        ?.valueChanges.pipe(
          startWith(""),
          map((val) => {
            this.onChangeOfUtilityId();
            return this.utilityProvidersList;
          })
        )!;
    });
  }

  onChangeOfUtilityId(): void {
    this.utilityName = this.utilityForm.controls["utilityId"].value;
    const utilityProvider = this.utilityProvidersList.find(
      (id) => id.utilityName === this.utilityName
    );
    if (utilityProvider) {
      this.utilityGbc = utilityProvider.gbc;
      this.utilityId = utilityProvider.utilityId;
      this.isUtilitySelected = true;
      this.getUtilityProvider(this.utilityId);
      this.onCceOptOut();
    } else {
      this.utilityGbc = "No";
      this.isUtilitySelected = false;
      this.cceOptOut = false;
    }
    if (this.showSMDTab) {
      if (this.utilityGbc === "Yes") {
        if (this.vendorCode === "SKYT") {
          this.isSkyTech = false;
        }
        this.energyProviderGbc = true;
      } else {
        this.energyProviderGbc = false;
        this.isSkyTech = true;
      }
    } else {
      this.energyProviderGbc = false;
      this.isSkyTech = true;
    }
    this.isDisableButton =
      this.utilityForm.get("utilityId")?.invalid || !utilityProvider
        ? true
        : false;
    this.restAPIService.setEnergyUtility(
      this.utilityId,
      this.utilityName,
      this.cceOptOut
    );
  }

  onCceOptOut(): void {
    this.restAPIService.getCceDetails(this.utilityId).subscribe((response) => {
      this.cceFullForm = response.ccefullForm;
      this.cceTooltip = `In your area, you can either choose ${this.cceFullForm} or ${this.utilityName} as your
      electricity provider. Checking this box indicates that you have opted for ${this.utilityName} as your provider.`;
      if (this.cceFullForm === "NA" || this.cceFullForm === "NONE") {
        this.cceOptOut = false;
      } else {
        this.cceOptOut = true;
      }
    });
  }

  getUtilityProvider(utilityID: number) {
    this.restAPIService.getUtilityProvider(utilityID).subscribe((data) => {
      this.haveResponse = true;
      this.restAPIService.utilityProvider = data.code;
      if (data.code === "PGE") {
        this.authorizationSection = true;
        if (data.website.oauth) {
          this.authorizationSectionPGE = true;
          this.authorizeEnergyUsageUrl =
            data.website.oauth +
            this.restAPIService.homeOwnerEmail +
            ";" +
            this.restAPIService.getVendorName() +
            ";" +
            this.restAPIService.getBuildingType();
        } else {
          this.authorizationSectionPGE = false;
        }
      } else {
        this.authorizationSection = false;
      }
    });
  }

  loaderStatus(loaderStatus) {
    this.showRedirectLoader = loaderStatus;
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
