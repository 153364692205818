import { LayoutService } from "./../shared/layout/layout.service";
import { UpgradesModel } from "./energy-changes.model";
import { RestAPIService } from "../../rest-api/rest-api.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { takeWhile } from "rxjs/operators";
import { RoofTypesModel } from "src/app/rest-api/rest-api.model";

@Component({
  selector: "yt-energy-changes",
  templateUrl: "energy-changes.component.html",
  styleUrls: ["./energy-changes.component.scss"],
})
export class EnergyChangesComponent implements OnInit, OnDestroy {
  upgradesForm: FormGroup;
  displayFutureEnergyUsage: boolean;
  displayAddEvCharges: boolean;
  displayNoOfEvCharges: boolean;
  updateMode: boolean;
  upgradesDetails: UpgradesModel;
  disableAddEVChargeButton = true;
  evChargesData: string;
  noOfEvChargesData: any;

  private alive = true;

  initialUpgradeFormValue: UpgradesModel = {
    addEvCharges: "no",
    futureEnergyUsage: 0,
    noOfEvCharges: "",
  };

  yesOrNo: RoofTypesModel[] = [
    {
      displayValue: "Yes",
      value: "yes",
    },
    {
      displayValue: "No",
      value: "no",
    },
  ];
  constructor(
    private fb: FormBuilder,
    private restAPIService: RestAPIService,
    private layoutServiceV2: LayoutService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.layoutServiceV2.hideSidenavSubject.next(false);
    if (this.restAPIService.getUserProvidedUpgrades() === undefined) {
      this.restAPIService.setDestination(6);
    }
    this.upgradesForm = this.fb.group({
      futureEnergyUsage: ["", Validators.required],
      addEvCharges: ["no", Validators.required],
      noOfEvCharges: [""],
    });
    let userEnteredDetails = this.restAPIService.getUserProvidedUpgrades();
    this.upgradesDetails =
      userEnteredDetails !== undefined
        ? userEnteredDetails
        : this.initialUpgradeFormValue;
    this.updateMode = userEnteredDetails !== undefined ? true : false;
    this.upgradesForm.setValue(this.upgradesDetails);
    if (this.updateMode) {
      this.displayFutureEnergyUsage = true;
      this.displayAddEvCharges = true;
      if (this.upgradesDetails.noOfEvCharges) {
        this.displayNoOfEvCharges = true;
        this.upgradesForm.controls["noOfEvCharges"].setValidators([
          Validators.required,
          Validators.min(1),
          Validators.max(5),
        ]);
        this.upgradesForm.controls["noOfEvCharges"].updateValueAndValidity();
      }
    }
    this.upgradesForm.valueChanges
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => {
        this.upgradesDetails = data;
      });
  }

  onAddEvCharges(data: string) {
    this.upgradesForm.controls["addEvCharges"].setValue(data);
    if (data === "no") {
      this.displayNoOfEvCharges = false;
      this.upgradesForm.controls["noOfEvCharges"].reset();
      this.upgradesForm.controls["noOfEvCharges"].clearValidators();
      this.upgradesForm.controls["noOfEvCharges"].updateValueAndValidity();
    } else {
      this.displayNoOfEvCharges = true;
      this.upgradesForm.controls["noOfEvCharges"].setValidators([
        Validators.required,
        Validators.min(1),
        Validators.max(5),
      ]);
      this.upgradesForm.controls["noOfEvCharges"].updateValueAndValidity();
    }
  }
  onNoEvChargesSubmit() {
    if (this.noOfEvChargesData) {
      this.upgradesForm.controls["noOfEvCharges"].setValue(
        this.noOfEvChargesData
      );
    } else {
      this.upgradesForm.controls["noOfEvCharges"].setValue("1");
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }
  onContinue() {
    this.restAPIService.postUpgrades(this.upgradesDetails).subscribe((data) => {
      if (data.status === 200) {
        this.layoutServiceV2.navRouterSubject.next({
          personalScreen: {
            showLink: true,
            activatedLink: false,
            disabledLink: false,
            menuNumber: "1",
          },
          roofTop: {
            showLink: true,
            activatedLink: false,
            disabledLink: false,
            menuNumber: "2",
          },
          lifeStyle: {
            showLink: false,
            activatedLink: false,
            disabledLink: false,
            menuNumber: "3",
          },
          additionalinfo: {
            showLink: true,
            activatedLink: true,
            disabledLink: false,
            menuNumber: "4",
          },
          upgrades: {
            showLink: true,
            activatedLink: false,
            disabledLink: false,
            menuNumber: "3",
          },
          energyUsage: {
            showLink: true,
            activatedLink: false,
            disabledLink: true,
            menuNumber: "5",
          },
          overview: {
            showLink: false,
            activatedLink: false,
            disabledLink: true,
            menuNumber: "",
          },
          logo1: {
            showLink: true,
            activatedLink: false,
            disabledLink: false,
            menuNumber: "",
          },
          logo2: {
            showLink: false,
            activatedLink: false,
            disabledLink: false,
            menuNumber: "",
          },
          usermenu: {
            showLink: false,
            activatedLink: false,
            disabledLink: false,
            menuNumber: "",
          },
          logout: {
            showLink: false,
            activatedLink: false,
            disabledLink: false,
            menuNumber: "",
          },
          poweredbyYt: {
            showLink: true,
            activatedLink: false,
            disabledLink: false,
            menuNumber: "",
          },
        });
        this.router.navigate(["additional-info"], {
          relativeTo: this.route.parent,
        });
      }
    });
  }
}
