import { LayoutService } from "./layout.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { LogoModel, SolarVendorModel } from "../../../rest-api/rest-api.model";
import { RouterInfoModel } from "../side-nav/router-info.model";
import { JWTTokenInfoModel } from "../../../auth/auth.model";
import { AppService } from "../../../app.service";
import { RestAPIService } from "../../../rest-api/rest-api.service";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "yt-layout",
  templateUrl: "layout.component.html",
})
export class LayoutComponent implements OnInit, OnDestroy {
  public solarVendor: SolarVendorModel;
  public showSpinner = true;
  private alive = true;
  dashboardlogoModel: LogoModel;
  logoModel: LogoModel;
  routeInfo: RouterInfoModel;
  hideSidenav: boolean;
  smdLink: boolean;
  jwtTokenInfo: JWTTokenInfoModel;
  address: String;
  smd;
  code: String;

  constructor(
    private layoutService: LayoutService,
    private appService: AppService,
    private restAPIService: RestAPIService
  ) {}

  ngOnInit() {
    this.routeInfo = {
      personalScreen: {
        showLink: true,
        activatedLink: true,
        disabledLink: false,
        menuNumber: "1",
      },
      roofTop: {
        showLink: true,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "2",
      },
      lifeStyle: {
        showLink: true,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "3",
      },
      additionalinfo: {
        showLink: true,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "4",
      },
      upgrades: {
        showLink: false,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "3",
      },
      energyUsage: {
        showLink: true,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "5",
      },
      overview: {
        showLink: false,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "",
      },
      logo1: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      logo2: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      usermenu: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      logout: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      poweredbyYt: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
    };
    this.layoutService.getSmdLink$
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => {
        if (data) {
          this.routeInfo = {
            ...this.routeInfo,
            personalScreen: {
              showLink: true,
              activatedLink: true,
              disabledLink: this.routeInfo.personalScreen.disabledLink,
              menuNumber: "1",
            },
            roofTop: {
              showLink: false,
              activatedLink: false,
              disabledLink: true,
              menuNumber: "2",
            },
            lifeStyle: {
              showLink: false,
              activatedLink: false,
              disabledLink: true,
              menuNumber: "3",
            },
            additionalinfo: {
              showLink: false,
              activatedLink: false,
              disabledLink: true,
              menuNumber: "4",
            },
            upgrades: {
              showLink: false,
              activatedLink: false,
              disabledLink: true,
              menuNumber: "3",
            },
            energyUsage: {
              showLink: true,
              activatedLink: false,
              disabledLink: true,
              menuNumber: "2",
            },
            overview: {
              showLink: false,
              activatedLink: false,
              disabledLink: true,
              menuNumber: "",
            },
          };
        }
      });

    this.layoutService.navRouter$
      .pipe(takeWhile((_) => this.alive))
      .subscribe((routeInfo) => {
        this.routeInfo = routeInfo;
      });

    this.layoutService.hideSidenav$
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => {
        this.hideSidenav = data;
      });

    this.layoutService.getSmdLink$
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => {
        this.restAPIService.setSmdLink(data);
      });

    this.appService.solarVendorModel$
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => (this.logoModel = data.logo));

    this.appService.currentVendorName$
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => (this.code = data));

    this.appService.jwtToken$
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => (this.jwtTokenInfo = data));

    this.appService.address$
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => (this.address = data));

    this.appService.dashboardlogoModel$
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => {
        this.dashboardlogoModel = data;
      });

    let vendorCode = this.appService.getVendorName();
    this.restAPIService.getSolarVendorDetails(vendorCode).subscribe((data) => {
      this.restAPIService.setSolarVendorDetails(data);
      this.solarVendor = data;
      this.showSpinner = false;
    });
  }
  ngOnDestroy() {
    this.alive = false;
  }
}
