<!-- OtherAppliances -->

<ng-container *ngIf="section === 'otherAppliances'">
  <ng-container *ngIf="activeTech === 'electricFireplace'" class="container">
    <h2 class="tech_header">
      Electric Fireplace
      <div class="desktop_display">
        <yt-star-rating [starCount]="1"></yt-star-rating>
      </div>
    </h2>
    <div class="mobile_display">
      <yt-star-rating [starCount]="1"></yt-star-rating>
    </div>
    <div class="yt__width">
      <div class="yt_content">
        <div>
          Electric fireplaces are available today in a variety of designs and
          decorative styles, making it easier to find one suited for your home
          decor.
        </div>
        <div class="padding_top">
          Some use LED lights and water vapor to resemble a real fireplace.
          Additionally, some come with electric resistance elements that can
          also provide heating.
        </div>
      </div>
    </div>
    <div class="border flex">
      <div class="yard-costrange_savings border-right">
        $300-$4,000
        <div class="saving_text">
          Average Cost <span class="seperator">:</span>
        </div>
      </div>
      <div class="noise_rating">
        $0.02-$0.35/hr
        <div
          class="saving_text tooltip_text pointer"
          matTooltip="Depends on the electricity
        cost and the mode of operation."
        >
          Operating Cost <span class="seperator">:</span>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTech === 'instantPot'" class="container">
    <h2 class="tech_header">Instant Pot</h2>
    <div class="yt__width">
      <div class="yt_content">
        <div>
          Multi-cookers such as the Instant Pot®, save cooking time, conserve
          energy, and keep vitamins intact during the cooking process.
        </div>
        <div class="padding_top">
          The Instant Pot® has a well-insulated exterior pot and can cook under
          pressure, so it uses less energy than conventional cooking. It also
          uses considerably less water. The saute feature allows the entire
          cooking process to be completed within one appliance making it really
          convenient.
        </div>
      </div>
    </div>
    <div class="border flex">
      <div class="average_cost-details">
        $60-$200
        <div class="saving_text">
          Average Cost <span class="seperator">:</span>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTech === 'electricKettle'" class="container">
    <h2 class="tech_header">Electric Kettle</h2>
    <div class="yt__width">
      <div class="yt_content">
        <div>
          Electric kettles offer a simple and convenient way to boil water for
          tea, coffee, and other hot beverages. They are usually well-insulated,
          and the heating coils are located immediately beneath the water. This
          decreases the amount of heat that escapes, allowing the water to boil
          faster.
        </div>
        <div class="padding_top">
          Many electric kettles feature automated shut-offs; when the water
          boils, the kettle switches off. You may improve efficiency by
          carefully measuring only the amount of water required for boiling.
        </div>
      </div>
    </div>
    <div class="border flex">
      <div class="average_cost-details">
        $20-$60
        <div class="saving_text">
          Average Cost <span class="seperator">:</span>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container
    *ngIf="activeTech === 'highEfficiencyClothesWasher'"
    class="container"
  >
    <h2 class="tech_header">
      High-efficiency Clothes Washer
      <div class="desktop_display">
        <yt-star-rating [starCount]="1"></yt-star-rating>
      </div>
    </h2>
    <div class="mobile_display">
      <yt-star-rating [starCount]="1"></yt-star-rating>
    </div>
    <div class="yt__width">
      <div class="yt_content">
        <div>
          Replacing your old clothes washer with a new high-efficiency model can
          save a significant amount of water.
        </div>
        <div>
          Energy Star certified clothes washers use about 25% less energy and
          33% less water than regular clothes washers.
        </div>
      </div>
    </div>
    <div class="border flex">
      <div class="yard-costrange_savings border-right">
        $900-$1,500
        <div class="saving_text">
          Average Cost <span class="seperator">:</span>
        </div>
      </div>
      <div class="noise_rating">
        $6/year
        <div class="saving_text">
          Average Savings <span class="seperator">:</span>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTech === 'smartWaterMonitor'" class="container">
    <h2 class="tech_header">
      Smart Water Monitor
      <div class="desktop_display">
        <yt-star-rating [starCount]="2"></yt-star-rating>
      </div>
    </h2>
    <div class="mobile_display">
      <yt-star-rating [starCount]="2"></yt-star-rating>
    </div>
    <div class="yt__width">
      <div class="yt_content">
        <div>
          Even small water leaks in your home can waste a huge amount of water
          over time. Additionally, catastrophic leaks can result in costly
          damage in your home very quickly. You can help to avoid these problems
          by installing a smart water monitoring and shutoff system on your main
          supply line.
        </div>
        <div class="padding_top">
          These systems monitor your home’s water usage 24/7 and can detect and
          alert you about problems ranging from very small leaks to a burst pipe
          or failed water heater. They can also be programmed to automatically
          shut off the water to your house in the event of a major leak.
          Controlling the system is easy from anywhere via an app, even if
          you’re on vacation. Additionally, over time, some of these systems
          learn about your water-usage patterns, so they can make intelligent
          decisions about what’s normal usage and what’s a problem. 
        </div>
      </div>
    </div>
    <div class="border flex">
      <div class="yard-costrange_savings border-right">
        $300-$800
        <div class="saving_text">
          Average Cost <span class="seperator">:</span>
        </div>
      </div>
      <div class="noise_rating">
        $25-$100/year
        <div class="saving_text">
          Average Savings <span class="seperator">:</span>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<!-- YardAppliances -->

<ng-container *ngIf="section === 'yardAppliances'">
  <ng-container *ngIf="activeTech === 'electricLawnMower'" class="container">
    <h2 class="tech_header">Electric Lawn Mower</h2>
    <div class="yt__width">
      <div class="yt_content">
        <div>
          Electric lawn mowers are easy to operate, perform better, and can save
          you money when compared to gasoline-fueled mowers. They are available
          in a wide range of prices and capabilities to choose from, based on
          your yard size and budget.
        </div>
        <div class="padding_top">
          Electric lawn mowers generate 60-70 decibels of noise, which is far
          lower than the 100 decibels generally produced by gas lawn mowers.
        </div>
      </div>
    </div>
    <div class="border flex">
      <div class="yard-costrange_savings border-right">
        $175-$600
        <div class="saving_text">
          Average Cost <span class="seperator">:</span>
        </div>
      </div>
      <div class="noise_rating">
        60-70 dB
        <div class="saving_text">
          Noise Rating <span class="seperator">:</span>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTech === 'electricGrassTrimmer'" class="container">
    <h2 class="tech_header">Electric Grass Trimmer</h2>
    <div class="yt__width">
      <div class="yt_content">
        <div>
          Electric grass trimmers offer plenty of power and are available in
          corded and battery-powered, cordless models. They require little
          maintenance, reduce noise pollution, are lighter, and are easier to
          handle. They produce zero exhaust emissions and are a great option for
          small to moderate-sized yards.
        </div>
      </div>
    </div>
    <div class="border flex">
      <div class="yard-costrange_savings border-right">
        $60-$300
        <div class="saving_text">
          Average Cost <span class="seperator">:</span>
        </div>
      </div>
      <div class="noise_rating">
        55-65 dB
        <div class="saving_text">
          Noise Rating <span class="seperator">:</span>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTech === 'electricLeafBlower'" class="container">
    <h2 class="tech_header">Electric Leaf Blower</h2>
    <div class="yt__width">
      <div class="yt_content">
        <div>
          Electric leaf blowers emit less noise, are lightweight, are easier to
          use, and don't release any fumes. Cheaper than gas leaf blowers, they
          are available in corded and cordless models.
        </div>
        <div class="padding_top">
          Electric leaf blowers generate 55-65 decibels of noise, which is
          significantly lower than the 70-95 decibels typically produced by gas
          leaf blowers, and they require less maintenance.
        </div>
      </div>
    </div>
    <div class="border flex">
      <div class="yard-costrange_savings border-right">
        $50-$200
        <div class="saving_text">
          Average Cost <span class="seperator">:</span>
        </div>
      </div>
      <div class="noise_rating">
        55-65 dB
        <div class="saving_text">
          Noise Rating <span class="seperator">:</span>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTech === 'irrigationSystem'" class="container">
    <h2 class="tech_header">
      Irrigation System
      <div class="desktop_display">
        <yt-star-rating [starCount]="2"></yt-star-rating>
      </div>
    </h2>
    <div class="mobile_display">
      <yt-star-rating [starCount]="2"></yt-star-rating>
    </div>
    <div class="yt__width">
      <div class="yt_content">
        <div>
          An efficient irrigation system can effectively conserve water, as
          landscaping consumes about 30% of household water on average.
        </div>
        <ol class="padding_top ol_padding">
          <li>
            Get an efficient irrigation system installed by a professional.
            Consider a smart system that adjusts for weather conditions such as
            not watering when it is raining.
          </li>
          <li>
            Set your sprinkler angles correctly to provide your grass the right
            amount of water.
          </li>
          <li>
            Adjust watering frequency according to the season (4 times a year).
          </li>
          <li>
            Regularly check irrigation zones for leaks, low water pressure, and
            water waste.
          </li>
          <li>
            Install drip irrigation for smaller yards and watering specific
            plants. By providing water directly to the roots, it is more
            effective than a sprinkler system.
          </li>
        </ol>
      </div>
    </div>
    <div class="border flex">
      <div class="yard-costrange_savings border-right">
        $200-$3,000
        <div class="saving_text">
          Average Cost <span class="seperator">:</span>
        </div>
      </div>
      <div class="noise_rating">
        $25-$150/year
        <div class="saving_text">
          Average Savings <span class="seperator">:</span>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<!-- EnergyEfficiencyAppliances -->

<ng-container *ngIf="section === 'energyEfficiencyAppliances'">
  <ng-container
    *ngIf="activeTech === 'atticWeatherizationAndInsulation'"
    class="container"
  >
    <h2 class="tech_header">
      Attic Weatherization and Insulation
      <div class="desktop_display">
        <yt-star-rating [starCount]="2"></yt-star-rating>
      </div>
    </h2>
    <div class="mobile_display">
      <yt-star-rating [starCount]="2"></yt-star-rating>
    </div>
    <div class="yt__width">
      <div class="yt_content">
        <div>
          Weatherizing and insulating your attic can save you up to 15% in
          heating and cooling costs by keeping unwanted air from escaping or
          entering your home and preventing heat from entering or escaping
          through your ceiling.
        </div>
        <ol class="padding_top ol_padding">
          <li>
            Inspect the insulation level in your attic and check for any air
            leaks. You can get a professional evaluation done through a
            home-energy expert.
          </li>
          <li>
            Upgrade your attic by hiring a licensed contractor. To
            do-it-yourself (DIY), check out EnergyStar’s
            <a
              class="link_text"
              href="https://www.energystar.gov/campaign/seal_insulate/attic_insulation_project"
              target="_blank"
              >Seal and Insulate program</a
            >.
          </li>
          <li>
            If you qualify, consider the low-income weatherization programs
            found in many states. More information can be found
            <a
              class="link_text"
              href="https://www.energy.gov/eere/wap/how-apply-weatherization-assistance"
              target="_blank"
              >here</a
            >.
          </li>
        </ol>
      </div>
    </div>
    <div class="border flex">
      <div class="yard-costrange_savings border-right">
        $500-$4,000
        <div
          class="saving_text pointer tooltip_text"
          matTooltip="Cost will depend on the
        size of the attic and level of insulation."
        >
          Average Cost <span class="seperator">:</span>
        </div>
      </div>
      <div class="noise_rating">
        $50-$500/year
        <div class="saving_text">
          Average Savings <span class="seperator">:</span>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTech === 'doublePaneWindows'" class="container">
    <h2 class="tech_header">
      Double-pane Windows
      <div class="desktop_display">
        <yt-star-rating [starCount]="3"></yt-star-rating>
      </div>
    </h2>
    <div class="mobile_display">
      <yt-star-rating [starCount]="3"></yt-star-rating>
    </div>
    <div class="yt__width">
      <div class="yt_content">
        <div>
          Double-pane windows use two sheets of glass in a window frame with a
          tiny gap between the glass panes that acts as insulation. Benefits
          include:
        </div>
        <ol class="padding_top ol_padding">
          <li>
            Minimizing the transfer of hot/cool air, protecting from cold
            winters and hot summers.
          </li>
          <li>
            Dampening outdoor noise and eliminating the need for storm windows.
          </li>
          <li>Reducing sun damage to floors, carpet, and furniture.</li>
        </ol>
        <div class="padding_top">
          Recommendation: Choose Energy Star qualified windows for verified
          energy efficiency and more savings.
        </div>
      </div>
    </div>
    <div class="border flex">
      <div class="yard-costrange_savings border-right">
        $1,500-$12,000
        <div
          class="saving_text pointer tooltip_text"
          matTooltip="Cost will depend on
          the number and types of windows replaced."
        >
          Average Cost <span class="seperator">:</span>
        </div>
      </div>
      <div class="noise_rating">
        $50-$600/year
        <div class="saving_text">
          Average Savings <span class="seperator">:</span>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container
    *ngIf="activeTech === 'floorsAndExteriorWallsInsulation'"
    class="container"
  >
    <h2 class="tech_header">
      Floor and Exterior Wall Insulation
      <div class="desktop_display">
        <yt-star-rating [starCount]="3"></yt-star-rating>
      </div>
    </h2>
    <div class="mobile_display">
      <yt-star-rating [starCount]="3"></yt-star-rating>
    </div>
    <div class="yt__width">
      <div class="yt_content">
        <div>
          Your home’s floor or exterior wall may require insulation if you have
          an older home. Additional insulation will make your home more
          comfortable and reduce heating and cooling needs. Floor insulation
          might not be possible if the house is built on a concrete slab.
        </div>
        <ol class="padding_top ol_padding">
          <li>
            Included as part of a home-energy inspection, a professional can
            provide you with actionable recommendations.
          </li>
          <li>
            Consider hiring a licensed contractor for floor and wall insulation.
            This Energy Star
            <a
              class="link_text"
              href="https://www.energystar.gov/campaign/seal_insulate/basement_crawlspace"
              target="_blank"
              >guide</a
            >
            has further information on do-it-yourself (DIY) floor insulation.
          </li>
        </ol>
      </div>
    </div>
    <div class="border flex">
      <div class="yard-costrange_savings border-right">
        $1,000-$10,000
        <div
          class="saving_text pointer tooltip_text"
          matTooltip="Cost will depend on
        floor and wall space covered and insulation
        needed."
        >
          Average Cost <span class="seperator">:</span>
        </div>
      </div>
      <div class="noise_rating">
        $100-$700/year
        <div class="saving_text">
          Average Savings <span class="seperator">:</span>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTech === 'hvacDuctMaintenance'" class="container">
    <h2 class="tech_header">
      HVAC Duct Maintenance
      <div class="desktop_display">
        <yt-star-rating [starCount]="2"></yt-star-rating>
      </div>
    </h2>
    <div class="mobile_display">
      <yt-star-rating [starCount]="2"></yt-star-rating>
    </div>
    <div class="yt__width">
      <div class="yt_content">
        <div>
          Poorly insulated or leaky air duct in your HVAC (heating, ventilation,
          and air conditioning) system can contribute to high energy bills. If
          you are building a new home, placing duct in a conditioned space will
          reduce energy losses.
        </div>
        <ol class="padding_top ol_padding">
          <li>
            Included as part of a home-energy inspection, a professional can
            provide you with actionable recommendations for upgrading or
            repairing your HVAC duct.
          </li>
          <li>
            Learn more about working with a contractor or do-it-yourself
            guidelines on duct improvement projects at the energystar.gov
            website
            <a
              class="link_text"
              href="https://www.energystar.gov/campaign/heating_cooling/duct_sealing"
              target="_blank"
              >here</a
            >.
          </li>
        </ol>
      </div>
    </div>
    <div class="border flex">
      <div class="yard-costrange_savings border-right">
        $300-$4,000
        <div class="saving_text">
          Average Cost <span class="seperator">:</span>
        </div>
      </div>
      <div class="noise_rating">
        $50-$400/year
        <div class="saving_text">
          Average Savings <span class="seperator">:</span>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
