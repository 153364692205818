import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "yt-roof-shade-answer-box",
  templateUrl: "roof-shade-answer-box.component.html",
  styleUrls: ["./roof-shade-answer-box.component.scss"],
})
export class RoofShadeAnswerBoxComponent implements OnInit {
  @Input() image: string;
  @Input() displayValue: string;
  @Input() subDisplayValue: string;
  @Input() selectedValue: boolean;
  subValuePadding: boolean = true;
  ngOnInit() {
    if (this.subDisplayValue !== undefined) {
      this.subValuePadding = false;
    }
  }
}
