import { CommonModule } from "@angular/common";
import { MatMenuModule } from "@angular/material/menu";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HomeOwnerDashboardRoutingModule } from "./homeowner-dashboard-routing.module";
import { ScrollTopModule } from "../shared/scroll-top/index";
import { LifestyleBoxModule } from "../shared/lifestyle-box/index";
import { CalculateMilesModule } from "../shared/calculate-miles/index";
import { RoofTopModule } from "../shared/roof-top/index";
import { DialogOverviewModule } from "../shared/dialog-overview/index";
import { EnergyInfoModule } from "../shared/energy-info/index";
import { HelpMeChooseModule } from "./shared/help-me-choose/index";
import { MarketPlaceCardModule } from "./shared/market-place-card";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatRadioModule } from "@angular/material/radio";
import { MatTabsModule } from "@angular/material/tabs";
import { MatChipsModule } from "@angular/material/chips";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatCardModule } from "@angular/material/card";
import { BackButtonModule } from "./shared/back-button";
import { DashboardFooterModule } from "./shared/dashboard-footer";
import { DashboardV2Module } from "./overview/dashboard-v2";
import { CompareFinancialModule } from "./shared/compare-financials";
import { LoaderModule } from "./shared/loader";
import { TranslateModule } from "@ngx-translate/core";
import { LearningHubModule } from "./learning-hub";
import { AdviceCardModule } from "./shared/electrification-advice-card";
import { FAQOverlayModule } from "./faq-overlay";
import { LMNavigatorModule } from "./shared/scroll-link-toggle";
import { DashboardV3Module } from "./overview/dashboard-v3";
import { EntriesPipeModule } from "../utils/entries.pipe";

export const HomeOwnerDashboardImports = [
  CommonModule,
  HomeOwnerDashboardRoutingModule,
  MatMenuModule,
  MatButtonModule,
  MatIconModule,
  MatSidenavModule,
  MatInputModule,
  MatFormFieldModule,
  MatTooltipModule,
  MatButtonToggleModule,
  MatSelectModule,
  MatCheckboxModule,
  MatSnackBarModule,
  MatDialogModule,
  MatSlideToggleModule,
  MatExpansionModule,
  MatRadioModule,
  MatTabsModule,
  MatChipsModule,
  MatCardModule,
  FormsModule,
  ReactiveFormsModule,
  ScrollTopModule,
  LifestyleBoxModule,
  CalculateMilesModule,
  RoofTopModule,
  DialogOverviewModule,
  EnergyInfoModule,
  HelpMeChooseModule,
  CompareFinancialModule,
  MarketPlaceCardModule,
  BackButtonModule,
  DashboardFooterModule,
  DashboardV2Module,
  DashboardV3Module,
  LoaderModule,
  LearningHubModule,
  TranslateModule,
  AdviceCardModule,
  FAQOverlayModule,
  LMNavigatorModule,
  EntriesPipeModule
];
