<div class="yt_border" [ngClass]="{ 'active-box': default === value }">
  <div class="image-box">
    <div class="icon_section">
      <mat-icon
        class="radio-icons mat-symbol-oulined"
        [ngClass]="{ 'active-icon': default === value }"
      >
        {{ icon }}
      </mat-icon>
    </div>
    <div class="yt__font pl0">{{ viewValue }}</div>
  </div>
</div>
