<scroll-top></scroll-top>
<div class="page_container" (window:resize)="onResize()">
  <div class="yt_width">
    <div class="yt__info-header">Current and future energy choices</div>
    <div class="yt__info-sub-header">
      Indicate choices you already own or are considering &nbsp;
      <span class="renterText" (click)="openPopup()" *ngIf="showPopup"
        >What choices apply to me?</span
      >
    </div>

    <div id="myNav" class="overlay">
      <tf-lifestyle-overlay
        (closeOverlay)="closeNav()"
        [header]="lifestyleHeader"
        [back]="onACBack"
        [subHeader]="lifestyleSubHeader"
        (openHPSC)="onSaveAC()"
      >
        <yt-solar *ngIf="showSolar" (saveSolar)="onSaveSolar()"></yt-solar>
        <yt-community-solar
          *ngIf="showCommunitySolar"
          (saveCommunitySolar)="onSaveCommunitySolar()"
        ></yt-community-solar>
        <yt-ev *ngIf="showEV" (saveEV)="onSaveEv()"></yt-ev>
        <yt-ac *ngIf="showAC" (saveAC)="onSaveAC()"></yt-ac>
        <yt-spa *ngIf="showSPA" (saveSPA)="onSaveSPA()"></yt-spa>
        <yt-pool *ngIf="showPOOL" (savePOOL)="onSavePOOL()"></yt-pool>
        <yt-battery
          *ngIf="showBATTERY"
          (saveBATTERY)="onSaveBATTERY()"
        ></yt-battery>
        <yt-hpwh *ngIf="showHPWH" (saveHPWH)="onSaveHPWH()"></yt-hpwh>
        <yt-ic *ngIf="showIC" (saveIC)="onSaveIC()"></yt-ic>
        <yt-hpd *ngIf="showHPD" (saveHPD)="onSaveHPD()"></yt-hpd>
        <yt-hpsc *ngIf="showHPSC" (saveHPSC)="onSaveHPSC()"> </yt-hpsc>
        <yt-e-bike *ngIf="showEBike" (saveEBike)="onSaveEBike()"></yt-e-bike>
      </tf-lifestyle-overlay>
    </div>

    <div *ngIf="energyChoiceTypes.powerGeneration" id="1" data-scroll-to="2">
      <div class="yt_subheader">
        <div *ngIf="vendorLifeStyle">
          <span *ngIf="vendorLifeStyle.solarTabStatus"
            >Renewable Energy and
          </span>
          Battery Storage
        </div>
        <span class="nextchoice" *ngIf="!renterSteppar">1 of 4</span>
        <span class="nextchoice" *ngIf="renterSteppar">1 of 3</span>
      </div>
      <div class="yt_boxWidth" *ngIf="vendorLifeStyle">
        <yt-lifestyle-box
          *ngIf="vendorLifeStyle.solarTabStatus"
          [tagline]="'Power My Home'"
          [technologyName]="'Solar'"
          (click)="onSolar()"
          [image]="'assets/lifestyle/solar.svg'"
          [savings]="'Save up to $100,000'"
          [visited]="solarVisited"
        >
        </yt-lifestyle-box>
        <yt-lifestyle-box
          *ngIf="vendorLifeStyle.batteryTab"
          [tagline]="'Plan for Outages'"
          [savings]="'Backup protection'"
          [technologyName]="techName_bat"
          [image]="bat_image"
          [visited]="batteryVisited"
          [matTooltip]="
            disableTooltip
              ? ''
              : 'Back up power to run your critical appliances during a power outage.'
          "
          (click)="onBATTERY()"
        >
        </yt-lifestyle-box>
      </div>
      <div class="yt_continue_button" *ngIf="!energyChoiceTypes.transportation">
        <button
          id="continue"
          mat-raised-button
          color="accent"
          (click)="onPowerGeneration()"
          class="continue-button"
        >
          NEXT CHOICE
        </button>
      </div>
    </div>

    <div *ngIf="energyChoiceTypes.transportation" id="2" data-scroll-to="3">
      <div
        class="yt_subheader"
        [ngStyle]="{ 'padding-top': isSUNW ? '0' : '40px' }"
      >
        <div>Clean Transportation</div>
        <span class="nextchoice" *ngIf="!steppar && !renterSteppar"
          >2 of 4</span
        >
        <span class="nextchoice" *ngIf="steppar">1 of 3</span>
        <span class="nextchoice" *ngIf="renterSteppar">2 of 3</span>
      </div>
      <div class="yt_boxWidth" *ngIf="vendorLifeStyle">
        <yt-lifestyle-box
          *ngIf="vendorLifeStyle.evTab"
          [tagline]="'Electrify My Ride'"
          [technologyName]="'Electric Vehicle'"
          [image]="'assets/lifestyle/ev1.svg'"
          [savings]="'Save up to $40,000'"
          [visited]="evVisited"
          [matTooltip]="
            disableTooltip
              ? ''
              : 'Clean and cheap transportation from a power plug.'
          "
          (click)="onEV()"
        >
        </yt-lifestyle-box>
        <yt-lifestyle-box
          *ngIf="vendorLifeStyle.eBikeTab"
          [tagline]="'Feel the E-Motion'"
          [technologyName]="'Electric Bike'"
          [image]="'assets/lifestyle/eBike.png'"
          [savings]="'Leave the car behind'"
          [visited]="ebikeVisited"
          [matTooltip]="
            disableTooltip
              ? ''
              : 'Fun, low-carbon transportation option that will get you moving again.'
          "
          (click)="onEBike()"
        >
        </yt-lifestyle-box>
      </div>
      <div class="yt_continue_button" *ngIf="!energyChoiceTypes.homeAppliances">
        <button
          id="continue"
          mat-raised-button
          color="accent"
          (click)="onTransportation()"
          class="continue-button"
        >
          NEXT CHOICE
        </button>
      </div>
    </div>

    <div *ngIf="energyChoiceTypes.homeAppliances" id="3" data-scroll-to="4">
      <div class="yt_subheader" style="padding-top: 40px">
        <div>Efficient Home Appliances</div>
        <span class="nextchoice" *ngIf="!steppar && !renterSteppar"
          >3 of 4</span
        >
        <span class="nextchoice" *ngIf="steppar">2 of 3</span>
        <span class="nextchoice" *ngIf="renterSteppar">3 of 3</span>
      </div>
      <div class="yt_boxWidth" *ngIf="vendorLifeStyle">
        <yt-lifestyle-box
          *ngIf="vendorLifeStyle.hpwhTab"
          [tagline]="'Super Efficient'"
          [technologyName]="'Water Heater'"
          [image]="'assets/lifestyle/hpwh.svg'"
          [savings]="'Save up to $3,000'"
          [visited]="hpwhVisited"
          [matTooltip]="
            disableTooltip
              ? ''
              : 'Highly efficient electric water heater that cuts your carbon footprint.'
          "
          (click)="onHPWH()"
        >
        </yt-lifestyle-box>
        <yt-lifestyle-box
          *ngIf="vendorLifeStyle.hpscTab"
          [tagline]="'Stay Comfortable'"
          [technologyName]="'Heating & Cooling'"
          [image]="'assets/lifestyle/ashp.svg'"
          [savings]="'Up to 600 trees planted'"
          [visited]="hpscVisited"
          (click)="onHPSC()"
          [matTooltip]="
            disableTooltip
              ? ''
              : 'Highly efficient electric system that can both heat & cool your home.'
          "
        >
        </yt-lifestyle-box>
        <yt-lifestyle-box
          *ngIf="vendorLifeStyle.icTab"
          [tagline]="'Enjoy Cooking'"
          [technologyName]="techName_ic"
          [image]="ic_image"
          [savings]="'Cut indoor pollution'"
          [visited]="icVisited"
          [matTooltip]="
            disableTooltip
              ? ''
              : 'Faster and safer cooktop providing significantly better indoor air quality.'
          "
          (click)="onIC()"
        >
        </yt-lifestyle-box>
        <yt-lifestyle-box
          *ngIf="vendorLifeStyle.hpdTab"
          [tagline]="'Dry Efficiently'"
          [technologyName]="'Clothes Dryer'"
          [image]="'assets/lifestyle/dryer.svg'"
          [savings]="'Up to 100 trees planted'"
          [visited]="hpdVisited"
          [matTooltip]="
            disableTooltip
              ? ''
              : 'Highly efficient electric dryer that cuts your carbon footprint.'
          "
          (click)="onHPD()"
        >
        </yt-lifestyle-box>
      </div>
      <div class="yt_continue_button" *ngIf="!energyChoiceTypes.poolSpa">
        <button
          id="continue"
          mat-raised-button
          color="accent"
          (click)="onAppliances()"
          class="continue-button"
        >
          {{ appliancesButtonText }}
        </button>
      </div>
    </div>

    <div *ngIf="energyChoiceTypes.poolSpa" id="4">
      <div class="yt_subheader" style="padding-top: 40px" *ngIf="haveAcPoolSpa">
        <div>AC, Pool and Spa</div>
        <span class="nextchoice" *ngIf="!steppar">4 of 4</span>
        <span class="nextchoice" *ngIf="steppar">3 of 3</span>
      </div>
      <div class="yt_boxWidth" *ngIf="vendorLifeStyle">
        <yt-lifestyle-box
          *ngIf="vendorLifeStyle.acTab"
          [tagline]="'Air Conditioner'"
          [visited]="acVisited"
          (click)="onAC()"
          [image]="'assets/lifestyle/AC.png'"
        >
        </yt-lifestyle-box>
        <yt-lifestyle-box
          *ngIf="vendorLifeStyle.poolTab"
          [tagline]="'Swimming Pool'"
          [visited]="spVisited"
          (click)="onSP()"
          [image]="'assets/lifestyle/pool.png'"
        >
        </yt-lifestyle-box>
        <yt-lifestyle-box
          *ngIf="vendorLifeStyle.spaTab"
          [tagline]="'Spa'"
          [image]="'assets/lifestyle/spa.png'"
          [visited]="spaVisited"
          (click)="onSPA()"
        >
        </yt-lifestyle-box>
      </div>
      <div class="yt_continue_button" id="final-continue">
        <button
          mat-raised-button
          color="accent"
          (click)="checKPopupHPWHStatus()"
          class="continue-button"
        >
          CONTINUE
        </button>
      </div>
    </div>
  </div>
  <yt-footer class="footer_container"></yt-footer>
</div>

<div *ngIf="buildingTypePopup" id="overlay" #userApplicableChoices>
  <div id="text">
    <div class="yt_popup-header padding_all">What choices apply to me?</div>
    <div class="padding_all" *ngIf="buildingTypeRenter">
      All the choices except the ones under Clean Transportation would require
      landlord support and also may not be feasible for renters. We show these
      choices for educational purposes.
    </div>
    <div class="padding_all" *ngIf="buildingTypeCondo">
      All the choices except the ones under Clean Transportation might require
      Homeowners Association support and also may not be feasible for
      condominiums or mobile homes. We show these choices for educational
      purposes.
    </div>
  </div>
</div>

<div *ngIf="appliancesPopup" id="overllay" [formGroup]="appliancesForm">
  <div id="info" class="">
    <div class="popUp_header">More information on Home Appliances</div>
    <div class="popUp_subheader">
      This will allow us to provide better recommendations
    </div>
    <div class="fields">
      <div class="w-100 w-60-m w-40-ns energy-choices" *ngIf="showICType">
        <div class="tech_type">Current Cooktop Type</div>
        <mat-form-field class="form_fields" appearance="outline">
          <mat-select formControlName="icType" required>
            <mat-option
              *ngFor="let type of applianceType"
              [value]="type.value"
              >{{ type.viewValue }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div
        class="w-100 w-60-m w-40-ns ml0-m energy-choices"
        [ngClass]="{ 'ml5-ns': showICType }"
        *ngIf="showHPDType"
      >
        <div class="tech_type">Current Dryer Type</div>
        <mat-form-field class="form_fields" appearance="outline">
          <mat-select formControlName="hpdType" required>
            <mat-option
              *ngFor="let type of applianceType"
              [value]="type.value"
              >{{ type.viewValue }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="yt_dialogContinueButton dialogBox" id="5" data-scroll-to="4">
      <button
        mat-raised-button
        color="accent"
        (click)="offPopup()"
        [disabled]="appliancesForm.invalid"
        class="continue-button"
      >
        SAVE
      </button>
    </div>
  </div>
</div>
