<div class="yt_width">
  <div class="overlay-content tl">
    <mat-toolbar style="margin-bottom: 1.5rem;">
      <span class="closebtn pointer white" (click)="closeNav()">
        <mat-icon class="example-icon yt_blue-color">chevron_left</mat-icon>
        <span class="back-text yt_blue-color">Back</span>
      </span>
    </mat-toolbar>
    <div class="cf yt_panel_width" [ngClass]="{'yt__ev-header_ma': header !== '' }">
      <div class="flex items-center justify-center">
        <!-- <div class="fl dn db-ns center "> -->
          <!-- <img [src]="image" class="yt_icon-size pr3"> -->
        <!-- </div> -->
        <!-- <div class="fl"> -->
          <div class="yt__info-header">{{header}}</div>
          <!-- <div class="tundora f3 yt__lh-1-15 yt__w-100" *ngIf="subHeader"> {{subHeader}}</div> -->
        <!-- </div> -->
      </div>
    </div>
    <div class="yt_panel_width">
      <ng-content></ng-content>
    </div>
  </div>
</div>