<div class="faq_container">
  <div class="about_technology" *ngIf="showAboutTech">
    <div class="header">Overview</div>
    <p>
      <strong>Types of EVs:</strong> All-electric vehicles (also called
      battery-electric vehicles, BEVs) are powered solely by electricity, while
      plug-in hybrid EVs (PHEVs) can run on either electricity or
      gasoline/petrol. When they run out of electricity, PHEVs switch to the
      backup fuel for their energy source.
      <span
        class="yt_content link_text"
        *ngIf="showReadMore"
        (click)="onReadContent('readMore')"
      >
        Read more...
      </span>
    </p>
    <div *ngIf="showReadLess">
      <p class="pt3">
        <strong>Driving Range:</strong> Many BEVs are available with driving
        ranges from about 115 miles to well over 400 miles (185 to 645 km).
        PHEVs have smaller batteries and can normally travel between 15 – 55
        miles (25 – 90 km) on a full charge.
      </p>

      <p class="pt3">
        <strong>Upfront Cost:</strong> EVs have higher upfront costs than
        equivalent gasoline/petrol vehicles, but incentives are sometimes
        available that make them more affordable.
      </p>

      <p class="pt3">
        <strong>Total Cost:</strong> They are generally more cost effective than
        gasoline/petrol vehicles when you consider the total cost of ownership.
        This is partly because electricity is cheaper per mile traveled and
        maintenance costs are lower.
      </p>

      <p class="pt3">
        <strong>Environment:</strong> A typical gasoline/petrol-powered car can
        produce more than 4 tonnes of CO2 emissions per year. By driving an EV,
        you can easily cut these emissions by half or more.
        <span class="link_text" (click)="onReadContent('readLess')">
          Read less
        </span>
      </p>
    </div>
  </div>
  <div *ngIf="!showAboutTech">
    <div *ngIf="isActive(0)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. Why consider electrifying your ride?</h5>
        <p class="answer_text">
          Once you drive an electric vehicle (EV), you’ll see why EV owners love
          their cars. They perform better, are cheaper to operate, and require
          very little maintenance. Instead of paying high costs for gasoline, EV
          owners generally charge with cleaner energy where they live, at work,
          or somewhere in their neighborhoods. Plus, an EV can dramatically
          reduce harmful emissions, helping to create a healthier future.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. What are electric vehicles (EVs) and plug-in hybrid electric
          vehicles (PHEVs)?
        </h5>
        <p class="answer_text">
          While most vehicles are fueled solely by gasoline, an EV is powered by
          electricity. There are two main types of EVs. An all-electric vehicle
          (also known as a battery electric vehicle, or BEV) is powered solely
          by electricity. Another type of EV, the plug-in hybrid EV (PHEV), can
          run on both electricity and on gasoline. PHEVs normally have smaller
          batteries and shorter electric ranges (such as 15 – 55 miles) than
          BEVs. When they run out of electricity, they switch to gasoline for
          their energy source. This means that a PHEV will often operate on its
          electric motor while driving around town and on its gasoline engine
          for longer trips.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          3. Are hybrid electric vehicles (such as the original Toyota Prius)
          electric vehicles?
        </h5>
        <p class="answer_text">
          No. Pure hybrid electric vehicles get all their energy from gasoline —
          they cannot be manually recharged like battery electric vehicles or
          plug-in hybrid EVs. Pure hybrids use a small battery and an internal
          combustion engine in combination that allows for greater efficiency.
          This, along with regenerative braking and other features, allows pure
          hybrids to achieve much better mileage and lower emissions than
          conventional gasoline vehicles.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          4. How far can an electric vehicle drive after a full charge?
        </h5>
        <p class="answer_text">
          A host of battery electric vehicles (BEVs) are available with ranges
          varying from about 115 miles to well over 400 miles. Those with
          shorter ranges are fine for local and city driving. Those with longer
          ranges can be used for long trips, especially given the increasing
          availability of fast-charging stations in many areas. Plug-in hybrid
          EVs (PHEVs) have smaller battery packs than BEVs and can normally
          travel between 15 – 55 miles on a full charge. However, they have
          gasoline backup for longer drives, so the range is much less of an
          issue.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(1)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. Are EVs expensive?</h5>
        <p class="answer_text">
          EVs generally have a higher up-front cost than gasoline vehicles, but
          many EV models are available in the US today for under $47,000 which
          is the national average new car sales price. Plus, state, federal, and
          utility incentives can take thousands of dollars off the price, making
          them even more affordable. Importantly, looking at the total cost of
          ownership, including the purchase cost (or lease cost), rebates and
          tax incentives, fuel costs, maintenance, insurance, etc., EVs can be
          cheaper than gasoline-powered vehicles without the hassle of going to
          the gas station and without generating local air emissions.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. What’s the energy cost to operate an EV?
        </h5>
        <p class="answer_text">
          Gasoline is not only more polluting than electricity, it’s also more
          expensive. You’ll save on energy costs by switching to an EV. The
          level of savings will depend on a number of factors such as your
          electricity rates, your local gasoline costs, and your type of car.
          However, using average costs in the US, a dollar of electricity will
          take you more than twice as many miles as a dollar of gasoline.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(2)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. Are electric vehicles dependable?</h5>
        <p class="answer_text">
          Since EVs have many fewer moving parts than a gasoline vehicle, they
          are significantly more reliable. In addition, they require much less
          maintenance because there’s no need to change the oil, spark plugs,
          etc. Furthermore, with regenerative braking, your EV’s brakes will
          last longer.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">2. How long do EV batteries last?</h5>
        <p class="answer_text">
          Most EV manufacturers guarantee their batteries for up to 8 years or
          100,000 miles and Tesla has reportedly designed its batteries to last
          for 300,000 to 500,000 miles. With any EV, there will be some battery
          degradation over time, and this translates into reduced range. For
          example, an EV with a 300 mile original range and 20% battery
          degradation after 10 years (which is typical), is still a 240 mile
          range EV and perfectly usable.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(3)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. How much do cars contribute to emissions?
        </h5>
        <p class="answer_text">
          The vast majority of transportation emissions in the US and around the
          world come from cars and trucks, and nearly 60% of those emissions are
          from the cars that we drive every day. The typical gasoline-powered
          car will produce 4-8 tons of CO2 per year, so the purchase of this
          type of car generally means that 75 tons of additional emissions will
          be released into the atmosphere over an average car’s lifespan. An EV
          can cut these emissions in half, or much more if you charge your EV
          with electricity that’s largely produced from solar, wind, or
          hydropower.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. What about the batteries? Are there emissions from their
          manufacture, and are they toxic?
        </h5>
        <p class="answer_text">
          Battery manufacture does result in additional emissions, but these are
          generally made up for in about a year due to the lower driving
          emissions of an EV. Regarding toxicity, the predominant battery type
          currently used in EVs (Lithium-ion), contains materials that can be
          hazardous if released into the environment. Accordingly, as with all
          types of batteries, proper recycling is required at the end of life,
          and recycling is now becoming more available for these types of
          batteries. Additionally, once aging batteries are no longer suited for
          EVs, they can often be repurposed in “second-life” stationary storage
          applications. Finally, the EV industry is working on solid-state
          batteries that should be smaller, safer, and longer-lived than
          conventional Lithium-ion batteries.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          3. Will EVs require the construction of additional power plants?
        </h5>
        <p class="answer_text">
          Having more EVs/PHEVs on the road doesn’t necessarily mean more power
          plants will be needed. It mostly depends on when the vehicles are
          charged. Many conventional plants are only used during peak-demand
          periods and are idle during off-peak periods (such as at night).
          Charging EVs when excess generation capacity is available can reduce
          the need for new plant construction. In addition, with emerging
          vehicle-to-grid (V2G) approaches, electricity stored in vehicle
          batteries can be used by the grid during peak-demand hours, further
          reducing the need for new plants and also making the grid more stable.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          4. How do the overall emissions compare to gasoline cars when taking
          power plant emissions into account?
        </h5>
        <p class="answer_text">
          An EV’s emissions will vary regionally, depending on the source of the
          electricity used to charge it. On average, however, EVs in the US
          produce 50 – 60% less emissions than gasoline powered vehicles, even
          when taking into account the production of the batteries. Also, with
          the increasing use of renewable electricity from sources such as the
          sun and wind (which have no greenhouse gas emissions from operations),
          the grid-supplied electricity used to power most EVs is getting
          cleaner all the time. Of course, when charged with 100% renewable
          energy, such as from rooftop solar, an EV will produce zero driving
          emissions.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          5. What else can I do to reduce my transportation emissions?
        </h5>
        <p class="answer_text">
          Steps like taking public transportation, riding a bike (or an electric
          bike), and reducing your air travel are additional great ways to
          reduce transportation emissions. These approaches can also produce
          other benefits such as lower costs, reduced traffic congestion,
          shorter commute times, and improved fitness.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(4)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How quickly can you charge an EV?</h5>
        <p class="answer_text">
          The rate at which you can charge an EV depends on the car and the type
          of charger. Chargers are categorized as Level 1, Level 2, or Level 3.
          Level 1 charging means charging from a typical 120V home electrical
          outlet and will usually only deliver 2 – 6 miles of charge per hour of
          charging, which is fine for many home charging situations.
        </p>
        <p class="pt3 answer_text">
          Level 2 charging involves charging from a 240V circuit, which usually
          delivers 10 – 60 miles of charge per hour of charging. This type of
          circuit can be installed in your home if you need that charging speed.
          This is the type of charger typically available at most commercial
          chargers and works for all EVs. Most charging stations that businesses
          supply for employees are Level 2, and these are also often available
          at public parking lots and garages.
        </p>
        <p class="pt3 answer_text">
          Level 3 charging is also called fast charging and uses a 480V circuit.
          This technology can provide as much as 200 miles of range in 30
          minutes or less, depending on the vehicle and the charger. This level
          is only available at commercial charging stations and is not always
          compatible with all vehicle types.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">2. How do I charge an EV at home?</h5>
        <p class="answer_text">
          Many people worry that they will have to put in an expensive charger
          to power their EV. This is an option but not usually required. Your
          car will come with a portable mobile charger that can be plugged into
          any 120V wall socket as long as no other large appliances use that
          circuit. This type of charger will typically provide only about 2 – 6
          miles of battery charge for every hour the car is plugged in. This
          will usually be plenty to cover most daily driving situations since
          most cars are driven less than 35 miles per day in the US. If you need
          more than that, a 240V circuit needs to be available in or near the
          garage or driveway. This allows for 10 – 60 miles of charging per
          hour.
        </p>
        <p class="pt3 answer_text">
          Wall-mounted charging stations are sometimes installed at homes, but
          these are not required. Instead, you can use a portable charger that
          plugs into a 240V wall socket. On the car side, all connectors use the
          standard J connector and so there are no compatibility issues. (Teslas
          have an adapter that allows for the use of a J connector.)
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          3. How can I charge an EV if I live in an apartment building or am on
          a long trip?
        </h5>
        <p class="answer_text">
          An increasing number of apartment buildings are installing chargers,
          often at the request of their tenants. For those tenants without this
          option, charging at work is sometimes a good approach. Chargers at
          office complexes are normally Level 2 chargers that can add 10 – 60
          miles of charging per hour. Another alternative is to find a Level 3,
          fast charger nearby, such as at a shopping center. Often you can
          charge up while buying groceries, taking in a movie, or working out at
          the gym.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">4. How can I charge an EV on a long trip?</h5>
        <p class="answer_text">
          This is becoming easier all the time. More EVs are becoming available
          with ranges of 200 – 350 miles and more fast charging stations are
          being deployed every month. Tesla has done a great job of installing
          Superchargers along major highways in most parts of the US. They are
          strategically located so that it is possible to travel very long
          distances with just a few stops along the way. Other fast charging
          stations are also quite easy to find along major highways. Smartphone
          apps are available to help drivers locate chargers near their homes or
          along the route of an upcoming road trip.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(5)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. What types of incentives are available for EVs?
        </h5>
        <p class="answer_text">
          Predominant among these is the federal tax credit of up to $7,500
          that’s available for many EVs. Various other incentives are also
          available at the state, local, and utility levels. Taken together,
          incentives can take thousands of dollars off the sticker price, making
          them much more affordable.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. How does the federal tax credit for EVs work?
        </h5>
        <p class="answer_text">
          The tax credit ranges up to $7,500 for the purchase of a new EV or
          PHEV, up to the purchaser’s tax liability. The credit doesn’t apply to
          leased vehicles and the dollar amount depends on the make and model of
          the vehicle. This credit comes directly off the taxes you would other
          side pay — it’s not a tax deduction. To claim the credit, you’ll need
          to fill out IRS Form 8936, Qualified Plug-in Electric Drive Motor
          Vehicle Credit. As always, it’s wise to consult with a tax
          professional when dealing with tax issues
        </p>
      </div>
    </div>
  </div>
</div>
