<div class="request-quote-box">
  <div class="flex_box">
    <div class="shoppingImage-box">
      <img src="assets/dashboard/overview/marketPlace.png" width="100%" />
    </div>
    <div class="marketplace_text_button">
      <div class="findTech_text">
        {{ marketPlaceDetails.boxText }}
      </div>
      <div class="request_button">
        <button
          mat-raised-button
          (click)="onStartShopping(marketPlaceDetails.buttonLink)"
          color="accent"
        >
          {{ marketPlaceDetails.buttonText }}
        </button>
      </div>
    </div>
  </div>
</div>
