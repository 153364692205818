import { MatCardModule } from "@angular/material/card";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AnswerBoxComponent } from "./answer-box.component";

@NgModule({
  imports: [CommonModule, MatCardModule],
  exports: [AnswerBoxComponent],
  declarations: [AnswerBoxComponent],
})
export class AnswerBoxModule {}
