<scroll-top></scroll-top>
<div class="page-container yt__learn_more">
  <yt-back [navigateTo]="'overview'"></yt-back>
  <div class="padding-back-button w-100 center">
    <div class="yt_maxWidth w-100 center">
      <div class="header_text tc">Electric Bike</div>
      <yt-scroll-link-toggle
        *ngIf="!isLangSpanish"
        [activeLink]="selectedSection"
        [isAboutTech]="true"
        [isSavings]="showHighChart"
        [isDescription]="!showHighChart"
        [isSettings]="true"
        [isDetails]="true"
        [isAssumptions]="!!assumptionDetails"
        [isFaq]="true"
        [techParameter]="'eBike'"
        (activeValue)="handleActiveValue($event)"
      ></yt-scroll-link-toggle>
      <div>
        <div
          class="yt-text"
          [innerHTML]="
            'homeOwenerDashboard.learnMore.eBike.paragraph' | translate
          "
        ></div>
        <div *ngIf="!isLangSpanish" id="aboutTechnology">
          <yt-ebike-FAQ [showAboutTech]="true"> </yt-ebike-FAQ>
        </div>
        <div class="faq_mobile">
          <button
            (click)="onFavorite()"
            class="favorite_btn"
            mat-raised-button
            color="accent"
          >
            <mat-icon
              class="favorite_icon"
              [ngClass]="{ favorite_icon_color: favStatus }"
              >{{ favIcon }}</mat-icon
            >FAVORITE
          </button>
          <button
            *ngIf="isLangSpanish"
            class="faq_button"
            mat-raised-button
            color="accent"
            (click)="handleFAQClick()"
          >
            MORE INFO
          </button>
        </div>
      </div>

      <div class="top_section w-100">
        <div class="faq_section">
          <div class="image_box">
            <img
              src="assets/lifestyle/eBike.png"
              loading="lazy"
              alt="electric bike"
              [ngStyle]="{ height: !displayMarketPlaceBox ? '160px' : '250px' }"
            />
          </div>
          <button
            (click)="onFavorite()"
            class="favorite_btn mt_20"
            mat-raised-button
            color="accent"
          >
            <mat-icon
              class="favorite_icon"
              [ngClass]="{ favorite_icon_color: favStatus }"
              >{{ favIcon }}</mat-icon
            >FAVORITE
          </button>
          <button
            *ngIf="isLangSpanish"
            class="faq_button mt_20"
            mat-raised-button
            color="accent"
            (click)="handleFAQClick()"
          >
            MORE INFO
          </button>
        </div>

        <div class="width-box" id="cost">
          <div class="main_values_box">
            <div class="details_header pb_14">Cost</div>
            <div class="top_values">
              <div>
                <div class="main_value_text" *ngIf="eBikeViewDetails">
                  ${{ financialDetails.eBikeCost.netCost | number }}
                  <div class="value_text">Price after incentives</div>
                </div>
              </div>
            </div>

            <div class="bottom_values">
              <div class="left_bottom_value">
                <div>
                  <div class="value" *ngIf="eBikeViewDetails">
                    ${{ financialDetails.eBikeCost.upfrontCost | number }}
                  </div>
                  <div class="value_label">
                    <span
                      class="pointer tooltip_text"
                      matTooltip="Includes typical price for an electric bike before consideration of any incentives."
                    >
                      Price
                    </span>
                  </div>
                </div>
              </div>
              <div class="right_bottom_value">
                <div class="value" *ngIf="eBikeViewDetails">
                  ${{ financialDetails.eBikeCost.incentives | number }}
                </div>
                <div class="value_label">
                  <mat-icon class="hidden_tooltip">help_outline</mat-icon>
                  <span
                    *ngIf="showIncentivesStatus"
                    class="link_text pointer"
                    (click)="showIncentiveBreakdown = true"
                    >Incentives
                  </span>
                  <span *ngIf="!showIncentivesStatus">Incentives </span>
                  <mat-icon
                    class="tooltip_icon"
                    matTooltip="Available tax credits and rebates."
                  >
                    help_outline
                  </mat-icon>
                </div>
              </div>
            </div>
          </div>

          <yt-market-place
            [tech_name]="'eBike'"
            [linkId]="linkId"
            [marketPlaceDetails]="marketPlaceDetails"
            *ngIf="marketPlaceDetails && displayMarketPlaceBox"
          ></yt-market-place>
          <div class="main_values_box advice_box" *ngIf="adviceCardStatus">
            <yt-electrification-advice-card
              [tech]="'eBike'"
            ></yt-electrification-advice-card>
          </div>
        </div>
      </div>

      <div class="ebike_bottom_section w-100">
        <div class="left_section">
          <div
            class="highChart_card highChart_card_mobile"
            id="savingsMobile"
            *ngIf="showHighChart"
          >
            <div class="details_header pb_14">Savings</div>
            <div class="w-100 toggle_buttons">
              <mat-button-toggle-group
                [(value)]="selected"
                (change)="updateChart($event.value)"
              >
                <mat-button-toggle
                  *ngFor="let time of timeSegmentList"
                  [value]="time.value"
                >
                  <span class="toggle_label">{{
                    time.viewValue?.toUpperCase()
                  }}</span></mat-button-toggle
                >
              </mat-button-toggle-group>
            </div>
            <div class="w-100 savings_text">
              Your
              <span>{{ timeFrame }}</span> projected
              <span
                class="tooltip_text_border pointer"
                *ngIf="showTooltipZero"
                style="border-color: tomato"
                matTooltip="When the displaced vehicle miles are zero, there are no electric bike savings. Also, 
                  when both an electric vehicle and solar are selected, any savings associated with an electric bike are 
                  included in with the electric vehicle savings."
              >
                energy savings
              </span>
              <span *ngIf="!showTooltipZero">energy savings</span>
              is
              <span
                class="savings_value"
                [ngClass]="{ value_color: eBike_Savings < 0 }"
                >{{ eBike_Savings | currency : "$" : "symbol" : "1.0-0" }}</span
              >
            </div>
            <div class="w-100 chart_section">
              <div class="mobile_chart_text">
                To view and compare graphs of energy costs, please login to your
                dashboard using a desktop.
              </div>
            </div>
          </div>
          <div
            class="highChart_card highChart_card_mobile w-100"
            *ngIf="!showHighChart"
            id="descriptionMobile"
          >
            <div class="details_header">DESCRIPTION</div>
            <div class="yt_text">{{ noChartText }}</div>
          </div>
          <div class="details_card" [formGroup]="eBikeForm" id="settings">
            <div class="details_header">PERSONALIZE</div>
            <div class="form_label baseline white_space">
              Displaced vehicle miles per week
              <mat-icon
                class="tooltip_icon"
                matTooltip="This is the number of miles ridden per week on an electric bike 
              that would have been driven by a car instead (commuting, running errands, etc.)."
              >
                help_outline
              </mat-icon>
            </div>
            <mat-form-field
              class="w-100 formfield_padding"
              floatLabel="auto"
              appearance="outline"
            >
              <input
                matInput
                formControlName="displacedMiles"
                placeholder="Enter 0-75"
                autocomplete="off"
                maxlength="3"
              />
              <mat-error *ngIf="isMilesOutOfRange">
                Please enter value between 0-75</mat-error
              >
              <mat-error *ngIf="isMilesRequired"> Required! </mat-error>
            </mat-form-field>
            <div
              class="save_button"
              [ngClass]="{
                '': !displayMarketPlaceBox,
                'ghost-button': displayMarketPlaceBox,
                disable_button: eBikeForm.invalid
              }"
            >
              <button
                mat-raised-button
                color="accent"
                #loaderButton
                [disabled]="eBikeForm.invalid"
                (click)="onSaveEBikeDetails()"
              >
                SAVE DETAILS
              </button>
            </div>
          </div>
          <yt-assumption-card
            [assumptionDetails]="assumptionDetails"
          ></yt-assumption-card>
        </div>

        <div class="right_section">
          <div
            class="highChart_card"
            id="savingsDesktop"
            [ngClass]="{
              'highChart-display': showHighChart,
              'highChart_d-none': !showHighChart
            }"
          >
            <div class="details_header pb_14">Savings</div>

            <app-Time-Rendering
              
            [selected]="selected"           
            (selectionChange)="updateChart($event)"> 
            </app-Time-Rendering>

            <div class="w-100 savings_text">
              Your
              <span>{{ timeFrame }}</span> projected
              <span
                class="tooltip_text_border pointer"
                *ngIf="showTooltipZero"
                style="border-color: tomato"
                matTooltip="When the displaced vehicle miles are zero, there are no electric bike savings. 
                Also, when both an electric vehicle and solar are selected, any savings associated with an 
                electric bike are included in with the electric vehicle savings."
              >
                energy savings
              </span>
              <span *ngIf="!showTooltipZero">energy savings</span>
              is
              <span
                class="savings_value"
                [ngClass]="{ value_color: eBike_Savings < 0 }"
                >{{ eBike_Savings | currency : "$" : "symbol" : "1.0-0" }}</span
              >
            </div>
            <div class="formula_text">
              Energy savings &nbsp; = &nbsp; Energy cost {{ yAxisLabel1 }} -
              Energy cost with electric bike
            </div>
            <div class="w-100 chart_section">
              <div class="hide_chart">
                <div class="chart" #container></div>
              </div>
            </div>
          </div>
          <div
            class="w-100 highChart_card"
            *ngIf="!showHighChart"
            id="descriptionDesktop"
          >
            <div class="details_header">DESCRIPTION</div>
            <div class="yt_text">{{ noChartText }}</div>
          </div>

          <div class="summary_card" id="details">
            <mat-expansion-panel
              (closed)="isTechnicalExpanded = false"
              [expanded]="isTechnicalExpanded"
              hideToggle="true"
            >
              <mat-expansion-panel-header (click)="expansionPanelClick()">
                <mat-panel-title class="flex items-center">
                  Technical Details &nbsp;
                  <mat-icon class="pointer mat_color">
                    {{
                      !icon ? "keyboard_arrow_down" : "keyboard_arrow_up"
                    }}</mat-icon
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel_body w-100">
                <div class="left_side_values">
                  <div class="label_heading w-100">Summary</div>
                  <div class="left_label_content_box">
                    <div class="label_content_row">
                      <div>Type</div>
                      <div *ngIf="eBikeViewDetails">
                        {{ technicalDetails.eBikeTechnicalSummary.type }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div
                        class="tooltip_text pointer"
                        matTooltip="The distance an electric bike can ride on one charge. 
                      This depends on battery size, terrain, rider and cargo weight, and how you ride."
                      >
                        Range
                      </div>
                      <div *ngIf="eBikeViewDetails">
                        {{ technicalDetails.eBikeTechnicalSummary.range }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Motor size</div>
                      <div *ngIf="eBikeViewDetails">
                        {{ technicalDetails.eBikeTechnicalSummary.motorSize }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Battery capacity</div>
                      <div *ngIf="eBikeViewDetails">
                        {{
                          technicalDetails.eBikeTechnicalSummary.batteryCapacity
                        }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div
                        class="tooltip_text pointer"
                        matTooltip="The speed at which the battery stops providing electric assistance."
                      >
                        Top assisted speed
                      </div>
                      <div *ngIf="eBikeViewDetails">
                        {{
                          technicalDetails.eBikeTechnicalSummary
                            .topAssistedSpeed
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </div>

          <div class="faq_card w-100" id="faq" *ngIf="!isLangSpanish">
            <mat-expansion-panel
              (closed)="isFAQExpanded = false"
              [expanded]="isFAQExpanded"
              hideToggle="true"
            >
              <mat-expansion-panel-header (click)="faqExpansionClick()">
                <mat-panel-title class="flex items-center">
                  Frequently Asked Questions &nbsp;
                  <mat-icon class="pointer mat_color">
                    {{
                      !icon1 ? "keyboard_arrow_down" : "keyboard_arrow_up"
                    }}</mat-icon
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel_body w-100">
                <yt-customized-tab
                  [tabHeadersList]="FAQTabsList"
                  (tabValue)="tabChanged($event)"
                ></yt-customized-tab>
                <yt-ebike-FAQ
                  [activeIndex]="tabIndex"
                  [showAboutTech]="false"
                ></yt-ebike-FAQ>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </div>
      <div
        class="link_text back_to_top"
        (click)="scrollToTop()"
        *ngIf="!isLangSpanish"
      >
        <mat-icon class="pointer mat_color">keyboard_arrow_up</mat-icon>
        <span class="pointer">Back to Top</span>
      </div>
      <div class="disclaimer_text" [ngClass]="{ pt_70: isLangSpanish }">
        The results and projections are estimates based on available data.
      </div>
    </div>
  </div>
</div>

<!-- Incentives Breakdown Dialogue box-->
<yt-breakdown-popup
  *ngIf="showIncentiveBreakdown && haveResponse"
  (closeIncentiveBreakdown)="showIncentiveBreakdown = false"
  [heading]="'Incentives Breakdown'"
  [netCost]="'Total'"
  [techName]="'eBike'"
>
</yt-breakdown-popup>

<!-- loader -->
<yt-loader *ngIf="loader"></yt-loader>
