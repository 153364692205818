<div class="yt_boxwidth" [ngStyle]="{ 'padding-bottom': visited === 'noPlan' ? '25px' : '0' }">
    <div class="yt_tagline">{{tagline}}</div>
    <div class="tech_name">{{technologyName}}</div>
    <div class="tech_image">
        <img [src]="image" loading="lazy" [alt]="technologyName" width="130px" height="110px">
    </div>
    <div *ngIf="visited === 'noPlan'" class="yt_savings">{{savings}}</div>
    <div *ngIf="visited !== 'noPlan'" class="plan_container"
        [ngClass]="{ 'plan_yes': visited === 'yes', 'plan_yesPlan': visited === 'yesPlan', 'plan_no': visited === 'no' }">
        <div *ngIf="visited === 'yes'">Already have</div>
        <div *ngIf="visited === 'yesPlan'">Interested <mat-icon>done</mat-icon></div>
        <div *ngIf="visited === 'no'">Not interested</div>
    </div>
</div>