<mat-sidenav-container class="h-100 yt__open-sans">
  <mat-sidenav
    #drawer
    class="sidenav"
    [ngClass]="{ hidden: (isHandset | async)!.matches }"
    fixedInViewport="false"
    [attr.role]="isHandset ? 'dialog' : 'navigation'"
    [mode]="(isHandset | async)!.matches ? 'over' : 'side'"
    [opened]="!(isHandset | async)!.matches"
    id="yt__sidenav"
  >
    <mat-toolbar
      class="h-100 relative"
      (click)="toggleSideNav()"
      *ngIf="routeInfo"
      class="menu_group"
    >
      <div
        *ngIf="!(isHandset | async)!.matches && routeInfo.logo1.showLink"
        class="desktopLogo"
      >
        <img
          *ngIf="logo && logo.customer"
          [src]="logo.customer.url"
          alt="logo"
          width="150px"
          style="cursor: pointer"
          (click)="onClickLogo()"
        />
        <mat-spinner *ngIf="!logo" color="accent" [diameter]="50"></mat-spinner>
      </div>
      <mat-toolbar-row
        *ngIf="(isHandset | async)!.matches"
        class="flex yt_mt_2 justify-end"
      >
        <mat-icon
          aria-label="Side nav toggle icon"
          class="pointer pr3 complete"
        >
          menu
        </mat-icon>
      </mat-toolbar-row>

      <!-- My Info -->
      <mat-toolbar-row
        *ngIf="routeInfo.personalScreen.showLink"
        style="padding-top: 74px"
      >
        <div
          class="flex items-center yt__outline-none w-100 h-100 yt_pt6"
          routerLinkActive="hi"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="onClickPersonal()"
          [ngClass]="{
            enabled_clr: routeInfo.personalScreen.activatedLink,
            disabled_clr: !routeInfo.personalScreen.activatedLink
          }"
        >
          <div
            class="oval pointer flex justify-center items-center"
            id="personalScreen"
            [ngClass]="{
              activenumber_clr: routeInfo.personalScreen.activatedLink,
              inactivenumber_clr: !routeInfo.personalScreen.activatedLink
            }"
          >
            {{ routeInfo.personalScreen.menuNumber }}
          </div>
          <div
            class="pointer menu_text"
            [ngClass]="{
              menu_hover:
                !routeInfo.personalScreen.disabledLink &&
                !routeInfo.personalScreen.activatedLink
            }"
          >
            My Info
          </div>
        </div>
      </mat-toolbar-row>

      <!-- RoofTop Info -->
      <mat-toolbar-row
        *ngIf="routeInfo.roofTop.showLink && !routeInfo.roofTop.disabledLink"
        style="padding-top: 54px"
      >
        <div
          class="flex items-center yt_pt6"
          routerLinkActive="hi"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="onClickRoofTop()"
          [ngClass]="{
            enabled_clr: routeInfo.roofTop.activatedLink,
            disabled_clr: !routeInfo.roofTop.activatedLink
          }"
        >
          <div
            class="oval pointer flex justify-center items-center"
            id="roofTop"
            [ngClass]="{
              activenumber_clr: routeInfo.roofTop.activatedLink,
              inactivenumber_clr: !routeInfo.roofTop.activatedLink
            }"
          >
            {{ routeInfo.roofTop.menuNumber }}
          </div>
          <div
            class="pointer menu_text"
            [ngClass]="{
              menu_hover:
                !routeInfo.roofTop.disabledLink &&
                !routeInfo.roofTop.activatedLink
            }"
          >
            Roof Info
          </div>
        </div>
      </mat-toolbar-row>
      <mat-toolbar-row
        *ngIf="routeInfo.roofTop.showLink && routeInfo.roofTop.disabledLink"
        style="padding-top: 54px"
      >
        <div
          class="flex items-center yt_pt6 disable_step"
          routerLinkActive="hi"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div
            class="oval flex justify-center items-center disable_step"
            id="roofTop"
            style="background-color: transparent"
          >
            {{ routeInfo.roofTop.menuNumber }}
          </div>
          <div class="menu_text">Roof Info</div>
        </div>
      </mat-toolbar-row>

      <!-- Energy Choices -->
      <mat-toolbar-row
        *ngIf="
          routeInfo.lifeStyle.showLink && !routeInfo.lifeStyle.disabledLink
        "
        style="padding-top: 54px"
      >
        <div
          class="flex items-center yt_pt6"
          routerLinkActive="hi"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="onClickLifeStyle()"
          [ngClass]="{
            enabled_clr: routeInfo.lifeStyle.activatedLink,
            disabled_clr: !routeInfo.lifeStyle.activatedLink
          }"
        >
          <div
            class="oval pointer flex justify-center items-center"
            id="lifeStyle"
            [ngClass]="{
              activenumber_clr: routeInfo.lifeStyle.activatedLink,
              inactivenumber_clr: !routeInfo.lifeStyle.activatedLink
            }"
          >
            {{ routeInfo.lifeStyle.menuNumber }}
          </div>
          <div
            class="pointer menu_text"
            [ngClass]="{
              menu_hover:
                !routeInfo.lifeStyle.disabledLink &&
                !routeInfo.lifeStyle.activatedLink
            }"
          >
            Energy Choices
          </div>
        </div>
      </mat-toolbar-row>
      <mat-toolbar-row
        *ngIf="routeInfo.lifeStyle.showLink && routeInfo.lifeStyle.disabledLink"
        style="padding-top: 54px"
      >
        <div
          class="flex items-center yt_pt6 disable_step"
          routerLinkActive="hi"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div
            class="oval flex justify-center items-center disable_step"
            id="lifeStyle"
            style="background-color: transparent"
          >
            {{ routeInfo.lifeStyle.menuNumber }}
          </div>
          <div class="menu_text">Energy Choices</div>
        </div>
      </mat-toolbar-row>

      <!-- Energy Changes -->
      <mat-toolbar-row
        *ngIf="routeInfo.upgrades.showLink && !routeInfo.upgrades.disabledLink"
        style="padding-top: 54px"
      >
        <div
          class="flex items-center yt_pt6"
          routerLinkActive="hi"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="onClickUpgrades()"
          [ngClass]="{
            enabled_clr: routeInfo.upgrades.activatedLink,
            disabled_clr: !routeInfo.upgrades.activatedLink
          }"
        >
          <div
            class="oval pointer flex justify-center items-center"
            id="upgrades"
            [ngClass]="{
              activenumber_clr: routeInfo.upgrades.activatedLink,
              inactivenumber_clr: !routeInfo.upgrades.activatedLink
            }"
          >
            {{ routeInfo.upgrades.menuNumber }}
          </div>
          <div
            class="pointer menu_text"
            [ngClass]="{
              menu_hover:
                !routeInfo.upgrades.disabledLink &&
                !routeInfo.upgrades.activatedLink
            }"
          >
            Energy Changes
          </div>
        </div>
      </mat-toolbar-row>
      <mat-toolbar-row
        *ngIf="routeInfo.upgrades.showLink && routeInfo.upgrades.disabledLink"
        style="padding-top: 54px"
      >
        <div
          class="flex items-center yt_pt6 disable_step"
          routerLinkActive="hi"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div
            class="oval flex justify-center items-center disable_step"
            id="upgrades"
            style="background-color: transparent"
          >
            {{ routeInfo.upgrades.menuNumber }}
          </div>
          <div class="menu_text">Energy Changes</div>
        </div>
      </mat-toolbar-row>

      <!-- Additional Info -->
      <mat-toolbar-row
        *ngIf="
          routeInfo.additionalinfo.showLink &&
          !routeInfo.additionalinfo.disabledLink
        "
        style="padding-top: 54px"
      >
        <div
          class="flex items-center yt_pt6"
          routerLinkActive="hi"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="onClickAdditionalInfo()"
          [ngClass]="{
            enabled_clr: routeInfo.additionalinfo.activatedLink,
            disabled_clr: !routeInfo.additionalinfo.activatedLink
          }"
        >
          <div
            class="oval pointer flex justify-center items-center"
            id="additionalinfo"
            [ngClass]="{
              activenumber_clr: routeInfo.additionalinfo.activatedLink,
              inactivenumber_clr: !routeInfo.additionalinfo.activatedLink
            }"
          >
            {{ routeInfo.additionalinfo.menuNumber }}
          </div>
          <div
            class="pointer menu_text"
            [ngClass]="{
              menu_hover:
                !routeInfo.additionalinfo.disabledLink &&
                !routeInfo.additionalinfo.activatedLink
            }"
          >
            Additional Info
          </div>
        </div>
      </mat-toolbar-row>
      <mat-toolbar-row
        *ngIf="
          routeInfo.additionalinfo.showLink &&
          routeInfo.additionalinfo.disabledLink
        "
        style="padding-top: 54px"
      >
        <div
          class="flex items-center yt_pt6 disable_step"
          routerLinkActive="hi"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div
            class="oval flex justify-center items-center disable_step"
            id="additionalinfo"
            style="background-color: transparent"
          >
            {{ routeInfo.additionalinfo.menuNumber }}
          </div>
          <div class="menu_text">Additional Info</div>
        </div>
      </mat-toolbar-row>

      <!-- Energy Info -->
      <mat-toolbar-row
        *ngIf="
          routeInfo.energyUsage.showLink && !routeInfo.energyUsage.disabledLink
        "
        style="padding-top: 54px"
      >
        <div
          class="flex items-center yt_pt6"
          routerLinkActive="hi"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="onClickEnergyUsage()"
          [ngClass]="{
            enabled_clr: routeInfo.energyUsage.activatedLink,
            disabled_clr: !routeInfo.energyUsage.activatedLink
          }"
        >
          <div
            class="oval pointer flex justify-center items-center"
            id="energyUsage"
            [ngClass]="{
              activenumber_clr: routeInfo.energyUsage.activatedLink,
              inactivenumber_clr: !routeInfo.energyUsage.activatedLink
            }"
          >
            {{ routeInfo.energyUsage.menuNumber }}
          </div>
          <div
            class="pointer menu_text"
            [ngClass]="{
              menu_hover:
                !routeInfo.energyUsage.disabledLink &&
                !routeInfo.energyUsage.activatedLink
            }"
          >
            Energy Info
          </div>
        </div>
      </mat-toolbar-row>
      <mat-toolbar-row
        *ngIf="
          routeInfo.energyUsage.showLink && routeInfo.energyUsage.disabledLink
        "
        style="padding-top: 54px"
      >
        <div
          class="flex items-center yt_pt6 disable_step"
          routerLinkActive="hi"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div
            class="oval flex justify-center items-center disable_step"
            id="energyUsage"
            style="background-color: transparent"
          >
            {{ routeInfo.energyUsage.menuNumber }}
          </div>
          <div class="menu_text">Energy Info</div>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </mat-sidenav>
  <mat-sidenav-content>
    <div *ngIf="!hideSidenav">
      <mat-toolbar
        *ngIf="(isHandset | async)!.matches && routeInfo.logo1.showLink"
        class="flex justify-center relative bg-white"
      >
        <mat-icon
          aria-label="Side nav toggle icon"
          (click)="drawer.toggle()"
          *ngIf="(isHandset | async)!.matches"
          class="absolute left-2 pointer"
        >
          menu
        </mat-icon>
        <a
          [href]="logo.href"
          target="_blank"
          *ngIf="logo"
          style="padding-top: 18px; padding-bottom: 19px"
        >
          <img [src]="logo.url" target="_blank" width="150px" />
        </a>
        <mat-spinner *ngIf="!logo" color="accent" [diameter]="50"></mat-spinner>
      </mat-toolbar>
      <mat-toolbar
        *ngIf="(isHandset | async)!.matches && routeInfo.logo2.showLink"
        class="flex justify-center relative bg-white"
      >
        <mat-icon
          aria-label="Side nav toggle icon"
          (click)="drawer.toggle()"
          *ngIf="(isHandset | async)!.matches"
          class="absolute left-2 pointer"
        >
          menu
        </mat-icon>
        <a [href]="dashboardlogo.href" target="_blank" *ngIf="dashboardlogo">
          <img [src]="dashboardlogo.url" target="_blank" width="150px" />
        </a>
        <mat-spinner
          *ngIf="!dashboardlogo"
          color="accent"
          [diameter]="50"
        ></mat-spinner>
      </mat-toolbar>
    </div>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
