import { MatDialog } from "@angular/material/dialog";
import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormArray, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { EVSettingModel, MNFAndModel } from "../energy-choices.model";
import { LifestyleService } from "../../../rest-api/lifestyle.service";
import { DropDownModel } from "../../../rest-api/rest-api.model";
import {
  DialogOverviewComponent,
  DialogOverview1Component,
} from "../../../shared/dialog-overview/dialog-overview.component";
import { RestAPIService } from "../../../rest-api/rest-api.service";
import { Utils } from "../../../utils/util";

@Component({
  selector: "yt-ev",
  templateUrl: "ev.component.html",
  styleUrls: ["../energy-choices-common.scss"],
})
export class EVComponent implements OnInit {
  evForm: FormGroup;
  showAddEV: boolean = true;
  panelOpenState: boolean = false;
  disableBoughtAndDatePurchased: boolean[] = [];
  updateMode: boolean;
  ev1Data: string;
  ev2Data: string;
  @Output() saveEV = new EventEmitter<any>();
  ev: FormArray;
  annualForm: FormGroup;
  approximatemiles: number = 0;
  monthList: string[];
  charges: string[];
  EVData: EVSettingModel[];
  mnfDropDownList: MNFAndModel[][] = [];
  showAnnualMiles = false;
  lastTwelthMonth: string;
  makeAndModelList1: MNFAndModel[];
  makeAndModelList2: MNFAndModel[];
  DayWeek: DropDownModel[] = [
    { value: "Day", viewValue: "Day" },
    { value: "Week", viewValue: "Week" },
  ];

  constructor(
    private fb: FormBuilder,
    private lifeStyleService: LifestyleService,
    private dialog: MatDialog,
    private utils: Utils,
    private restAPIService: RestAPIService
  ) {
    this.annualForm = this.fb.group({
      miles: ["", Validators.required],
      dayWeek: ["Day", Validators.required],
    });
    this.restAPIService.updateMakeAndModel();
  }

  ngOnInit(): void {
    this.monthList = this.utils.monthList;
    this.lastTwelthMonth = this.utils.lastTwelthMonth;
    this.charges = this.utils.chargesList;
    this.evForm = this.fb.group({
      ev: this.fb.array([this.createItem()]),
    });
    this.setDefaultValueForCarModel(0);
    if (this.lifeStyleService.getEVInfo() !== undefined) {
      this.EVData = this.lifeStyleService.getEVInfo();
      this.updateMode = true;
      this.checkUpdatedEV(this.EVData);
    }
    if (this.evForm.get(`ev.${0}`)?.get("own")?.value === "yesPlan") {
      this.evForm.get(`ev.${0}`)?.get("datePurchased")?.disable();
    }
    this.mnfDropDownList = this.restAPIService.updateMakeAndModel();
    this.getMakeAndModel(1);
    this.getMakeAndModel(2);
  }

  onPopUp() {
    this.showAnnualMiles = true;
  }

  onClose() {
    this.showAnnualMiles = false;
  }

  approximateMiles() {
    if (
      this.annualForm.get("miles")?.value !== "" &&
      this.annualForm.get("miles")?.value !== null &&
      this.annualForm.get("dayWeek")?.value === "Day"
    ) {
      this.approximatemiles = Number(this.annualForm.get("miles")?.value) * 365;
    } else if (
      this.annualForm.get("miles")?.value !== "" &&
      this.annualForm.get("miles")?.value !== null &&
      this.annualForm.get("dayWeek")?.value === "Week"
    ) {
      this.approximatemiles = Number(this.annualForm.get("miles")?.value) * 52;
    }
  }

  isFormFieldEmpty() {
    if (
      this.annualForm.get("miles")?.value === "" ||
      this.annualForm.get("miles")?.value === null
    ) {
      return true;
    } else {
      return false;
    }
  }

  isDatePurchasedEmpty(): boolean {
    return this.evForm.get("ev")?.value.forEach((element, index: number) => {
      if (this.evForm.get(`ev.${index}`)?.get("own")?.value === "yes") {
        if (this.evForm.get(`ev.${index}`)?.get("datePurchased")?.invalid) {
          return true;
        }
      }
      return false;
    });
  }

  isMNFAndModelListAbsent(): boolean {
    return this.mnfDropDownList.length === 0;
  }
  isDatePurchasedSelected(index: number): boolean {
    const datePurchased = this.evForm.get(`ev.${index}`)?.get("datePurchased")!;
    return datePurchased.touched && datePurchased.invalid;
  }

  createItem(): FormGroup {
    return this.fb.group({
      own: ["yesPlan", Validators.required],
      model: ["", Validators.required],
      annualMiles: [
        "15000",
        [
          Validators.required,
          Validators.pattern(
            "^([[0-9][0-9][0-9][0-9]|[0-4][0-9][0-9][0-9][0-9]|)$"
          ),
          Validators.min(1000),
          Validators.max(40000),
        ],
      ],
      charging: ["100", Validators.required],
      bought: [false],
      datePurchased: ["", Validators.required],
    });
  }

  checkUpdatedEV(data) {
    const evFormItems = <FormArray>this.evForm.get("ev");
    for (let i = 0; i < evFormItems.length; i++) {
      evFormItems.removeAt(i);
    }
    data.forEach((ev) => {
      evFormItems.push(
        this.fb.group({
          own: ev.own,
          model: ev.model,
          annualMiles: ev.annualMiles,
          charging: ev.charging,
          bought: ev.bought,
          datePurchased: ev.bought ? ev.datePurchased : this.lastTwelthMonth,
        })
      );
    });
    for (let index in data) {
      this.onSelectOfQuestion(index);
    }
    for (let index in data) {
      let modelValue = data[index].model;
      this.onCarTypeChange(modelValue, Number(index));
    }
  }

  onSelectOfQuestion(i) {
    if (this.evForm.get(`ev.${i}`)?.get("own")?.value === "yes") {
      this.evForm.get(`ev.${i}`)?.enable();
      this.evForm.get(`ev.${i}`)?.get("bought")?.enable();
      this.showAddEV = true;
      this.disableBoughtAndDatePurchased[i] = true;
    } else if (this.evForm.get(`ev.${i}`)?.get("own")?.value === "yesPlan") {
      this.evForm.get(`ev.${i}`)?.get("model")?.enable();
      this.evForm.get(`ev.${i}`)?.get("annualMiles")?.enable();
      this.evForm.get(`ev.${i}`)?.get("charging")?.enable();
      this.evForm.get(`ev.${i}`)?.get("bought")?.disable();
      this.evForm.get(`ev.${i}`)?.get("datePurchased")?.disable();
      this.showAddEV = true;
      this.disableBoughtAndDatePurchased[i] = false;
    } else if (this.evForm.get(`ev.${i}`)?.get("own")?.value === "no") {
      this.evForm.get(`ev.${i}`)?.get("bought")?.disable();
      this.evForm.get(`ev.${i}`)?.get("datePurchased")?.disable();
      this.evForm.get(`ev.${i}`)?.get("model")?.disable();
      this.evForm.get(`ev.${i}`)?.get("annualMiles")?.disable();
      this.evForm.get(`ev.${i}`)?.get("charging")?.disable();
      this.showAddEV = false;
      this.disableBoughtAndDatePurchased[i] = false;
    }
  }

  public get evArray(): FormArray {
    return this.evForm.get("ev") as FormArray;
  }

  onAddAnotherEV(): void {
    this.showAddEV = false;
    const controls = <FormArray>this.evForm.controls["ev"];
    controls.push(this.createItem());
    this.setDefaultValueForCarModel(controls.length - 1);
    this.disableBoughtAndDatePurchased[1] = false;
  }

  removeEV() {
    this.showAddEV = true;
    if (this.evForm.value.ev.length > 1) {
      this.evArray.removeAt(1);
    }
  }

  setDefaultValueForCarModel(index: number) {
    var arrayControl = this.evForm.get("ev") as FormArray;
    arrayControl.at(index)?.get("model")?.setValue("Nissan Leaf S");
    arrayControl.at(index)?.get("datePurchased")?.disable();
  }

  onSaveEV() {
    const evInfo: {
      energyChoices: {
        ev: {
          own: string;
          model?: string;
          annualMiles?: string;
          charging?: string;
          bought?: string;
          datePurchased?: string;
        }[];
      };
    } = {
      energyChoices: {
        ev: [],
      },
    };
    this.evForm.get("ev")?.value.forEach((element, i) => {
      if (this.evForm.get(`ev.${i}`)?.get("own")?.value === "yes") {
        const datePurchased = this.evForm
          .get(`ev.${i}`)
          ?.get("datePurchased")?.value;
        const bought = this.utils.isDatePurchaseSelected(datePurchased);
        this.evForm.get(`ev.${i}`)?.get("bought")?.setValue(bought);
        evInfo.energyChoices.ev[i] = {
          own: this.evForm.get(`ev.${i}`)?.get("own")?.value,
          model: this.evForm.get(`ev.${i}`)?.get("model")?.value,
          annualMiles: this.evForm.get(`ev.${i}`)?.get("annualMiles")?.value,
          charging: this.evForm.get(`ev.${i}`)?.get("charging")?.value,
          bought: this.evForm.get(`ev.${i}`)?.get("bought")?.value,
          datePurchased: !this.evForm.get(`ev.${i}`)?.get("bought")?.value
            ? null
            : this.evForm.get(`ev.${i}`)?.get("datePurchased")?.value,
        };
      } else if (this.evForm.get(`ev.${i}`)?.get("own")?.value === "yesPlan") {
        evInfo.energyChoices.ev[i] = {
          own: this.evForm.get(`ev.${i}`)?.get("own")?.value,
          model: this.evForm.get(`ev.${i}`)?.get("model")?.value,
          annualMiles: this.evForm.get(`ev.${i}`)?.get("annualMiles")?.value,
          charging: this.evForm.get(`ev.${i}`)?.get("charging")?.value,
        };
      } else if (this.evForm.get(`ev.${i}`)?.get("own")?.value === "no") {
        evInfo.energyChoices.ev[i] = {
          own: this.evForm.get(`ev.${i}`)?.get("own")?.value,
        };
      }
    });
    this.lifeStyleService.onSaveLifestyleInfo(evInfo).subscribe((response) => {
      if (response.status === 200) {
        this.lifeStyleService.postEVInfo(evInfo);
        this.saveEV.emit();
      }
    });
  }

  getMakeAndModel(count: number): void {
    this.restAPIService
      .getMakeAndModelList(`ev${count}`)
      .subscribe((response) => {
        if (count === 1) {
          this.makeAndModelList1 = response.mnfDropDownList;
        } else if (count === 2) {
          this.makeAndModelList2 = response.mnfDropDownList;
        }
      });
  }

  onCarTypeChange(value: string, index: number): boolean {
    if (index === 1) {
      if (this.makeAndModelList2) {
        let carType = this.makeAndModelList2
          .filter((list: MNFAndModel) => list.mnfAndModel === value)
          .map((list: MNFAndModel) => list.carType)[0];
        return carType === "PHEV";
      }
      return false;
    } else {
      if (this.makeAndModelList1) {
        let carType = this.makeAndModelList1
          .filter((list: MNFAndModel) => list.mnfAndModel === value)
          .map((list: MNFAndModel) => list.carType)[0];
        return carType === "PHEV";
      }
      return false;
    }
  }

  openDialogStatus(evstatus) {
    if (evstatus === 0) {
      this.openDialog1();
    }
    if (evstatus === 1) {
      this.openDialog2();
    }
  }

  openDialog1(): void {
    let dialogRef = this.dialog.open(DialogOverviewComponent, {
      width: "90%",
      height: "75%",
      autoFocus: false,
      backdropClass: "dark-backdrop",
      panelClass: "custom-dialog-container",
      data: { ev1Data: this.ev1Data, app: "HP" },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.ev1Data = result;
        this.setDefaultValueForCarModel1(0);
      }
    });
  }

  openDialog2(): void {
    let dialogRef = this.dialog.open(DialogOverview1Component, {
      width: "90%",
      height: "75%",
      autoFocus: false,
      backdropClass: "dark-backdrop",
      panelClass: "custom-dialog-container",
      data: { ev2Data: this.ev2Data, app: "HP" },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.ev2Data = result;
        this.setDefaultValueForCarModel1(1);
      }
    });
  }
  setDefaultValueForCarModel1(index: number) {
    var arrayControl = this.evForm.get("ev") as FormArray;
    arrayControl
      .at(index)
      .get("model")
      ?.setValue(index === 0 ? this.ev1Data : this.ev2Data);
  }

  disableLink(i): boolean {
    return this.evForm.get(`ev.${i}`)?.get("own")?.value === "no";
  }

  getValue(key: string, index: number): string {
    return this.evForm.get(`ev.${index}`)?.get(key)?.value;
  }

  validateAnnualMiles(i: number): boolean {
    if (!this.evForm) return false;
    return (
      this.evForm.get(`ev.${i}`)?.get("annualMiles")?.hasError("pattern")! ||
      this.evForm.get(`ev.${i}`)?.get("annualMiles")?.hasError("min")! ||
      this.evForm.get(`ev.${i}`)?.get("annualMiles")?.hasError("max")!
    );
  }

  isAnnualMilesEmpty(i: number): boolean {
    return this.evForm
      .get(`ev.${i}`)
      ?.get("annualMiles")
      ?.hasError("required")!;
  }
}
