<section *ngIf="activeTab && calculateLength(activeTab)">
  <div class="header">
    {{ header }}
  </div>
  <div class="subHeader">{{ subHeader }}</div>
</section>

<div [id]="section">
  <yt-clean-choice-cards
    [activeTab]="activeTab"
    [activeTech]="currentTech"
    [section]="section"
    (showTech)="activeTechInfo($event)"
  ></yt-clean-choice-cards>

  <div
    class="tech_info_wrapper"
    *ngIf="currentTech"
    [ngClass]="{ active_border: currentTech }"
  >
    <mat-icon class="mat-icon" (click)="onClose()">close</mat-icon>
    <info-card-simple-low-cost
      *ngIf="activeTab === 'simpleLowCost'"
      [activeTech]="currentTech"
    ></info-card-simple-low-cost>

    <info-card-transportation
      *ngIf="activeTab === 'transportation'"
      [activeTech]="currentTech"
    ></info-card-transportation>

    <info-card-appliances
      *ngIf="activeTab === 'appliances'"
      [activeTech]="currentTech"
      [section]="section"
    ></info-card-appliances>
  </div>
</div>
