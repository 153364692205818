import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { LifestyleService } from "../../../rest-api/lifestyle.service";
import { Utils } from "../../../utils/util";
import { SpaSettingModel } from "../energy-choices.model";

@Component({
  selector: "yt-spa",
  templateUrl: "spa.component.html",
  styleUrls: ["../energy-choices-common.scss"],
})
export class SpaComponent implements OnInit {
  monthList: string[];
  lastTwelthMonth: string;
  panelOpenState: boolean = false;
  disableDatePicker: boolean;
  spaForm: FormGroup;
  updateMode: boolean;
  spaData: SpaSettingModel[];
  @Output() saveSPA = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private lifeStyleService: LifestyleService,
    private utils: Utils
  ) {
    this.spaForm = this.fb.group({
      own: ["yesPlan", Validators.required],
      hrs: [
        "75",
        [
          Validators.required,
          Validators.pattern(
            "^([1-9]|[1-9][0-9]|[1-9][0-9][0-9]|[1-7][0-9][0-9][0-9]|8[0-6][0-9][0-9]|8[7][0-8][0-5])$"
          ),
          Validators.min(1),
          Validators.max(8784),
        ],
      ],
      bought: [""],
      datePurchased: ["", Validators.required],
    });
  }

  ngOnInit() {
    this.monthList = this.utils.monthList;
    this.lastTwelthMonth = this.utils.lastTwelthMonth;
    if (this.lifeStyleService.getSpaInfo() !== undefined) {
      this.spaData = this.lifeStyleService.getSpaInfo();
      this.checkInUpdateView(this.spaData[0]);
    }
    if (this.spaForm.get("own")?.value !== "yes") {
      this.spaForm.get("datePurchased")?.disable();
    }
  }

  isDatePurchasedEmpty() {
    if (this.spaForm.get("own")?.value === "yes") {
      if (!this.spaForm.get("datePurchased")?.value) {
        return true;
      }
    }
    return false;
  }

  checkInUpdateView(data: SpaSettingModel) {
    this.updateMode = true;
    this.spaForm.controls["own"].setValue(data.own);
    if (data.hrs) {
      this.spaForm.controls["hrs"].setValue(data.hrs);
    } else {
      this.spaForm.get("hrs")?.disable();
    }
    if (data.bought) {
      this.spaForm.controls["bought"].setValue(data.bought);
      this.spaForm.controls["datePurchased"].setValue(data.datePurchased);
    } else {
      if (data.own === "yes") {
        this.spaForm.controls["bought"].setValue(false);
        this.spaForm.controls["datePurchased"].setValue(this.lastTwelthMonth);
      } else {
        this.spaForm.get("bought")?.disable();
        this.spaForm.get("datePurchased")?.disable();
      }
    }
    this.disableDatePicker = data.own === "yes";
  }

  condition() {
    if (this.spaForm.get("own")?.value === "yes") {
      this.spaForm.get("bought")?.enable();
      this.spaForm.get("hrs")?.enable();
      this.spaForm.get("datePurchased")?.enable();
      this.disableDatePicker = true;
    } else if (this.spaForm.get("own")?.value === "yesPlan") {
      this.spaForm.get("bought")?.disable();
      this.spaForm.get("hrs")?.enable();
      this.spaForm.get("datePurchased")?.disable();
      this.disableDatePicker = false;
    } else if (this.spaForm.get("own")?.value === "no") {
      this.spaForm.get("bought")?.disable();
      this.spaForm.get("hrs")?.disable();
      this.spaForm.get("datePurchased")?.disable();
      this.disableDatePicker = false;
    }
  }

  onSaveSPA() {
    const spaInfo: {
      energyChoices: {
        spa: {
          own: string;
          hrs?: string;
          bought?: string;
          datePurchased?: string;
        }[];
      };
    } = {
      energyChoices: {
        spa: [],
      },
    };
    if (this.spaForm.get("own")?.value === "yes") {
      const datePurchased = this.spaForm.get("datePurchased")?.value;
      const bought = this.utils.isDatePurchaseSelected(datePurchased);
      this.spaForm.get("bought")?.setValue(bought);
      spaInfo.energyChoices.spa[0] = {
        own: this.spaForm.get("own")?.value,
        hrs: this.spaForm.get("hrs")?.value,
        bought: this.spaForm.get("bought")?.value,
        datePurchased: !this.spaForm.get("bought")?.value
          ? null
          : this.spaForm.get("datePurchased")?.value,
      };
    } else if (this.spaForm.get("own")?.value === "yesPlan") {
      spaInfo.energyChoices.spa[0] = {
        own: this.spaForm.get("own")?.value,
        hrs: this.spaForm.get("hrs")?.value,
      };
    } else if (this.spaForm.get("own")?.value === "no") {
      spaInfo.energyChoices.spa[0] = {
        own: this.spaForm.get("own")?.value,
      };
    }
    this.lifeStyleService.onSaveLifestyleInfo(spaInfo).subscribe((response) => {
      if (response.status === 200) {
        this.lifeStyleService.postSpaInfo(spaInfo);
        this.saveSPA.emit();
      }
    });
  }
}
