<div class="main_section">
  <div class="container">
    <mat-stepper
      (selectionChange)="selectionChange($event)"
      linear="true"
      labelPosition="bottom"
      #stepper
    >
      <!-- Sign Up -->
      <mat-step
        [stepControl]="stepOne"
        [editable]="stepOne.controls['visit'].valid"
      >
        <form [formGroup]="stepOne">
          <ng-template matStepLabel>My Info</ng-template>
          <yt-personal-info
            [vendorInfo]="vendorInfo"
            (homeInfo)="toggleHomeInfo()"
          ></yt-personal-info>
        </form>
      </mat-step>
      <!-- Address -->
      <mat-step
        [stepControl]="stepTwo"
        [editable]="stepTwo.controls['visit'].valid"
        ><form [formGroup]="stepTwo">
          <ng-template matStepLabel>Home Info</ng-template>
          <yt-address
            *ngIf="showHomeInfo"
            (energyInfo)="toggleEnergyInfo()"
            [buildingTypes]="buildingTypes"
          ></yt-address></form
      ></mat-step>

      <!-- UtilityProvider -->
      <mat-step
        [stepControl]="stepThree"
        [editable]="stepThree.controls['visit'].valid"
      >
        <form [formGroup]="stepThree">
          <ng-template matStepLabel>Energy Info</ng-template>
          <yt-utility-provider
            *ngIf="stepTwo.controls['visit'].valid && showEnergyInfo"
            [vendorInfo]="vendorInfo"
            (goalInfo)="toggleDashboard()"
          ></yt-utility-provider>
        </form>
      </mat-step>

      <!-- Goal -->
      <!-- <mat-step
        *ngIf="!isSolutionCCE"
        [stepControl]="stepFour"
        [editable]="stepFour.controls['visit'].valid"
      >
        <form [formGroup]="stepFour">
          <ng-template matStepLabel>My Goals</ng-template>
          <yt-goal></yt-goal>
        </form>
      </mat-step> -->
    </mat-stepper>
  </div>

  <div class="right_section">
    <div class="image_section"></div>
    <div class="main_content">
      <div class="header">{{ heading }}</div>
      <div class="text_content">
        <div *ngIf="StepperEnum.MyInfo === this.stepperIndex">
          <p
            *ngFor="let text of journeyContentDetails.myInfo.detail"
            class="point_section"
          >
            {{ text.info }}
          </p>
        </div>
        <ul *ngIf="StepperEnum.HomeInfo === this.stepperIndex">
          <li
            *ngFor="let point of journeyContentDetails.homeInfo.detail"
            class="point_section"
          >
            {{ point.info }}
          </li>
        </ul>
        <div
          *ngIf="StepperEnum.EnergyInfo === this.stepperIndex"
          class="list_content"
        >
          <div
            class="info_content"
            *ngFor="let content of journeyContentDetails.energyInfo.detail"
          >
            <img [src]="content.image" width="75px" class="point_image" />
            <p class="point_section">{{ content.info }}</p>
          </div>
        </div>
        <div *ngIf="StepperEnum.Goal === this.stepperIndex && !isSolutionCCE">
          <p class="point_section">
            {{ this.journeyContentDetails.goalInfo.detail[0].info }}
            <span (click)="onPopup()" class="text_link">{{
              this.journeyContentDetails.goalInfo.detail[1].info
            }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Ask Expert Popup -->
<yt-expert-popup
  *ngIf="askExpertPopup"
  (closePopup)="onClosePopup()"
></yt-expert-popup>
