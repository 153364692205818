import { Injectable } from "@angular/core";
import { AppService } from "../app.service";
import { RoofTypesModel } from "../rest-api/rest-api.model";

export const customerVendors = [
  "SUNW",
  "YSLR",
  "EVMA",
  "USGB",
  "WORK",
  "WSPP",
  "ACTE",
  "EART",
  "UBER",
  "THER",
  "LIME",
  "HUBS",
  "HUMA",
  "LIFE",
  "KYND",
  "LOWE",
];

@Injectable()
export class Utils {
  lastTwelthMonth: string;

  constructor(private appService: AppService) {}

  get monthList(): string[] {
    let dates: string[] = [];
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() - 1;
    let monthList = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "dec",
    ];

    if (month === 11) {
      for (let index = 1; index < 13; index++) {
        dates.push(`${monthList[index - 1]} ${year}`);
      }
    } else {
      for (let index = month + 1; index < month + 13; index++) {
        if (index % 12 > month) {
          dates.push(`${monthList[index]} ${year - 1}`);
        } else {
          dates.push(`${monthList[index % 12]} ${year}`);
        }
      }
    }
    this.lastTwelthMonth = `before ${dates[0]}`;
    dates.unshift(this.lastTwelthMonth);
    return dates;
  }

  get chargesList(): string[] {
    let charges: string[] = [];
    for (let j = 0; j <= 100; j++) {
      if (j % 10 === 0) {
        charges.push(j.toString());
      }
    }
    return charges;
  }

  isDatePurchaseSelected(datePurchased: string): boolean {
    return datePurchased !== this.lastTwelthMonth;
  }

  updateCurrentLanguage(
    languageList: RoofTypesModel[],
    selectedLanguage: string
  ): string {
    let currentLanguage = "English";
    if (!languageList.length) {
      return currentLanguage;
    }
    this.appService.selectedLanguage.next(selectedLanguage);
    const selectedObject = languageList.find(
      (lang) => lang.value === selectedLanguage
    );
    if (selectedObject) {
      currentLanguage = selectedObject.displayValue;
    }
    return currentLanguage;
  }

  toTitleCase(label: string): string {
    return label.charAt(0).toUpperCase() + label.slice(1);
  }

  haveMarketPlace(solutionType: string): boolean {
    return solutionType === "broader";
  }

  thousandSeparator(x): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
