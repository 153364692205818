<div class="container" (window:resize)="onResize()">
  <div
    class="prev-button"
    *ngIf="showScrollButton"
    (click)="handleScroll('prev')"
  >
    <mat-icon>chevron_left</mat-icon>
  </div>

  <div class="wrapper">
    <ng-container *ngFor="let cleanChoiceCard of displayCleanChoices">
      <section
        class="tech_card"
        [ngClass]="{ active_border: activeTech === cleanChoiceCard.cardName }"
        *ngIf="cardList[activeTab][section][cleanChoiceCard.cardName]"
      >
        <div class="image_container">
          <img [src]="cleanChoiceCard.img" width="100%" height="100%" />
        </div>
        <div class="content_wrapper">
          <span class="content_header">{{ cleanChoiceCard.header }}</span>
          <div class="content_subHeader">
            {{ cleanChoiceCard.subHeader }}
            <span
              (click)="showTechInfo(cleanChoiceCard.cardName)"
              class="readmore_text"
            >
              {{
                cleanChoiceCard.cardName === activeTech
                  ? "Read less"
                  : "Read more"
              }}
            </span>
          </div>
          <div class="button-wrapper" *ngIf="cleanChoiceCard.displayBox">
            <button
              mat-raised-button
              color="accent"
              class="button-width"
              (click)="onStartShopping(cleanChoiceCard)"
            >
              {{ cleanChoiceCard.buttonText }}
            </button>
          </div>
        </div>
      </section>
    </ng-container>
  </div>

  <div
    class="next-button"
    *ngIf="showScrollButton"
    (click)="handleScroll('next')"
  >
    <mat-icon>chevron_right</mat-icon>
  </div>
</div>
