import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "yt-techradio-boxes",
  templateUrl: "techradio-boxes.component.html",
  styleUrls: ["./techradio-boxes.component.scss"],
})
export class TechRadioBoxesComponent implements OnInit {
  @Input() cardImage: string;
  @Input() viewValue: string;
  @Input() default: string;
  @Input() value: string;

  constructor() {}

  ngOnInit() {}
}
