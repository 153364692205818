import { Component, Input, OnChanges } from "@angular/core";
import { DashboardAPIService } from "../../../../rest-api/dashboard/dashboard-api.service";
import { Router } from "@angular/router";
import { MarketPlaceAnalyticsModel } from "../../../../rest-api/dashboard/dashboard-api.model";
import { MarketPlaceService } from "src/app/homeowner-dashboard/market-place/market-place.service";

@Component({
  selector: "yt-techdetail-card",
  templateUrl: "techdetail-card.component.html",
  styleUrls: ["./techdetail-card.component.scss"],
})
export class TechDetailCardComponent implements OnChanges {
  @Input() viewValue: string;
  @Input() value: string;
  @Input() techName: string;
  @Input() techParagraph: string;
  @Input() navigatingUrl: string;
  @Input() marketPlaceLink: string;
  paragraphKey: string;

  constructor(
    private dashboardAPI: DashboardAPIService,
    private marketplaceService: MarketPlaceService,
    private router: Router
  ) {}

  ngOnChanges(): void {
    this.paragraphKey = `homeOwenerDashboard.marketPlace.${this.value}.paragraph`;
  }

  onBuyingGuide(): void {
    const details: MarketPlaceAnalyticsModel = {
      tech: this.value,
      detail: "viewBuyingGuide",
    };
    this.dashboardAPI.marketplaceDetailsAnalytics(details);
    this.marketplaceService.openShopNowTab(this.marketPlaceLink);
  }

  onLearnMore(): void {
    this.router.navigateByUrl(this.navigatingUrl);
    const details: MarketPlaceAnalyticsModel = {
      tech: this.value,
      detail: "learnMore",
    };
    this.dashboardAPI.marketplaceDetailsAnalytics(details);
  }
}
