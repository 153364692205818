import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { DashboardAPIService } from "../../../../rest-api/dashboard/dashboard-api.service";
import {
  MarketPartnerModel,
  MarketPlaceMakeDetailsModel,
  MarketPlaceMnfModel,
} from "../../../market-place/market-place.model";
import { MarketPlaceService } from "../../../market-place/market-place.service";
import { MarketPlaceAnalyticsModel } from "../../../../rest-api/dashboard/dashboard-api.model";

@Component({
  selector: "yt-partnerdetail-card",
  templateUrl: "partnerdetail-card.component.html",
  styleUrls: ["./partnerdetail-card.component.scss"],
})
export class PartnerDetailCardComponent implements OnInit, OnChanges {
  header: string;
  description: string;
  selectLabel: string;
  modelSelected: string = "default";
  marketPlaceMnfList: MarketPlaceMakeDetailsModel;
  manufactureList: string[];
  manufactureInfo: MarketPlaceMnfModel;
  anyMnfStatus: boolean = false;
  @Input() techCard: MarketPartnerModel[];
  @Input() defaultValue: string;
  constructor(
    private marketPlaceService: MarketPlaceService,
    private dashboardAPIService: DashboardAPIService
  ) {}

  ngOnInit() {
    if (this.defaultValue === "ev") {
      this.header = "Search local dealerships online";
      this.description =
        "Find cars at dealerships near you before stepping foot on the lot.";
      this.selectLabel = "Select Make";
    } else {
      this.header = "Search for efficient products";
      this.description =
        "Find the right product that suits your need and also locate installers near you.";
      this.selectLabel = "Select Manufacturer";
    }
    this.loadManufactureList();
  }

  ngOnChanges(): void {
    if (
      this.techCard &&
      this.defaultValue &&
      this.marketPlaceService.marketPlaceMnfList
    ) {
      const key =
        `${this.defaultValue}MarketplaceMnfList` as keyof MarketPlaceMakeDetailsModel;
      if (this.marketPlaceService.marketPlaceMnfList[key]) {
        const list = this.marketPlaceService.marketPlaceMnfList[key];
        this.manufactureList = list.map(
          (model: MarketPlaceMnfModel) => model.mnf
        );
      } else {
        this.manufactureList = [];
      }
      this.anyMnfStatus = false;
    } else {
      this.manufactureList = [];
    }
  }

  loadManufactureList(): void {
    this.marketPlaceService
      .getMarketPlaceMakeDetails()
      .subscribe((data: MarketPlaceMakeDetailsModel) => {
        const key =
          `${this.defaultValue}MarketplaceMnfList` as keyof MarketPlaceMakeDetailsModel;
        const list = data[key];
        if (Array.isArray(list)) {
          this.manufactureList = list.map(
            (item: MarketPlaceMnfModel) => item.mnf
          );
        }
      });
  }

  onChangeOfMake(models: string): void {
    this.manufactureInfo = this.marketPlaceService.marketPlaceMnfList[
      `${this.defaultValue}MarketplaceMnfList`
    ].filter((model) => {
      if (model.mnf === models) {
        return model;
      }
    })[0];
    this.anyMnfStatus = !!(
      this.modelSelected === "Any" && this.defaultValue === "hpwh"
    );
  }

  isPrimaryLinkButtonDisabled(): boolean {
    return (
      this.modelSelected === "default" ||
      this.manufactureInfo.primaryLink === null
    );
  }

  isSecondaryLinkButtonDisabled(): boolean {
    return (
      this.modelSelected === "default" ||
      this.manufactureInfo.secondaryLink === null
    );
  }

  onPrimaryButton(): void {
    if (this.manufactureInfo) {
      this.updateAnalytics("primary");
      this.marketPlaceService.openShopNowTab(this.manufactureInfo.primaryLink);
    }
  }

  onSecondaryButton(): void {
    if (this.anyMnfStatus) {
      this.onPrimaryButton();
    } else {
      this.updateAnalytics();
      this.marketPlaceService.openShopNowTab(
        this.manufactureInfo.secondaryLink
      );
    }
  }

  onEnergyStar(): void {
    window.open(this.manufactureInfo.secondaryLink, "_blank");
  }
  onShopping(link: string, linkId: string): void {
    const details: MarketPlaceAnalyticsModel = {
      tech: this.defaultValue,
      linkId: linkId,
    };
    this.dashboardAPIService.marketplaceDetailsAnalytics(details);
    this.marketPlaceService.openShopNowTab(link);
  }

  getButtonText(type: string): string {
    if (this.defaultValue === "ev") {
      if (type === "DEALERS") {
        return "FIND DEALERS NEAR ME";
      } else {
        return "SCHEDULE TEST DRIVE";
      }
    } else {
      if (type === "LOCATION") {
        return "LOCATE INSTALLERS";
      } else if (this.anyMnfStatus) {
        return "VIEW PRODUCTS";
      } else {
        return "VIEW PRODUCTS";
      }
    }
  }

  updateAnalytics(button?: string): void {
    if (button === "primary") {
      if (this.defaultValue === "ev") {
        this.updateMarketplaceAnalytics("DEALERS");
      } else if (this.anyMnfStatus) {
        this.updateMarketplaceAnalytics("INSTALLERS");
      } else {
        this.updateMarketplaceAnalytics("PRODUCTS");
      }
    } else {
      if (this.defaultValue === "ev") {
        this.updateMarketplaceAnalytics("TESTDRIVE");
      } else {
        this.updateMarketplaceAnalytics("INSTALLERS");
      }
    }
  }

  updateMarketplaceAnalytics(link: string): void {
    const details: MarketPlaceAnalyticsModel = {
      tech: this.defaultValue,
      linkId: link,
    };
    this.dashboardAPIService.marketplaceDetailsAnalytics(details);
  }
}
