import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ToggleButtonComponent } from './toggle-button.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonToggleModule
  ],
  exports: [ToggleButtonComponent],
  declarations: [ToggleButtonComponent]
})
export class ToggleButtonModule { }
