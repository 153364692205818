<scroll-top></scroll-top>
<div class="page-container">
  <div class="padding-class">
    <div class="yt_maxWidth">
      <div class="header">Your favorites</div>
      <section class="favorite-wrapper" *ngIf="selectedCategory || favoritesList.length">
        <ng-container *ngFor="let favorite of favoritesList">
          <div class="card">
            <!-- <mat-icon
              class="favorite_icon"
              (click)="onRemove(favorite.techParameter)"
              matTooltip="Remove from favorites"
              [ngClass]="{
                isDisabled: favoriteLoading === favorite.techParameter
              }"
            >
              favorite
            </mat-icon> -->
            <div class="image-wrapper">
              <img
                [src]="favorite.img"
                [alt]="favorite.techName"
                class="image"
              />
            </div>
            <div class="tech-name" [matTooltip]="favorite.techName">
              {{ favorite.techName }}
            </div>
            <div
              class="savings-value"
              [ngClass]="{ 'error-savings': favorite.isNegative }"
            >
              {{ favorite.dollarSavings | currency : "$" : "symbol" : "1.0-0" }}
            </div>
            <div
              class="savings-label"
              [ngStyle]="{
                'padding-bottom':
                  !lifeStyleModel.marketplace ||
                  favorite.techParameter === 'everGreen'
                    ? '0px'
                    : '10px'
              }"
            >
              <span *ngIf="twentyYear">20 year</span>
              <span *ngIf="tenYear">10 year</span>
              <span *ngIf="oneYear">1 year</span
              ><span *ngIf="oneMonth">1 month</span> savings
            </div>
            <button
              mat-raised-button
              color="accent"
              class="show-now"
              *ngIf="
                lifeStyleModel.marketplace &&
                favorite.techParameter !== 'everGreen'
              "
              (click)="onShopNow(favorite.techParameter, favorite.link)"
            >
              SHOP NOW
            </button>
            <button
              mat-raised-button
              class="view-buying-guide"
              *ngIf="
                favorite.guideLink && favorite.techParameter !== 'everGreen'
              "
              (click)="
                openGuidanceLink(favorite.guideLink, favorite.techParameter)
              "
            >
              View Buying Guide
            </button>
          </div>
        </ng-container>
      </section>
      <div *ngIf="!favoritesList.length" class="info-text">
        <img src="assets/dashboard/overview/noFavorites.png" alt="" />
        <div>Your list it empty!</div>
        <p class="noTechs_text">
          Don’t know what to add? Get started by exploring our recommendations!
        </p>
        <button
          mat-raised-button
          color="accent"
          (click)="onAddTech()"
          class="view-buying-guide"
        >
          Mark your favorites
          <mat-icon class="fav_icon">favorite_border</mat-icon>
        </button>
      </div>
      <div class="header">Products that you already have</div>
      <section class="already-have-wrapper" *ngIf="selectedCategory || alreadyHaveList.length">
        <ng-container *ngFor="let alreadyHave of alreadyHaveList">
          <div class="card">
            <mat-icon
              class="close-icon"
              [ngClass]="{
                isDisabled: removeAlreadyHaveTech === alreadyHave.techParameter
              }"
              (click)="onRemove(alreadyHave.techParameter)"
              >close</mat-icon
            >
            <div class="image-wrapper">
              <img
                [src]="alreadyHave.img"
                [alt]="alreadyHave.techName"
                class="image"
              />
            </div>
            <div class="tech-name" [matTooltip]="alreadyHave.techName">
              {{ alreadyHave.techName }}
            </div>
            <button
              mat-raised-button
              color="accent"
              class="show-now"
              *ngIf="
                lifeStyleModel.marketplace &&
                alreadyHave.techParameter !== 'everGreen'
              "
              (click)="
                onShopNow(alreadyHave.techParameter, alreadyHave.link, true)
              "
            >
              SHOP AGAIN
            </button>
          </div>
        </ng-container>
      </section>
      <div class="info-text" *ngIf="!alreadyHaveList.length">
        <img src="assets/dashboard/overview/noAlreadyHave.png" alt="" />
        <div>No products added yet!</div>
        <p class="noTechs_text">
          Indicate the products that you already have/purchased by
          clicking <mat-icon class="check_circle">
            check_circle_outline
          </mat-icon>
          icon under recommendations
        </p>
        <button
          mat-raised-button
          color="accent"
          (click)="onAddTech()"
          class="view-buying-guide"
        >
          Add products
        </button>
      </div>
    </div>
  </div>
</div>

<!-- loader -->
<yt-loader
  [blackLoader]="showBlackLoader"
  *ngIf="loader || (showBlackLoader && isSavingsAPIDispatched)"
  top="0"
  [relative]="true"
></yt-loader>
