import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NotificationModel } from "../notification-center.model";
import { NotificationCenterService } from "../notification-center.service";

@Component({
  selector: "yt-notifcation-home",
  templateUrl: "notification-home.component.html",
  styleUrls: ["./notification-home.component.scss"],
})
export class NotificationHomeComponent implements OnInit, OnDestroy {
  loader = true;
  haveNotification: boolean;
  NotificationModel: NotificationModel[];

  constructor(
    private router: Router,
    public notificationCenterService: NotificationCenterService
  ) {}

  ngOnInit() {
    this.notificationCenterService
      .getNotificationDetail()
      .subscribe((response) => {
        this.NotificationModel = response;
        if (this.NotificationModel.length === 0) {
          this.haveNotification = true;
        } else {
          this.haveNotification = false;
        }
        this.loader = false;
      });
  }

  openNotificationSetting(): void {
    this.router.navigateByUrl("/dashboard/notification-center/setting");
  }

  ngOnDestroy(): void {}
}
