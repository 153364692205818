import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSliderModule } from "@angular/material/slider";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CalculateMilesModule } from "../../../../shared/calculate-miles";
import { DialogBoxModule } from "../../../../shared/energy-info/Dialog-box";
import { PopupLoaderModule } from "../../../../shared/popup-loader";
import { CompareFinancialModule } from "../../../shared/compare-financials";
import { RadioButtonsComponent } from "../../../shared/update-settings/radio-buttons/radio-buttons.component";
import { TechRadioBoxesComponent } from "../../../shared/update-settings/techradio-boxes/techradio-boxes.component";
import { AppliancesComponent } from "./appliances/appliances.component";
import { HPDComponent } from "./appliances/hpd/hpd.component";
import { HPSCComponent } from "./appliances/hpsc/hpsc.component";
import { HPWHComponent } from "./appliances/hpwh/hpwh.component";
import { ICComponent } from "./appliances/ic/ic.component";
import { EnergyInfoComponent } from "./general/energy-info/energy-info.component";
import { EnergyInfoService } from "./general/energy-info/energy-info.service";
import { ManualEstimateComponent } from "./general/energy-info/manual-estimate/manual-estimate.component";
import { ShareMyDataComponent } from "./general/energy-info/share-my-data/share-my-data.component";
import { GeneralComponent } from "./general/general.component";
import { SettingOverlayComponent } from "./setting-overlay.component";
import { SimpleLowCostComponent } from "./simpleLow-cost/simpleLow-cost.component";
import { BatteryComponent } from "./solar-battery/battery/battery.component";
import { SolarBatteryComponent } from "./solar-battery/solar-battery.component";
import { CommunitySolarComponent } from "./solar-battery/solar/community-solar/community-solar.component";
import { SolarComponent } from "./solar-battery/solar/solar.component";
import { EBikeComponent } from "./transportation/ebike/ebike.component";
import { EvChargersComponent } from "./transportation/ev-chargers/ev-chargers.component";
import { EVComponent } from "./transportation/ev/ev.component";
import { TransportationComponent } from "./transportation/transportation.component";
import { TranslateModule } from "@ngx-translate/core";
import { SettingsOverlayService } from "./settings-overlay.service";
import { ConfirmPopupModule } from "../../../../shared/confirm-popup";
import { PortableBatteryComponent } from "./solar-battery/portable-battery/portable-battery.component";
import { MspOverlayComponent } from "../msp-overlay/msp-overlay.component";
import { LoaderModule } from "src/app/homeowner-dashboard/shared/loader";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDialogModule,
    MatButtonModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    CalculateMilesModule,
    CompareFinancialModule,
    PopupLoaderModule,
    MatSliderModule,
    MatTooltipModule,
    DialogBoxModule,
    TranslateModule,
    ConfirmPopupModule,
    LoaderModule
  ],
  declarations: [
    SettingOverlayComponent,
    TechRadioBoxesComponent,
    RadioButtonsComponent,
    GeneralComponent,
    SimpleLowCostComponent,
    TransportationComponent,
    SolarBatteryComponent,
    AppliancesComponent,
    EVComponent,
    EBikeComponent,
    HPWHComponent,
    ICComponent,
    HPDComponent,
    HPSCComponent,
    BatteryComponent,
    PortableBatteryComponent,
    SolarComponent,
    CommunitySolarComponent,
    EnergyInfoComponent,
    ManualEstimateComponent,
    ShareMyDataComponent,
    EvChargersComponent,
    MspOverlayComponent,
  ],
  exports: [SettingOverlayComponent],
  providers: [EnergyInfoService, SettingsOverlayService],
})
export class SettingOverlayModule {}
