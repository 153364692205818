import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DropDownModel } from "../../rest-api/rest-api.model";
import { DashboardAPIService } from "../../rest-api/dashboard/dashboard-api.service";
import {
  MarketPlaceDetailsModel,
  MarketPlaceMakeDetailsModel,
  MarketPlaceTechModel,
} from "./market-place.model";
import { Observable } from "rxjs";
import { Location } from "@angular/common";

@Injectable()
export class MarketPlaceService {
  baseUrl: string;
  userEmail: string;
  marketplaceDetailsUrl: string;
  energySageDetailsUrl: string;
  marketPlaceUrl: string;
  marketplaceMnfDetailsUrl: string;
  productTab: WindowProxy | null = null;
  marketPlaceMnfList: MarketPlaceMakeDetailsModel;
  marketPlaceTabsList: DropDownModel[] = [
    {
      value: "energyEfficientDevices",
      viewValue: "Clean & Smart Choices",
    },
    {
      value: "transportationAndAccessories",
      viewValue: "Transportation & Accessories",
    },
    {
      value: "solarBattery",
      viewValue: "Solar & Battery",
    },
    {
      value: "homeAppliances",
      viewValue: "Home Appliances",
    },
  ];

  constructor(
    private httpClient: HttpClient,
    private location: Location,
    private dashboardAPI: DashboardAPIService
  ) {
    this.baseUrl = `${this.dashboardAPI.API_END_POINT}`;
    this.marketplaceDetailsUrl = `${this.baseUrl}ho/dashboard/marketplaceTechDetails/`;
    this.energySageDetailsUrl = `${this.baseUrl}marketPlace/energySage/`;
    this.marketplaceMnfDetailsUrl = `${this.baseUrl}ho/dashboard/marketplaceDealershipDetails`;
    this.marketPlaceUrl = "assets/json/marketplace.json";
    this.userEmail = this.dashboardAPI.userDetails.userName;
  }

  marketPlaceTechDetails(): Observable<MarketPlaceTechModel> {
    return this.httpClient.get<MarketPlaceTechModel>(this.marketPlaceUrl);
  }

  marketPlaceDetails(): Observable<MarketPlaceDetailsModel> {
    let marketplaceTechDetailsUrl = `${this.marketplaceDetailsUrl}${
      this.dashboardAPI.getUserDetails().userName
    }`;
    return this.httpClient.get<MarketPlaceDetailsModel>(
      marketplaceTechDetailsUrl
    );
  }

  energySageDetails() {
    return this.httpClient.get<{ token: string }>(
      `${this.energySageDetailsUrl}${
        this.dashboardAPI.getUserDetails().userName
      }`
    );
  }

  getMarketPlaceMakeDetails(): Observable<MarketPlaceMakeDetailsModel> {
    return this.httpClient.get<MarketPlaceMakeDetailsModel>(
      this.marketplaceMnfDetailsUrl + `/${this.userEmail}`
    );
  }

  onTabChange(tabName: string, techName: string) {
    if (techName === "") {
      return this.location.replaceState(`/dashboard/market-place/${tabName}`);
    } else {
      return this.location.replaceState(
        `/dashboard/market-place/${tabName}/${techName}`
      );
    }
  }

  openShopNowTab(url: string): void {
    if (this.productTab == null || this.productTab.closed) {
      // Open a new tab if it doesn't exist or if it has been closed
      this.productTab = window.open(url, "_blank");
    } else {
      // Focus on the existing tab and navigate to the new URL
      this.productTab.focus();
      this.productTab.location.href = url;
    }
  }
}
