<scroll-top></scroll-top>
<div class="page-container">
  <div *ngIf="!sliderStatus" class="padding-class">
    <div class="yt_maxWidth w-100 center">
      <div class="yt_alert" id="alert" *ngIf="showAlert">
        <div class="alert_message">
          <div class="flex justify-between alert_padding yt_message">
            <span class="padding_right" *ngIf="sliderAlert">
              You can improve the accuracy of your results by authorizing PG&E
              to share your energy data. To do this, please
              <a class="alert_link_text" (click)="onEnergyUsage()"
                >click here</a
              >
              and log into PG&E.
            </span>
            <span class="padding_right" *ngIf="revokedAlert">
              Your PG&E utility data is not up to date.
              <a class="alert_link_text" (click)="onEnergyUsage()"
                >Click here</a
              >
              to reauthorize PG&E to share your energy data so it remains
              current.
            </span>
            <span class="padding_right" *ngIf="partialAlert">
              Incomplete energy authorization! Please
              <a class="alert_link_text" (click)="onEnergyUsage()"
                >click here</a
              >
              and provide access to (1) Basic (2) Usage & (3) Billing info.
            </span>
            <span
              class="fa fa-times pointer"
              *ngIf="!sliderAlert"
              (click)="onClose()"
            ></span>
          </div>
        </div>
      </div>
      <div class="w-100 flex-ns justify-between">
        <div class="heading_text">
          <span *ngIf="cash">Cost</span>
          <span *ngIf="energy">Usage</span>
          <span *ngIf="carbon">CO2 Emission</span>
          Details
        </div>
        <div class="toggle_buttons yt__energy_usage">
          <mat-button-toggle-group [(value)]="selected">
            <mat-button-toggle
              (click)="onCash()"
              value="CASH"
              *ngIf="costTabStatus"
            >
              <span class="toggle_label">COSTS</span>
            </mat-button-toggle>
            <mat-button-toggle
              value="ENERGY"
              class="label_margin"
              (click)="onEnergy()"
            >
              <span class="toggle_label">USAGE</span>
            </mat-button-toggle>
            <mat-button-toggle value="CARBON" (click)="onCarbon()">
              <span class="toggle_label">CO2 EMISSIONS</span>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div *ngIf="cash" class="w-100">
        <yt-cost-overview
          (sliderUser)="slider($event)"
          (pgeOnline)="pgeOnline($event)"
          (alertMessage)="alertStatus($event)"
          [usageChart]="usageChart"
          [usageChartHover]="usageChartHover"
        ></yt-cost-overview>
      </div>
      <div *ngIf="energy" class="w-100">
        <yt-usage-overview
          (sliderUser)="slider($event)"
          (pgeOnline)="pgeOnline($event)"
          (alertMessage)="alertStatus($event)"
          [usageChart]="usageChart"
          [usageChartHover]="usageChartHover"
        ></yt-usage-overview>
      </div>
      <div *ngIf="carbon" class="w-100">
        <yt-carbon-overview
          [usageChart]="usageChart"
          [usageChartHover]="usageChartHover"
        ></yt-carbon-overview>
      </div>
    </div>
  </div>

  <div
    *ngIf="sliderStatus"
    class="flex center justify-center vh-75 flex-column items-center error_padding"
  >
    <div class="error_heading_text">Energy Authorization</div>
    <div class="error_subheading_text" *ngIf="!pgeOnlineStatus && sliderStatus">
      You have currently selected the manual estimate approach and provided your
      average monthly electricity bill.
    </div>
    <div class="error_subheading_text" *ngIf="pgeOnlineStatus && sliderStatus">
      You have currently selected the manual estimate approach and provided your
      average monthly electricity bill. To view your usage, please
      <a class="link_text" (click)="onEnergyUsage()">click here</a>
      to provide access to your energy data.
    </div>
  </div>
</div>
