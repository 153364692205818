<div class="container">
  <div class="dashboard overlay_top_bg">
    <div class="overlay_top_content">
      <mat-icon class="mat-icon" (click)="closeOverlay()" mat-dialog-close
        >close</mat-icon
      >
      <div class="header mr3-ns">{{ header }}</div>
      <div class="subHeader">{{ subHeader }}</div>
    </div>
  </div>

  <div class="padding_10">
    <div class="incentives_container">
      <span class="border-b_mv">
        <div class="incentives_value">
          <div class="header">Total incentives</div>
          <div class="value">${{ totalIncentives | number }}</div>
        </div>
        <div class="disclaimer__message">
          Disclaimer: This is just an estimate. Please note certain incentives
          cannot be combined and hence the final eligible incentives may be
          lower.
        </div>
      </span>
      <div class="incentives_header">Detailed Incentives Breakdown</div>
      <div class="ExpandCollapse_link">
        <button (click)="expandAllPanels()">Expand All</button> |
        <button (click)="collapseAllPanels()">Collapse All</button>
      </div>
      <div class="panel_container">
        <div>TYPE</div>
        <div>AMOUNT</div>
      </div>
      <mat-accordion class="example-headers-align" multi>
        <ng-container *ngFor="let category of technologyIncentives | entries">
          <mat-expansion-panel
            (opened)="(true)"
            [expanded]="data.category === category[0]"
          >
            <mat-expansion-panel-header>
              <mat-panel-title class="header_wrapper category">
                <span>{{ category[0] | techName }}</span>
                <span>${{ category[1].total | number }}</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <mat-accordion multi>
                <ng-container *ngFor="let tech of category[1] | entries">
                  <mat-expansion-panel
                    [expanded]="data.tech === tech[0]"
                    *ngIf="
                      tech[0] !== 'total' && tech[1]['rebateDetails'].length
                    "
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title class="header_wrapper technology">
                        <span>{{ tech[0] | techName }}</span>
                        <span>${{ tech[1]["incentives"] | number }}</span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div
                      class="incentives_lables"
                      *ngFor="let rebate of tech[1]['rebateDetails']"
                    >
                      <span class="minW_40" *ngIf="rebate.rebateLink">
                        <a
                          class="yt_rebateName yt_rebateName_link"
                          [href]="rebate.rebateLink"
                          target="_blank"
                        >
                          <span>{{ rebate.rebateName }}</span> </a
                        ><span *ngIf="rebate.specialCriteria === 'Yes'">*</span>
                      </span>
                      <span
                        *ngIf="!rebate.rebateLink"
                        class="none_link minW_40"
                      >
                        {{ rebate.rebateName }}
                      </span>
                      <span class="tr">${{ rebate.rebate | number }}</span>
                    </div>
                  </mat-expansion-panel>
                </ng-container>
              </mat-accordion>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
      <p class="special_criteria" *ngIf="showSpecialCriteria">
        * Special conditions may apply
      </p>
    </div>
  </div>
  <!-- loader -->
  <yt-loader *ngIf="loader" top="0" [relative]="true"></yt-loader>
</div>
