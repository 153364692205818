import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RestAPIService } from "../../rest-api/rest-api.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { RequestMessageModel } from "../../rest-api/rest-api.model";

@Component({
  selector: "yt-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.scss"],
})
export class ContactUsComponent implements OnInit {
  descriptionText: FormGroup;
  @Input() showPopup: boolean;
  @Output() closePopup: EventEmitter<boolean> = new EventEmitter();
  @ViewChild("contact_popup", { read: ElementRef }) contactModal: ElementRef;

  constructor(
    private fb: FormBuilder,
    private restAPI: RestAPIService,
    private snackBar: MatSnackBar
  ) {
    this.descriptionText = this.fb.group({
      userText: ["", Validators.required],
    });
  }

  @HostListener("document:keydown", ["$event"])
  @HostListener("document:click", ["$event"])
  onEvent(event: KeyboardEvent | MouseEvent) {
    if (this.contactModal) {
      if (event instanceof KeyboardEvent && event.key === "Escape") {
        this.closePopup.emit(false);
      } else if (
        event instanceof MouseEvent &&
        this.contactModal.nativeElement &&
        event.target === this.contactModal.nativeElement
      ) {
        this.closePopup.emit(false);
      }
    }
  }

  ngOnInit(): void {}

  onSubmit(): void {
    const userRequest: RequestMessageModel = {
      message: this.descriptionText.get("userText")?.value,
    };
    this.restAPI.requestMessage(userRequest, "contactUs").subscribe(
      (data) => {
        if (data.message === "Mailed") {
          this.snackBar.open(
            "Your message has been sent. Our team will reach out to you shortly.",
            "OK",
            {
              duration: 2000,
            }
          );
          this.onClosePopup();
        }
      },
      (error) => console.error(error.error.message)
    );
  }

  onClosePopup() {
    this.closePopup.emit(false);
  }
}
