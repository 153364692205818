<mat-card
  class="example-card pointer"
  [ngClass]="
    selectedValue ? 'yt__mat-border' : visited ? 'yt_mat-grayborder' : ''
  "
>
  <div *ngIf="image">
    <img
      mat-card-image
      [src]="image"
      [alt]="displayValue"
      loading="lazy"
      class="yt__mat-card-img"
    />
  </div>
  <mat-card-content>
    <div class="yt__f4 fw6 pointer nowrap tc">{{ displayValue }}</div>
  </mat-card-content>
</mat-card>
