import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DashboardAPIService } from "../../../../../rest-api/dashboard/dashboard-api.service";
import {
  AeratorsShowerHeadsDataModel,
  LEDDataModel,
  SimpleLowCostDataModel,
  SmartPowerStripsDataModel,
  SmartThermostatDataModel,
  WeatherStrippingDataModel,
} from "../../../model/userdata.model";
import { SettingsOverlayService } from "../settings-overlay.service";
import { Subject } from "rxjs";

@Component({
  selector: "yt-simpleLow-cost",
  templateUrl: "simpleLow-cost.component.html",
  styleUrls: ["./simpleLow-cost.component.scss"],
})
export class SimpleLowCostComponent implements OnInit {
  simpleLowCostForm: FormGroup;
  lowCostDataModel: SimpleLowCostDataModel;
  simpleLowCost: SimpleLowCostDataModel;
  @Input() activeTech: string;
  @Input() activeIcon: string;
  subject: Subject<any> = new Subject();

  constructor(
    private fb: FormBuilder,
    private dashboardAPI: DashboardAPIService,
    private settingService: SettingsOverlayService
  ) {
    this.simpleLowCostForm = this.fb.group({
      ledCflBulbs: ["yesPlan", Validators.required],
      smartThermostat: ["yesPlan", Validators.required],
      weatherStripping: ["yesPlan", Validators.required],
      smartPowerStrips: ["yesPlan", Validators.required],
      aerators: ["yesPlan", Validators.required],
    });
  }

  ngOnInit(): void {
    this.lowCostDataModel = this.settingService.checkSettingsInfoError(
      "simpleLowCost"
    )
      ? this.settingService.settingsInfoErrors.simpleLowCost!
      : this.settingService.checkSettingsInfo("simpleLowCost")
      ? this.settingService.settingsInfo.simpleLowCost!
      : this.dashboardAPI.energyChoicesData.simpleLowCost;
    this.lowCostDataModel.ledCflBulbs.forEach((ledCflBulbs: LEDDataModel) => {
      this.simpleLowCostForm.get("ledCflBulbs")?.setValue(ledCflBulbs.own);
    });
    this.lowCostDataModel.smartThermostat.forEach(
      (smartThermostat: SmartThermostatDataModel) => {
        this.simpleLowCostForm
          .get("smartThermostat")
          ?.setValue(smartThermostat.own);
      }
    );
    this.lowCostDataModel.weatherStripping.forEach(
      (weatherStripping: WeatherStrippingDataModel) => {
        this.simpleLowCostForm
          .get("weatherStripping")
          ?.setValue(weatherStripping.own);
      }
    );
    this.lowCostDataModel.smartPowerStrips.forEach(
      (smartPowerStrip: SmartPowerStripsDataModel) => {
        this.simpleLowCostForm
          .get("smartPowerStrips")
          ?.setValue(smartPowerStrip.own);
      }
    );
    this.lowCostDataModel.showerHeadsAerators.forEach(
      (aerators: AeratorsShowerHeadsDataModel) => {
        this.simpleLowCostForm.get("aerators")?.setValue(aerators.own);
      }
    );
    if (this.activeIcon) {
      if (this.activeIcon === "favorite") {
        this.simpleLowCostForm.get(this.activeTech)?.setValue("yesPlan");
      } else if (this.activeIcon === "alreadyHave") {
        this.simpleLowCostForm.get(this.activeTech)?.setValue("yes");
      }
    }
    this.onValueChanges();
  }

  onValueChanges(): void {
    this.simpleLowCostForm.valueChanges.subscribe((data) => {
      this.subject.pipe().subscribe(() => {
        this.simpleLowCost = {
          ledCflBulbs: [{ own: data.ledCflBulbs }],
          smartPowerStrips: [{ own: data.smartPowerStrips }],
          smartThermostat: [{ own: data.smartThermostat }],
          showerHeadsAerators: [{ own: data.aerators }],
          weatherStripping: [{ own: data.weatherStripping }],
        };
        if (this.simpleLowCostForm.valid) {
          this.settingService.updateSettingsInfo(
            this.dashboardAPI.energyChoicesData.simpleLowCost,
            this.simpleLowCost,
            "simpleLowCost"
          );
        } else {
          this.settingService.updateSettingsInfoErrors(
            this.dashboardAPI.energyChoicesData.simpleLowCost,
            this.simpleLowCost,
            "simpleLowCost"
          );
        }
      });
      this.subject.next();
    });
  }

  onSaveLowCostTech(): void {
    this.settingService.putSettingsInfo();
  }
}
