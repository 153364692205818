import { RestAPIService } from "../../../rest-api/rest-api.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatSliderOptionsModel } from "../energy-info.model";
import { Router } from "@angular/router";
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
} from "@angular/core";
import { AuthenticationService } from "../../../auth/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserDataModel } from "../../../homeowner-dashboard/overview/model/userdata.model";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";
import { OverviewService } from "src/app/homeowner-dashboard/overview/overview.service";

@Component({
  selector: "yt-manual-entry",
  templateUrl: "manual-entry.component.html",
  styleUrls: ["./manual-entry.component.scss"],
})
export class ManualEntryComponent implements OnInit, OnDestroy {
  estimateForm: FormGroup;
  @Input() utilityName: string;
  @Input() utilityId: any;
  @Input() isDisableButton: boolean;
  @Input() cceOptOut: boolean;
  @Input() haveAutoLogin: boolean;
  @Input() isDashboard: boolean;
  @Output() redirectLoaderStatus: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private restAPIService: RestAPIService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private overviewService: OverviewService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private dashboardAPI: DashboardAPIService
  ) {
    this.estimateForm = this.formBuilder.group({
      monthlyBill: [],
    });
  }

  ngOnInit(): void {
    let roofTopPage = this.restAPIService.getSmdLink();
    if (!roofTopPage) {
      this.getBuildingTypeValue();
    } else {
      this.sliderModel = this.residentialTownHomeSliderOptions;
    }
    this.isDashboard ? this.getManualEstimate() : this.setManualEstimate();
  }

  getManualEstimate(): void {
    this.overviewService.userSettingInfo.subscribe(
      (response: UserDataModel) => {
        this.monthlyBill = Number(response.avgMonthlyBill);
      }
    );
  }

  setManualEstimate(): void {
    this.monthlyBill = this.sliderModel.default;
  }

  get monthlyBill(): number {
    return this.estimateForm.get("monthlyBill")?.value;
  }

  set monthlyBill(billValue: number) {
    this.estimateForm.get("monthlyBill")?.setValue(billValue);
  }

  onSubmit(): void {
    let button = document.querySelector<HTMLElement>("#loaderButton");
    if (button) {
      button.innerHTML =
        'Submitting... &nbsp;<i class="fa fa-spinner fa-spin"></i>';
      button.style.pointerEvents = "none";
    }
    if (this.monthlyBill === null) {
      this.monthlyBill = this.sliderModel.default;
    }
    this.restAPIService
      .postAvgMonthlyBill(
        this.monthlyBill.toString(),
        this.utilityName,
        this.utilityId,
        this.cceOptOut
      )
      .subscribe((data) => {
        if (data.status === 200) {
          setTimeout(() => {
            if (button) button.innerHTML = "SUBMIT";
            this.checkHaveAutoLogin();
          }, 1500);
        }
      });
  }

  onSaveEstimate(): void {
    this.overviewService
      .updateUserSettingInfo({
        avgMonthlyBill: this.monthlyBill,
      })
      .subscribe((response: any) => {
        if (response.status === 200) {
          this.snackBar.open("Changes Saved.", "OK", {
            duration: 8000,
          });
        }
      });
  }

  checkHaveAutoLogin(): void {
    if (this.haveAutoLogin) {
      this.authenticationService.getAutoLoginDetails().subscribe((data) => {
        this.authenticationService.setJWTToken(data.token);
        const vendorCode = this.restAPIService.getVendorName();
        this.dashboardAPI.getVendorDetails(vendorCode, true);
      });
    } else {
      this.router.navigateByUrl("sign-up/thank-you");
    }
  }

  sliderModel: MatSliderOptionsModel = {
    min: 0,
    max: 0,
    default: 0,
    step: 0,
  };

  residentialTownHomeSliderOptions: MatSliderOptionsModel = {
    min: 35,
    max: 500,
    default: 95,
    step: 5,
  };

  nonProfitCommercialSliderOptions: MatSliderOptionsModel = {
    min: 0,
    max: 10000,
    default: 1000,
    step: 50,
  };

  getBuildingTypeValue(): void {
    let buildingType = this.restAPIService.getBuildingType();
    if (buildingType === "nonProfit" || buildingType === "commercial") {
      this.sliderModel = this.nonProfitCommercialSliderOptions;
    } else {
      this.sliderModel = this.residentialTownHomeSliderOptions;
    }
  }

  ngOnDestroy(): void {}
}
