import { Component } from "@angular/core";
import { LifestyleModel } from "../../overview/model/solarvendordetails.model";
import { JWTTokenInfoModel } from "../../../auth/auth.model";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";
import { AppService } from "../../../app.service";
import { DashboardLayoutService } from "./dashboard-layout.service";
import { RouterInfoModel } from "../top-nav/router-info.model";
import {
  LogoModel,
  RoofTypesModel,
  SolarVendorModel,
} from "../../../rest-api/rest-api.model";
import { Title } from "@angular/platform-browser";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "yt-dashboard-layout",
  templateUrl: "./dashboard-layout.component.html",
})
export class DashboardLayoutComponent {
  public solarVendor: SolarVendorModel;
  public showSpinner = true;
  lifeStyleModel: LifestyleModel;
  logoModel: LogoModel;
  smd;
  private alive = true;
  routeInfo: RouterInfoModel;
  hideSidenav: boolean;
  smdLink: boolean;
  jwtTokenInfo: JWTTokenInfoModel;
  languageTranslator: RoofTypesModel[];
  address: string;
  name: string;
  code: string;

  constructor(
    private layoutService: DashboardLayoutService,
    private appService: AppService,
    private dashboardAPI: DashboardAPIService,
    private titleService: Title
  ) {}

  ngOnInit() {
    let isUserLoggedIn = false;
    if (this.dashboardAPI.getUserDetails()) {
      isUserLoggedIn = !!Object.keys(this.dashboardAPI.getUserDetails()).length;
    }
    this.routeInfo = {
      personalScreen: {
        showLink: true,
        activatedLink: true,
        disabledLink: false,
      },
      roofTop: {
        showLink: true,
        activatedLink: false,
        disabledLink: true,
      },
      lifeStyle: {
        showLink: true,
        activatedLink: false,
        disabledLink: true,
      },
      additionalinfo: {
        showLink: true,
        activatedLink: false,
        disabledLink: true,
      },
      upgrades: {
        showLink: false,
        activatedLink: false,
        disabledLink: true,
      },
      energyUsage: {
        showLink: true,
        activatedLink: false,
        disabledLink: true,
      },
      overview: {
        showLink: false,
        activatedLink: false,
        disabledLink: true,
      },
      logo1: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      logo2: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      usermenu: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      logout: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      poweredbyYt: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      topnav: {
        showLink: isUserLoggedIn,
        activatedLink: false,
        disabledLink: false,
      },
      topnavlogin: {
        showLink: !isUserLoggedIn,
        activatedLink: false,
        disabledLink: false,
      },
    };
    this.layoutService.getSmdLink$
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => {
        if (data) {
          this.routeInfo = {
            ...this.routeInfo,
            personalScreen: {
              showLink: true,
              activatedLink: true,
              disabledLink: this.routeInfo.personalScreen?.disabledLink!,
            },
            roofTop: {
              showLink: false,
              activatedLink: false,
              disabledLink: true,
            },
            lifeStyle: {
              showLink: false,
              activatedLink: false,
              disabledLink: true,
            },
            additionalinfo: {
              showLink: false,
              activatedLink: false,
              disabledLink: true,
            },
            upgrades: {
              showLink: false,
              activatedLink: false,
              disabledLink: true,
            },
            energyUsage: {
              showLink: true,
              activatedLink: false,
              disabledLink: true,
            },
            overview: {
              showLink: false,
              activatedLink: false,
              disabledLink: true,
            },
          };
        }
      });
    this.layoutService.navRouter$
      .pipe(takeWhile((_) => this.alive))
      .subscribe((routeInfo) => {
        this.routeInfo = routeInfo;
      });
    this.layoutService.hideSidenav$
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => {
        this.hideSidenav = data;
      });
    this.layoutService.getSmdLink$
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => {
        this.dashboardAPI.setSmdLink(data);
      });
    this.appService.jwtToken$
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => (this.jwtTokenInfo = data));
    this.appService.address$
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => (this.address = data));
    this.appService.name$
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => (this.name = data));
    this.appService.dashboardlogoModel$
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => (this.logoModel = data));
    this.appService.currentVendorName$
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {
        this.code = data;
      });
    this.appService.languageModel$
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {
        this.languageTranslator = data;
      });
    this.appService.lifeStyleModel$
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => (this.lifeStyleModel = data));
    let vendorCode = (this.code = this.appService.getVendorName());
    this.appService.solarVendorModel.subscribe((response: SolarVendorModel) => {
      this.languageTranslator = response.languageTranslator;
      this.dashboardAPI.setSolarVendorDetails(response);
      this.solarVendor = response;
      this.showSpinner = false;
    });
    this.titleService.setTitle(
      `Dashboard | ${this.appService.vendorsList[vendorCode].title}`
    );
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
