import { JWTTokenInfoModel } from "./auth.model";
import * as jwt_decode from "jwt-decode";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RestAPIService } from "../rest-api/rest-api.service";
import { ChangePasswordModel } from "../homeowner-dashboard/change-password/change-password.model";
import { take, takeWhile, tap } from "rxjs/operators";
import { AppService } from "../app.service";

@Injectable()
export class AuthenticationService {
  private token: string;
  public jwtTokenInfo: JWTTokenInfoModel;
  private credentials: string;
  private authenticationUrl: string;
  private changePasswordUrl: string;
  private directLoginUrl: string;
  private alive = true;
  redirectUrl: string;

  constructor(
    private httpClient: HttpClient,
    private restAPIService: RestAPIService,
    private appService: AppService
  ) {
    this.authenticationUrl =
      this.restAPIService.API_END_POINT + "auth/homeOwnerLogin";
    this.changePasswordUrl =
      restAPIService.API_END_POINT + "auth/homeOwnerChangePwd";
    this.directLoginUrl =
      restAPIService.API_END_POINT + "auth/homeOwnerDirectLogin/";
  }

  login(username: string, password: string) {
    let authCredentials = username + ":" + password;
    this.credentials = authCredentials;

    return this.httpClient.get<{ token: string }>(this.authenticationUrl).pipe(
      tap((data) => {
        let token = data.token;
        if (token) {
          this.token = token;
          this.setJWTToken(token);
          return true;
        } else {
          return false;
        }
      })
    );
  }

  setJWTToken(token: string): void {
    this.token = token;
    let tokenJson = jwt_decode(token);
    this.jwtTokenInfo = {
      sub: tokenJson.sub,
      userName: tokenJson.userName,
      userDisplayName: tokenJson.displayName,
      solarVendorCode: tokenJson.solarVendorCode,
      solarVendorDescription: tokenJson.solarVendorDescription,
      userType: tokenJson.userType,
    };
  }

  logout(): void {
    this.token = "";
    this.jwtTokenInfo = {} as JWTTokenInfoModel;
    sessionStorage.removeItem("token");
  }

  getJwtToken(): string {
    if (!this.token) {
      let token: string = sessionStorage.getItem("token")!;
      if (token) {
        this.setJWTToken(token);
        this.token = token;
      }
    }
    return this.token;
  }

  updateTheme(vendorCode): void {
    this.restAPIService
      .getSolarVendorDetails(vendorCode)
      .pipe(
        take(1),
        takeWhile(() => this.alive)
      )
      .subscribe((data) => {
        this.appService.updateTheme(data.themeClasses);
      });
  }

  getJwtTokenInfo() {
    return this.jwtTokenInfo.userName;
  }

  getHttpClientHeaders(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.applyAuthorizationHeader(),
    });
  }

  applyAuthorizationHeader(): string {
    if (this.token) {
      return "Bearer " + this.token;
    } else {
      return "Basic " + btoa(this.credentials);
    }
  }

  resetPassword(resetPasswordData: ChangePasswordModel) {
    return this.httpClient.put<{ message: string }>(
      this.changePasswordUrl,
      resetPasswordData
    );
  }

  getAutoLoginDetails() {
    return this.httpClient
      .get<{ token: string }>(
        this.directLoginUrl + this.restAPIService.homeOwnerEmail
      )
      .pipe(tap((data) => {}));
  }
}
