import { Component, OnInit } from "@angular/core";
import { takeWhile } from "rxjs/operators";
import { AppService } from "src/app/app.service";
import { LogoModel } from "src/app/rest-api/rest-api.model";

@Component({
  selector: "yt-dashboard-footer",
  templateUrl: "dashboard-footer.component.html",
  styleUrls: ["./dashboard-footer.component.scss"],
})
export class DashboardFooterComponent implements OnInit {
  logo: LogoModel;
  private alive = true;

  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appService.dashboardlogoModel$
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => {
        this.logo = data;
      });
  }
}
