import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { TopNavComponent } from "./shared/top-nav/top-nav.component";
import { HomeOwnerInfoComponent } from "./homeowner-info.component";
import { LayoutComponent } from "./layout/layout.component";
import { MainSectionComponent } from "./main-section/main-section.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { MatSliderModule } from "@angular/material/slider";
import { HPInfoRoutingModule } from "./homeowner-info-routing";
import { AddressComponent } from "./main-section/address/address.component";
import { UtilityProviderComponent } from "./main-section/utility-provider/utility-provider.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { HomeOwnerInfoService } from "./homeowner-info.service";
import { PersonalInfoComponent } from "./main-section/personal-info/personal-info.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FooterComponent } from "./shared/footer/footer.component";
import { ErrorPopupComponent } from "./shared/error-popup/error-popup.component";
import { MatStepperModule } from "@angular/material/stepper";
import { MatRadioModule } from "@angular/material/radio";
import { TranslateModule } from "@ngx-translate/core";
import { MatMenuModule } from "@angular/material/menu";
import { ConfirmPopupModule } from "../shared/confirm-popup";
import { GoogleMapsModule } from "@angular/google-maps";
import { Utils } from "../utils/util";
import { GoalComponent } from "./main-section/goal/goal.component";
import { GoalCardsInitial } from "./main-section/goal/goal.init";
import { ExpertAdvicePopupModule } from "../shared/expert-popup";

@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatCardModule,
    MatButtonModule,
    MatSliderModule,
    HPInfoRoutingModule,
    MatCheckboxModule,
    MatIconModule,
    FormsModule,
    MatTooltipModule,
    MatStepperModule,
    MatRadioModule,
    TranslateModule,
    MatMenuModule,
    ConfirmPopupModule,
    GoogleMapsModule,
    ExpertAdvicePopupModule,
  ],
  declarations: [
    TopNavComponent,
    LayoutComponent,
    HomeOwnerInfoComponent,
    MainSectionComponent,
    AddressComponent,
    UtilityProviderComponent,
    PersonalInfoComponent,
    FooterComponent,
    ErrorPopupComponent,
    GoalComponent,
  ],
  providers: [HomeOwnerInfoService, Utils, GoalCardsInitial],
  exports: [HomeOwnerInfoComponent, LayoutComponent],
})
export class HPJourneyModule {}
