import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthenticationService } from "../../auth/auth.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DashboardAPIService } from "../../rest-api/dashboard/dashboard-api.service";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "yt-change-password",
  templateUrl: "change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  hideCurrentPassword = true;
  hideNewPassword = true;
  invalidCurrentPassword: boolean;
  resetPassword: FormGroup;
  resetPasswordStatus: string;
  private alive = true;
  barLabel: string = "Password strength:";
  showSuccessMessage = false;
  showEmailInfoMsg: boolean;
  personElement: HTMLElement;

  userDetailsFormValidation = {
    currentPassword: "",
    newPassword: {
      required: "",
      pattern: "",
    },
  };

  validationErrorMessages = {
    currentPassword: {
      required: "Required!",
    },
    newPassword: {
      required: "Required!",
      pattern: "Password does not meet requirements",
    },
  };

  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private dashboardAPI: DashboardAPIService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.personElement = document.getElementById("person")!;
    if (this.personElement) this.personElement.style.font = "600";
    this.resetPassword = this.fb.group({
      currentPassword: ["", Validators.required],
      newPassword: ["", [Validators.required]],
    });
    this.showEmailInfoMsg = this.dashboardAPI.loggedInCount === 1;
    this.resetPassword.valueChanges
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => {
        this.onFormValueChanges(data);
      });
  }

  onFormValueChanges(data: any) {
    if (!this.resetPassword) {
      return;
    }
    this.checkForErrorValidations(data);
  }

  get newPassword() {
    return this.resetPassword.get("newPassword");
  }

  get currentPassword() {
    return this.resetPassword.get("currentPassword");
  }

  onSubmit() {
    let changePassword = {
      userName: this.dashboardAPI.getUserDetails().userName,
      currentPassword: this.currentPassword?.value,
      newPassword: this.newPassword?.value,
    };
    this.authenticationService
      .resetPassword(changePassword)
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => {
        this.invalidCurrentPassword =
          data.message === "Invalid current password";
        if (data.message === "Password changed successfully") {
          this.resetPasswordStatus = "";
          this.resetPassword.reset();
          this.showSuccessMessage = true;
        } else if (data.message === "Invalid current password") {
          this.resetPasswordStatus = "";
        } else {
          this.resetPasswordStatus = data.message;
        }
      });
  }

  checkForErrorValidations(data: any) {
    const form = this.resetPassword;
    const formErrors = this.userDetailsFormValidation;
    const validationMessage = this.validationErrorMessages;

    for (const field in formErrors) {
      if (field === "newPassword") {
        formErrors[field] = {
          required: "",
          pattern: "",
        };
      } else {
        formErrors[field] = "";
      }
      const control = form.get(field);
      if (control && control.dirty && control.invalid) {
        const messages = validationMessage[field];
        for (const key in control.errors) {
          if (field === "newPassword") {
            formErrors[field][key] += messages[key];
          } else {
            formErrors[field] += messages[key];
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.personElement) this.personElement.style.fontWeight = "normal";
  }
}
