import { Component, HostListener, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "yt-msp-overlay",
  templateUrl: "./msp-overlay.component.html",
  styleUrls: ["./msp-overlay.component.scss"],
})
export class MspOverlayComponent implements OnInit {
  icon: boolean = false;
  isPanelExpanded: boolean[] = new Array(4);
  constructor(private dialogRef: MatDialogRef<MspOverlayComponent>) {}

  @HostListener("document:keydown", ["$event"])
  onKeyDown(event) {
    if (event.key == "Escape") {
      this.dialogRef.close();
    }
  }

  ngOnInit(): void {}

  onPanelOpened(index: number) {
    this.isPanelExpanded[index] = true;
  }
  onPanelClosed(index: number) {
    this.isPanelExpanded[index] = false;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  scrollTop(): void {
    const element = document.getElementById("msp_overlay");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
}
