import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatRadioChange } from "@angular/material/radio";
import { DropDownModel } from "../../../rest-api/rest-api.model";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "yt-roof-QA",
  templateUrl: "roof-QA.component.html",
  styleUrls: ["./roof-QA.component.scss"],
})
export class RoofQAComponent implements OnInit, OnDestroy {
  @Input() defaultValue: string;
  @Input() buildingTypeDisplayModel: DropDownModel[];
  @Input() imagePath: string;
  @Input() header: string;
  @Output() buldingTypeSelect = new EventEmitter<string>();
  @Input() pngImage: boolean;

  buldingType: FormControl;
  private alive = true;

  constructor() {}

  ngOnInit() {
    this.buldingType = new FormControl(this.defaultValue);
    this.buldingType.valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {
        this.buldingTypeSelect.emit(data);
      });
  }

  radioChange(event: MatRadioChange) {
    this.defaultValue = event.value;
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
