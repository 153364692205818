import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  HostListener,
  ElementRef,
} from "@angular/core";
@Component({
  selector: "yt-error-dialog",
  templateUrl: "error-dialog.component.html",
  styleUrls: ["./error-dialog.component.scss"],
})
export class ErrorDialogComponent {
  @Input() cityStatus;
  @Input() loginaccess;
  @Input() duplicationuserror;
  @Input() showErrorDialogueBox;
  @Input() forgotPasswordLink;
  @Input() loginLink;
  @Output() isDialogboxOpened = new EventEmitter<any>();
  @ViewChild("errorDialogueOverlay", { read: ElementRef }) modal: ElementRef;

  constructor() {}

  @HostListener("document:keydown", ["$event"])
  onKeyDown(event) {
    if (event.key == "Escape") {
      this.closeErrorDialogBox();
    }
  }
  @HostListener("document:click", ["$event"])
  onDocumentClick(event) {
    if (this.modal) {
      if (event.target === this.modal.nativeElement) {
        this.closeErrorDialogBox();
      }
    }
  }

  ngOnInit() {}

  closeErrorDialogBox() {
    this.isDialogboxOpened.emit();
    this.showErrorDialogueBox = false;
  }
}
