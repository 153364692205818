<div
  class="paragraph"
  [innerHTML]="
    'homeOwenerDashboard.settingsOverlay.simpleLowCost.paragraph' | translate
  "
></div>
<div class="container" [formGroup]="simpleLowCostForm">
  <div class="label_container">
    <div class="image-box">
      <div class="icon_section">
        <mat-icon class="radio-icons mat-symbol-oulined">
          check_circle
        </mat-icon>
      </div>
      <div class="yt__font pl0">ALREADY HAVE</div>
    </div>

    <div class="image-box">
      <div class="icon_section">
        <mat-icon class="radio-icons mat-symbol-oulined"> favorite </mat-icon>
      </div>
      <div class="yt__font pl0">ADD TO FAVORITES</div>
    </div>
  </div>

  <div class="tech_container">
    <div class="techLabel">LED Bulbs</div>
    <mat-radio-group class="radio_wrapper" formControlName="ledCflBulbs">
      <mat-radio-button value="yes"></mat-radio-button>
      <mat-radio-button value="yesPlan"></mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="tech_container background_color">
    <div class="techLabel">Smart Power Strips</div>
    <mat-radio-group class="radio_wrapper" formControlName="smartPowerStrips">
      <mat-radio-button value="yes"></mat-radio-button>
      <mat-radio-button value="yesPlan"></mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="tech_container">
    <div class="techLabel">Smart Thermostat</div>
    <mat-radio-group class="radio_wrapper" formControlName="smartThermostat">
      <mat-radio-button value="yes"></mat-radio-button>
      <mat-radio-button value="yesPlan"></mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="tech_container background_color">
    <div class="techLabel">Weather Stripping</div>
    <mat-radio-group class="radio_wrapper" formControlName="weatherStripping">
      <mat-radio-button value="yes"></mat-radio-button>
      <mat-radio-button value="yesPlan"></mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="tech_container">
    <div class="techLabel">Showerheads and Aerators</div>
    <mat-radio-group class="radio_wrapper" formControlName="aerators">
      <mat-radio-button value="yes"></mat-radio-button>
      <mat-radio-button value="yesPlan"></mat-radio-button>
    </mat-radio-group>
  </div>
</div>
<div class="yt_form-button w-100">
  <button
    mat-raised-button
    color="accent"
    (click)="onSaveLowCostTech()"
    class="fr yt__mb-12 w-100 w-30-ns hover-save"
  >
    SAVE
  </button>
</div>
