import { Component, Inject, OnInit, QueryList, ViewChildren } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IncentivesAPIService } from "../../../../../incentives/incentives.service";
import {
  TechIncentivesModel,
  TechnologyIncentivesModel,
  TechnologyModel,
} from "../../../../../incentives/incentives.model";
import { MatExpansionPanel } from "@angular/material/expansion";

@Component({
  selector: "yt-incentives-overlay",
  templateUrl: "./incentives-overlay.component.html",
  styleUrls: ["./incentives-overlay.component.scss"],
})
export class IncentivesOverlayComponent implements OnInit {
  loader: boolean = true;
  header: string;
  subHeader: string;
  totalIncentives: number;
  technologyIncentives: TechnologyModel;
  technologyIncentivesList: [string, TechIncentivesModel];
  showSpecialCriteria: boolean;
  @ViewChildren(MatExpansionPanel) panels!: QueryList<MatExpansionPanel>;

  constructor(
    private dialogRef: MatDialogRef<IncentivesOverlayComponent>,
    private incentivesAPI: IncentivesAPIService,
    @Inject(MAT_DIALOG_DATA) public data: { category: string; tech: string }
  ) {}

  ngOnInit(): void {
    this.incentivesAPI
      .getDashboardIncentivesDetails("ALL")
      .subscribe((response: TechnologyIncentivesModel) => {
        this.loader = false;
        this.header = "YOUR PERSONALIZED INCENTIVES";
        this.subHeader =
          "Know the savings you are eligible for. Check the incentives applicable to your house location and building type.";
        this.totalIncentives = response.totalIncentives!;
        this.technologyIncentives = response.technologyIncentives;
        this.showSpecialCriteria = this.hasSpecialCriteria();
      });
  }

  hasSpecialCriteria(): boolean {
    for (const category of Object.values(this.technologyIncentives)) {
      for (const tech of Object.values(category)) {
        if (tech && typeof tech === "object") {
          for (const rebate of tech["rebateDetails"]) {
            return rebate.specialCriteria === "Yes";
          }
        }
      }
    }
    return false;
  }

  closeOverlay(): void {
    this.dialogRef.close();
  }

  expandAllPanels(): void {
    const allPanels = this.panels.toArray();
    allPanels.forEach((panel) => panel.open());
  }

  collapseAllPanels(): void {
    const allPanels = this.panels.toArray();
    allPanels.forEach((panel) => panel.close());
  }
}
