import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { BatteryFinancialModel } from "../../learn-more/battery-details/battery-details.model";
import { SolarFinancialModel } from "../../learn-more/solar-details/solar-details.model";
import { EVFinancialModel } from "../../learn-more/ev1-details/ev-details.model";

@Component({
  selector: "yt-compare-financial",
  templateUrl: "compare-financial.component.html",
  styleUrls: ["./compare-financial.component.scss"],
})
export class CompareFinancialComponent implements OnInit {
  cash: boolean;
  loan: boolean;
  lease: boolean;
  matRadioForm: FormGroup;
  @Input() ev;
  @Input() solar;
  @Input() battery;
  @Input() financeValue: string;
  @Input() evDetails: EVFinancialModel;
  @Input() solarDetails: SolarFinancialModel;
  @Input() batteryDetails: BatteryFinancialModel;
  @Output() inputFinance: EventEmitter<string> = new EventEmitter();
  @Output() compare: EventEmitter<boolean> = new EventEmitter();
  @ViewChild("compareFinancialOverlay", { read: ElementRef }) modal: ElementRef;

  constructor(private fb: FormBuilder) {
    this.matRadioForm = this.fb.group({
      finance: [],
    });
  }

  @HostListener("document:keydown", ["$event"])
  onKeyDown(event): void {
    if (event.key == "Escape") {
      this.onClose();
    }
  }
  @HostListener("document:click", ["$event"])
  onDocumentClick(event): void {
    if (this.modal.nativeElement) {
      if (this.modal.nativeElement === event.target) {
        this.onClose();
      }
    }
  }

  ngOnInit(): void {
    this.matRadioForm.controls["finance"].setValue(this.financeValue);
    this.onChange();
  }

  onClose(): void {
    this.compare.emit();
    this.matRadioForm.controls["finance"].setValue(this.financeValue);
    this.onChange();
  }

  get financeFormValue(): string {
    return this.matRadioForm.controls["finance"].value;
  }

  onChange(): void {
    this.cash = this.financeFormValue === "cash";
    this.loan = this.financeFormValue === "loan";
    this.lease = this.financeFormValue === "lease";
  }

  onSave(): void {
    this.compare.emit();
    this.inputFinance.emit(this.financeFormValue);
  }
}
