<div>
  <div class="tech__summary">
    <div class="heading">Heat Pump Heating & Cooling</div>
    <div
      class="paragraph"
      [innerHTML]="'homeOwenerDashboard.learnMore.hpsc.paragraph' | translate"
    ></div>
  </div>
</div>

<form [formGroup]="hpscForm" class="form__container">
  <div class="form__wrapper">
    <div>
      <mat-radio-group
        class="radioButton-flex"
        formControlName="own"
        (change)="condition()"
      >
        <div class="cards" *ngFor="let radioOptions of radioButtonOptions">
          <label>
            <yt-radio-buttons
              [viewValue]="radioOptions.viewValue"
              [icon]="radioOptions.icon"
              [default]="selectedRadioButton"
              [value]="radioOptions.value"
            ></yt-radio-buttons>
            <mat-radio-button
              class="hidden_button absolute top-0"
              [value]="radioOptions.value"
              (change)="onRadioChange($event)"
            >
            </mat-radio-button>
          </label>
        </div>
      </mat-radio-group>
      <div class="form__field-wrapper">
        <section class="form__field-section mb_0">
          <div class="field__label">Do you have ducts?</div>
          <mat-form-field
            hideRequiredMarker="true"
            floatLabel="auto"
            appearance="outline"
          >
            <mat-select
              placeholder="Current heating system type"
              formControlName="ducts"
            >
              <mat-option *ngFor="let duct of ducts" [value]="duct.value">
                {{ duct.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="hpscForm.controls['hpscType'].hasError('required')"
            >
              Required!
            </mat-error>
          </mat-form-field>
        </section>

        <section class="form__field-section">
          <div class="field__label pt_16">Do you have an air conditioner?</div>
          <mat-form-field
            hideRequiredMarker="true"
            floatLabel="auto"
            appearance="outline"
          >
            <mat-select placeholder="Type" formControlName="acType">
              <mat-option *ngFor="let type of acTypes" [value]="type.value">
                {{ type.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error> Required! </mat-error>
          </mat-form-field>
        </section>

        <div class="pt_28">
          Current heating system
          <mat-icon
            matTooltip="This information relates to the heating system currently in your home."
            class="h-18"
          >
            help_outline
          </mat-icon>
        </div>

        <section class="form__field-section">
          <div
            class="field__label"
            class="pt_16 field__label"
            [ngStyle]="{
              color:
                hpscForm.controls['own'].value === 'no' ||
                hpscForm.controls['own'].value === null
                  ? '#c7c7c7'
                  : '#333333'
            }"
          >
            Age
          </div>
          <mat-form-field
            hideRequiredMarker="true"
            floatLabel="auto"
            appearance="outline"
          >
            <mat-select formControlName="age" placeholder="Select Age">
              <mat-option *ngFor="let range of ageList" [value]="range.value">{{
                range.viewValue
              }}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                hpscForm.controls['age'].hasError('required') && haveInputError
              "
            >
              Required!
            </mat-error>
          </mat-form-field>
        </section>

        <section
          class="form__field-section"
          [ngStyle]="{
            display: hpscForm.controls['own'].value === 'yes' ? 'none' : ''
          }"
        >
          <div class="field__label">Type</div>
          <mat-form-field
            hideRequiredMarker="true"
            floatLabel="auto"
            appearance="outline"
          >
            <mat-select
              placeholder="Current heating system type"
              formControlName="hpscType"
              (selectionChange)="onTypeChanges()"
            >
              <mat-option
                *ngFor="let heater of hpsc_Type"
                [value]="heater.value"
              >
                {{ heater.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                hpscForm.controls['hpscType'].hasError('required') &&
                haveInputError
              "
            >
              Required!
            </mat-error>
          </mat-form-field>
        </section>

        <section class="form__field-section" *ngIf="disableDatePicker">
          <div class="field__label">Purchased month & year</div>
          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker="true"
            appearance="outline"
          >
            <mat-select placeholder="Select" formControlName="datePurchased">
              <mat-option *ngFor="let month of monthList" [value]="month">
                {{ month | titlecase }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            *ngIf="
              (isDatePurchasedEmpty() &&
                hpscForm.controls['datePurchased'].touched) ||
              haveInputError
            "
          >
            Required!
          </mat-error>
        </section>
      </div>
    </div>
  </div>

  <div class="action__wrapper">
    <button
      mat-raised-button
      color="accent"
      (click)="onSaveHPSC()"
      [disabled]="hpscForm.invalid || isDatePurchasedEmpty()"
      class="primary__button"
    >
      SAVE
    </button>
  </div>
  <div class="note__message">
    NOTE: The above fields have been pre-filled with default values.
  </div>
</form>
