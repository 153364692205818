import { LayoutService } from "./../shared/layout/layout.service";
import { RestAPIService } from "../../rest-api/rest-api.service";
import { AppService } from "../../app.service";
import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { takeWhile } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Component({
  selector: "yt-thank-you",
  templateUrl: "thank-you.component.html",
  styleUrls: ["./thank-you.component.scss"],
})
export class ThankYouComponent implements OnInit {
  location: Location;
  solarVendorName: string;
  dashboardAccess: boolean = false;
  userFromDashboard: boolean = false;
  header: string;
  subHeader: string;
  vendorDescription: string;
  declarationText: boolean = false;
  vendorCode: string;
  userDetails: string = "";
  public alive = true;
  vendorLink: string;

  constructor(
    private appService: AppService,
    private restAPIService: RestAPIService,
    private layoutServiceV2: LayoutService
  ) {}

  ngOnInit() {
    this.layoutServiceV2.navRouterSubject.next(undefined);
    this.layoutServiceV2.hideSidenavSubject.next(true);
    this.layoutServiceV2.emitCloseSideBar();
    const sideNavElement = document.getElementById("yt__sidenav");
    if (sideNavElement) sideNavElement.style.display = "none";
    this.vendorCode = this.restAPIService.solarVendorInformation.code;
    this.solarVendorName = this.restAPIService.getSolarVendorDescrption();
    if (this.restAPIService.userFromDashboard) {
      this.userFromDashboard = true;
    } else {
      let buildingTypes;
      if (this.restAPIService.buildingTypes !== undefined) {
        buildingTypes = this.restAPIService.buildingTypes;
      } else if (this.restAPIService.buildingType !== undefined) {
        buildingTypes = this.restAPIService.buildingType;
      } else if (this.restAPIService.getBuildingType()) {
        buildingTypes = this.restAPIService.getBuildingType();
      }
      if (this.restAPIService.solarVendorInformation.code !== "SKYT") {
        if (buildingTypes !== "nonProfit") {
          // if (this.restAPIService.utilityProvider === "PGE") {
          this.dashboardAccess = true;
          // }
        }
      }
    }
    // this code is used to disable browser back button
    history.pushState("", "", location.href);
    window.onpopstate = function () {
      history.go(1);
    };

    localStorage.setItem("pgeCompleted", "true");

    let vendorCode = this.appService.getVendorName();
    this.restAPIService
      .getSolarVendorDetails(vendorCode)
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {
        this.vendorDescription = data.description;
        this.vendorLink = data.vendorLink;
        if (data.solutionType === "vendor") {
          this.declarationText = true;
          this.header =
            "has partnered with YellowTin to better serve your needs. An email from";
        } else if (data.solutionType === "broader") {
          this.declarationText = false;
          this.header = "An email from";
        } else if (data.solutionType === "cce") {
          this.declarationText = true;
          this.header =
            "partnered with YellowTin to bring you SCP Electrify. An email from";
        }
        if (this.restAPIService.homeOwnerEmail === undefined) {
          this.subHeader = "with your login details has been sent.";
          this.userDetails = "";
        } else {
          this.userDetails = this.restAPIService.homeOwnerEmail;
          this.subHeader = "with your login details has been sent to";
        }
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  onClose() {
    window.close();
  }

  onLogin() {
    let login = "dashboard/login";
    const isProd = environment.production;
    if (this.vendorCode === "YSLR" || !isProd) {
      window.open(
        `${this.vendorLink}${login}/?vendor=${this.vendorCode}`,
        "_blank"
      );
    } else {
      window.open(`${this.vendorLink}${login}`, "_blank");
    }
  }
}
