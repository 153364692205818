import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { DashboardAPIService } from "../../../../rest-api/dashboard/dashboard-api.service";
import { TechCardInitial } from "../../../shared/mini-cards/tech-cards/tech-card.init";
import { TechDetailsModel } from "../../model/dashboard.init.model";
import {
  CategorySavingsModel,
  RecommendationTechModel,
} from "../../model/dashboard.model";
import { RestAPIService } from "../../../../rest-api/rest-api.service";
import { MatDialog } from "@angular/material/dialog";
import { MspOverlayComponent } from "../msp-overlay/msp-overlay.component";
import { CategoryListModel } from "src/app/homeowner-dashboard/shared/mini-cards/mini-cards.model";
import { TimeFrameEnum } from "src/app/homeowner-dashboard/learn-more/learn-more.enum";

@Component({
  selector: "yt-recommendation",
  templateUrl: "./recommendation.component.html",
  styleUrls: ["./recommendation.component.scss"],
})
export class RecommendationComponent implements OnInit, OnChanges {
  tabLabel: string;
  tabList: CategoryListModel[] = [];
  onTechListLength: boolean;
  lowCostSavingsValue: number = 0;
  transportSavingsValue = 0;
  appliancesSavingsValue = 0;
  solarBatterySavingsValue = 0;
  favoriteSavingsValue = 0;
  onEverGreen: boolean = false;
  selectedIndex: number = 0;
  buildingType: string;
  approach1Status: boolean;
  scrollOffSet: number = 200;
  showScrollButton: boolean = false;
  tabHeaderElem: HTMLDivElement;
  simpleLowCost: TechDetailsModel[] = [];
  transportation: TechDetailsModel[] = [];
  solarBattery: TechDetailsModel[] = [];
  appliances: TechDetailsModel[] = [];
  favorite: TechDetailsModel[] = [];
  @Input() timeFrame: TimeFrameEnum;
  @Input() showWhatChoices: boolean;
  @Input() categorySavings: CategorySavingsModel;
  @Input() showEverGreen: boolean;
  @Input() everGreenLink: string;
  @Input() recommendationDetails: RecommendationTechModel;
  @Input() errorMsg: boolean;
  @Input() categoriesList: string[];
  @Input() solarErrorMessage: string;
  @Input() activeTab: string;
  @Output() tabName: EventEmitter<string> = new EventEmitter();
  @Output() addTech: EventEmitter<string> = new EventEmitter();
  @Output() alreadyHave: EventEmitter<string> = new EventEmitter();
  @Output() removeTech: EventEmitter<string> = new EventEmitter();
  @Output() alreadyEverGreen: EventEmitter<boolean> = new EventEmitter();
  @Output() updateSavings: EventEmitter<boolean> = new EventEmitter();
  @Output() savingsDialog: EventEmitter<string> = new EventEmitter();
  @Output() openSelectionDialog: EventEmitter<string> = new EventEmitter();

  constructor(
    private techCard: TechCardInitial,
    private dashboardAPI: DashboardAPIService,
    private restAPI: RestAPIService,
    private dialog: MatDialog
  ) {}

  ngOnChanges(): void {
    const initCategories = this.techCard.categoryList;
    this.simpleLowCost = Object.values(
      this.recommendationDetails.simpleLowCost
    );
    this.transportation = Object.values(
      this.recommendationDetails.transportation
    );
    this.solarBattery = Object.values(this.recommendationDetails.solarBattery);
    this.appliances = Object.values(this.recommendationDetails.appliances);
    this.favorite = Object.values(this.recommendationDetails.favorite);
    if (this.categoriesList.length) {
      this.tabList = initCategories.filter(
        (cat, index) => cat.value === this.categoriesList[index]
      );
    }
    this.tabList.push(initCategories[initCategories.length - 1]);
    this.getCategorySavings();
    this.onEverGreen = this.dashboardAPI.haveEverGreen;
    this.tabHeaderElem =
      document.querySelector<HTMLDivElement>(".tab_headers")!;
    this.onResize();
  }

  getTranslateText(tabName): string {
    return this.restAPI.getTranslateText(
      `homeOwenerDashboard.dashboard.recommendation.categoryList.${tabName}`
    );
  }

  ngOnInit(): void {
    this.selectedIndex = this.tabList.findIndex(
      (tab) => tab.value === this.activeTab
    );
    this.tabLabel = this.activeTab;
    this.tabName.emit(this.tabLabel);
    this.buildingType = this.dashboardAPI.getBuildingType();
    const renterApproach = this.dashboardAPI.vendorDetails.renterApproach;
    const condoApproach = this.dashboardAPI.vendorDetails.condominiumApproach;
    this.approach1Status =
      renterApproach === "approach1" || condoApproach === "approach1";
  }

  onTabChange(value: string, index): void {
    this.selectedIndex = index;
    this.tabLabel = value;
    this.tabName.emit(this.tabLabel);
  }

  tabStatus(event: string): boolean {
    return event === this.tabLabel;
  }

  updateSavingsDetails(): void {
    this.updateSavings.emit();
  }

  getCategorySavings(): void {
    this.lowCostSavingsValue =
      this.categorySavings.simpleLowCost?.dollarSavings!;
    this.transportSavingsValue =
      this.categorySavings.transportation?.dollarSavings!;
    this.appliancesSavingsValue =
      this.categorySavings.appliances?.dollarSavings!;
    this.solarBatterySavingsValue =
      this.categorySavings.solarBattery?.dollarSavings!;
    this.favoriteSavingsValue = this.categorySavings.favourites;
  }

  translateText(status: string): string {
    return this.restAPI.getTranslateText(
      `homeOwenerDashboard.dashboard.recommendation.${this.tabLabel}.savings.${status}`
    );
  }

  onAdd(value: string): void {
    this.addTech.emit(value);
  }

  onRemove(value: string): void {
    this.removeTech.emit(value);
  }

  onAlreadyHave(value: string): void {
    this.alreadyHave.emit(value);
  }

  alreadyHaveEverGreen(value: boolean) {
    this.onEverGreen = false;
    this.alreadyEverGreen.emit(value);
  }

  checkTechList(value: boolean): void {
    this.onTechListLength = value;
  }

  onEverGreenFavorite(value: boolean) {
    this.onEverGreen = value;
    if (!value) {
      const simpleLowCostElement =
        document.querySelector<HTMLDivElement>("#simpleLowCost");
      if (simpleLowCostElement) simpleLowCostElement.click();
    }
  }

  openSavingsDialog(value: string): void {
    this.savingsDialog.emit(value);
  }

  openDialog(): void {
    this.openSelectionDialog.emit();
  }

  getApproach1Status(): boolean {
    if (this.approach1Status) return true;
    return this.buildingType === "singleFamilyHome";
  }

  showServicePanelInfo(): void {
    let dialogRef = this.dialog.open(MspOverlayComponent, {
      width: "70%",
      height: "100%",
      autoFocus: false,
      backdropClass: "dark-backdrop",
      panelClass: "homeSetting_container",
      data: { tech: "" },
      disableClose: true,
      exitAnimationDuration: 0,
    });
    dialogRef.backdropClick().subscribe(() => dialogRef.close());
  }

  handleScroll(button: string): void {
    let left = this.tabHeaderElem.scrollLeft;
    if (button === "prev") {
      left -= this.scrollOffSet;
    } else if (button === "next") {
      left += this.scrollOffSet;
    }
    this.tabHeaderElem.scrollTo({ left, behavior: "smooth" });
  }

  isButtonDisabled(button: string): boolean {
    if (button === "next") {
      return (
        this.tabHeaderElem.scrollLeft + this.tabHeaderElem.clientWidth ===
        this.tabHeaderElem.scrollWidth
      );
    } else {
      return this.tabHeaderElem.scrollLeft === 0;
    }
  }

  onResize(): void {
    this.showScrollButton =
      this.tabHeaderElem.scrollWidth > this.tabHeaderElem.clientWidth;
  }
}
