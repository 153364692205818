<div class="faq_container">
  <div class="about_technology" *ngIf="showAboutTech">
    <div class="header">Overview</div>
    <p>
      <strong>Uses:</strong> A battery storage system can provide peace of mind
      by storing electricity that lets you run important household devices
      during a power blackout. Also, in certain situations , a battery can let
      you store cheaper solar power for later use to reduce the need for
      higher-priced electricity from the grid.
      <span
        class="link_text"
        *ngIf="showReadMore"
        (click)="onReadContent('readMore')"
      >
        Read more...
      </span>
    </p>
    <div *ngIf="showReadLess">
      <p class="pt3">
        <strong>Types of Systems:</strong> Stationary battery systems typically
        use lithium-ion batteries and range from 4 to 13 kWh of storage
        capacity. A portable power station is a smaller and less-expensive
        option that’s suitable for renters as well as homeowners. Generally in
        the range of .25 – 1.5 kWh, these can be charged from a wall outlet and
        then stored in a closet until needed during a power outage.
      </p>

      <p class="pt3">
        <strong>Economics:</strong> Stationary battery storage is still quite
        expensive, sometimes costing as much or more as a rooftop solar system
        alone. Given the costs, homeowners often opt for storage only when the
        reliability of power supply is a significant concern or local
        electricity prices present a substantial benefit for the later use of
        stored electricity.
      </p>

      <p class="pt3">
        <strong>Maintenance:</strong> The current generation of lithium-ion
        batteries is virtually maintenance free.
      </p>

      <p class="pt3">
        <strong>EV-Related Storage Options:</strong> An emerging storage option
        involves the use of an EV’s battery, instead of a stationary battery
        system. This has the economic advantage of using one battery for both
        home storage and transportation. It can also result in a much larger
        available capacity since many EV batteries hold 50 kWh or more.
        <span class="link_text" (click)="onReadContent('readLess')">
          Read less
        </span>
      </p>
    </div>
  </div>
  <div *ngIf="!showAboutTech">
    <div *ngIf="isActive(0)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. What are the main uses of a home battery system?
        </h5>
        <p class="answer_text">
          A home battery system stores electrical energy to run household
          devices at a later time without the need of grid-supplied electricity.
          There are two primary uses for a home battery system. The first is to
          serve as a backup power supply when the grid is down, so important
          appliances in the house are still powered during a blackout. The
          second use, which can sometimes come into play if an applicable
          time-of-use electric rate is available, is to help save on your
          electricity bills. In such situations, the battery can store
          lower-priced electricity (such as from a rooftop solar array), and
          then the stored energy can be used in the home later in the day when
          time-of-use electricity rates are typically higher.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">2. What types of batteries are there?</h5>
        <p class="answer_text">
          Lead-acid batteries had sometimes been used in the past for home
          applications. However, modern storage systems, normally storing 8 – 13
          kWh of energy, use lithium-ion batteries with a lifetime of 10-20
          years. They are generally guaranteed for 10 years. A
          <strong>portable</strong> power station is a smaller and
          less-expensive alternative to a stationary system. These portable
          systems generally store from .25 – 1.5 kWh and can be charged from a
          wall outlet and then stored in a closet until needed during a power
          outage. They can often be used to charge electronic devices, and if
          large enough, a refrigerator for a day or so.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">3. Is rooftop solar a must for batteries?</h5>
        <p class="answer_text">
          Stationary batteries are normally installed where there is also a
          rooftop solar system. This allows you to charge the battery when
          you're producing surplus solar energy. However, you don't need to have
          solar to install a battery storage system. It's possible to get a
          standalone battery system that's charged from the electric grid and
          supplies emergency backup power. If you're on the right type of
          time-of-use electric rate, you can also use such a system to store
          power from the grid when it's less expensive and use it to displace
          more-expensive power later in the day.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">4. Will one battery be enough?</h5>
        <p class="answer_text">
          Stationary lithium-ion batteries are generally sized to hold about 8 –
          13 kWh of energy. This is normally enough to power low-energy
          appliances like lights, refrigerators, fans, microwaves, and home
          electronics for a day or two(without being recharged by a solar
          system). For a longer duration without solar recharge, or for
          heavy-duty appliances, a system with multiple batteries is typically
          used. If you can recharge your battery during the day with solar, then
          one battery is much more likely to meet your needs.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(1)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How expensive are they?</h5>
        <p class="answer_text">
          A basic stationary battery system in the range of 8 – 13 kWh will
          likely cost about $10,000 – $15,000 installed. However, federal (and
          sometimes state or utility) incentives can bring the cost down
          substantially. A portable power station in the range of .25 kWh – 1.5
          kWh, will likely cost between $350 – $1,200.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">2. Are back-up generators less expensive?</h5>
        <p class="answer_text">
          Traditional back-up generators running on natural gas, diesel,
          propane, or gasoline are cheaper upfront, but have higher ongoing
          maintenance and fuel costs. A home battery system can sometimes be
          more cost effective over the long run, and has the advantages of being
          quieter and less polluting.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          3. Can a battery help me save on my electricity bills?
        </h5>
        <p class="answer_text">
          When paired with a rooftop solar system, a battery can help to reduce
          electricity bills in some cases. It depends on several factors. If
          you're on a suitable time-of-use electric rate with your utility, you
          can use a battery to store solar energy during the day and then
          discharge the battery in the evening, reducing your need to take
          electricity from the utility grid later in the day after the sun goes
          down. Since time-of-use prices are generally higher in the evening,
          your stored solar energy helps you avoid paying the higher electricity
          prices. Other factors such as your utility's approach toward rooftop
          solar and your electricity usage patterns, also play significant roles
          in determining if you can cut your bills with a battery.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(2)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. What type of maintenance is needed?</h5>
        <p class="answer_text">
          Current lithium-ion batteries are practically maintenance free.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(3)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. Can the batteries be recycled?</h5>
        <p class="answer_text">
          Yes. Lithium-ion batteries contain over 99% recyclable materials. They
          are generally warrantied for 10 years and can last much longer, with a
          small decrease in performance annually.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. Are home batteries good for the environment?
        </h5>
        <p class="answer_text">
          Yes. Home batteries can reduce our need for power plants and grid
          infrastructure improvements. For example, when deployed in sufficient
          numbers and with the right economic incentives, they can be
          coordinated to reduce peak demands and reduce the need for building
          fossil-fired generation plants. Additionally, home batteries can store
          extra solar energy from the day to use at night, allowing us to use
          clean energy later into the evening.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(4)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. Where are batteries typically installed?
        </h5>
        <p class="answer_text">
          Most stationary batteries can be installed indoors or outdoors. They
          are often located on an outside wall near the main electrical panel or
          inside on a garage wall. Portable power stations can be easily stored
          in a closet or garage and then moved to where they're needed during an
          outage.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. How difficult is the installation process?
        </h5>
        <p class="answer_text">
          Usually the whole process for installing a stationary battery can be
          finished in one or two days. A city permit is required and it’s often
          followed by an electrical inspection before being put in operation.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          3. How long can a home battery serve as backup in case of a power
          outage?
        </h5>
        <p class="answer_text">
          A battery backup system is normally sized to supply backup power for
          essential end uses for less than a day without the benefit of any
          solar recharge. When solar energy is available, it can be used to
          replenish the battery, extending the backup time. In any case, the
          duration of power availability will depend on the size of your
          battery, the availability of solar electricity for recharging, and the
          amount of energy used.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">4. Can it be a DIY project?</h5>
        <p class="answer_text">
          Generally not for a stationary battery project. These installations
          require substantial expertise and are typically carried out by a
          professional. A portable battery is a perfect DIY project. You just
          buy it,plug it in to charge,and then store it away until needed during
          a grid outage.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          5. How is the operation of a battery system programmed and monitored?
        </h5>
        <p class="answer_text">
          This can be handled by the installer or accomplished with a smartphone
          app that is supplied by the manufacturer. Most stationary battery
          systems are Wi-Fi connected, so communication is generally
          straightforward.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">6. Are batteries safe?</h5>
        <p class="answer_text">
          Yes, home batteries are quite safe with a lot of safety mechanisms
          already built in.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(5)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. What types of incentives are available for home energy storage?
        </h5>
        <p class="answer_text">
          The key incentive is the 30% federal Residential Clean Energy Tax
          Credit. In some cases other incentives are also available at the state
          and utility levels. Taken together, incentives can take thousands of
          dollars off the price of a battery system, making them much more
          affordable.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. Can I take the federal Tax Credit for a battery if i don't have
          solar?
        </h5>
        <p class="answer_text">
          Yes, as of January 2023 the tax credit can be applied to standalone
          battery systems. (Previously it was only available if paired with a
          solar energy system.) The tax credit covers 30% of the system's cost
          and it's scheduled to drop to 26% in 2033, 22% in 2034, and zero in
          2035.(Consult a tax expert for a definitive assessment of your ability
          to make use of the credit.)
        </p>
      </div>
    </div>
  </div>
</div>
