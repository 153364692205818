<div class="w-100 padding-top">
	<div class="data_box">
		<div class="w-100 top_section">
			<div class="breakdown_text">
				Energy Usage Breakdown
			</div>
		</div>
		<div class="w-100 bottom_section">
			<div class="w-50 left_values">
				<div class="sub_value" *ngIf="!electricAuthorizedStatus">{{ annualElectricUsage | number }} kWh
				</div>
				<div class="sub_value" *ngIf="electricAuthorizedStatus">
					{{annualElectricUsage}}
				</div>
				<div class="sub_value_label">Electricity</div>
			</div>
			<div class="w-50 right_values">
				<div class="sub_value" *ngIf="!gasNotAuthorizedStatus">{{ annualGasUsage | number}} therms
				</div>
				<div class="sub_value" *ngIf="gasNotAuthorizedStatus">
					{{annualGasUsage}}
				</div>
				<div class="sub_value_label">Natural Gas</div>
			</div>
		</div>
	</div>

	<div class="chart_section w-100">
		<div class="monthly_text">Monthly Energy Usage</div>
		<div class="chart_box w-100">
			<div class="w-100 chart_toggle_buttons yt__energy_usage_common">
				<mat-button-toggle-group [(value)]="selected" (change)="getUsageOverviewData($event.value)">
					<mat-button-toggle (click)="onElectricity()" value="Electricity">
						<span class="toggle_label">ELECTRICITY</span>
					</mat-button-toggle>
					<mat-button-toggle value="Gas" class="label_margin" (click)="onGas()">
						<span class="toggle_label">NATURAL GAS</span>
					</mat-button-toggle>
				</mat-button-toggle-group>
			</div>
			<div class="w-100 high_chart" *ngIf="showElectricityChart || showGasChart">
				<div class="height" #container></div>
			</div>
			<div class="w-100 high_chart" style="padding-bottom: 40px;" *ngIf="electricity && showElectricityChartError">
				<div class="paragraph_text">
					We have not received your electricity data from the utility provider. This is because you have only
					authorized your gas but not electricity data. If you wish to authorize your electricity data, please
					<span class="link_text" (click)="onEnergyUsageSetting()">click here</span>.
				</div>
			</div>
			<div class="w-100 high_chart" style="padding-bottom: 40px;" *ngIf="gas && showGasChartError">
				<div class="paragraph_text">
					<div class="paragraph_text">
						We have not received the gas data from the utility provider. This might happen due to any one of
						the following reasons:
					</div>
					<div class="paragraph_text">
						1) You have only authorized your electricity but not gas data. If you wish to authorize your gas
						data, please <span class="link_text" (click)="onEnergyUsageSetting()">click here</span>.
					</div>
					<div class="paragraph_text">
						2) Gas service is not available at your home through the utility provider.
					</div>
					<div class="paragraph_text">
						3) You have an All-Electric home and so do not have gas service.
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="descriptionMessage" *ngIf="showElectricityChart || showGasChart">
		The results are based on your utility provided data and are rounded to the nearest decimal.
	</div>
</div>


<div id="overlay" (click)="offPopUp()">
	<div id="text">
		<div class="bg-popup-white">
			<div class="yt_popup-header yt_popup-padding tc fw6">Energy Authorization</div>
			<div class="yt_popup-padding">
				<p class="f4 a">
					We have not received this information from the utility provider. This might happen due to any
					one of the following reasons:
				</p>
				<div class="f4 a pt3">
					<p class="f4">1) Energy data is not authorized.</p>
					<p class="f4">2) Complete authorization is not provided.</p>
					<p class="f4">3) This service is not available at your home.</p>
				</div>
				<p class="f4 a pt3">
					If you wish to authorize your energy data, please
					<span class="pointer yt_color" (click)="onEnergyUsageSetting()">click here</span>.
				</p>
			</div>
		</div>
	</div>
</div>

<!-- loader -->
<yt-loader *ngIf="loader"></yt-loader>
