<div class="container">
  <h1 class="header">{{ "homeOwnerInfo.energyInfo.header" | translate }}</h1>
  <p class="subHeader">
    {{ "homeOwnerInfo.energyInfo.subHeader" | translate }}
  </p>
  <form [formGroup]="utilityForm">
    <mat-form-field
      hideRequiredMarker="true"
      floatLabel="auto"
      appearance="outline"
      class="margin_bottom"
    >
      <mat-label>Select your electric utility provider</mat-label>
      <mat-select
        matInput
        required
        formControlName="utilityId"
        (selectionChange)="onChangeOfUtilityId($event.value)"
      >
        <mat-option *ngFor="let name of utilityProvidersList" [value]="name">{{
          name.utilityName
        }}</mat-option>
      </mat-select>
      <mat-error *ngIf="isFieldValid('utilityId', true)"> Required! </mat-error>
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      floatLabel="auto"
      hideRequiredMarker="true"
      class="nameField bill_field"
    >
      <mat-label style="margin-left: 8px"
        >Average monthly electric bill
      </mat-label>
      <input
        type="text"
        matInput
        required
        formControlName="avgMonthlyBill"
        autocomplete="avgMonthlyBill"
      />
      <mat-icon class="material-icons" matPrefix>attach_money</mat-icon>
      <mat-hint align="end">Accepts value between $35 - $500</mat-hint>
      <mat-error
        *ngIf="
          utilityForm.controls['avgMonthlyBill'].hasError('pattern') ||
          utilityForm.controls['avgMonthlyBill'].hasError('min') ||
          utilityForm.controls['avgMonthlyBill'].hasError('max')
        "
      >
        Please enter value between 35-500
      </mat-error>
      <mat-error *ngIf="isFieldValid('avgMonthlyBill', true)"
        >Required!
      </mat-error>
    </mat-form-field>

    <div class="checkbox-padding" *ngIf="cceOptOut">
      <mat-checkbox class="checkbox-text" formControlName="checkCceOptOut">
        I have <strong>opted out</strong> of {{ cceFullForm }} as my electricity
        provider.
        <mat-icon class="help-icon" [matTooltip]="cceTooltip"
          >help_outline</mat-icon
        >
      </mat-checkbox>
    </div>

    <div class="button_wrapper">
      <button
        id="loaderButton"
        mat-raised-button
        class="button_width"
        color="accent"
        (click)="onSubmit(true)"
        [disabled]="isButtonDisabled()"
      >
        SUBMIT
      </button>
    </div>
  </form>
</div>
