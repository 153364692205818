import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TechNamePipeModule } from "../../../../../utils/tech.pipe";
import { LoaderModule } from "../../../../shared/loader";
import { IncentivesOverlayComponent } from "./incentives-overlay.component";
import { EntriesPipeModule } from "src/app/utils/entries.pipe";

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    LoaderModule,
    MatExpansionModule,
    MatTooltipModule,
    TechNamePipeModule,
    EntriesPipeModule
  ],
  declarations: [IncentivesOverlayComponent],
  exports: [IncentivesOverlayComponent],
  providers: [],
})
export class IncentivesOverlayModule {}
