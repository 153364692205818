import { Component } from '@angular/core';

@Component({
    selector: 'yt-popup-loader',
    templateUrl: 'popup-loader.component.html',
    styleUrls: ['./popup-loader.component.scss']
})

export class PopupLoaderComponent {
    ngOnInit() { }
}