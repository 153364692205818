<div class="home__container">
  <div class="header">
    {{ "homeOwenerDashboard.dashboard.personalizeCard.header" | translate }}
  </div>
  <div class="sub_header">
    {{ "homeOwenerDashboard.dashboard.personalizeCard.subHeader" | translate }}
  </div>
  <div class="button_wrapper">
    <button
      mat-raised-button
      color="accent"
      class="update_btn"
      (click)="onUpdate()"
    >
      {{ "homeOwenerDashboard.dashboard.personalizeCard.button" | translate }}
    </button>
  </div>
</div>
