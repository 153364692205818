<scroll-top></scroll-top>
<div class="page-container yt__forgot_password">
  <div class="flex padding-93-class">
    <div class="flex formSection center">
      <form [formGroup]="forgotPasswordForm" class="form_width center">
        <div class="heading_text">Forgot Password</div>
        <div class="subheading_text">
          Please enter your email address and we will send you instructions to
          reset your password.
        </div>
        <div class="form_field_padding">
          <mat-form-field
            class="w-100"
            hideRequiredMarker="true"
            floatLabel="auto"
            appearance="outline"
          >
            <mat-label>Email</mat-label>
            <input
              matInput
              autocomplete="off"
              formControlName="email"
              type="email"
              autocomplete="off"
            />
          </mat-form-field>
          <div *ngIf="errorMessage" class="error_message">
            {{ errorMessage }}
          </div>
        </div>
        <div class="w-100 forgot_button">
          <button
            mat-raised-button
            [disabled]="forgotPasswordForm.invalid"
            color="accent"
            (click)="onSubmit()"
          >
            RESET PASSWORD
          </button>
        </div>
        <div class="tr login_text">
          <span (click)="onReturnToLogin()" class="pointer">
            Back to Login
          </span>
        </div>
      </form>
    </div>
  </div>
</div>

<div
  class="overlay"
  *ngIf="showSuccessDialogue"
  (click)="showSuccessDialogue = false"
>
  <div id="text">
    <div class="bg-white tc yt__forgot_password">
      <mat-icon class="done_icon pa2 flex center">done</mat-icon>
      <div class="tc fw6 yt_padding-21 yt_popup-header">Check your email!</div>
      <div class="f4 tl yt_padding-21 lh-15">
        <span>{{ header }}</span>
        You should see an email from
        <span class="b">support@yellowtin.com</span> with your temporary
        password.
      </div>
      <div class="f4 tl yt_padding-21 lh-15">
        If you don't see the email, check your spam folder.
      </div>
    </div>
  </div>
</div>
