<div class="w-100 margin__bottom" [formGroup]="batteryForm">
  <div class="energy-choices">
    <div class="yt-text battery-text"></div>
    <div class="yt_p_top">
      <mat-expansion-panel
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        [expanded]="true"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="yt__panel-header"
            >Your Choices</mat-panel-title
          >
        </mat-expansion-panel-header>
        <div class="cf">
          <div class="w-100">
            <div class="w-100 w-50-l fl radio-buttons yt_border-right">
              <mat-radio-group
                (change)="condition()"
                formControlName="own"
                class="example-radio-group"
              >
                <div class="yt-Btn-Font">
                  Tell us about your
                  <span *ngIf="buildType">portable </span>battery
                </div>
                <div class="radio_text">
                  <div class="dib yt_ques-padding">
                    <mat-radio-button value="yes">
                      <span class="yt_option">Already have one</span>
                    </mat-radio-button>
                  </div>
                  <div class="dib yt_answer-padding-2">
                    <mat-radio-button value="yesPlan">
                      <span class="yt_option">Interested in getting one</span>
                    </mat-radio-button>
                  </div>
                  <div class="dib yt_answer-padding-2">
                    <mat-radio-button value="no">
                      <span class="yt_option">Not interested</span>
                    </mat-radio-button>
                  </div>
                </div>
              </mat-radio-group>
            </div>
            <div
              class="w-100 w-100-m w-50-l fl yt_paddingtop-42 yt_pd-left yt_form_wrapper"
            >
              <div>
                <div
                  class="yt__f4 yt_pb-7"
                  [ngStyle]="{
                    color:
                      batteryForm.controls['own'].value === 'no' ||
                      batteryForm.controls['own'].value === 'yes'
                        ? '#c7c7c7'
                        : '#333333'
                  }"
                >
                  Backup hours
                  <mat-icon
                    matTooltip="This is the number of hours to power essential appliances in a blackout"
                    matTooltipPosition="right"
                    class="h-18"
                  >
                    help_outline
                  </mat-icon>
                </div>
                <div class="yt__pt0-7 yt__pb-20">
                  <mat-form-field
                    appearance="outline"
                    floatLabel="auto"
                    [ngClass]="{
                      'disable-field':
                        batteryForm.controls['own'].value === 'no' ||
                        batteryForm.controls['own'].value === 'yes'
                    }"
                  >
                    <input
                      matInput
                      type="text"
                      class="quantity"
                      placeholder="Enter 1-35"
                      formControlName="hrs"
                      maxlength="3"
                    />
                    <mat-error
                      *ngIf="
                        batteryForm.controls['hrs'].hasError('pattern') ||
                        batteryForm.controls['hrs'].hasError('min') ||
                        batteryForm.controls['hrs'].hasError('max')
                      "
                    >
                      Please enter value between 1-35
                    </mat-error>
                    <mat-error
                      *ngIf="batteryForm.controls['hrs'].hasError('required')"
                    >
                      Required!
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
  <div class="pt_30 w-100 yt_box-shadow">
    <button
      mat-raised-button
      color="accent"
      (click)="onSaveBATTERY()"
      [disabled]="batteryForm.invalid"
      class="fr w-100 yt__mb-12"
    >
      SAVE
    </button>
  </div>
</div>
