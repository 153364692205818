import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BatteryDataModel } from "../../../../model/userdata.model";
import { DashboardAPIService } from "../../../../../../rest-api/dashboard/dashboard-api.service";
import { RadioOptionsModel } from "../../../../../../rest-api/dashboard/dashboard-api.model";
import { MatRadioChange } from "@angular/material/radio";
import { SettingsDialogData } from "../../setting-overlay.component.model";
import { SettingsOverlayService } from "../../settings-overlay.service";
import { Subject } from "rxjs";

@Component({
  selector: "yt-portable-battery",
  templateUrl: "portable-battery.component.html",
  styleUrls: ["../../common-tech.scss"],
})
export class PortableBatteryComponent implements OnInit {
  panelOpenState: boolean = false;
  portableBatteryForm: FormGroup;
  disableDatePicker: boolean;
  portableBatteryData: BatteryDataModel[];
  ownValue: string;
  selectedRadioButton: string;
  radioButtonOptions: RadioOptionsModel[];
  hideFormField: boolean;
  portableBatterySettings: BatteryDataModel[];
  subject: Subject<any> = new Subject();
  @Input() portableBatteryDetails;
  @Input() activeIcon: string;
  @Output() techSave: EventEmitter<SettingsDialogData> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private dashboardAPIService: DashboardAPIService,
    private settingService: SettingsOverlayService
  ) {
    this.portableBatteryForm = this.fb.group({
      own: ["", Validators.required],
      hrs: [
        "4",
        [
          Validators.required,
          Validators.pattern(/^(?:[1-9]|0[1-9]|1[0-8])$/),
          Validators.min(1),
          Validators.max(24),
        ],
      ],
    });
  }

  ngOnInit() {
    this.dashboardAPIService.getRadioButtonDetails().subscribe((data) => {
      this.radioButtonOptions = data.radioButtons;
    });
    this.portableBatteryData = this.settingService.checkSettingsInfoError(
      "portableBattery"
    )
      ? this.settingService.settingsInfoErrors.solarBattery?.portableBattery!
      : this.settingService.checkSettingsInfo("portableBattery")
      ? this.settingService.settingsInfo.solarBattery?.portableBattery!
      : this.dashboardAPIService.energyChoicesData.solarBattery
          .portableBattery!;

    this.portableBatteryData.forEach((portableBattery: BatteryDataModel) => {
      this.ownValue = portableBattery.own!;
      this.setSelectedRadioValues(this.ownValue);
      this.portableBatteryForm.patchValue(portableBattery);
      if (this.ownValue === "yes") {
        this.hideFormField = false;
      } else if (this.ownValue === "yesPlan") {
        this.hideFormField = true;
        this.disableDatePicker = true;
      } else if (this.ownValue === "noPlan") {
        this.hideFormField = true;
        this.disableDatePicker = true;
      }
    });
    if (this.activeIcon) {
      if (this.activeIcon === "favorite") {
        this.portableBatteryForm.get("own")?.setValue("yesPlan");
      } else if (this.activeIcon === "alreadyHave") {
        this.portableBatteryForm.get("own")?.setValue("yes");
      }
      this.onValueChanges();
      this.setSelectedRadioValues(this.portableBatteryForm.get("own")?.value);
      this.condition();
    } else {
      this.onValueChanges();
    }
  }

  onRadioChange(event: MatRadioChange) {
    this.setSelectedRadioValues(event.value);
  }

  setSelectedRadioValues(value: string): void {
    this.selectedRadioButton = value;
  }

  condition() {
    if (this.portableBatteryForm.get("own")?.value === "yes") {
      this.hideFormField = false;
    } else if (this.portableBatteryForm.get("own")?.value === "yesPlan") {
      this.hideFormField = true;
      this.portableBatteryForm.enable();
    } else if (this.portableBatteryForm.get("own")?.value === "noPlan") {
      this.hideFormField = true;
    }
  }

  onValueChanges(): void {
    this.portableBatteryForm.valueChanges.subscribe((data) => {
      this.subject.pipe().subscribe(() => {
        if (data.own === "yes") {
          this.portableBatterySettings = [
            {
              own: data.own,
            },
          ];
        } else if (data.own === "yesPlan" || data.own === "noPlan") {
          this.portableBatterySettings = [
            {
              own: data.own,
              hrs: this.portableBatteryForm.get("hrs")?.value || data.hrs,
            },
          ];
        }
        if (this.portableBatteryForm.valid) {
          this.settingService.updateSettingsInfo(
            this.dashboardAPIService.energyChoicesData.solarBattery,
            this.portableBatterySettings,
            "solarBattery",
            "portableBattery"
          );
        } else {
          this.settingService.updateSettingsInfoErrors(
            this.dashboardAPIService.energyChoicesData.solarBattery,
            this.portableBatterySettings,
            "solarBattery",
            "portableBattery"
          );
        }
      });
      this.subject.next();
    });
  }

  onSavePortableBattery() {
    this.settingService.putSettingsInfo(this.getSettingsInfo);
  }

  getSettingsInfo = () => {
    if (this.activeIcon) {
      this.techSave.emit({
        own: this.portableBatteryForm.get("own")?.value,
        tech: "portableBattery",
      });
    }
  };
}
