<scroll-top></scroll-top>
<div class="page-container yt__logout_component">
	<div class="flex padding-93-class">
		<div class="content yt_maxWidth center">
			<div class="heading_text tc">Logged Out</div>
			<div class="subheading_text tc">You have successfully logged out.</div>
			<div class="w-100 login_button">
				<button mat-raised-button color="accent" (click)="onLogin()">BACK TO LOGIN</button>
			</div>
			<div *ngIf="feedBack" class="feedback_text tc">
				Your feedback matters.
				<a [href]="feedBackLink" target="_blank" class="pointer no-underline link_text">Click here</a>
				to take our short survey.
			</div>

		</div>
	</div>
</div>
