import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "yt-captcha",
  templateUrl: "./captcha.component.html",
  styleUrls: ["./captcha.component.scss"],
})
export class CaptchaComponent implements OnInit {
  config: any = {
    type: 2,
    length: 6,
    cssClass: "custom",
    back: {
      stroke: "#2F9688",
      solid: "#f2efd2",
    },
    font: {
      color: "#212121",
      size: "35px",
      family: "Open sans",
    },
  };
  code: any = null;
  resultCode: any = null;
  captchaInput = new FormControl();

  constructor() {}

  ngOnInit(): void {
    this.createCaptcha();
  }

  createCaptcha(): void {
    switch (this.config.type) {
      case 1:
        const char =
          Math.random().toString(24).substring(2, this.config.length) +
          Math.random().toString(24).substring(2, 4);
        this.code = this.resultCode = char.toUpperCase();
        break;
      case 2:
        const number1 = Math.floor(Math.random() * 99);
        const number2 = Math.floor(Math.random() * 9);
        const symbols = ["+", "-"];
        const symbol = symbols[Math.floor(Math.random() * symbols.length)];
        this.code = number1 + symbol + number2 + "=?";
        this.resultCode =
          symbol === "+" ? number1 + number2 : number1 - number2;
        break;
    }
    setTimeout(() => {
      let canvas: HTMLCanvasElement = <HTMLCanvasElement>(
        document.getElementById("captchaCanvas")
      );
      var ctx = canvas.getContext("2d")!;
      ctx.fillStyle = this.config.back.solid;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.beginPath();
      canvas.style.letterSpacing = 1 + "rem";
      ctx.font = this.config.font.size + " " + this.config.font.family;
      ctx.fillStyle = this.config.font.color;
      ctx.textBaseline = "middle";
      ctx.fillText(this.code, 40, 50);
      if (this.config.back.stroke) {
        ctx.strokeStyle = this.config.back.stroke;
        for (var i = 0; i < 150; i++) {
          ctx.moveTo(Math.random() * 300, Math.random() * 300);
          ctx.lineTo(Math.random() * 300, Math.random() * 300);
        }
        ctx.stroke();
      }
    }, 100);
  }

  checkCaptcha(): void {
    if (this.captchaInput.value !== this.resultCode.toString()) {
      console.log("failed");
    } else {
      console.log("success");
    }
  }
}
