<div id="popup" [formGroup]="annualForm" #calculateMilesOverlay>
  <div class="info" [ngClass]="{ calculateInfo: isDashboard }">
    <div class="popUp_header">Your Annual Miles</div>
    <div class="fields yt__calculate_miles">
      <div class="w-100 w-50-m w-50-l field-size">
        <div class="tech_type">Miles driven</div>
        <mat-form-field
          class="form_fields"
          floatLabel="auto"
          appearance="outline"
        >
          <input
            matInput
            type="number"
            formControlName="miles"
            placeholder="Enter miles"
            autocomplete="off"
          />
          <mat-error>Required!</mat-error>
        </mat-form-field>
      </div>
      <div class="w-100 w-25-m w-30-ns yt-day-field">
        <div class="tech_type">Per</div>
        <mat-form-field
          hideRequiredMarker="true"
          floatLabel="auto"
          class="form_fields"
          appearance="outline"
        >
          <mat-select formControlName="dayWeek">
            <mat-option
              *ngFor="let DayWeeks of DayWeek"
              [value]="DayWeeks.value"
            >
              {{ DayWeeks.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="calculateButton dialogBox">
      <button
        mat-raised-button
        color="accent"
        (click)="approximateMiles()"
        [disabled]="isFormFieldEmpty()"
      >
        CALCULATE
      </button>
    </div>
    <div class="result_text">
      Your approximate annual miles :
      <span class="milesValue">{{ approximateMilesValue | number }} miles</span>
    </div>
  </div>
</div>
