<div id="popUp">
  <div id="text">
    <div
      class="w-100 flex items-center justify-center yt_popup-padding yt_exclamation"
    >
      <div class="dib yt_heading fw6">{{ header }}</div>
    </div>
    <div class="yt_popup-padding yt_text lh-copy">
      <div>
        {{ subheader }}
      </div>
      <div class="popup-button">
        <button
          mat-raised-button
          color="accent"
          (click)="onSelectNo()"
          class="cancel-button"
        >
          {{ secondaryButton }}
        </button>
        <button
          mat-raised-button
          color="accent"
          (click)="onSelectYes()"
          class="continue-button"
        >
          {{ primaryButton }}
        </button>
      </div>
    </div>
  </div>
</div>
