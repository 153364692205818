import { NgModule } from '@angular/core';
import { LifestyleBoxComponent } from './lifestyle-box.component';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, MatIconModule],
  exports: [LifestyleBoxComponent],
  declarations: [LifestyleBoxComponent],
})
export class LifestyleBoxModule { }
