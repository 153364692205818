import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { JWTTokenInfoModel } from "../../auth/auth.model";
import { AuthenticationService } from "../../auth/auth.service";
import { DashboardAPIService } from "../../rest-api/dashboard/dashboard-api.service";

@Component({
  selector: "yt-energy-usage-overview",
  templateUrl: "energy-usage-overview.component.html",
  styleUrls: ["./energy-usage-overview.component.scss"],
})
export class EnergyUsageOverviewComponent {
  selected: string;
  cash: boolean;
  energy: boolean;
  carbon: boolean;
  sliderStatus: boolean;
  showAlert: boolean;
  sliderAlert: boolean;
  revokedAlert: boolean;
  partialAlert: boolean;
  savingsURL: string;
  baseURL: string;
  jwtTokenInfo: JWTTokenInfoModel;
  pgeOnlineStatus: boolean;
  costTabStatus: boolean;
  usageChart: string;
  usageChartHover: string;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    public DashboardAPI: DashboardAPIService
  ) {
    this.jwtTokenInfo = this.authenticationService.jwtTokenInfo;
    this.baseURL = DashboardAPI.API_END_POINT;
    this.savingsURL =
      this.baseURL + `ho/dashboard/savings/${this.jwtTokenInfo.userName}`;
  }

  ngOnInit() {
    this.defaultAlertStatus();
    this.costTabStatus =
      this.DashboardAPI.solarVendorDetails?.dashboard?.usageCostTab;
    this.broaderVendorsColorList();
    if (this.costTabStatus) {
      this.selected = "CASH";
      this.cash = true;
      this.energy = false;
      this.carbon = false;
    } else {
      this.selected = "ENERGY";
      this.energy = true;
      this.cash = false;
      this.carbon = false;
    }
  }

  slider(slider: boolean) {
    this.sliderStatus = slider;
    this.defaultAlertStatus();
  }

  defaultAlertStatus(): void {
    this.showAlert =
      this.partialAlert =
      this.sliderAlert =
      this.revokedAlert =
        this.DashboardAPI.showPgeUsageAlertStatus;
  }

  pgeOnline(pgeOnline: boolean) {
    this.pgeOnlineStatus = pgeOnline;
  }

  alertStatus(message: string) {
    this.showAlert =
      message !== "Complete auth" &&
      message !== "PGE under maintenance" &&
      this.DashboardAPI.showPgeUsageAlertStatus;
    this.partialAlert =
      message === "Partial auth" && this.DashboardAPI.showPgeUsageAlertStatus;
    this.sliderAlert =
      message === "Slider" && this.DashboardAPI.showPgeUsageAlertStatus;
    this.revokedAlert =
      message === "Revoked" && this.DashboardAPI.showPgeUsageAlertStatus;
  }

  onCash() {
    this.cash = true;
    this.energy = false;
    this.carbon = false;
  }
  onEnergy() {
    this.energy = true;
    this.cash = false;
    this.carbon = false;
  }

  onCarbon() {
    this.energy = false;
    this.cash = false;
    this.carbon = true;
  }

  onClose() {
    this.DashboardAPI.showPgeUsageAlertStatus = false;
    const alertElement = document.getElementById("alert");
    if (alertElement) alertElement.style.display = "none";
  }

  onEnergyUsage() {
    this.router.navigate(["/dashboard/overview"]);
    this.DashboardAPI.energyInfoData = true;
  }

  broaderVendorsColorList(): void {
    let a = document.querySelector("body")!;
    this.usageChart = getComputedStyle(a).getPropertyValue("--usageChart");
    this.usageChartHover =
      getComputedStyle(a).getPropertyValue("--usageChartHover");
  }
}
