import { Component, Input } from "@angular/core";
import { MarketPlaceDetailsModel } from "../../learn-more/learn-more.model";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";
import { Router } from "@angular/router";
import { MarketPlaceService } from "../../market-place/market-place.service";
@Component({
  selector: "yt-market-place",
  templateUrl: "market-place-card.component.html",
  styleUrls: ["market-place-card.component.scss"],
})
export class MarketPlaceCardComponent {
  vendorCodes: string[];
  techNames: string[];
  transportationTech: string[];
  energyEfficientTech: string[];
  isLowe: boolean;
  @Input() tech_name: string;
  @Input() linkId: string;
  @Input() marketPlaceDetails: MarketPlaceDetailsModel;

  constructor(
    private dashboardApiService: DashboardAPIService,
    private router: Router,
    private marketplaceService: MarketPlaceService
  ) {}

  ngOnInit() {
    this.isLowe =
      this.dashboardApiService.getUserDetails().solarVendorCode === "LOWE";
  }

  onStartShopping(buttonLink: string) {
    let marketPlaceDevices;
    this.dashboardApiService.dashboardDetailsAnalytics(
      "marketplace",
      this.tech_name
    );
    this.techNames = [
      "hpwh",
      "ashp",
      "hpd",
      "ic",
      "portableIC",
      "portableBattery",
      "electricFireplace",
    ];
    this.transportationTech = ["ev", "evChargers", "eBike"];
    this.energyEfficientTech = [
      "ledCflBulbs",
      "smartThermostat",
      "weatherStripping",
      "smartPowerStrips",
      "showerHeadsAerators",
      "everGreen",
    ];
    if (this.isLowe) {
      if (buttonLink) {
        this.marketplaceService.openShopNowTab(buttonLink);
      } else if (this.tech_name === "ev1" || this.tech_name === "ev2") {
        this.tech_name = "ev";
        this.router.navigateByUrl(
          `dashboard/market-place/transportationAndAccessories/${this.tech_name}`
        );
      }
    } else {
      if (this.tech_name === "ev1" || this.tech_name === "ev2") {
        this.tech_name = "ev";
      }
      if (
        this.tech_name === "solar" ||
        this.tech_name === "battery" ||
        this.tech_name === "communitySolar"
      ) {
        marketPlaceDevices = "solarBattery";
      } else if (this.techNames.includes(this.tech_name)) {
        marketPlaceDevices = `homeAppliances/${this.tech_name}`;
      } else if (this.transportationTech.includes(this.tech_name)) {
        marketPlaceDevices = "transportationAndAccessories";
      } else if (this.energyEfficientTech.includes(this.tech_name)) {
        if (
          this.tech_name === "ledCflBulbs" ||
          this.tech_name === "smartPowerStrips"
        ) {
          marketPlaceDevices = "energyEfficientDevices/lightingElectricDevices";
        } else if (
          this.tech_name === "smartThermostat" ||
          this.tech_name === "weatherStripping"
        ) {
          marketPlaceDevices = "energyEfficientDevices/homeComfortDevices";
        } else if (this.tech_name === "showerHeadsAerators") {
          marketPlaceDevices = "energyEfficientDevices/waterSavingDevices";
        } else if (this.tech_name === "everGreen") {
          window.open(this.linkId, "_blank");
        }
      }
      const marketPlaceRoute = `dashboard/market-place/${marketPlaceDevices}`;
      if (this.tech_name !== "everGreen") {
        this.transportationTech.includes(this.tech_name)
          ? this.router.navigateByUrl(`${marketPlaceRoute}/${this.tech_name}`)
          : this.router.navigateByUrl(marketPlaceRoute);
      }
    }
  }
}
