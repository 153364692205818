<ng-container *ngIf="activeTech === 'smartLightingControls'" class="container">
  <h2 class="tech_header">
    Smart Lighting Control
    <div class="desktop_display">
      <yt-star-rating [starCount]="1"></yt-star-rating>
    </div>
  </h2>
  <div class="mobile_display">
    <yt-star-rating [starCount]="1"></yt-star-rating>
  </div>
  <div class="yt__width">
    <div class="yt_content">
      <div>
        A smart lighting system allows you to control your lighting usage and
        conserve energy in a personalized and convenient way. You can automate
        your lighting by setting schedules and timers, and you can even access
        it remotely via a smart device.
      </div>
      <div class="padding_top">
        Using dimmers and adding motion and light sensors to your smart lighting
        system can help you save even more electricity. Most smart lighting
        appliances are easy to install and do not require any hardwiring.
      </div>
      <div class="padding_top">
        Note: If you currently have LED/CFL lights, you will likely not notice a
        significant cost savings.
        <span
          class="link_text pointer"
          (click)="onLearnMoreLink()"
          routerLink="/dashboard/details/led-bulbs"
        >
          Learn more about LED/CFL lighting </span
        >.
      </div>
    </div>
  </div>
  <div class="border flex">
    <div class="yard-costrange_savings border-right">
      $20-$50
      <div class="saving_text">
        Average Cost <span class="seperator">:</span>
      </div>
    </div>
    <div class="noise_rating">
      $60/year
      <div class="saving_text">
        Average Savings <span class="seperator">:</span>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="activeTech === 'hvacFilters'" class="container">
  <h2 class="tech_header">
    HVAC Filters
    <div class="desktop_display">
      <yt-star-rating [starCount]="1"></yt-star-rating>
    </div>
  </h2>
  <div class="mobile_display">
    <yt-star-rating [starCount]="1"></yt-star-rating>
  </div>
  <div class="yt__width">
    <div class="yt_content">
      <div>
        HVAC filters that aren't cleaned/replaced on a regular basis can
        increase wear on your HVAC system, increase your energy expenses,
        contribute to pollution, and create upper-respiratory problems. Filters
        should be changed or cleaned on a regular basis to ensure the efficiency
        of your system.  A dirty filter can increase your system's energy usage
        by 5 to 15 percent.
      </div>
      <div class="padding_top">
        Filters can be located in various places, depending on the type of HVAC
        system(s) you have. Check the Maintenance section of a system’s User
        Manual for details on filter locations and recommended frequency of
        cleaning/replacement.
      </div>
    </div>
  </div>
  <div class="border flex">
    <div class="yard-costrange_savings border-right">
      $5-$25
      <div class="saving_text">
        Average Cost <span class="seperator">:</span>
      </div>
    </div>
    <div class="noise_rating">
      $60/year
      <div class="saving_text">
        Average Savings <span class="seperator">:</span>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="activeTech === 'lineDryingClothes'" class="container">
  <h2 class="tech_header">
    Line Drying Clothes
    <div class="desktop_display">
      <yt-star-rating [starCount]="1"></yt-star-rating>
    </div>
  </h2>
  <div class="mobile_display">
    <yt-star-rating [starCount]="1"></yt-star-rating>
  </div>
  <div class="yt__width">
    <div class="yt_content">
      <div>
        You may significantly lower your home’s carbon footprint by simply
        switching from using a clothes dryer to air-drying your clothing.
      </div>
      <div class="padding_top">
        Air-drying prevents shrinkage, eliminates wrinkles, and disinfects
        clothing. It also enhances the freshness of clothes and removes odors
        without the use of chemical laundry fresheners like dryer sheets and
        fabric softeners.
      </div>
      <div class="padding_top">
        You can begin by purchasing a clothesline and clothespins for drying
        clothes outside. If you prefer to dry your clothing indoors, there are
        various options for folding drying racks.
      </div>
    </div>
  </div>
  <div class="border flex">
    <div class="yard-costrange_savings border-right">
      $10-$50
      <div class="saving_text">
        Average Cost <span class="seperator">:</span>
      </div>
    </div>
    <div class="noise_rating">
      $30-$350/year
      <div class="saving_text">
        Average Savings <span class="seperator">:</span>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="activeTech === 'waterHeaterTemperature'" class="container">
  <h2 class="tech_header">
    Water Heater Temperature
    <div class="desktop_display">
      <yt-star-rating [starCount]="1"></yt-star-rating>
    </div>
  </h2>
  <div class="mobile_display">
    <yt-star-rating [starCount]="1"></yt-star-rating>
  </div>
  <div class="yt__width">
    <div class="yt_content">
      <div>
        Lowering the temperature of your water heater is a no-cost,
        energy-efficient, and cost-saving solution.
      </div>
      <div class="padding_top">
        The US Department of Energy recommends a temperature of 120 degrees F
        for water heaters as a way to save energy. This temperature can also
        help to reduce the chance of household members (particularly children
        and the elderly) being scalded. 
      </div>
      <div class="padding_top">
        Note, however, that there is a slight risk of Legionella bacteria
        spreading at this temperature. Accordingly, you might want to consider
        this factor, especially if a family member is immunocompromised.
      </div>
    </div>
  </div>
  <div class="border flex">
    <div class="yard-costrange_savings border-right">
      $0
      <div class="saving_text">Cost <span class="seperator">:</span></div>
    </div>
    <div class="noise_rating">
      $40-$100/year
      <div class="saving_text">
        Average Savings <span class="seperator">:</span>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="activeTech === 'turningOffFaucet'" class="container">
  <h2 class="tech_header">
    Turning off Faucet
    <div class="desktop_display">
      <yt-star-rating [starCount]="1"></yt-star-rating>
    </div>
  </h2>
  <div class="mobile_display">
    <yt-star-rating [starCount]="1"></yt-star-rating>
  </div>
  <div class="yt__width">
    <div class="yt_content">
      <ol class="ol_padding">
        <li>
          Turn off the water while brushing your teeth or soaping your hands.
          According to the EPA, just doing this can save up to 200 gallons of
          water each month.
        </li>
        <li>
          Wash your vegetables in a pan of water instead of using running water.
        </li>
        <li>
          Fill a basin with water and rinse your dishes all at once rather than
          scrubbing them one at a time under running water.
        </li>
      </ol>
    </div>
  </div>
  <div class="border flex">
    <div class="yard-costrange_savings border-right">
      $0
      <div class="saving_text">
        Average Cost <span class="seperator">:</span>
      </div>
    </div>
    <div class="noise_rating">
      200-500 gallons/month
      <div class="saving_text">
        Average Water Savings <span class="seperator">:</span>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="activeTech === 'wateringPlantsAndYard'" class="container">
  <h2 class="tech_header">
    Watering Plants and Yard
    <div class="desktop_display">
      <yt-star-rating [starCount]="1"></yt-star-rating>
    </div>
  </h2>
  <div class="mobile_display">
    <yt-star-rating [starCount]="1"></yt-star-rating>
  </div>
  <div class="yt__width">
    <div class="yt_content">
      <ol class="ol_padding">
        <li>
          Water in the early morning or late evening as water will evaporate at
          a slower rate in cooler temperatures.
        </li>
        <li>
          Deep watering every few days is better than light watering each day as
          it improves root growth and conserves more water.
        </li>
        <li>
          Avoid over-watering plants. It’s time to water when the soil is dry or
          leaves are droopy or darker in color.
        </li>
        <li>
          Planting native and drought-resistant plants will help save water.
        </li>
        <li>
          Know your soil conditions to better understand the best watering
          frequency.
        </li>
      </ol>
    </div>
  </div>
  <div class="border flex">
    <div class="yard-costrange_savings border-right">
      $0
      <div class="saving_text">
        Average Cost <span class="seperator">:</span>
      </div>
    </div>
    <div class="noise_rating">
      $10-$100/year
      <div class="saving_text">
        Average Savings <span class="seperator">:</span>
      </div>
    </div>
  </div>
</ng-container>
