<div id="popUp" #expertOverlay>
  <div id="text">
    <div class="header">Ask an energy expert</div>
    <form [formGroup]="userMessage">
      <textarea
        class="user_message"
        rows="10"
        placeholder="Draft your message here..."
        formControlName="userText"
      ></textarea>
      <div class="w-100 request_btn">
        <button
          mat-raised-button
          color="accent"
          (click)="sendRequest()"
          [disabled]="userMessage.invalid"
        >
          SEND YOUR QUESTION
        </button>
      </div>
    </form>
  </div>
</div>
