import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeOwnerProductComponent } from "./homeowner-product.component";
import { MyInfoComponent } from "./my-info/my-info.component";
import { EnergyChangesComponent } from "./energy-changes/energy-changes.component";
import { EnergyChoicesComponent } from "./energy-choices/energy-choices.component";
import { AdditionalCommentsComponent } from "./additional-comments/additional-comments.component";
import { EnergyInfoComponent } from "./energy-info/energy-info.component";
import { ThankYouComponent } from "./thank-you/thank-you.component";
import { RoofTopComponent } from "./roof-top/roof-top.component";

const routes: Routes = [
  {
    path: "",
    component: HomeOwnerProductComponent,
    children: [
      { path: "", component: MyInfoComponent },
      { path: "roof-top", component: RoofTopComponent },
      { path: "additional-info", component: AdditionalCommentsComponent },
      {
        path: "energy-choices",
        component: EnergyChoicesComponent,
      },
      { path: "energy-changes", component: EnergyChangesComponent },
      { path: "energy-info", component: EnergyInfoComponent },
      { path: "thank-you", component: ThankYouComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeOwnerProductRoutingModule {}
