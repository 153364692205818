<div class="tech__summary">
  <div class="general_heading">Your Energy Information</div>
</div>
<div class="form__wrapper w80">
  <div class="utility_label">
    Electric Utility <span>{{ utilityName }}</span>
  </div>

  <div *ngIf="pgeSuccess || pgeInProgress" class="status_section">
    <div *ngIf="pgeSuccess" class="tech__summary">
      <div class="general_heading">You are all set!</div>
      <p>
        You have successfully completed your energy data authorization via the
        PG&E Share My Data platform. We can now analyze your detailed energy
        data and deliver more-accurate and personalized recommendations.
      </p>
    </div>
    <div *ngIf="pgeInProgress" class="tech__summary">
      <div class="general_heading">Thank you!</div>
      <p>
        You have completed your energy data authorization via the PG&E Share My
        Data platform. This process can take up to 20 minutes and your dashboard
        results will automatically be updated.
      </p>

      <div class="check_status">
        <span><a class="link__text" (click)="onRefresh()">Click here</a></span>
        to check status
      </div>
    </div>
  </div>
  <div class="container" *ngIf="energyInfoSection">
    <!-- Manual Estimate -->
    <div
      class="manual_section"
      *ngIf="revokedAuth"
      [ngClass]="{ manual_wrapper: !smdStatus && revokedAuth }"
    >
      <yt-manual-estimate
        [revokedAuth]="revokedAuth"
        [smdStatus]="smdStatus"
        (averageBill)="onAvgBill($event)"
      ></yt-manual-estimate>
    </div>
    <!-- OR separator -->
    <div class="separator" *ngIf="smdStatus && revokedAuth">OR</div>
    <!-- SMD SECTION -->
    <div
      class="smd_section"
      *ngIf="smdStatus || pgeStartStatus"
      [ngStyle]="{
        'padding-top':
          (smdStatus || pgeStartStatus) && revokedAuth ? '0px' : '28px'
      }"
    >
      <div
        *ngIf="!pgeStartStatus"
        class="smd_content"
        [ngClass]="{
          smd_wrapper: (smdStatus || pgeStartStatus) && !revokedAuth
        }"
      >
        <yt-share-my-data
          [authorizedURL]="authorizeEnergyUsageUrl"
          [isDashboard]="true"
          [isPGE]="isPGE"
          [authorizationSectionPGE]="authorizationSectionPGE"
          [revokedAuth]="revokedAuth"
          (pgeStartStatus)="pgeStarted()"
        ></yt-share-my-data>
      </div>
      <div class="info__text" *ngIf="pgeStartStatus">
        <p>
          You have started the authorization process. Your dashboard will
          refresh automatically after getting all your energy data from PG&E.
          This process takes 20-30 minutes approximately.
        </p>
      </div>
      <div class="auth_text" *ngIf="authorizationSection">
        After authorization,
        <span class="link__text" (click)="onRefresh()">click here</span> to
        check PG&E Share My Data authorization status
      </div>
    </div>
  </div>
</div>

<div id="loader" (click)="offRefresh()">
  <div id="text">
    <div class="bg-white">
      <div class="f4 yt_padding tc">
        We are still retrieving your energy information...
      </div>
      <div class="loader mh-auto"></div>
    </div>
  </div>
</div>

<div id="noAuth" (click)="offRefresh()">
  <div id="text">
    <div class="bg-white">
      <div class="yt_popup-header yt_padding fw6 tc">Not Authorized!</div>
      <div class="f4 tl">
        You have not authorized your energy data yet. Please click the LOGIN TO
        PG&E button to continue..
      </div>
    </div>
  </div>
</div>
