<!-- Dashboard -->
<div *ngIf="!cardStatus">
  <div class="flex">
    <img src="assets/dashboard/overview/advice.png" />
    <div class="header ml_14">
      {{ "homeOwenerDashboard.dashboard.expertAdviceCard.header" | translate }}
    </div>
  </div>
  <div class="text_content">
    {{ "homeOwenerDashboard.dashboard.expertAdviceCard.linkText" | translate }}
    {{ "homeOwenerDashboard.dashboard.expertAdviceCard.paragraph" | translate }}
  </div>
  <div class="button_section">
    <button
      mat-raised-button
      color="accent"
      class="button_width"
      (click)="onConnect()"
    >
      {{
        "homeOwenerDashboard.dashboard.expertAdviceCard.buttonText" | translate
      }}
    </button>
  </div>
</div>
<!-- learn more -->
<div class="card_display" *ngIf="cardStatus">
  <img src="assets/dashboard/overview/advice.png" />
  <div class="flex flex_col">
    <div class="header ml_14">
      {{ "homeOwenerDashboard.dashboard.expertAdviceCard.header" | translate }}
    </div>
    <div class="text_content flex_col ml_14">
      <a (click)="onConnect()" class="alert_link_text" target="_blank">{{
        "homeOwenerDashboard.dashboard.expertAdviceCard.linkText" | translate
      }}</a>
      {{
        "homeOwenerDashboard.dashboard.expertAdviceCard.paragraph" | translate
      }}
    </div>
  </div>
</div>

<!-- Expert Advice Popup -->
<yt-expert-popup
  *ngIf="showPopup"
  (closePopup)="onClosePopup()"
></yt-expert-popup>
