import { ActivatedRoute, Router } from "@angular/router";
import { RestAPIService } from "../../rest-api/rest-api.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { LayoutService } from "../shared/layout/layout.service";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "yt-additional-comments",
  templateUrl: "additional-comments.component.html",
  styleUrls: ["./additional-comments.component.scss"],
})
export class AdditionalCommentsComponent implements OnInit, OnDestroy {
  customerComment: FormControl;
  comments: string;
  private alive = true;

  constructor(
    public restAPIService: RestAPIService,
    private layoutServiceV2: LayoutService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.layoutServiceV2.hideSidenavSubject.next(false);
    this.customerComment = new FormControl();
    this.customerComment.valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => (this.comments = data));

    let hi = this.restAPIService.getCustomerComments();
    if (hi !== undefined) {
      hi = hi === "onomatopoeia" ? "" : hi;
      this.customerComment.setValue(hi);
    }

    if (this.restAPIService.getCustomerComments() === undefined) {
      this.restAPIService.setDestination(4);
    }
  }

  onContinue() {
    let buildingType = this.restAPIService.getBuildingType();
    let showLifeStyleButton: boolean;
    let showUpgradesButton: boolean;
    if (buildingType === "nonProfit" || buildingType === "commercial") {
      showLifeStyleButton = false;
      showUpgradesButton = true;
    } else {
      showLifeStyleButton = true;
      showUpgradesButton = false;
    }
    this.layoutServiceV2.navRouterSubject.next({
      personalScreen: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "1",
      },
      roofTop: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "2",
      },
      lifeStyle: {
        showLink: showLifeStyleButton,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "3",
      },
      additionalinfo: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "4",
      },
      upgrades: {
        showLink: showUpgradesButton,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "3",
      },
      energyUsage: {
        showLink: true,
        activatedLink: true,
        disabledLink: false,
        menuNumber: "5",
      },
      overview: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      logo1: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      logo2: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      usermenu: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      logout: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      poweredbyYt: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
    });

    this.comments =
      this.comments === undefined || this.comments === ""
        ? "onomatopoeia"
        : this.comments;
    this.restAPIService
      .postCoustmerComments(this.comments)
      .subscribe((data) => {
        if (data.status === 200) {
          this.router.navigate(["energy-info"], {
            relativeTo: this.route.parent,
          });
        }
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
