<div class="faq_container">
  <div class="about_technology" *ngIf="showAboutTech">
    <div class="header">Overview</div>
    <p>
      <strong>How it works:</strong> A conventional dryer creates the heat for
      drying by burning natural gas or using electric resistance heating
      elements. A heat pump dryer (HPD) is more energy efficient because it
      doesn’t create heat, but instead uses an electric-powered compressor, heat
      exchangers, and a refrigerant to move heat from the surrounding air into
      the dryer’s drum. It also recycles some of the heat by not letting it vent
      out of the dryer. This technology allows a HPD to use about one-half as
      much electricity as a traditional electric dryer.
      <span
        class="link_text"
        *ngIf="showReadMore"
        (click)="onReadContent('readMore')"
      >
        Read more...
      </span>
    </p>

    <div *ngIf="showReadLess">
      <p class="pt3">
        <strong>Types of Systems:</strong> There are typically 3 different
        sizes. Portable table top units that can do a 3 kg load (3 cu ft), a
        standard unit with about 6-7 kg load (4-5 cu ft) capacity and large
        units which are more popular in the Americas with about 9-11 kg (7-8 cu
        ft) capacity.
      </p>

      <p class="pt3">
        <strong>Special Requirements:</strong> Conventional dryers expel warm,
        moist air through a vent to outside the building. Heat pump dryers,
        however, don’t require venting because they condense the moisture into
        water that is sent to a drain or a collection tank. Hence, a condensate
        drain pipe is added to remove the water produced by the heat pump. If
        this presents a problem, heat pump dryers come equipped with a
        collection tank, but that tank must be emptied periodically.
      </p>

      <p class="pt3">
        <strong>Economics:</strong> The cost of a heat pump dryer is generally
        30-40% higher than a conventional electric clothes dryer.
      </p>

      <p class="pt3">
        <strong>Maintenance:</strong> As with any clothes dryer, it’s necessary
        to periodically clean the lint filter. Additionally, some heat pump
        dryers require the removal of lint from the condenser every several
        months.
      </p>

      <p class="pt3">
        <strong>Environment:</strong> The magnitude of environmental benefit
        depends largely on the type of dryer you’re switching from. The biggest
        impact occurs when switching from a natural gas or propane dryer to a
        heat pump dryer (especially if you’re using electricity with a low
        carbon content). When switching from traditional electric dryers the
        benefit is lower. Of course if you can live with a clothes line that is
        the best from an environmental point of view.
        <span class="link_text" (click)="onReadContent('readLess')">
          Read less
        </span>
      </p>
    </div>
  </div>

  <div *ngIf="!showAboutTech">
    <div *ngIf="isActive(0)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. What is the difference between a conventional clothes dryer and a
          heat pump dryer?
        </h5>
        <p class="answer_text">
          A conventional dryer creates the heat for drying by burning natural
          gas or using electric resistance heating elements. A heat pump dryer
          (HPD) is more energy efficient because it doesn’t create heat, but
          instead uses an electric-powered compressor, heat exchangers, and a
          refrigerant to move heat from the surrounding air into the dryer’s
          drum. This technology allows a HPD to use about one-half as much
          electricity as a traditional electric dryer. When replacing a natural
          gas dryer, a HPD can help reduce your home’s carbon footprint.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. How long does it take to dry clothes with a heat pump dryer?
        </h5>
        <p class="answer_text">
          Heat pump dryers operate at a lower temperature than a conventional
          dryer. Accordingly, drying times can be significantly longer.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">3. Do heat pump dryers need to be vented?</h5>
        <p class="answer_text">
          In most cases, no. Conventional dryers expel warm, moist air through a
          vent to outside the building. Heat pump dryers, however, don’t require
          venting because they condense the moisture into water that is sent to
          a drain or a collection tank. Some manufacturers market a hybrid dryer
          where the heat pump is optional and the consumer can choose to bypass
          it with conventional electric heating if faster drying is needed. Such
          dryers must be vented.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">4. What sizes are available?</h5>
        <p class="answer_text">
          Various capacity heat pump dryers are available, but the most popular
          ones come in two sizes – a smaller 3 – 4 cubic ft. capacity and a
          larger 7 – 8 cubic ft. capacity.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(1)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How expensive are heat pump dryers?</h5>
        <p class="answer_text">
          The cost of a heat pump dryer is generally in the range of $1,100 to
          $1,400. This is significantly more than low-cost traditional clothes
          dryers, which can often be found for $500 to $900.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. Do heat pump dryers save money over normal electric dryers?
        </h5>
        <p class="answer_text">
          Since they use about half the energy to dry, a heat pump dryer’s
          ongoing energy costs are lower than that of a conventional electric
          dryer. Depending on how often you use the dryer and the electricity
          costs in your area, you could possibly recoup your initial additional
          investment in as little as 5 years. If you use natural gas for drying
          your clothes, you’ll likely not save any money on your energy bills.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(2)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. What kind of maintenance issues arise?</h5>
        <p class="answer_text">
          As with any clothes dryer, it’s necessary to periodically clean the
          lint filter. Additionally, some heat pump dryers require the removal
          of lint from the condenser every several months. Finally, heat pump
          dryers produce water that must be either removed through a drain or
          collected in a tank. If you use the tank option, it must be emptied
          periodically. (Note that in most cases heat pump dryers do not have a
          vent or ducting that needs to be cleaned of lint as with a
          conventional dryer.)
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. How reliable are heat pump dryers compared to conventional clothes
          dryers?
        </h5>
        <p class="answer_text">
          This is a relatively new technology in the US, so long-term
          reliability data are still being collected here. While manufacturers’
          warranties are similar to those of traditional dryers, the design of a
          heat pump dryer is more complicated than a conventional dryer and that
          may have an adverse effect on long-term reliability.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(3)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. Is there a greenhouse gas reduction benefit of using a heat pump
          dryer?
        </h5>
        <p class="answer_text">
          Yes, but the magnitude depends largely on the type of dryer you’re
          switching from. The biggest impact occurs when switching from a
          natural gas or propane dryer to a heat pump dryer (especially if
          you’re using electricity with a low carbon content). If switching from
          a conventional electric dryer, the greenhouse gas benefit will likely
          be less dramatic, but will still be in the neighborhood of a 50%
          reduction.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(4)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. Are there any special installation steps for a heat pump dryer?
        </h5>
        <p class="answer_text">
          In many cases a condensate drain pipe is added to remove the water
          produced by the heat pump. If this presents a problem, heat pump
          dryers come equipped with a collection tank, but that tank must be
          emptied periodically. Except for hybrid models, heat pump dryers are
          ventless, so no outside venting duct is needed.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. Are stackable washer dryer models available?
        </h5>
        <p class="answer_text">
          Yes, stackable heat pump dryers and washer models are available. This
          can be an effective way to save space.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          3. What are the effects on clothing when a heat pump dryer is used?
        </h5>
        <p class="answer_text">
          Heat pump dryers operate at a lower temperature than a conventional
          dryer, and as a result can take a significantly longer time to dry a
          load. The lower temperatures are beneficial to clothing but the longer
          tumbling time adds more wear and tear.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">4. Are heat pump dryers safe?</h5>
        <p class="answer_text">
          Yes, they are probably safer than conventional dryers. Lint trapped in
          a conventional clothes dryer can sometimes catch fire and spread to
          the house via lint trapped in the vent ducting. Since a non-hybrid
          heat pump dryer doesn’t require venting, the fire danger is reduced.
        </p>
      </div>
    </div>
  </div>
</div>
