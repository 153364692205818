import { Component, OnInit, Input, OnChanges } from "@angular/core";
import {
  MarketPlaceDetailsModel,
  transportationModel,
  MarketPlaceDataModel,
} from "../market-place.model";
import { MarketPlaceService } from "../market-place.service";
import {  MatRadioChange } from "@angular/material/radio";

@Component({
  selector: "yt-transportation-tab",
  templateUrl: "transportation-tab.component.html",
  styleUrls: ["../marketplace-tab.component.scss"],
})
export class TransportationTabComponent implements OnInit, OnChanges {
  transportationDetail: MarketPlaceDataModel[];
  technologyDetails: MarketPlaceDetailsModel;
  boxText: string;
  buttonText: string;
  defaultValue: string;
  defaultIndex: number;
  @Input() activeTech: string;
  @Input() transportationAndAccessories: transportationModel;
  @Input() transportationJSON: MarketPlaceDataModel[];

  constructor(private marketPlaceService: MarketPlaceService) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.transportationDetail = Object.keys(
      this.transportationAndAccessories
    ).map(
      (tech) =>
        this.transportationJSON.find((transport) => tech === transport.value)!
    );
    this.setDefaultValues(
      this.activeTech || this.transportationDetail[0].value
    );
  }

  setDefaultValues(value: string): void {
    this.defaultValue = value;
    this.defaultIndex = this.transportationDetail.findIndex(
      (tech) => tech.value === this.defaultValue
    );
    this.marketPlaceService.onTabChange(
      "transportationAndAccessories",
      this.defaultValue
    );
  }

  onTechChange(event: MatRadioChange): void {
    this.marketPlaceService.onTabChange(
      "transportationAndAccessories",
      event.value
    );
    this.setDefaultValues(event.value);
  }
}
