import { AuthenticationService } from "../../../auth/auth.service";
import { RestAPIService } from "../../../rest-api/rest-api.service";
import { Component, OnInit, ViewChild, OnDestroy, Input } from "@angular/core";
import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { MatSidenav } from "@angular/material/sidenav";
import { Router } from "@angular/router";
import { LogoModel } from "../../../rest-api/rest-api.model";
import { JWTTokenInfoModel } from "../../../auth/auth.model";
import { LayoutService } from "../layout/layout.service";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "yt-side-nav",
  templateUrl: "side-nav.component.html",
  styleUrls: ["./side-nav.component.scss"],
})
export class SideNavComponent implements OnInit, OnDestroy {
  @ViewChild("drawer") sidenav: MatSidenav;
  @Input() routeInfo;
  @Input() hideSidenav: boolean;
  @Input() smdLink: boolean;
  @Input() logo: LogoModel;
  @Input() dashboardlogo: LogoModel;
  @Input() jwtToken: JWTTokenInfoModel;
  @Input() address: String;
  vendor: string;
  private alive = true;
  isHandset: Observable<BreakpointState>;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private layoutService: LayoutService,
    private restAPIService: RestAPIService,
    private authenticationService: AuthenticationService
  ) {
    this.isHandset = this.breakpointObserver.observe(Breakpoints.Handset);
  }

  toggleSideNav() {
    this.isHandset.pipe(takeWhile(() => this.alive)).subscribe((data) => {
      if (data.matches) {
        this.sidenav.toggle();
      }
    });
  }

  ngOnInit() {
    this.layoutService.change.subscribe((closeSideBar) => {
      if (closeSideBar) {
        if (this.sidenav.mode == "side") {
          this.sidenav.close();
        }
      } else {
        if (this.sidenav.mode == "side") {
          this.sidenav.open();
        }
      }
    });
  }

  onClickLogo() {
    window.open(this.logo.customer?.href, "_blank");
  }

  onClickLogout() {
    if (this.sidenav.mode == "over") {
      this.sidenav.close();
    }
    this.authenticationService.logout();
    this.router.navigateByUrl("/login");
  }

  ngOnDestroy() {
    this.alive = false;
  }

  onClickPersonal() {
    let smdPresent = this.restAPIService.getSmdLink();
    let menuNumber: string;

    if (smdPresent) {
      let hi = {
        ...this.routeInfo,
        personalScreen: {
          showLink: true,
          activatedLink: true,
          disabledLink: this.routeInfo.personalScreen.disabledLink,
          menuNumber: this.routeInfo.personalScreen.menuNumber,
        },
        roofTop: {
          showLink: false,
          activatedLink: false,
          disabledLink: this.routeInfo.roofTop.disabledLink,
          menuNumber: this.routeInfo.roofTop.menuNumber,
        },
        lifeStyle: {
          showLink: false,
          activatedLink: false,
          disabledLink: this.routeInfo.lifeStyle.disabledLink,
          menuNumber: this.routeInfo.lifeStyle.menuNumber,
        },
        additionalinfo: {
          showLink: false,
          activatedLink: false,
          disabledLink: this.routeInfo.additionalinfo.disabledLink,
          menuNumber: this.routeInfo.additionalinfo.menuNumber,
        },
        energyUsage: {
          showLink: true,
          activatedLink: false,
          disabledLink: this.routeInfo.energyUsage.disabledLink,
          menuNumber: this.routeInfo.energyUsage.menuNumber,
        },
      };
      this.layoutService.navRouterSubject.next(hi);
    } else {
      let showLifeStyleButton: boolean = true;
      let showUpgradesButton: boolean = false;
      let disableAdditionalInfo;
      let vendorType = this.restAPIService.solarVendorInformation.solutionType;
      let buildingType = this.restAPIService.getBuildingType();

      if (buildingType === "nonProfit") {
        showLifeStyleButton = false;
        showUpgradesButton = true;
      } else {
        showLifeStyleButton = true;
        showUpgradesButton = false;
      }
      if (
        vendorType === undefined ||
        vendorType === "cce" ||
        vendorType === "broader"
      ) {
        disableAdditionalInfo = true;
      } else {
        disableAdditionalInfo = false;
      }
      menuNumber = disableAdditionalInfo ? "3" : "5";

      this.layoutService.navRouterSubject.next({
        ...this.routeInfo,
        personalScreen: {
          showLink: true,
          activatedLink: true,
          disabledLink: false,
          menuNumber: "1",
        },
        roofTop: {
          showLink: !disableAdditionalInfo,
          activatedLink: false,
          disabledLink: true,
          menuNumber: "2",
        },
        lifeStyle: {
          showLink: showLifeStyleButton && vendorType !== "broader",
          activatedLink: false,
          disabledLink: true,
          menuNumber: disableAdditionalInfo ? "2" : "3",
        },
        upgrades: {
          showLink: showUpgradesButton,
          activatedLink: false,
          disabledLink: true,
          menuNumber: "3",
        },
        additionalinfo: {
          showLink: !disableAdditionalInfo,
          activatedLink: false,
          disabledLink: true,
          menuNumber: "4",
        },
        energyUsage: {
          showLink: true,
          activatedLink: false,
          disabledLink: true,
          menuNumber: menuNumber,
        },
      });
    }
    this.router.navigateByUrl("/sign-up");
  }

  onClickRoofTop() {
    let showLifeStyleButton: boolean = true;
    let showUpgradesButton: boolean = false;
    let disableAdditionalInfo;
    let vendorType = this.restAPIService.solarVendorInformation.solutionType;
    let buildingType = this.restAPIService.getBuildingType();
    let menuNumber: string;
    if (buildingType === "nonProfit" || buildingType === "commercial") {
      showLifeStyleButton = false;
      showUpgradesButton = true;
    } else {
      showLifeStyleButton = true;
      showUpgradesButton = false;
    }
    if (
      vendorType === undefined ||
      vendorType === "cce" ||
      vendorType === "broader"
    ) {
      disableAdditionalInfo = true;
    } else {
      disableAdditionalInfo = false;
    }
    menuNumber = disableAdditionalInfo ? "4" : "5";
    this.layoutService.navRouterSubject.next({
      ...this.routeInfo,
      personalScreen: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "1",
      },
      roofTop: {
        showLink: true,
        activatedLink: true,
        disabledLink: false,
        menuNumber: "2",
      },
      lifeStyle: {
        showLink: showLifeStyleButton,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "3",
      },
      upgrades: {
        showLink: showUpgradesButton,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "3",
      },
      additionalinfo: {
        showLink: !disableAdditionalInfo,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "4",
      },
      energyUsage: {
        showLink: true,
        activatedLink: false,
        disabledLink: true,
        menuNumber: menuNumber,
      },
    });
    this.router.navigateByUrl("sign-up/roof-top");
  }

  onClickLifeStyle() {
    let disableAdditionalInfo;
    let vendorType = this.restAPIService.solarVendorInformation.solutionType;
    let menuNumber: string;
    if (
      vendorType === undefined ||
      vendorType === "cce" ||
      vendorType === "broader"
    ) {
      disableAdditionalInfo = true;
    } else {
      disableAdditionalInfo = false;
    }
    menuNumber = disableAdditionalInfo ? "3" : "5";
    this.layoutService.navRouterSubject.next({
      ...this.routeInfo,
      personalScreen: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "1",
      },
      roofTop: {
        showLink: !disableAdditionalInfo,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "2",
      },
      lifeStyle: {
        showLink: true,
        activatedLink: true,
        disabledLink: false,
        menuNumber: disableAdditionalInfo ? "2" : "3",
      },
      additionalinfo: {
        showLink: !disableAdditionalInfo,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "4",
      },
      energyUsage: {
        showLink: true,
        activatedLink: false,
        disabledLink: true,
        menuNumber: menuNumber,
      },
    });
    this.router.navigateByUrl("sign-up/energy-choices");
  }

  onClickUpgrades() {
    let disableAdditionalInfo;
    let vendorType = this.restAPIService.solarVendorInformation.solutionType;
    let menuNumber: string;
    if (
      vendorType === undefined ||
      vendorType === "cce" ||
      vendorType === "broader"
    ) {
      disableAdditionalInfo = true;
    } else {
      disableAdditionalInfo = false;
    }
    menuNumber = disableAdditionalInfo ? "4" : "5";
    this.layoutService.navRouterSubject.next({
      ...this.routeInfo,
      personalScreen: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "1",
      },
      roofTop: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "2",
      },
      upgrades: {
        showLink: true,
        activatedLink: true,
        disabledLink: false,
        menuNumber: "3",
      },
      additionalinfo: {
        showLink: !disableAdditionalInfo,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "4",
      },
      energyUsage: {
        showLink: true,
        activatedLink: false,
        disabledLink: true,
        menuNumber: menuNumber,
      },
    });
    this.router.navigateByUrl("sign-up/energy-changes");
  }

  onClickAdditionalInfo() {
    let showLifeStyleButton: boolean = true;
    let showUpgradesButton: boolean = false;
    let disableAdditionalInfo;
    let vendorType = this.restAPIService.solarVendorInformation.solutionType;
    let buildingType = this.restAPIService.getBuildingType();
    let menuNumber: string;
    if (buildingType === "nonProfit" || buildingType === "commercial") {
      showLifeStyleButton = false;
      showUpgradesButton = true;
    } else {
      showLifeStyleButton = true;
      showUpgradesButton = false;
    }
    if (
      vendorType === undefined ||
      vendorType === "cce" ||
      vendorType === "broader"
    ) {
      disableAdditionalInfo = true;
    } else {
      disableAdditionalInfo = false;
    }
    menuNumber = disableAdditionalInfo ? "4" : "5";
    this.layoutService.navRouterSubject.next({
      ...this.routeInfo,
      personalScreen: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "1",
      },
      roofTop: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "2",
      },
      lifeStyle: {
        showLink: showLifeStyleButton,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "3",
      },
      upgrades: {
        showLink: showUpgradesButton,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "3",
      },
      additionalinfo: {
        showLink: !disableAdditionalInfo,
        activatedLink: true,
        disabledLink: false,
        menuNumber: "4",
      },
      energyUsage: {
        showLink: true,
        activatedLink: false,
        disabledLink: true,
        menuNumber: menuNumber,
      },
    });
    this.router.navigateByUrl("sign-up/additional-info");
  }

  onClickEnergyUsage() {
    let smdPresent = this.restAPIService.getSmdLink();
    if (smdPresent) {
      let hi = {
        ...this.routeInfo,
        personalScreen: {
          showLink: true,
          activatedLink: false,
          disabledLink: false,
          menuNumber: "1",
        },
        roofTop: {
          showLink: false,
          activatedLink: false,
          disabledLink: false,
          menuNumber: "",
        },
        lifeStyle: {
          showLink: false,
          activatedLink: false,
          disabledLink: false,
          menuNumber: "",
        },
        additionalinfo: {
          showLink: false,
          activatedLink: false,
          disabledLink: false,
          menuNumber: "",
        },
        energyUsage: {
          showLink: true,
          activatedLink: true,
          disabledLink: false,
          menuNumber: "2",
        },
      };
      this.layoutService.navRouterSubject.next(hi);
    } else {
      let disableAdditionalInfo;
      let vendorType = this.restAPIService.solarVendorInformation.solutionType;
      let buildingType = this.restAPIService.getBuildingType();
      let menuNumber: string;
      if (
        vendorType === undefined ||
        vendorType == "cce" ||
        vendorType === "broader"
      ) {
        disableAdditionalInfo = true;
      } else {
        disableAdditionalInfo = false;
      }
      menuNumber = disableAdditionalInfo ? "3" : "5";
      menuNumber = vendorType === "broader" ? "2" : menuNumber;
      this.layoutService.navRouterSubject.next({
        ...this.routeInfo,
        personalScreen: {
          showLink: true,
          activatedLink: false,
          disabledLink: this.routeInfo.personalScreen.disabledLink,
          menuNumber: "1",
        },
        roofTop: {
          showLink: !disableAdditionalInfo,
          activatedLink: false,
          disabledLink: this.routeInfo.roofTop.disabledLink,
          menuNumber: "2",
        },
        lifeStyle: {
          showLink: vendorType !== "broader" && buildingType !== "nonProfit",
          activatedLink: false,
          disabledLink: this.routeInfo.lifeStyle.disabledLink,
          menuNumber: disableAdditionalInfo ? "2" : "3",
        },
        upgrades: {
          showLink: buildingType === "nonProfit",
          activatedLink: false,
          disabledLink: false,
          menuNumber: "3",
        },
        additionalinfo: {
          showLink: !disableAdditionalInfo && vendorType !== "broader",
          activatedLink: false,
          disabledLink: this.routeInfo.additionalinfo.disabledLink,
          menuNumber: "4",
        },
        energyUsage: {
          showLink: true,
          activatedLink: true,
          disabledLink: this.routeInfo.energyUsage.disabledLink,
          menuNumber: menuNumber,
        },
      });
    }
    this.router.navigateByUrl("sign-up/energy-info");
  }
}
