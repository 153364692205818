import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { HPWHDataModel } from "../../../../model/userdata.model";
import { LearnMoreService } from "../../../../../learn-more/learn-more.service";
import { DashboardAPIService } from "../../../../../../rest-api/dashboard/dashboard-api.service";
import { RadioOptionsModel } from "../../../../../../rest-api/dashboard/dashboard-api.model";
import { MatRadioChange } from "@angular/material/radio";
import { SettingsDialogData } from "../../setting-overlay.component.model";
import { Utils } from "../../../../../../utils/util";
import { SettingsOverlayService } from "../../settings-overlay.service";
import { DropDownModel } from "../../../../../../rest-api/rest-api.model";
import { Subject } from "rxjs";

@Component({
  selector: "yt-hpwh",
  templateUrl: "hpwh.component.html",
  styleUrls: ["../../common-tech.scss"],
})
export class HPWHComponent implements AfterContentChecked {
  hpwhForm: FormGroup;
  monthList: string[];
  lastTwelthMonth: string;
  disableDatePicker: boolean = false;
  hpwhDataModel: HPWHDataModel[];
  ownValue: string;
  selectedRadioButton: string;
  radioButtonOptions: RadioOptionsModel[];
  hpwhTypes: DropDownModel[];
  hideFormField: boolean;
  hpwhSettings: HPWHDataModel[];
  currentHeater: string;
  ageList: DropDownModel[];
  haveInputError: boolean;
  @Input() activeIcon: string;
  @Input() HPWHDetails;
  subject: Subject<any> = new Subject();
  @Output() techSave: EventEmitter<SettingsDialogData> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private utils: Utils,
    private learnMoreService: LearnMoreService,
    private dashboardAPIService: DashboardAPIService,
    private settingService: SettingsOverlayService
  ) {
    this.hpwhTypes = this.learnMoreService.energyChoiceType;
    this.hpwhForm = this.fb.group({
      own: ["", Validators.required],
      currentHeater: ["", Validators.required],
      age: ["6", Validators.required],
      bought: [""],
      datePurchased: ["", Validators.required],
    });
  }

  ngAfterContentChecked(): void {
    const datePurchased = this.hpwhForm.get("datePurchased")!;
    if (this.haveInputError) this.haveInputError = datePurchased.invalid;
  }

  ngOnInit() {
    this.dashboardAPIService.getRadioButtonDetails().subscribe((data) => {
      this.radioButtonOptions = data.radioButtons;
    });
    this.monthList = this.utils.monthList;
    this.lastTwelthMonth = this.utils.lastTwelthMonth;
    this.haveInputError = this.settingService.checkSettingsInfoError("hpwh");
    this.hpwhDataModel = this.haveInputError
      ? this.settingService.settingsInfoErrors.appliances?.hpwh!
      : this.settingService.checkSettingsInfo("hpwh")
      ? this.settingService.settingsInfo.appliances?.hpwh!
      : this.dashboardAPIService.energyChoicesData.appliances.hpwh!;
    this.hpwhDataModel.forEach((hpwh: HPWHDataModel) => {
      this.ownValue = hpwh.own!;
      this.setSelectedRadioValues(this.ownValue);
      this.hpwhForm.patchValue(hpwh);
      if (hpwh.own === "yes") {
        this.hideFormField = false;
        this.hpwhForm.get("age")?.disable();
        this.hpwhForm.get("currentHeater")?.disable();
        if (!hpwh.bought) {
          this.hpwhForm.get("datePurchased")?.setValue(this.lastTwelthMonth);
        }
        this.disableDatePicker = true;
      } else if (hpwh.own === "yesPlan") {
        this.hideFormField = true;
        this.hpwhForm.enable();
        this.hpwhForm.get("bought")?.disable();
        this.currentHeater = hpwh.currentHeater?.toString()!;
        this.ageList = this.dashboardAPIService.conventionalTypeRange;
        this.hpwhForm.get("datePurchased")?.disable();
        this.disableDatePicker = false;
      } else if (hpwh.own === "noPlan") {
        this.hideFormField = true;
        this.hpwhForm.enable();
        this.currentHeater = hpwh.currentHeater?.toString()!;
        this.ageList = this.dashboardAPIService.conventionalTypeRange;
        this.hpwhForm.get("bought")?.disable();
        this.hpwhForm.get("datePurchased")?.disable();
        this.disableDatePicker = false;
      }
      this.hpwhForm.get("own")?.enable();
    });
    if (this.activeIcon) {
      if (this.activeIcon === "favorite") {
        this.hpwhForm.get("own")?.setValue("yesPlan");
      } else if (this.activeIcon === "alreadyHave") {
        this.hpwhForm.get("own")?.setValue("yes");
      }
      this.onValueChanges();
      this.setSelectedRadioValues(this.hpwhForm.get("own")?.value);
      this.condition();
    } else {
      this.onValueChanges();
    }
  }

  onRadioChange(event: MatRadioChange) {
    this.setSelectedRadioValues(event.value);
  }

  setSelectedRadioValues(value: string): void {
    this.selectedRadioButton = value;
  }

  condition() {
    this.ownValue = this.hpwhForm.get("own")?.value;
    const hpwh = this.dashboardAPIService.energyChoicesData.appliances.hpwh![0];
    if (this.ownValue === "yes") {
      this.hideFormField = false;
      this.hpwhForm.get("bought")?.enable();
      this.hpwhForm.get("datePurchased")?.enable();
      this.disableDatePicker = true;
    } else if (this.ownValue === "yesPlan") {
      this.hideFormField = true;
      this.hpwhForm.get("currentHeater")?.enable();
      this.hpwhForm.get("age")?.enable();
      this.hpwhForm.get("currentHeater")?.setValue(hpwh.currentHeater);
      this.currentHeater = hpwh.currentHeater?.toString()!;
      this.ageList = this.dashboardAPIService.conventionalTypeRange;
      this.hpwhForm.get("age")?.setValue(hpwh.age);
      this.hpwhForm.get("bought")?.disable();
      this.hpwhForm.get("datePurchased")?.disable();
      this.disableDatePicker = false;
    } else if (this.ownValue === "noPlan") {
      this.hideFormField = true;
      this.hpwhForm.get("currentHeater")?.enable();
      this.hpwhForm.get("age")?.enable();
      this.hpwhForm.get("currentHeater")?.setValue(hpwh.currentHeater);
      this.currentHeater = hpwh.currentHeater?.toString()!;
      this.ageList = this.dashboardAPIService.conventionalTypeRange;
      this.hpwhForm.get("age")?.setValue(hpwh.age);
      this.hpwhForm.get("bought")?.disable();
      this.hpwhForm.get("datePurchased")?.disable();
      this.disableDatePicker = false;
    }
  }

  isDatePurchasedEmpty() {
    if (this.ownValue === "yes") {
      if (!this.hpwhForm.get("datePurchased")?.value) {
        return true;
      }
    }
    return false;
  }

  onValueChanges(): void {
    this.hpwhForm.valueChanges.subscribe((data) => {
      this.subject.pipe().subscribe(() => {
        if (data.own === "yes") {
          const datePurchased = data.datePurchased;
          const bought = this.utils.isDatePurchaseSelected(datePurchased);
          this.hpwhSettings = [
            {
              own: data.own,
              bought: bought,
              datePurchased: bought ? datePurchased : null,
            },
          ];
        } else if (data.own === "yesPlan" || data.own === "noPlan") {
          this.hpwhSettings = [
            {
              own: data.own,
              currentHeater: data.currentHeater,
              age: data.age,
            },
          ];
        }
        if (this.hpwhForm.valid) {
          this.settingService.updateSettingsInfo(
            this.dashboardAPIService.energyChoicesData.appliances,
            this.hpwhSettings,
            "appliances",
            "hpwh"
          );
        } else {
          this.settingService.updateSettingsInfoErrors(
            this.dashboardAPIService.energyChoicesData.appliances,
            this.hpwhSettings,
            "appliances",
            "hpwh"
          );
        }
      });
      this.subject.next();
    });
  }

  onSaveHPWH() {
    this.settingService.putSettingsInfo(this.getSettingsInfo);
  }

  getSettingsInfo = () => {
    if (this.activeIcon) {
      this.techSave.emit({ own: this.ownValue, tech: "hpwh" });
    }
  };
}
