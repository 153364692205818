<div>
  <div class="tech__summary">
    <div class="general_heading">Your Home Information</div>
  </div>
</div>
<div class="form__container" [formGroup]="generalForm">
  <div class="form__wrapper w80 mt_0">
    <div class="label">No. of people in household</div>
    <mat-form-field
      floatLabel="auto"
      appearance="outline"
      hideRequiredMarker="true"
    >
      <input
        matInput
        type="text"
        maxlength="3"
        placeholder="Enter 1-10"
        formControlName="howManyPeople"
      />
      <mat-error
        *ngIf="
          generalForm.controls['howManyPeople'].hasError('pattern') ||
          generalForm.controls['howManyPeople'].hasError('min') ||
          generalForm.controls['howManyPeople'].hasError('max')
        "
      >
        Please enter value between 1-10
      </mat-error>
      <mat-error
        *ngIf="generalForm.controls['howManyPeople'].hasError('required')"
      >
        Required!
      </mat-error>
    </mat-form-field>

    <div class="form__section">
      <div class="input_container">
        <div class="label">Living space (in sq.ft)</div>
        <div class="yt__maxwidth">
          <mat-form-field
            floatLabel="auto"
            appearance="outline"
            hideRequiredMarker="true"
          >
            <input
              matInput
              type="text"
              maxlength="5"
              placeholder="Enter 200-5000"
              formControlName="sqfoot"
            />
            <mat-error
              *ngIf="
                generalForm.controls['sqfoot'].hasError('min') ||
                generalForm.controls['sqfoot'].hasError('max') ||
                generalForm.controls['sqfoot'].hasError('pattern')
              "
            >
              Please enter 200-5000
            </mat-error>
            <mat-error
              *ngIf="generalForm.controls['sqfoot'].hasError('required')"
            >
              Required!
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="input_container mb_4">
        <div class="label">No. of stories</div>
        <div class="yt__max-width">
          <mat-form-field floatLabel="auto" appearance="outline">
            <mat-select formControlName="stories">
              <mat-option
                [value]="item.value"
                *ngFor="let item of storiesRange"
                >{{ item.viewValue }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="checkbox_container">
      <mat-checkbox
        Value="checked"
        formControlName="incentives"
        class="checkbox_content"
      >
        Include incentives for low/moderate income households.
      </mat-checkbox>
    </div>
  </div>
  <div class="margin_top">
    <yt-energy-info
      [utilityName]="utilityName"
      (renewableStatus)="onUpdateEverGreenStatus($event)"
      (avgMonthlyBill)="onAvgMonthlyBill($event)"
      [smdStatus]="smdStatus"
    ></yt-energy-info>
  </div>
  <div class="button_container w-100">
    <button
      mat-raised-button
      color="accent"
      (click)="onSaveGeneral()"
      [disabled]="generalForm.invalid"
      class="hover-save fr button_font w-100 w-30-ns"
    >
      SAVE
    </button>
  </div>
</div>
<div class="note__message">
  NOTE: The above fields have been pre-filled with default values.
</div>
