<div class="faq_container">
  <div class="about_technology" *ngIf="showAboutTech">
    <div class="header">Overview</div>
    <p>
      <strong>Smarter Heating and Cooling:</strong> Installing a smart
      thermostat is a great way to start saving.  It’ll help optimize your
      home’s heating and cooling energy usage, which can often be 40-50% of
      total usage.
      <span
        class="link_text"
        *ngIf="showReadMore"
        (click)="onReadContent('readMore')"
      >
        Read more...
      </span>
    </p>
    <div *ngIf="showReadLess">
      <p class="pt3">
        <strong>How it Helps:</strong> Smart thermostats make it easier to gain
        control of your heating and cooling costs. It’s simple to control the
        temperature of your home from anywhere via a smartphone-based app. Many
        smart thermostats can even optimize your heating and cooling on their
        own by sensing the schedule of your home’s occupants over time.
      </p>

      <p class="pt3">
        <strong>Cost:</strong> Many models are available in the range of
        $130-$350, and rebates are available in various parts of the US, making
        them more affordable.
      </p>

      <p class="pt3">
        <strong>Environment:</strong> Cutting your heating and cooling energy
        use is a great way to shrink your carbon footprint.
      </p>

      <p class="pt3">
        <strong>Increased Comfort:</strong> A smart thermostat can help you keep
        your home at the right temperature, making your home more comfortable in
        both the winter and summer.
        <span class="link_text" (click)="onReadContent('readLess')">
          Read less
        </span>
      </p>
    </div>
  </div>
  <div *ngIf="!showAboutTech">
    <div *ngIf="isActive(0)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How does a smart thermostat work?</h5>
        <p class="answer_text">
          Smart thermostats can make it easier to gain control of your heating
          and cooling usage. Unlike normal thermostats, you can control a smart
          thermostat from anywhere via an app, so as schedules change, you can
          adapt your energy usage accordingly. Many are also smart enough to
          learn about your temperature preferences over time, check on whether
          anyone’s home, download a weather forecast, and use all that
          information to optimize your usage for you. Some models even provide
          you with feedback about your heating and cooling energy use.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. How does a smart thermostat differ from a programmable thermostat?
        </h5>
        <p class="answer_text">
          A programmable thermostat can be set to change the temperature setting
          of your home according to a pre-set schedule. For example, using the
          thermostat’s digital interface you can change the settings to use less
          energy when everyone is out, or in the evenings when you’re asleep.
          Many people, however, find it difficult (or just don’t bother) to
          program such thermostats. A smart thermostat, however, can help by
          making it easier to manage your temperature settings. Since they are
          tied to the Internet and controlled by an app or voice, you can more
          easily and flexibly reset the smart thermostat’s temperature settings
          anytime you want to match changing schedules. Also, some of them learn
          about your temperature preferences over time and automatically
          determine the optimal programming to meet your needs. Some link to
          occupancy and temperature sensors as they optimize, and some even tie
          into weather forecasts to predict what the needs will be.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(1)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How expensive are smart thermostats?</h5>
        <p class="answer_text">
          Most are available in the range of $150-$250. The higher-priced models
          sometimes come with added features, such as air quality monitors,
          reminders about filter changes, and the ability to link into
          occupancy/temperature sensors and even air quality monitors.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">2. How much can I save?</h5>
        <p class="answer_text">
          A smart thermostat can often save 10% – 20% of a home’s heating and
          cooling bills. This can be significant since space conditioning
          generally consumes 40% - 50% of a household’s energy.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(2)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. How do smart thermostats help the environment?
        </h5>
        <p class="answer_text">
          Since heating and cooling represents a sizeable portion of a home’s
          energy usage, cutting even 10% - 20% of this usage can really help cut
          your carbon footprint. Also, since some smart thermostats provide
          feedback on energy usage and trends, they can provide a better
          understanding of energy use and help you reduce even more.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(3)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. Can I install a smart thermostat myself?
        </h5>
        <p class="answer_text">
          By carefully following the enclosed instructions most homeowners can
          install one themselves. If you are a renter and want to replace the
          existing thermostat with a smart one, make sure that the process is
          reversible and you can restore the older thermostat when it's time to
          move.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. Does it need to be connected to the Internet?
        </h5>
        <p class="answer_text">
          In general, yes -- smart thermostats should be linked to the Internet
          for them to function properly.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(4)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. What incentives are available?</h5>
        <p class="answer_text">
          Many utilities and some government entities offer rebates for smart
          thermostats. These are generally in the range of $50 - $100.
          Additionally, financial incentives (such as $5/month) are sometimes
          provided by utilities for participation in demand-reduction programs
          that tie into a home’s smart thermostat. Such programs cut electricity
          use during peak periods, helping to promote grid reliability as well
          as reduced emissions.
        </p>
      </div>
    </div>
  </div>
</div>
