import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  HostListener,
  Input,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DropDownModel } from "../../rest-api/rest-api.model";

@Component({
  selector: "yt-calculate-miles",
  templateUrl: "calculate-miles.component.html",
  styleUrls: ["./calculate-miles.component.scss"],
})
export class CalculateMilesComponent implements OnInit {
  @Input() isDashboard: boolean;
  annualForm: FormGroup;
  approximateMilesValue: number = 0;
  DayWeek: DropDownModel[] = [
    { value: "Day", viewValue: "Day" },
    { value: "Week", viewValue: "Week" },
  ];
  @Output("onClose") onPopupClose: EventEmitter<any> = new EventEmitter();
  @ViewChild("calculateMilesOverlay", { read: ElementRef }) modal: ElementRef;

  constructor(private fb: FormBuilder) {}

  @HostListener("document:keydown", ["$event"])
  onKeyDown(event): void {
    if (event.key === "Escape") {
      this.onClose();
    }
  }
  @HostListener("document:click", ["$event"])
  onDocumentClick(event): void {
    if (this.modal.nativeElement) {
      if (this.modal.nativeElement === event.target) {
        this.onClose();
      }
    }
  }

  ngOnInit(): void {
    this.annualForm = this.fb.group({
      miles: ["", Validators.required],
      dayWeek: ["Day", Validators.required],
    });
  }

  get milesValue(): string {
    return this.annualForm.get("miles")?.value;
  }

  approximateMiles(): void {
    if (this.milesValue && this.annualForm.get("dayWeek")?.value === "Day") {
      this.approximateMilesValue = Number(this.milesValue) * 365;
    } else if (
      this.milesValue &&
      this.annualForm.get("dayWeek")?.value === "Week"
    ) {
      this.approximateMilesValue = Number(this.milesValue) * 52;
    }
  }

  isFormFieldEmpty(): boolean {
    return !this.milesValue;
  }

  onClose(): void {
    this.onPopupClose.emit();
  }
}
