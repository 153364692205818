import { Component, Input, OnChanges } from "@angular/core";
import { DashboardAPIService } from "../../../../rest-api/dashboard/dashboard-api.service";
import { LearningHubModel } from "../../model/solarvendordetails.model";
import { CleanChoicesSections, CleanChoicesTabs } from "../../../../utils/enum";

@Component({
  selector: "yt-clean-choices",
  templateUrl: "./clean-choices.component.html",
  styleUrls: ["./clean-choices.component.scss"],
})
export class CleanChoicesComponent implements OnChanges {
  header: string;
  subHeader: string;
  currentTech: string = "";
  prevTab: string;
  techLength: boolean;
  techCardList: LearningHubModel;
  @Input() activeTab: string;
  @Input() section: string;
  @Input() activeTech: string;

  constructor(private dashboardAPI: DashboardAPIService) {}

  ngOnChanges(): void {
    this.techCardList = this.dashboardAPI.vendorDetails.learningHub;
    if (this.activeTech) {
      this.currentTech = this.activeTech in this.techCardList[this.activeTab][this.section]
        ? this.activeTech
        : "";
    }
    if (this.activeTab === CleanChoicesTabs.SIMPLE_LOW_COST) {
      this.header = "Other simple and low cost recommendations for your home";
      this.subHeader =
        "Adopt these simple and low-cost approaches to minimize your energy use, lower your costs, and reduce carbon emissions.";
    } else if (this.activeTab === CleanChoicesTabs.TRANSPORTATION) {
      this.header = "Other transportation recommendations for you";
      this.subHeader =
        "Public transit helps to minimize traffic congestion, pollution, and carbon emissions. Make the switch to a more environmentally friendly commute by utilizing public transit in your area.";
    } else if (this.activeTab === CleanChoicesTabs.APPLIANCES) {
      if (this.section === CleanChoicesSections.OTHER_APPLIANCES) {
        this.header = "Other appliance recommendations for your home";
        this.subHeader =
          "Efficient home appliances are inexpensive and can help lower your household's energy bill as well as its environmental impact.";
      }
      if (this.section === CleanChoicesSections.YARD_APPLIANCES) {
        this.header = "A few recommendations for your yard";
        this.subHeader =
          "Electric yard appliances are a healthier, exhaust-free, quieter, and cost-effective alternative to the traditional gas-powered lawn maintenance tools.";
      }
      if (this.section === CleanChoicesSections.ENERGY_EFFICIENCY_APPLIANCES) {
        this.header =
          "Some effective energy efficiency recommendations to reduce heating/cooling costs.";
        this.subHeader =
          "Effective energy efficiency measures will keep your home comfortable in all seasons while saving you money on utility bills and reducing your carbon footprint.";
      }
    }
    this.scrollToCleanChoices();
  }

  activeTechInfo(tech: string): void {
    this.currentTech = this.currentTech === tech ? "" : tech;
  }

  onClose(): void {
    this.currentTech = "";
  }

  checkTechListLength(selectedTab: string): boolean {
    if (selectedTab && (selectedTab in this.techCardList)) {
      const selectedTech = this.techCardList[selectedTab][this.section];
      return Object.values(selectedTech).some((value) => value === true);
    }
    return false;
  }

  //To avoid multiple re-render of DOM
  calculateLength(selectedTab: string): boolean {
    if (this.prevTab !== selectedTab) {
      this.prevTab = selectedTab;
      this.techLength = this.checkTechListLength(selectedTab);
    }
    return this.techLength;
  }

  scrollToCleanChoices(): void {
    if (this.activeTech && this.activeTech in this.techCardList[this.activeTab][this.section]) {
      setTimeout(() => {
        const element = document.querySelector(`#${this.section} .wrapper`);
        if (element) {
          element.scrollIntoView();
        }
      }, 500);
    }
  }
}
