import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "yt-back",
  templateUrl: "back-button.component.html",
  styleUrls: ["./back-button.component.scss"],
})
export class BackButtonComponent {
  constructor(private router: Router) {}
  @Input() navigateTo;
  @Input() isLandingPage:boolean;
  ngOnInit() {}

  onBackClick() {
    if(this.isLandingPage){
      this.router.navigateByUrl(`${this.navigateTo}`)
    }else{
    this.router.navigateByUrl(`/dashboard/${this.navigateTo}`);
    }
  }
}
