import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { TimeFrameEnum } from "../../homeowner-dashboard/learn-more/learn-more.enum";

@Component({
  selector: "app-Time-Rendering",
  templateUrl: "./Time-Rendering.component.html",
  styleUrls: ["./Time-Rendering.component.scss"],
})
export class TimeRenderingComponent implements OnChanges {
  timeSegmentList: Array<{ value: string; viewValue: string }> = [
    { value: "oneMonth", viewValue: "1 month" },
    { value: "oneYear", viewValue: "1 Year" },
    { value: "tenYear", viewValue: "10 Year" },
    { value: "twentyYear", viewValue: "20 Year" },
  ];

  @Input() selected: string;

  @Output() selectionChange: EventEmitter<TimeFrameEnum> =
    new EventEmitter<TimeFrameEnum>();

  updateChart(value: string): void {
    let enumValue: TimeFrameEnum;

    switch (value) {
      case "oneMonth":
        enumValue = TimeFrameEnum.OneMonth;
        break;
      case "oneYear":
        enumValue = TimeFrameEnum.OneYear;
        break;
      case "tenYear":
        enumValue = TimeFrameEnum.TenYear;
        break;
      case "twentyYear":
        enumValue = TimeFrameEnum.TwentyYear;
        break;
      default:
        throw new Error("Invalid time segment");
    }

    this.selectionChange.emit(enumValue);
  }

  ngOnChanges(changes: SimpleChanges): void {}
}
