import { NgModule } from "@angular/core";
import { MediaMatcher, BreakpointObserver } from "@angular/cdk/layout";
import { NotificationCenterService } from "./notification-center/notification-center.service";
import { HomeOwnerDashboardImports } from "./homeowner-dashboard.imports";
import { HomeOwnerDashboardDeclaration } from "./homeowner-dashboard.declarations";
import { HomeOwnerDashboardComponent } from "./homeowner-dashboard.component";
import { LearnMoreUtils } from "./learn-more/learn-more.common.util";
import { LearnMoreService } from "./learn-more/learn-more.service";
import { MarketPlaceService } from "./market-place/market-place.service";
import { Utils } from "../utils/util";

@NgModule({
  imports: [HomeOwnerDashboardImports],
  declarations: [HomeOwnerDashboardDeclaration],
  exports: [HomeOwnerDashboardComponent],
  providers: [
    BreakpointObserver,
    MediaMatcher,
    NotificationCenterService,
    LearnMoreUtils,
    LearnMoreService,
    MarketPlaceService,
    Utils,
  ],
})
export class HomeOwnerDashboardModule {}
