import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LifestyleService } from "../../../rest-api/lifestyle.service";
import { BatterySettingModel } from "../energy-choices.model";

@Component({
  selector: "yt-battery",
  templateUrl: "battery.component.html",
  styleUrls: ["../energy-choices-common.scss"],
})
export class BatteryComponent implements OnInit {
  panelOpenState: boolean = false;
  batteryForm: FormGroup;
  bbForm: FormGroup;
  disableSave: boolean;
  updateMode: boolean;
  homeOwnerEmail: string;
  batteryData: BatterySettingModel[];
  @Output() saveBATTERY = new EventEmitter<any>();
  disableDatePicker: boolean;
  buildType: boolean;
  batteryInfo: HTMLElement;

  constructor(
    private fb: FormBuilder,
    private lifeStyleService: LifestyleService
  ) {}

  ngOnInit() {
    this.batteryForm = this.fb.group({
      own: ["yesPlan", Validators.required],
      hrs: [
        "10",
        [
          Validators.required,
          Validators.pattern(/^(?:[1-9]|[1-2][0-9]|3[0-5])$/),
          Validators.min(1),
          Validators.max(35),
        ],
      ],
      solar: ["no", Validators.required],
    });
    this.bbForm = this.fb.group({
      own: ["yesPlan", Validators.required],
      hrs: [
        "10",
        [
          Validators.required,
          Validators.pattern(/^(?:[1-9]|[1-2][0-9]|3[0-5])$/),
          Validators.min(0),
          Validators.max(35),
        ],
      ],
      solar: ["no", Validators.required],
    });
    if (this.batteryForm.get("own")?.value === "yes") {
      this.batteryForm.get("hrs")?.disable();
    }
    if (this.lifeStyleService.getBatteryInfo() !== undefined) {
      this.batteryData = this.lifeStyleService.getBatteryInfo();
      this.checkInUpdateView(this.batteryData[0]);
    }
    this.checkRenterCondo();
  }

  checkRenterCondo(): void {
    this.batteryInfo = document.querySelector(".battery-text")!;
    this.batteryInfo.innerHTML = `<strong>Battery</strong>, as an add-on to solar, is an effective way to provide electricity for crucial
      needs during a power outage, thereby ensuring peace of mind. The hours of backup you
      select will help define the capacity and cost of a battery system.`;
    this.buildType = this.lifeStyleService.isRenterCondoUser;
    if (this.buildType) {
      this.batteryInfo.innerHTML = `A <strong>portable battery</strong> is an effective way to provide electricity for crucial needs during a power
        outage, thereby ensuring peace of mind. The hours of backup you select will help define
        the capacity and cost of a battery system.`;
    }
  }

  condition() {
    if (this.batteryForm.get("own")?.value === "yesPlan") {
      this.batteryForm.enable();
    } else if (this.batteryForm.get("own")?.value === "yes") {
      this.batteryForm.get("hrs")?.disable();
    } else {
      this.batteryForm.get("hrs")?.disable();
    }
  }

  checkInUpdateView(data) {
    this.updateMode = true;
    this.batteryForm.controls["own"].setValue(data.own);
    if (data.hrs) {
      this.batteryForm.get("hrs")?.enable();
      this.batteryForm.controls["hrs"].setValue(data.hrs);
    } else {
      this.batteryForm.get("hrs")?.disable();
    }
    data.solar
      ? this.batteryForm.controls["solar"].setValue(data.solar)
      : this.batteryForm.get("solar")?.disable();
  }

  onSaveBATTERY() {
    this.bbForm.get("own")?.setValue(this.batteryForm.get("own")?.value);
    if (this.bbForm.get("own")?.value === "yesPlan") {
      this.bbForm.enable();
      this.bbForm.get("hrs")?.setValue(this.batteryForm.get("hrs")?.value);
      this.bbForm.get("solar")?.setValue(this.batteryForm.get("solar")?.value);
    } else {
      this.bbForm.get("solar")?.enable();
      this.bbForm.get("solar")?.setValue(this.batteryForm.get("solar")?.value);
      this.bbForm.get("hrs")?.disable();
    }
    const batteryInfo: {
      energyChoices: {
        battery: {
          own: string;
          solar: string;
          hrs?: string;
        }[];
      };
    } = {
      energyChoices: {
        battery: [],
      },
    };
    if (this.bbForm.get("own")?.value === "yes") {
      batteryInfo.energyChoices.battery[0] = {
        own: this.bbForm.get("own")?.value,
        solar: this.bbForm.get("solar")?.value,
      };
    } else if (this.bbForm.get("own")?.value === "yesPlan") {
      batteryInfo.energyChoices.battery[0] = {
        own: this.bbForm.get("own")?.value,
        hrs: this.bbForm.get("hrs")?.value,
        solar: this.bbForm.get("solar")?.value,
      };
    } else if (this.bbForm.get("own")?.value === "no") {
      batteryInfo.energyChoices.battery[0] = {
        own: this.bbForm.get("own")?.value,
        solar: this.bbForm.get("solar")?.value,
      };
    }
    this.lifeStyleService
      .onSaveLifestyleInfo(batteryInfo)
      .subscribe((response) => {
        if (response.status === 200) {
          this.lifeStyleService.postBatteryInfo(batteryInfo);
          this.saveBATTERY.emit();
        }
      });
  }
}
