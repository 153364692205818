<footer class="footer">
  <div class="poweredby padding-right desktop_links">
    <ng-container *ngIf="logo.customer && logo.customer.poweredByUrl">
      <a
        href="https://www.yellowtin.com"
        target="_blank"
        class="no-underline poweredby"
      >
        Powered by YellowTin
      </a>
      <div class="vertical-line"></div>
    </ng-container>
    <a
      href="https://www.yellowtin.com/privacy_policy/"
      target="_blank"
      class="no-underline poweredby"
    >
      Privacy Policy
    </a>
    <div class="vertical-line"></div>
    <a
      href="https://www.yellowtin.com/yt-ccpa-form-rtoo/"
      target="_blank"
      class="no-underline poweredby"
    >
      Do Not Sell My Info
    </a>
  </div>
  <div class="poweredby mobile_links">
    <div class="wrapper_mobile">
      <ng-container *ngIf="logo.customer && logo.customer.poweredByUrl">
        <a
          href="https://www.yellowtin.com"
          target="_blank"
          class="no-underline poweredby"
        >
          Powered by YellowTin
        </a>
      </ng-container>
      <a
        href="https://www.yellowtin.com/privacy_policy/"
        target="_blank"
        class="no-underline poweredby"
      >
        Privacy Policy
      </a>
      <a
        href="https://www.yellowtin.com/yt-ccpa-form-rtoo/"
        target="_blank"
        class="no-underline poweredby"
      >
        Do Not Sell My Info
      </a>
    </div>
  </div>
</footer>
