<div class="faq_container">
  <div class="about_technology" *ngIf="showAboutTech">
    <div class="header">Overview</div>
    <p>
      <strong>How it works:</strong> A rooftop photovoltaic (PV) system uses
      solar panels to convert sunlight to direct-current (DC) electricity. An
      inverter then converts from DC to alternating-current (AC) electricity –
      the type used in your home.
      <span
        class="link_text"
        *ngIf="showReadMore"
        (click)="onReadContent('readMore')"
      >
        Read more...
      </span>
    </p>
    <div *ngIf="showReadLess">
      <p class="pt3">
        <strong>Grid-Tied Systems:</strong> Most rooftop PV systems are tied to
        the electrical grid. This allows you to take electricity from the grid
        when you need extra (such as at night) and put electricity onto the grid
        when you generate more than you need. Generally, your local utility will
        provide a credit for the power you put on the grid.
      </p>

      <p class="pt3">
        <strong>Battery Storage:</strong> Battery systems can be installed along
        with solar as a backup power source during power outages. Also,
        depending on your electric rates, a battery can store cheaper solar
        power for later use to reduce the need for high-priced electricity from
        the grid. Given the high cost of battery storage, however, this storage
        of power for later use is generally not cost effective.
      </p>

      <p class="pt3">
        <strong>Economics:</strong> How much you’ll save by going solar depends
        on a variety of factors specific to your situation. For many, however,
        solar is now a cost-effective option, with systems paying back in less
        than 8 – 10 years — well under a system’s expected life of 25 years.
      </p>

      <p class="pt3">
        <strong>Environment:</strong> The environmental benefits of solar
        largely depend on the level of emissions associated with the
        grid-supplied power being displaced. The benefits are greatest where
        fossil fuels (such as coal, oil, and natural gas) provide a large
        percentage of the local power.
      </p>

      <p class="pt3">
        <strong>Maintenance:</strong> Very little routine maintenance is
        required. Rinsing or washing the solar panels a few times a year will
        remove dust/debris that can degrade the system’s efficiency. With the
        exception of certain types of inverters (which may require replacement
        after about 15 years), the components can be expected to last for the
        lifetime of the system.
        <span class="link_text" (click)="onReadContent('readLess')">
          Read less
        </span>
      </p>
    </div>
  </div>

  <div *ngIf="!showAboutTech">
    <div *ngIf="isActive(0)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How does a solar system work?</h5>
        <p class="answer_text">
          A solar electric system, also known as a photovoltaic (PV) system,
          uses solar panels to convert sunlight to electricity. The panels
          produce direct-current (DC) electricity, which must then be converted
          to alternating-current (AC) power — the type used in your home. An
          inverter (or a series of panel-mounted microinverters) is used to
          convert from DC to AC power. Almost all solar systems are “grid-tied”.
          When you produce more electricity than you use at any given time, most
          utilities allow you to put that electricity back onto the power grid
          and give you a credit for the power you provide.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. How can I tell if my house is a good fit for solar?
        </h5>
        <p class="answer_text">
          A home with an uncluttered south-facing roof and little or no shade is
          ideal. Somewhat shaded roofs and east- and west-facing roofs also are
          viable, but have a lower annual output for the same size solar system.
          A qualified solar installer can perform an analysis of your roof and
          suggest your best options.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          3. Does having solar on my roof mean I am “off the grid” and not
          connected to my local power company?
        </h5>
        <p class="answer_text">
          No. Having solar on your roof does not generally mean you are “off the
          grid”. Almost all solar systems are “grid-tied” systems, allowing a
          homeowner to use the electrical grid when needed. For example, you can
          often put excess electricity onto the grid during the day when the
          sun’s shining and draw power back from the grid at night.
        </p>
        <p class="pt3 answer_text">
          Battery backup systems can be installed along with solar as a way for
          homeowners to deal with temporary power outages. Although it adds to
          the cost of the system, batteries can provide power to critical
          appliances such as refrigerators and computers. Battery systems can
          even be sized large enough to power all of a house’s electrical loads
          for multiple days, but that’s generally cost prohibitive for the
          majority of homeowners.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          4. Do solar systems work in a power outage?
        </h5>
        <p class="answer_text">
          If your grid-connected solar system doesn’t have battery storage, your
          system will shut off in the event of an outage. This is to prevent
          emergency responders and electric utility personnel from being injured
          by electricity that is being fed back into the grid from solar
          systems. However, if you don’t have a battery, some types of inverters
          allow you to manually disconnect your system from the grid and access
          solar power during an outage, as long as the sun is shining.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          5. What happens at night or on cloudy days? Where does my electricity
          come from?
        </h5>
        <p class="answer_text">
          At night and on very cloudy days, a solar system goes dormant. During
          these times you will get power from the grid.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">6. Will my roof leak?</h5>
        <p class="answer_text">
          Solar mounting systems rely on the same leak-resistant technologies
          and installation principles as other roof features such as vents and
          exhaust fans. Ask your solar installer how your system will be mounted
          and how any roof penetrations will be sealed.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          7. Does my roofing material impact my ability to get solar?
        </h5>
        <p class="answer_text">
          The solar industry has created mounting methods and equipment for most
          of the commonly used roofing materials. Some types of materials (such
          as composition shingles) are easier to install solar on, and others
          (such as clay tiles or wood shingles) present more of a challenge and
          may involve a higher installation cost.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          8. Can I have solar panels mounted on the ground instead of on my
          roof?
        </h5>
        <p class="answer_text">
          Yes, some solar installers offer this service, but it’s generally more
          costly and complex.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          9. Will solar installers provide small systems?
        </h5>
        <p class="answer_text">
          Some solar installers will provide very small systems (even under 1
          kW). In general, however, smaller systems are less economic. While
          they have a lower overall cost, they have a higher per-kW cost.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          10. What happens to the solar hardware when I sell my home?
        </h5>
        <p class="answer_text">
          Because they are designed for a specific location, solar systems
          almost always stay with the home on which they are initially
          installed. The cost to remove and reinstall a system on a different
          home is very high. Solar homes typically have a higher resale value
          because they have lower monthly energy costs.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          11. Can I heat my pool with my solar electric system?
        </h5>
        <p class="answer_text">
          While an electrically driven heat pump (powered by solar) can be used
          for pool heating, this technology is not widely used for this purpose.
          The solar technology normally used to heat a pool is called solar
          thermal heating which warms the water directly in solar thermal panels
          facing the sun. This approach is different from solar photovoltaic
          technology which generates electricity.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          12. Can I provide hot water (for showers, etc.) with my solar electric
          panels?
        </h5>
        <p class="answer_text">
          Two key solar technologies are available for providing hot water for a
          home. Solar thermal (not solar photovoltaic) heating involves running
          a fluid through a particular type of solar thermal panel. This is a
          more-direct way to use solar for water heating, but is generally
          expensive and prone to leakage and other maintenance problems. The
          other approach involves producing electricity in solar electric panels
          and then using this electricity for water heating. You can use a
          highly efficient heat pump water heater (HPWH) or a conventional
          electric water heater.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(1)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How much do solar systems cost?</h5>
        <p class="answer_text">
          System costs vary by size and technology. A typical roof-mounted
          system normally ranges between $2 to $4 per Watt. For a 4 kilowatt (4
          kW, or 4,000 Watt) solar system, costs generally range between $8,000
          and $16,000 before the federal tax credit. Through the end of 2022,
          the 26% tax credit is available for residential solar systems, but
          this is currently scheduled to drop to 22% in 2023, and to zero in
          2024. Smaller systems generally cost more per Watt than a larger
          system. In some cases, homeowners going solar don’t pay for the entire
          system up front. Instead, they take out a loan, or participate in a
          lease or Power Purchase Agreement (PPA) program offered by a solar
          installer. If you can afford it, the economics of the direct-purchase
          approach are generally the most favorable.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">2. What is Net Energy Metering (NEM)?</h5>
        <p class="answer_text">
          Net Energy Metering (NEM), which is used by many utilities around the
          country, measures the amount of excess electricity put onto the grid
          by your solar system as well as the amount of electricity you take
          from the grid. The utility keeps track of this for you and informs you
          each month about your energy balance. Sometimes customers with solar
          do not pay a monthly electric bill (other than a monthly charge), but
          annually receive a ‘true up’ bill that accounts for their net
          generation or net usage. If the true-up indicates net generation
          during the year, the homeowner is compensated either by check or by
          rolling over a credit toward future charges. If the true-up indicated
          net usage, the homeowner pays the utility for the net electricity
          usage for the year.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          3. What is my up-front cost? What costs might I face later on related
          to the solar system?
        </h5>
        <p class="answer_text">
          Costs vary greatly depending on whether you purchase the system, take
          out a loan, or sign a lease or Power Purchase Agreement (PPA). If you
          purchase the system, you’ll pay the cost of the system up front and
          also be responsible for any future maintenance. With a loan, you’ll
          generally pay little or nothing up front for the system, pay back the
          loan with a monthly payment, and be responsible for any maintenance.
          With a lease or PPA, you don’t own the system. Typically, you pay
          little or nothing up front and have no responsibility for maintenance,
          but you’ll pay a fixed recurring monthly fee for electricity. If you
          can afford it, the economics of the direct-purchase approach are
          generally the most favorable.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">4. Who benefits most from solar power?</h5>
        <p class="answer_text">
          The most cost-effective installations are in homes with larger
          electric bills and unshaded roof space facing generally south. But
          with prices rapidly coming down, many people with smaller bills and
          less-than-optimal roof orientations also find the economics of solar
          to be compelling. Homeowners sometimes install solar as a way to
          benefit the environment, rather than for pure economic reasons.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          5. How does solar impact my property value?
        </h5>
        <p class="answer_text">
          Studies have shown that homes with solar energy systems sell for 4 –
          5% more than homes without them. However, your property value will
          likely increase more if you own, rather than use a lease or a Power
          Purchase Agreemesnt for your solar system.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          6. Will having a solar system help the sale of my home?
        </h5>
        <p class="answer_text">
          It will depend on how you acquired the solar system and the age of
          your system. The value of your home is likely to increase if the solar
          system is free of encumbrances, like a lease or a PPA. With a lease or
          PPA, the details would need to be worked out with your solar provider
          to transfer the lease or PPA obligations to the new homeowner.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          7. What is the likely payback period (in years) for my solar
          investment?
        </h5>
        <p class="answer_text">
          Payback time is determined by many factors, most importantly the
          amount of your current electricity bill and the up-front cost of your
          system. Customers who directly purchase the system and have large
          bills may see a return on their investment in as little as 3 to 5
          years, while those with lower bills will generally have longer payback
          periods. Those in regions with higher electricity rates will have
          shorter payback periods.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          8. What is the difference between secured solar loans and unsecured
          solar loans?
        </h5>
        <p class="answer_text">
          The primary difference between secured and unsecured solar loans is
          that secured solar loans require that you promise an asset, usually
          your home, as collateral for the money that you borrow. Unsecured
          solar loans do not, but their interest rates are generally higher to
          compensate for the increased risk taken on by the lender.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          9. How long is a typical lease/Power Purchase Agreement (PPA)
          contract?
        </h5>
        <p class="answer_text">
          Lease and PPA contract periods can vary, but a 20-year contract is
          typical.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          10. What happens at the end of my contract if I’m in a lease or Power
          Purchase Agreement (PPA)?
        </h5>
        <p class="answer_text">
          At the end of your contract, you’ll need to work with your solar
          provider to determine if you’ll keep the solar system on your home
          (there may be a cost associated with this) or have it removed. Your
          options should be written into your initial contract, and it’s always
          good to review such contract language carefully before signing an
          agreement.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          11. What if I have a solar lease or a solar Power Purchase Agreement
          (PPA) and I want to sell my home? What do I do about the contract?
        </h5>
        <p class="answer_text">
          Typically the contract is sold to the new home buyer, but sometimes
          there are complications. The options you have in this situation should
          be outlined in your initial contract with the solar installer, and
          it’s always good to carefully review such contract language before
          signing an agreement.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          12. After getting solar, what is the fee for removal & replacement of
          the solar panels if I need to reroof?
        </h5>
        <p class="answer_text">
          If your roof needs to be replaced during the life of your solar
          system, a professional will need to uninstall and reinstall the part
          of the system that is on the roof. The cost for this service typically
          ranges from about $500/kW to $800/kW of solar capacity.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          13. Do I need to get insurance when I buy a rooftop solar system?
        </h5>
        <p class="answer_text">
          Often your standard homeowner’s policy will cover the solar equipment
          installed as part of your system. In some cases, a rider may be
          required on your homeowner’s policy. It’s best to check with your
          insurance carrier.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(2)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. Are solar electric systems reliable?</h5>
        <p class="answer_text">
          Yes. Solar systems consist of various components, but the key ones are
          the solar panels and the inverter(s). While the energy generated by a
          solar panel decreases a small amount each year, they are guaranteed to
          operate for 25 years. The reliability of an inverter depends on the
          type. Microinverters are quite reliable and can be expected to last
          for 25 years. Central inverters, however, are less reliable and can be
          expected to require replacement before the end of the 25-year expected
          life of a solar system.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. How long are solar inverters guaranteed for?
        </h5>
        <p class="answer_text">
          Central inverters (also called string inverters) generally come with a
          10- to 12-year warranty, but many manufacturers offer extensions to 20
          years for an extra fee. Microinverters generally come with a 25-year
          warranty.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          3. What maintenance do I need to do on my solar panels?
        </h5>
        <p class="answer_text">
          Rinsing or washing the panels a few times a year will help keep the
          system operating at its best. Washing can be done with a garden hose
          and should be done early in the morning before the panels get hot.
          There is a very small chance that spraying cold water on hot solar
          panels can cause damage.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          4. Who is responsible for repairs, and what happens if the system
          breaks down?
        </h5>
        <p class="answer_text">
          This depends on how you choose to install your system. Most Power
          Purchase Agreements (PPAs) and lease programs include the maintenance
          costs for the life of the contract. So if there is an issue, you call
          your provider. If you directly purchase the equipment or take out a
          loan for it, you (as the owner) have the responsibility.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          5. If a lease or Power Purchase Agreement (PPA) is 20 years long, will
          the equipment last that long?
        </h5>
        <p class="answer_text">
          Most Power Purchase Agreements (PPAs) and lease programs cover
          maintenance (caused by equipment malfunction) for the life of the
          agreement. In these cases, the equipment will be maintained at no cost
          to the homeowner, but check the specifics contained in any agreement.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(3)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. What size solar energy system should I get?
        </h5>
        <p class="answer_text">
          The best size for your solar energy system will depend on a variety of
          factors, including how much electricity you use on an annual basis,
          the climate where you live, the amount of shade that falls on your
          roof, the electricity rate plan you’re on with your utility, etc. Your
          YellowTin Dashboard will provide a rough estimate of an appropriate
          system size, but it’s best to obtain estimates from several solar
          installers who can perform more-detailed analyses.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. If I have an old roof, do I need to replace it before installing
          solar?
        </h5>
        <p class="answer_text">
          If your roof has less than 15 years of life left, you should seriously
          consider re roofing prior to installing solar. This is because it can
          be costly (approximately $500/kW to $800/kW) to remove and reinstall
          the system’s panels and roof mounting hardware if you need to reroof
          after going solar. A roofer or solar professional can help you
          determine the amount of life left on your roof. Solar installers can
          generally coordinate with your roofer if you wish to have solar
          installed at the same time as you reroof.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">3. How long is the install time?</h5>
        <p class="answer_text">
          An actual solar installation is typically completed within a day or
          two. An inspection by the city is then typically scheduled within a
          week after the installation. After passing inspection, a
          permission-to-operate (PTO) certification is generally provided by the
          utility. The best installers often have a backlog, which can result in
          wait times when scheduling the install. It’s best to plan ahead, and
          this is especially true at times when a tax credit is
          dropping/expiring since many homeowners will be trying to install
          systems prior to the deadline.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          4. What about my homeowners’ association (HOA)? Do I need approval?
        </h5>
        <p class="answer_text">
          If you’re part of an HOA, approval is typically required before a
          solar system is installed. You should check with your HOA on its solar
          policy.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(4)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. What types of incentives are available for rooftop solar?
        </h5>
        <p class="answer_text">
          Predominant among these is the 30%
          <a
            href="https://www.consumerreports.org/home-garden/alternative-energy/how-the-residential-clean-energy-solar-tax-credit-works-a1771685058/"
            target="_blank"
            class="link_text"
            >federal tax credit</a
          >. In some cases other incentives are also available at the state,
          local, and utility levels. Taken together, incentives can take
          thousands of dollars off the price of a solar system, making solar
          much more affordable.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. How does the solar federal tax credit for my home work?
        </h5>
        <p class="answer_text">
          The tax credit is currently equal to 30% of the installed cost of a
          solar system and is generally available for someone’s principal
          residence. On 1/1/33 the credit is scheduled to drop to 26%, on
          1/1/34, it will drop to 22%, and on 1/1/35 it will drop to zero. Since
          it’s a tax credit, homeowners can take the amount of the credit (up to
          their tax liability) directly off their tax payments, rather than as a
          deduction from their taxable income. Note that this tax credit may not
          be of much use if you pay little or no federal income taxes. It’s
          always wise to consult with a tax advisor.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          3. What are the filing requirements for the federal solar tax credit?
        </h5>
        <p class="answer_text">
          To claim the credit, you must file IRS Form 5695 as part of your tax
          return. You calculate the credit on the form and then enter the result
          on your 1040. If you end up with a bigger credit than you have income
          tax due, you can’t use the credit to get money back from the IRS in
          that year. Instead, you can generally carry the credit over to the
          following tax year(s). However, you can’t carry unused credits into
          any year following the year the credit expires. Please be sure to
          consult your tax advisor.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          4. Can I claim the federal tax credit for a rental property?
        </h5>
        <p class="answer_text">
          You can’t claim a credit for installing solar power at rental
          properties you own, unless you also live in the house for part of the
          year and use it as a rental when you’re away. In general, you’ll have
          to reduce the credit to reflect the time you’re not there. Please be
          sure to consult your tax advisor.
        </p>
      </div>
    </div>
  </div>
</div>
