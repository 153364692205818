<yt-popup-loader *ngIf="loader"></yt-popup-loader>
<div *ngIf="!loader" class="w-100 h-100 dialog-container yt__help_me_choose">
  <div class="w-100">
    <h1 class="header ma0">Choose your {{ title }}</h1>
    <div class="flex-dropdown">
      <div class="filterBy_group">
        <div class="filerBy_text nowrap">Filter by</div>
        <mat-form-field
          floatLabel="auto"
          class="filter_by"
          appearance="outline"
        >
          <mat-select
            placeholder="Show All"
            [(value)]="filterBy"
            (selectionChange)="onFilterChange($event.value)"
          >
            <mat-option
              *ngFor="let filter of filterGroup"
              [value]="filter.value"
              >{{ filter.viewValue }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="w-50-l w-100-m w-100 flex-sortBy">
        <div class="yt_sort-by nowrap">Sort by</div>
        <mat-form-field
          floatLabel="auto"
          class="sort_by yt_text_overflow"
          appearance="outline"
        >
          <mat-select
            placeholder="Alphabetical"
            [(value)]="sortBy"
            (selectionChange)="onSortChange($event.value)"
          >
            <mat-option *ngFor="let sort of sortByList" [value]="sort.value">
              <span *ngIf="sort.value !== 'alpha'">{{ sortByMsg }}</span>
              <span> {{ sort.viewValue }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="noTechMessage_text" *ngIf="makeAndModelsList.length === 0">
    No results to display.
  </div>
  <div class="list-container">
    <ng-container *ngFor="let model of makeAndModelsList" mat-dialog-actions>
      <button
        *ngIf="!isEV"
        mat-dialog-close="{{ model.mnf }} {{ model.model }}"
        class="model-button"
        cdkFocusInitial
      >
        <div class="model-name">{{ model.mnf }}</div>
        <div class="model-type">{{ model.model }}</div>
        <div class="model-incentives">${{ model.netCost | number }}</div>
        <div class="model-incentives__label">Net cost</div>
        <div *ngIf="isHPWH" class="model-row">
          <span>Capacity</span>
          <span>{{ model.capacity }} gallons</span>
        </div>
        <div *ngIf="isEVCharger" class="model-row">
          <span>Charging Power</span>
          <span>{{ model.chargingPower }}</span>
        </div>
        <div *ngIf="isEE" class="model-row">
          <span>Warranty</span>
          <span>{{ model.warranty }}</span>
        </div>
        <div *ngIf="isIC" class="model-row">
          <span>Type</span>
          <span>{{ model.type }}</span>
        </div>
        <div class="model-row">
          <span>Upfront cost</span>
          <span>${{ model.upfrontCost }}</span>
        </div>
      </button>

      <button
        *ngIf="isEV"
        mat-dialog-close="{{ model.modelMake }} {{ model.modelType }}"
        class="model-button"
        cdkFocusInitial
      >
        <div class="model-name">{{ model.modelMake }}</div>
        <div class="model-type">{{ model.modelType }}</div>
        <div class="model-incentives">
          ${{ model.afterIncentives | number }}
        </div>
        <div class="model-incentives__label">Price after incentives</div>
        <div class="model-row">
          <span>Electric Range</span>
          <span>{{ model.drivingRange }} miles</span>
        </div>
        <div class="model-row">
          <span>Price</span>
          <span>${{ model.msrp | number }}</span>
        </div>
      </button>
    </ng-container>
  </div>
  <div class="disclaimer-text" *ngIf="isEE">
    A net cost of $0 indicates that incentives will cover the entire upfront
    cost.
  </div>
</div>
