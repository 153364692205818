<div
  class="compare"
  [ngClass]="{ battery_width: battery }"
  #compareFinancialOverlay
>
  <div class="financial">
    <div class="bg-white" [formGroup]="matRadioForm">
      <mat-radio-group formControlName="finance" (change)="onChange()">
        <div class="yt_heading" *ngIf="ev">EV Financing Options</div>
        <div class="yt_heading" *ngIf="battery">Battery Financing Options</div>
        <div class="yt_heading" *ngIf="solar">Solar Financing Options</div>
        <div class="cards" [ngClass]="{ 'battery-cards': battery }">
          <label>
            <div
              class="yt_border card-hover"
              [ngClass]="{ 'border-color': cash }"
            >
              <label for="select">
                <div>
                  <mat-radio-button
                    class="hidden_button absolute top-0"
                    value="cash"
                  >
                  </mat-radio-button>
                </div>
              </label>
              <div class="heading">Cash</div>
              <div class="financial-values">
                <div *ngIf="solar">
                  Price ${{ solarDetails.solarCost.compareScreen.cash.cost | number }}
                </div>
                <div *ngIf="ev">
                  Price ${{ evDetails.evCost.compareScreen.cash.cost | number }}
                </div>
                <div *ngIf="battery">
                  Price ${{ batteryDetails.batteryCost.compareScreen.cash.cost | number }}
                </div>
              </div>
              <div class="tc">
                <div class="monthly-payment" *ngIf="solar">
                  ${{ solarDetails.solarCost.compareScreen.cash.priceAfterIncentives | number }}
                </div>
                <div class="monthly-payment" *ngIf="ev">
                  ${{
                    evDetails.evCost.compareScreen.cash.priceAfterIncentives
                      | number
                  }}
                </div>
                <div class="monthly-payment" *ngIf="battery">
                  ${{ batteryDetails.batteryCost.compareScreen.cash.priceAfterIncentives | number }}
                </div>
                <div class="m-labels" *ngIf="ev">Price after incentives</div>
                <div
                  class="m-labels tooltip_text"
                  *ngIf="solar || battery"
                  matTooltip="Net cost = Upfront cost - Incentives"
                >
                  Net cost
                </div>
              </div>
              <div class="tc">
                <div class="down-payment" *ngIf="solar">
                  ${{ solarDetails.solarCost.compareScreen.cash.price | number }}
                </div>
                <div class="down-payment" *ngIf="ev">
                  ${{ evDetails.evCost.compareScreen.cash.price | number }}
                </div>
                <div class="down-payment" *ngIf="battery">
                  ${{ batteryDetails.batteryCost.compareScreen.cash.price | number }}
                </div>
                <div
                  class="d-labels tooltip_text"
                  *ngIf="ev"
                  matTooltip="Includes typical price (with sales tax) paid in your area for your selected vehicle, before consideration of any federal, state, or utility incentives."
                >
                  Price (cash)
                </div>
                <div
                  class="d-labels tooltip_text"
                  *ngIf="solar || battery"
                  matTooltip="Includes equipment, labor, permits, etc."
                >
                  Upfront cost
                </div>
              </div>
              <div class="tc">
                <div class="down-payment" *ngIf="solar">
                  ${{ solarDetails.solarCost.compareScreen.cash.incentives | number }}
                </div>
                <div class="down-payment" *ngIf="ev">
                  ${{ evDetails.evCost.compareScreen.cash.incentives | number }}
                </div>
                <div class="down-payment" *ngIf="battery">
                  ${{ batteryDetails.batteryCost.compareScreen.cash.incentives | number }}
                </div>
                <div
                  class="i-labels tooltip_text"
                  matTooltip="Available tax credits and rebates."
                >
                  Incentives
                </div>
              </div>
            </div>
          </label>

          <label>
            <div
              class="yt_border card-hover mt_mobile mt-tablet"
              [ngClass]="{ 'border-color': loan, 'margin-left': battery }"
            >
              <label for="select">
                <div>
                  <mat-radio-button
                    class="hidden_button absolute top-0"
                    value="loan"
                  >
                  </mat-radio-button>
                </div>
              </label>
              <div class="heading">Loan</div>
              <div class="financial-values">
                <div *ngIf="solar">
                  Price ${{ solarDetails.solarCost.compareScreen.loan.cost | number }} | Down pymt.
                  {{solarDetails.solarCost.compareScreen.loan.downPaymentPercent | number }}% | Term /
                  Rate {{ solarDetails.solarCost.compareScreen.loan.term | number }}yrs /
                  {{ solarDetails.solarCost.compareScreen.loan.rate | number }}%
                </div>
                <div *ngIf="ev">
                  Price ${{ evDetails.evCost.compareScreen.loan.cost | number }}
                  | Down pymt.
                  {{ evDetails.evCost.compareScreen.loan.downPaymentPercent | number }}%
                  | Term / Rate
                  {{ evDetails.evCost.compareScreen.loan.term | number }}yrs /
                  {{ evDetails.evCost.compareScreen.loan.rate | number }}%
                </div>
                <div *ngIf="battery">
                  Price ${{ batteryDetails.batteryCost.compareScreen.loan.cost | number}} | Down pymt.
                  {{ batteryDetails.batteryCost.compareScreen.loan.downPaymentPercent | number }}% | Term /
                  Rate {{ batteryDetails.batteryCost.compareScreen.loan.term | number }}yrs /
                  {{batteryDetails.batteryCost.compareScreen.loan.rate | number }}%
                </div>
              </div>
              <div class="tc">
                <div class="monthly-payment" *ngIf="solar">
                  ${{ solarDetails.solarCost.compareScreen.loan.monthlyPayment | number }}
                </div>
                <div class="monthly-payment" *ngIf="ev">
                  ${{
                    evDetails.evCost.compareScreen.loan.monthlyPayment | number
                  }}
                </div>
                <div class="monthly-payment" *ngIf="battery">
                  ${{ batteryDetails.batteryCost.compareScreen.loan.monthlyPayment | number }}
                </div>
                <div class="m-labels">Monthly payment</div>
              </div>
              <div class="tc">
                <div class="down-payment" *ngIf="solar">
                  ${{ solarDetails.solarCost.compareScreen.loan.downPayment | number }}
                </div>
                <div class="down-payment" *ngIf="ev">
                  ${{
                    evDetails.evCost.compareScreen.loan.downPayment | number
                  }}
                </div>
                <div class="down-payment" *ngIf="battery">
                  ${{ batteryDetails.batteryCost.compareScreen.loan.downPayment | number }}
                </div>
                <div class="d-labels">Down payment</div>
              </div>
              <div class="tc">
                <div class="down-payment" *ngIf="solar">
                  ${{ solarDetails.solarCost.compareScreen.loan.incentives | number }}
                </div>
                <div class="down-payment" *ngIf="ev">
                  ${{ evDetails.evCost.compareScreen.loan.incentives | number }}
                </div>
                <div class="down-payment" *ngIf="battery">
                  ${{ batteryDetails.batteryCost.compareScreen.loan.incentives | number }}
                </div>
                <div
                  class="i-labels tooltip_text"
                  matTooltip="Available tax credits and rebates."
                >
                  Incentives
                </div>
              </div>
            </div>
          </label>

          <label *ngIf="!battery">
            <div
              class="yt_border card-hover mt_mobile mt-desktop"
              [ngClass]="{ 'border-color': lease }"
            >
              <label for="select">
                <div>
                  <mat-radio-button
                    class="hidden_button absolute top-0"
                    value="lease"
                  >
                  </mat-radio-button>
                </div>
              </label>
              <div class="heading">Lease</div>
              <div class="financial-values">
                <div *ngIf="solar">
                  Price ${{ solarDetails.solarCost.compareScreen.lease.cost | number }} | Term
                  {{ solarDetails.solarCost.compareScreen.lease.term | number }}yrs
                </div>
                <div *ngIf="ev">
                  Price ${{
                    evDetails.evCost.compareScreen.lease?.cost | number
                  }}
                  | Down pymt.
                  {{
                    evDetails.evCost.compareScreen.lease?.downPaymentPercent | number
                  }}% | Term / Rate
                  {{ evDetails.evCost.compareScreen.lease?.term | number }}yrs /
                  {{ evDetails.evCost.compareScreen.lease?.rate | number }}%
                </div>
              </div>
              <div class="tc">
                <div class="monthly-payment" *ngIf="solar">
                  ${{ solarDetails.solarCost.compareScreen.lease.monthlyPayment | number }}
                </div>
                <div class="monthly-payment" *ngIf="ev">
                  ${{ evDetails.evCost.compareScreen.lease?.monthlyPayment | number }}
                </div>
                <div class="m-labels">Monthly payment</div>
              </div>
              <div class="tc">
                <div class="down-payment" *ngIf="solar">
                  ${{ solarDetails.solarCost.compareScreen.lease.downPayment | number }}
                </div>
                <div class="down-payment" *ngIf="ev">
                  ${{ evDetails.evCost.compareScreen.lease?.downPayment | number }}
                </div>
                <div class="d-labels">Down payment</div>
              </div>
              <div class="tc">
                <div class="down-payment" *ngIf="solar">
                  ${{ solarDetails.solarCost.compareScreen.lease.incentives | number }}
                </div>
                <div class="down-payment" *ngIf="ev">
                  ${{
                    evDetails.evCost.compareScreen.lease?.incentives | number
                  }}
                </div>
                <div class="i-labels" *ngIf="solar">Incentives</div>
                <div
                  class="i-labels tooltip_text"
                  *ngIf="ev"
                  matTooltip="Available tax credits and rebates."
                >
                  Incentives
                </div>
              </div>
            </div>
          </label>
        </div>
        <div class="save_button dialogBox">
          <button
            mat-raised-button
            color="accent"
            (click)="onSave()"
            class="fr"
          >
            SAVE
          </button>
        </div>
      </mat-radio-group>
    </div>
  </div>
</div>
