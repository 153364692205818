import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";

@Component({
  selector: "yt-scroll-link-toggle",
  templateUrl: "./scroll-link-toggle.component.html",
  styleUrls: ["./scroll-link-toggle.component.scss"],
})
export class LMNavigatorComponent implements OnInit {
  toggle: boolean = true;
  isCost: boolean = true;
  @Input() activeLink: string;
  @Input() isAboutTech: boolean;
  @Input() isSavings: boolean;
  @Input() isDescription: boolean;
  @Input() isSettings: boolean;
  @Input() isDetails: boolean;
  @Input() isAssumptions: boolean;
  @Input() isFaq: boolean;
  @Input() techParameter: string;
  @Output() activeValue = new EventEmitter<string>();
  scrollOffSet: number = 200;
  showScrollButton: boolean = false;
  tabHeaderElem: HTMLDivElement;
  constructor(private dashboardAPI: DashboardAPIService) {}

  ngOnInit(): void {}

  onClick(group) {
    this.activeValue.emit(group.value);
    if (this.toggle) {
      if (
        this.techParameter === "battery" ||
        this.techParameter === "portableBattery" ||
        this.techParameter === "eBike"
      ) {
        this.onDescription(group.value);
      } else {
        this.onScroll(group.value);
      }
      if (group.value === "faq")
        this.dashboardAPI.dashboardDetailsAnalytics(
          "moreInfo",
          this.techParameter
        );
    }
  }

  onDescription(id: string) {
    if (id === "description") {
      const desktopEl = document.getElementById("descriptionDesktop");
      const mobileEl = document.getElementById("descriptionMobile");
      if (desktopEl) desktopEl.scrollIntoView({ behavior: "smooth" });
      if (mobileEl) mobileEl.scrollIntoView({ behavior: "smooth" });
    } else if (id === "savings") {
      const desktopEl = document.getElementById("savingsDesktop");
      const mobileEl = document.getElementById("savingsMobile");
      if (desktopEl) desktopEl.scrollIntoView({ behavior: "smooth" });
      if (mobileEl) mobileEl.scrollIntoView({ behavior: "smooth" });
    } else {
      this.scrollInto(id);
    }
  }
  
  onScroll(id: string) {
      const el = document.querySelector(".highChart_card_mobile");
      if (el) el.scrollIntoView({ behavior: "smooth" });
      this.scrollInto(id);
  }

  scrollInto(id: string): void {
    const element = document.getElementById(id);
    if (element)
      element.scrollIntoView({
        behavior: "smooth",
      });
  }

  ngAfterViewChecked(): void {
    this.tabHeaderElem =
      document.querySelector<HTMLDivElement>(".button-header")!;
    this.onResize();
  }

  handleScroll(button: string): void {
    let left = this.tabHeaderElem.scrollLeft;
    if (button === "prev") {
      left -= this.scrollOffSet;
    } else if (button === "next") {
      left += this.scrollOffSet;
    }
    this.tabHeaderElem.scrollTo({ left, behavior: "smooth" });
  }

  onResize(): void {
    this.showScrollButton =
      this.tabHeaderElem.scrollWidth > this.tabHeaderElem.clientWidth;
  }
}
