<div class="yt__info-header yt_header-margin" *ngIf="header">
  {{ header }}
</div>
<mat-radio-group [formControl]="buldingType" class="relative">
  <div
    class="yt__w-46 dib yt__first-child-ml0 yt__last-child-mr0 yt__mr1-625rem"
    *ngFor="let type of buildingTypeDisplayModel"
  >
    <label>
      <yt-roof-shade-answer-box
        [image]="imagePath ? imagePath + type.value + '.svg' : ''"
        [displayValue]="type.viewValue!"
        [subDisplayValue]="type.subViewValue!"
        [selectedValue]="defaultValue === type.value"
      >
      </yt-roof-shade-answer-box>
      <mat-radio-button
        class="yt__visibility-hidden absolute top-0"
        [value]="type.value"
        color="accent"
        (change)="radioChange($event)"
      >
      </mat-radio-button>
    </label>
  </div>
</mat-radio-group>
