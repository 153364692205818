import { Component, OnInit, Input, ViewChild, OnChanges } from "@angular/core";
import {
  homeAppliancesModel,
  MarketPlaceDetailsModel,
  MarketPlaceDataModel,
} from "../market-place.model";
import { MarketPlaceService } from "../market-place.service";
import { MatRadioChange } from "@angular/material/radio";
import { PartnerDetailCardComponent } from "../../shared/marketplace/partnerdetail-card/partnerdetail-card.component";

@Component({
  selector: "yt-homeAppliances-tab",
  templateUrl: "homeAppliances-tab.component.html",
  styleUrls: ["../marketplace-tab.component.scss"],
})
export class HomeAppliancesComponent implements OnInit, OnChanges {
  homeAppliancesDetails: MarketPlaceDataModel[];
  technologyDetails: MarketPlaceDetailsModel;
  boxText: string;
  buttonText: string;
  defaultValue: string;
  defaultIndex: number;
  count: number = 0;
  @Input() activeTech: string;
  @Input() homeAppliances: homeAppliancesModel;
  @Input() homeAppliancesJSON: MarketPlaceDataModel[];
  @ViewChild(PartnerDetailCardComponent)
  partnerDetailCard: PartnerDetailCardComponent;

  constructor(private marketPlaceService: MarketPlaceService) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.homeAppliances) {
      this.homeAppliancesDetails = Object.keys(this.homeAppliances).map(
        (tech) =>
          this.homeAppliancesJSON.find(
            (appliances) => tech === appliances.value
          )!
      );
    }
    this.setDefaultValues(
      this.activeTech || this.homeAppliancesDetails[0].value
    );
  }

  setDefaultValues(value: string): void {
    this.defaultValue = value;
    this.defaultIndex = this.homeAppliancesDetails.findIndex(
      (tech) => tech.value === this.defaultValue
    );
    this.marketPlaceService.onTabChange("homeAppliances", this.defaultValue);
    //update child component only on radio button change
    if (this.count !== 0) {
      this.partnerDetailCard.modelSelected = "default";
    }
    this.count += 1;
  }

  onTechChange(event: MatRadioChange) {
    this.marketPlaceService.onTabChange("homeAppliances", event.value);
    this.setDefaultValues(event.value);
  }
}
