import { Injectable } from "@angular/core";
import {
  EnergyStatusModel,
  UtilityProviderModel,
} from "../../../../../../shared/energy-info/energy-info.model";

@Injectable()
export class EnergyInfoService {
  utilityProviderResponse: UtilityProviderModel;
  energyInfoStatusResponse: EnergyStatusModel;

  constructor() {}
}
