import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { userDataPutModel } from "./setting-overlay.component.model";
import { DropDownModel } from "../../../../rest-api/rest-api.model";
import { DashboardAPIService } from "../../../../rest-api/dashboard/dashboard-api.service";
import { OverviewService } from "../../overview.service";
import { Subject } from "rxjs";

@Injectable()
export class SettingsOverlayService {
  energyInfoStatus: boolean = false;
  settingsInfo: userDataPutModel = {};
  settingsInfoErrors: userDataPutModel = {};
  tabStatus: boolean = false;
  errorMessage: boolean;
  showSavingLoader = new Subject<boolean>();
  systemSizeList: DropDownModel[] = [
    { value: "2", viewValue: "Very Small (0-3 kW)" },
    { value: "5", viewValue: "Small (3-6 kW)" },
    { value: "7", viewValue: "Medium (6-8 kW)" },
    { value: "9", viewValue: "Large (8-10 kW)" },
    { value: "12", viewValue: "Very Large (10-15 kW)" },
    { value: "18", viewValue: "Extremely Large (>15 kW)" },
  ];

  constructor(
    private overviewService: OverviewService,
    private snackBar: MatSnackBar,
    private dashboardAPI: DashboardAPIService
  ) {}

  putSettingsInfo(closeOverlay?: () => void): void {
    this.validationMessage();
    if (!this.errorMessage && Object.keys(this.settingsInfo).length !== 0) {
      this.showSavingLoader.next(true);
      this.overviewService
        .updateUserSettingInfo(this.settingsInfo)
        .subscribe((data) => {
          if (data.status === 200) {
            this.snackBar.open("Changes Saved.", "OK", {
              duration: 8000,
            });
            this.overviewService.userSettingInfo.subscribe((response) => {
              this.showSavingLoader.next(false);
              this.dashboardAPI.setEnergyChoiceResponse(response);
            });
            this.settingsInfo = {};
            if (closeOverlay) closeOverlay();
          }
        });
    }
  }

  checkSettingsInfo(key: string): boolean {
    const requestString = JSON.stringify(this.settingsInfo);
    return requestString.includes(`"${key}"`);
  }

  checkSettingsInfoError(key: string): boolean {
    const requestString = JSON.stringify(this.settingsInfoErrors);
    return requestString.includes(`"${key}"`);
  }

  updateSettingsInfo(response, payload, key1: string, key2?: string): void {
    if (JSON.stringify(response) !== JSON.stringify(payload)) {
      if (key2) {
        this.settingsInfo[key1] = {
          ...this.settingsInfo[key1],
          [key2]: payload,
        };
        if (
          this.settingsInfoErrors[key1] &&
          this.settingsInfoErrors[key1][key2]
        ) {
          delete this.settingsInfoErrors[key1][key2];
        }
        if (
          this.settingsInfoErrors[key1] &&
          Object.keys(this.settingsInfoErrors[key1]).length === 0
        ) {
          delete this.settingsInfoErrors[key1];
        }
      } else {
        this.settingsInfo = { ...this.settingsInfo, [key1]: payload };
        if (this.settingsInfoErrors[key1]) {
          delete this.settingsInfoErrors[key1];
        }
      }
    } else {
      if (key2) {
        if (this.settingsInfo[key1][key2]) {
          delete this.settingsInfo[key1][key2];
        }
      } else {
        if (this.settingsInfo[key1]) {
          delete this.settingsInfo[key1];
        }
        if (this.settingsInfoErrors[key1]) {
          delete this.settingsInfoErrors[key1];
        }
      }
    }
  }

  updateSettingsInfoErrors(
    response,
    payload,
    key1: string,
    key2?: string
  ): void {
    if (JSON.stringify(response) !== JSON.stringify(payload)) {
      if (key2) {
        this.settingsInfoErrors[key1] = {
          ...this.settingsInfoErrors[key1],
          [key2]: payload,
        };
        if (this.settingsInfo[key1] && this.settingsInfo[key1][key2]) {
          delete this.settingsInfo[key1][key2];
        }
      } else {
        this.settingsInfoErrors = {
          ...this.settingsInfoErrors,
          [key1]: payload,
        };
        if (this.settingsInfo[key1]) {
          delete this.settingsInfo[key1];
        }
      }
    } else {
      if (key2) {
        if (this.settingsInfoErrors[key1][key2]) {
          delete this.settingsInfoErrors[key1][key2];
        }
      } else {
        if (this.settingsInfoErrors[key1]) {
          delete this.settingsInfoErrors[key1];
        }
      }
    }
  }

  validationMessage(): void {
    this.errorMessage = !!Object.entries(this.settingsInfoErrors).length;
    if (this.errorMessage) {
      this.snackBar.open(
        "Please fill all the required fields across the tabs to save settings.",
        "OK",
        {
          duration: 8000,
        }
      );
    }
  }
}
