<div>
  <mat-radio-group class="radioButton-flex">
    <div class="cards" *ngFor="let techList of displayTransportation">
      <label>
        <yt-techradio-boxes
          [cardImage]="techList.techImage"
          [viewValue]="techList.viewValue"
          [default]="defaultValue"
          [value]="techList.value"
        ></yt-techradio-boxes>
        <mat-radio-button
          class="hidden_button absolute top-0"
          [value]="techList.value"
          (change)="onTechChange($event)"
        >
        </mat-radio-button>
      </label>
    </div>
  </mat-radio-group>
</div>

<div>
  <yt-ev
    *ngIf="evList.includes(defaultValue)"
    [evDetails]="transportationTechDetails.ev"
    [activeIcon]="activeIcon"
    [techParameter]="activeTech"
    (techSave)="emitTech($event)"
    [makeAndModelList1]="makeAndModelList1"
    [makeAndModelList2]="makeAndModelList1"
  ></yt-ev>
</div>

<div>
  <yt-ebike
    *ngIf="defaultValue === 'eBike'"
    [activeIcon]="activeIcon"
    [eBikeDetails]="transportationTechDetails.eBike"
    (techSave)="emitTech($event)"
  ></yt-ebike>
</div>

<div>
  <yt-ev-chargers
    *ngIf="defaultValue === 'evChargers'"
    [evChargerDetails]="transportationTechDetails.evChargers"
    [activeIcon]="activeIcon"
    (techSave)="emitTech($event)"
  >
  </yt-ev-chargers>
</div>
