import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  HostListener,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import {
  IncentivesDetailsRequest,
  TechIncentivesModel,
  TechnologyIncentivesModel,
} from "../../../incentives/incentives.model";
import { IncentivesAPIService } from "../../../incentives/incentives.service";
import { RebateDetailsModel } from "../../learn-more/learn-more.model";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";

@Component({
  selector: "yt-breakdown-popup",
  templateUrl: "breakdown-popup.component.html",
  styleUrls: ["./breakdown-popup.component.scss"],
})
export class BreakdownPopupComponent implements OnInit, OnDestroy {
  showSpecialCriteria: boolean = false;
  showIncentiveBreakdown: boolean;
  totalValue: number;
  loader: boolean = true;
  incentiveDetails: IncentivesDetailsRequest[];
  rebateDetails: RebateDetailsModel[] = [];
  @Input() heading: string;
  @Input() netCost: string;
  @Input() techName: string;
  @Output() closeIncentiveBreakdown: EventEmitter<void> = new EventEmitter();
  @ViewChild("breakDownOverlay", { read: ElementRef }) modal: ElementRef;

  constructor(
    private sanitizer: DomSanitizer,
    private incentiveService: IncentivesAPIService,
    private dashboardAPI: DashboardAPIService
  ) {}

  @HostListener("document:keydown", ["$event"])
  onKeyDown(event): void {
    if (event.key == "Escape") {
      this.ngOnDestroy();
    }
  }
  @HostListener("document:click", ["$event"])
  onDocumentClick(event): void {
    if (this.modal.nativeElement) {
      if (event.target === this.modal.nativeElement) {
        this.ngOnDestroy();
      }
    }
  }

  getTrustedUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  ngOnInit(): void {
    this.showIncentiveBreakdown = true;
    this.dashboardAPI.dashboardDetailsAnalytics("incentives", this.techName);
    this.incentiveService
      .getDashboardIncentivesDetails(this.techName)
      .subscribe((data: TechnologyIncentivesModel) => {
        Object.entries(data.technologyIncentives).forEach(
          (tech: TechIncentivesModel[]) => {
            this.loader = false;
            this.rebateDetails = tech[1].rebateDetails;
            this.totalValue = tech[1].incentives;
            this.showSpecialCriteria = this.rebateDetails.some(
              (rebate: RebateDetailsModel) => rebate.specialCriteria === "Yes"
            );
          }
        );
      });
  }

  ngOnDestroy(): void {
    this.showIncentiveBreakdown = false;
    this.closeIncentiveBreakdown.emit();
  }
}
