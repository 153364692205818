<div>
  <mat-radio-group class="radioButton-flex" *ngIf="transportationDetail">
    <div class="cards" *ngFor="let techList of transportationDetail">
      <label>
        <yt-techradio-button
          [cardImage]="techList.techImage"
          [viewValue]="techList.viewValue"
          [default]="defaultValue"
          [value]="techList.value"
        ></yt-techradio-button>
        <mat-radio-button
          class="hidden_button absolute top-0"
          [value]="techList.value"
          (change)="onTechChange($event)"
        >
        </mat-radio-button>
      </label>
    </div>
  </mat-radio-group>
</div>

<div *ngIf="transportationDetail">
  <yt-techdetail-card
    [viewValue]="transportationDetail[defaultIndex].viewValue"
    [value]="transportationDetail[defaultIndex].value"
    [navigatingUrl]="transportationDetail[defaultIndex].routerLink!"
    [techName]="transportationDetail[defaultIndex].techName"
    [techParagraph]="transportationDetail[defaultIndex].techParagraph!"
    [marketPlaceLink]="transportationAndAccessories[defaultValue].guidanceLink"
  ></yt-techdetail-card>
</div>

<div *ngIf="transportationAndAccessories">
  <yt-partnerdetail-card
    [techCard]="transportationAndAccessories[defaultValue].installers"
    [defaultValue]="defaultValue"
  ></yt-partnerdetail-card>
</div>
