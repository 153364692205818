<p class="yt_manual-heading tc">Tell us your average monthly electric bill</p>
<p class="yt-manual-subheading tc">(based on the most recent 12 months)</p>
<div class="tc pt5 slider-container" [formGroup]="estimateForm">
  <span class="slider-value"> ${{ sliderModel.min }}</span>
  <mat-slider
    [min]="sliderModel.min"
    [max]="sliderModel.max"
    discrete
    tickInterval="3"
    [step]="sliderModel.step"
  >
    <input matSliderThumb formControlName="monthlyBill" [value]="sliderModel.default" />
  </mat-slider>
  <span class="slider-value"> ${{ sliderModel.max }}</span>
</div>

<div class="cf yt-mt-5">
  <div class="fl w-100 w-70-ns mb2 mb0-ns yt__tundora">
    <div class="btn-card f5 mr3-ns yt__open-sans bg-black-40 h3" hidden>
      <div class="yt__tundora pt2 pt3-ns white">
        Secure login details and information connected here.
      </div>
    </div>
  </div>
  <button
    *ngIf="!isDashboard"
    mat-raised-button
    [disabled]="isDisableButton"
    color="accent"
    id="loaderButton"
    class="fr"
    (click)="onSubmit()"
  >
    SUBMIT
  </button>
  <button
    *ngIf="isDashboard"
    mat-raised-button
    color="accent"
    class="fr"
    (click)="onSaveEstimate()"
  >
    SAVE
  </button>
</div>
