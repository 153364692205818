<div class="captcha-container {{ config.cssClass }}">
  <canvas id="captchaCanvas" width="316" height="80"></canvas>

  <div class="captcha-actions">
    <input type="text" [formControl]="captchaInput" />
    <button
      mat-raised-button
      color="accent"
      class="captchaCheck"
      (click)="checkCaptcha()"
    >
      Check
    </button>
    <mat-icon
      class="material-icons"
      class="captchaReload"
      (click)="createCaptcha()"
    >
      refresh
    </mat-icon>
  </div>
</div>
