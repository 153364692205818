<scroll-top></scroll-top>
<div class="page_container">
  <div class="yt_width">
    <div class="yt__info-header">Additional comments (optional)</div>
    <div class="yt__info-sub-header">
      Anything else we should know? Please let us know if you have any comments.
    </div>
    <textarea
      class="w-100 w-80-l w-80-m yt_br-10 yt_height-350 yt_fz-20 pa4"
      placeholder="For example, my trees have been trimmed recently and Google maps does not reflect this"
      [formControl]="customerComment"
    ></textarea>
    <article class="cf">
      <div class="w-100 tr mt5">
        <button
          mat-raised-button
          color="accent"
          class=""
          (click)="onContinue()"
        >
          CONTINUE
        </button>
      </div>
    </article>
  </div>
  <yt-footer class="footer_container"></yt-footer>
</div>
