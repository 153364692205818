import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ElectrificationAdviceCardComponent } from "./electrification-advice-card.component";
import { TranslateModule } from "@ngx-translate/core";
import { ExpertAdvicePopupModule } from "../../../shared/expert-popup";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  imports: [CommonModule, TranslateModule, ExpertAdvicePopupModule, MatButtonModule],
  declarations: [ElectrificationAdviceCardComponent],
  providers: [],
  exports: [ElectrificationAdviceCardComponent],
})
export class AdviceCardModule {}
