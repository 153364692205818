<scroll-top></scroll-top>
<div class="page-container yt__notification-setting">
  <yt-back [navigateTo]="'notification-center'"></yt-back>
  <div class="padding-class">
    <div class="yt_maxWidth">
      <div class="notification-box">
        <form [formGroup]="notificationForm">
          <div class="heading">Notification Settings</div>
          <div class="settings-text">
            <span class="settings-text">
              {{ vendorDesc }} may still send you important notifications about
              your account outside of your preferred notification settings.
            </span>
          </div>
          <div class="settings-text email-padding">Email me about...</div>
          <div class="formSection">
            <div class="incentives-box">
              <div class="sub-heading">Incentives</div>
              <mat-slide-toggle
                [checked]="notificationStatus"
                formControlName="incentivesEmailMe"
                (change)="onIncentivesValueChange()"
              ></mat-slide-toggle>
            </div>
            <div class="incentives-text">
              Notifications about incentives of your relevant clean energy
              choices.
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
