import { CleanChoiceCardsModel } from "../../../model/dashboard.init.model";

export class CleanChoiceCardsInitial {
  imagePath = "assets/lifestyle/clean-choices/details/";

  cleanChoiceCardsList: CleanChoiceCardsModel = {
    simpleLowCost: {
      otherSimpleLowCost: [
        {
          cardName: "smartLightingControls",
          img: `${this.imagePath}smartLightingControls.png`,
          header: "Smart Lighting Control",
          subHeader:
            "A smart lighting system allows you to control your lighting usage...",
        },
        {
          cardName: "hvacFilters",
          img: `${this.imagePath}hvacfilters.png`,
          header: "HVAC Filters",
          subHeader:
            "HVAC filters that aren't cleaned/replaced on a regular basis...",
        },
        {
          cardName: "lineDryingClothes",
          img: `${this.imagePath}dry-clothes.png`,
          header: "Line Drying Clothes",
          subHeader:
            "You may significantly lower your home’s carbon footprint by...",
        },
        {
          cardName: "waterHeaterTemperature",
          img: `${this.imagePath}waterHeater-temp.png`,
          header: "Water Heater Temperature",
          subHeader: "Lowering the temperature of your water heater is a...",
        },
        {
          cardName: "turningOffFaucet",
          img: `${this.imagePath}faucet.png`,
          header: "Turning off Faucet",
          subHeader:
            "Turn off the water while brushing your teeth or soaping your hands.",
        },
        {
          cardName: "wateringPlantsAndYard",
          img: `${this.imagePath}manually-watering.png`,
          header: "Watering Plants and Yard",
          subHeader:
            "Water in the early morning or late evening as water will evaporate...",
        },
      ],
    },
    transportation: {
      otherTransportation: [
        {
          cardName: "railwayTransit",
          img: `${this.imagePath}Railtransit.png`,
          header: "Railway Transit",
          subHeader:
            "Rail travel within or between cities is one of the most affordable...",
        },
        {
          cardName: "busTransit",
          img: `${this.imagePath}Bustransit.png`,
          header: "Bus Transit",
          subHeader:
            "Traveling by bus is the least expensive mode of transportation.",
        },
      ],
    },
    appliances: {
      energyEfficiencyAppliances: [
        {
          cardName: "hvacDuctMaintenance",
          img: `${this.imagePath}Duct-Maintenance.jpg`,
          header: "HVAC Duct Maintenance",
          subHeader: "Poorly insulated or leaky air duct in your HVAC...",
        },
        {
          cardName: "atticWeatherizationAndInsulation",
          img: `${this.imagePath}attic-weatherization.jpg`,
          header: "Attic Weatherization and Insulation",
          subHeader: "Weatherizing and insulating your attic can save you...",
        },
        {
          cardName: "floorsAndExteriorWallsInsulation",
          img: `${this.imagePath}floor-wall-insulation.jpg`,
          header: "Floor and Exterior Wall Insulation",
          subHeader: "Your home’s floor or exterior wall may require...",
        },
        {
          cardName: "doublePaneWindows",
          img: `${this.imagePath}Double-pane-windows.jpg`,
          header: "Double-pane Windows",
          subHeader:
            "Double-pane windows use two sheets of glass in a window...",
        },
      ],
      otherAppliances: [
        {
          cardName: "electricKettle",
          img: `${this.imagePath}ElectricKettle.png`,
          header: "Electric Kettle",
          subHeader: "Electric kettles offer a simple and convenient way...",
        },
        {
          cardName: "instantPot",
          img: `${this.imagePath}InstantPot.png`,
          header: "Instant Pot",
          subHeader:
            "Multi-cookers such as the Instant Pot®, save cooking time,...",
        },
        {
          cardName: "electricFireplace",
          img: `${this.imagePath}ElectricFireplace.png`,
          header: "Electric Fireplace",
          subHeader: "Electric fireplaces are available today in a variety...",
        },
        {
          cardName: "highEfficiencyClothesWasher",
          img: `${this.imagePath}efficiency-clothesWasher.png`,
          header: "High-efficiency Clothes Washer",
          subHeader: "Replacing your old clothes washer with a new...",
        },
        {
          cardName: "smartWaterMonitor",
          img: `${this.imagePath}smartWaterMonitor.png`,
          header: " Smart Water Monitor",
          subHeader:
            "Even small water leaks in your home can waste a huge amount of...",
        },
      ],
      yardAppliances: [
        {
          cardName: "electricLeafBlower",
          img: `${this.imagePath}leaf-blower.png`,
          header: "Electric Leaf Blower",
          subHeader:
            "Electric leaf blowers emit less noise, are lightweight...",
        },
        {
          cardName: "electricGrassTrimmer",
          img: `${this.imagePath}electric-trimmer.png`,
          header: "Electric Grass Trimmer",
          subHeader: "Electric grass trimmers offer plenty of power and are...",
        },
        {
          cardName: "electricLawnMower",
          img: `${this.imagePath}lawn-mower.png`,
          header: "Electric Lawn Mower",
          subHeader:
            "Electric lawn mowers are easy to operate, perform better,...",
        },
        {
          cardName: "irrigationSystem",
          img: `${this.imagePath}irrigation.png`,
          header: "Irrigation System",
          subHeader: "An efficient irrigation system can effectively...",
        },
      ],
    },
  };
}
