import {
  ACSettingModel,
  BatterySettingModel,
  CommunitySolarSettingModel,
  EBikeSettingModel,
  EnergyChoicesModel,
  EnergyChoiceTypes,
  EVSettingModel,
  HPDSettingModel,
  HPSCSettingModel,
  HPWHSettingModel,
  ICSettingModel,
  PoolSettingModel,
  SolarSettingModel,
  SpaSettingModel,
} from "../homeowner-product/energy-choices/energy-choices.model";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RestAPIService } from "./rest-api.service";
import { environment } from "../../environments/environment";

@Injectable()
export class LifestyleService {
  userProvidedEBikeInfo: EBikeSettingModel[];
  userProvidedBatteryInfo: BatterySettingModel[];
  userProvidedEVInfo: EVSettingModel[];
  userProvidedHPDInfo: HPDSettingModel[];
  userProvidedSpaInfo: SpaSettingModel[];
  userProvidedPoolInfo: PoolSettingModel[];
  userProvidedICInfo: ICSettingModel[];
  userProvidedACInfo: ACSettingModel[];
  userProvidedHPWHInfo: HPWHSettingModel[];
  userProvidedHPSCInfo: HPSCSettingModel[];
  userProvidedSolarInfo: SolarSettingModel[];
  userProvidedCommunitySolarInfo: CommunitySolarSettingModel[];
  userProvidedSqFoot: string;
  lifestyleType: EnergyChoiceTypes;
  energyChoicesInfo: EnergyChoicesModel;
  peopleCount = "3";
  makeAndModel = [];
  buildingType: String;

  public homeOwnerEmail: string;
  public readonly API_END_POINT = environment.API_URL;
  public readonly HOME_OWNERS = "homeOwners/";
  public readonly updateLifestyleInfoUrl =
    this.API_END_POINT + "v3/" + this.HOME_OWNERS;

  constructor(
    private httpClient: HttpClient,
    private restAPIService: RestAPIService
  ) {}

  getEnergyChoicesInfo() {
    this.energyChoicesInfo = {
      solar: this.userProvidedSolarInfo,
      communitySolar: this.userProvidedCommunitySolarInfo,
      battery: this.userProvidedBatteryInfo,
      ev: this.userProvidedEVInfo,
      ac: this.userProvidedACInfo,
      hpd: this.userProvidedHPDInfo,
      spa: this.userProvidedSpaInfo,
      pool: this.userProvidedPoolInfo,
      ic: this.userProvidedICInfo,
      hpwh: this.userProvidedHPWHInfo,
      hpsc: this.userProvidedHPSCInfo,
      eBike: this.userProvidedEBikeInfo,
      sqFoot: this.userProvidedSqFoot,
      howManyPeople: this.peopleCount,
    };
    return this.energyChoicesInfo;
  }

  setPeopleCount(count) {
    this.peopleCount = count;
  }

  postSolarInfo(solarData) {
    let solarOwn = solarData.energyChoices.solar[0].own;
    solarData.energyChoices.solar[0].own =
      solarOwn === "addPanel" ? "yesPlan" : solarOwn;
    this.userProvidedSolarInfo = solarData.energyChoices.solar;
    const userInfo = solarData;
    return this.httpClient.put(
      this.updateLifestyleInfoUrl + this.restAPIService.homeOwnerEmail,
      userInfo,
      { observe: "response" }
    );
  }

  getUserProvidedSolarInfo() {
    return this.userProvidedSolarInfo;
  }

  postBatteryInfo(data) {
    this.userProvidedBatteryInfo = data.energyChoices.battery;
  }

  getBatteryInfo() {
    return this.userProvidedBatteryInfo;
  }

  getEnergyChoices() {
    return this.lifestyleType;
  }

  setEnergyChoices(types) {
    this.lifestyleType = types;
  }

  resetLifestyleData(): void {
    this.userProvidedSolarInfo = undefined as unknown as SolarSettingModel[];
    this.userProvidedBatteryInfo =
      undefined as unknown as BatterySettingModel[];
    this.userProvidedEVInfo = undefined as unknown as EVSettingModel[];
    this.userProvidedACInfo = undefined as unknown as ACSettingModel[];
    this.userProvidedHPWHInfo = undefined as unknown as HPWHSettingModel[];
    this.userProvidedHPSCInfo = undefined as unknown as HPSCSettingModel[];
    this.userProvidedICInfo = undefined as unknown as ICSettingModel[];
    this.userProvidedHPDInfo = undefined as unknown as HPDSettingModel[];
    this.userProvidedPoolInfo = undefined as unknown as PoolSettingModel[];
    this.userProvidedSpaInfo = undefined as unknown as SpaSettingModel[];
    this.lifestyleType = undefined as unknown as EnergyChoiceTypes;
    this.userProvidedEBikeInfo = undefined as unknown as EBikeSettingModel[];
  }

  postEVInfo(data) {
    this.userProvidedEVInfo = data.energyChoices.ev;
  }

  getEVInfo() {
    return this.userProvidedEVInfo;
  }

  postHPDInfo(data) {
    this.userProvidedHPDInfo = data.energyChoices.hpd;
  }

  getHPDInfo() {
    return this.userProvidedHPDInfo;
  }

  postSpaInfo(data) {
    this.userProvidedSpaInfo = data.energyChoices.spa;
  }

  getSpaInfo() {
    return this.userProvidedSpaInfo;
  }

  postPoolInfo(data) {
    this.userProvidedPoolInfo = data.energyChoices.pool;
  }

  getPoolInfo() {
    return this.userProvidedPoolInfo;
  }

  postICInfo(data) {
    this.userProvidedICInfo = data.energyChoices.ic;
  }

  getICInfo() {
    return this.userProvidedICInfo;
  }

  postHpwhInfo(data) {
    this.userProvidedHPWHInfo = data.energyChoices.hpwh;
  }

  getHpwhInfo() {
    return this.userProvidedHPWHInfo;
  }

  postHpscInfo(data) {
    this.userProvidedSqFoot = data.energyChoices.sqFoot;
    this.userProvidedHPSCInfo = data.energyChoices.hpsc;
  }

  getHpscInfo() {
    return this.userProvidedHPSCInfo;
  }

  postACInfo(data) {
    this.userProvidedACInfo = data.energyChoices.ac;
  }

  getACInfo() {
    return this.userProvidedACInfo;
  }

  postEBikeInfo(data) {
    this.userProvidedEBikeInfo = data.energyChoices.eBike;
  }

  getEBikeInfo() {
    return this.userProvidedEBikeInfo;
  }

  onSaveLifestyleInfo(lifestyleInfo) {
    return this.httpClient.put(
      this.updateLifestyleInfoUrl + this.restAPIService.homeOwnerEmail,
      lifestyleInfo,
      { observe: "response" }
    );
  }

  get isRenterCondoUser(): boolean {
    this.buildingType = this.restAPIService.buildingType;
    if (this.buildingType === "renter" || this.buildingType === "condominium") {
      const approach = "approach2";
      const vendorInfo = this.restAPIService.solarVendorInformation;
      const renterApproach = vendorInfo.renterApproach;
      const condoApproach = vendorInfo.condominiumApproach;
      if (renterApproach === approach || condoApproach === approach) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
