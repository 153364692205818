<div class="overlay_top_bg" id="msp_overlay">
  <div class="header_section">
    <mat-icon class="close_icon" (click)="onClose()">close</mat-icon>
    <div class="header">Main Service Panel</div>
  </div>
</div>
<div class="overlay_content">
  <div class="yt_text">
    The Main Service Panel resides just behind the electrical utility meter and
    is the place where the electrical utility service connects to the house. It
    hosts a series of circuit breakers or fuses that connect to various parts of
    the house. The whole panel has a maximum current rating like 100 Amperes or
    200 Amperes and this is the maximum amount of current that it can safely
    provide the whole house. Due to increased electrification in the house the
    main service panel can get overloaded and might need to be upgraded.
    <div class="img_section">
      <div class="img_box">
        <img
          src="assets/dashboard/overview/msp.png"
          alt="msp image"
          height="250px"
        />
      </div>
      <div class="cost_section">
        <div class="cost_header">Cost</div>
        <div class="main_text">$3,000 to $5,000</div>
      </div>
    </div>
    <div class="pv3">
      <span class="header_font">How it works:</span> The main service panel has
      a main service breaker that specifies the maximum amount of current that
      the whole house can draw at a time. This is based on the main service
      panel capacity rating and is typically 100-200 amperes. The breakers and
      fuses are safety devices that can be used to energize and de-energize
      parts of the house or a particular appliance.
    </div>
    <div class="pb3">
      <span class="header_font">Types of Main Service Panels:</span>
      Traditional panels are just electrical busbars with multiple slots for the
      breakers. However, some new “smart” service panels are available in the
      market now which let homeowners better understand and control their
      electricity usage on a circuit-by-circuit basis. Each panel just has a
      maximum current capacity rating.
    </div>
    <div class="pb3">
      <span class="header_font">Special Requirements:</span> Sometimes only
      upgrading the Main Service Panel may not be enough. The electrical service
      line that is coming into the house from the electric utility may not be
      able to handle the increased load of the house and may need to be
      upgraded. These types of upgrades are more costly and will require an
      appointment and service call with the electrical utility.
    </div>
    <div>
      <strong class="header_font">Economics:</strong> The panel upgrade needs to
      be done by a licensed electrician and will require a city inspection and
      permit. The total cost can vary a lot based on the complexity of the work
      and the local labor rates of the area, but usually it is between
      $3000-$5000.
    </div>
    <div class="pt3">
      <strong class="header_font">Maintenance:</strong> Usually Main Service
      Panels are guaranteed for life and do not require any maintenance.
      Sometimes the individual breakers can fail, at which point they have to be
      replaced. However, they are not expensive.
    </div>
  </div>

  <div class="header">Frequently Asked Questions</div>
  <mat-expansion-panel
    hideToggle="true"
    class="mt_24"
    (opened)="onPanelOpened(0)"
    (closed)="onPanelClosed(0)"
  >
    <mat-expansion-panel-header>
      <mat-panel-title class="display_center">
        Basics &nbsp;
        <mat-icon class="mat_color">
          {{
            !isPanelExpanded[0] ? "keyboard_arrow_down" : "keyboard_arrow_up"
          }}</mat-icon
        >
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="panel_body">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. What is the purpose of the Main Service Panel?
        </h5>
        <p class="answer_text">
          The Main Service Panel is where the electrical utility service
          connects to the house and hosts a series of circuit breakers or fuses
          that connect to various parts of the house. The breakers and fuses are
          safety devices that can be used to energize and de-energize parts of
          the house or a particular appliance. The whole panel has a maximum
          current rating like 100 Amperes or 200 Amperes and this is the maximum
          amount of current that it can safely provide the whole house.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. Why is it sometimes necessary to upgrade the Main Service Panel?
        </h5>
        <p class="answer_text">
          Usually in houses that were built before 1990 the Main Service Panel
          is rated to handle a maximum of 100 Amperes of current. As consumers
          start to electrify high power appliances in the house like the
          cooktop, dryer, water heater, etc., that did not run on electricity
          before, the amount of current drawn by the whole house increases and
          has the potential to exceed this 100 Ampere limit. This can lead to
          the panel getting overloaded which might trip the main service breaker
          or worse still result in an electrical fire. To mitigate this, steps
          need to be taken to either upgrade the Main Service Panel to one that
          can handle 200 Amperes or introduce power-sharing devices that can
          sometimes produce the same result at a lower cost.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          3. What is the difference between a Main Service Panel upgrade and a
          utility service upgrade?
        </h5>
        <p class="answer_text">
          Sometimes only upgrading the Main Service Panel may not be enough. The
          electrical service line that is coming into the house from the
          electric utility may not be able to handle the increased load of the
          house and may need to be upgraded. These types of upgrades are more
          costly and will require an appointment and service call with the
          electrical utility.
        </p>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel
    hideToggle="true"
    class="mt_24"
    (opened)="onPanelOpened(1)"
    (closed)="onPanelClosed(1)"
  >
    <mat-expansion-panel-header>
      <mat-panel-title class="display_center">
        Economics &nbsp;
        <mat-icon class="mat_color">
          {{
            !isPanelExpanded[1] ? "keyboard_arrow_down" : "keyboard_arrow_up"
          }}</mat-icon
        >
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="panel_body">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. How much does a Main Service Panel upgrade cost?
        </h5>
        <p class="answer_text">
          Usually the panel upgrade needs to be done by a licensed electrician
          and will require a city inspection and permit. The total cost can vary
          a lot based on the complexity of the work and the local labor rates of
          the area, but usually it is between $3000-$5000.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. How much does a utility service upgrade cost?
        </h5>
        <p class="answer_text">
          If the electrical line that is coming into the house from the
          electrical utility can handle the increased demand of the upgraded
          panel, then a service upgrade is unnecessary. However, if it is not
          the case, that line has to be replaced. Depending on whether the line
          is overhead or underground the costs can vary a lot and can be $10,000
          or more.
        </p>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel
    hideToggle="true"
    class="mt_24"
    (opened)="onPanelOpened(2)"
    (closed)="onPanelClosed(2)"
  >
    <mat-expansion-panel-header>
      <mat-panel-title class="display_center">
        Maintenance &nbsp;
        <mat-icon class="mat_color">
          {{
            !isPanelExpanded[2] ? "keyboard_arrow_down" : "keyboard_arrow_up"
          }}</mat-icon
        >
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="panel_body">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. What maintenance is needed for the upgraded panel?
        </h5>
        <p class="answer_text">
          Usually Main Service Panels are guaranteed for life and do not require
          any maintenance. Sometimes the individual breakers can fail, at which
          point they have to be replaced. However, they are not expensive.
        </p>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel
    hideToggle="true"
    class="mt_24"
    (opened)="onPanelOpened(3)"
    (closed)="onPanelClosed(3)"
  >
    <mat-expansion-panel-header>
      <mat-panel-title class="display_center">
        Steps to use &nbsp;
        <mat-icon class="mat_color">
          {{
            !isPanelExpanded[3] ? "keyboard_arrow_down" : "keyboard_arrow_up"
          }}</mat-icon
        >
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="panel_body">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. How long does it take to upgrade the panel?
        </h5>
        <p class="answer_text">
          Usually the process requires a city permit and a licensed electrician
          doing the upgrade. The whole process can take 2-3 days. After the
          upgrade is done, the process is signed off with a city inspection. If
          a service upgrade is required, then an appointment has to be made with
          the electrical utility and it is a more complicated process that can
          take months.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. What types of Main Service Panels are available?
        </h5>
        <p class="answer_text">
          Traditional panels are just electrical busbars with multiple slots for
          the breakers. However, some new service panels are available in the
          market now which let homeowners better understand and control their
          electricity usage on a circuit-by-circuit basis. One can even keep
          track of usage in real-time and control how they use energy from a
          smartphone or tablet. These new devices can also help you move toward
          an all-electric home, facilitating the integration of solar, battery
          storage, and other electrification upgrades (such as induction stoves,
          heat pumps, EV chargers, etc.).
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          3. Can a Main Service Panel upgrade be avoided somehow?
        </h5>
        <p class="answer_text">
          Under some circumstances, a service panel upgrade can be avoided or
          postponed by going on a “<a
            href="https://www.redwoodenergy.net/all-electric-retrofits"
            target="_blank"
            >Watt Diet</a
          >”, essentially keeping your power usage below the level that can be
          handled by your existing panel. The Watt Diet employs options such as
          power-sharing devices that allow the same electrical circuit to be
          shared among two high-powered appliances provided only one of them is
          being used at a particular time. For example, since an electric dryer
          is used infrequently, if it is situated close to the garage, the 240V
          electrical outlet of the dryer can be shared between the dryer and a
          Level 2 EV charger which will charge the car at times when the dryer
          is idle. There are products in the market like the
          <a
            href="https://www.bsaelectronics.com/collections/dryer-buddy-plus-auto"
            target="_blank"
            >Dryer Buddy</a
          >
          or a
          <a
            href="https://getneocharge.com/products/neocharge-smart-splitter"
            target="_blank"
            >Smart Splitter</a
          >
          that will help you do this. Since the appliances are not on
          simultaneously, the main panel is not overloaded. Usually this can be
          done in the initial phases of the electrification. For a whole-house
          upgrade, a Main Service Panel upgrade may be required eventually.
        </p>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel
    hideToggle="true"
    class="mt_24"
    (opened)="onPanelOpened(4)"
    (closed)="onPanelClosed(4)"
  >
    <mat-expansion-panel-header>
      <mat-panel-title class="display_center">
        Incentives &nbsp;
        <mat-icon class="mat_color">
          {{
            !isPanelExpanded[4] ? "keyboard_arrow_down" : "keyboard_arrow_up"
          }}</mat-icon
        >
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="panel_body">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. What types of incentives are available?</h5>
        <p class="answer_text">
          Utilities, states, counties, Community Choice Aggregators, etc.
          sometimes offer rebates on Main Service Panel Upgrade costs. In
          addition, a 30% federal tax credit is planned for service panel
          upgrades beginning in 2023.
        </p>
      </div>
    </div>
  </mat-expansion-panel>
  <div class="scroll_top" (click)="scrollTop()">
    <mat-icon>keyboard_arrow_up</mat-icon>
    <span>Back to top</span>
  </div>
</div>
