<scroll-top></scroll-top>
<div class="page_container">
  <div class="yt_width energy-changes">
    <div [formGroup]="upgradesForm">
      <div class="pt3 yt__info-header">Future % increase in energy usage</div>
      <div class="yt__info-sub-header">
        This information will be used to determine your total energy needs.
      </div>
      <div class="w-100 w-70-l yt_pt-1-5">
        <div class="yt__header">% increase in future energy usage</div>
        <div class="flex flex-nowrap">
          <div class="slider-value">0%</div>
          <mat-slider
            class="w-50 db"
            formControlName="futureEnergyUsage"
            min="0"
            max="100"
            discrete
            tickInterval="10"
            step="10"
          >
            <input matSliderThumb />
          </mat-slider>
          <div class="slider-value">100%</div>
        </div>
      </div>
      <div class="yt__header">Planning to add EV chargers?</div>
      <yt-toggle-button
        class="toggle_btn_padding"
        [buttons]="yesOrNo"
        [intialValue]="upgradesDetails.addEvCharges"
        (selectedButton)="onAddEvCharges($event)"
      ></yt-toggle-button>
      <div class="" *ngIf="displayNoOfEvCharges">
        <div class="yt__header">Number of EV chargers</div>
        <mat-form-field
          appearance="outline"
          floatLabel="auto"
          class="yt_border"
        >
          <input
            matInput
            class="quantity"
            type="number"
            formControlName="noOfEvCharges"
            placeholder="1"
          />
        </mat-form-field>
      </div>
    </div>
    <mat-error
      *ngIf="
        upgradesForm.controls['noOfEvCharges'].hasError('min') ||
        upgradesForm.controls['noOfEvCharges'].hasError('max')
      "
    >
      Please enter valid no of chargers (1-5)
    </mat-error>
    <button
      mat-raised-button
      color="accent"
      (click)="onContinue()"
      [disabled]="upgradesForm.invalid"
    >
      CONTINUE
    </button>
  </div>
  <yt-footer class="footer_container"></yt-footer>
</div>
