export enum TimeFrameEnum {
  TwentyYear = "twentyYear",
  TenYear = "tenYear",
  OneYear = "oneYear",
  OneMonth = "oneMonth",
}

export enum SavingsTypeEnum {
  DOLLAR = "dollar",
  CARBON = "carbon",
}
