import { RoofTypeAnswerBoxComponent } from "./roof-type-answer-box.component";
import { MatCardModule } from "@angular/material/card";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

@NgModule({
  imports: [CommonModule, MatCardModule],
  exports: [RoofTypeAnswerBoxComponent],
  declarations: [RoofTypeAnswerBoxComponent],
})
export class RoofTypeAnswerBoxModule {}
