<scroll-top></scroll-top>
<div class="page-container yt__lc_details">
  <yt-back [navigateTo]="'overview'"></yt-back>
  <div class="padding-back-button w-100 center">
    <div class="yt_maxWidth w-100 center" [formGroup]="efForm">
      <div class="header_text tc">Low-Cost Efficiency</div>
      <div class="yt-text">
        Small investments involving installing a smart thermostat, replacing
        incandescent lighting, using smart power strips, etc. can save you a lot
        over the long term. <strong>Low-cost energy efficiency</strong>
        steps also cut carbon emissions.
      </div>
      <div class="yt_width">
        <div class="header_box">
          LED /CFL BULBS
          <div class="textStyle">
            Cost<span class="yt_value">${{ ledBulbs }}</span>
          </div>
        </div>
        <div class="yt__width">
          <div class="image_box">
            <img class="image" src="assets/lifestyle/LED.svg" />
            <div
              *ngIf="
                !haveYltnMarketplace &&
                marketPlaceDetails_ledCflBulbs &&
                displayMarketPlaceBox_ledCflBulbs
              "
              class="padding_top"
            >
              <a
                class="button-link"
                [href]="marketPlaceLink_ledCflBulbs"
                target="_blank"
              >
                <button
                  mat-raised-button
                  class="marketplace-button"
                  id="electricEfficiency_market"
                  (click)="onStartShopping('ledCflBulbs', linkId_ledCflBulbs)"
                  color="accent"
                >
                  {{ marketPlaceDetails_ledCflBulbs.buttonText }}
                </button>
              </a>
            </div>
            <div
              *ngIf="
                haveYltnMarketplace &&
                marketPlaceDetails_ledCflBulbs &&
                displayMarketPlaceBox_ledCflBulbs
              "
              class="padding_top"
            >
              <button
                mat-raised-button
                class="marketplace-button"
                id="electricEfficiency_market"
                (click)="onStartShopping('ledCflBulbs', linkId_ledCflBulbs)"
                color="accent"
              >
                {{ marketPlaceDetails_ledCflBulbs.buttonText }}
              </button>
            </div>
          </div>
          <div class="yt_already_text">
            <div class="padding_bottom">
              <span class="already_text">Already have?</span>
              <mat-button-toggle-group
                [value]="installedLED"
                (change)="onChangeLED()"
              >
                <mat-button-toggle value="yes">Yes</mat-button-toggle>
                <mat-button-toggle value="no">No</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <span id="ledBulbs"></span>
            <span
              class="yt_content"
              *ngIf="readMore"
              (click)="readManager('onReadMore')"
            >
              Read more...</span
            >
            <span
              *ngIf="readMoreContent"
              class="yt_content"
              (click)="readManager('onReadLess')"
            >
              Read less</span
            >
          </div>
        </div>
      </div>
      <div class="yt_width">
        <div class="header_box">
          <div class="subHead">
            <div class="text_header">
              SMART THERMOSTAT: <span class="fw4">{{ makeAndModel }}</span>
            </div>
            <div>
              <span class="help_me_find" (click)="openHelpMeChoose()">
                <span class="helpme">Help me choose</span>
                <span class="choose">Choose</span>
              </span>
            </div>
          </div>
          <div
            *ngIf="showNetCost"
            class="link_text pointer"
            (click)="showIncentiveBreakdown = true"
          >
            Net Cost<span class="cost_value">${{ smartThermostat }}</span>
          </div>
          <div *ngIf="!showNetCost" class="disable_link">
            Net Cost<span class="cost_value">${{ smartThermostat }}</span>
          </div>
        </div>
        <div class="yt__width">
          <div class="image_box">
            <img class="thermostat-image" src="assets/lifestyle/thermo.svg" />
            <div
              *ngIf="
                !haveYltnMarketplace &&
                marketPlaceDetails_thermostat &&
                displayMarketPlaceBox_thermostat
              "
              class="padding_top"
            >
              <a
                class="button-link"
                [href]="marketPlaceLink_thermostat"
                target="_blank"
              >
                <button
                  mat-raised-button
                  class="marketplace-button"
                  id="electricEfficiency_market"
                  (click)="onStartShopping('thermostat', linkId_thermostat)"
                  color="accent"
                >
                  {{ marketPlaceDetails_thermostat.buttonText }}
                </button>
              </a>
            </div>
            <div
              *ngIf="
                haveYltnMarketplace &&
                marketPlaceDetails_thermostat &&
                displayMarketPlaceBox_thermostat
              "
              class="padding_top"
            >
              <button
                mat-raised-button
                class="marketplace-button"
                id="electricEfficiency_market"
                (click)="onStartShopping('thermostat', linkId_thermostat)"
                color="accent"
              >
                {{ marketPlaceDetails_thermostat.buttonText }}
              </button>
            </div>
          </div>
          <div class="yt_text">
            <span id="smartThermoInfo"></span>
            <span
              class="yt_content"
              *ngIf="readMoreTHR"
              (click)="readManager('onReadMoreTHR')"
            >
              Read more...</span
            >
            <span
              class="yt_content"
              *ngIf="readMoreContentTHR"
              (click)="readManager('onReadLessTHR')"
            >
              Read less</span
            >
          </div>
        </div>
      </div>
      <div class="yt_width">
        <div class="header_box">
          SMART POWER STRIPS
          <div class="textStyle">
            Cost<span class="yt_value">${{ smartStrips }}</span>
          </div>
        </div>
        <div class="yt__width">
          <div class="image_box">
            <img class="image" src="assets/lifestyle/SPS.svg" />
            <div
              *ngIf="
                !haveYltnMarketplace &&
                marketPlaceDetails_smartPowerStrips &&
                displayMarketPlaceBox_smartPowerStrips
              "
              class="padding_top"
            >
              <a
                class="button-link"
                [href]="marketPlaceLink_smartPowerStrips"
                target="_blank"
              >
                <button
                  mat-raised-button
                  class="marketplace-button"
                  id="electricEfficiency_market"
                  (click)="
                    onStartShopping('smartPowerStrips', linkId_smartPowerStrips)
                  "
                  color="accent"
                >
                  {{ marketPlaceDetails_smartPowerStrips.buttonText }}
                </button>
              </a>
            </div>
            <div
              *ngIf="
                haveYltnMarketplace &&
                marketPlaceDetails_smartPowerStrips &&
                displayMarketPlaceBox_smartPowerStrips
              "
              class="padding_top"
            >
              <button
                mat-raised-button
                class="marketplace-button"
                id="electricEfficiency_market"
                (click)="
                  onStartShopping('smartPowerStrips', linkId_smartPowerStrips)
                "
                color="accent"
              >
                {{ marketPlaceDetails_smartPowerStrips.buttonText }}
              </button>
            </div>
          </div>
          <div class="yt_text">
            <span id="smartPowInfo"></span>
            <span
              class="yt_content"
              *ngIf="readMoreSMT"
              (click)="readManager('onReadMoreSMT')"
            >
              Read more...
            </span>
            <span
              *ngIf="readMoreContentSMT"
              class="yt_content"
              (click)="readManager('onReadLessSMT')"
            >
              Read less</span
            >
          </div>
        </div>
      </div>
      <div class="yt_width">
        <div class="header_box">
          WEATHER STRIPPING
          <div class="textStyle">
            Cost<span class="yt_value">${{ weatherStripping }}</span>
          </div>
        </div>
        <div class="yt__width">
          <div class="image_box">
            <img class="image" src="assets/lifestyle/Weatherstripping.svg" />
            <div
              *ngIf="
                !haveYltnMarketplace &&
                marketPlaceDetails_weatherStripping &&
                displayMarketPlaceBox_weatherStripping
              "
              class="padding_top"
            >
              <a
                class="button-link"
                [href]="marketPlaceLink_weatherStripping"
                target="_blank"
              >
                <button
                  mat-raised-button
                  class="marketplace-button"
                  id="electricEfficiency_market"
                  (click)="
                    onStartShopping('weatherStripping', linkId_weatherStripping)
                  "
                  color="accent"
                >
                  {{ marketPlaceDetails_weatherStripping.buttonText }}
                </button>
              </a>
            </div>
            <div
              *ngIf="
                haveYltnMarketplace &&
                marketPlaceDetails_weatherStripping &&
                displayMarketPlaceBox_weatherStripping
              "
              class="padding_top"
            >
              <button
                mat-raised-button
                class="marketplace-button"
                id="electricEfficiency_market"
                (click)="
                  onStartShopping('weatherStripping', linkId_weatherStripping)
                "
                color="accent"
              >
                {{ marketPlaceDetails_weatherStripping.buttonText }}
              </button>
            </div>
          </div>
          <div class="yt_text">
            <span id="weatherStripInfo"></span>
            <span
              class="yt_content"
              *ngIf="readMoreWEA"
              (click)="readManager('onReadMoreWEA')"
            >
              Read more...</span
            >
            <span
              class="yt_content"
              *ngIf="readMoreContentWEA"
              (click)="readManager('onReadLessWEA')"
            >
              Read less</span
            >
          </div>
        </div>
      </div>
      <div class="yt_width">
        <div class="header_box">
          LOW FLOW AERATORS & SHOWERHEADS
          <div class="textStyle">
            Cost<span class="yt_value">${{ aeratorsShowerHeads }}</span>
          </div>
        </div>
        <div class="yt__width">
          <div class="image_box">
            <img class="image" src="assets/lifestyle/showerhead.svg" />
            <div
              *ngIf="
                !haveYltnMarketplace &&
                marketPlaceDetails_AS &&
                displayMarketPlaceBox_AS
              "
              class="padding_top"
            >
              <a
                class="button-link"
                [href]="marketPlaceLink_AS"
                target="_blank"
              >
                <button
                  mat-raised-button
                  class="marketplace-button"
                  id="electricEfficiency_market"
                  (click)="onStartShopping('aerators', linkId_aerators)"
                  color="accent"
                >
                  {{ marketPlaceDetails_AS.buttonText }}
                </button>
              </a>
            </div>
            <div
              *ngIf="
                haveYltnMarketplace &&
                marketPlaceDetails_AS &&
                displayMarketPlaceBox_AS
              "
              class="padding_top"
            >
              <button
                mat-raised-button
                class="marketplace-button"
                id="electricEfficiency_market"
                (click)="onStartShopping('aerators', linkId_aerators)"
                color="accent"
              >
                {{ marketPlaceDetails_AS.buttonText }}
              </button>
            </div>
          </div>
          <div class="yt_text">
            <span id="lowFInfo"></span>
            <span
              class="yt_content"
              *ngIf="readMoreSHW"
              (click)="readManager('onReadMoreSHW')"
            >
              Read more...</span
            >
            <span
              class="yt_content"
              *ngIf="readMoreContentSHW"
              (click)="readManager('onReadLessSHW')"
            >
              Read less</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Incentives Breakdown Dialogue box-->
<yt-breakdown-popup
  *ngIf="isResponseLoaded && showIncentiveBreakdown"
  [heading]="'Cost Breakdown'"
  [netCost]="'Net Cost'"
  [techName]="'lce'"
  (closeIncentiveBreakdown)="showIncentiveBreakdown = false"
>
</yt-breakdown-popup>

<!-- loader -->
<yt-loader *ngIf="loader"></yt-loader>
