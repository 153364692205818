import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LearningHubComponent } from "./learning-hub.component";
import { MatTabsModule } from "@angular/material/tabs";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatMenuModule } from "@angular/material/menu";
import { MatExpansionModule } from "@angular/material/expansion";
import { ScrollTopModule } from "../../shared/scroll-top";
import { BackButtonModule } from "../../homeowner-dashboard/shared/back-button";
import { RouterModule } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { DashboardFooterModule } from "../shared/dashboard-footer";
import { LoaderModule } from "../shared/loader";

@NgModule({
  imports: [
    CommonModule,
    MatTabsModule,
    MatButtonModule,
    MatButtonToggleModule,
    FormsModule,
    MatIconModule,
    ReactiveFormsModule,
    ScrollTopModule,
    MatMenuModule,
    BackButtonModule,
    MatExpansionModule,
    RouterModule,
    LoaderModule,
    DashboardFooterModule,
  ],
  declarations: [LearningHubComponent],
  providers: [],
  exports: [LearningHubComponent],
})
export class LearningHubModule {}
