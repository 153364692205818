import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { DropDownModel } from "../../../rest-api/rest-api.model";
import { RestAPIService } from "../../../rest-api/rest-api.service";
import { HPJourneyDetailsPUTModel } from "../../homeowner-info.model";
import { HomeOwnerInfoService } from "../../homeowner-info.service";

@Component({
  selector: "yt-address",
  templateUrl: "./address.component.html",
  styleUrls: ["../component-common.scss"],
})
export class AddressComponent implements OnInit {
  userAddress: FormGroup;
  address: FormControl;
  formattedAddress: string;
  addressFormatting: boolean = false;
  error: string;
  invalidAddress: boolean = false;
  showErrorDialogBox: boolean = false;
  isDialogBoxOpened: boolean = false;
  duplicationUserError: boolean = false;
  cityStatus: boolean = false;
  @Input() buildingTypes: DropDownModel[];
  @Output() energyInfo: EventEmitter<null> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private homeOwnerInfoService: HomeOwnerInfoService,
    private restAPI: RestAPIService
  ) {
    this.userAddress = this.fb.group({
      address: [""],
      city: [""],
      state: [""],
      county: [""],
      country: [""],
      zipCode: [""],
      buildingType: [""],
    });
  }

  ngOnInit(): void {
    const homeInfo = this.homeOwnerInfoService.userInfo?.homeInfo;
    if (homeInfo?.addressInfo) {
      this.userAddress.patchValue(homeInfo.addressInfo);
      this.invalidAddress = false;
      this.addressFormatting = true;
    }
    if (homeInfo?.buildingType)
      this.userAddress.controls["buildingType"].setValue(homeInfo.buildingType);

    this.getGoogleMapAddress();
  }

  onValidateAddress(address: string) {
    this.invalidAddress = true;
    this.addressFormatting = false;
  }

  getGoogleMapAddress(): void {
    const input = <HTMLInputElement>document.getElementById("placeAddress");
    const places = new google.maps.places.Autocomplete(input);
    google.maps.event.addListener(places, "place_changed", () => {
      const result = places.getPlace();
      const address = result.address_components;
      if (address) {
        address.forEach((component) => {
          const type = component.types[0];
          const name = component.long_name;
          if (type === "country") {
            this.userAddress.controls["country"].setValue(component.short_name);
          }
          if (type === "administrative_area_level_1") {
            this.userAddress.controls["state"].setValue(name);
          }
          if (type === "administrative_area_level_2") {
            let county = component.long_name;
            const length = county.length;
            const countyLength = county.substring(length - 7, length);
            if (countyLength === " County") {
              county = county.substring(0, length - 7);
            }
            this.userAddress.controls["county"].setValue(county);
          }
          if (type === "postal_code") {
            this.userAddress.controls["zipCode"].setValue(name);
          }
          if (type === "locality") {
            this.userAddress.controls["city"].setValue(name);
          }
        });
        this.addressFormatting = true;
        this.invalidAddress = false;
        this.userAddress.controls["address"].setValue(result.formatted_address);
        const buttonWrapper =
          document.querySelector<HTMLDivElement>(".button_wrapper");
        if (buttonWrapper) buttonWrapper.click();
        this.onSubmit(false);
      } else {
        this.addressFormatting = false;
        this.invalidAddress = true;
      }
    });
  }

  onSubmit(onContinue: boolean = false): void {
    this.homeOwnerInfoService.selectedProviderId = 0;
    if (!this.isDialogBoxOpened) {
      const zipCode = this.userAddress.controls["zipCode"].value || null;
      const address = this.addressFormatting
        ? this.userAddress.controls["address"].value
        : "";
      let userInfo: HPJourneyDetailsPUTModel = {
        homeInfo: {
          addressInfo: {
            address,
            county: this.userAddress.controls["county"].value || null,
            country: this.userAddress.controls["country"].value || null,
            city: this.userAddress.controls["city"].value || null,
            zipCode,
            state: this.userAddress.controls["state"].value || null,
          },
          buildingType: this.userAddress.controls["buildingType"].value
            ? this.userAddress.controls["buildingType"].value
            : null,
          onContinue,
        },
      };
      if (!this.addressFormatting) delete userInfo.homeInfo?.addressInfo;
      if (!this.userAddress.controls["buildingType"].value)
        delete userInfo.homeInfo?.buildingType;
      if (
        this.addressFormatting ||
        this.userAddress.controls["buildingType"].value
      ) {
        this.homeOwnerInfoService.userInfo = {
          ...this.homeOwnerInfoService.userInfo,
          homeInfo: userInfo.homeInfo,
        };
        this.homeOwnerInfoService.postUserDetails(userInfo).subscribe(
          (data) => {
            if (data.energyInfo) {
              this.homeOwnerInfoService.energyProviderList =
                data.energyInfo.elecUtilityProviders!;
            }
            if (onContinue) this.energyInfo.emit();
          },
          (error) => {
            if (error.status === 409) {
              this.openErrorDialogBox(error);
            } else {
              this.restAPI.showErrorMessage(error.error.message);
            }
          }
        );
      }
    }
  }

  openErrorDialogBox(error): void {
    this.error = error.error.message;
    if (this.error === "City Not Supported") {
      this.cityStatus = true;
      this.showErrorDialogBox = true;
      this.duplicationUserError = false;
    } else if (this.error === "Country Not Supported") {
      this.cityStatus = true;
      this.showErrorDialogBox = true;
      this.duplicationUserError = false;
    } else if (this.error === "State Not Supported") {
      this.cityStatus = true;
      this.showErrorDialogBox = true;
      this.duplicationUserError = false;
    } else {
      this.restAPI.showErrorMessage(error.error.message);
    }
  }

  closeErrorDialogBox(): void {
    this.isDialogBoxOpened = false;
    this.showErrorDialogBox = false;
  }

  isFieldValid(field: string, required: boolean): boolean {
    const isEmpty = this.userAddress.controls[field].value === "";
    if (required) return isEmpty && this.userAddress.controls[field].touched;
    return isEmpty;
  }

  isButtonDisabled(): boolean {
    return (
      this.isFieldValid("address", false) ||
      this.isFieldValid("buildingType", false) ||
      this.invalidAddress
    );
  }
}
