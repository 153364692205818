import { Component, Input } from "@angular/core";
import { DashboardAPIService } from "../../../../rest-api/dashboard/dashboard-api.service";
import { MarketPlaceDataModel } from "../../market-place.model";
import { Router } from "@angular/router";
import { MarketPlaceAnalyticsModel } from "../../../../rest-api/dashboard/dashboard-api.model";
import { MarketPlaceService } from "../../market-place.service";

@Component({
  selector: "yt-energyEfficientDetail-card",
  templateUrl: "energyEfficientDetail-card.component.html",
  styleUrls: ["./energyEfficientDetail-card.component.scss"],
})
export class EnergyEfficientDetailComponent {
  @Input() techList: MarketPlaceDataModel[];
  @Input() rewardBonus: boolean;
  constructor(
    private dashboardAPIService: DashboardAPIService,
    private router: Router,
    private marketplaceService: MarketPlaceService
  ) {}

  getTechParagraph(techName: string): string {
    return `homeOwenerDashboard.marketPlace.${techName}.paragraph`;
  }

  onShopping(techValue, link, linkId) {
    const details: MarketPlaceAnalyticsModel = {
      tech: techValue,
      linkId: linkId,
    };
    this.dashboardAPIService.marketplaceDetailsAnalytics(details);
    this.marketplaceService.openShopNowTab(link);
  }

  onLearnMore(tech: MarketPlaceDataModel): void {
    this.router.navigateByUrl(tech.routerLink!);
    const details: MarketPlaceAnalyticsModel = {
      tech: tech.value,
      detail: "learnMore",
    };
    this.dashboardAPIService.marketplaceDetailsAnalytics(details);
  }

  onBuyingGuide(tech: MarketPlaceDataModel): void {
    if (tech.partnerList?.guidanceLink) {
      this.marketplaceService.openShopNowTab(tech.partnerList.guidanceLink);
    } else if (tech.partnerList?.guidanceLink) {
      window.open(tech.partnerList.guidanceLink, "_blank");
    }
    const details: MarketPlaceAnalyticsModel = {
      tech: tech.value,
      detail: "viewBuyingGuide",
    };
    this.dashboardAPIService.marketplaceDetailsAnalytics(details);
  }
}
