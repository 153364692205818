import { OverviewService } from "src/app/homeowner-dashboard/overview/overview.service";
import { RestAPIService } from "../../../rest-api/rest-api.service";
import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "Dialog-Box",
  templateUrl: "Dialog-box.component.html",
  styleUrls: ["./Dialog-box.component.scss"],
})
export class DialogBoxComponent implements OnInit {
  yellowtin: boolean;
  solarInstallers: boolean;
  PGEPopUp: boolean;
  Yellowtin: boolean;
  confirmPopup: boolean;
  utilityName: string;
  utilityId: any;
  cceOptOut: boolean;
  solutionType: string;

  constructor(
    private restAPIService: RestAPIService,
    private dialog: MatDialogRef<DialogBoxComponent>,
    private overviewService: OverviewService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.PGEPopUp = this.data.link === "PGE";
    this.Yellowtin = this.data.link === "YT";
    this.confirmPopup = this.data.link === "confirm";
    this.utilityName = this.restAPIService.elecUtilityProviderName;
    this.utilityId = this.restAPIService.elecUtilityProviderId;
    this.cceOptOut = this.restAPIService.cceOptOut;
    this.solutionType = this.restAPIService.solarVendorInformation.solutionType;
    if (this.solutionType === "broader") {
      this.yellowtin = true;
      this.solarInstallers = false;
    } else if (this.solutionType === "vendor" || this.solutionType === "cce") {
      this.yellowtin = false;
      this.solarInstallers = true;
    }
  }

  onSubmit(): void {
    this.data.dashboard ? this.continuePGE() : this.updateUtilityProvider();
  }

  updateUtilityProvider(): void {
    this.restAPIService
      .postUtilityProvider(this.utilityName, this.utilityId, this.cceOptOut)
      .subscribe((response) => {
        if (response.status === 200) {
          window.location.replace(this.data.link2);
        }
      });
  }

  continuePGE(): void {
    const pgeStatus = { pgeAuth: true };
    this.overviewService.onContinuePge(pgeStatus).subscribe((response) => {
      if (response.status === 200) {
        this.data.link2 = this.data.link2 + ";" + "dbu";
        window.open(this.data.link2, "_blank");
        this.dialog.close("pgeStart");
      }
    });
  }
}
