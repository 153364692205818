import { Injectable } from "@angular/core";
import { DashboardAPIService } from "../../rest-api/dashboard/dashboard-api.service";
import { HttpClient } from "@angular/common/http";
import { UserDataModel } from "../overview/model/userdata.model";
import { Observable } from "rxjs/Observable";
import { SolarVendorDetailsModel } from "../overview/model/solarvendordetails.model";
import {
  CleanChoiceCategoryModel,
  LifestylesDTO,
  TechInfoModel,
} from "./model/dashboard.model";

@Injectable()
export class OverviewService {
  techInfo: TechInfoModel;
  initState: TechInfoModel;
  productTab: WindowProxy | null = null;
  readonly lifestyleURL = "assets/json/energy-choice.json";
  readonly baseUrl;
  readonly API_END_POINT;
  readonly settingsAPI;
  readonly learningHubMarketAPI;
  readonly updateSettingsAPI;
  readonly solarVendorDashboardAPI;

  constructor(
    private dashboardAPI: DashboardAPIService,
    private httpClient: HttpClient
  ) {
    this.API_END_POINT = this.dashboardAPI.API_END_POINT;
    this.baseUrl = `${this.API_END_POINT}ho/dashboard/`;
    this.settingsAPI = `${this.baseUrl}settings/`;
    this.learningHubMarketAPI = `${this.baseUrl}learningHubMarket/`;
    this.updateSettingsAPI = `${this.baseUrl}updateSettings/`;
    this.solarVendorDashboardAPI = `${this.API_END_POINT}solarVendorsDashboard/`;
    this.initInfoState();
  }

  get lifestyleJSON(): Observable<LifestylesDTO> {
    return this.httpClient.get<LifestylesDTO>(this.lifestyleURL);
  }

  get lifestyleData(): Observable<SolarVendorDetailsModel> {
    return this.httpClient.get<SolarVendorDetailsModel>(
      `${this.solarVendorDashboardAPI}${this.dashboardAPI.userDetails.solarVendorCode}/${this.dashboardAPI.userDetails.userName}`
    );
  }

  get userSettingInfo(): Observable<UserDataModel> {
    return this.httpClient.get<UserDataModel>(
      this.settingsAPI + this.dashboardAPI.userDetails.userName
    );
  }

  updateUserSettingInfo(userInfo) {
    return this.httpClient.put(
      this.updateSettingsAPI + this.dashboardAPI.userDetails.userName,
      userInfo,
      { observe: "response" }
    );
  }

  updateTechSettingInfo(
    techParameter: string,
    ownPlan: string,
  ): Observable<any> {
    const payload = this.buildPayload(techParameter, ownPlan);
    return this.httpClient.put(
      this.updateSettingsAPI + this.dashboardAPI.userDetails.userName,
      payload,
      { observe: "response" }
    );
  }

  buildPayload(techParameter: string, ownValue: string) {
    switch (techParameter) {
      case "ev1":
        return {
          transportation: {
            ev: [{ own: ownValue,"tech": "ev1" }],
          },
        };
      case "ev2":
        return {
          transportation: {
            ev: [{ own: ownValue,"tech": "ev2"}],
          },
        };
      case "evChargers":
        return {
          transportation: {
            evChargers: [{ own: ownValue }],
          },
        };
      case "eBike":
        return {
          transportation: {
            eBike: [{ own: ownValue }],
          },
        };
      case "solar":
        return {
          solarBattery: {
            solar: [{ own: ownValue }],
          },
        };
      case "battery":
        return {
          solarBattery: {
            battery: [{ own: ownValue }],
          },
        };
      case "portableBattery":
        return {
          solarBattery: {
            portableBattery: [{ own: ownValue }],
          },
        };
      case "communitySolar":
        return {
          solarBattery: {
            communitySolar: [{ own: ownValue }],
          },
        };
      case "hpwh":
        return {
          appliances: {
            hpwh: [{ own: ownValue }],
          },
        };
      case "ashp":
      case "hpsc":
        return {
          appliances: {
            hpsc: [{ own: ownValue }],
          },
        };
      case "hpd":
        return {
          appliances: {
            hpd: [{ own: ownValue }],
          },
        };
      case "ic":
        return {
          appliances: {
            ic: [{ own: ownValue }],
          },
        };
      case "portableIC":
        return {
          appliances: {
            ic: [{ own: ownValue }],
          },
        };
      case "ledCflBulbs":
        return {
          simpleLowCost: {
            ledCflBulbs: [{ own: ownValue }],
          },
        };
      case "smartThermostat":
        return {
          simpleLowCost: {
            smartThermostat: [{ own: ownValue }],
          },
        };
      case "smartPowerStrips":
        return {
          simpleLowCost: {
            smartPowerStrips: [{ own: ownValue }],
          },
        };
      case "weatherStripping":
        return {
          simpleLowCost: {
            weatherStripping: [{ own: ownValue }],
          },
        };
      case "showerHeadsAerators":
        return {
          simpleLowCost: {
            showerHeadsAerators: [{ own: ownValue }],
          },
        };
      case "everGreen":
        return {
          simpleLowCost: {
            everGreen: [{ own: ownValue }],
          },
        };
      default:
        return {};
    }
  }

  initInfoState(): void {
    this.initState = {
      battery: {
        title: "Battery",
        tooltip:
          "Back up power to run your critical appliances during a power outage.",
        tagline: "Plan for Outages",
        image: "assets/lifestyle/battery.svg",
      },
      // ic: {
      //   title: "Induction Cooking",
      //   tooltip:
      //     "Faster and safer cooktop providing significantly better indoor air quality.",
      //   tagline: "Enjoy Cooking",
      //   image: "assets/lifestyle/cooktop.png",
      // },
    };
  }

  updateEnergyChoiceInfo(buildingType: string): boolean {
    if (buildingType === "renter" || buildingType === "condominium") {
      const renterApproach = this.dashboardAPI.vendorDetails.renterApproach;
      const condoApproach = this.dashboardAPI.vendorDetails.condominiumApproach;
      if (renterApproach === "approach2" || condoApproach === "approach2") {
        this.techInfo = Object.assign({}, this.initState);
        this.techInfo.battery = {
          title: "Portable Battery",
          tooltip: this.initState.battery.tooltip,
          tagline: this.initState.battery.tagline,
          image: "assets/lifestyle/portable-battery.png",
        };
        // this.techInfo.ic = {
        //   title: "Portable Cooktop",
        //   tooltip: this.initState.ic.tooltip,
        //   tagline: this.initState.ic.tagline,
        //   image: "assets/lifestyle/portable-cooktop.png",
        // };
        return true;
      } else {
        this.techInfo = Object.assign({}, this.initState);
        return false;
      }
    } else {
      this.techInfo = Object.assign({}, this.initState);
      return false;
    }
  }

  onContinuePge(pgeStatus) {
    return this.httpClient.put(
      this.updateSettingsAPI + this.dashboardAPI.getUserDetails().userName,
      pgeStatus,
      { observe: "response" }
    );
  }

  getCleanChoiceMarketplace(
    category: string
  ): Observable<CleanChoiceCategoryModel[]> {
    return this.httpClient.get<CleanChoiceCategoryModel[]>(
      this.learningHubMarketAPI +
        this.dashboardAPI.userDetails.userName +
        "?categoryInfo=" +
        category
    );
  }
}
