<!-- <swiper
  #swiper
  class="swiper"
  [navigation]="true"
  [pagination]="{ clickable: true }"
  (mouseenter)="onEnter()"
  (mouseleave)="onLeave()"
>
  <ng-template swiperSlide *ngIf="showEmailAlert">
    <div class="yt_alert" id="emailAlert">
      <div class="freeAccessAlert_message">
        <div class="flex justify-between alert_padding yt_message">
          <span class="padding_right">
            Congratulations! Here’s your clean energy dashboard. We have also
            sent an email from
            <strong>support&#64;yellowtin.com</strong>
            with your login details.
          </span>
          <span
            class="fa fa-times pointer padding_left"
            (click)="onCloseOfEmailAlert()"
          ></span>
        </div>
      </div>
    </div>
  </ng-template> -->

<!-- When user has selected category -->
<!-- <ng-template swiperSlide *ngIf="showCategorySelectedAlert">
    <div class="yt_alert" id="greetAlert">
      <div class="freeAccessAlert_message">
        <div class="flex justify-between alert_padding yt_message">
          <span class="padding_right"
            >See how you can save
            <strong class="incentives"
              >$${{ incentivesAmount | number }}</strong
            >
            from potential tax credits and rebates.
          </span>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template swiperSlide *ngIf="showCategorySelectedAlert">
    <div class="yt_alert" id="greetAlert">
      <div class="freeAccessAlert_message">
        <div class="flex justify-between alert_padding yt_message">
          <span class="padding_right"
            >Personalizing the details would provide even more accurate savings
            estimate. <span class="alert_link_text">Click here</span>
          </span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template swiperSlide *ngIf="showAlert">
    <div class="yt_alert" id="alert">
      <div class="alert_message">
        <div class="flex justify-between alert_padding yt_message">
          <span class="padding_right" *ngIf="sliderAlert">
            You can improve the accuracy of your results by authorizing PG&E to
            share your energy data. To do this, please
            <a class="alert_link_text" (click)="onEnergyUsage()">click here</a>
            and log into PG&E.
          </span>
          <span class="padding_right" *ngIf="revokedAlert">
            Your PG&E utility data is not up to date.
            <a class="alert_link_text" (click)="onEnergyUsage()">Click here</a>
            to reauthorize PG&E to share your energy data so it remains current.
          </span>
          <span class="padding_right" *ngIf="partialAlert">
            Incomplete energy authorization! Please
            <a class="alert_link_text" (click)="onEnergyUsage()">click here</a>
            and provide access to (1) Basic (2) Usage & (3) Billing info.
          </span>
          <span
            class="fa fa-times pointer"
            *ngIf="!sliderAlert"
            (click)="onAlertClose()"
          ></span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template swiperSlide *ngIf="showErrorAlert">
    <div class="yt_alert" id="errorAlert">
      <div class="alert_message">
        <div class="flex justify-between alert_padding yt_message">
          <span
            >Some of your inputs are outside of expected ranges and this could
            lead to incorrect estimates. Please confirm your key inputs related
            to the {{ errorTechnologiesList.join(", ") }}. We will proceed with
            your analysis based on the data provided. If you would like further
            assistance, please contact us at
            <a class="alert_link_text" href="mailto:support@yellowtin.com"
              >YellowTin Support</a
            >.</span
          >
        </div>
      </div>
    </div>
  </ng-template>
</swiper> -->

<div class="swiper-container" *ngIf="alertCardsList.length">
  <ng-container *ngFor="let alert of alertCardsList; index as i">
    <div
      class="swiper-card"
      [ngClass]="{ 'active-swiper-card': i === activeAlertCardIndex }"
    >
      <div class="border green-border-alert" *ngIf="alert.value === 'congrats'">
        <span class="padding_right">
          Congratulations! Here’s your clean energy dashboard. We have also sent
          an email from
          <strong>support&#64;yellowtin.com</strong>
          with your login details.
        </span>
        <mat-icon (click)="onCloseOfEmailAlert()">close</mat-icon>
      </div>
      <!-- <div
        class="border green-border-alert"
        *ngIf="alert.value === 'categorySelectedSavings'"
      >
        <span class="padding_right">
          <div class="imageText_wrapper">
            <img src="assets/dashboard/overview/incentives.png" alt="" />
            <span>
              <a
                class="alert_link_text"
                [ngClass]="{ disable_link: incentivesAmount === 0 }"
                (click)="onIncentives()"
                >See how</a
              >
              you can save
              <strong class="incentives"
                >${{ incentivesAmount | number }}</strong
              >
              from potential tax credits and rebates.
            </span>
          </div>
        </span>
      </div> -->
      <!-- <div
        class="border green-border-alert"
        *ngIf="alert.value === 'categorySelectedPersonalize'"
      >
        <div class="imageText_wrapper">
          <img src="assets/dashboard/overview/personalise.png" alt="" />
          <span class="padding_right"
            >Personalizing the details would provide even more accurate savings
            estimate.
            <a class="alert_link_text" (click)="onEnergyUsage()">Click here</a>
          </span>
        </div>
      </div> -->
      <div
        class="border red-border-alert"
        *ngIf="alert.value === 'techError' && !!errorTechnologiesList.length"
      >
        <span
          >Some of your inputs are outside of expected ranges and this could
          lead to incorrect estimates. Please confirm your key inputs related to
          the {{ errorTechnologiesList.join(", ") }}. We will proceed with your
          analysis based on the data provided. If you would like further
          assistance, please contact us at
          <a class="alert_link_text" href="mailto:support@yellowtin.com"
            >YellowTin Support</a
          >.</span
        >
      </div>
      <div class="border red-border-alert" *ngIf="alert.value === 'pge'">
        <span class="padding_right" *ngIf="sliderAlert">
          You can improve the accuracy of your results by authorizing PG&E to
          share your energy data. To do this, please
          <a class="alert_link_text" (click)="onEnergyUsage()">click here</a>
          and log into PG&E.
        </span>
        <span class="padding_right" *ngIf="revokedAlert">
          Your PG&E utility data is not up to date.
          <a class="alert_link_text" (click)="onEnergyUsage()">Click here</a>
          to reauthorize PG&E to share your energy data so it remains current.
        </span>
        <span class="padding_right" *ngIf="partialAlert">
          Incomplete energy authorization! Please
          <a class="alert_link_text" (click)="onEnergyUsage()">click here</a>
          and provide access to (1) Basic (2) Usage & (3) Billing info.
        </span>
        <span
          class="fa fa-times pointer"
          *ngIf="!sliderAlert"
          (click)="onAlertClose()"
        ></span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="alertCardsList.length > 1">
    <div class="swiper-navigation">
      <div
        class="swiper-nav-button"
        *ngFor="let alert of alertCardsList; index as i"
        [ngClass]="{ 'active-nav-button': i === activeAlertCardIndex }"
        (click)="handleDotClick(i)"
      ></div>
    </div>
    <div class="swiper-button left" (click)="onPrevSwiper()">
      <mat-icon class="icon">chevron_left</mat-icon>
    </div>
    <div class="swiper-button right" (click)="onNextSwiper()">
      <mat-icon class="icon">chevron_right</mat-icon>
    </div>
  </ng-container>
</div>

<div id="errorAlert-overlay" #errorAlertOverlay *ngIf="showErrorMsgPopup">
  <div id="errorAlert-text">
    <div class="yt_bg-white yt_text">
      <div class="yt_popup-header padding_all">Possible error</div>
      <div class="padding_all">
        <div class="f4">
          One or more inputs are outside of expected ranges. The typical reasons
          for this are as follows:
        </div>
        <ul class="ul_padding">
          <li
            class="list_padding"
            *ngIf="errorTechnologiesList.includes('Water Heater')"
          >
            <div class="yt_popup_subheader">Water Heater</div>
            <div class="f4">
              Your current water heater type might be incorrect.
            </div>
          </li>
          <li
            class="list_padding"
            *ngIf="errorTechnologiesList.includes('Heating & Cooling')"
          >
            <div class="yt_popup_subheader">Heating and cooling</div>
            <div class="f4">
              Your current heating system type might be incorrect.
            </div>
          </li>
          <li
            class="list_padding"
            *ngIf="errorTechnologiesList.includes('Electric Vehicle')"
          >
            <div class="yt_popup_subheader">Electric Vehicle</div>
            <div class="f4">
              Your Make & Model or the Annual Miles Driven might be incorrect.
            </div>
          </li>
          <li
            class="list_padding"
            *ngIf="errorTechnologiesList.includes('Spa')"
          >
            <div class="yt_popup_subheader">Spa</div>
            <div class="f4">Your annual spa usage might be incorrect. .</div>
          </li>
          <li
            class="list_padding"
            *ngIf="errorTechnologiesList.includes('Average Monthly Bill')"
          >
            <div class="yt_popup_subheader">Average Monthly Bill</div>
            <div class="f4">
              Your estimate might be too low.
              <span class="link_text" routerLink="/energy-info"
                >Click here</span
              >
              to update.
            </div>
          </li>
          <li
            class="list_padding"
            *ngIf="errorTechnologiesList.includes('Solar')"
          >
            <div class="yt_popup_subheader">Solar</div>
            <div class="f4">
              You may currently have a solar system and not indicated it
              <span
                class="link_text"
                routerLink="/dashboard/energy-choice/solar"
                >here.</span
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
