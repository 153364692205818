<div>
  <mat-radio-group class="radioButton-flex">
    <div class="cards" *ngFor="let techList of displayAppliances">
      <label>
        <yt-techradio-boxes
          [cardImage]="techList.techImage"
          [viewValue]="techList.viewValue"
          [default]="defaultValue"
          [value]="techList.value"
        ></yt-techradio-boxes>
        <mat-radio-button
          class="hidden_button absolute top-0"
          [value]="techList.value"
          (change)="onTechChange($event)"
        >
        </mat-radio-button>
      </label>
    </div>
  </mat-radio-group>
</div>

<div>
  <yt-hpwh
    *ngIf="defaultValue === 'hpwh'"
    [activeIcon]="activeIcon"
    [HPWHDetails]="appliancesTechDetails.hpwh"
    (techSave)="emitTech($event)"
  ></yt-hpwh>
</div>

<div>
  <yt-ic
    *ngIf="defaultValue === 'ic' || defaultValue === 'portableIC'"
    [activeIcon]="activeIcon"
    [ICDetails]="appliancesTechDetails.ic"
    (techSave)="emitTech($event)"
  ></yt-ic>
</div>

<div>
  <yt-hpd
    *ngIf="defaultValue === 'hpd'"
    [activeIcon]="activeIcon"
    [HPDDetails]="appliancesTechDetails.hpd"
    (techSave)="emitTech($event)"
  ></yt-hpd>
</div>

<div>
  <yt-hpsc
    *ngIf="defaultValue === 'hpsc'"
    [activeIcon]="activeIcon"
    [HPSCDetails]="appliancesTechDetails.hpsc"
    (techSave)="emitTech($event)"
  ></yt-hpsc>
</div>
