<div class="container">
  <h1 class="header">{{ "homeOwnerInfo.personalInfo.header" | translate }}</h1>
  <p class="subHeader">
    {{ "homeOwnerInfo.personalInfo.subHeader" | translate }}
  </p>
  <form [formGroup]="userDetailsForm">
    <div class="name_wrapper">
      <mat-form-field
        appearance="outline"
        floatLabel="auto"
        hideRequiredMarker="true"
        class="nameField"
      >
        <mat-label>First Name</mat-label>
        <input
          type="text"
          matInput
          formControlName="firstName"
          autocomplete="firstName"
          required
        />
        <mat-error
          *ngIf="userDetailsForm.controls['firstName'].hasError('required')"
          >Required!</mat-error
        >
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        floatLabel="auto"
        hideRequiredMarker="true"
        class="nameField"
      >
        <mat-label>Last Name</mat-label>
        <input
          type="text"
          matInput
          formControlName="lastName"
          autocomplete="firstName"
          required
        />
        <mat-error
          *ngIf="userDetailsForm.controls['lastName'].hasError('required')"
          >Required!</mat-error
        >
      </mat-form-field>
    </div>
    <mat-form-field
      appearance="outline"
      floatLabel="auto"
      hideRequiredMarker="true"
    >
      <mat-label>Email Address</mat-label>
      <input
        type="text"
        matInput
        formControlName="emailId"
        (focusout)="triggerApi(true)"
      />
      <mat-error
        *ngIf="userDetailsForm.controls['emailId'].hasError('required')"
        >Required!</mat-error
      >
      <mat-error
        *ngIf="
          userDetailsForm.controls['emailId'].hasError('pattern') &&
          !userDetailsForm.controls['emailId'].hasError('required')
        "
        >Invalid Email!</mat-error
      >
      <mat-error
        *ngIf="isWorkDayUser && userDetailsForm.controls['emailId'].valid"
      >
        Please use your personal email address to sign up
      </mat-error>
      <mat-error
        *ngIf="
          userDetailsForm.controls['emailId'].valid &&
          !isWorkDayUser &&
          inValidMail
        "
      >
        Invalid email!
      </mat-error>
      <mat-hint
        align="end"
        *ngIf="vendorsWithPersonalMail.includes(vendorCode) && !isWorkDayUser"
        >Use your personal email address to sign up</mat-hint
      >
    </mat-form-field>
    <div class="button_wrapper">
      <button
        mat-raised-button
        color="accent"
        (click)="onSubmit(true)"
        [disabled]="
          userDetailsForm.invalid ||
          (isWorkDayUser && userDetailsForm.controls['emailId'].valid)
        "
      >
        NEXT
      </button>
    </div>
  </form>
  <div class="yt_disclaimer">
    <p>
      <span>{{ tagLine }}</span>
      {{ "homeOwnerInfo.personalInfo.disclaimer.tagLine" | translate }}
      <a
        class="pointer yt__link"
        href="https://www.yellowtin.com/termsofuse/"
        target="_blank"
      >
        {{ "homeOwnerInfo.personalInfo.disclaimer.terms" | translate }} </a
      >{{ "homeOwnerInfo.personalInfo.disclaimer.and" | translate }}
      <a
        class="pointer yt__link"
        href="https://www.yellowtin.com/privacy_policy/"
        target="_blank"
      >
        {{ "homeOwnerInfo.personalInfo.disclaimer.policy" | translate }}</a
      >
    </p>
    <div class="disclaimer_text">
      <img src="assets/shield.svg" alt="shield" class="shield_padding" />
      Don’t worry, your information will not be shared with any third parties
      without consent.
    </div>
  </div>

  <yt-error-popup
    [showErrorDialogueBox]="showErrorDialogueBox"
    [cityStatus]="cityStatus"
    (isDialogBoxOpened)="closeErrorDialogBox()"
    [loginAccess]="loginAccess"
    [loginLink]="loginLink"
    [forgotPasswordLink]="forgotPasswordLink"
    [duplicationUserError]="duplicationUserError"
  ></yt-error-popup>

  <yt-confirm-popup
    header="Confirm email update"
    subheader="This action will clear all your information. Are you sure you want to continue?"
    primaryButton="YES"
    secondaryButton="NO"
    *ngIf="isEmailEdited"
    (selectedNo)="cancelEmailUpdate()"
    (selectedYes)="updateEmailAddress()"
  ></yt-confirm-popup>
</div>
