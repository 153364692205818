import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import {
  LearnMoreRequestModel,
  MarketPlaceDetailsModel,
} from "../learn-more.model";
import {
  EVChargerCostModel,
  EVChargerSummaryModel,
  EVChargersModel,
  LearnMoreDetailsModel,
} from "./ev-chargers.model";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";
import { LearnMoreService } from "../learn-more.service";
import { HelpMeChooseComponent } from "../../shared/help-me-choose/help-me-choose.component";
import {
  MNFAndModelListResponse,
  MNFAndModel,
} from "../../../homeowner-product/energy-choices/energy-choices.model";
import { RestAPIService } from "../../../rest-api/rest-api.service";
import { AppService } from "../../../app.service";
import { DropDownModel } from "../../../rest-api/rest-api.model";
import { SectionListModel } from "../../../rest-api/dashboard/dashboard-api.model";
import { OverviewService } from "../../overview/overview.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "yt-ev-chargers-details",
  templateUrl: "./ev-chargers-details.component.html",
  styleUrls: ["../common-learn-more.scss"],
})
export class EVChargerDetailsComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  icon = false;
  icon1 = false;
  loader = true;
  haveResponse = false;
  showHelpMeChoose: boolean;
  evChargerForm: FormGroup;
  evChargerCostModel: EVChargerCostModel;
  evChargerSummaryModel: EVChargerSummaryModel;
  marketPlaceDetails: MarketPlaceDetailsModel;
  learnMoreDetails: LearnMoreDetailsModel;
  displayMarketPlaceBox: boolean;
  marketPlaceLink: string;
  totalIncentives: number;
  showIncentivesStatus: boolean;
  showIncentiveBreakdown = false;
  linkId: string;
  vendorName: string;
  adviceCardStatus: boolean;
  makeAndModelList: MNFAndModel[];
  isLangSpanish: boolean;
  tabLabel: string;
  tabIndex: number = 0;
  selectedSection: string = "aboutTechnology";
  FAQTabsList: DropDownModel[];
  isTechnicalExpanded: boolean;
  isFAQExpanded: boolean;
  favStatus: boolean;
  favIcon: string;
  @ViewChild("loaderButton", { read: ElementRef }) saveButton: ElementRef;

  constructor(
    private snackBar: MatSnackBar,
    private overviewService: OverviewService,
    private dashboardService: DashboardAPIService,
    private learnMoreService: LearnMoreService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private restAPIService: RestAPIService,
    private cdRef: ChangeDetectorRef,
    private appService: AppService
  ) {
    this.evChargerForm = this.fb.group({
      model: ["", Validators.required],
    });
  }

  ngAfterContentChecked(): void {
    this.isLangSpanish = this.appService.selectedLanguage.value === "sp";
    this.cdRef.detectChanges(); // TO avoid expression checking error in console NG100
  }

  ngOnInit(): void {
    this.vendorName = this.dashboardService.getUserDetails().solarVendorCode;
    this.dashboardService.faqDetails().subscribe((list: SectionListModel) => {
      this.FAQTabsList = list.evChargers.faqList!;
    });
    this.showHelpMeChoose =
      this.dashboardService.vendorDetails.helpMeChoose.evChargers!;
    this.learnMoreService
      .learnMoreInfo<EVChargersModel>("evChargers")
      .subscribe((response: EVChargersModel) => {
        this.loader = false;
        this.haveResponse = true;
        this.evChargerCostModel = response.financialDetails.evChargerCost;
        this.evChargerSummaryModel = response.technicalDetails.evChargerSummary;
        this.totalIncentives = this.evChargerCostModel.incentives;
        this.showIncentivesStatus = this.evChargerCostModel.showIncentives;
        this.learnMoreDetails = response.learnMoreDetails;
        this.favStatus = this.learnMoreDetails.favoriteStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
        this.getEvChargerMakeAndModelList();
        this.evChargerForm
          .get("model")
          ?.setValue(response.learnMoreDetails.mnfAndModel);
      });
    this.dashboardService
      .getMarketplaceDetails("evChargers")
      .subscribe((response: MarketPlaceDetailsModel) => {
        this.marketPlaceDetails = response;
        this.adviceCardStatus = response.expertAdvice;
        this.displayMarketPlaceBox = this.marketPlaceDetails.displayBox;
        this.marketPlaceLink = this.marketPlaceDetails.buttonLink;
        if (this.marketPlaceDetails.linkId === "") {
          this.linkId = "";
        } else {
          this.linkId = this.marketPlaceDetails.linkId;
        }
      });
  }

  getEvChargerViewDetails(): void {
    this.learnMoreService
      .learnMoreInfo<EVChargersModel>("evChargers")
      .subscribe((response: EVChargersModel) => {
        this.loader = false;
        this.haveResponse = true;
        this.evChargerCostModel = response.financialDetails.evChargerCost;
        this.evChargerSummaryModel = response.technicalDetails.evChargerSummary;
        this.totalIncentives = this.evChargerCostModel.incentives;
        this.showIncentivesStatus = this.evChargerCostModel.showIncentives;
        this.learnMoreDetails = response.learnMoreDetails;
        this.favStatus = this.learnMoreDetails.favoriteStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
      });
  }

  getEvChargerMakeAndModelList() {
    this.restAPIService
      .getMakeAndModelList("evChargers")
      .subscribe(
        (response: MNFAndModelListResponse) =>
          (this.makeAndModelList = response.mnfDropDownList)
      );
  }

  handleActiveValue(data: string): void {
    if (data === "details") {
      this.isTechnicalExpanded = true;
      this.icon = true;
    } else if (data === "faq") {
      this.isFAQExpanded = true;
      this.icon1 = true;
    }
  }

  technicalInfoPanel(): void {
    this.icon = !this.icon;
  }

  basicInfoPanel(): void {
    this.icon1 = !this.icon1;
  }

  learnMoreApi() {
    this.learnMoreService
      .updateLearnMoreInfo("evChargers", this.evChargerData)
      .subscribe((data: any) => {
        this.ngOnInit();
        this.dashboardService.dashboardDetailsAnalytics("save", "evChargers");
      });
  }

  get evChargerData(): LearnMoreRequestModel {
    return {
      evCharger: {
        mnfAndModel: this.evChargerForm.get("model")?.value,
      },
    };
  }

  onSaveDetails() {
    this.haveResponse = false;
    if (
      this.learnMoreService.isFormEditted(
        this.evChargerData.evCharger!,
        this.learnMoreDetails
      )
    ) {
      this.saveButton.nativeElement.innerHTML =
        'Saving... &nbsp;<i class="fa fa-spinner fa-spin"></i>';
      this.dashboardService.dashboardDetailsAnalytics("save", "evChargers");
      this.learnMoreService
        .updateLearnMoreInfo("evChargers", this.evChargerData)
        .subscribe((data: any) => {
          setTimeout(() => {
            this.saveButton.nativeElement.innerHTML = "SAVE DETAILS";
            this.ngOnInit();
          }, 800);
        });
    }
  }

  openHelpMeChoose(): void {
    let dialogRef = this.dialog.open(HelpMeChooseComponent, {
      width: "90%",
      height: "75%",
      autoFocus: false,
      backdropClass: "dark-backdrop",
      panelClass: "custom-dialog-container",
      data: { tech: "evCharger" },
    });
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result !== undefined) {
        this.evChargerForm.get("model")?.setValue(result);
        this.learnMoreApi();
      }
    });
  }

  tabChanged(event: any) {
    this.tabLabel = event.value;
    this.tabIndex = event.index;
  }

  scrollToTop(): void {
    document.querySelector(".mat-sidenav-content")?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  handleFAQClick(): void {
    this.dashboardService.dashboardDetailsAnalytics("moreInfo", "evChargers");
    window.open(
      "https://www.yellowtin.com/clean-energy-information-center-es/electric-vehicle-charger-faq-es/",
      "_blank"
    );
  }

  onFavorite(): void {
    const ownPlan = this.favStatus ? "noPlan" : "yesPlan";
    this.overviewService
      .updateTechSettingInfo("evChargers", ownPlan)
      .subscribe((response) => {
        this.favStatus = !this.favStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
        this.getEvChargerViewDetails();

        const action = this.favStatus ? "add" : "remove";
        const info = this.dashboardService.techMessage("evChargers", action);
        this.snackBar.open(info, "OK", {
          duration: 8000,
        });
      });
  }

  ngOnDestroy(): void {}
}
