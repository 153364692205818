<footer class="footer">
  <div class="footer_text">
    <span>
      Powered by
      <a href="https://www.yellowtin.com/" target="_blank">YellowTin</a> |</span
    >
    <a href="https://www.yellowtin.com/privacy_policy/" target="_blank">
      Privacy Policy
    </a>
    |
    <a href="https://www.yellowtin.com/yt-contact-us" target="_blank">
      Contact Us
    </a>
    |
    <a href="https://www.yellowtin.com/yt-ccpa-form-rtoo/" target="_blank">
      Do Not Sell My Info
    </a>
  </div>
</footer>
