export enum StepperEnum {
  MyInfo = 0,
  HomeInfo = 1,
  EnergyInfo = 2,
  Goal = 3,
}

export enum CleanChoicesSections {
  OTHER_SIMPLE_LOW_COST = "otherSimpleLowCost",
  OTHER_TRANSPORTATION = "otherTransportation",
  OTHER_APPLIANCES = "otherAppliances",
  YARD_APPLIANCES = "yardAppliances",
  ENERGY_EFFICIENCY_APPLIANCES = "energyEfficiencyAppliances",
}

export enum CleanChoicesTabs {
  SIMPLE_LOW_COST = "simpleLowCost",
  TRANSPORTATION = "transportation",
  APPLIANCES = "appliances",
}
