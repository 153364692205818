<scroll-top></scroll-top>
<div class="page-container yt__login_component">
  <div class="flex padding-93-class">
    <div class="flex items-center center login-card yt_maxWidth">
      <div
        class="imageSection w-50-ns w-100 h-100 flex flex-column yt_hideMobile"
      >
        <img
          src="assets/dashboard/login_hero.png"
          width="100%"
          alt="A home having solar on roof and an electric vehicle"
        />
        <div class="yt_tagline-text tc">
          {{ "homeOwenerDashboard.login.heroImage_tagLine" | translate }}
        </div>
      </div>
      <div class="w-50-ns w-100 h-100 justify-center">
        <form [formGroup]="LoginForm" class="form_width center">
          <div class="login_text">{{ "homeOwenerDashboard.login.header" | translate }}</div>
          <div class="loginSection">
            <mat-form-field
              class="w-100"
              hideRequiredMarker="true"
              floatLabel="auto"
              appearance="outline"
            >
              <mat-label>Email</mat-label>
              <input
                matInput
                autocomplete="off"
                formControlName="email"
                type="email"
                autocomplete="off"
              />
            </mat-form-field>
          </div>
          <div class="form_field_padding loginSection">
            <mat-form-field
              class="w-100"
              hideRequiredMarker="true"
              floatLabel="auto"
              appearance="outline"
            >
              <mat-label>Password</mat-label>
              <input
                matInput
                autocomplete="off"
                formControlName="password"
                [type]="hidenewPassword ? 'password' : 'text'"
                autocomplete="off"
              />
              <mat-icon
                matSuffix
                class="mat-icon-color"
                (click)="hidenewPassword = !hidenewPassword"
              >
                {{
                  hidenewPassword ? "visibility_off" : "visibility"
                }}</mat-icon
              >
            </mat-form-field>
            <div class="error_message" *ngIf="loginError">
              Invalid email / password combination.
            </div>
          </div>
          <div class="yt_forgot_password loginSection" style="border: 0">
            <span
              (click)="onForgotpassword()"
              class="cursor-pointer-logo yt__cursor_pointer"
              >{{
                "homeOwenerDashboard.login.forgotPassword" | translate
              }}</span
            >
          </div>
          <div class="w-100 login_button loginSection">
            <button
              mat-raised-button
              [disabled]="LoginForm.invalid"
              color="accent"
              (click)="onSubmit()"
            >
              {{ "homeOwenerDashboard.login.button" | translate }}
            </button>
          </div>
          <div class="w-100 disclaimer_text disclaimer_text_color loginSection">
            <span>
              <span>{{ logMessage }}</span>
              {{ "homeOwenerDashboard.login.tagLine" | translate }}
            </span>
            <a
              class="pointer no-underline yt_term-text_color"
              href="https://www.yellowtin.com/termsofuse/"
              target="_blank"
            >
              {{ "homeOwenerDashboard.login.terms" | translate }}
            </a>
            {{ "homeOwenerDashboard.login.and" | translate }}
            <a
              class="pointer no-underline yt_term-text_color"
              href="https://www.yellowtin.com/privacy_policy/"
              target="_blank"
            >
              {{ "homeOwenerDashboard.login.policy" | translate }}
            </a>
          </div>
          <div class="w-100 tc signup_text">
            {{ "homeOwenerDashboard.login.signupTextBefore" | translate }}
            <a
              [href]="signUpLink"
              target="_blank"
              class="yt_color-label no-underline db db-m di-l"
              >{{ "homeOwenerDashboard.login.signupTextAfter" | translate }}</a
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
