import { Component, OnInit, Input } from "@angular/core";
import { MatRadioChange } from "@angular/material/radio";
import {
  CategoryModel,
  energyEfficientDevicesModel,
  MarketPlaceCategoryListModel,
  MarketPlaceDataModel,
} from "../market-place.model";
import { MarketPlaceService } from "../market-place.service";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";

@Component({
  selector: "yt-efficientDevices-tab",
  templateUrl: "efficientDevices-tab.component.html",
  styleUrls: ["../marketplace-tab.component.scss"],
})
export class EnergyEfficientDevicesComponent implements OnInit {
  energyEfficientCategory: CategoryModel[];
  defaultValue: string;
  defaultIndex: number;
  rewardBonusStatus: boolean;
  @Input() activeTech: string;
  @Input() energyEfficientDevices: energyEfficientDevicesModel;
  @Input() energyEfficientJSON: MarketPlaceCategoryListModel;
  constructor(
    private marketPlaceService: MarketPlaceService,
    private dashboardAPI: DashboardAPIService
  ) {}

  ngOnInit(): void {
    const categories = this.energyEfficientJSON;
    const result = {
      lightingElectricDevices: {},
      homeComfortDevices: {},
      yardCareAppliances: {},
      kitchenAppliances: {},
      waterSavingDevices: {},
    };
    Object.keys(categories).forEach((categoryName: string) => {
      result[categoryName].techImage = categories[categoryName].techImage;
      result[categoryName].viewValue = categories[categoryName].viewValue;
      result[categoryName].value = categories[categoryName].value;
      result[categoryName].techList = [];
      categories[categoryName].techList.forEach(
        (tech: MarketPlaceDataModel) => {
          if (this.energyEfficientDevices.hasOwnProperty(tech.value)) {
            tech["partnerList"] = this.energyEfficientDevices[tech.value];
            result[categoryName].techList.push(tech);
          }
        }
      );
    });
    this.rewardBonusStatus = this.dashboardAPI.rewardBonus;
    this.energyEfficientCategory = Object.values(
      result
    ) as unknown as CategoryModel[];
    this.energyEfficientCategory = this.energyEfficientCategory.filter(
      (tech) => tech.techList.length
    );
    this.energyEfficientCategory.forEach((category) => {
      category.techList.forEach((tech) => {
        if (this.activeTech === tech.value) {
          this.activeTech = category.value;
        }
      });
      this.setDefaultValues(
        this.activeTech || this.energyEfficientCategory[0].value
      );
    });
  }
  setDefaultValues(value: string): void {
    this.defaultValue = value;
    this.defaultIndex = this.energyEfficientCategory.findIndex(
      (category) => category.value === this.defaultValue
    );
    this.marketPlaceService.onTabChange(
      "energyEfficientDevices",
      this.defaultValue
    );
  }

  onTechChange(event: MatRadioChange) {
    this.marketPlaceService.onTabChange("energyEfficientDevices", event.value);
    this.setDefaultValues(event.value);
  }
}
