<div class="w-100 margin__bottom" [formGroup]="hpscForm">
  <div class="energy-choices">
    <div class="yt-text">
      A highly efficient <strong>air-source heat pump</strong>, providing both
      heating and cooling for your home, can help you to significantly cut down
      on your carbon footprint. This especially true if you are replacing a
      natural gas furnace.
    </div>
    <div class="yt_p_top">
      <mat-expansion-panel
        class="cb"
        [expanded]="true"
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="yt__panel-header"
            >Your Choices</mat-panel-title
          >
        </mat-expansion-panel-header>
        <div class="w-100 cf">
          <div class="w-100 w-50-l fl radio-buttons">
            <mat-radio-group
              (change)="condition()"
              formControlName="own"
              class="example-radio-group"
            >
              <div class="yt-Btn-Font">
                Tell us about your air-source heat pump
              </div>
              <div class="radio_text">
                <div class="dib yt_ques-padding">
                  <mat-radio-button value="yes">
                    <span class="yt_option">Already have one</span>
                  </mat-radio-button>
                </div>
                <div class="dib yt_answer-padding-2">
                  <mat-radio-button value="yesPlan">
                    <span class="yt_option">Interested in getting one</span>
                  </mat-radio-button>
                </div>
                <div class="dib yt_answer-padding-2">
                  <mat-radio-button value="no">
                    <span class="yt_option">Not interested</span>
                  </mat-radio-button>
                </div>
              </div>
            </mat-radio-group>
          </div>

          <div class="w-100 w-100-m w-50-l fl yt_paddingtop-42 yt_border-left">
            <div class="yt__f4 yt_pb-7">Home's living space,sq.ft</div>
            <div class="yt__pt0-7">
              <mat-form-field
                appearance="outline"
                hideRequiredMarker="off"
                floatLabel="auto"
              >
                <input
                  matInput
                  type="text"
                  class="quantity"
                  formControlName="sqFoot"
                  required
                  autocomplete="off"
                  placeholder="Enter 200-5000"
                  maxlength="5"
                />
                <mat-error
                  *ngIf="
                    hpscForm.controls['sqFoot'].hasError('min') ||
                    hpscForm.controls['sqFoot'].hasError('max') ||
                    hpscForm.controls['sqFoot'].hasError('pattern')
                  "
                >
                  Please enter 200-5000
                </mat-error>
                <mat-error
                  *ngIf="hpscForm.controls['sqFoot'].hasError('required')"
                >
                  Required!
                </mat-error>
              </mat-form-field>
            </div>
            <div class="yt__f4 yt_pt1-75">Heating Ducts</div>
            <div class="yt__pt0-7">
              <mat-form-field appearance="outline" floatLabel="auto">
                <mat-select placeholder="I have one" formControlName="ducts">
                  <mat-option [value]="duct.value" *ngFor="let duct of ducts">
                    {{ duct.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="yt_usage">
              Current heating system
              <mat-icon
                matTooltip="This information relates to the heating system currently in your home."
                class="h-18"
              >
                help_outline
              </mat-icon>
            </div>
            <div
              class="yt__f4 yt_pt-16"
              [ngStyle]="{
                color:
                  hpscForm.controls['own'].value === 'no'
                    ? '#c7c7c7'
                    : '#333333'
              }"
            >
              Age
            </div>
            <div
              class="yt__pt0-7"
              [ngClass]="{ 'yt__pb-20': !disableDatePicker }"
            >
              <mat-form-field
                appearance="outline"
                floatLabel="auto"
                [ngClass]="{
                  'disable-field': hpscForm.controls['own'].value === 'no'
                }"
              >
                <input
                  matInput
                  type="text"
                  class="quantity"
                  formControlName="age"
                  maxlength="3"
                  placeholder="Enter 0-45"
                  autocomplete="off"
                />
                <mat-error
                  *ngIf="
                    hpscForm.controls['age'].hasError('pattern') ||
                    hpscForm.controls['age'].hasError('min') ||
                    hpscForm.controls['age'].hasError('max')
                  "
                >
                  Please enter 0-45
                </mat-error>
                <mat-error
                  *ngIf="hpscForm.controls['age'].hasError('required')"
                >
                  Required!
                </mat-error>
              </mat-form-field>
            </div>
            <div
              [ngStyle]="{
                visibility:
                  hpscForm.controls['own'].value === 'yes'
                    ? 'hidden'
                    : 'visible'
              }"
            >
              <div
                class="yt__f4 yt_pt-16"
                [ngStyle]="{
                  color:
                    hpscForm.controls['own'].value === 'no'
                      ? '#c7c7c7'
                      : '#333333'
                }"
              >
                Type
              </div>
              <div class="yt__pt0-7">
                <mat-form-field
                  appearance="outline"
                  floatLabel="auto"
                  [ngClass]="{
                    'disable-field':
                      hpscForm.controls['own'].value !== 'yesPlan' ||
                      hpscForm.controls['own'].value === 'yes'
                  }"
                  hideRequiredMarker="true"
                >
                  <mat-select
                    placeholder="Current heating system type"
                    formControlName="hpscType"
                  >
                    <mat-option
                      [value]="heater.value"
                      *ngFor="let heater of currentHeaterCooler"
                    >
                      {{ heater.viewValue }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="hpscForm.controls['hpscType'].hasError('required')"
                  >
                    Required!
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="disableDatePicker">
              <div
                class="yt__f4 yt_pt1-75"
                [ngStyle]="{
                  color:
                    hpscForm.controls['own'].value === 'no' ||
                    hpscForm.controls['own'].value === 'yesPlan'
                      ? '#c7c7c7'
                      : '#333333'
                }"
              >
                Purchased month & year
              </div>
              <div class="yt__pb-20 yt__pt0-7">
                <mat-form-field
                  appearance="outline"
                  floatLabel="auto"
                  [ngClass]="{
                    'disable-field':
                      hpscForm.controls['own'].value === 'no' ||
                      hpscForm.controls['own'].value === 'yesPlan'
                  }"
                >
                  <mat-select
                    matInput
                    placeholder="Select"
                    formControlName="datePurchased"
                  >
                    <mat-option *ngFor="let month of monthList" [value]="month"
                      >{{ month | titlecase }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div
                  class="cb w-100 yt_error-message"
                  *ngIf="
                    isDatePurchasedEmpty() &&
                    hpscForm.controls['datePurchased'].touched
                  "
                >
                  Required!
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
  </div>

  <div class="pt_30 w-100">
    <button
      mat-raised-button
      color="accent"
      (click)="onSaveHPSC()"
      [disabled]="hpscForm.invalid || isDatePurchasedEmpty()"
      class="fr w-100 yt__mb-12"
    >
      SAVE
    </button>
  </div>
</div>
