import {
  AfterContentChecked,
  Component,
  HostListener,
  Inject,
  OnInit,
} from "@angular/core";
import { DashboardAPIService } from "../../../../rest-api/dashboard/dashboard-api.service";
import { DropDownModel } from "../../../../rest-api/rest-api.model";
import {
  HomeSettingDataModel,
  HomeSettingModel,
  SettingsDialogData,
} from "./setting-overlay.component.model";
import {
  AppliancesDataModel,
  SolarBatteryDataModel,
  TransportationDataModel,
  UserDataModel,
} from "../../model/userdata.model";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RestAPIService } from "../../../../rest-api/rest-api.service";
import { EnergyInfoService } from "./general/energy-info/energy-info.service";
import {
  EnergyStatusModel,
  UtilityProviderModel,
} from "../../../../shared/energy-info/energy-info.model";
import { SettingsOverlayService } from "./settings-overlay.service";

@Component({
  selector: "yt-setting-overlay",
  templateUrl: "./setting-overlay.component.html",
  styleUrls: ["./setting-overlay.component.scss"],
})
export class SettingOverlayComponent implements OnInit, AfterContentChecked {
  activeTech: string;
  activeIcon: string;
  tabIndex: number = 0;
  loader: boolean = true;
  tabLabel: string = "general";
  showScrollButton: boolean = false;
  homeSettingTabsList: DropDownModel[];
  homeSettingTechDetails: UserDataModel;
  transportationTechDetails: TransportationDataModel;
  solarBatteryTechDetails: SolarBatteryDataModel;
  appliancesTechDetails: AppliancesDataModel;
  transportationJSONDetails: HomeSettingDataModel[];
  solarBatteryJSONDetails: HomeSettingDataModel[];
  appliancesJSONDetails: HomeSettingDataModel[];
  settingJSONDetails: HomeSettingModel;
  haveResponse: boolean = false;
  settingStatus: boolean;
  tabHeaderElem: HTMLDivElement;
  scrollOffSet: number = 200;
  validationStatus: boolean = false;
  showSavingLoader: boolean;

  constructor(
    private dashboardAPIService: DashboardAPIService,
    private restAPIService: RestAPIService,
    private dialogRef: MatDialogRef<SettingOverlayComponent>,
    private energyInfoService: EnergyInfoService,
    private settingService: SettingsOverlayService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.homeSettingTabsList = this.dashboardAPIService.homeSettingTabsList;
  }

  ngAfterContentChecked(): void {
    this.validationStatus = this.settingService.errorMessage;
  }

  @HostListener("document:keydown", ["$event"])
  onKeyDown(event) {
    if (event.key == "Escape") {
      if (
        !!Object.keys(this.settingService.settingsInfo).length ||
        !!Object.keys(this.settingService.settingsInfoErrors).length
      ) {
        this.settingStatus = true;
      } else {
        this.dialogRef.disableClose = false;
        this.dialogRef.close();
        this.settingService.settingsInfo = {};
      }
    }
  }

  ngOnInit(): void {
    this.settingService.showSavingLoader.subscribe((status) => {
      this.showSavingLoader = status;
    })
    this.validationStatus = this.settingService.errorMessage;
    this.dashboardAPIService
      .homeSettingTechDetails()
      .subscribe((response: HomeSettingModel) => {
        this.homeSettingTechDetails =
          this.dashboardAPIService.energyChoicesData;
        this.getTabList();
        this.activeTech = this.data.tech;
        this.activeIcon = this.data.icon;

        this.transportationTechDetails =
          this.homeSettingTechDetails.transportation;
        this.solarBatteryTechDetails = this.homeSettingTechDetails.solarBattery;
        this.appliancesTechDetails = this.homeSettingTechDetails.appliances;
        this.settingJSONDetails = response;
        this.transportationJSONDetails = this.settingJSONDetails.transportation;
        this.solarBatteryJSONDetails = this.settingJSONDetails.solarBattery;
        this.appliancesJSONDetails = this.settingJSONDetails.appliances;
        this.restAPIService.updateMakeAndModel();

        this.restAPIService
          .getUtilityProvider()
          .subscribe((response: UtilityProviderModel) => {
            this.energyInfoService.utilityProviderResponse = response;

            this.restAPIService.energyStatusAPI.subscribe(
              (response: EnergyStatusModel) => {
                this.energyInfoService.energyInfoStatusResponse = response;
                this.haveResponse = true;
                if (
                  this.energyInfoService.energyInfoStatusResponse &&
                  this.energyInfoService.utilityProviderResponse
                ) {
                  this.loader = false;
                  this.tabHeaderElem =
                    document.querySelector<HTMLDivElement>(".tab-headers")!;
                  this.onResize();
                }
              }
            );
          });
      });
  }

  tabChanged(textLabel: string, index: number): void {
    this.activeTech = "";
    this.activeIcon = "";
    this.tabLabel = textLabel;
    this.tabIndex = index;
  }

  checkTabStatus(event: string): boolean {
    return event === this.tabLabel;
  }

  emitTech(value: SettingsDialogData): void {
    if (!this.validationStatus) {
      this.dialogRef.close(value);
    }
  }

  getTabList(): void {
    let responseTabList: string[] = [];
    Object.entries(this.homeSettingTechDetails).forEach(
      (tab: [string, UserDataModel]) => {
        if (Object.entries(tab[1]).length) {
          responseTabList.push(tab[0]);
        }
      }
    );
    this.homeSettingTabsList =
      this.dashboardAPIService.homeSettingTabsList.filter(
        (tab: DropDownModel) => responseTabList.includes(tab.value)
      );

    this.tabLabel = this.data.tabLabel || this.homeSettingTabsList[0].value;
    this.tabIndex = this.homeSettingTabsList.findIndex(
      (tab) => tab.value === this.tabLabel
    );
  }

  onClose(): void {
    if (
      !!Object.keys(this.settingService.settingsInfo).length ||
      !!Object.keys(this.settingService.settingsInfoErrors).length
    ) {
      this.settingStatus = true;
    } else {
      this.dialogRef.close();
    }
  }

  onSelectedNo(): void {
    this.settingStatus = false;
    this.dialogRef.disableClose = false;
    this.dialogRef.close();
    this.settingService.errorMessage = false;
    this.settingService.settingsInfoErrors = {};
  }

  onSelectedYes(): void {
    this.settingService.validationMessage();
    if (!this.settingService.errorMessage) {
      this.settingService.putSettingsInfo(this.closeOverlay);
    } else {
      this.settingStatus = false;
    }
  }

  closeOverlay = () => {
    this.settingStatus = false;
    this.dialogRef.disableClose = false;
    this.settingService.errorMessage = false;
    this.dialogRef.close();
  };

  handleScroll(button: string): void {
    let left = this.tabHeaderElem.scrollLeft;
    if (button === "prev") {
      left -= this.scrollOffSet;
    } else if (button === "next") {
      left += this.scrollOffSet;
    }
    this.tabHeaderElem.scrollTo({ left, behavior: "smooth" });
  }

  onResize(): void {
    this.showScrollButton =
      this.tabHeaderElem.scrollWidth > this.tabHeaderElem.clientWidth;
  }

  isButtonDisabled(button: string): boolean {
    if (button === "next") {
      return (
        this.tabHeaderElem.scrollLeft + this.tabHeaderElem.clientWidth ===
        this.tabHeaderElem.scrollWidth
      );
    } else {
      return this.tabHeaderElem.scrollLeft === 0;
    }
  }
}
