<scroll-top></scroll-top>
<div class="page-container yt__learn_more">
  <yt-back [navigateTo]="'overview'"></yt-back>
  <div class="padding-back-button w-100 center">
    <div class="yt_maxWidth w-100 center">
      <div class="header_text tc">LED Bulbs</div>
      <yt-scroll-link-toggle
        *ngIf="!isLangSpanish"
        [activeLink]="selectedSection"
        [isAboutTech]="true"
        [isSavings]="false"
        [isDescription]="true"
        [isSettings]="false"
        [isDetails]="false"
        [isAssumptions]="true"
        [isFaq]="true"
        [techParameter]="'ledCflBulbs'"
        (activeValue)="handleActiveValue($event)"
      ></yt-scroll-link-toggle>

      <div>
        <div
          class="yt-text"
          [innerHTML]="
            'homeOwenerDashboard.learnMore.ledCflBulbs.paragraph' | translate
          "
        ></div>
        <div *ngIf="!isLangSpanish" id="aboutTechnology">
          <yt-led-bulbs-FAQ [showAboutTech]="true"></yt-led-bulbs-FAQ>
        </div>
        <div class="faq_mobile">
          <button
            (click)="onFavorite()"
            class="favorite_btn"
            mat-raised-button
            color="accent"
          >
            <mat-icon
              class="favorite_icon"
              [ngClass]="{ favorite_icon_color: favStatus }"
              >{{ favIcon }}</mat-icon
            >FAVORITE
          </button>
          <button
            *ngIf="isLangSpanish"
            class="faq_button"
            mat-raised-button
            color="accent"
            (click)="handleFAQClick()"
          >
            MORE INFO
          </button>
        </div>
      </div>
      <div class="top_section w-100">
        <div class="faq_section">
          <div class="image_box">
            <img
              src="/assets/lifestyle/LED.png"
              alt="LED/CFL Bulbs"
              [ngStyle]="{ height: !displayMarketPlace ? '160px' : '250px' }"
            />
          </div>
          <button
            (click)="onFavorite()"
            class="mt_20 favorite_btn"
            mat-raised-button
            color="accent"
          >
            <mat-icon
              class="favorite_icon"
              [ngClass]="{ favorite_icon_color: favStatus }"
              >{{ favIcon }}</mat-icon
            >FAVORITE
          </button>
          <button
            *ngIf="isLangSpanish"
            class="mt_20 faq_button"
            mat-raised-button
            color="accent"
            (click)="handleFAQClick()"
          >
            MORE INFO
          </button>
        </div>

        <div class="width-box" id="cost">
          <div class="main_values_box">
            <div class="details_header pb_14">Cost</div>
            <div class="top_values">
              <div class="main_value_text" *ngIf="LEDViewDetails">
                ${{ financialDetails.costDetails.netCost | number }}
                <div class="value_text">
                  <span
                    class="tooltip_text pointer"
                    matTooltip="Net cost = Upfront cost - Incentives"
                    >Net cost</span
                  >
                </div>
              </div>
            </div>
            <div class="bottom_values">
              <div class="left_bottom_value">
                <div class="value" *ngIf="LEDViewDetails">
                  ${{ financialDetails.costDetails.upfrontCost | number }}
                </div>
                <div class="value_label">
                  <span
                    class="tooltip_text pointer"
                    matTooltip="Includes cost of bulbs."
                    >Upfront cost</span
                  >
                </div>
              </div>
              <div class="right_bottom_value">
                <div class="value" *ngIf="LEDViewDetails">
                  ${{ financialDetails.costDetails.incentives | number }}
                </div>
                <div class="value_label">
                  <mat-icon class="hidden_tooltip">help_outline</mat-icon>
                  <span
                    *ngIf="incentiveStatus"
                    class="link_text pointer"
                    (click)="showIncentiveBreakdown = true"
                    >Incentives</span
                  >
                  <span *ngIf="!incentiveStatus">Incentives </span>
                  <mat-icon
                    class="tooltip_icon pointer"
                    matTooltip="Available tax credits and rebates."
                  >
                    help_outline
                  </mat-icon>
                </div>
              </div>
            </div>
          </div>
          <yt-market-place
            [tech_name]="'ledCflBulbs'"
            [linkId]="linkId"
            [marketPlaceDetails]="marketplaceDetails"
            *ngIf="marketplaceDetails && displayMarketPlace"
          ></yt-market-place>
          <div class="main_values_box advice_box" *ngIf="adviceCardStatus">
            <yt-electrification-advice-card
              [tech]="'ledCflBulbs'"
            ></yt-electrification-advice-card>
          </div>
        </div>
      </div>

      <div class="battery_bottom_section w-100">
        <div class="left_section">
          <div class="highChart_card highChart_card_mobile">
            <div class="details_header">DESCRIPTION</div>
            <div class="yt_text">
              <span [innerHTML]="ledDescription"></span>
              <span
                class="read_link"
                *ngIf="readMore"
                (click)="onReadContent('readMore')"
              >
                Read more...
              </span>
              <span
                class="read_link yt_read_less"
                *ngIf="readLess"
                (click)="onReadContent('readLess')"
              >
                Read less
              </span>
            </div>
          </div>
          <yt-assumption-card
            [assumptionDetails]="assumptionDetails"
            [showHelpMeChoose]="false"
          ></yt-assumption-card>
        </div>

        <div class="right_section">
          <div class="w-100 highChart_card" id="description">
            <div class="details_header">DESCRIPTION</div>
            <div class="yt_text">
              <span [innerHTML]="ledDescription"></span>
              <span
                class="read_link"
                *ngIf="readMore"
                (click)="onReadContent('readMore')"
              >
                Read more...
              </span>
              <span
                class="read_link yt_read_less"
                *ngIf="readLess"
                (click)="onReadContent('readLess')"
                >Read less</span
              >
            </div>
          </div>

          <div class="faq_card w-100" id="faq" *ngIf="!isLangSpanish">
            <mat-expansion-panel
              (closed)="isFAQExpanded = false"
              [expanded]="isFAQExpanded"
              hideToggle="true"
            >
              <mat-expansion-panel-header (click)="faqExpansionClick()">
                <mat-panel-title class="flex items-center">
                  Frequently Asked Questions &nbsp;
                  <mat-icon class="pointer mat_color">
                    {{
                      !icon ? "keyboard_arrow_down" : "keyboard_arrow_up"
                    }}</mat-icon
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel_body w-100">
                <yt-customized-tab
                  [tabHeadersList]="FAQTabsList"
                  (tabValue)="tabChanged($event)"
                ></yt-customized-tab>
                <yt-led-bulbs-FAQ
                  [activeIndex]="tabIndex"
                  [showAboutTech]="false"
                ></yt-led-bulbs-FAQ>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </div>
      <div
        class="link_text back_to_top"
        (click)="scrollToTop()"
        *ngIf="!isLangSpanish"
      >
        <mat-icon class="pointer mat_color">keyboard_arrow_up</mat-icon>
        <span class="pointer">Back to Top</span>
      </div>
      <div class="disclaimer_text" [ngClass]="{ pt_70: isLangSpanish }">
        The results and projections are estimates based on available data.
      </div>
    </div>
  </div>
</div>

<!-- Incentives Breakdown Dialogue box-->
<yt-breakdown-popup
  *ngIf="showIncentiveBreakdown"
  [heading]="'Incentive Breakdown'"
  [netCost]="'Total'"
  (closeIncentiveBreakdown)="showIncentiveBreakdown = false"
  [techName]="'ledCflBulbs'"
>
</yt-breakdown-popup>

<!-- loader -->
<yt-loader *ngIf="loader"></yt-loader>
