import { Component, Input } from "@angular/core";

@Component({
  selector: "yt-grid-savvy",
  templateUrl: "./grid-savvy.component.html",
  styleUrls: ["./grid-savvy.component.scss"],
})
export class GridSavvyComponent {
  @Input() logoURL: string;
}
