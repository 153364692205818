import { Component, Input } from "@angular/core";

@Component({
  selector: "yt-radio-buttons",
  templateUrl: "radio-buttons.component.html",
  styleUrls: ["./radio-buttons.component.scss"],
})
export class RadioButtonsComponent {
  @Input() viewValue: string;
  @Input() icon: string;
  @Input() default: string;
  @Input() value: string;
}
