import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeOwnerInfoComponent } from "./homeowner-info.component";

const routes: Routes = [
  {
    path: "",
    component: HomeOwnerInfoComponent,
    data:{title: 'Register'}
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HPInfoRoutingModule {}
