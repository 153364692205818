<div class="container">
  <div class="header">GridSavvy Rewards</div>
  <div class="text_content">
    <div class="image_section"><img [src]="logoURL" width="100%" /></div>
    <div class="text_info">
      Get paid for helping us power the electric grid with more local, clean
      energy. Visit our
      <a
        href="https://sonomacleanpower.org/programs/gridsavvyrewards"
        target="_blank"
        class="alert_link_text"
        >GridSavvy Rewards</a
      >
      page to learn more about our incentive offers!
    </div>
  </div>
</div>
