import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { MatRadioChange } from "@angular/material/radio";
import { DashboardAPIService } from "../../../../../rest-api/dashboard/dashboard-api.service";
import { AppliancesDataModel } from "../../../model/userdata.model";
import {
  HomeSettingDataModel,
  SettingsDialogData,
} from "../setting-overlay.component.model";

@Component({
  selector: "yt-appliances",
  templateUrl: "appliances.component.html",
  styleUrls: ["../common-setting-overlay.scss"],
})
export class AppliancesComponent implements OnInit {
  displayAppliances: HomeSettingDataModel[] = [];
  defaultValue: string;
  buildingType: string;
  @Input() activeTech: string;
  @Input() activeIcon: string;
  @Input() appliancesTechDetails: AppliancesDataModel;
  @Input() appliancesJSONDetails: HomeSettingDataModel[];
  @Output() techSave: EventEmitter<SettingsDialogData> = new EventEmitter();

  constructor(private dashboardAPIService: DashboardAPIService) {}

  ngOnInit(): void {
    this.buildingType = this.dashboardAPIService.buildType;
    Object.keys(this.appliancesTechDetails).forEach((tech) =>
      this.appliancesJSONDetails.find((appliances) => {
        if (tech === appliances.value) {
          const temp = appliances;
          if (temp.value === "portableIC" || temp.value === "ic") {
            temp.viewValue =
              this.dashboardAPIService.icType === "standard"
                ? "Induction Cooking"
                : "Portable Cooktop";
          }
          this.displayAppliances.push(temp);
        }
      })
    );
    this.setDefaultValues(this.activeTech || this.displayAppliances[0].value);
  }

  setDefaultValues(value: string): void {
    this.defaultValue = value === "portableIC" ? "ic" : value;
  }

  onTechChange(event: MatRadioChange): void {
    this.activeIcon = "";
    this.setDefaultValues(event.value);
  }

  emitTech(value: SettingsDialogData): void {
    this.techSave.emit(value);
  }
}
