<yt-side-nav
  [routeInfo]="routeInfo"
  [hideSidenav]="hideSidenav"
  [smdLink]="smdLink"
  [logo]="logoModel"
  [dashboardlogo]="dashboardlogoModel"
  [jwtToken]="jwtTokenInfo"
  [address]="address"
>
  <ng-content select="[content]"></ng-content>
</yt-side-nav>
