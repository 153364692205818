import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DashboardAPIService } from "../../../../../rest-api/dashboard/dashboard-api.service";
import { DropDownModel } from "../../../../../rest-api/rest-api.model";
import { GeneralDataModel } from "../../../model/userdata.model";
import { SettingsOverlayService } from "../settings-overlay.service";
import { Subject } from "rxjs";

@Component({
  selector: "yt-general",
  templateUrl: "general.component.html",
  styleUrls: ["../common-setting-overlay.scss", "../common-tech.scss"],
})
export class GeneralComponent implements OnInit {
  vendorCode: string;
  utilityName: string;
  avgMonthlyBill: string;
  subject: Subject<any> = new Subject();
  storiesRange: DropDownModel[] = [
    {
      value: "1",
      viewValue: "1",
    },
    {
      value: "2",
      viewValue: "2",
    },
    {
      value: "3",
      viewValue: "3 or more",
    },
  ];
  everGreenStatus: boolean = false;
  showEverGreen: boolean = false;
  generalForm: FormGroup;
  generalSettings: GeneralDataModel;
  smdStatus: boolean;

  constructor(
    private fb: FormBuilder,
    private dashboardAPI: DashboardAPIService,
    private settingService: SettingsOverlayService
  ) {
    this.generalForm = this.fb.group({
      howManyPeople: [
        "3",
        [
          Validators.required,
          Validators.pattern("^([1-9]|10)$"),
          Validators.min(1),
          Validators.max(12),
        ],
      ],
      sqfoot: [
        "1600",
        [
          Validators.required,
          Validators.pattern("^([2-9][0-9][0-9]|[0-5][0-9][0-9][0-9]|)$"),
          Validators.min(200),
          Validators.max(5000),
        ],
      ],
      stories: ["1", [Validators.required]],
      incentives: [false],
    });
  }

  ngOnInit(): void {
    this.vendorCode = this.dashboardAPI.getUserDetails().solarVendorCode;
    const generalDetails = this.settingService.checkSettingsInfoError("general")
      ? this.settingService.settingsInfoErrors.general
      : this.settingService.checkSettingsInfo("general")
      ? this.settingService.settingsInfo.general
      : this.dashboardAPI.energyChoicesData.general;
    this.generalForm.get("howManyPeople")?.setValue(generalDetails?.noOfPeople);
    this.generalForm.get("sqfoot")?.setValue(generalDetails?.livingSpace);
    this.smdStatus = generalDetails?.energyInfo.showSMDTab!;
    this.generalForm
      .get("incentives")
      ?.setValue(generalDetails?.incentivesCAREFERA);
    this.utilityName = generalDetails?.energyInfo.elecUtilityName!;
    this.generalForm.get("stories")?.setValue(generalDetails?.stories);
    this.avgMonthlyBill = generalDetails?.energyInfo.avgMonthlyBill!;
    this.onValueChanges();
  }

  onUpdateEverGreenStatus(value: boolean): void {
    this.everGreenStatus = value;
  }

  onAvgMonthlyBill(bill: string) {
    this.avgMonthlyBill = bill;
    this.updateGeneralSettings();
  }

  onValueChanges(): void {
    this.generalForm.valueChanges.subscribe((data) => {
      this.subject.pipe().subscribe(() => {
        this.updateGeneralSettings(data);
      });
      this.subject.next();
    });
  }

  updateGeneralSettings(formChanges?): void {
    let noOfPeople, livingSpace, stories, incentivesCAREFERA;
    if (formChanges) {
      noOfPeople = formChanges.howManyPeople;
      livingSpace = formChanges.sqfoot;
      stories = formChanges.stories;
      incentivesCAREFERA = formChanges.incentives;
    } else {
      noOfPeople = this.generalForm.get("howManyPeople")?.value;
      livingSpace = this.generalForm.get("sqfoot")?.value;
      stories = this.generalForm.get("stories")?.value;
      incentivesCAREFERA = this.generalForm.get("incentives")?.value;
    }

    this.generalSettings = {
      noOfPeople,
      livingSpace,
      stories,
      incentivesCAREFERA,
      energyInfo: {
        avgMonthlyBill: this.avgMonthlyBill,
        elecUtilityId:
          this.dashboardAPI.energyChoicesData.general.energyInfo.elecUtilityId,
        elecUtilityName:
          this.dashboardAPI.energyChoicesData.general.energyInfo
            .elecUtilityName,
        showSMDTab:
          this.dashboardAPI.energyChoicesData.general.energyInfo.showSMDTab,
      },
    };
    if (this.generalForm.valid) {
      this.settingService.updateSettingsInfo(
        this.dashboardAPI.energyChoicesData.general,
        this.generalSettings,
        "general"
      );
    } else {
      this.settingService.updateSettingsInfoErrors(
        this.dashboardAPI.energyChoicesData.general,
        this.generalSettings,
        "general"
      );
    }
  }

  onSaveGeneral(): void {
    this.settingService.putSettingsInfo();
  }
}
