<div class="page_container">
  <div class="yt_width">
    <div *ngIf="roofTopPageModel">
      <div class="yt__info-header yt_header-margin-top">
        Tell us about your solar status &nbsp;
        <span
          class="renterText"
          (click)="openCondoRenterPopup()"
          *ngIf="showPopup"
        >
          Is this applicable to me?
        </span>
      </div>
      <div id="1" *ngIf="roofTopPageModel.solar" class="pointer">
        <yt-roof-QA
          [imagePath]="'assets/roof-top-imagesv2/solar/'"
          [defaultValue]="roofTopDetails[0].own"
          [buildingTypeDisplayModel]="roofTopPageModel.solar"
          (buldingTypeSelect)="onBuldingTypeQuestion($event)"
        >
        </yt-roof-QA>
      </div>
      <div *ngIf="roofAgeQuestion">
        <div class="yt__info-header yt_header-margin">
          Tell us the condition of your roof
        </div>
        <div
          id="2"
          data-scroll-to="3"
          [ngClass]="roofAgeQuestion ? 'yt__visible' : 'yt__hidden'"
          class="pointer"
        >
          <yt-roof-QA
            [imagePath]="'assets/roof-top-imagesv2/roofCondition/'"
            [defaultValue]="roofTopDetails[0].roofAge"
            [buildingTypeDisplayModel]="roofTopPageModel.roofAges"
            (buldingTypeSelect)="onRoofAgeQuestion($event)"
          >
          </yt-roof-QA>
        </div>
        <div
          id="3"
          data-scroll-to="4"
          [ngClass]="planForReRoofQuestion ? 'yt__visible' : 'yt__hidden'"
        >
          <yt-roof-QA
            [header]="'Planning to re-roof before going solar'"
            [imagePath]="'assets/roof-top-imagesv2/reRoof/'"
            [defaultValue]="roofTopDetails[0].roofReplace"
            [buildingTypeDisplayModel]="roofTopPageModel.planForReRoof"
            (buldingTypeSelect)="onPlanForReRoofQuestion($event)"
          >
          </yt-roof-QA>
        </div>
        <div
          id="4"
          data-scroll-to="5"
          [ngClass]="roofSlopeQuestion ? 'yt__visible' : 'yt__hidden'"
        >
          <yt-roof-QA
            [header]="'Tell us the slope of your roof'"
            [imagePath]="'assets/roof-top-imagesv2/roofSlope/'"
            [defaultValue]="roofTopDetails[0].roofSlope"
            [buildingTypeDisplayModel]="roofTopPageModel.roofSlopes"
            (buldingTypeSelect)="onRoofSlopeQuestion($event)"
          >
          </yt-roof-QA>
        </div>
        <div
          id="5"
          data-scroll-to="6"
          [ngClass]="roofShadeQuestion ? 'yt__visible' : 'yt__hidden'"
        >
          <yt-roof-shade-QA
            [header]="'Tell us the shade on your roof'"
            [imagePath]="'assets/roof-top-imagesv2/roofShade/'"
            [defaultValue]="roofTopDetails[0].roofShade"
            [buildingTypeDisplayModel]="roofTopPageModel.roofShades"
            (buldingTypeSelect)="onRoofShadeQuestion($event)"
          >
          </yt-roof-shade-QA>
        </div>
        <div
          id="6"
          data-scroll-to="7"
          [ngClass]="roofTypeQuestion ? 'yt__visible' : 'yt__hidden'"
        >
          <yt-roof-type-QA
            [header]="'Tell us the type of your roof'"
            [imagePath]="'assets/roof-top-imagesv2/roofType/'"
            [selectedValue]="roofTopDetails[0].roofType"
            [roofTypeList]="roofTopPageModel.roofTypes"
            (roofTypeSelect)="onRoofTypeQuestion($event)"
          >
          </yt-roof-type-QA>
        </div>
      </div>

      <div id="7" class="w-100 tr continue_button" *ngIf="roofAgeQuestion">
        <button
          mat-raised-button
          color="accent"
          (click)="onSaveSolar()"
          [disabled]="roofTopQAForm.invalid && !continueWithoutSolar"
        >
          SAVE
        </button>
      </div>
      <div *ngIf="!roofAgeQuestion" class="w-100 tr continue_button_solar">
        <button
          mat-raised-button
          color="accent"
          (click)="onSaveSolar()"
          [disabled]="roofTopQAForm.invalid && !continueWithoutSolar"
        >
          SAVE
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="CondoRenterPopup"
  *ngIf="showCondoRenterPopup"
  #canInstallSolarPopup
>
  <div id="text">
    <div class="yt_popup-header padding_all">Can I install solar?</div>
    <div *ngIf="buildingTypeRenter" class="padding_all">
      <div>
        Installing solar at a rental property can benefit both renters and
        landlords but requires the support of the landlord and may be more
        challenging than installing solar on a single-family home.
      </div>
      <div class="pt3">
        If you wish to understand the impact of solar as it applies to a typical
        single-family home, click "Interested".
      </div>
    </div>
    <div *ngIf="buildingTypeCondo" class="padding_all">
      <div>
        Installing solar in a condominium or mobile home can provide benefits,
        but may be more challenging than installing solar on a single-family
        home.
      </div>
      <div class="pt3">
        If you wish to understand the impact of solar as it applies to a typical
        single-family home, click "Interested".
      </div>
    </div>
  </div>
</div>
