import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  AfterContentChecked,
  ChangeDetectorRef,
} from "@angular/core";
import { Router } from "@angular/router";
import * as Highcharts from "highcharts";
import { MatDialog } from "@angular/material/dialog";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { TimeFrameEnum } from "../learn-more.enum";
import { LearnMoreService } from "../learn-more.service";
import {
  MarketPlaceDetailsModel,
  LearnMoreRequestModel,
  BarChartDataModel,
} from "../learn-more.model";
import {
  MNFAndModelListResponse,
  MNFAndModel,
} from "../../../homeowner-product/energy-choices/energy-choices.model";
import {
  EVDetailsModel,
  EVFinancialModel,
  EVTechnicalModel,
  EVLearnMoreModel,
  EVAssumptionModel,
  EVLeaseModel,
  EVLoanModel,
  EVCashModel,
  DollarSavingsModel,
} from "./ev-details.model";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";
import { HelpMeChooseComponent } from "../../shared/help-me-choose/help-me-choose.component";
import { RestAPIService } from "../../../rest-api/rest-api.service";
import { Utils } from "../../../utils/util";
import { DropDownModel } from "../../../rest-api/rest-api.model";
import { AppService } from "../../../app.service";
import { SectionListModel } from "../../../rest-api/dashboard/dashboard-api.model";
import { OverviewService } from "../../overview/overview.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "yt-ev1-details",
  templateUrl: "ev1-details.component.html",
  styleUrls: ["../common-learn-more.scss"],
})
export class EV1DetailsComponent implements OnInit, AfterContentChecked {
  showHelpMeChoose: boolean;
  evSavings: number;
  carType: string;
  showCard = false;
  icon = false;
  icon1 = false;
  showAnnualMilesPopup = false;
  selected: TimeFrameEnum;
  yAxisLabel: string;
  yAxisLabel1: string;
  panelOpenState = false;
  electricViewDetails: EVDetailsModel;
  financialDetails: EVFinancialModel;
  technicalDetails: EVTechnicalModel;
  learnMoreDetails: EVLearnMoreModel;
  assumptionDetails: EVAssumptionModel;
  marketPlaceDetails: MarketPlaceDetailsModel;
  evCash: EVCashModel;
  evLoan: EVLoanModel;
  evLease: EVLeaseModel;
  twentyYear: boolean;
  oneYear: boolean;
  oneMonth: boolean;
  showCash: boolean;
  showLoan: boolean;
  showLease: boolean;
  chartOptions1: Highcharts.Options = {};
  showIncentivesStatus: boolean;
  showTooltipZero: boolean;
  evForm: FormGroup;
  charges: string[];
  totalIncentives: number;
  loader = true;
  financial = true;
  haveResponse = false;
  financeValue: string;
  displayMarketPlaceBox: boolean;
  marketPlaceLink: string;
  buildType: boolean;
  showIncentiveBreakdown = false;
  showCompareFinancial = false;
  showEVChargersLink: boolean;
  vendorName: string;
  linkId: string;
  makeAndModelList: MNFAndModel[];
  financeList: DropDownModel[];
  timeFrame: string;
  adviceCardStatus: boolean;
  timeSegmentList: DropDownModel[];
  activeIndex: number = 0;
  tabLabel: string;
  tabIndex: number = 0;
  isLangSpanish: boolean;
  selectedSection: string = "aboutTechnology";
  FAQTabsList: DropDownModel[];
  paymentTypeLabel: string;
  isTechnicalExpanded: boolean;
  isFAQExpanded: boolean;
  favStatus: boolean;
  favIcon: string;
  assumptionLabel: string;
  financeEvSavings: DollarSavingsModel;
  @ViewChild("container", { read: ElementRef }) container: ElementRef;
  @ViewChild("loaderButton", { read: ElementRef }) saveButton: ElementRef;

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private fb: FormBuilder,
    private utils: Utils,
    private learnMoreService: LearnMoreService,
    private dashboardService: DashboardAPIService,
    private overviewService: OverviewService,
    private restAPIService: RestAPIService,
    private cdRef: ChangeDetectorRef,
    private appService: AppService
  ) {
    this.financeList = this.learnMoreService.financeList;
    this.timeSegmentList = this.learnMoreService.timeSegmentList;
    this.evForm = this.fb.group({
      own: "yesPlan",
      model: ["Nissan Leaf S", Validators.required],
      annualMiles: [
        "15000",
        [
          Validators.required,
          Validators.pattern(
            "^([0-9][0-9][0-9][0-9]|[0-4][0-9][0-9][0-9][0-9]|)$"
          ),
          Validators.min(1000),
          Validators.max(40000),
        ],
      ],
      charging: ["100", Validators.required],
      finance: ["cash", Validators.required],
      bought: [""],
      datePurchased: [""],
    });
  }

  ngAfterContentChecked(): void {
    this.isLangSpanish = this.appService.selectedLanguage.value === "sp";
    this.cdRef.detectChanges(); // TO avoid expression checking error in console NG100
  }

  ngOnInit(): void {
    this.vendorName = this.dashboardService.getUserDetails().solarVendorCode;
    this.dashboardService.faqDetails().subscribe((list: SectionListModel) => {
      this.FAQTabsList = list.ev.faqList!;
    });
    this.showHelpMeChoose =
      this.dashboardService.vendorDetails.helpMeChoose.ev!;
    this.charges = this.utils.chargesList;
    this.setTimeSelector(this.dashboardService.timeFrame);
    this.getVehicleViewDetails(this.selected);
    this.getEvMakeAndModelList();
    this.restAPIService
      .getMakeAndModelList("ev1")
      .subscribe((response: MNFAndModelListResponse) => {
        this.makeAndModelList = response.mnfDropDownList;
      });
    this.marketPlaceAPI();
    this.buildType = this.overviewService.updateEnergyChoiceInfo(
      this.dashboardService.buildType
    );
    this.showEVChargersLink = !this.buildType;
  }

  isMNFAndModelListAbsent(): boolean {
    if (!this.makeAndModelList) return true;
    return this.makeAndModelList.length === 0;
  }

  getEvMakeAndModelList() {
    this.restAPIService
      .getMakeAndModelList("ev1")
      .subscribe(
        (response: MNFAndModelListResponse) =>
          (this.makeAndModelList = response.mnfDropDownList)
      );
  }

  marketPlaceAPI(): void {
    this.dashboardService
      .getMarketplaceDetails("ev1")
      .subscribe((response: MarketPlaceDetailsModel) => {
        this.marketPlaceDetails = response;
        this.adviceCardStatus = response.expertAdvice;
        this.displayMarketPlaceBox = this.marketPlaceDetails.displayBox;
        this.marketPlaceLink = this.marketPlaceDetails.buttonLink;
        if (this.marketPlaceDetails.linkId === "") {
          this.linkId = "";
        } else {
          this.linkId = this.marketPlaceDetails.linkId;
        }
      });
  }

  handleActiveValue(data: string): void {
    if (data === "details") {
      this.isTechnicalExpanded = true;
      this.icon = true;
    } else if (data === "faq") {
      this.isFAQExpanded = true;
      this.icon1 = true;
    }
  }

  expansionPanelClick(): void {
    this.icon = !this.icon;
  }

  faqExpansionClick(): void {
    this.icon1 = !this.icon1;
  }

  showDetails(): void {
    this.showCard = !this.showCard;
  }

  setTimeSelector(period: TimeFrameEnum): void {
    this.selected = period;
    if (this.selected) {
      this.timeFrame = this.learnMoreService
        .renderTimeFrame(this.selected)
        .toLowerCase();
    }
  }

  getVehicleViewDetails(period: TimeFrameEnum): void {
    this.learnMoreService
      .learnMoreInfo<EVDetailsModel>("ev", 1)
      .subscribe((response: EVDetailsModel) => {
        this.loader = false;
        this.haveResponse = true;
        this.electricViewDetails = response;
        this.technicalDetails = this.electricViewDetails.technicalDetails;
        this.financialDetails = this.electricViewDetails.financialDetails;
        this.learnMoreDetails = this.electricViewDetails.learnMoreDetails;
        this.assumptionDetails = this.electricViewDetails.assumptionsDetails;
        this.financeValue = this.learnMoreDetails.financial;
        this.assumptionLabel = this.utils.toTitleCase(this.financeValue);
        this.paymentTypeLabel = this.dashboardService.getPaymentTypeLabel(
          this.financeValue
        );
        this.evFormDetails = this.learnMoreDetails;
        this.favStatus = this.learnMoreDetails.favoriteStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
        this.financeEvSavings = this.financialDetails.evSavings;
        this.showIncentivesStatus = this.financialDetails.evCost.showIncentives;
        this.totalIncentives = this.financialDetails.evCost.incentives;
        this.carType = this.technicalDetails.evTechnicalSummary.carType;
        this.carType = this.carType === "All Electric" ? "EV" : this.carType;
        this.yAxisLabel = "Energy cost";
        this.yAxisLabel1 = "with " + this.carType;
        this.updateChart(period);
        this.showTooltipZero = this.evSavings <= 0;
        this.showCash = this.financeValue === "cash";
        this.showLoan = this.financeValue === "loan";
        this.showLease = this.financeValue === "lease";
      });
  }

  updateChart(period: TimeFrameEnum): void {
    let electricityChargingCost;
    let dataInputs;

    this.setTimeSelector(period);
    electricityChargingCost = this.financeEvSavings.electricity[period];
    electricityChargingCost =
      electricityChargingCost <= 0 ? 0.0001 : electricityChargingCost;
    this.evSavings = this.financeEvSavings.total[period];

    dataInputs = [
      { y: electricityChargingCost, color: "#EA6C94" },
      {
        y: this.financeEvSavings.gasoline[period],
        color: "#414141",
      },
    ];
    this.updateChartOptions(dataInputs, this.yAxisLabel, this.yAxisLabel1);
  }

  onCarTypeChange(value: string): boolean {
    if (this.makeAndModelList) {
      let carType = this.makeAndModelList
        .filter((x) => x.mnfAndModel === value)
        .map((y) => y.carType)[0];
      return carType === "PHEV";
    }
    return false;
  }

  updateChartOptions(
    inputData: BarChartDataModel[],
    yAxisLabel: string,
    yAxisLabel1: string
  ): void {
    let filteredInputData: BarChartDataModel[] = [];
    let i = 0;
    for (let entry of inputData) {
      if (entry.y != 0) {
        filteredInputData[i] = entry;
        i++;
      }
    }
    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
      },
    });

    this.chartOptions1 = {
      credits: {
        enabled: false,
      },
      chart: {
        type: "bar",
      },
      title: {
        text: "",
      },
      xAxis: {
        labels: {
          style: {
            color: "#333333",
            fontSize: "16px",
            fontFamily: "Open sans",
          },
        },
        categories: [
          `${yAxisLabel} <br> ${yAxisLabel1}`,
          `Energy cost with <br> gasoline vehicle`,
        ],
        gridLineWidth: 0,
        lineWidth: 1,
        tickWidth: 0,
      },
      yAxis: {
        tickAmount: 8,
        gridLineWidth: 0,
        tickWidth: 0,
        lineWidth: 1,
        title: {
          text: null,
        },
        labels: {
          style: {
            color: "#333333",
            fontSize: "12px",
            fontFamily: "Open sans",
          },
          formatter: function (this) {
            if (Number(this.value) >= 1000) {
              return "$" + Number(this.value) / 1000 + "K";
            } else {
              return "$" + this.value;
            }
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          pointWidth: 50,
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "16px",
              fontWeight: "normal",
              fontFamily: "Open sans",
            },
            formatter: function (this) {
              if (this.point.y && this.point.y < 1) {
                return "$0";
              } else {
                return (
                  "$" +
                  this.point.y?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                );
              }
            },
            animation: {
              duration: 20,
            },
          },
        },
        series: {
          borderWidth: 0,
          animation: {
            duration: 1500,
          },
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              chart: {
                className: "small-chart",
              },
            },
          },
        ],
      },
      series: [
        {
          type: "bar",
          showInLegend: false,
          data: filteredInputData,
        },
      ],
    };
    Highcharts.chart(this.container.nativeElement, this.chartOptions1);
  }

  onSaveDetails(finance?: string): void {
    this.haveResponse = false;
    if (finance) {
      this.evForm.get("finance")?.setValue(finance);
      this.financeValue = finance;
    } else {
      this.financeValue = this.evForm.get("finance")?.value;
    }
    if (
      this.learnMoreService.isFormEditted(
        this.evData.ev!,
        this.learnMoreDetails
      )
    ) {
      this.saveButton.nativeElement.innerHTML =
        'Saving... &nbsp;<i class="fa fa-spinner fa-spin"></i>';
      this.dashboardService.dashboardDetailsAnalytics("save", "ev1");
      this.learnMoreService
        .updateLearnMoreInfo("ev1", this.evData)
        .subscribe((data: any) => {
          setTimeout(() => {
            this.saveButton.nativeElement.innerHTML = "SAVE DETAILS";
            this.getVehicleViewDetails(this.selected);
          }, 800);
        });
    }
  }

  openHelpMeChoose(): void {
    let dialogRef = this.dialog.open(HelpMeChooseComponent, {
      width: "90%",
      height: "80%",
      autoFocus: false,
      backdropClass: "dark-backdrop",
      panelClass: "custom-dialog-container",
      data: { tech: "ev1" },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.evForm.get("model")?.setValue(result);
        this.onSaveDetails(this.financeValue);
      }
    });
  }

  navigateToEVChargers(): void {
    this.router.navigateByUrl("/dashboard/details/ev-chargers");
  }

  get isMilesOutOfRange(): boolean {
    return (
      this.evForm.controls["annualMiles"].hasError("pattern") ||
      this.evForm.controls["annualMiles"].hasError("min") ||
      this.evForm.controls["annualMiles"].hasError("max")
    );
  }

  get isMilesRequired(): boolean {
    return this.evForm.controls["annualMiles"].hasError("required");
  }

  get isChargingRequired(): boolean {
    return this.evForm.controls["charging"].hasError("required");
  }

  get evData(): LearnMoreRequestModel {
    return {
      ev: {
        model: this.evForm.get("model")?.value,
        annualMiles: this.evForm.get("annualMiles")?.value,
        charging: this.evForm.get("charging")?.value,
        financial: this.evForm.get("finance")?.value,
      },
    };
  }

  set evFormDetails(details: EVLearnMoreModel) {
    this.evForm.get("model")?.setValue(details.model);
    this.evForm.get("charging")?.setValue(details.charging.toLocaleString());
    this.evForm.get("finance")?.setValue(this.financeValue);
    this.evForm.get("annualMiles")?.setValue(details.annualMiles);
  }

  handleFAQClick(): void {
    this.dashboardService.dashboardDetailsAnalytics("moreInfo", "ev1");
    window.open(
      "https://www.yellowtin.com/clean-energy-information-center-es/electric-vehicle-faq-es/",
      "_blank"
    );
  }

  tabChanged(event: any) {
    this.tabLabel = event.value;
    this.tabIndex = event.index;
  }

  scrollToTop(): void {
    document.querySelector(".mat-sidenav-content")?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  onFavorite(): void {
    const ownPlan = this.favStatus ? "noPlan" : "yesPlan";
    this.overviewService
      .updateTechSettingInfo("ev1", ownPlan)
      .subscribe((response) => {
        this.favStatus = !this.favStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
       this.getVehicleViewDetails(this.selected);
       
      const action = this.favStatus ? "add" : "remove";
      const info = this.dashboardService.techMessage("ev1", action);
      this.snackBar.open(info, "OK", {
        duration: 8000,
      });
      });
  }
}
