import { AuthenticationService } from "./auth/auth.service";
// import { UserDetailsFormResolverService } from './user-details-form/user-details-form-resolver.service.';
// UserDetailsFormResolverService,
import { AppService } from "./app.service";
// import { GestureConfig } from '@angular/material/core';
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./auth/auth.interceptor";
import { AuthGuard } from "./auth/auth-guard.service";
import { RedirectGuard } from "./redirect-guard.service";
import { LayoutService } from "./homeowner-product/shared/layout/layout.service";
import { DashboardLayoutService } from "./homeowner-dashboard/shared/dashboard-layout/dashboard-layout.service";
import { Title } from "@angular/platform-browser";

export const APP_PROVIDERS = [
  AppService,
  AuthenticationService,
  AuthGuard,
  RedirectGuard,
  LayoutService,
  DashboardLayoutService,
  Title,
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
