<div class="overlay_header_bg" id="overlay_top">
  <div class="pa4">
    <mat-icon class="mat-icon" (click)="onClose()">close</mat-icon>
    <div class="tech_header mr3-ns">{{ activeTech | techName }}</div>
  </div>
</div>

<yt-battery-FAQ *ngIf="activeTech === 'battery'"></yt-battery-FAQ>
<yt-ebike-FAQ *ngIf="activeTech === 'eBike'"></yt-ebike-FAQ>
<yt-ev-FAQ *ngIf="activeTech === 'ev'"></yt-ev-FAQ>
<yt-hpd-FAQ *ngIf="activeTech === 'HP_hpd'"></yt-hpd-FAQ>
<yt-hpsc-FAQ *ngIf="activeTech === 'HP_hpsc'"></yt-hpsc-FAQ>
<yt-hpwh-FAQ *ngIf="activeTech === 'HP_hpwh'"></yt-hpwh-FAQ>
<yt-ic-FAQ *ngIf="activeTech === 'icing'"></yt-ic-FAQ>
<yt-solar-FAQ *ngIf="activeTech === 'solar'"></yt-solar-FAQ>
<yt-led-bulbs-FAQ *ngIf="activeTech === 'ledCflBulbs'"></yt-led-bulbs-FAQ>
<yt-smart-thermostat-FAQ
  *ngIf="activeTech === 'smartThermostat'"
></yt-smart-thermostat-FAQ>
<yt-smart-power-strip-FAQ
  *ngIf="activeTech === 'smartPowerStrips'"
></yt-smart-power-strip-FAQ>
<yt-shower-heads-aerators-FAQ
  *ngIf="activeTech === 'LM_showerHeadsAerators'"
></yt-shower-heads-aerators-FAQ>
<yt-weather-strips-FAQ
  *ngIf="activeTech === 'weatherStripping'"
></yt-weather-strips-FAQ>

<div class="scroll_top_link">
  <mat-icon class="top_icon" (click)="scrollTop()">keyboard_arrow_up</mat-icon>
  <span (click)="scrollTop()">Back to top</span>
</div>
