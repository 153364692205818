import { Component } from '@angular/core';

@Component({
  selector: 'scroll-top',
  templateUrl: 'scroll-top.component.html',
  styleUrls: ['./scroll-top.component.scss']
})

export class ScrollTopComponent { 
    ngAfterViewInit() {
        let top = document.getElementById('scroll-top');
        if (top !== null) {
          top.scrollIntoView({});
          top.scrollTo(0, -500);
          top = null;
        }
      }
}