<div class="page_container">
  <div class="yt_width">
    <div *ngIf="roofTopPageModel">
      <div class="yt__info-header yt_header-margin-top">
        Are you interested in community solar program?
      </div>
      <div id="1" class="pointer">
        <yt-roof-QA
          [imagePath]="'assets/roof-top-imagesv2/community-solar/'"
          [defaultValue]="roofTopDetails[0].own"
          [buildingTypeDisplayModel]="roofTopPageModel.solar!"
          (buldingTypeSelect)="onBuldingTypeQuestion($event)"
        >
        </yt-roof-QA>
      </div>
      <div id="7" class="w-100 tr continue_button">
        <button
          mat-raised-button
          color="accent"
          (click)="onSaveCommunitySolar()"
          [disabled]="!isEnabled"
        >
          SAVE
        </button>
      </div>
    </div>
  </div>
</div>
