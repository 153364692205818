import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BackButtonComponent } from "./back-button.component";

@NgModule({
    imports: [CommonModule],
    declarations: [BackButtonComponent],
    providers: [],
    exports: [BackButtonComponent]
})
export class BackButtonModule {}
