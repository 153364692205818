import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { PopupLoaderModule } from "../../../shared/popup-loader/index";
import { HelpMeChooseComponent } from "./help-me-choose.component";
import { HelpMeChooseService } from "./help-me-choose.service";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    PopupLoaderModule,
    MatDialogModule,
    MatButtonModule,
  ],
  declarations: [HelpMeChooseComponent],
  exports: [HelpMeChooseComponent],
  providers: [HelpMeChooseService],
})
export class HelpMeChooseModule {}
