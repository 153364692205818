import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ICSettingModel } from "../energy-choices.model";
import { LifestyleService } from "../../../rest-api/lifestyle.service";
import { LearnMoreService } from "../../../homeowner-dashboard/learn-more/learn-more.service";
import { Utils } from "../../../utils/util";
import { DropDownModel } from "../../../rest-api/rest-api.model";

@Component({
  selector: "yt-ic",
  templateUrl: "ic.component.html",
  styleUrls: ["../energy-choices-common.scss"],
})
export class ICComponent implements OnInit {
  panelOpenState: boolean = false;
  icForm: FormGroup;
  updateMode: boolean;
  icData: ICSettingModel[];
  @Output() saveIC = new EventEmitter<any>();
  disableDatePicker: boolean;
  monthList: string[];
  lastTwelthMonth: string;
  buildType: boolean;
  currentRanges: DropDownModel[];

  constructor(
    private fb: FormBuilder,
    private lifeStyleService: LifestyleService,
    private utils: Utils,
    private learnMoreService: LearnMoreService
  ) {
    this.currentRanges = this.learnMoreService.typeOfGas;
    let countofPeople = this.lifeStyleService.peopleCount;
    this.icForm = this.fb.group({
      own: ["yesPlan", Validators.required],
      howManyPeople: [
        countofPeople,
        [
          Validators.required,
          Validators.pattern("^(1[0-2]|[1-9])$"),
          Validators.min(1),
          Validators.max(10),
        ],
      ],
      currentRange: ["Natural gas", Validators.required],
      bought: [""],
      datePurchased: ["", Validators.required],
    });
  }

  ngOnInit() {
    this.monthList = this.utils.monthList;
    this.lastTwelthMonth = this.utils.lastTwelthMonth;
    if (this.icForm.get("own")?.value === "yes") {
      this.icForm.get("currentRange")?.disable();
      this.icForm.get("currentRange")?.setValue("Electric");
    }
    if (this.icForm.get("own")?.value === "yesPlan") {
      this.icForm.get("currentRange")?.setValue("Natural gas");
    }
    if (this.lifeStyleService.getICInfo() !== undefined) {
      this.icData = this.lifeStyleService.getICInfo();
      this.checkInUpdateView(this.icData[0]);
    }
    if (this.icForm.get("own")?.value !== "yes") {
      this.icForm.get("datePurchased")?.disable();
    }
    this.icForm.get("howManyPeople")?.valueChanges.subscribe(() => {
      this.lifeStyleService.setPeopleCount(
        this.icForm.get("howManyPeople")?.value
      );
    });
    this.buildType = this.lifeStyleService.isRenterCondoUser;
  }

  isDatePurchasedEmpty() {
    if (this.icForm.get("own")?.value === "yes") {
      if (!this.icForm.get("datePurchased")?.value) {
        return true;
      }
    }
    return false;
  }

  condition(): void {
    if (this.icForm.get("own")?.value === "yes") {
      this.icForm.get("howManyPeople")?.enable();
      this.icForm.get("bought")?.enable();
      this.icForm.get("currentRange")?.disable();
      this.icForm.get("datePurchased")?.enable();
      this.disableDatePicker = true;
    } else if (this.icForm.get("own")?.value === "yesPlan") {
      this.icForm.get("howManyPeople")?.enable();
      this.icForm.get("bought")?.enable();
      this.icForm.get("currentRange")?.enable();
      this.icForm.get("datePurchased")?.disable();
      this.disableDatePicker = false;
    } else if (this.icForm.get("own")?.value === "no") {
      this.icForm.get("howManyPeople")?.disable();
      this.icForm.get("bought")?.disable();
      this.icForm.get("currentRange")?.disable();
      this.icForm.get("datePurchased")?.disable();
      this.disableDatePicker = false;
    } else if (this.icForm.get("own")?.value === "noPlan") {
      this.icForm.get("howManyPeople")?.disable();
      this.icForm.get("bought")?.disable();
      this.icForm.get("currentRange")?.disable();
      this.icForm.get("datePurchased")?.disable();
      this.disableDatePicker = false;
    }
  }

  checkInUpdateView(data: ICSettingModel) {
    this.updateMode = true;
    this.icForm.controls["own"].setValue(data.own);
    if (data.currentRange) {
      this.icForm.controls["currentRange"].setValue(data.currentRange);
      this.icForm.get("currentRange")?.enable();
    } else {
      if (this.icForm.get("own")?.value === "yesPlan") {
        this.icForm.get("currentRange")?.enable();
      } else {
        this.icForm.get("currentRange")?.disable();
      }
    }
    if (data.bought) {
      this.icForm.controls["bought"].setValue(data.bought);
      this.icForm.controls["datePurchased"].setValue(data.datePurchased);
    } else {
      if (data.own === "yes") {
        this.icForm.controls["bought"].setValue(false);
        this.icForm.controls["datePurchased"].setValue(this.lastTwelthMonth);
      } else {
        this.icForm.controls["datePurchased"].disable();
        this.icForm.get("bought")?.disable();
      }
    }
    this.disableDatePicker = data.own === "yes" ? true : false;
    this.condition();
  }

  onSaveIC() {
    const icInfo: {
      energyChoices: {
        howManyPeople: string;
        ic: {
          own: string;
          currentRange?: string;
          bought?: string;
          datePurchased?: string;
        }[];
      };
    } = {
      energyChoices: {
        howManyPeople: this.icForm.get("howManyPeople")?.value,
        ic: [],
      },
    };
    if (this.icForm.get("own")?.value === "yes") {
      const datePurchased = this.icForm.get("datePurchased")?.value;
      const bought = this.utils.isDatePurchaseSelected(datePurchased);
      this.icForm.get("bought")?.setValue(bought);
      if (this.icForm.get("currentRange")?.value === "") {
        this.icForm.get("currentRange")?.setValue("Electric");
      }
      icInfo.energyChoices.ic[0] = {
        own: this.icForm.get("own")?.value,
        currentRange: this.icForm.get("currentRange")?.value,
        bought: this.icForm.get("bought")?.value,
        datePurchased: !this.icForm.get("bought")?.value
          ? null
          : this.icForm.get("datePurchased")?.value,
      };
    } else if (this.icForm.get("own")?.value === "yesPlan") {
      if (this.icForm.get("currentRange")?.value === "") {
        this.icForm.get("currentRange")?.reset();
      }
      icInfo.energyChoices.ic[0] = {
        own: this.icForm.get("own")?.value,
        currentRange: this.icForm.get("currentRange")?.value,
      };
    } else if (this.icForm.get("own")?.value === "no") {
      icInfo.energyChoices.ic[0] = {
        own: this.icForm.get("own")?.value,
      };
    }
    this.lifeStyleService.onSaveLifestyleInfo(icInfo).subscribe((response) => {
      if (response.status === 200) {
        this.lifeStyleService.postICInfo(icInfo);
        this.saveIC.emit();
      }
    });
  }
}
