// // Angular 2
// import { enableDebugTools, disableDebugTools } from '@angular/platform-browser';
// import { enableProdMode, ApplicationRef } from '@angular/core';
// const ENV = require('./custom-typings.d').ENV;
// // Angular debug tools in the dev console
// // https://github.com/angular/angular/blob/86405345b781a9dc2438c0fbe3e9409245647019/TOOLS_JS.md
// let _decorateModuleRef = function identity<T>(value: T): T { return value; };

// if ('production' === ENV) {
//   // Production
//   // disableDebugTools();
//   enableProdMode();

// } else {

//   _decorateModuleRef = (modRef: any) => {
//     const appRef = modRef.injector.get(ApplicationRef);
//     const cmpRef = appRef.components[0];

//     let _ng = (<any>window).ng;
//     enableDebugTools(cmpRef);
//     (<any>window).ng.probe = _ng.probe;
//     (<any>window).ng.coreTokens = _ng.coreTokens;
//     return modRef;
//   };
// }

// export const decorateModuleRef = _decorateModuleRef;

export const environment = {
  production: false,
  API_URL: "https://api-staging.yellowtin.com:8443/",
  BASE_URL: "https://hp-app-dev.yellowtin.com/",
  MIXPANEL_KEY: "35f33c74b48ed2f2c37c9e11b7d1e2af",
};
