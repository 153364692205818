import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  HostListener,
  ElementRef,
} from "@angular/core";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { EventEmitter } from "@angular/core";
import { RoofTopPageModel } from "../../roof-top/roof-top.model";
import { RestAPIService } from "../../../rest-api/rest-api.service";
import { LifestyleService } from "../../../rest-api/lifestyle.service";
import { AppService } from "../../../app.service";
import { SolarSettingModel } from "../energy-choices.model";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "yt-solar",
  templateUrl: "solar.component.html",
  styleUrls: ["./solar.component.scss"],
})
export class SolarComponent implements OnInit, OnDestroy {
  roofTopQAForm: FormGroup;
  roofTopPageModel: RoofTopPageModel;
  roofTopDetails: SolarSettingModel[];
  continueWithoutSolar: boolean = false;
  roofAgeQuestion: boolean;
  planForReRoofQuestion: boolean;
  roofSlopeQuestion: boolean;
  roofShadeQuestion: boolean;
  roofTypeQuestion: boolean;
  yesSolarQuestion: boolean = true;
  updateMode: boolean;
  userRoofTopDetails: SolarSettingModel[];
  showPopup: boolean;
  buildingTypeRenter: boolean;
  buildingTypeCondo: boolean;
  updateUserDetailsUrl: string;
  homeOwnerEmail: string;
  @ViewChild("canInstallSolarPopup", { read: ElementRef }) modal: ElementRef;
  initialUserDetailsValue: SolarSettingModel[] = [
    {
      own: "",
      roofReplace: "",
      roofAge: "",
      roofShade: "",
      roofSlope: "",
      roofType: "",
    },
  ];
  private alive = true;
  showCondoRenterPopup: boolean;
  @Output() saveSolar = new EventEmitter<any>();

  constructor(
    private restAPIService: RestAPIService,
    private lifeStyleService: LifestyleService,
    private fb: FormBuilder,
    private appService: AppService,
    private lifestyleService: LifestyleService
  ) {}

  @HostListener("document:keydown", ["$event"])
  onKeyDown(event) {
    if (event.key == "Escape") {
      this.closeCondoRenterPopup();
    }
  }
  @HostListener("document:click", ["$event"])
  onDocumentClick(event) {
    if (this.modal && this.showCondoRenterPopup) {
      if (event.target === this.modal.nativeElement) {
        this.closeCondoRenterPopup();
      }
    }
  }

  ngOnInit(): void {
    this.getRoofTopQA();
    this.userRoofTopDetails = this.lifeStyleService.getUserProvidedSolarInfo();
    this.roofTopDetails =
      this.userRoofTopDetails !== undefined
        ? this.userRoofTopDetails
        : this.initialUserDetailsValue;
    this.updateMode = this.userRoofTopDetails !== undefined ? true : false;
    if (this.restAPIService.solarVendorInformation.solutionType === "vendor") {
      this.userRoofTopDetails[0].own = "yes";
      this.yesSolarQuestion = false;
    }
    this.hideAllQuestion();
    this.createroofTopForm();
    if (this.userRoofTopDetails) {
      if (
        this.userRoofTopDetails[0].own === "no" ||
        this.userRoofTopDetails[0].own === "yes"
      ) {
        this.hideAllQuestion();
        this.resetRoofTopDetails();
        this.continueWithoutSolar = true;
      } else {
        this.showAllQuestions();
        this.continueWithoutSolar = false;
      }
    }
    let buildingType = this.getBuldingTypeValue();
    this.buildingTypeRenter = buildingType === "renter";
    this.buildingTypeCondo = buildingType === "condominium";
    if (this.buildingTypeRenter || this.buildingTypeCondo) {
      this.showPopup = !this.lifestyleService.isRenterCondoUser;
    }
  }

  getRoofTopQA() {
    const vedorName = this.appService.getVendorName();
    this.restAPIService
      .getAllRoofTopQA(vedorName)
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => {
        this.roofTopPageModel = data;
        this.roofTopPageModel.roofTypes =
          this.restAPIService.getSolarVendorInformation().roofTypes;
      });
  }

  hideAllQuestion() {
    this.roofAgeQuestion =
      this.restAPIService.solarVendorInformation.solutionType === "vendor"
        ? true
        : false;
    this.planForReRoofQuestion = false;
    this.roofSlopeQuestion = false;
    this.roofShadeQuestion = false;
    this.roofTypeQuestion = false;
  }
  showAllQuestions() {
    this.roofAgeQuestion = true;
    this.planForReRoofQuestion = true;
    this.roofSlopeQuestion = true;
    this.roofShadeQuestion = true;
    this.roofTypeQuestion = true;
  }

  createroofTopForm() {
    this.roofTopQAForm = this.fb.group({
      own: [""],
      roofAge: ["", Validators.required],
      roofReplace: ["", Validators.required],
      roofSlope: ["", Validators.required],
      roofShade: ["", Validators.required],
      roofType: ["", Validators.required],
    });
    if (this.updateMode) {
      this.roofTopQAForm.get("own")?.setValue(this.userRoofTopDetails[0].own);
      this.roofTopQAForm
        .get("roofReplace")
        ?.setValue(this.userRoofTopDetails[0].roofReplace);
      this.roofTopQAForm
        .get("roofAge")
        ?.setValue(this.userRoofTopDetails[0].roofAge);
      this.roofTopQAForm
        .get("roofSlope")
        ?.setValue(this.userRoofTopDetails[0].roofSlope);
      this.roofTopQAForm
        .get("roofShade")
        ?.setValue(this.userRoofTopDetails[0].roofShade);
      this.roofTopQAForm
        .get("roofType")
        ?.setValue(this.userRoofTopDetails[0].roofType);
    }
    this.roofTopQAForm.valueChanges
      .pipe(takeWhile((_) => this.alive))
      .subscribe((data) => {
        const solarData = {
          energyChoices: {
            solar: [
              {
                own: data.own,
                roofAge: data.roofAge,
                roofReplace: data.roofReplace,
                roofType: data.roofType,
                roofSlope: data.roofSlope,
                roofShade: data.roofShade,
              },
            ],
          },
        };
        if (
          this.roofTopQAForm.value.own === "no" ||
          this.roofTopQAForm.value.own === "yes"
        ) {
          this.resetRoofValues();
          this.lifeStyleService.postSolarInfo(solarData).subscribe(() => {});
        } else if (this.roofTopQAForm.valid) {
          if (
            this.restAPIService.solarVendorInformation.solutionType === "vendor"
          ) {
            this.roofTopQAForm.value.own = "yes";
          }
          this.lifeStyleService.postSolarInfo(solarData).subscribe(() => {});
        }
      });
  }

  resetRoofValues() {
    this.roofTopQAForm.value.roofReplace = "";
    this.roofTopQAForm.value.roofAge = "";
    this.roofTopQAForm.value.roofShade = "";
    this.roofTopQAForm.value.roofSlope = "";
    this.roofTopQAForm.value.roofType = "";
  }

  resetRoofTopDetails() {
    this.roofTopQAForm.reset();
    this.roofTopQAForm.get("own")?.setValue(this.roofTopDetails[0].own);
    this.roofTopDetails[0].roofAge = "";
    this.roofTopDetails[0].roofReplace = "";
    this.roofTopDetails[0].roofShade = "";
    this.roofTopDetails[0].roofSlope = "";
    this.roofTopDetails[0].roofType = "";
  }

  onBuldingTypeQuestion(own: string) {
    this.roofTopQAForm.controls["own"].setValue(own);
    let ownValue = this.roofTopQAForm.controls["own"].value;
    if (ownValue === "no" || ownValue === "yes") {
      this.continueWithoutSolar = true;
      this.resetRoofTopDetails();
      this.roofAgeQuestion = false;
      this.roofTopQAForm.valid == true;
    } else {
      if (this.roofTopQAForm.controls["roofAge"].invalid) {
        this.hideAllQuestion();
      }
      this.continueWithoutSolar = false;
      this.roofAgeQuestion = true;
    }
  }

  onRoofAgeQuestion(age: string) {
    this.roofTopQAForm.controls["roofAge"].setValue(age);
    this.planForReRoofQuestion = true;
  }

  onPlanForReRoofQuestion(plan: string) {
    this.roofTopQAForm.controls["roofReplace"].setValue(plan);
    this.roofSlopeQuestion = true;
  }

  onRoofSlopeQuestion(roofSlope: string) {
    this.roofTopQAForm.controls["roofSlope"].setValue(roofSlope);
    this.roofShadeQuestion = true;
  }

  onRoofShadeQuestion(roofShade: string) {
    this.roofTopQAForm.controls["roofShade"].setValue(roofShade);
    this.roofTypeQuestion = true;
  }

  onRoofTypeQuestion(roofType: string) {
    this.roofTopQAForm.controls["roofType"].setValue(roofType);
  }

  getBuldingTypeValue(): string {
    return this.restAPIService.getBuildingType();
  }

  openCondoRenterPopup() {
    this.showCondoRenterPopup = true;
  }

  closeCondoRenterPopup() {
    this.showCondoRenterPopup = false;
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  onSaveSolar(): void {
    this.hideAllQuestion();
    this.saveSolar.emit();
  }
}
