<div class="w-100 toggle_buttons">
  <mat-button-toggle-group
    [value]="selected"
    (change)="updateChart($event.value)"
  >
    <mat-button-toggle
      *ngFor="let time of timeSegmentList"
      [value]="time.value"
      [ngClass]="{'active-toggle': selected === time.value}"
      (click)="selected = time.value"
    >
      <span class="toggle_label">
        {{ time.viewValue.toUpperCase() }}
      </span>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
