<div class="header">
  <span *ngIf="revokedAuth && smdStatus">Approach 1:</span>
  Manual Estimate
</div>
<div class="sub-header">Review your average monthly electric bill</div>
<div [formGroup]="estimateForm" #matSlider class="slider_section">
  <mat-slider
    min="35"
    max="500"
    tickInterval="5"
    discrete
    step="5"
    (input)="onSliderChange($event)"
  >
    <input matSliderThumb formControlName="sliderValue" />
  </mat-slider>
</div>
<div class="label_section">
  <span class="slider_value">$35</span> <span class="slider_value">$500</span>
</div>
