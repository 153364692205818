<form [formGroup]="evForm" class="margin__bottom energy-choices">
  <div class="yt-text">
    <strong>Electric vehicles</strong> are fun to drive, with lots of pep. More
    importantly, they can save you a lot on your energy and maintenance costs.
    Even better, with no tailpipe emissions, an EV is a very effective way to
    cut your carbon emissions.
  </div>
  <div class="w-100">
    <div
      formArrayName="ev"
      *ngFor="let item of evArray.controls; let i = index"
    >
      <div
        [ngStyle]="{ 'padding-top': i === 1 ? '30px' : '40px' }"
        [formGroupName]="i"
      >
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
          [expanded]="true"
        >
          <mat-expansion-panel-header>
            <mat-panel-title class="yt__panel-header"
              >Electric Vehicle #{{ i + 1 }}</mat-panel-title
            >
          </mat-expansion-panel-header>
          <div class="cf">
            <div class="w-100">
              <div
                class="w-100 w-50-l fl"
                [ngClass]="{
                  yt_height_ev: i === 1 && !disableBoughtAndDatePurchased[1],
                  yt_height_ev_remove:
                    i === 1 && disableBoughtAndDatePurchased[1]
                }"
              >
                <div class="radio-buttons">
                  <mat-radio-group
                    class="example-radio-group"
                    (change)="onSelectOfQuestion(i)"
                    formControlName="own"
                  >
                    <div class="yt-Btn-Font">
                      Tell us about your electric vehicle
                    </div>
                    <div class="radio_text">
                      <div class="dib yt_ques-padding">
                        <mat-radio-button value="yes">
                          <span class="yt_option">Already have one</span>
                        </mat-radio-button>
                      </div>
                      <div class="dib yt_answer-padding-2">
                        <mat-radio-button value="yesPlan">
                          <span class="yt_option"
                            >Interested in getting one</span
                          >
                        </mat-radio-button>
                      </div>
                      <div class="dib yt_answer-padding-2">
                        <mat-radio-button value="no">
                          <span class="yt_option">Not interested</span>
                        </mat-radio-button>
                      </div>
                    </div>
                  </mat-radio-group>
                </div>
                <div
                  class="radio-buttons pointer remove-ev"
                  *ngIf="i === 1"
                  (click)="removeEV()"
                >
                  Remove Electric Vehicle #2
                </div>
              </div>

              <div
                class="w-100 w-100-m w-50-l fl yt_border-left yt_paddingtop-42"
              >
                <div
                  class="yt__f4 flex justify-between items-center yt__pt-20 yt_pb-7"
                  style="max-width: 285px"
                  [ngStyle]="{
                    color: getValue('own', i) === 'no' ? '#c7c7c7' : '#333333'
                  }"
                >
                  Make & Model
                  <span
                    (click)="disableLink(i) ? null : openDialogStatus(i)"
                    class="pointer fw6 yt_help-text"
                    [ngClass]="{
                      'yt_help-hide': getValue('own', i) === 'no'
                    }"
                  >
                    Help me choose
                  </span>
                </div>
                <div class="yt__pt0-7">
                  <mat-form-field
                    appearance="outline"
                    floatLabel="auto"
                    [ngClass]="{
                      'disable-field': getValue('own', i) === 'no'
                    }"
                  >
                    <mat-select
                      placeholder="Make & Model"
                      formControlName="model"
                    >
                      <mat-option
                        *ngFor="let makeAndModel of mnfDropDownList[i]"
                        [value]="makeAndModel.mnfAndModel"
                        [matTooltip]="makeAndModel.mnfAndModel"
                        >{{ makeAndModel.mnfAndModel }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <div
                  class="yt__f4 yt_pt1-75"
                  [ngStyle]="{
                    color: getValue('own', i) ? '#c7c7c7' : '#333333'
                  }"
                >
                  <div
                    class="yt__f4 flex justify-between items-center yt__pt-20"
                    style="max-width: 285px"
                  >
                    <div>
                      <span
                        [ngStyle]="{
                          color:
                            getValue('own', i) === 'no' ? '#c7c7c7' : '#333333'
                        }"
                      >
                        Annual miles
                      </span>
                      <mat-icon
                        class="pointer h-20"
                        matTooltipPosition="right"
                        matTooltip="Total miles driven on electricity in a year. This should not include the number of miles a plug-in hybrid electric vehicle (PHEV) is driven on gasoline."
                      >
                        help_outline
                      </mat-icon>
                    </div>
                    <div>
                      <span
                        (click)="onPopUp()"
                        class="pointer fw6 yt_help-text"
                        [ngClass]="{
                          'yt_help-hide': getValue('own', i) === 'no'
                        }"
                      >
                        Calculate</span
                      >
                    </div>
                  </div>
                </div>
                <div class="yt__pt0-7">
                  <mat-form-field
                    appearance="outline"
                    floatLabel="auto"
                    hideRequiredMarker="true"
                    [ngClass]="{
                      'disable-field': getValue('own', i) === 'no'
                    }"
                  >
                    <div class="yt_suffix">
                      <input
                        matInput
                        type="text"
                        formControlName="annualMiles"
                        placeholder="Enter 1000-40000"
                        required
                        maxlength="6"
                      />
                      <span
                        matSuffix
                        class="suffix_color"
                        [ngClass]="{
                          disabled_suffix_color: getValue('own', i) === 'no'
                        }"
                        *ngIf="onCarTypeChange(getValue('model', i), i)"
                        >miles driven by electricity</span
                      >
                      <span
                        matSuffix
                        class="suffix_color"
                        [ngClass]="{
                          disabled_suffix_color: getValue('own', i) === 'no'
                        }"
                        *ngIf="!onCarTypeChange(getValue('model', i), i)"
                        >miles</span
                      >
                    </div>
                    <mat-error *ngIf="validateAnnualMiles(i)">
                      Please enter value between 1000-40000
                    </mat-error>
                    <mat-error *ngIf="isAnnualMilesEmpty(i)">
                      Required!
                    </mat-error>
                  </mat-form-field>
                </div>
                <div
                  class="yt__f4 yt_pt1-75"
                  [ngStyle]="{
                    color: getValue('own', i) === 'no' ? '#c7c7c7' : '#333333'
                  }"
                >
                  Home charging
                  <mat-icon
                    matTooltip="This is the % of time you will be charging the EV from your home."
                    matTooltipPosition="right"
                    class="h-20"
                  >
                    help_outline
                  </mat-icon>
                </div>
                <div
                  class="yt__pt0-7"
                  [ngClass]="{ 'yt__pb-20': !disableBoughtAndDatePurchased[i] }"
                >
                  <mat-form-field
                    appearance="outline"
                    floatLabel="auto"
                    [ngClass]="{
                      'disable-field': getValue('own', i) === 'no'
                    }"
                  >
                    <mat-select
                      placeholder="Home charging"
                      formControlName="charging"
                      required
                    >
                      <mat-option
                        *ngFor="let charge of charges"
                        [value]="charge"
                        >{{ charge }}%</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngIf="disableBoughtAndDatePurchased[i]">
                  <article class="cf">
                    <div
                      class="fl w-60-c yt__f4 yt_pt1-75"
                      [ngStyle]="{
                        color:
                          getValue('own', i) === 'yes' ? '#333333' : '#c7c7c7'
                      }"
                    >
                      Purchased/leased month & year
                    </div>
                  </article>
                  <div class="yt__pb-20 yt__pt0-7">
                    <mat-form-field
                      appearance="outline"
                      floatLabel="auto"
                      hideRequiredMarker="true"
                      [ngClass]="{
                        'disable-field': !(getValue('own', i) === 'yes')
                      }"
                    >
                      <mat-select
                        matInput
                        placeholder="Select"
                        formControlName="datePurchased"
                        required
                      >
                        <mat-option
                          *ngFor="let month of monthList"
                          [value]="month"
                          >{{ month | titlecase }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                    <div
                      class="cb w-100 yt_error-message"
                      *ngIf="isDatePurchasedSelected(i)"
                    >
                      Required!
                    </div>
                  </div>
                </div>
                <div
                  class="remove-ev2 pointer"
                  *ngIf="i === 1"
                  (click)="removeEV()"
                >
                  Remove Electric Vehicle #2
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
  </div>
  <div
    class="yt__add-ev-btn w-100"
    [ngStyle]="{ 'padding-top': showAddEV ? '30px' : '0px' }"
    *ngIf="2 > evArray.controls.length"
  >
    <button
      *ngIf="showAddEV"
      mat-raised-button
      class="w-100"
      (click)="onAddAnotherEV()"
    >
      + Add Electric Vehicle #2
    </button>
  </div>
  <div class="pt_30 w-100">
    <button
      mat-raised-button
      color="accent"
      (click)="onSaveEV()"
      [disabled]="
        evForm.invalid || isDatePurchasedEmpty() || isMNFAndModelListAbsent()
      "
      class="fr w-100 yt__mb-12"
    >
      SAVE
    </button>
  </div>
</form>

<!-- Calculate Miles -->
<yt-calculate-miles
  *ngIf="showAnnualMiles"
  (onClose)="onClose()"
></yt-calculate-miles>
