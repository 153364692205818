import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { DropDownModel } from "../../../../../../rest-api/rest-api.model";
import { DashboardAPIService } from "../../../../../../rest-api/dashboard/dashboard-api.service";
import { ACDataModel, HPSCDataModel } from "../../../../model/userdata.model";
import { RadioOptionsModel } from "../../../../../../rest-api/dashboard/dashboard-api.model";
import { MatRadioChange } from "@angular/material/radio";
import { SettingsDialogData } from "../../setting-overlay.component.model";
import { SettingsOverlayService } from "../../settings-overlay.service";
import { Utils } from "../../../../../../utils/util";
import { LearnMoreService } from "../../../../../learn-more/learn-more.service";
import { Subject } from "rxjs";

@Component({
  selector: "yt-hpsc",
  templateUrl: "hpsc.component.html",
  styleUrls: ["../../common-tech.scss"],
})
export class HPSCComponent implements AfterContentChecked {
  panelOpenState: boolean = false;
  hpscForm: FormGroup;
  disableDatePicker: boolean = false;
  monthList: string[];
  lastTwelthMonth: string;
  ownValue: string;
  hpscDataModel: HPSCDataModel[];
  acDataModel: ACDataModel[];
  selectedRadioButton: string;
  radioButtonOptions: RadioOptionsModel[];
  hpscSettings: HPSCDataModel[];
  acSettings: ACDataModel[];
  currentHPSC: string;
  haveInputError: boolean;
  ageList: DropDownModel[];
  @Input() HPSCDetails;
  @Input() activeIcon: string;
  subject: Subject<any> = new Subject();
  @Output() techSave: EventEmitter<SettingsDialogData> = new EventEmitter();
  acTypes: DropDownModel[];
  hpsc_Type: DropDownModel[] = [
    { value: "Natural gas", viewValue: "Natural gas" },
    { value: "Electric", viewValue: "Standard electric" },
    { value: "Propane", viewValue: "Propane" },
    { value: "Fuel oil", viewValue: "Fuel oil" },
    { value: "Dont know", viewValue: "Don't know" },
  ];
  ducts: DropDownModel[] = [
    { value: "have", viewValue: "Yes" },
    { value: "dontHave", viewValue: "No" },
    { value: "notSure", viewValue: "I'm not sure" },
  ];

  constructor(
    private fb: FormBuilder,
    private dashboardAPIService: DashboardAPIService,
    private utils: Utils,
    private settingService: SettingsOverlayService,
    private learnMoreService: LearnMoreService
  ) {
    this.acTypes = this.dashboardAPIService.acTypes;
    this.hpscForm = this.fb.group({
      own: ["", Validators.required],
      ducts: ["have", Validators.required],
      hpscType: ["", Validators.required],
      age: ["", Validators.required],
      acType: ["", Validators.required],
      bought: [""],
      datePurchased: ["", Validators.required],
    });
  }

  ngAfterContentChecked(): void {
    const datePurchased = this.hpscForm.get("datePurchased")!;
    if (this.haveInputError) this.haveInputError = datePurchased.invalid;
  }

  ngOnInit() {
    this.dashboardAPIService.getRadioButtonDetails().subscribe((data) => {
      this.radioButtonOptions = data.radioButtons;
    });
    this.monthList = this.utils.monthList;
    this.lastTwelthMonth = this.utils.lastTwelthMonth;
    this.haveInputError = this.settingService.checkSettingsInfoError("hpsc");
    this.hpscDataModel = this.haveInputError
      ? this.settingService.settingsInfoErrors.appliances?.hpsc!
      : this.settingService.checkSettingsInfo("hpsc")
      ? this.settingService.settingsInfo.appliances?.hpsc!
      : this.dashboardAPIService.energyChoicesData.appliances.hpsc!;
    this.acDataModel = this.settingService.checkSettingsInfoError("ac")
      ? this.settingService.settingsInfoErrors.appliances?.ac!
      : this.settingService.checkSettingsInfo("ac")
      ? this.settingService.settingsInfo.appliances?.ac!
      : this.dashboardAPIService.energyChoicesData.appliances.ac!;
    this.hpscDataModel.forEach((hpsc: HPSCDataModel) => {
      this.ownValue = hpsc.own!;
      this.setSelectedRadioValues(this.ownValue);
      this.hpscForm.patchValue(hpsc);
      if (hpsc.own === "yes") {
        this.hpscForm.enable();
        this.ageList = this.learnMoreService.getAgeList("Electric");
        this.hpscForm.get("hpscType")?.disable();
        if (!hpsc.bought) {
          this.hpscForm.get("datePurchased")?.setValue(this.lastTwelthMonth);
        }
        this.disableDatePicker = true;
      } else if (hpsc.own === "yesPlan") {
        this.hpscForm.enable();
        this.currentHPSC = hpsc.hpscType!;
        this.ageList = this.learnMoreService.getAgeList(this.currentHPSC);
        this.hpscForm.get("bought")?.disable();
        this.hpscForm.get("datePurchased")?.disable();
        this.disableDatePicker = false;
      } else if (hpsc.own === "noPlan") {
        this.hpscForm.enable();
        this.currentHPSC = hpsc.hpscType!;
        this.ageList = this.learnMoreService.getAgeList(this.currentHPSC);
        this.hpscForm.get("bought")?.disable();
        this.hpscForm.get("datePurchased")?.disable();
        this.disableDatePicker = false;
      }
      this.hpscForm.get("own")?.enable();
      this.hpscForm.get("acType")?.enable();
    });
    this.acDataModel.forEach((ac: ACDataModel) => {
      this.hpscForm.get("acType")?.setValue(ac.type);
      if (ac.own === "no" && ac.type === "central") {
        this.hpscForm.get("acType")?.setValue("haveAC");
      } else if (ac.own === "yes" && ac.type === "central") {
        this.hpscForm.get("acType")?.setValue("haveAC");
      } else if (ac.own === "yes" && ac.type === "room") {
        this.hpscForm.get("acType")?.setValue("have1AC");
      }
    });
    if (this.acDataModel.length >= 2) {
      if (
        this.acDataModel[0].own === "yes" &&
        this.acDataModel[0].type === "room" &&
        this.acDataModel[1].own === "yes" &&
        this.acDataModel[1].type === "room"
      ) {
        this.hpscForm.get("acType")?.setValue("haveMoreAC");
      }
    } else {
      if (
        this.acDataModel[0].own === "yes" &&
        this.acDataModel[0].type === "room"
      ) {
        this.hpscForm.get("acType")?.setValue("have1AC");
      }
    }

    if (this.activeIcon) {
      if (this.activeIcon === "favorite") {
        this.hpscForm.get("own")?.setValue("yesPlan");
      } else if (this.activeIcon === "alreadyHave") {
        this.hpscForm.get("own")?.setValue("yes");
      }
      this.onValueChanges();
      this.setSelectedRadioValues(this.hpscForm.get("own")?.value);
      this.condition();
    } else {
      this.onValueChanges();
    }
  }

  onRadioChange(event: MatRadioChange) {
    this.setSelectedRadioValues(event.value);
  }

  setSelectedRadioValues(value: string): void {
    this.selectedRadioButton = value;
  }

  isDatePurchasedEmpty() {
    if (this.hpscForm.get("own")?.value === "yes") {
      if (!this.hpscForm.get("datePurchased")?.value) {
        return true;
      }
    }
    return false;
  }

  condition() {
    const hpsc = this.dashboardAPIService.energyChoicesData.appliances.hpsc![0];
    if (this.hpscForm.get("own")?.value === "yes") {
      this.hpscForm.get("hpscType")?.disable();
      this.hpscForm.get("age")?.enable();
      this.ageList = this.learnMoreService.getAgeList("Electric");
      this.hpscForm.get("age")?.setValue(this.ageList[2].value);
      this.hpscForm.get("bought")?.enable();
      this.hpscForm.get("datePurchased")?.enable();
      this.disableDatePicker = true;
    } else if (this.hpscForm.get("own")?.value === "yesPlan") {
      this.hpscForm.get("hpscType")?.enable();
      this.hpscForm.get("age")?.enable();
      this.hpscForm.get("hpscType")?.setValue(hpsc.hpscType);
      this.currentHPSC = hpsc.hpscType!;
      this.ageList = this.learnMoreService.getAgeList(this.currentHPSC);
      this.hpscForm.get("age")?.setValue(hpsc.age);
      this.hpscForm.get("bought")?.disable();
      this.hpscForm.get("datePurchased")?.disable();
      this.disableDatePicker = false;
    } else if (this.hpscForm.get("own")?.value === "noPlan") {
      this.hpscForm.get("hpscType")?.enable();
      this.hpscForm.get("age")?.enable();
      this.hpscForm.get("hpscType")?.setValue(hpsc.hpscType);
      this.currentHPSC = hpsc.hpscType!;
      this.ageList = this.learnMoreService.getAgeList(this.currentHPSC);
      this.hpscForm.get("age")?.setValue(hpsc.age);
      this.hpscForm.get("bought")?.disable();
      this.hpscForm.get("datePurchased")?.disable();
      this.disableDatePicker = false;
    }
    // this.hpscForm.get("sqfoot").enable();
    this.hpscForm.get("ducts")?.enable();
    this.hpscForm.get("acType")?.enable();
  }

  onTypeChanges(): void {
    this.currentHPSC = this.hpscForm.get("hpscType")?.value;
    this.ageList = this.learnMoreService.getAgeList(this.currentHPSC);
    this.hpscForm.get("age")?.setValue(this.ageList[2].value);
  }

  onValueChanges(): void {
    this.hpscForm.valueChanges.subscribe((data) => {
      this.subject.pipe().subscribe(() => {
        if (data.own === "yes") {
          const datePurchased = data.datePurchased;
          const bought = this.utils.isDatePurchaseSelected(datePurchased);
          this.hpscSettings = [
            {
              own: data.own,
              ducts: data.ducts,
              bought: bought,
              age: data.age,
              datePurchased: bought ? datePurchased : null,
            },
          ];
        } else if (data.own === "yesPlan" || data.own === "noPlan") {
          this.hpscSettings = [
            {
              own: data.own,
              ducts: data.ducts,
              hpscType: data.hpscType,
              age: data.age,
            },
          ];
        }
        if (data.acType === "dontHaveAC") {
          this.acSettings = [
            {
              own: "no",
              type: "central",
            },
          ];
        } else if (data.acType === "haveAC") {
          this.acSettings = [
            {
              own: "yes",
              type: "central",
              bought: false,
              datePurchased: this.lastTwelthMonth,
            },
          ];
        } else if (data.acType === "have1AC") {
          this.acSettings = [
            {
              own: "yes",
              type: "room",
              bought: false,
              datePurchased: this.lastTwelthMonth,
            },
          ];
        } else if (data.acType === "haveMoreAC") {
          this.acSettings = [
            {
              own: "yes",
              type: "room",
              bought: false,
              datePurchased: this.lastTwelthMonth,
            },
            {
              own: "yes",
              type: "room",
              bought: false,
              datePurchased: this.lastTwelthMonth,
            },
          ];
        }
        if (this.hpscForm.valid) {
          this.settingService.updateSettingsInfo(
            this.dashboardAPIService.energyChoicesData.appliances,
            this.hpscSettings,
            "appliances",
            "hpsc"
          );
          this.settingService.updateSettingsInfo(
            this.dashboardAPIService.energyChoicesData.appliances,
            this.acSettings,
            "appliances",
            "ac"
          );
        } else {
          this.settingService.updateSettingsInfoErrors(
            this.dashboardAPIService.energyChoicesData.appliances,
            this.hpscSettings,
            "appliances",
            "hpsc"
          );
          this.settingService.updateSettingsInfoErrors(
            this.dashboardAPIService.energyChoicesData.appliances,
            this.acSettings,
            "appliances",
            "ac"
          );
        }
      });
      this.subject.next();
    });
  }

  onSaveHPSC() {
    this.settingService.putSettingsInfo(this.getSettingsInfo);
  }

  getSettingsInfo = () => {
    if (this.activeIcon) {
      this.techSave.emit({
        own: this.hpscForm.get("own")?.value,
        tech: "hpsc",
      });
    }
  };
}
