import { FormControl } from "@angular/forms";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { takeWhile } from "rxjs/operators";
import { RoofTypesModel } from "src/app/rest-api/rest-api.model";

@Component({
  selector: "yt-toggle-button",
  templateUrl: "toggle-button.component.html",
  styleUrls: ["./toggle-button.component.scss"],
})
export class ToggleButtonComponent implements OnInit, OnDestroy {
  @Input() buttons: RoofTypesModel[] = [];
  @Input() intialValue: string;
  @Output() selectedButton = new EventEmitter<string>();
  buttonGroup: FormControl;
  private alive = true;

  ngOnInit() {
    this.buttonGroup = new FormControl(this.intialValue);
    this.buttonGroup.valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {
        this.selectedButton.emit(data);
      });
  }
  ngOnDestroy() {
    this.alive = false;
  }
}
