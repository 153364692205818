import { Component, HostListener, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "yt-faq-overlay",
  templateUrl: "./faq-overlay.component.html",
  styleUrls: ["./faq-overlay.component.scss"],
})
export class FAQOverlayComponent implements OnInit {
  activeTech: string;
  constructor(
    private dialogRef: MatDialogRef<FAQOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { tech: string }
  ) {}

  @HostListener("document:keydown", ["$event"])
  onKeyDown(event) {
    if (event.key == "Escape") {
      this.onClose();
    }
  }

  ngOnInit(): void {
    this.activeTech = this.data.tech;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  scrollTop(): void {
    const element = document.getElementById("overlay_top");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
}
