<div class="container">
  <div class="header_section">
    {{
      "homeOwenerDashboard.dashboard.electrificationProgress.header" | translate
    }}
  </div>
  <div class="sub_header">
    {{
      "homeOwenerDashboard.dashboard.electrificationProgress.subHeader"
        | translate
    }}
  </div>
  <p class="tc fw6">{{ progressValue + "%" }}</p>
  <ul class="bar_section">
    <li class="bar_point" *ngFor="let item of barPoints"></li>
  </ul>
  <div class="label_section mb_20"><span>0%</span> <span>100%</span></div>
  <div class="header_section alreadyhave_icon mb_12">
    <mat-icon>check_circle_outline</mat-icon
    ><span>{{
      "homeOwenerDashboard.dashboard.electrificationProgress.subText"
        | translate
    }}</span>
  </div>
  <yt-already-tech-card
    [techList]="techList"
    (removeTech)="onRemove($event)"
  ></yt-already-tech-card>
</div>
