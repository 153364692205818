import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  HelpMeChooseRequestModel,
  HelpMeChooseResponseModel,
} from "./help-me-choose.model";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";

@Injectable()
export class HelpMeChooseService {
  constructor(
    private httpClient: HttpClient,
    private restAPIService: DashboardAPIService
  ) {}

  getHelpMeChooseData(
    request: HelpMeChooseRequestModel
  ): Observable<HelpMeChooseResponseModel> {
    return this.httpClient.get<HelpMeChooseResponseModel>(
      `${this.restAPIService.API_END_POINT}${request.techName}Catalog/detail/${this.restAPIService.getUserDetails().userName}?filterParam=${request.filterBy}&sortParam=${request.sortBy}`
    );
  }

  getEvHelpMeChooseData(
    request: HelpMeChooseRequestModel
  ): Observable<HelpMeChooseResponseModel> {
    return this.httpClient.get<HelpMeChooseResponseModel>(
      `${this.restAPIService.API_END_POINT}evCatalog/detail/DB/${this.restAPIService.getUserDetails().userName}?filterParam=${request.filterBy}&sortParam=${request.sortBy}&ev=${request.techName}`
    );
  }
}
