<div style="margin-top: 21px" id="strength" #strength>
  <div class="flex bar_alignment mobile_alignment">
    <div class="barLabel">{{ barLabel }}</div>
    <div class="flex bar_alignment">
      <p class="text_style">{{ msg }}</p>
      <ul id="strengthBar">
        <li class="point" [style.background-color]="bar0"></li>
        <li class="point" [style.background-color]="bar1"></li>
        <li class="point" [style.background-color]="bar2"></li>
      </ul>
    </div>
  </div>
</div>
