import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  NotificationModel,
  NotificationSettingsModel,
} from "./notification-center.model";
import { DashboardAPIService } from "../../rest-api/dashboard/dashboard-api.service";
import { Observable } from "rxjs";

@Injectable()
export class NotificationCenterService {
  public readonly API_END_POINT;
  public readonly notificationDetailUrl;
  public readonly getNotificationStatusUrl;
  public readonly putNotificationUpdateUrl;

  constructor(
    private httpClient: HttpClient,
    public dashboardAPI: DashboardAPIService
  ) {
    this.API_END_POINT = this.dashboardAPI.API_END_POINT;
    this.notificationDetailUrl =
      this.API_END_POINT + "ho/dashboard/notificationDetail/";
    this.getNotificationStatusUrl =
      this.API_END_POINT + "ho/dashboard/notificationStatus/";
    this.putNotificationUpdateUrl =
      this.API_END_POINT + "ho/dashboard/notificationUpdate/";
  }

  getNotificationDetail(): Observable<NotificationModel[]> {
    return this.httpClient.get<NotificationModel[]>(
      this.notificationDetailUrl + this.dashboardAPI.getUserDetails().userName
    );
  }

  getNotificationStatus(): Observable<NotificationSettingsModel> {
    return this.httpClient.get<NotificationSettingsModel>(
      this.getNotificationStatusUrl +
        this.dashboardAPI.getUserDetails().userName
    );
  }

  putNotificationUpdate(notificationStatus: { incentivesEmailMe: boolean }) {
    return this.httpClient.put(
      this.putNotificationUpdateUrl +
        this.dashboardAPI.getUserDetails().userName,
      notificationStatus,
      { observe: "response" }
    );
  }
}
