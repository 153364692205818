import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from "@angular/core";
import { TourGuideModel } from "./tour-guide.model";

@Component({
  selector: "yt-tour-guide",
  templateUrl: "./tour-guide.component.html",
  styleUrls: ["./tour-guide.component.scss"],
})
export class TourGuideComponent implements OnInit, AfterViewInit {
  positionTop: number;
  positionLeft: number;
  offSetWidth: number;
  offSetHeight: number;
  offSetTop: number;
  offSetLeft: number;
  offsetRight: number;
  containerElement: HTMLElement;
  container;
  matContainer;
  title: string;
  bodyText: string;
  buttonText: string;
  showTourGuide: boolean;
  currentStep = 0;
  totalStep = 0;
  selector: string;
  isSUNW: boolean;
  onLastStep: boolean = false;
  dashboardContainer;
  pointerElement: HTMLElement | null;
  childSelector: string;
  hasScrolled: boolean = false;
  private resizeObserver: ResizeObserver | null = null;
  private scrollHandler = this.onScroll.bind(this);
  @Input() stepObject: TourGuideModel[];
  @Input() vendorCode: string;
  @Input() onLandingPage: boolean;
  @Output() closeTourGuide: EventEmitter<void> = new EventEmitter();
  @Output() showHomeSettings: EventEmitter<boolean> = new EventEmitter();

  onScroll(): void {
    this.rePositionContainer();
  }

  ngAfterViewInit(): void {
    this.resizeObserver = new ResizeObserver(() => this.onPositionChange());
    this.stepObject.forEach((el) => {
      if (el.selector) {
        const element = document.querySelector(el.selector);
        if (element) {
          this.resizeObserver?.observe(element);
        }
      }
    });
    window.addEventListener("scroll", this.scrollHandler, true);
  }

  onPositionChange(): void {
    if (this.stepObject[this.currentStep].selector) {
      const element = document.querySelector(
        this.stepObject[this.currentStep].selector
      );
      if (element) {
        this.rePositionContainer();
      }
    }
  }

  ngOnInit(): void {
    this.container = document.querySelector(".tour-guide");
    this.matContainer =
      document.querySelector(".mat-drawer-content") ||
      document.querySelector("body");
    this.totalStep = this.stepObject.length;
    this.startTourGuide();
    this.matContainer.style.overflow = "hidden";
    this.dashboardContainer = document.querySelector(".yt__dashboard");
    this.dashboardContainer.classList.add("tour-guide-overlay");
    if (this.vendorCode === "SUNW") {
      this.isSUNW = true;
    }
  }

  startTourGuide(): void {
    this.title = this.stepObject[this.currentStep].title;
    this.bodyText = this.stepObject[this.currentStep].bodyText;
    this.buttonText = this.stepObject[this.currentStep].buttonText;
    this.selector = this.stepObject[this.currentStep].selector;
    this.childSelector = this.stepObject[this.currentStep].childSelector!;
    const bodyElement = document.querySelector(".tour-guide__body");
    const headerElement = document.querySelector(".tour-guide__header");
    if (bodyElement) bodyElement.innerHTML = this.bodyText;
    if (headerElement) headerElement.innerHTML = this.title;
    this.checkSelector();
  }

  defaultPositionContainer(): void {
    this.container.style.left = `50%`;
    this.container.style.top = `50%`;
    this.matContainer.scrollTop = 0;
  }

  rePositionContainer(): void {
    this.containerElement.style.pointerEvents = "none";
    let offsetTop, offsetLeft;
    if (this.pointerElement) {
      const childValues = this.getPositionValues(this.pointerElement);
      offsetTop = childValues.top + childValues.height;
      offsetLeft = childValues.left + childValues.width / 2;
    } else {
      const parentValues = this.getPositionValues(this.containerElement);
      offsetTop = parentValues.top + parentValues.height;
      offsetLeft = parentValues.left + parentValues.width / 2;
    }
    this.container.style.left = `${offsetLeft}px`;
    this.container.style.top = `${offsetTop}px`;
    if (this.containerElement) {
      this.container.classList.add("tooltip");
    }
    this.containerElement.scrollIntoView({
      behavior: "auto",
      block: "center",
    });
  }

  getPositionValues(element: HTMLElement) {
    return element.getBoundingClientRect();
  }

  onNext(): void {
    this.hasScrolled = false;
    if (this.containerElement)
      this.containerElement.style.pointerEvents = "auto";
    if (
      this.stepObject.length > 1 &&
      this.currentStep < this.stepObject.length - 1
    ) {
      this.currentStep += 1;
      this.startTourGuide();
      if (this.currentStep === this.stepObject.length - 1) {
        this.onLastStep = true;
      }
    } else {
      if (this.stepObject[this.currentStep].scrollBack) {
        const scrollElement = document.querySelector(
          this.stepObject[this.currentStep].scrollBack!
        );
        if (scrollElement)
          scrollElement.scrollIntoView({
            behavior: "smooth",
          });
      } else {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
      this.ngOnDestroy();
    }
  }

  checkSelector(): void {
    if (this.selector) {
      this.containerElement = document.querySelector(this.selector)!;
      if (this.childSelector) {
        this.pointerElement = document.querySelector(this.childSelector)!;
      } else {
        this.pointerElement = null;
      }
      this.removeFocus();
      this.containerElement.classList.add("tour-guide-focus");
      this.rePositionContainer();
    } else {
      this.defaultPositionContainer();
    }
  }

  removeFocus(): void {
    const focusClass = document.querySelector(".tour-guide-focus");
    if (focusClass) {
      focusClass.classList.remove("tour-guide-focus");
    }
  }

  openHomeSettings() {
    this.showHomeSettings.emit(true);
    this.ngOnDestroy();
  }

  ngOnDestroy(): void {
    if (this.containerElement)
      this.containerElement.style.pointerEvents = "auto";
    this.matContainer.style.overflowY = "auto";
    this.dashboardContainer.classList.remove("tour-guide-overlay");
    this.removeFocus();
    this.closeTourGuide.emit();
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    window.removeEventListener("scroll", this.scrollHandler, true);
  }
}