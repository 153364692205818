<scroll-top></scroll-top>
<div class="page_container">
  <div class="yt_width">
    <div class="flex items-center flex-column vh-100 justify-center">
      <mat-icon class="yt__vida-loca yt__ba-vida-loca yt__br5">done</mat-icon>
      <div class="yt_header">Thank You</div>
      <div class="yt_subheader yt_ptr-10" *ngIf="dashboardAccess">
        <span *ngIf="declarationText">{{ vendorDescription }}</span>
        {{ header }}
        <span class="fw6">support@yellowtin.com</span>
        <span>
          {{ subHeader }} <span class="fw6">{{ userDetails }}</span>
        </span>

        <div class="pd_button">
          <button
            mat-raised-button
            color="accent"
            class="login_button"
            (click)="onLogin()"
          >
            LOG IN TO DASHBOARD
          </button>
        </div>
        <div class="yt_spam-text yt_pt-5">
          Don't see the email? Check your junk/ spam folder.
        </div>
        <div class="yt_spam-text yt_pt-20">
          Incorrect email address? Contact
          <a class="link" href="mailto:support@yellowtin.com"
            >YellowTin Support</a
          >
        </div>
      </div>
      <div class="yt_subheader yt_ptr-10" *ngIf="userFromDashboard">
        <div>
          You have completed your energy data authorization via the PG&E Share
          My Data platform. Please check the status in the energy usage section
          of your dashboard.
        </div>
        <div class="tc close_button">
          <button
            mat-raised-button
            color="accent"
            class="w-100"
            (click)="onClose()"
          >
            CLOSE WINDOW
          </button>
        </div>
      </div>
      <div
        class="yt_subheader"
        *ngIf="dashboardAccess == false && userFromDashboard == false"
      >
        Your submission is received and {{ solarVendorName }} will contact you
        soon.
      </div>
    </div>
  </div>
  <yt-footer class="page_container"></yt-footer>
</div>
<!-- <yt-captcha></yt-captcha> -->
