<nav
  class="flex justify-between background-color-white height-10-nav menu_text"
>
  <div class="padding_left flex items-center">
    <img
      class="yt-cursor-hand yltn_logo"
      [src]="logo.url"
      (click)="onClickLogo(logo.href)"
      *ngIf="logo && logo.customer && !logo.customer.poweredByUrl"
      alt="dashboardlogo"
      loading="lazy"
      width="100px"
    />
    <img
      class="yt-cursor-hand logo-margin_right logo_width"
      [src]="!logoStatus ? logo.url : logo.customer?.url"
      (click)="onClickLogo()"
      [ngClass]="{
        'work-logo_width': vendorCode === 'WORK',
        'wsp-logo_width': vendorCode === 'WSPP'
      }"
      *ngIf="logo"
      alt="dashboardlogo"
      loading="lazy"
    />
  </div>
  <div class="login_section">
    <span
      class="yt-cursor-hand fw6 show_link mr4"
      routerLink="/incentives"
      routerLinkActive="fw6"
    >
      Rebates & Incentives
    </span>
    <span
      class="mr4 yt-cursor-hand show_link"
      routerLink="/dashboard/learning-hub"
      routerLinkActive="fw6"
      >Learning Hub</span
    >
    <span
      class="mr4 yt-cursor-hand show_link"
      routerLinkActive="fw6"
      routerLink="/dashboard/login"
      >Login</span
    >
    <span
      class="yt-cursor-hand show_link"
      routerLinkActive="fw6"
      routerLink="/register"
      >Sign up</span
    >
    <!-- HAM MOBILE For unauthorized top nav -->
    <div class="yt_mobile_view ml4">
      <span class="material-icons" [matMenuTriggerFor]="additionalInfo">
        menu
      </span>
    </div>
  </div>
</nav>
<!--UA-Page Nav Menu Drop-down -->
<mat-menu #additionalInfo="matMenu" [overlapTrigger]="false">
  <div class="menu-subheading_padding" routerLink="/incentives">
    <img
      src="assets/dashboard/top-nav/incentives.svg"
      alt="incentives"
      height="18px"
      width="18px"
      loading="lazy"
    />
    <span class="yt-subHeading-style icon_padding_left"
      >Rebates & Incentives</span
    >
  </div>
  <div class="menu-subheading_padding" routerLink="/dashboard/learning-hub">
    <img
      src="assets/dashboard/top-nav/Electrify.svg"
      alt="learning hub"
      height="18px"
      width="18px"
      loading="lazy"
    />
    <span class="yt-subHeading-style icon_padding_left"> Learning Hub </span>
  </div>
  <div class="menu-subheading_padding" routerLink="/dashboard/login">
    <img
      src="assets/dashboard/top-nav/login.svg"
      alt="login"
      height="18px"
      width="18px"
      loading="lazy"
    />
    <span class="yt-subHeading-style icon_padding_left">Login</span>
  </div>
  <div class="menu-subheading_padding" routerLink="/register">
    <img
      src="assets/dashboard/top-nav/signup.svg"
      alt="signup"
      height="18px"
      width="18px"
      loading="lazy"
    />
    <span class="yt-subHeading-style icon_padding_left">Sign up</span>
  </div>
</mat-menu>
<div class="page_container">
  <div class="non_dashboard overlay_top_bg">
    <div class="overlay_top_content margin_20">
      <div class="header">{{ header }}</div>
      <div class="subHeader">{{ subHeader }}</div>
      <div class="hero_img">
        <img
          src="../../assets/hero_img.png"
          alt="hero image"
          width="100%"
          height="400px"
        />
      </div>
      <div class="incentives_container">
        <div class="incentives_header">
          Enter your household information to find out.
        </div>
        <div class="form__wrapper w80 mt_0" [formGroup]="incentivesForm">
          <div class="form__section">
            <div class="input_container">
              <div class="label">Address</div>
              <div class="yt__maxwidth">
                <mat-form-field
                  floatLabel="auto"
                  appearance="outline"
                  hideRequiredMarker="true"
                >
                  <input
                    id="placeAddress"
                    matInput
                    required
                    type="search"
                    #address
                    placeholder="Address"
                    formControlName="address"
                    autocomplete="noneaddress"
                    (input)="onValidateAddress(address.value)"
                  />
                  <mat-hint
                    align="end"
                    *ngIf="!(invalidAddress && !isFieldValid('address', true))"
                    >We don't store your address into our system.
                  </mat-hint>
                  <mat-error *ngIf="isFieldValid('address', true)">
                    Required!
                  </mat-error>
                  <mat-error
                    *ngIf="
                      outOfRange &&
                      !incentivesForm.controls['address'].hasError('required')
                    "
                    >Please enter valid zip code</mat-error
                  >
                  <div
                    *ngIf="invalidAddress && !isFieldValid('address', true)"
                    class="yt-error-address"
                  >
                    Select value from dropdown
                  </div>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="form__section">
            <div class="maxW48">
              <div class="label">Building type and ownership</div>
              <div class="yt__maxwidth">
                <mat-form-field floatLabel="auto" appearance="outline">
                  <mat-select
                    id="buildingType"
                    formControlName="buildingType"
                    placeholder="Building Type"
                  >
                    <mat-option
                      *ngFor="let buildingType of buildingTypeList"
                      [value]="buildingType.value"
                      >{{ buildingType.displayValue }}</mat-option
                    >
                  </mat-select>
                  <mat-error
                    *ngIf="
                      incentivesForm.controls['buildingType'].hasError(
                        'required'
                      )
                    "
                  >
                    Required!
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="maxW48">
              <div class="label">Utility provider</div>
              <div class="yt__maxwidth">
                <mat-form-field
                  floatLabel="auto"
                  appearance="outline"
                  [ngClass]="{
                    'disable-field':
                      incentivesForm.controls['zipCode'].invalid ||
                      outOfRange ||
                      emptyProviderList
                  }"
                >
                  <mat-select
                    id="utilityProvider"
                    formControlName="electricUtilityName"
                    placeholder="Utility Provider"
                    [value]="utilityId"
                    (selectionChange)="onSelectUtilityProvider($event)"
                  >
                    <mat-option
                      *ngFor="
                        let electricUtilityName of electricUtilityNameList
                      "
                      [value]="electricUtilityName.utilityId"
                      >{{ electricUtilityName.utilityName }}</mat-option
                    >
                  </mat-select>
                  <mat-error
                    *ngIf="
                      incentivesForm.controls['electricUtilityName'].touched &&
                      incentivesForm.controls['electricUtilityName'].hasError(
                        'required'
                      )
                    "
                  >
                    Required!
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="button_wrapper">
            <button
              mat-raised-button
              #searchButton
              color="accent"
              [disabled]="incentivesForm.invalid || outOfRange"
              (click)="onSave()"
            >
              SEARCH
            </button>
          </div>
        </div>

        <div class="noNotification-box" *ngIf="haveEmptyRebateDetails">
          <img
            src="assets/dashboard/noIncentives.png"
            height="50px"
            width="50px"
          />
          <p class="text-padding noNotification_text">
            No incentives currently available
          </p>
        </div>

        <div *ngIf="technologyIncentives && !haveEmptyRebateDetails">
          <div class="incentives_header">Detailed Incentives Breakdown</div>
          <div class="ExpandCollapse_link">
            <button (click)="accordion.openAll()">Expand All</button> |
            <button (click)="accordion.closeAll()">Collapse All</button>
          </div>
          <div class="panel_container">
            <div>TYPE</div>
            <div>AMOUNT</div>
          </div>
          <mat-accordion class="example-headers-align" multi>
            <div *ngFor="let tech of technologyIncentivesList">
              <mat-expansion-panel
                (opened)="(true)"
                [expanded]="true"
                *ngIf="tech[1].rebateDetails.length"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title class="header_wrapper">
                    <span>{{ tech[0] | techName }}</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div
                  class="incentives_lables"
                  *ngFor="let rebate of tech[1].rebateDetails"
                >
                  <span class="minW_40" *ngIf="rebate.rebateLink">
                    <a
                      class="yt_rebateName yt_rebateName_link"
                      [href]="rebate.rebateLink"
                      target="_blank"
                    >
                      <span>{{ rebate.rebateName }}</span> </a
                    ><span *ngIf="rebate.specialCriteria">*</span>
                  </span>
                  <span *ngIf="!rebate.rebateLink" class="none_link minW_40">
                    {{ rebate.rebateName }}
                  </span>
                  <span class="tr">{{ rebate.rebate }}</span>
                </div>
              </mat-expansion-panel>
            </div>
          </mat-accordion>

          <p class="special_criteria" *ngIf="showSpecialCriteria">
            * Special conditions may apply
          </p>
        </div>
        <p
          class="disclaimer_text"
          *ngIf="!haveEmptyRebateDetails && haveGetStartedButton"
        >
          Disclaimer: This is just an estimate. Please note certain incentives
          cannot be combined and hence the final eligible incentives may be
          lower.
        </p>
        <div *ngIf="haveGetStartedButton" class="signup_button">
          <p class="sign_header">Sign up to view your personalized savings</p>
          <p class="sign_subHeader">
            Get notified about new incentives in your area.
          </p>
          <button mat-raised-button color="accent" (click)="onGetStarted()">
            GET STARTED
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- loader -->
<yt-loader *ngIf="loader"></yt-loader>
