import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DashboardFooterComponent } from "./dashboard-footer.component";

@NgModule({
    imports: [CommonModule],
    declarations: [DashboardFooterComponent],
    providers: [],
    exports: [DashboardFooterComponent]
})
export class DashboardFooterModule {}
