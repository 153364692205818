import { NgModule } from "@angular/core";
import { DashboardV3Component } from "./dashboard-v3.component";
import { ScrollTopModule } from "../../../shared/scroll-top/index";
import { DashboardFooterModule } from "../../shared/dashboard-footer/index";
import { AlertCarouselComponent } from "./alert-carousel/alert-carousel.component";
import { CommonModule } from "@angular/common";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { TechCardsComponent } from "../../shared/mini-cards/tech-cards/tech-cards.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { RedirectLoaderModule } from "src/app/shared/redirect-loader";
import { CleanChoicesModule } from "../dashboard-v2/clean-choices";
import { LoaderModule } from "../../shared/loader/index";
import { SettingOverlayModule } from "../dashboard-v2/setting-overlay";
import { TourGuideModule } from "../../shared/tour-guide/index";
import { IncentivesComponent } from "../dashboard-v2/incentives/incentives.component";
import { IncentivesOverlayModule } from "../dashboard-v2/incentives/incentives-overlay";

@NgModule({
    declarations: [
        DashboardV3Component,
        AlertCarouselComponent,
        TechCardsComponent,
        IncentivesComponent
    ],
    exports: [DashboardV3Component],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatRadioModule,
        MatSelectModule,
        MatTooltipModule,
        ScrollTopModule,
        TranslateModule,
        DashboardFooterModule,
        CleanChoicesModule,
        RedirectLoaderModule,
        LoaderModule,
        SettingOverlayModule,
        TourGuideModule,
        IncentivesOverlayModule
    ]
})
export class DashboardV3Module {}
