import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RoofTypeQAComponent } from "./roof-type-QA/roof-type-QA.component";
import { RoofShadeQAComponent } from "./roof-shade-QA/roof-shade-QA.component";
import { AnswerBoxModule } from "./answer-box/index";
import { RoofTypeAnswerBoxModule } from "./roof-type-answer-box/index";
import { RoofShadeAnswerBoxModule } from "./roof-shade-answer-box/index";
import { RoofQAComponent } from "./roof-QA/roof-QA.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatRadioModule } from "@angular/material/radio";

@NgModule({
  imports: [
    CommonModule,
    RoofShadeAnswerBoxModule,
    RoofTypeAnswerBoxModule,
    AnswerBoxModule,
    ReactiveFormsModule,
    FormsModule,
    MatRadioModule,
  ],
  declarations: [RoofTypeQAComponent, RoofShadeQAComponent, RoofQAComponent],
  exports: [RoofQAComponent, RoofShadeQAComponent, RoofTypeQAComponent],
})
export class RoofTopModule {}
