<div class="header">
  <span *ngIf="isPGE && revokedAuth">Approach 2:</span> Share My Data
</div>
<div class="info_text">
  <p *ngIf="yellowTinInfo">
    {{ vendorName }} recommends sharing your energy data via the
    <span class="link_text" (click)="openDialogBox('PGE')"
      >PG&E Share My Data</span
    >
    platform.
  </p>
  <p *ngIf="yellowTinInfo" class="yt_padding-top">
    Your detailed energy data will be used to more accurately calculate your
    savings and recommend optimal clean energy choices.
  </p>
  <p *ngIf="solarInstaller">
    {{ vendorName }} recommends sharing your energy data via the
    <span class="link_text" (click)="openDialogBox('PGE')"
      >PG&E Share My Data</span
    >
    platform.
  </p>
  <p *ngIf="solarInstaller" class="yt_padding-top">
    The detailed energy data we receive will allow us to calculate your system
    size, solar savings and payback more accurately.
  </p>
  <p *ngIf="!authorizationSectionPGE" class="yt_error-info">
    Note: PG&E Share My Data platform is currently under maintenance. You can
    authorize the release of your energy usage data once the maintenance is
    complete. Please choose the Manual Estimate option at this time.
  </p>
</div>

<div class="link_section">
  <div class="link_mobile">
    <div>
      <a [href]="certificateLink">
        <img
          src="assets/energy-usage/Godaddy_Seal.gif"
          class="image_section"
          (click)="openCertificate()"
        />
      </a>
    </div>
    <div class="secure_text">
      Secure link to share your data only with
      <span class="link__text" (click)="openDialogBox('YT')" *ngIf="disableText"
        >YellowTin</span
      >
      <span
        class="link__text"
        id="yltn_text"
        (click)="openDialogBox('YT')"
        *ngIf="!disableText"
        >YellowTin.</span
      >
      <span *ngIf="disableText">&nbsp; and {{ vendorName }}.</span>
    </div>
  </div>
</div>
<div class="loginButton">
  <button
    mat-raised-button
    color="accent"
    class="w-100"
    [disabled]="!authorizationSectionPGE"
    (click)="openDialogBox('confirm')"
  >
    LOGIN TO PG&E
    <mat-icon class="yt_mat-icon">launch</mat-icon>
  </button>
</div>
