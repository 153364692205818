import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EVChargerDataModel } from "../../../../model/userdata.model";
import { RadioOptionsModel } from "../../../../../../rest-api/dashboard/dashboard-api.model";
import { DashboardAPIService } from "../../../../../../rest-api/dashboard/dashboard-api.service";
import { MatRadioChange } from "@angular/material/radio";
import { MatDialog } from "@angular/material/dialog";
import { HelpMeChooseComponent } from "../../../../../shared/help-me-choose/help-me-choose.component";
import { MNFAndModel } from "../../../../../../homeowner-product/energy-choices/energy-choices.model";
import { RestAPIService } from "../../../../../../rest-api/rest-api.service";
import { SettingsDialogData } from "../../setting-overlay.component.model";
import { SettingsOverlayService } from "../../settings-overlay.service";
import { Subject } from "rxjs";

@Component({
  selector: "yt-ev-chargers",
  templateUrl: "ev-chargers.component.html",
  styleUrls: ["../../common-tech.scss"],
})
export class EvChargersComponent implements OnInit {
  evChargerForm: FormGroup;
  ownValue: string;
  hideFormField: boolean = true;
  chargerData: string;
  evChargerDataModel: EVChargerDataModel[];
  selectedRadioButton: string;
  vendorCode: string;
  radioButtonOptions: RadioOptionsModel[];
  mnfDropDownList: MNFAndModel[] = [];
  evChargerSettings: EVChargerDataModel[];
  @Input() activeIcon: string;
  @Input() evChargerDetails;
  subject: Subject<any> = new Subject();
  @Output() techSave: EventEmitter<SettingsDialogData> = new EventEmitter();

  constructor(
    private dashboardAPIService: DashboardAPIService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private restAPI: RestAPIService,
    private settingService: SettingsOverlayService
  ) {
    this.evChargerForm = this.fb.group({
      own: ["", Validators.required],
      model: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.dashboardAPIService.getRadioButtonDetails().subscribe((data) => {
      this.radioButtonOptions = data.radioButtons;
    });
    this.evChargerDataModel = this.settingService.checkSettingsInfoError(
      "evChargers"
    )
      ? this.settingService.settingsInfoErrors.transportation?.evChargers!
      : this.settingService.checkSettingsInfo("evChargers")
      ? this.settingService.settingsInfo.transportation?.evChargers!
      : this.dashboardAPIService.energyChoicesData.transportation.evChargers!;

    this.evChargerDataModel.forEach((evCharger: EVChargerDataModel) => {
      this.ownValue = evCharger.own;
      this.setSelectedRadioValues(this.ownValue);
      this.evChargerForm.get("own")?.setValue(evCharger.own);
      this.evChargerForm.get("model")?.setValue(evCharger.mnfAndModel);
      if (evCharger.own === "noPlan") {
        this.hideFormField = true;
        this.evChargerForm.get("model")?.enable();
      } else if (evCharger.own === "yesPlan") {
        this.hideFormField = true;
        this.evChargerForm.get("model")?.enable();
      } else if (evCharger.own === "yes") {
        this.hideFormField = false;
      }
    });
    this.vendorCode = this.dashboardAPIService.getUserDetails().solarVendorCode;
    this.restAPI.getMakeAndModelList("evChargers").subscribe((data) => {
      this.mnfDropDownList = data.mnfDropDownList;
    });
    if (this.activeIcon) {
      if (this.activeIcon === "favorite") {
        this.evChargerForm.get("own")?.setValue("yesPlan");
      } else if (this.activeIcon === "alreadyHave") {
        this.evChargerForm.get("own")?.setValue("yes");
      }
      this.onValueChanges();
    }
    this.setSelectedRadioValues(this.evChargerForm.get("own")?.value);
    this.condition();
    this.onValueChanges();
  }

  condition(): void {
    this.ownValue = this.evChargerForm.get("own")?.value;
    if (this.ownValue === "noPlan") {
      this.hideFormField = true;
      this.evChargerForm.get("model")?.enable();
    } else if (this.ownValue === "yesPlan") {
      this.hideFormField = true;
      this.evChargerForm.get("model")?.enable();
    } else if (this.ownValue === "yes") {
      this.hideFormField = false;
    }
  }

  onRadioChange(event: MatRadioChange): void {
    this.setSelectedRadioValues(event.value);
  }

  setSelectedRadioValues(value: string) {
    this.selectedRadioButton = value;
  }

  openDialogStatus(): void {
    let dialogRef = this.dialog.open(HelpMeChooseComponent, {
      width: "90%",
      height: "75%",
      autoFocus: false,
      backdropClass: "dark-backdrop",
      panelClass: "custom-dialog-container",
      data: { tech: "evCharger" },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.chargerData = result;
        this.evChargerForm.get("model")?.setValue(result);
      }
    });
  }

  onValueChanges(): void {
    this.evChargerForm.valueChanges.subscribe((data) => {
      this.subject.pipe().subscribe(() => {
        if (data.own === "yesPlan" || "noPlan") {
          this.evChargerSettings = [
            {
              own: data.own,
              mnfAndModel: data.model,
            },
          ];
        } else if (data.own === "yes") {
          this.evChargerSettings = [
            {
              own: data.own,
            },
          ];
        }
        if (this.evChargerForm.valid) {
          this.settingService.updateSettingsInfo(
            this.dashboardAPIService.energyChoicesData.transportation,
            this.evChargerSettings,
            "transportation",
            "evChargers"
          );
        } else {
          this.settingService.updateSettingsInfoErrors(
            this.dashboardAPIService.energyChoicesData.transportation,
            this.evChargerSettings,
            "transportation",
            "evChargers"
          );
        }
      });
      this.subject.next();
    });
  }

  onEvChargerDetails(): void {
    this.settingService.putSettingsInfo(this.getSettingsInfo);
  }

  getSettingsInfo = () => {
    if (this.activeIcon) {
      this.techSave.emit({ own: this.ownValue, tech: "evCharger" });
    }
  };
}
