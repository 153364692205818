<div
  id="loader"
  [ngStyle]="{ top: top }"
  [ngClass]="{ relative_loader: relative, blackOverlay: blackLoader }"
>
  <div class="h-100 w-100 flex justify-center items-center flex-column">
    <ng-container *ngIf="!blackLoader">
      <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
      <div class="loadText">Loading your info...</div>
    </ng-container>
    <ng-container *ngIf="blackLoader">
      <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
      <div class="loadText">Applying your changes...</div>
    </ng-container>
  </div>
</div>
