import {
  CategoryColorsModel,
  TechCategoryModel,
} from "../../../overview/model/dashboard.init.model";
import { CategoryListModel } from "../mini-cards.model";

export class TechCardInitial {
  imagePath = "assets/lifestyle/";
  lm_Path = "/dashboard/details/";

  categoryList: CategoryListModel[] = [
    {
      img: "assets/dashboard/dashboard_icons_v2/simpleCost.svg",
      altText: "simpleLowCost",
      viewValue: "Simple/Low Cost",
      value: "simpleLowCost",
    },
    {
      img: "assets/dashboard/dashboard_icons_v2/transport.png",
      altText: "transportation",
      viewValue: "Transportation",
      value: "transportation",
    },
    {
      img: "assets/dashboard/dashboard_icons_v2/solar.png",
      altText: "solarBattery",
      viewValue: "Solar & Battery",
      value: "solarBattery",
    },
    {
      img: "assets/dashboard/dashboard_icons_v2/appliances.svg",
      altText: "appliances",
      value: "appliances",
      viewValue: "Appliances",
    },
    {
      img: "assets/dashboard/dashboard_icons_v2/favorite.svg",
      altText: "favorite",
      value: "favorites",
      viewValue: "Favorites",
    },
  ];

  categoryColorList: CategoryColorsModel = {
    solarBattery: "#DFE154",
    transportation: "#EA6C94",
    simpleLowCost: "#A67BED",
    appliances: "#5BCADA",
  };

  techList: TechCategoryModel = {
    simpleLowCost: {
      ledCflBulbs: {
        own: "",
        priceAfterIncentives: 0,
        techParameter: "ledCflBulbs",
        img: `${this.imagePath}LED.png`,
        negativeTooltip:
          "Even if savings are small or zero, efficient lighting provides environmental benefits.",
        toolTip:
          "LED bulbs are an inexpensive way to cut your electricity bill and they last a long, long time. You can choose from a wide range of types.",
        techName: "LED Bulbs",
        navigation: `${this.lm_Path}led-bulbs`,
        link: "",
        dollarSavings: 0,
        carbonSavings: 0,
        leaves: 0,
        netCostLabel: "",
        showTech: false,
        shopNow: false,
        isNegative: false,
        rebateStatus: false,
      },
      smartThermostat: {
        own: "",
        priceAfterIncentives: 0,
        img: `${this.imagePath}thermo.svg`,
        techParameter: "smartThermostat",
        negativeTooltip:
          "Even if savings are small or zero, a smart thermostat provides environmental benefits.",
        toolTip:
          "Make your home more comfortable and save money with a Smart Thermostat. Use your phone or voice for easy control.",
        techName: "Smart Thermostat",
        navigation: `${this.lm_Path}smart-thermostat`,
        link: "",
        dollarSavings: 0,
        carbonSavings: 0,
        leaves: 0,
        netCostLabel: "",
        showTech: false,
        shopNow: false,
        isNegative: false,
        rebateStatus: false,
      },
      smartPowerStrips: {
        own: "",
        priceAfterIncentives: 0,
        img: `${this.imagePath}SPS.svg`,
        techParameter: "smartPowerStrips",
        negativeTooltip:
          "Even if savings are small or zero, smart power strips provide environmental benefits.",
        toolTip: `'Appliances' standby mode can suck large amounts of electricity from your home over time. Smart power strips help enormously to help eliminate this waste.`,
        techName: "Smart Power Strips",
        navigation: `${this.lm_Path}smart-power-strips`,
        link: "",
        dollarSavings: 0,
        carbonSavings: 0,
        leaves: 0,
        netCostLabel: "",
        showTech: false,
        shopNow: false,
        isNegative: false,
        rebateStatus: false,
      },
      showerHeadsAerators: {
        own: "",
        priceAfterIncentives: 0,
        img: `${this.imagePath}showerhead.svg`,
        techParameter: "showerHeadsAerators",
        negativeTooltip:
          "Even if savings are small or zero, low-flow showerheads and aerators provide environmental benefits.",
        toolTip:
          "Low-flow showerheads and aerators are inexpensive and easy to install. Cut both your energy and water use at the same time.",
        techName: "Showerheads and Aerators",
        navigation: `${this.lm_Path}showerheads-aerators`,
        link: "",
        dollarSavings: 0,
        carbonSavings: 0,
        leaves: 0,
        netCostLabel: "",
        showTech: false,
        shopNow: false,
        isNegative: false,
        rebateStatus: false,
      },
      weatherStripping: {
        own: "",
        priceAfterIncentives: 0,
        img: `${this.imagePath}Weatherstripping.svg`,
        techParameter: "weatherStripping",
        negativeTooltip:
          "Even if savings are small or zero, weather stripping provides environmental benefits.",
        toolTip:
          "Even small air leaks can add up to a surprisingly large increase in your energy bills. Weather stripping is a simple way to plug the leaks.",
        techName: "Weather Stripping",
        navigation: `${this.lm_Path}weather-stripping`,
        link: "",
        dollarSavings: 0,
        carbonSavings: 0,
        leaves: 0,
        netCostLabel: "",
        showTech: false,
        shopNow: false,
        isNegative: false,
        rebateStatus: false,
      },
      everGreen: {
        own: "",
        priceAfterIncentives: 0,
        img: `assets/dashboard/dashboard_icons_v2/everGreen.png`,
        techParameter: "everGreen",
        negativeTooltip:
          "There is an increased cost for buying 100% renewable electricity to replace your current usage.",
        toolTip:
          "It'll cost a little more each month, but choosing a 100% renewable electricity plan can eliminate nearly all the greenhouse gas emissions from your electricity use.",
        techName: "100% Renewable electricity (Evergreen)",
        navigation: `${this.lm_Path}renewable-electricity`,
        link: "",
        dollarSavings: 0,
        carbonSavings: 0,
        leaves: 0,
        netCostLabel: "",
        showTech: false,
        shopNow: false,
        isNegative: false,
      },
    },
    transportation: {
      evChargers: {
        own: "",
        priceAfterIncentives: 0,
        techName: "EV Charger",
        techParameter: "evChargers",
        negativeTooltip:
          "An EV charger won’t directly save you any money, but it makes saving money with your EV a lot more convenient.",
        toolTip:
          "Be ready to drive in the morning! A home EV Charger on a 240V circuit can add about 25 miles or more to your car's battery for each hour of charging.",
        navigation: `${this.lm_Path}ev-chargers`,
        link: "",
        netCostLabel: "",
        img: `${this.imagePath}/evcLearnmore.png`,
        dollarSavings: 0,
        carbonSavings: 0,
        leaves: 0,
        datePurchased: "",
        showTech: false,
        shopNow: false,
        isNegative: true,
        rebateStatus: false,
      },
      eBike: {
        own: "",
        priceAfterIncentives: 0,
        techName: "Electric Bike",
        techParameter: "eBike",
        negativeTooltip:
          "When the displaced vehicle miles are zero, there are no electric bike savings. Also, when both an electric vehicle and solar are selected, any savings associated with an electric bike are included in with the electric vehicle savings.",
        toolTip:
          "Electric bikes are a fun, low-carbon option that'll help you save money, avoid parking hassles, and help save the environment.",
        navigation: `${this.lm_Path}electric-bike`,
        link: "",
        netCostLabel: "",
        img: `${this.imagePath}eBike.png`,
        dollarSavings: 0,
        carbonSavings: 0,
        leaves: 0,
        datePurchased: "",
        showTech: false,
        shopNow: false,
        isNegative: false,
        rebateStatus: false,
      },
      ev1: {
        own: "",
        drivingRange: "",
        financial: "",
        monthlyPayment: 0,
        makeAndModel: "",
        priceAfterIncentives: 0,
        paybackPeriod: "",
        dollarSavings: 0,
        carbonSavings: 0,
        leaves: 0,
        datePurchased: "",
        img: `${this.imagePath}ev1.svg`,
        techName: "",
        techParameter: "ev1",
        navigation: `${this.lm_Path}electric-vehicle-1`,
        link: "",
        toolTip:
          "Electric vehicles are fun to drive, with lots of pep! More importantly, they can cut your energy and maintenance costs while substantially reducing your carbon emissions.",
        negativeTooltip:
          "Due to its high electricity usage per mile traveled, it is more economical to operate this PHEV on gasoline than electricity.",
        netCostLabel: "",
        showTech: false,
        shopNow: false,
        isNegative: false,
        rebateStatus: false,
      },
      ev2: {
        own: "",
        drivingRange: "",
        financial: "",
        monthlyPayment: 0,
        makeAndModel: "",
        priceAfterIncentives: 0,
        paybackPeriod: "",
        dollarSavings: 0,
        carbonSavings: 0,
        leaves: 0,
        datePurchased: "",
        img: `${this.imagePath}ev2.png`,
        techName: "",
        techParameter: "ev2",
        navigation: `${this.lm_Path}electric-vehicle-2`,
        link: "",
        toolTip:
          "Electric vehicles are fun to drive, with lots of pep! More importantly, they can cut your energy and maintenance costs while substantially reducing your carbon emissions.",
        negativeTooltip:
          "Due to its high electricity usage per mile traveled, it is more economical to operate this PHEV on gasoline than electricity.",
        netCostLabel: "",
        showTech: false,
        shopNow: false,
        isNegative: false,
        rebateStatus: false,
      },
    },
    appliances: {
      hpwh: {
        own: "",
        priceAfterIncentives: 0,
        makeAndModel: "",
        dollarSavings: 0,
        carbonSavings: 0,
        leaves: 0,
        img: `${this.imagePath}hpwh.svg`,
        techName: "Heat Pump Water Heater",
        techParameter: "hpwh",
        netCostLabel: "",
        datePurchased: "",
        navigation: `${this.lm_Path}water-heater`,
        link: "",
        toolTip:
          "Upgrade to a super-efficient heat pump water heater to save money and shrink your carbon footprint. Generous incentives are often available to help cover the upfront costs.",
        negativeTooltip:
          "Water heating with natural gas is cheaper than heating with an electric water heater. However, switching from natural gas reduces carbon emissions.",
        showTech: false,
        shopNow: false,
        isNegative: false,
        rebateStatus: false,
      },
      hpsc: {
        own: "",
        priceAfterIncentives: 0,
        dollarSavings: 0,
        carbonSavings: 0,
        leaves: 0,
        datePurchased: "",
        img: `${this.imagePath}ashp.svg`,
        techName: "Heat Pump Heating & Cooling",
        techParameter: "hpsc",
        netCostLabel: "",
        navigation: `${this.lm_Path}heating-cooling`,
        link: "",
        showTech: false,
        negativeTooltip: "",
        toolTip:
          "A highly efficient heat pump for both heating and cooling can make your home more comfortable and help you cut down on your carbon emissions (especially if you're replacing a natural gas or oil furnace).",
        shopNow: false,
        isNegative: false,
        rebateStatus: false,
      },
      hpd: {
        own: "",
        priceAfterIncentives: 0,
        dollarSavings: 0,
        carbonSavings: 0,
        leaves: 0,
        datePurchased: "",
        netCostLabel: "",
        img: `${this.imagePath}dryer.svg`,
        techName: "Heat Pump Clothes Dryer",
        techParameter: "hpd",
        navigation: `${this.lm_Path}dryer`,
        link: "",
        negativeTooltip:
          "Using a natural gas dryer is cheaper than using a heat pump dryer. However, switching from gas reduces carbon emissions.",
        toolTip:
          "A heat pump dryer is a very efficient way to dry your clothes. They're also simpler to install since there's no need for outside venting.",
        showTech: false,
        shopNow: false,
        isNegative: false,
        rebateStatus: false,
      },
      ic: {
        priceAfterIncentives: 0,
        own: "",
        dollarSavings: 0,
        carbonSavings: 0,
        leaves: 0,
        datePurchased: "",
        img: `${this.imagePath}cooktop.png`,
        techName: "Induction Cooking",
        techParameter: "ic",
        navigation: `${this.lm_Path}cooktop`,
        link: "",
        toolTip:
          "Professional chefs as well as consumers are turning to induction cooking because of its speed and accurate temperature control. Also, when replacing natural gas, induction dramatcally reduces indoor air pollution.",
        negativeTooltip: "",
        netCostLabel: "",
        showTech: false,
        shopNow: false,
        isNegative: false,
        rebateStatus: false,
      },
    },
    solarBattery: {
      solar: {
        dollarSavings: 0,
        carbonSavings: 0,
        leaves: 0,
        monthlyPayment: 0,
        own: "",
        systemSize: 0,
        upfrontCost: 0,
        img: `${this.imagePath}solar.svg`,
        techName: "Rooftop Solar",
        techParameter: "solar",
        netCostLabel: "",
        navigation: `${this.lm_Path}solar`,
        link: "",
        datePurchased: "",
        showTech: false,
        panelStatus: false,
        error1: false,
        error2: false,
        errorTooltip: "",
        paybackPeriod: "",
        toolTip:
          "Reduce your electricity costs long into the future while increasing the value of your home. Solar also helps improve the economics of other green technologies, like EVs and heat pumps.",
        negativeTooltip: "",
        shopNow: false,
        isNegative: false,
        rebateStatus: false,
      },
      battery: {
        own: "",
        img: `${this.imagePath}battery.svg`,
        toolTip:
          "Be ready to meet critical power needs during power outages. Also, when paired with solar, a battery can sometimes help you save money by using stored energy later in the day.",
        negativeTooltip:
          "When operated only for backup power during an emergency, a battery does not provide any savings in electricity costs.",
        navigation: `${this.lm_Path}battery`,
        link: "",
        techName: "Battery",
        netCostLabel: "",
        techParameter: "battery",
        dollarSavings: 0,
        carbonSavings: 0,
        leaves: 0,
        priceAfterIncentives: 0,
        datePurchased: "",
        showTech: false,
        shopNow: false,
        isNegative: false,
        rebateStatus: false,
      },
      portableBattery: {
        own: "",
        img: `${this.imagePath}portable-battery.png`,
        toolTip:
          "Get peace of mind with a portable battery that can be used during power outages. It'll provide power for crucial needs such as phones, computers, and modems.",
        negativeTooltip:
          "When operated only for backup power during an emergency, a battery does not provide any savings in electricity costs.",
        navigation: `${this.lm_Path}portable-battery`,
        link: "",
        techName: "Portable Battery",
        netCostLabel: "Net cost",
        techParameter: "portableBattery",
        dollarSavings: 0,
        carbonSavings: 0,
        leaves: 0,
        priceAfterIncentives: 0,
        datePurchased: "",
        showTech: false,
        shopNow: false,
        isNegative: false,
        rebateStatus: false,
      },
      communitySolar: {
        dollarSavings: 0,
        carbonSavings: 0,
        leaves: 0,
        monthlyPayment: 0,
        own: "",
        systemSize: 0,
        upfrontCost: 0,
        img: `${this.imagePath}Community Solar.png`,
        techName: "Community solar",
        techParameter: "communitySolar",
        netCostLabel: "",
        navigation: `${this.lm_Path}community-solar`,
        link: "",
        datePurchased: "",
        showTech: false,
        toolTip:
          "Community Solar lets you use solar energy even without solar panels on your roof.  Tapping into a local solar farm can help you cut your electricity bill by 10% or more.",
        negativeTooltip:
          "While you can go green by signing up for Community Solar, there's no discount in your area.",
        shopNow: false,
        isNegative: false,
      },
    },
    favorite: {},
  };
}
