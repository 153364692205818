<div class="faq_container">
  <div class="about_technology" *ngIf="showAboutTech">
    <div class="header">Overview</div>
    <p>
      <strong>Solar for All:</strong> If you would like to switch to solar
      energy but can’t because you rent or some other reason, community solar
      may be just what you need. In regions where this option is available, you
      can enjoy some of solar energy’s benefits without the need to put solar
      panels on your roof and without facing a big upfront expense.
      <span
        class="link_text"
        *ngIf="showReadMore"
        (click)="onReadContent('readMore')"
      >
        Read more...
      </span>
    </p>

    <div *ngIf="showReadLess">
      <p class="pt3">
        <strong>How it works:</strong> By subscribing to a small part of a
        remotely located solar plant (sometimes called a solar garden or solar
        farm), you can conveniently join with other households and let a
        third-party solar developer handle all the technical details. Since the
        developer works in concert with your local utility, you’ll receive a
        slightly lower electricity bill each month. Best of all, you’ll have the
        satisfaction of knowing that you’re drawing power from a clean energy
        source.
      </p>

      <p class="pt3">
        <strong>Economics:</strong> There’s generally no up-front fee to join,
        and you can typically save up to 10% off your monthly electric bill.
        This isn’t as much as you can save through rooftop solar, but it’s great
        for those unable to put solar panels on their roof.
      </p>

      <p class="pt3">
        <strong>Environment:</strong> You can normally sign up to have all, or
        almost all, of your electricity usage provided by solar power. This can
        dramatically reduce your carbon footprint while simultaneously
        encouraging the further development of renewable power in your region.
        <span class="link_text" (click)="onReadContent('readLess')">
          Read less
        </span>
      </p>
    </div>
  </div>
  <div *ngIf="!showAboutTech">
    <div *ngIf="isActive(0)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How does community solar work?</h5>
        <p class="answer_text">
          With community solar, you subscribe to a share of the output of a
          solar plant located somewhere in your area. As the garden produces
          power each month, you’ll receive credits that reduce the cost of your
          electricity bill, typically by about 10%. This is a pretty good deal
          since there’s generally no upfront cost and you don’t have to worry
          about installing or maintaining any solar equipment on your property.
          It’s an especially good option for those renting apartments and others
          unable to pursue traditional rooftop solar.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. Do I need to switch my utility company to move to community solar?
        </h5>
        <p class="answer_text">
          No. You’ll maintain your relationship with your electric utility,
          continuing to pay them monthly for at least a portion of your
          electricity bill. In addition, you’ll have a new relationship with the
          community solar provider or the organization managing the program that
          you’ll pay separately for the solar energy produced by the solar
          plant. The total amount you’ll pay will be less than you would
          otherwise be paying without community solar. (In some programs, the
          two monthly bills are combined into one as a convenience to the
          subscribers.)
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          3. Is community solar available everywhere in the US?
        </h5>
        <p class="answer_text">
          Unfortunately, no. Its availability depends on various factors,
          including state policies, regulations, and the initiatives taken by
          local utilities and solar developers. Many states and utilities
          already have community solar programs set up, and more are moving in
          this direction. Your personalized YellowTin Dashboard will indicate if
          you currently have the option of participating in community solar, and
          it’ll provide an estimate of potential dollar and carbon savings if
          you choose this option.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(1)" hideToggle="true">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How much can I save?</h5>
        <p class="answer_text">
          You can generally save about 10% of your electricity costs on a
          community solar program. The process normally involves: (a) getting a
          $/kWh credit from your electric utility for each kWh of solar energy
          produced on your behalf and (b) paying your community solar provider a
          $/kWh payment (that’s less than the utility credit) for each kWh of
          solar energy. The net result is a reduction in your overall
          electricity costs.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. Is there an upfront cost for participation?
        </h5>
        <p class="answer_text">
          There are two key types of community solar programs, with different
          approaches to upfront costs. The great majority of programs employ a
          subscription approach through which you pay nothing upfront, quickly
          begin saving money, and have the ability to drop out of the program at
          any time. The other approach involves direct ownership of a portion of
          the solar array rather than a subscription to the energy produced.
          This concept involves a larger upfront investment and longer-term
          commitment, but the financial rewards are typically greater.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(2)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. Does community solar benefit the environment?
        </h5>
        <p class="answer_text">
          Yes. Community solar encourages the development of solar projects that
          add carbon-free electricity to the grid, displacing fossil-fueled
          electricity. Moreover, the electricity is generated locally, which
          results in lower transmission losses and reduces the need for new
          transmission lines. A side benefit is the development of nearby
          energy-related jobs that can help boost a local economy.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(3)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. How difficult is signing up or canceling?
        </h5>
        <p class="answer_text">
          Signing up for a community solar Program is pretty straightforward.
          You’ll need to carefully review and understand the subscription
          agreement, but there’s typically no upfront cost. Often you’ll be able
          to choose from among several existing solar plants in your region.
          However, in some cases all the existing plants may be fully
          subscribed, so you may need to wait for the completion of another
          plant for your subscription to begin. Canceling a subscription is also
          often straightforward. While some community solar providers require a
          minimum commitment term, most do not.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">2. Will I receive two bills?</h5>
        <p class="answer_text">
          In most cases yes, you’ll still receive your monthly utility bill
          (which will be lower than before) and you’ll also receive a bill from
          your community solar provider. Some programs have moved to consolidate
          the two bills into one, but not all programs have achieved this goal
          yet.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          3. Can I subscribe to community solar if I have rooftop solar?
        </h5>
        <p class="answer_text">
          This depends on the policies in your area. In some areas, those with
          rooftop solar are allowed to participate as a way to cover that
          portion of their electricity usage that’s not supplied by their own
          generation. In other areas, those with rooftop solar are not allowed
          to participate.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          4. What if I move as a community solar subscriber?
        </h5>
        <p class="answer_text">
          If you move within the same electric utility service territory, you
          can generally continue your participation without interruption. If you
          move out of the service territory, you would have to cancel your
          subscription.
        </p>
      </div>
    </div>
  </div>
</div>
