import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { HPDSettingModel } from "../energy-choices.model";
import { LifestyleService } from "../../../rest-api/lifestyle.service";
import { LearnMoreService } from "../../../homeowner-dashboard/learn-more/learn-more.service";
import { Utils } from "../../../utils/util";
import { DropDownModel } from "../../../rest-api/rest-api.model";

@Component({
  selector: "yt-hpd",
  templateUrl: "hpd.component.html",
  styleUrls: ["../energy-choices-common.scss"],
})
export class HPDComponent implements OnInit {
  panelOpenState: boolean = false;
  HPDForm: FormGroup;
  updateMode: boolean;
  homeOwnerEmail: string;
  hpdData: HPDSettingModel[];
  @Output() saveHPD = new EventEmitter<any>();
  disableDatePicker: boolean;
  monthList: string[];
  lastTwelthMonth: string;
  currentDryer: DropDownModel[];

  constructor(
    private fb: FormBuilder,
    private lifeStyleService: LifestyleService,
    private utils: Utils,
    private learnMoreService: LearnMoreService
  ) {
    this.currentDryer = this.learnMoreService.typeOfGas;
    let countofPeople = this.lifeStyleService.peopleCount;
    this.HPDForm = this.fb.group({
      own: ["yesPlan", Validators.required],
      howManyPeople: [
        countofPeople,
        [
          Validators.required,
          Validators.pattern("^(1[0-2]|[1-9])$"),
          Validators.min(1),
          Validators.max(10),
        ],
      ],
      currentDryer: ["Natural gas", Validators.required],
      bought: [""],
      datePurchased: ["", Validators.required],
    });
  }

  ngOnInit() {
    this.monthList = this.utils.monthList;
    this.lastTwelthMonth = this.utils.lastTwelthMonth;
    if (this.HPDForm.get("own")?.value === "yes") {
      this.HPDForm.get("currentDryer")?.disable();
      this.HPDForm.get("currentDryer")?.setValue("Electric");
    }
    if (this.HPDForm.get("own")?.value === "yesPlan") {
      this.HPDForm.get("currentDryer")?.setValue("Natural gas");
    }

    if (this.lifeStyleService.getHPDInfo() !== undefined) {
      this.hpdData = this.lifeStyleService.getHPDInfo();
      this.checkInUpdateView(this.hpdData[0]);
    }
    if (this.HPDForm.get("own")?.value !== "yes") {
      this.HPDForm.get("datePurchased")?.disable();
    }
    this.HPDForm.get("howManyPeople")?.valueChanges.subscribe((data) => {
      this.lifeStyleService.setPeopleCount(
        this.HPDForm.get("howManyPeople")?.value
      );
    });
  }

  isDatePurchasedEmpty() {
    if (this.HPDForm.get("own")?.value === "yes") {
      if (!this.HPDForm.get("datePurchased")?.value) {
        return true;
      }
    }
    return false;
  }

  condition() {
    if (this.HPDForm.get("own")?.value === "yes") {
      this.HPDForm.get("howManyPeople")?.enable();
      this.HPDForm.get("bought")?.enable();
      this.HPDForm.get("currentDryer")?.disable();
      this.HPDForm.get("datePurchased")?.enable();
      this.disableDatePicker = true;
    }
    if (this.HPDForm.get("own")?.value === "yesPlan") {
      this.HPDForm.get("howManyPeople")?.enable();
      this.HPDForm.get("bought")?.disable();
      this.HPDForm.get("currentDryer")?.enable();
      this.HPDForm.get("datePurchased")?.disable();
      this.disableDatePicker = false;
    }
    if (this.HPDForm.get("own")?.value === "no") {
      this.HPDForm.get("howManyPeople")?.disable();
      this.HPDForm.get("bought")?.disable();
      this.HPDForm.get("currentDryer")?.disable();
      this.HPDForm.get("datePurchased")?.disable();
      this.disableDatePicker = false;
    }
    if (this.HPDForm.get("own")?.value === "noPlan") {
      this.HPDForm.get("howManyPeople")?.disable();
      this.HPDForm.get("bought")?.disable();
      this.HPDForm.get("currentDryer")?.disable();
      this.HPDForm.get("datePurchased")?.disable();
      this.disableDatePicker = false;
    }
  }

  checkInUpdateView(data) {
    this.updateMode = true;
    this.HPDForm.controls["own"].setValue(data.own);
    if (data.currentDryer) {
      this.HPDForm.controls["currentDryer"].setValue(data.currentDryer);
      this.HPDForm.get("currentDryer")?.enable();
    } else {
      if (this.HPDForm.get("own")?.value === "yesPlan") {
        this.HPDForm.get("currentDryer")?.enable();
      } else {
        this.HPDForm.get("currentDryer")?.disable();
      }
    }
    if (data.bought) {
      this.HPDForm.controls["bought"].setValue(data.bought);
      this.HPDForm.controls["datePurchased"].setValue(data.datePurchased);
    } else {
      if (data.own === "yes") {
        this.HPDForm.controls["bought"].setValue(false);
        this.HPDForm.controls["datePurchased"].setValue(this.lastTwelthMonth);
      } else {
        this.HPDForm.get("bought")?.disable();
        this.HPDForm.get("datePurchased")?.disable();
      }
    }
    this.disableDatePicker = data.own === "yes" ? true : false;
    this.condition();
  }

  onSaveIC() {
    const hpdInfo: {
      energyChoices: {
        howManyPeople: string;
        hpd: {
          own: string;
          currentDryer?: string;
          bought?: string;
          datePurchased?: string;
        }[];
      };
    } = {
      energyChoices: {
        howManyPeople: this.HPDForm.get("howManyPeople")?.value,
        hpd: [],
      },
    };
    if (this.HPDForm.get("own")?.value === "yes") {
      const datePurchased = this.HPDForm.get("datePurchased")?.value;
      const bought = this.utils.isDatePurchaseSelected(datePurchased);
      this.HPDForm.get("bought")?.setValue(bought);
      hpdInfo.energyChoices.hpd[0] = {
        own: this.HPDForm.get("own")?.value,
        bought: this.HPDForm.get("bought")?.value,
        datePurchased: !this.HPDForm.get("bought")?.value
          ? null
          : this.HPDForm.get("datePurchased")?.value,
      };
    } else if (this.HPDForm.get("own")?.value === "yesPlan") {
      if (this.HPDForm.get("currentDryer")?.value === "") {
        this.HPDForm.get("currentDryer")?.reset();
      }
      hpdInfo.energyChoices.hpd[0] = {
        own: this.HPDForm.get("own")?.value,
        currentDryer: this.HPDForm.get("currentDryer")?.value,
      };
    } else if (this.HPDForm.get("own")?.value === "no") {
      hpdInfo.energyChoices.hpd[0] = {
        own: this.HPDForm.get("own")?.value,
      };
    }
    this.lifeStyleService.onSaveLifestyleInfo(hpdInfo).subscribe((response) => {
      if (response.status === 200) {
        this.lifeStyleService.postHPDInfo(hpdInfo);
        this.saveHPD.emit();
      }
    });
  }

  get datePurchased(): FormControl {
    return this.HPDForm.controls["datePurchased"] as FormControl;
  }
}
