<div class="faq_container">
  <div class="about_technology" *ngIf="showAboutTech">
    <div class="header">Overview</div>
    <p>
      <strong>How it works:</strong> Electric bikes, also called eBikes, assist
      riders using power provided by an integrated electric motor and battery.
      When compared to traditional bikes, eBikes allow riders to travel longer
      distances in a shorter amount of time through added range and
      electric-assisted pedaling. Powerful eBikes have a throttle and can run
      without pedaling while battery power lasts.
      <span
        class="link_text"
        *ngIf="showReadMore"
        (click)="onReadContent('readMore')"
      >
        Read more...
      </span>
    </p>

    <div *ngIf="showReadLess">
      <p class="pt3">
        <strong>Types of eBikes:</strong> Various different styles of eBikes are
        on the market, including city/commuter, cargo, folding, and even
        three-wheeled bikes. The most popular ones are low powered with limited
        range and largely treated as bicycles. The more powerful ones with
        larger battery and range are usually treated as a moped and need to be
        registered.
      </p>

      <p class="pt3">
        <strong>Special Requirements:</strong> The battery of the eBike needs to
        be charged from time to time. This can be done by removing the battery
        if possible and taking it to a standard electrical outlet. If not, the
        eBike needs to be taken near the outlet to charge. Other than this road
        safety requirements, speed limits and age limits for riding different
        types of eBikes need to be adhered to.
      </p>

      <p class="pt3">
        <strong>Economics:</strong> eBikes vary widely in costs based on sizes,
        specs and capabilities. The prices can be double that of a similar
        traditional bike or even 10 times higher.
      </p>

      <p class="pt3">
        <strong>Maintenance:</strong> In addition to traditional bicycle
        maintenance in an eBike there are a few other things to do. The electric
        motors are usually very robust and rarely need maintenance. However, the
        battery will lose some of its capacity over time and may eventually need
        to be replaced. Battery replacement may be needed every 5-10 years.
      </p>

      <p class="pt3">
        <strong>Environment:</strong> An eBike is the most efficient form of
        transportation known to humans in terms of energy consumed per mile or
        km. It is more efficient than walking or riding a traditional bike even
        when charged with dirty grid electricity. If car trips are replaced the
        environmental advantages are huge. If charged with renewable
        electricity, it can have almost zero impact on the environment.
        <span class="link_text" (click)="onReadContent('readLess')">
          Read less
        </span>
      </p>
    </div>
  </div>

  <div *ngIf="!showAboutTech">
    <div *ngIf="isActive(0)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. What is the difference between a standard bike and an eBike?
        </h5>
        <p class="answer_text">
          Electric bikes, also called eBikes, assist riders using power provided
          by an integrated motor and battery. Compared to traditional bikes,
          eBikes provide faster, easier commutes, near-zero emissions, and an
          enjoyable ride. When compared to traditional bikes, eBikes allow
          riders to travel longer distances in a shorter amount of time. The
          added range and electric-assisted pedaling can help replace car trips,
          ease commutes to work or school, and bridge the gap between public
          transit and home or your next destination.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">2. How do I charge an eBike?</h5>
        <p class="answer_text">
          Charging times will vary based on the size of the battery, but,
          generally, it will take 3-5 hours to fully charge an eBike battery
          using a standard 120-volt outlet typical in most homes and businesses.
          The charger is supplied with the bike and sometimes the battery can be
          detached and charged without having to carry the whole bike indoors.
          Charging with the help of an extension cord is also fine.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          3. What will happen if I run out of charge?
        </h5>
        <p class="answer_text">
          An eBike usually operates in a pedal-assist mode, where the motor
          provides an extra boost while the rider pedals. Some eBikes have a
          throttle that can be used for operation in fully electric mode,
          without pedaling. A third option is fully manual mode, where the motor
          is not used at all. In case the battery charge is depleted, the bike
          can be operated in manual mode, just like a standard pedal bike
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">4. How far can I travel with an eBike?</h5>
        <p class="answer_text">
          On flat terrain with a low assist level, many eBikes can travel for
          more than 50 miles on a single charge. However, 30-50 miles is a
          reliable average. Some models also accept an extra battery for added
          range. It’s recommended that new owners test battery life on shorter
          rides to better gauge an eBike’s range.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">5. What types of eBikes are available?</h5>
        <div class="">
          <p class="answer_text">
            Various different styles of eBikes are on the market, including
            city/commuter, cargo, folding, and even three-wheeled bikes.
            Step-through bikes, without a top frame tube, can provide easier
            mounting and dismounting. Specialty road, mountain, and tandem bikes
            are also available.
          </p>
          <p class="pt3 answer_text">
            The US recognizes three classes of eBikes: Class 1, Class 2, and
            Class 3. The differences between these classes include whether power
            is provided only when pedaling or controlled by the throttle and the
            top speed at which the motor can assist the rider (20 MPH or 28
            MPH). The differences are summarized in the table below.
          </p>
          <table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Power</th>
                <th>Top Speed for Assistance from Motor</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Class 1</td>
                <td>Pedal-assisted</td>
                <td>20 MPH</td>
              </tr>
              <tr>
                <td>Class 2</td>
                <td>20 MPH</td>
                <td>Throttle Controlled or Pedal-assisted</td>
              </tr>
              <tr>
                <td>Class 3</td>
                <td>Pedal-assisted</td>
                <td>28 MPH</td>
              </tr>
            </tbody>
          </table>
          <p class="pt3 answer_text">
            In general, eBike motors are required to be 750 watts or less.
            Additionally, riders under the age of 17 are usually required to
            wear a helmet while riding any eBike, and Class 3 bikes can only be
            legally ridden by those 16 and older. eBikes mostly have 20, 22, or
            26 inch wheels. Other uncommon wheel sizes may also be available in
            the market.
          </p>
        </div>
      </div>
    </div>
    <div *ngIf="isActive(1)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How expensive are eBikes?</h5>
        <p class="answer_text">
          eBikes vary widely in costs based on sizes, specs and capabilities.
          The prices can range from $500 to $5,000 with the most popular ones
          coming in around $1,000-$3,000.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">2. Will I save money with an eBike?</h5>
        <p class="answer_text">
          When compared to traditional bikes, eBikes allow riders to travel
          longer distances in a shorter amount of time. It’s also easier to
          carry items like groceries etc. If traditional car trips like
          commutes, grocery shopping, etc. can be replaced by eBikes, then it
          can save a substantial amount in fuel costs. Due to their relatively
          low energy usage and small batteries, the cost of charging an eBike is
          almost negligible. Finally, the increased bike trips, even with some
          assist from a motor, can have a positive impact on health and help
          reduce healthcare costs.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(2)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. What kind of maintenance issues arise?</h5>
        <p class="answer_text">
          When compared to traditional bikes, eBikes have a motor, battery, and
          wiring. As with a traditional bike, minor maintenance is needed for
          the wheels, brakes, gears, etc. With an eBike, the electric motors are
          usually very robust and rarely need maintenance. However, the battery
          will lose some of its capacity over time and may eventually need to be
          replaced. You might want to check what type of battery warranty comes
          with an eBike that you’re considering.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(3)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. Is there a greenhouse gas reduction benefit of using an eBike?
        </h5>
        <p class="answer_text">
          If traditional gasoline-powered car trips are replaced by eBikes,
          there is definitely a reduction in the amount of greenhouse gases
          emitted. Even when replacing electric vehicle trips that are not
          powered by carbon-free electricity, there will be a greenhouse gas
          reduction since eBikes are more efficient than an EV.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(4)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. What security issues can arise from using an eBike?
        </h5>
        <p class="answer_text">
          Because eBikes are more expensive than their non-motorized
          counterparts, they can be targets for theft. The best strategy is to
          keep your eBike secured in a locked home, access-controlled building,
          or bike locker. When this isn’t possible, leave your bike securely
          locked in a well-trafficked location for as short a time as possible.
          Invest in a strong U-lock or chain lock that encloses the frame and
          back wheel. The front wheel can be removed and secured with the same
          lock, or a second lock can be used.
        </p>
        <p class="pt3 answer_text">
          When possible, remove your battery and take it with you. Finally,
          consider getting insurance for your bike in the event it is stolen and
          make sure to record your serial number in case it is recovered.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. What are the safety protocols for an eBike?
        </h5>
        <p class="answer_text">
          If you haven’t ridden a bike very often on public roads, the first
          step is to become familiar with the basics of signaling turns,
          navigating intersections, and passing other riders. The League of
          American Bicyclists has extensive resources on the Smart Cycling
          section of their web site (<a
            class="link_text"
            href="https://bikeleague.org/ridesmart"
            target="_blank"
            >https://bikeleague.org/ridesmart</a
          >).
        </p>
        <p class="pt3 answer_text">
          Among the important differences for new eBike riders is the eBike’s
          speed and weight. Although road cyclists often travel at 20 mph or
          more, casual riders usually do not. Therefore, it’s important to
          understand that you may have less time to react than you are used to,
          and traffic may not expect you to be traveling at these higher speeds.
          In addition, eBikes usually weigh about 20 lbs. more than a
          traditional bike, so the process of mounting and dismounting will be
          somewhat different, and braking may take longer despite having
          stronger disk-style brakes.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          3. Can I take my eBike with me on mass transit?
        </h5>
        <p class="answer_text">
          Many local transit authorities allow eBikes to use the on-board bike
          racks. However, keep in mind that eBikes often weigh in excess of 50
          pounds and will need to be lifted by the rider. eBikes are also
          welcome in many trains. However, some transit authorities may prohibit
          them and have restrictions on weight and tire size.
        </p>
        <p class="pt3 answer_text">
          As eBikes become more common, transit agencies around the country are
          updating their policies. Check with your local transit authority to
          understand where and how eBikes can be used on buses, ferries, and
          trains.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          4. What are the rules of riding eBikes on trails and in parks?
        </h5>

        <p class="answer_text">
          Generally, Class 1 and Class 2 eBikes are allowed on all types of
          paved trails, including Class 1 (paved, separated) bike paths. Class 3
          eBikes are not allowed on Class 1 bike paths. Riders should remember
          that the speed limit on all Class 1 trails is 15 miles per hour.
        </p>
        <p class="pt3 answer_text">
          In most state parks, Class 1 and Class 2 eBikes are allowed on paved
          and unpaved trails where traditional bicycles are allowed, but riders
          should consult park rules before visiting.
        </p>
        <p class="answer_text">
          In national parks, eBikes are treated like motorized vehicles and are
          allowed in the same areas and on the same trails used by motorized
          vehicles. The U.S. Forest Service has about 60,000 miles of trails
          that are open to Class 1, 2, and 3 eBikes. On Bureau of Land
          Management (BLM) trails, local land managers set rules on eBike use.
        </p>
        <p class="pt3 answer_text">
          eBikes may not be allowed on unpaved trails and sometimes rules in
          county parks vary by location.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(5)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. What types of incentives are available?</h5>
        <p class="answer_text">
          Utilities, counties, Community Choice Aggregators, etc. sometimes
          offer rebates of various types on electric bikes.
        </p>
      </div>
    </div>
  </div>
</div>
