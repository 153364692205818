import { Injectable, EventEmitter, Output } from "@angular/core";
import { Subject } from "rxjs/Subject";
import { RouterInfoModel } from "../top-nav/router-info.model";

@Injectable()
export class DashboardLayoutService {
  closeSideBar = false;
  @Output() change: EventEmitter<boolean> = new EventEmitter();

  public navRouterSubject = new Subject<RouterInfoModel>();
  public navRouter$ = this.navRouterSubject.asObservable();

  public hideSidenavSubject = new Subject<boolean>();
  public hideSidenav$ = this.hideSidenavSubject.asObservable();

  public setSmdLinkSubject = new Subject<boolean>();
  public getSmdLink$ = this.setSmdLinkSubject.asObservable();

  public disableNextStepButtonSubject = new Subject<boolean>();
  public disableNextStepButton$ = this.disableNextStepButtonSubject.asObservable();

  emitCloseSideBar() {
    this.closeSideBar = true;
    this.change.emit(this.closeSideBar);
  }

  emitOpenSideBar() {
    this.closeSideBar = false;
    this.change.emit(this.closeSideBar);
  }
}
