import { PGEAuthRoutingModule } from './pge-auth-routing.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PGEAuthComponent } from './pge-auth.component';

@NgModule({
  imports: [CommonModule, PGEAuthRoutingModule],
  exports: [PGEAuthComponent],
  declarations: [PGEAuthComponent]
})
export class PGEAuthModule { }
