import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'entries'
})
export class EntriesPipe implements PipeTransform {
  transform(value: any):   
 [string, any][] {
    if (!value) {
      return [];
    }

    return Object.entries(value);
  }
}

@NgModule({
    declarations: [EntriesPipe],
    exports: [EntriesPipe],
  })
  export class EntriesPipeModule {}