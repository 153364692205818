import { Routes } from "@angular/router";
import { NotFound404Component } from "./not-found404.component";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "register",
    pathMatch: "full",
    data: { title: "Register" },
  },
  {
    path: "my-info",
    redirectTo: "/register",
    pathMatch: "full",
    data: { title: "Register" },
  },
  {
    path: "sign-up",
    loadChildren: () =>
      import("./homeowner-product/index").then((m) => m.HomeOwnerProductModule),
    data: { title: "SignUp" },
  },
  {
    path: "register",
    loadChildren: () =>
      import("./homeowner-info/index").then((m) => m.HPJourneyModule),
    data: { title: "Register" },
  },
  {
    path: "pge_auth_code",
    loadChildren: () => import("./pge-auth/index").then((m) => m.PGEAuthModule),
    data: { title: "Register" },
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./homeowner-dashboard/index").then(
        (m) => m.HomeOwnerDashboardModule
      ),
    data: { title: "Dashboard" },
  },
  {
    path: "incentives",
    loadChildren: () =>
      import("./incentives/index").then((m) => m.IncentivesModule),
      data:{title:'Rebates & Incentives'}
  },
  { path: "**", component: NotFound404Component },
];
