<div class="tech_card default_text" *ngIf="checkTechListLength()">
  <span>No options here</span>
</div>
<div class="tech_card" *ngFor="let item of techList">
  <mat-icon
    class="close_icon"
    (click)="onRemoveTech(item)"
    [ngClass]="{ isDisabled: removeAlreadyHaveTech === item.techParameter }"
    >close</mat-icon
  >
  <div class="tech_info">
    <div class="tech_name">{{ item.techName }}</div>
  </div>
</div>
