<scroll-top></scroll-top>
<div class="page-container yt__learn_more">
  <yt-back [navigateTo]="'overview'"></yt-back>
  <div class="padding-back-button w-100 center">
    <div class="yt_maxWidth w-100 center">
      <div class="header_text tc">Electric Vehicle 2</div>

      <yt-scroll-link-toggle
        *ngIf="!isLangSpanish"
        [activeLink]="selectedSection"
        [isAboutTech]="true"
        [isSavings]="true"
        [isDescription]="false"
        [isSettings]="true"
        [isDetails]="true"
        [isAssumptions]="true"
        [isFaq]="true"
        [techParameter]="'ev2'"
        (activeValue)="handleActiveValue($event)"
      ></yt-scroll-link-toggle>

      <div>
        <div
          class="yt-text"
          [innerHTML]="'homeOwenerDashboard.learnMore.ev.paragraph' | translate"
        ></div>
        <div *ngIf="!isLangSpanish" id="aboutTechnology">
          <yt-ev-FAQ [showAboutTech]="true"></yt-ev-FAQ>
        </div>
        <div class="faq_mobile">
          <button
            (click)="onFavorite()"
            class="favorite_btn"
            mat-raised-button
            color="accent"
          >
            <mat-icon
              class="favorite_icon"
              [ngClass]="{ favorite_icon_color: favStatus }"
              >{{ favIcon }}</mat-icon
            >FAVORITE
          </button>
          <button
            *ngIf="isLangSpanish"
            class="faq_button"
            mat-raised-button
            color="accent"
            (click)="handleFAQClick()"
          >
            MORE INFO
          </button>
        </div>
      </div>

      <div class="top_section w-100">
        <div class="faq_section">
          <div class="image_box">
            <img
              src="assets/lifestyle/ev2.png"
              alt="electric vehicle"
              [ngStyle]="{ height: !displayMarketPlaceBox ? '160px' : '250px' }"
            />
          </div>
          <button
            (click)="onFavorite()"
            class="favorite_btn mt_20"
            mat-raised-button
            color="accent"
          >
            <mat-icon
              class="favorite_icon"
              [ngClass]="{ favorite_icon_color: favStatus }"
              >{{ favIcon }}</mat-icon
            >FAVORITE
          </button>
          <button
            *ngIf="isLangSpanish"
            class="faq_button mt_20"
            mat-raised-button
            color="accent"
            (click)="handleFAQClick()"
          >
            MORE INFO
          </button>
        </div>

        <div class="width-box" id="cost">
          <div class="main_values_box" *ngIf="!showLoan">
            <div class="details_header pb_14">Cost</div>
            <div class="top_values">
              <div *ngIf="showCash">
                <div class="main_value_text">
                  ${{ financialDetails.evCost.priceAfterIncentives | number }}
                  <div class="value_text">Price after incentives</div>
                </div>
              </div>
              <div *ngIf="showLease">
                <div class="main_value_text">
                  ${{ financialDetails.evCost.monthlyPayment | number }}
                  <div class="value_text">{{ paymentTypeLabel }}</div>
                </div>
              </div>
            </div>

            <div class="bottom_values">
              <div class="left_bottom_value">
                <div *ngIf="showCash">
                  <div class="value" *ngIf="electricViewDetails">
                    ${{ financialDetails.evCost.msrp | number }}
                  </div>
                  <div class="value_label">
                    <span
                      class="tooltip_text pointer"
                      matTooltip="Includes MSRP plus state sales tax for your selected vehicle before consideration of any incentives."
                    >
                      Price
                    </span>
                  </div>
                </div>
                <div *ngIf="showLease">
                  <div class="value">
                    ${{ financialDetails.evCost.downPayment | number }}
                  </div>
                  <div class="value_label">Down payment</div>
                </div>
              </div>
              <div class="right_bottom_value">
                <div class="value" *ngIf="electricViewDetails">
                  ${{ financialDetails.evCost.incentives | number }}
                </div>
                <div class="value_label">
                  <mat-icon class="hidden_tooltip">help_outline</mat-icon>
                  <span
                    *ngIf="showIncentivesStatus"
                    class="link_text pointer"
                    (click)="showIncentiveBreakdown = true"
                    >Incentives
                  </span>
                  <span *ngIf="!showIncentivesStatus">Incentives </span>
                  <mat-icon
                    class="tooltip_icon"
                    matTooltip="Available tax credits and rebates."
                  >
                    help_outline
                  </mat-icon>
                </div>
              </div>
            </div>
          </div>

          <div class="main_values_box" *ngIf="showLoan">
            <div class="details_header pb_14">Cost</div>
            <div class="top_values">
              <div class="main_value_text">
                ${{ financialDetails.evCost.monthlyPayment | number }}
                <div class="value_text">{{ paymentTypeLabel }}</div>
              </div>
            </div>
            <div class="bottom_values">
              <div class="left_bottom_value_2">
                <div class="value" *ngIf="electricViewDetails">
                  ${{ financialDetails.evCost.msrp | number }}
                </div>
                <div class="value_label">
                  <span
                    class="tooltip_text pointer"
                    matTooltip="Includes typical price (with sales tax) paid in your area for your selected vehicle, before consideration of any federal, state, or utility incentives."
                  >
                    Price
                  </span>
                </div>
              </div>
              <div class="middle_bottom_value">
                <div class="value">
                  ${{ financialDetails.evCost.downPayment | number }}
                </div>
                <div class="value_label">Down payment</div>
              </div>
              <div class="right_bottom_value_2">
                <div class="value" *ngIf="electricViewDetails">
                  ${{ financialDetails.evCost.incentives | number }}
                </div>
                <div class="value_label">
                  <mat-icon class="hidden_tooltip">help_outline</mat-icon>
                  <span
                    *ngIf="showIncentivesStatus"
                    class="link_text pointer"
                    (click)="showIncentiveBreakdown = true"
                    >Incentives
                  </span>
                  <span *ngIf="!showIncentivesStatus">Incentives </span>
                  <mat-icon
                    class="tooltip_icon"
                    matTooltip="Available tax credits and rebates."
                  >
                    help_outline
                  </mat-icon>
                </div>
              </div>
            </div>
          </div>
          <yt-market-place
            [tech_name]="'ev2'"
            [linkId]="linkId"
            [marketPlaceDetails]="marketPlaceDetails"
            *ngIf="marketPlaceDetails && displayMarketPlaceBox"
          ></yt-market-place>
          <div class="main_values_box advice_box" *ngIf="adviceCardStatus">
            <yt-electrification-advice-card
              [tech]="'ev2'"
            ></yt-electrification-advice-card>
          </div>
        </div>
      </div>

      <div class="bottom_section w-100">
        <div class="left_section">
          <div class="highChart_card highChart_card_mobile">
            <div class="details_header pb_14">Savings</div>
            <div class="w-100 toggle_buttons">
              <mat-button-toggle-group
                [(value)]="selected"
                (change)="updateChart($event.value)"
              >
                <mat-button-toggle
                  *ngFor="let time of timeSegmentList"
                  [value]="time.value"
                >
                  <span class="toggle_label">{{
                    time.viewValue?.toUpperCase()
                  }}</span></mat-button-toggle
                >
              </mat-button-toggle-group>
            </div>
            <div class="w-100 savings_text">
              Your
              <span>{{ timeFrame }}</span> projected
              <span
                class="tooltip_text_border pointer"
                *ngIf="showTooltipZero"
                style="border-color: tomato"
                matTooltip="Due to its high electricity usage per mile traveled, it is more economical to operate this PHEV on gasoline than electricity."
              >
                energy savings
              </span>
              <span *ngIf="!showTooltipZero">energy savings</span>
              is
              <span
                class="savings_value"
                [ngClass]="{ value_color: evSavings < 0 }"
                >{{ evSavings | currency : "$" : "symbol" : "1.0-0" }}</span
              >
            </div>
            <div class="w-100 chart_section">
              <div class="mobile_chart_text">
                To view and compare graphs of energy costs, please login to your
                dashboard using a desktop.
              </div>
            </div>
          </div>
          <div class="details_card" [formGroup]="evForm" id="settings">
            <div class="details_header">PERSONALIZE</div>

            <div class="form_label flex justify-between items-baseline">
              Make & model
              <span
                class="help_me_find"
                (click)="openHelpMeChoose()"
                *ngIf="showHelpMeChoose"
              >
                <span class="helpme">Help me choose</span>
                <span class="choose">Choose</span>
              </span>
            </div>
            <div>
              <mat-form-field
                floatLabel="auto"
                hideRequiredMarker="true"
                class="w-100 formfield_padding"
                appearance="outline"
              >
                <mat-select placeholder="Make & Model" formControlName="model">
                  <mat-option
                    *ngFor="let makeAndModel of makeAndModelList"
                    [value]="makeAndModel.mnfAndModel"
                    [matTooltip]="makeAndModel.mnfAndModel"
                    >{{ makeAndModel.mnfAndModel }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form_label flex justify-between items-baseline">
              Financing
              <span class="help_me_find" (click)="showCompareFinancial = true"
                >Compare</span
              >
            </div>

            <div>
              <mat-form-field
                class="w-100 disabled_formfield"
                floatLabel="auto"
                hideRequiredMarker="true"
                appearance="outline"
              >
                <mat-select formControlName="finance">
                  <mat-option
                    *ngFor="let finance of financeList"
                    [value]="finance.value"
                  >
                    {{ finance.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form_label flex justify-between">
              <div class="baseline">
                <span> Annual miles </span>
                <mat-icon
                  class="tooltip_icon pointer"
                  matTooltipPosition="right"
                  matTooltip="Total miles driven on electricity in a year. This should not include the number of miles a plug-in hybrid electric vehicle (PHEV) is driven on gasoline."
                >
                  help_outline
                </mat-icon>
              </div>
              <div>
                <span (click)="onPopUp()" class="help_me_find">Calculate</span>
              </div>
            </div>
            <div>
              <mat-form-field
                class="w-100 formfield_padding"
                floatLabel="auto"
                hideRequiredMarker="true"
                appearance="outline"
              >
                <input
                  matInput
                  type="text"
                  formControlName="annualMiles"
                  placeholder="Enter 1000-40000"
                  autocomplete="off"
                  required
                  maxlength="6"
                />
                <span
                  matSuffix
                  class="suffix_color"
                  *ngIf="onCarTypeChange(evForm.controls['model'].value)"
                >
                  miles driven by electricity
                </span>
                <span
                  matSuffix
                  class="suffix_color"
                  *ngIf="!onCarTypeChange(evForm.controls['model'].value)"
                  >miles</span
                >
                <mat-error *ngIf="isMilesOutOfRange">
                  Please enter value between 1000-40000
                </mat-error>
                <mat-error *ngIf="isMilesRequired"> Required! </mat-error>
              </mat-form-field>
            </div>
            <div class="form_label baseline">
              Home charging
              <mat-icon
                class="tooltip_icon pointer"
                matTooltip="This is the % of time you will be charging the vehicle from your home."
                matTooltipPosition="right"
              >
                help_outline
              </mat-icon>
            </div>
            <div>
              <mat-form-field
                class="w-100 formfield_padding"
                floatLabel="auto"
                hideRequiredMarker="true"
                appearance="outline"
              >
                <mat-select formControlName="charging">
                  <mat-option *ngFor="let charge of charges" [value]="charge"
                    >{{ charge }}%</mat-option
                  >
                </mat-select>
                <mat-error *ngIf="isChargingRequired"> Required! </mat-error>
              </mat-form-field>
            </div>
            <div
              class="help_me_find tr pt-10"
              *ngIf="showEVChargersLink"
              (click)="navigateToEVChargers()"
            >
              Learn about EV Charger
            </div>
            <div
              class="save_button"
              [ngClass]="{
                '': !displayMarketPlaceBox,
                'ghost-button': displayMarketPlaceBox,
                disable_button: evForm.invalid || isMNFAndModelListAbsent()
              }"
            >
              <button
                mat-raised-button
                color="accent"
                #loaderButton
                [disabled]="evForm.invalid || isMNFAndModelListAbsent()"
                (click)="onSaveDetails()"
              >
                SAVE DETAILS
              </button>
            </div>
          </div>
          <yt-assumption-card
            [assumptionDetails]="assumptionDetails"
            [assumptionLabel]="assumptionLabel"
            [showLoanLease]="!showCash"
          ></yt-assumption-card>
        </div>

        <div class="right_section">
          <div class="highChart_card" id="savings">
            <div class="details_header pb_14">Savings</div>

            <app-Time-Rendering
              
              [selected]="selected"           
              (selectionChange)="updateChart($event)"> 
            </app-Time-Rendering>

            <div class="w-100 savings_text">
              Your
              <span>{{ timeFrame }}</span> projected
              <span
                class="tooltip_text_border pointer"
                *ngIf="showTooltipZero"
                style="border-color: tomato"
                matTooltip="Due to its high electricity usage per mile traveled, it is more economical to operate this PHEV on gasoline than electricity."
              >
                energy savings
              </span>
              <span *ngIf="!showTooltipZero">energy savings</span>
              is
              <span
                class="savings_value"
                [ngClass]="{ value_color: evSavings < 0 }"
                >{{ evSavings | currency : "$" : "symbol" : "1.0-0" }}</span
              >
            </div>
            <div class="formula_text">
              Energy savings &nbsp; = &nbsp; Energy cost with gasoline vehicle -
              Energy cost with
              {{ carType }}
            </div>
            <div class="w-100 chart_section">
              <div class="hide_chart">
                <div class="chart" #container></div>
              </div>
            </div>
            <div class="w-100 chart_description_text" *ngIf="!buildType">
              With high gasoline costs, an EV/PHEV can dramatically cut energy
              costs. If you're able to add rooftop solar, you can reduce your
              ongoing energy costs even more.
            </div>
          </div>
          <div class="summary_card" id="details">
            <mat-expansion-panel
              (closed)="isTechnicalExpanded = false"
              [expanded]="isTechnicalExpanded"
              hideToggle="true"
            >
              <mat-expansion-panel-header (click)="expansionPanelClick()">
                <mat-panel-title class="flex items-center">
                  Technical Details &nbsp;
                  <mat-icon class="pointer mat_color">
                    {{
                      !icon ? "keyboard_arrow_down" : "keyboard_arrow_up"
                    }}</mat-icon
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel_body w-100">
                <div class="left_side_values">
                  <div class="label_heading w-100">Summary</div>
                  <div class="left_label_content_box">
                    <div class="label_content_row">
                      <span
                        class="tooltip_text pointer"
                        matTooltip="The number of miles the EV will travel before the battery needs to be recharged."
                      >
                        Elec. driving range
                      </span>
                      <div *ngIf="electricViewDetails">
                        {{ technicalDetails.evTechnicalSummary.drivingRange }}
                        miles
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <span
                        class="tooltip_text pointer"
                        matTooltip="Level 2: Time to fully charge a depleted battery using a Level 2 (240 V) charger."
                      >
                        Time to charge - L2
                      </span>
                      <div *ngIf="electricViewDetails">
                        {{ technicalDetails.evTechnicalSummary.timeToCharge }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="right_side_values">
                  <div class="label_heading w-100">
                    PG&E Utility Rate Plan
                    <mat-icon class="panel_tooltip_icon"
                      matTooltip="PG&E offers different rate plans such as E1, ETOU-C and EV plans for electric vehicle owners. See the PG&E website for details.">
                      help_outline</mat-icon>
                  </div>
                  <div class="left_label_content_box">
                    <div class="label_content_row">
                      <span class="tooltip_text pointer"
                        matTooltip="This is assumed to be E1 (standard residential rate) if you did not authorize sharing your energy data on PG&E Share My Data platform.">
                        Current plan
                      </span>
                      <div *ngIf="electricViewDetails">
                        {{financialDetails.evRatePlan.currentPlan}}
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </mat-expansion-panel>
          </div>

          <div class="faq_card w-100" id="faq" *ngIf="!isLangSpanish">
            <mat-expansion-panel
              (closed)="isFAQExpanded = false"
              [expanded]="isFAQExpanded"
              hideToggle="true"
            >
              <mat-expansion-panel-header (click)="faqExpansionClick()">
                <mat-panel-title class="flex items-center">
                  Frequently Asked Questions &nbsp;
                  <mat-icon class="pointer mat_color">
                    {{
                      !icon1 ? "keyboard_arrow_down" : "keyboard_arrow_up"
                    }}</mat-icon
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel_body w-100">
                <yt-customized-tab
                  [tabHeadersList]="FAQTabsList"
                  (tabValue)="tabChanged($event)"
                ></yt-customized-tab>
                <yt-ev-FAQ
                  [activeIndex]="tabIndex"
                  [showAboutTech]="false"
                ></yt-ev-FAQ>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </div>
      <div class="link_text back_to_top" (click)="scrollToTop()">
        <mat-icon class="pointer mat_color"> keyboard_arrow_up </mat-icon>
        <span class="pointer">Back to Top</span>
      </div>
      <div class="disclaimer_text" [ngClass]="{ pt_70: isLangSpanish }">
        The results and projections are estimates based on available data.
      </div>
    </div>
  </div>
</div>

<!-- Incentives Breakdown Dialogue box-->
<yt-breakdown-popup
  *ngIf="showIncentiveBreakdown && haveResponse"
  [heading]="'Incentives Breakdown'"
  [netCost]="'Total'"
  [techName]="'ev2'"
  (closeIncentiveBreakdown)="showIncentiveBreakdown = false"
>
</yt-breakdown-popup>

<!-- Compare Financial Dialogue box-->
<yt-compare-financial
  *ngIf="haveResponse && showCompareFinancial"
  [ev]="financial"
  [evDetails]="financialDetails"
  [financeValue]="financeValue"
  (inputFinance)="onSaveDetails($event)"
  (compare)="showCompareFinancial = false"
>
</yt-compare-financial>

<!-- Calculate Miles Dialogue box -->
<yt-calculate-miles
  *ngIf="showAnnualMilesPopup"
  (onClose)="onClosePopup()"
  [isDashboard]="true"
></yt-calculate-miles>

<!-- loader -->
<yt-loader *ngIf="loader"></yt-loader>
