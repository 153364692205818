<div>
  <div class="tech__summary">
    <div class="heading">Heat Pump Clothes Dryer</div>
    <div
      class="paragraph"
      [innerHTML]="'homeOwenerDashboard.learnMore.hpd.paragraph' | translate"
    ></div>
  </div>
</div>

<form [formGroup]="hpdForm" class="form__container">
  <div class="form__wrapper">
    <div>
      <mat-radio-group
        class="radioButton-flex"
        formControlName="own"
        (change)="condition()"
      >
        <div class="cards" *ngFor="let radioOptions of radioButtonOptions">
          <label>
            <yt-radio-buttons
              [viewValue]="radioOptions.viewValue"
              [icon]="radioOptions.icon"
              [default]="selectedRadioButton"
              [value]="radioOptions.value"
            ></yt-radio-buttons>
            <mat-radio-button
              class="hidden_button absolute top-0"
              [value]="radioOptions.value"
              (change)="onRadioChange($event)"
            >
            </mat-radio-button>
          </label>
        </div>
      </mat-radio-group>
      <div class="form__field-wrapper">
        <section class="form__field-section" *ngIf="hideFormField">
          <div class="field__label">Current dryer type</div>
          <mat-form-field
            hideRequiredMarker="true"
            floatLabel="auto"
            appearance="outline"
          >
            <mat-select
              placeholder="Current dryer type"
              formControlName="currentDryer"
            >
              <mat-option
                *ngFor="let dryer of current_dryer"
                [value]="dryer.value"
                >{{ dryer.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="hpdForm.controls['currentDryer'].hasError('required')"
            >
              Required!
            </mat-error>
          </mat-form-field>
        </section>

        <section class="form__field-section" *ngIf="disableDatePicker">
          <div class="field__label">Purchased month & year</div>
          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker="true"
            appearance="outline"
          >
            <mat-select
              placeholder="Select"
              formControlName="datePurchased"
              required
            >
              <mat-option *ngFor="let month of monthList" [value]="month"
                >{{ month | titlecase }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                (isDatePurchasedEmpty() &&
                  hpdForm.controls['datePurchased'].touched) ||
                haveInputError
              "
            >
              Required!
            </mat-error>
          </mat-form-field>
        </section>
      </div>
    </div>
  </div>

  <div class="action__wrapper">
    <button
      mat-raised-button
      color="accent"
      (click)="onSaveHPD()"
      [disabled]="isDatePurchasedEmpty() || hpdForm.invalid"
      class="primary__button"
    >
      SAVE
    </button>
  </div>
  <div class="note__message">
    NOTE: The above fields have been pre-filled with default values.
  </div>
</form>
