import { AppService } from "../../../app.service";
import { RestAPIService } from "../../../rest-api/rest-api.service";
import { MatDialog } from "@angular/material/dialog";
import { Component, OnInit, Input } from "@angular/core";
import { DialogBoxComponent } from "../Dialog-box/Dialog-box.component";
import { SolarVendorModel } from "../../../rest-api/rest-api.model";
import { takeWhile } from "rxjs/operators";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";

@Component({
  selector: "yt-energy-sync",
  templateUrl: "energy-sync.component.html",
  styleUrls: ["./energy-sync.component.scss"],
})
export class EnergySyncComponent implements OnInit {
  solarVendorName: string;
  @Input() authorizeEnergyUsageUrl: string;
  @Input() smdTabStatus: boolean;
  @Input() haveResponse: boolean;
  @Input() isDashboard: boolean;
  informationforyellowtin: boolean;
  private alive = true;
  informationforSolarinstaller: boolean;

  constructor(
    public dialog: MatDialog,
    private restAPIService: RestAPIService,
    private appService: AppService,
    private dashboardService: DashboardAPIService
  ) {}

  ngOnInit() {
    this.solarVendorName = this.restAPIService.getSolarVendorDescrption();
    if (!this.solarVendorName) {
      this.solarVendorName = this.dashboardService.solarVendorDescription;
    }
    let vendorCode = this.appService.getVendorName();
    this.restAPIService
      .getSolarVendorDetails(vendorCode)
      .pipe(takeWhile(() => this.alive))
      .subscribe((data: SolarVendorModel) => {
        if (data.solutionType === "broader" || data.solutionType === "cce") {
          this.informationforyellowtin = true;
          this.informationforSolarinstaller = false;
        } else if (data.solutionType === "vendor") {
          this.informationforyellowtin = false;
          this.informationforSolarinstaller = true;
        }
      });
  }

  openCertificate() {
    window.open(
      "https://seal.godaddy.com/verifySeal?sealID=abhS69r7UJ5LjuP0qBzd1r49UwOVkeVXipTFPM4Jwp3bk8k4MGWVEYTSDe4q",
      "newwindow",
      "width=700,height=500"
    );
    return false;
  }

  openPopup(label: string) {
    this.dialog.open(DialogBoxComponent, {
      width: "650px",
      height: "auto",
      autoFocus: false,
      backdropClass: "dark-backdrop",
      data: {
        link: label,
        link2: this.authorizeEnergyUsageUrl,
        dashboard: this.isDashboard,
      },
    });
  }
}
