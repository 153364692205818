import { Component, Input } from "@angular/core";

@Component({
  selector: "yt-assumption-card",
  templateUrl: "./assumption-card.component.html",
  styleUrls: ["./assumption-card.component.scss"],
})
export class AssumptionCardComponent {
  @Input() showLoanLease: boolean;
  @Input() assumptionLabel: string;
  @Input() assumptionDetails;
  @Input() showHelpMeChoose: boolean;
}
