import { Component, Input } from "@angular/core";

@Component({
  selector: "yt-roof-type-answer-box",
  templateUrl: "roof-type-answer-box.component.html",
  styleUrls: ["./roof-type-answer-box.component.scss"],
})
export class RoofTypeAnswerBoxComponent {
  @Input() image: string;
  @Input() displayValue: string;
  @Input() isSelected: boolean;
}
