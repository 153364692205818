import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../auth/auth.service";
import { DashboardAPIService } from "../../rest-api/dashboard/dashboard-api.service";
import { RestAPIService } from "../../rest-api/rest-api.service";
import { DashboardLayoutService } from "../shared/dashboard-layout/dashboard-layout.service";

@Component({
  selector: "yt-logout",
  templateUrl: "logout.component.html",
  styleUrls: ["./logout.component.scss"],
})
export class LogoutComponent {
  vendorName: string;
  feedBack: boolean;
  feedBackLink: string;
  constructor(
    private router: Router,
    private layoutservice: DashboardLayoutService,
    public dashboardAPI: DashboardAPIService,
    private authenticationService: AuthenticationService,
    private restAPI: RestAPIService
  ) {}

  ngOnInit() {
    this.dashboardAPI.setUserDetails(null);
    this.restAPI.homeOwnerEmail = "";
    this.feedBack = this.dashboardAPI.feedbackLink ? true : false;
    this.feedBackLink = this.dashboardAPI.feedbackLink;
    this.authenticationService.logout();
    this.layoutservice.navRouterSubject.next({
      personalScreen: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      roofTop: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      lifeStyle: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      additionalinfo: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      upgrades: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      energyUsage: {
        showLink: true,
        activatedLink: true,
        disabledLink: false,
      },
      overview: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      logo1: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      logo2: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      usermenu: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      logout: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      poweredbyYt: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      topnav: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      topnavlogin: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
    });
    this.dashboardAPI.showErrorAlertStatus = true;
  }

  onLogin() {
    this.router.navigateByUrl("/dashboard/login");
  }
}
