import { ForgotPasswordModel } from "./forgot-password.model";
import { HttpClient } from "@angular/common/http";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "../../app.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DashboardLayoutService } from "../shared/dashboard-layout/dashboard-layout.service";
import { DashboardAPIService } from "../../rest-api/dashboard/dashboard-api.service";
import { take, takeWhile } from "rxjs/operators";
import { RestAPIService } from "../../rest-api/rest-api.service";

@Component({
  selector: "yt-forgot-password",
  templateUrl: "forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordModel: ForgotPasswordModel;
  errorMessage: string;
  forgotPasswordForm: FormGroup;
  baseURL: string;
  header: string;
  declarationText: boolean = false;
  private alive = true;
  public forgotPasswordFormValue: { email: string };
  forgotPasswordUrl: string;
  forgotPasswordEmailId: string;
  showSuccessDialogue = false;
  vendorCode: string;

  constructor(
    private layoutService: DashboardLayoutService,
    private httpClient: HttpClient,
    public dashboardAPI: DashboardAPIService,
    private fb: FormBuilder,
    private router: Router,
    public snackBar: MatSnackBar,
    private appService: AppService,
    private restAPIService: RestAPIService
  ) {
    this.baseURL = dashboardAPI.API_END_POINT;
    this.forgotPasswordUrl = this.baseURL + "auth/homeOwnerForgotPwd/";
  }

  ngOnInit() {
    this.vendorCode = this.appService.getVendorName();
    this.passwordSuccessMessage();

    this.layoutService.navRouterSubject.next({
      personalScreen: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      roofTop: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      lifeStyle: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      additionalinfo: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      upgrades: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      energyUsage: {
        showLink: true,
        activatedLink: true,
        disabledLink: false,
      },
      overview: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      logo1: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      logo2: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      usermenu: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      logout: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      poweredbyYt: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
      topnav: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
      },
      topnavlogin: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
      },
    });
    this.forgotPasswordForm = this.fb.group({
      email: ["", [Validators.required]],
    });
    this.forgotPasswordForm.valueChanges.subscribe(
      (data) => (this.forgotPasswordFormValue = data)
    );
  }

  get email() {
    return this.forgotPasswordForm.get("email") as FormControl;
  }

  onReturnToLogin() {
    this.router.navigate(["/dashboard/login"]);
  }

  passwordSuccessMessage(): void {
    this.restAPIService
      .getSolarVendorDetails(this.vendorCode)
      .pipe(
        take(1),
        takeWhile(() => this.alive)
      )
      .subscribe((data) => {
        this.header = data.tagLine_en;
      });
  }

  onSubmit() {
    this.forgotPasswordEmailId =
      this.forgotPasswordForm.controls["email"].value.toLowerCase();
    this.httpClient
      .get<ForgotPasswordModel>(
        this.forgotPasswordUrl +
          this.forgotPasswordEmailId +
          `?vendor=${this.vendorCode}`
      )
      .pipe(takeWhile((_) => this.alive))
      .subscribe((response) => {
        this.forgotPasswordModel = response;
        if (this.forgotPasswordModel.message === "Username is Valid") {
          this.errorMessage = "";
          this.showSuccessDialogue = true;
          this.forgotPasswordForm.reset();
        } else if (this.forgotPasswordModel.message === "Username is Invalid") {
          this.errorMessage = "Enter valid email address";
        } else if (this.forgotPasswordModel.message === "No DashBoard Access") {
          this.errorMessage =
            "You started the journey via Skytech Solar. Unfortunately, YellowTin does not support the dashboard feature for Skytech users.";
        }
      });
  }
}
