<scroll-top></scroll-top>
<div class="page-container yt__dashboard">
  <div class="padding-class">
    <div class="yt_maxWidth">
      <div
        *ngIf="showMaxPanelError || showMinPanelError || showErrorMessage"
        class="flex center justify-center vh-75 flex-column items-center error_padding"
      >
        <div *ngIf="showMaxPanelError" class="w-100 tc">
          <div class="error_subheading_text">{{ errorPhrase1 }}</div>
        </div>

        <div *ngIf="showMinPanelError" class="w-100 tc">
          <div class="error_subheading_text">{{ errorPhrase2 }}</div>
        </div>

        <div *ngIf="showErrorMessage" class="w-100 tc">
          <img src="assets/dashboard/overview/error1.png" loading="lazy" />
          <div class="error_heading_text">Unexpected error!</div>
          <div class="error_subheading_text">
            Report the error to
            <a
              href="mailto:support@yellowtin.com"
              class="link_text no-underline pointer"
              >YellowTin support</a
            >
            team.
          </div>
        </div>
      </div>
      <div *ngIf="showDashboard">
        <!-- <yt-alert-carousel
          *ngIf="alertCounter"
          [showEmailAlert]="showEmailAlert"
          [showGreetAlert]="showGreetAlert"
          [showErrorAlert]="showErrorAlert"
          [errorTechnologiesList]="errorTechnologiesList"
          [showAlert]="showAlert"
          [sliderAlert]="sliderAlert"
          [revokedAlert]="revokedAlert"
          [partialAlert]="partialAlert"
          [alertCounter]="alertCounter"
          (closeEmail)="onCloseOfEmailAlert()"
          (closeGreet)="onGreetClose()"
          (closeAlert)="onAlertClose()"
          (changeCount)="onChangeCount()"
          (closeOutcomeAlert)="onCloseOfErrorAlert()"
          (alertSetToZero)="alertCounter = 0"
          (energyInfoStatus)="onEnergyUsage($event)"
        >
        </yt-alert-carousel> -->

        <div
          class="top_header_row w-100"
          [ngClass]="{ action_wrapper: expertAdviceCardStatus }"
        >
          <div class="heading_text">
            <div class="pr3">
              {{ "homeOwenerDashboard.dashboard.overview.header" | translate }}
            </div>
            <div class="tour_btn">
              <button
                mat-raised-button
                color="accent"
                class="btn"
                (click)="onTour()"
              >
                Launch Quick Tour
              </button>
            </div>
          </div>
          <div class="pt3 pt0-ns flex items-center justify-end">
            <div class="time_frame">
              {{
                "homeOwenerDashboard.dashboard.overview.timeframe" | translate
              }}:
            </div>
            <div class="yt_text_to_lcase">
              <mat-form-field appearance="outline">
                <mat-select
                  [value]="timeSelected"
                  (selectionChange)="onValueChange($event.value, selected)"
                >
                  <mat-option
                    *ngFor="let time of timeFrameOptions"
                    [value]="time.value"
                  >
                    {{ time.viewValue?.toLowerCase() }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="overview-expert_wrapper">
          <div
            class="highChart_box w-100"
            [ngClass]="{ overview_width: expertAdviceCardStatus }"
          >
            <div class="toggle_buttons">
              <mat-button-toggle-group
                [value]="selected"
                (change)="onValueChange(timeSelected, $event.value, true)"
              >
                <mat-button-toggle value="dollar">
                  <span class="toggle_label">{{
                    "homeOwenerDashboard.dashboard.overview.dollar" | translate
                  }}</span>
                </mat-button-toggle>
                <mat-button-toggle class="label_margin" value="carbon">
                  <span class="toggle_label">{{
                    "homeOwenerDashboard.dashboard.overview.carbon" | translate
                  }}</span>
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <div class="chart_section">
              <div class="highChart" *ngIf="showDashboard">
                <div class="height" #container></div>
              </div>
              <div class="savings_with_trees">
                <div class="main_values_box tc">
                  {{
                    "homeOwenerDashboard.dashboard.overview.savingsTextLeft"
                      | translate
                  }}
                  <span class="savings_value" *ngIf="!isCarbon">
                    {{ totalSavings | currency : "$" : "symbol" : "1.0-0" }}
                  </span>
                  <span class="savings_value" *ngIf="isCarbon">
                    {{ totalCarbonSavings | number }} lbs
                  </span>
                  {{
                    "homeOwenerDashboard.dashboard.overview.savingsTextCenter"
                      | translate
                  }}
                  <span *ngIf="twentyYear"> 20 years</span>
                  <span *ngIf="tenYear"> 10 years</span>
                  <span *ngIf="oneYear"> 1 year</span>
                  <span *ngIf="oneMonth"> 1 month</span>
                  {{
                    "homeOwenerDashboard.dashboard.overview.savingsTextRight"
                      | translate
                  }}
                </div>
                <div *ngIf="isCarbon" class="treesPlanted">
                  Equivalent to
                  <strong>{{ totalTrees }} trees planted</strong>
                  <mat-icon
                    class="mat-icon"
                    matTooltip="The number of trees planted doesn’t change when you select different time frames because a tree continues to absorb carbon throughout its lifetime. For example, installing a technology that reduces 48 lbs of CO2 pollution per year equates to planting one tree (which absorbs 48 lbs of CO2 per year). Over 10 years, the technology will reduce 480 lbs and planting one tree will also have the effect of reducing 480 lbs."
                    >help_outline</mat-icon
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="expert-card_wrapper" *ngIf="expertAdviceCardStatus">
            <div class="card_container h-100">
              <yt-electrification-advice-card></yt-electrification-advice-card>
            </div>
          </div>
        </div>

        <div id="savings_overview">
          <!-- Recommendations -->
          <div class="container_box">
            <div class="recommend_section">
              <yt-recommendation
                *ngIf="solarDashboardUserConsModel && activeTab && !loader"
                [activeTab]="activeTab"
                [categoriesList]="categoriesList"
                [showWhatChoices]="showWhatChoices"
                [categorySavings]="categorySavings"
                [recommendationDetails]="recommendationList"
                (addTech)="onAdd($event)"
                (alreadyHave)="onAlreadyHave($event)"
                (removeTech)="onRemove($event)"
                [timeFrame]="timeSelected"
                (updateSavings)="updateSavings()"
                (savingsDialog)="openSavingsDialog($event)"
                [errorMsg]="showErrorMessageTab2"
                [solarErrorMessage]="solarErrorMessage"
                (openSelectionDialog)="openWhatChoices()"
                (tabName)="selectedTab($event)"
              ></yt-recommendation>
            </div>
            <div class="user_section">
              <div class="top_section">
                <div class="box_section">
                  <!-- <div
                    class="card_container"
                    matTooltip="Total potential incentives available for all of the recommendations. You can view the specific incentives in the Learn more section for each energy choice."
                  >
                    <yt-incentives
                      [totalIncentives]="totalIncentives"
                    ></yt-incentives>
                  </div> -->
                  <div class="card_container settings_box">
                    <yt-home-settings
                      (updateSavings)="updateSavings()"
                      [showHomeSettings]="showHomeSettings"
                      (onClose)="showHomeSettings = false"
                    ></yt-home-settings>
                  </div>
                </div>
                <div class="card_container">
                  <yt-already-have
                    [techList]="alreadyHaveTechs"
                    (removeTech)="onRemove($event)"
                    [progressValue]="progressBarValue"
                  ></yt-already-have>
                </div>
              </div>
            </div>
          </div>

          <ng-container *ngIf="!isApproach2RC">
            <yt-clean-choices
              *ngIf="activeTab === 'appliances'"
              [activeTab]="activeTab"
              [section]="'energyEfficiencyAppliances'"
              [activeTech]="activeTech"
            ></yt-clean-choices>
          </ng-container>

          <yt-clean-choices
            *ngIf="
              activeTab === 'simpleLowCost' ||
              activeTab === 'transportation' ||
              activeTab === 'appliances'
            "
            [activeTab]="activeTab"
            [section]="section"
            [activeTech]="activeTech"
          ></yt-clean-choices>

          <ng-container *ngIf="!isApproach2RC">
            <yt-clean-choices
              *ngIf="activeTab === 'appliances'"
              [activeTab]="activeTab"
              [section]="'yardAppliances'"
              [activeTech]="activeTech"
            ></yt-clean-choices>
          </ng-container>
        </div>
        <div class="disclaimer_text">
          The results and projections are estimates based on available data.
        </div>
      </div>
    </div>
  </div>
</div>

<!-- recommendations popup for renter and condo user -->
<div id="renterPopup" (click)="closeWhatChoices()">
  <div id="text">
    <div class="yt_popup-header padding_all">What choices apply to me?</div>
    <div class="padding_all">
      <span *ngIf="buildingTypeRenter">
        Many of the choices listed below would require landlord support and may
        not be feasible for renters. We show these choices for educational
        purposes.
      </span>
      <span *ngIf="buildingTypeCondo">
        Many of the choices listed below would require Homeowners Association
        support and may not be feasible for condominiums or mobile homes. We
        show these choices for educational purposes.
      </span>
      <ul>
        <li>Battery</li>
        <li>Heat Pump Clothes Dryer</li>
        <li>Heat Pump Heating & Cooling</li>
        <li>Heat Pump Water Heater</li>
        <li>Induction Cooking</li>
        <li>Rooftop Solar</li>
      </ul>
      Please click on
      <mat-icon class="material-icons favorite_icon">favorite_border</mat-icon>
      to add the choice to 'favorites' or click on
      <mat-icon class="material-icons already_icon"
        >check_circle_outline</mat-icon
      >
      if you already have.
    </div>
  </div>
</div>

<!-- Dialog box for energy savings savings -->
<div
  class="savingsDialog"
  *ngIf="showSavingsPopup"
  (click)="closeSavingsDialog()"
>
  <div id="text">
    <div class="yt_bg-white">
      <div class="yt_popup-header padding_all" *ngIf="savingsPopup">
        Energy Savings
      </div>
      <div class="yt_popup-header padding_all" *ngIf="!savingsPopup">
        How is this calculated?
      </div>
      <!-- To be shown when clicked on 20/1 year/month savings text -->
      <div class="f4 yt_text padding_all" *ngIf="savingsPopup">
        This represents the savings from using solar to reduce your energy costs
        from:
        <ol>
          <li>Current energy usage</li>
          <li>Future planned energy usage of the choices in "Favorites"</li>
        </ol>
        Please note there will be a reduction in your current energy usage if
        you currently have an electric appliance and include a heat pump-based
        technology in "Favorites". As an example, if you currently have an
        electric water heater and added a heat pump water heater to "Favorites",
        since the energy usage of a heat pump water heater is much lower than a
        regular electric water heater, this results in a reduction from the
        current energy usage.
      </div>
      <!-- To be shown when clicked on the How is calculated text -->
      <div class="f4 yt_text padding_all" *ngIf="!savingsPopup">
        This <strong>Combined Energy Savings</strong> estimates your
        consolidated savings when all of the choices in "Your Selections" are
        considered together. It eliminates any double counting of savings that
        can occur when you select Solar along with other clean energy choices.
        <div class="pv3">
          For example, when you select both Solar and an EV, the EV savings can
          be considered as arising from the replacement of gasoline with
          grid-supplied electricity plus the replacement of the grid-supplied
          electricity with solar.
        </div>
        Since the value shown for Solar savings also includes the savings from
        replacing grid-supplied electricity with solar, summing the individual
        savings from EV and Solar would result in double counting. The
        <strong>Combined Energy Savings</strong>, however, eliminates any such
        double counting.
      </div>
    </div>
  </div>
</div>

<!-- loader -->
<yt-loader
  [blackLoader]="showBlackLoader"
  *ngIf="loader || (showBlackLoader && isSavingsAPIDispatched)"
  top="0"
  [relative]="true"
></yt-loader>

<yt-redirect-loader
  [vendorCode]="vendorCode"
  *ngIf="showRedirectLoader"
></yt-redirect-loader>

<!-- Tour guide -->
<yt-tour-guide
  *ngIf="showTourGuide && !showRedirectLoader"
  (closeTourGuide)="closeTourGuide()"
  [vendorCode]="vendorCode"
  [stepObject]="tourGuideObject"
  (showHomeSettings)="openHomeSettings($event)"
>
</yt-tour-guide>
