import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { DashboardAPIService } from "../../../../rest-api/dashboard/dashboard-api.service";
import { RestAPIService } from "../../../../rest-api/rest-api.service";
import { LearnMoreService } from "../../../learn-more/learn-more.service";
import { SettingOverlayComponent } from "../../../overview/dashboard-v2/setting-overlay/setting-overlay.component";
import { SettingsDialogData } from "../../../overview/dashboard-v2/setting-overlay/setting-overlay.component.model";
import { SettingsOverlayService } from "../../../overview/dashboard-v2/setting-overlay/settings-overlay.service";
import { TechDetailsModel } from "../../../overview/model/dashboard.init.model";
import { TechCardInitial } from "./tech-card.init";
import { UserModifiedTechsModel } from "../../../overview/model/userdata.model";
import { TimeFrameEnum } from "src/app/homeowner-dashboard/learn-more/learn-more.enum";
import { IncentivesOverlayComponent } from "src/app/homeowner-dashboard/overview/dashboard-v2/incentives/incentives-overlay/incentives-overlay.component";
import { MarketPlaceService } from "src/app/homeowner-dashboard/market-place/market-place.service";
import { SavingsDetailsResponseType } from "src/app/homeowner-dashboard/overview/dashboard-v3/dashboard-v3.types";

@Component({
  selector: "yt-tech-cards",
  templateUrl: "./tech-cards.component.html",
  styleUrls: ["./tech-cards.component.scss"],
})
export class TechCardsComponent implements OnInit, OnChanges {
  userEmail: string;
  savingsURL: string;
  buildingType: string;
  isLowe = false;
  @Input() tabLabel: string;
  @Input() timeFrame: TimeFrameEnum;
  @Input() techList: TechDetailsModel[];
  @Output() onRemove: EventEmitter<string> = new EventEmitter();
  @Output() alreadyHave: EventEmitter<boolean> = new EventEmitter();
  @Output() updateSavings: EventEmitter<void> = new EventEmitter();
  @Output() onAdd: EventEmitter<string> = new EventEmitter();
  @Output() alreadyHaveTech: EventEmitter<string> = new EventEmitter();
  @Output() savingsDialog: EventEmitter<string> = new EventEmitter();
  shopNowOnFavorite: boolean = false;
  userModifiedTechs: UserModifiedTechsModel;
  isFavorite: boolean = false;
  leafs = new Array(4).fill("");
  tabName: string;
  isSimpleLowCost: boolean;
  alreadyHaveLoading: string;
  favoriteLoading: string;
  savingsDetails: SavingsDetailsResponseType;
  constructor(
    private marketplaceService: MarketPlaceService,
    private dialog: MatDialog,
    private router: Router,
    private techCardList: TechCardInitial,
    private learnMoreService: LearnMoreService,
    private snackBar: MatSnackBar,
    private dashboardAPIService: DashboardAPIService,
    private restAPI: RestAPIService,
    private settingService: SettingsOverlayService
  ) {}

  ngOnChanges(): void {
    const time = this.dashboardAPIService.timeFrame;
    if (time && this.techList) {
      this.techList = this.techList
        .sort((a, b) => a.dollarSavings - b.dollarSavings)
        .reverse();
    }
    this.userModifiedTechs =
      this.dashboardAPIService.energyChoicesData.userModifiedTechs;
  }

  ngOnInit(): void {
    this.isLowe =
      this.dashboardAPIService.getUserDetails().solarVendorCode === "LOWE";

    this.userEmail = this.dashboardAPIService.getUserDetails().userName;
    this.buildingType = this.dashboardAPIService.getBuildingType();
    this.savingsURL = this.dashboardAPIService.savingsUrl;
    if (this.tabLabel === "favorites") {
      this.shopNowOnFavorite = true;
    }
    if (this.tabLabel === "simpleLowCost") {
      this.isSimpleLowCost = true;
    }
    this.dashboardAPIService.alreadyHaveLoading.subscribe((response) => {
      this.alreadyHaveLoading = response;
    });
    this.dashboardAPIService.favoriteLoading.subscribe((response) => {
      this.favoriteLoading = response;
    });
  }

  translateText(technology: TechDetailsModel): string {
    const tabName = this.getCategoryName(technology);
    let techName;
    if (technology.techParameter === "portableIC") {
      techName = "ic";
    } else {
      techName = technology.techParameter;
    }
    return this.restAPI.getTranslateText(
      `homeOwenerDashboard.dashboard.recommendation.${tabName}.${techName}.toolTip`
    );
  }
  onFavorites(technology: TechDetailsModel, index): void {
    const techParameter =
      technology.techParameter === "hpsc" ? "ashp" : technology.techParameter;
    this.dashboardAPIService.favoriteLoading.next(techParameter);
    this.tabName = this.tabLabel;
    if (this.userModifiedTechs.favourites.includes(techParameter)) {
      this.onAdd.emit(technology.techParameter);
    } else {
      this.openSettingOverlay(technology, "favorite", this.tabName);
      this.userModifiedTechs.favourites.push(techParameter);
    }
  }

  onRemoveTech(value: TechDetailsModel): void {
    this.dashboardAPIService.favoriteLoading.next(value.techParameter);
    this.onRemove.emit(value.techParameter);
  }

  onAlreadyHave(technology: TechDetailsModel, index): void {
    const techParameter =
      technology.techParameter === "hpsc" ? "ashp" : technology.techParameter;
    this.dashboardAPIService.alreadyHaveLoading.next(techParameter);
    if (this.userModifiedTechs.alreadyHave.includes(techParameter)) {
      this.alreadyHaveTech.emit(technology.techParameter);
    } else if (this.tabLabel === "favorites") {
      this.tabName = this.getCategoryName(technology);
      this.openSettingOverlay(technology, "alreadyHave", this.tabName);
    } else {
      this.tabName = this.tabLabel;
      this.openSettingOverlay(technology, "alreadyHave", this.tabName);
    }
  }

  getCategoryName(technology): string {
    let tabName;
    Object.entries(this.techCardList.techList).filter((tech) => {
      Object.keys(tech[1]).filter((techName) => {
        if (techName === technology.techParameter) {
          tabName = tech[0];
        } else if (
          technology.techParameter === "battery" ||
          technology.techParameter === "portableBattery"
        ) {
          tabName = "solarBattery";
        } else if (
          technology.techParameter === "ic" ||
          technology.techParameter === "portableIC"
        ) {
          tabName = "appliances";
        }
      });
    });
    return tabName;
  }

  onLearnMore(item: TechDetailsModel): void {
    this.router.navigateByUrl(item.navigation);
    if (item.techParameter === "hpsc") {
      this.dashboardAPIService.dashboardDetailsAnalytics("lmScreen", "ashp");
    } else {
      this.dashboardAPIService.dashboardDetailsAnalytics(
        "lmScreen",
        item.techParameter
      );
    }
  }

  onShopNow(techDetails: TechDetailsModel): void {
    let techName =
      techDetails.techParameter === "hpsc" ? "ashp" : techDetails.techParameter;
    techName = techName === "ev1" || techName === "ev2" ? "ev" : techName;
    this.dashboardAPIService.dashboardDetailsAnalytics("shopNow", techName);

    if (this.isLowe && techDetails.link) {
      this.marketplaceService.openShopNowTab(techDetails.link);
    } else {
      const marketplaceRoute = "dashboard/market-place/";
      const homeAppliances = ["hpd", "hpsc", "hpwh"];
      const solarBattery = ["battery", "communitySolar", "solar"];
      const energyEfficientDevices = [
        "ledCflBulbs",
        "smartThermostat",
        "weatherStripping",
        "smartPowerStrips",
        "showerHeadsAerators",
      ];
      const transportationTech = ["ev", "eBike", "evChargers"];
      if (homeAppliances.includes(techDetails.techParameter)) {
        this.router.navigateByUrl(
          `${marketplaceRoute}homeAppliances/${techName}`
        );
      } else if (energyEfficientDevices.includes(techDetails.techParameter)) {
        if (
          techDetails.techParameter === "ledCflBulbs" ||
          techDetails.techParameter === "smartPowerStrips"
        ) {
          this.router.navigateByUrl(
            `${marketplaceRoute}energyEfficientDevices/lightingElectricDevices`
          );
        } else if (
          techDetails.techParameter === "smartThermostat" ||
          techDetails.techParameter === "weatherStripping"
        ) {
          this.router.navigateByUrl(
            `${marketplaceRoute}energyEfficientDevices/homeComfortDevices`
          );
        } else if (techDetails.techParameter === "showerHeadsAerators") {
          this.router.navigateByUrl(
            `${marketplaceRoute}energyEfficientDevices/waterSavingDevices`
          );
        }
      } else if (transportationTech.includes(techName)) {
        this.router.navigateByUrl(
          `${marketplaceRoute}transportationAndAccessories/${techName}`
        );
      } else if (solarBattery.includes(techDetails.techParameter)) {
        this.router.navigateByUrl(`${marketplaceRoute}solarBattery`);
      }

      if (techDetails.techParameter === "portableBattery") {
        this.router.navigateByUrl(
          `${marketplaceRoute}homeAppliances/portableBattery`
        );
      } else if (techDetails.techParameter === "ic") {
        this.router.navigateByUrl(`${marketplaceRoute}homeAppliances/ic`);
      } else if (techDetails.techParameter === "portableIC") {
        this.router.navigateByUrl(
          `${marketplaceRoute}homeAppliances/portableIC`
        );
      }
    }
  }

  getTimeFrame(time: TimeFrameEnum): string {
    return this.learnMoreService.renderTimeFrame(time).toLowerCase();
  }

  // Settings Overlay
  openSettingOverlay(technology: TechDetailsModel, icon, tabName?): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.position = { right: "0" };
    let dialogRef = this.dialog.open(SettingOverlayComponent, {
      width: "70%",
      height: "100%",
      autoFocus: false,
      backdropClass: "dark-backdrop",
      panelClass: "homeSetting_container",
      disableClose: true,
      data: {
        tech: technology.techParameter,
        icon,
        tabLabel: tabName,
      },
      exitAnimationDuration: 0,
    });

    dialogRef.backdropClick().subscribe(() => {
      if (
        !!Object.keys(this.settingService.settingsInfo).length ||
        !!Object.keys(this.settingService.settingsInfoErrors).length
      ) {
        dialogRef.componentInstance.settingStatus = true;
      } else {
        dialogRef.close();
      }
    });

    dialogRef.afterClosed().subscribe((result: SettingsDialogData) => {
      if (result) {
        if (result.own === "yesPlan") {
          const info = this.techMessage(result.tech, "add");
          this.snackBar.open(info, "OK", {
            duration: 8000,
          });
        }
      }
      this.dashboardAPIService.energyInfoData = false;
      this.settingService.settingsInfo = {};
      this.updateSavings.emit();
    });
  }

  checkTechListLength(): boolean {
    return this.techList.every((tech) => tech.showTech === false);
  }

  onSavings(): void {
    this.savingsDialog.emit("savings");
  }

  techMessage(techName: string, status: string): string {
    return this.dashboardAPIService.techMessage(techName, status);
  }

  getLeafTooltip(tech: string, carbon: number): string {
    return `${this.getTimeFrame(this.timeFrame)} Carbon Savings: ${carbon} lbs`;
  }

  isString(value): boolean {
    return typeof value === "string";
  }

  incentivesClick(tech: string): void {
    this.dashboardAPIService.dashboardDetailsAnalytics("incentives");
    const dialogConfig = new MatDialogConfig();
    dialogConfig.position = { right: "0" };
    let dialogRef = this.dialog.open(IncentivesOverlayComponent, {
      width: "70%",
      height: "100%",
      autoFocus: false,
      backdropClass: "dark-backdrop",
      panelClass: "homeSetting_container",
      data: { category: this.tabLabel, tech },
      exitAnimationDuration: 0,
    });
    dialogRef.afterClosed().subscribe(() => {});
  }
}
