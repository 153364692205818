<ng-container *ngIf="activeTech === 'railwayTransit'" class="container">
  <h2 class="tech_header">Railway Transit</h2>
  <div class="yt__width">
    <div class="yt_content">
      <div>
        Rail travel within or between cities is one of the most affordable,
        environmentally friendly, and effective travel methods. You can avoid
        traffic, save money, and enjoy some leisure time by taking the train,
        metro, or light rail to work.
      </div>
    </div>
  </div>
  <div class="border flex">
    <div class="yard-costrange_savings border-right">
      $2-$10 per trip
      <div class="saving_text">
        Average Cost <span class="seperator">:</span>
      </div>
    </div>
    <div class="noise_rating">
      $370/year
      <div class="saving_text">
        Average Savings<span class="seperator">:</span>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="activeTech === 'busTransit'" class="container">
  <h2 class="tech_header">Bus Transit</h2>
  <div class="yt__width">
    <div class="yt_content">
      <div>
        Traveling by bus is the least expensive mode of transportation. Buses
        are accessible and welcoming, with some providing reclining seats,
        onboard entertainment, and WIFI. By reading or working while on the bus,
        you can reclaim some of your commuting time.
      </div>
    </div>
  </div>
  <div class="border flex">
    <div class="yard-costrange_savings border-right">
      $1-$3 per trip
      <div class="saving_text">
        Average Cost <span class="seperator">:</span>
      </div>
    </div>
    <div class="noise_rating">
      $280/year
      <div class="saving_text">
        Average Savings<span class="seperator">:</span>
      </div>
    </div>
  </div>
</ng-container>
