import { Component } from "@angular/core";

@Component({
  selector: "yt-footer",
  templateUrl: "footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  showPoweredBy = false;
  vendorCode: string;

  constructor() {}

  ngOnInit(): void {
  }
}
