<div class="breakdown_overlay" *ngIf="showIncentiveBreakdown" #breakDownOverlay>
  <div class="text">
    <div class="bg-white">
      <div class="yt_popup-header yt_popup-padding tc fw6">{{ heading }}</div>
      <div class="spinner_section" *ngIf="loader">
        <i class="fa fa-spinner fa-pulse fa-2x"></i>
      </div>
      <div class="yt_popup-padding">
        <div
          class="w-100 bb yt_popup-padding-42 flex items-center flex"
          *ngFor="let rebate of rebateDetails"
        >
          <div class="w-70 fl flex">
            <span *ngIf="rebate.rebateLink"
              ><a
                class="yt_rebateName yt_rebateName_link"
                [href]="rebate.rebateLink && getTrustedUrl(rebate.rebateLink)"
                target="_blank"
              >
                {{ rebate.rebateName }}</a
              ></span
            >
            <span *ngIf="!rebate.rebateLink" class="none_link">
              {{ rebate.rebateName }}
            </span>
            <span *ngIf="rebate.specialCriteria === 'Yes'">* </span>
          </div>
          <div class="w-30 fr tr">
            <div class="yt_label">${{ rebate.rebate | number }}</div>
          </div>
        </div>
      </div>
      <div class="yt_total" *ngIf="!loader">
        <div class="w-40 fl">
          <div class="yt_label fw6">{{ netCost }}</div>
        </div>
        <div class="w-60 fr tr">
          <div class="yt_label fw6">${{ totalValue | number }}</div>
        </div>
      </div>
      <div *ngIf="showSpecialCriteria" class="yt_condition">
        * Special conditions may apply
      </div>
    </div>
  </div>
</div>
