export class GoalCardsInitial {
  categoryList = [
    {
      img: "assets/dashboard/dashboard_icons_v2/simpleCost.svg",
      altText: "simpleLowCost",
      viewValue: "Simple measures",
      value: "simpleLowCost",
      subHeader: "LED bulbs, smart thermostats and more",
      selected: false,
    },
    {
      img: "assets/dashboard/dashboard_icons_v2/transport.png",
      altText: "transportation",
      viewValue: "Transportation",
      value: "transportation",
      subHeader: "Electric vehicle, electric bike",
      selected: false,
    },
    {
      img: "assets/dashboard/dashboard_icons_v2/solar.png",
      altText: "solarBattery",
      viewValue: "Solar & Battery",
      value: "solarBattery",
      subHeader: "Create and store your own power",
      selected: false,
    },
    {
      img: "assets/dashboard/dashboard_icons_v2/appliances.svg",
      altText: "appliances",
      value: "appliances",
      viewValue: "Appliances",
      subHeader: "Water heater, heating & cooling and more",
      selected: false,
    },
  ];
}
