<div class="faq_container">
  <div class="about_technology" *ngIf="showAboutTech">
    <div class="header">Overview</div>
    <p>
      <strong>A Different Way to Light:</strong> LED (light emitting diode)
      bulbs represent a huge leap in lighting technology. Their use of
      semiconductors allows them to use much less energy and last much longer
      than other types of lighting.
      <span
        class="link_text"
        *ngIf="showReadMore"
        (click)="onReadContent('readMore')"
      >
        Read more...
      </span>
    </p>

    <div *ngIf="showReadLess">
      <p class="pt3">
        <strong>Significant Savings:</strong> LED bulbs are about 85% more
        efficient than incandescent bulbs. This means that switching out just
        one bulb that’s used for two hours/day can save you between $4 - $12
        each year (depending on your electricity rate). This will quickly cover
        the small added cost of an LED bulb. Also, since an LED bulb can last 20
        years or more, you won’t have to change the bulb every couple of years.
      </p>

      <p class="pt3">
        <strong>Cost:</strong> Costs have dropped dramatically over the last
        five years. You can now buy LED bulbs in the range of $2 - $4 for the
        replacement of typical 40 - 100 Watt bulbs. For more specialized
        fixtures, LED bulbs can cost somewhat more.
      </p>

      <p class="pt3">
        <strong>Environment:</strong> Changing out an old bulb is a super easy
        way to help the environment while saving money. Also, you’ll be throwing
        away a lot fewer bulbs because LED bulbs last so long.
      </p>

      <p class="pt3">
        <strong>Get Onboard:</strong> About one-half of US households already
        use LED bulbs for most or all of their indoor lighting. Why not join
        them?
        <span class="link_text" (click)="onReadContent('readLess')">
          Read less
        </span>
      </p>
    </div>
  </div>
  <div *ngIf="!showAboutTech">
    <div *ngIf="isActive(0)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. What are the benefits of LED bulbs?</h5>
        <p class="answer_text">
          The benefits are many! For just a little additional cost, LED bulbs
          use about 85% less energy than an incandescent bulb, saving you money
          and cutting your carbon footprint. They last 25 times as long, meaning
          reduced hassle from changing burnt-out bulbs, and they offer more
          options for the color and quality of the light they produce. Finally,
          since the great of majority of energy put into an incandescent bulb
          ends up as heat (instead of light), you might have lower cooling costs
          in the summer.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. What else can I do to reduce lighting costs?
        </h5>
        <p class="answer_text">
          Various other technologies can help. Dimmers, for example, can help
          cut energy use as well as extend the life of a bulb. Timers and
          occupancy sensors can be very effective at limiting lighting use to
          just the times when it’s actually needed. Finally, voice-activated
          approaches for increase control and convenience, making it easier to
          eliminate the lighting of unoccupied areas.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          3. Should I switch to LEDs if I have compact fluorescent (CFL) bulbs?
        </h5>
        <p class="answer_text">
          Yes, but the urgency is not as great as when you’re replacing
          incandescent bulbs. Even though CFL bulbs are more efficient than
          incandescent bulbs, they still use about twice as much energy as an
          LED bulb. Also, LEDs will last a lot longer and don’t suffer from the
          delay in reaching full light output associated with most CFLs.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(1)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How expensive are LED bulbs?</h5>
        <p class="answer_text">
          Since costs have dropped substantially since their introduction, you
          can now find LED bulbs in the range of $2 - $4 for the replacement of
          typical 40 - 100 Watt bulbs with a screw base. (You’ll likely have to
          pay somewhat more for less-common bulb types designed for specialized
          fixtures.) Also, since an LED bulb can last 20 years or more, you
          won’t have to buy new bulbs as often.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">2. How much can I save?</h5>
        <p class="answer_text">
          This will depend on the cost of electricity in your area and the
          amount of lighting you require, but it can be substantial. Even
          switching out just one incandescent bulb for an LED bulb that’s on for
          2 hours/day can save $4 - $12 over the course of a year, depending on
          your electricity rate. It’s good to start by replacing the most-used
          bulbs in your home since those will provide the bulk of the savings.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(2)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How do LED bulbs help the environment?</h5>
        <p class="answer_text">
          Since it doesn’t take a lot of time or effort, changing out an old
          incandescent or CFL bulb might be the easiest technology approach for
          helping the environment. Also, since other types of bulbs produce
          large amounts of waste heat, you may cut your summer cooling usage.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(3)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. Can I find bulbs that fit my existing lamps and fixtures?
        </h5>
        <p class="answer_text">
          For the great majority of situations, the answer is yes. You can find
          LED bulbs that will fit just about any lamp or fixture in your home.
          (For complicated fixtures and situations, you might need the help of a
          handyman or electrician.) When getting your own bulbs, since there are
          many options, make sure to get bulbs that are compatible with your
          needs. For example, you’ll want dimmable LED bulbs to match dimmable
          lamps and fixtures. Also, while buying LED bulbs, choose the hue, or
          “color temperature”, that best fits the room where the bulb will be
          placed. For example, bulbs with a color temperature of 2000K - 2700K
          are classified as “warm white” and have softer light that’s a bit more
          relaxing for your eyes. 4000K - 5000K LED bulbs produce “cool white”
          light that appears brighter and is more useful for kitchens,
          workshops, etc.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">2. What about outdoor lighting?</h5>
        <p class="answer_text">
          You can find LED floodlights and other types of bulbs commonly used
          outdoors. These are designed to withstand the elements, so there’s no
          need to treat LEDs any differently than your current outdoor bulbs.
          Since much outdoor lighting is on all night, LED bulbs can be
          especially cost effective there.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(4)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. What regulations are in place?</h5>
        <p class="answer_text">
          As a part of a long-standing set of steps toward increasing
          efficiency, the federal government released updated rules that phase
          out the sale of both incandescent and halogen options as of August
          2023 for commonly used types of bulbs. Some states have also
          implemented restrictions on the sale of certain types of bulbs.
        </p>
      </div>
    </div>
  </div>
</div>
