<div class="faq_container">
  <div class="about_technology" *ngIf="showAboutTech">
    <div class="header">Overview</div>
    <p>
      <strong>Uses:</strong> A portable battery (often called a portable power
      station) provides a relatively inexpensive way to provide backup power for
      some of your electricity-using devices in the event of a power outage.
      After charging it up from a wall outlet, you can keep such a battery in a
      closet where it’s ready to be retrieved if you lose power. They are
      typically used to power computers, Internet gateways, lights, and other
      devices that use limited amounts of power.
      <span
        class="link_text"
        *ngIf="showReadMore"
        (click)="onReadContent('readMore')"
      >
        Read more...
      </span>
    </p>
    <div *ngIf="showReadLess">
      <p class="pt3">
        <strong>Types of Systems:</strong> A portable battery is a smaller and
        less-expensive option than the stationary battery systems typically used
        in conjunction with rooftop solar. A portable system is generally in the
        range of .25 – 2.0 kWh, much smaller than the 10 kWh and larger
        batteries typically associated with a stationary system.
      </p>

      <p class="pt3">
        <strong>Cost:</strong> Purchase price depends on a variety of factors,
        but the key factor is the battery’s storage capacity, which is measured
        in kWh. For example, a .5 kWh battery generally costs about $300 - $400
        while a 1.5 kWh battery would be about $1,250 - $1,500.
      </p>

      <p class="pt3">
        <strong>Maintenance:</strong> These batteries are virtually maintenance
        free and generally come with warranties between 2 and 5 years.
      </p>

      <p class="pt3">
        <strong>Comparison to Backup Generators:</strong> The amount of energy
        stored in a portable battery is limited by the battery’s kWh rating.
        Accordingly, it will be able to provide power only for a limited period
        of time during an outage. (An exception is when the sun is shining and
        the battery can be charged from solar panels. A traditional back-up
        generator (typically fueled by natural gas or gasoline), on the other
        hand, is designed to provide power even during long-term outages. Key
        disadvantages of back-up generators, however, include higher costs,
        noise, and pollution during operation.
        <span class="link_text" (click)="onReadContent('readLess')">
          Read less
        </span>
      </p>
    </div>
  </div>
  <div *ngIf="!showAboutTech">
    <div *ngIf="isActive(0)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. What are the main uses of a portable battery?
        </h5>
        <p class="answer_text">
          A portable battery stores electricity that can be used to run
          household devices without the need for grid-supplied power. This
          provides a backup power supply when the grid is down, so important
          devices can still be operated for a limited amount of time during a
          blackout. Generally, portable systems provide backup for
          low-energy-consuming applications such as electronics, communications,
          and lighting, but larger portable batteries can sometimes power
          appliances such as a refrigerator for a day or more.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. What types of portable batteries are there?
        </h5>
        <p class="answer_text">
          Portable batteries should not be confused with their larger and
          more-expensive stationary cousins that are typically used in
          conjunction with rooftop solar. Within the category of portable
          batteries, there are various types of systems also. They range widely
          in energy (kWh) capacity, power output (kW), charging rate, type of
          output ports, battery chemistry, etc. Most portable batteries use some
          form of lithium-ion chemistry. One of the newer forms,
          lithium-iron-phosphate (LFP), allows for a much larger number of
          charge-discharge cycles and a longer system life.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">3. Can you charge from solar panels?</h5>
        <p class="answer_text">
          Normally, portable batteries are charged by plugging them into a wall
          outlet, not by using solar panels. However, some such batteries can
          accept solar power and households sometimes use this capability to
          extend the time period that backup power can be provided into an
          extended outage. This typically involves buying one or two solar
          panels and storing them along with the battery so that they’re ready
          for an outage.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(1)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How expensive are they?</h5>
        <p class="answer_text">
          Portable batteries vary widely in price, depending on the storage
          capacity and other features. A portable battery in the range of .25
          kWh – 2.0 kWh, will likely cost between $250 and $2,000.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">2. Are back-up generators less expensive?</h5>
        <p class="answer_text">
          Traditional back-up generators running on natural gas, diesel,
          propane, or gasoline are cheaper upfront, but have higher ongoing
          maintenance and fuel costs.  A portable battery can sometimes be more
          cost effective over the long run, and has the advantages of being much
          quieter and less polluting. Of course, a backup generator has the
          ability to supply power for a longer period of time since a battery
          has only a limited amount of stored energy.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(2)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. What type of maintenance is needed?</h5>
        <p class="answer_text">
          Portable batteries are safe and practically maintenance free. Typical
          lithium-ion battery chemistries can typically handle 500 – 1,000
          charge/discharge cycles, while more recent chemistries can handle
          3,000 or more.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(3)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. Can the batteries be recycled?</h5>
        <p class="answer_text">
          Yes. The batteries within a portable power station contain valuable
          metals that can be recovered and reused.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. Are home batteries good for the environment?
        </h5>
        <p class="answer_text">
          Various environmental issues are associated with lithium-ion
          batteries. These include the mining of cobalt and other materials, the
          battery production process itself, and the need for recycling or safe
          disposal at the end of a battery’s life. However, these issues are
          likely to be less troublesome than those associated with the use of a
          backup generator burning a fossil fuel.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(4)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. How difficult is setting up a portable battery?
        </h5>
        <p class="answer_text">
          The process is quite straightforward and you can easily implement it
          as a do-it-yourself project. After obtaining the battery, it should be
          charged and then placed in a location where it’s easily retrieved in
          the case of a power outage. When it’s needed, you can connect your
          devices to it through output ports such as 120 V AC outlets, 12 V DC
          ports, and USB ports.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. How long can a portable battery last as a backup source in case of
          a power outage?
        </h5>
        <p class="answer_text">
          To ensure an adequate backup power supply, it's advisable to first
          assess the power consumption of the end uses to be backed up and the
          length of time you’ll need the devices to operate during an outage.
          Then you can select a portable battery with a capacity that matches
          your needs. For example, if you wish to back up devices using a total
          of 250 W of power (such as a computer, an Internet gateway, and some
          lighting) for a 4-hour period, you would need a battery with a 1,000
          Wh (i.e., 1 kWh) capacity. When solar energy is available to replenish
          the battery, this can dramatically extend the backup time.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(5)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. Are incentives available for portable batteries?
        </h5>
        <p class="answer_text">
          Unlike the situation for larger, stationary batteries, portable
          batteries are rarely eligible for incentives. When available,
          incentives generally take the form of a utility rebate in the range of
          $50 to $100.
        </p>
      </div>
    </div>
  </div>
</div>
