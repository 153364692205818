import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";
import { MarketPlaceDetailsModel } from "../learn-more.model";
import { LearnMoreService } from "../learn-more.service";
import {
  ShowerHeadsAeratorsAssumptionsModel,
  ShowerHeadsAeratorsDetailsModel,
  ShowerHeadsAeratorsFinancialModel,
  ShowerHeadsAeratorsLearnMoreModel,
  ShowerHeadsAeratorsTechnicalDetailsModel,
} from "./shower-heads-aerators.model";
import { DropDownModel } from "../../../rest-api/rest-api.model";
import { SectionListModel } from "../../../rest-api/dashboard/dashboard-api.model";
import { AppService } from "../../../app.service";
import { OverviewService } from "../../overview/overview.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-shower-heads-aerators-details",
  templateUrl: "./shower-heads-aerators-details.component.html",
  styleUrls: ["../common-learn-more.scss"],
})
export class ShowerHeadsAeratorsDetailsComponent
  implements OnInit, AfterContentChecked
{
  loader: boolean = true;
  icon: boolean = false;
  showIncentivesBreakdown = false;
  marketplaceDetails: MarketPlaceDetailsModel;
  displayMarketPlace: boolean;
  description: string;
  marketPlaceLink: string;
  linkId: string;
  readMore: boolean = true;
  readLess: boolean = false;
  totalIncentives: number;
  incentiveStatus: boolean;
  adviceCardStatus: boolean;
  showerHeadsDetails: ShowerHeadsAeratorsDetailsModel;
  assumptionDetails: ShowerHeadsAeratorsAssumptionsModel;
  financialDetails: ShowerHeadsAeratorsFinancialModel;
  technicalDetails: ShowerHeadsAeratorsTechnicalDetailsModel;
  activeIndex: number = 0;
  tabLabel: string;
  tabIndex: number = 0;
  isLangSpanish: boolean;
  selectedSection: string = "aboutTechnology";
  FAQTabsList: DropDownModel[];
  isFAQExpanded: boolean;
  favStatus: boolean;
  favIcon: string;
  learnMoreDetails: ShowerHeadsAeratorsLearnMoreModel;

  constructor(
    private snackBar: MatSnackBar,
    private overviewService: OverviewService,
    private LearnMoreService: LearnMoreService,
    private dashboardAPI: DashboardAPIService,
    private cdRef: ChangeDetectorRef,
    private appService: AppService
  ) {}

  ngAfterContentChecked(): void {
    this.isLangSpanish = this.appService.selectedLanguage.value === "sp";
    this.cdRef.detectChanges(); // TO avoid expression checking error in console NG100
  }

  ngOnInit(): void {
    this.dashboardAPI.faqDetails().subscribe((list: SectionListModel) => {
      this.FAQTabsList = list.showerHeadAerators.faqList!;
    });
    this.marketPlaceAPI();
    this.getShowerHeadsDetails();
  }

  getShowerHeadsDetails(): void {
    this.LearnMoreService.learnMoreInfo<ShowerHeadsAeratorsDetailsModel>(
      "showerHeadsAerators"
    ).subscribe((data: ShowerHeadsAeratorsDetailsModel) => {
      this.loader = false;
      this.showerHeadsDetails = data;
      this.assumptionDetails = data.assumptionDetails;
      this.financialDetails = data.financialDetails;
      this.technicalDetails = data.technicalDetails;
      this.incentiveStatus = this.financialDetails.costDetails.showIncentives;
      this.totalIncentives = this.financialDetails.costDetails.incentives;
      this.description =
        this.technicalDetails.showerHeadsAeratorsMoreDetails[0];
      this.favStatus = this.showerHeadsDetails.learnMoreDetails.favoriteStatus;
      this.favIcon = this.LearnMoreService.updateFavoriteIcon(this.favStatus);
    });
  }

  handleActiveValue(data: string): void {
    if (data === "faq") {
      this.isFAQExpanded = true;
      this.icon = true;
    }
  }

  faqExpansionClick(): void {
    this.icon = !this.icon;
  }

  onReadContent(value: string): void {
    if (value === "readMore") {
      this.readMore = false;
      this.readLess = true;
      const spanElement = `<span class="db pt3 w100"> </span>`;
      this.description +=
        spanElement + this.technicalDetails.showerHeadsAeratorsMoreDetails[1];
    } else if (value === "readLess") {
      this.readMore = true;
      this.readLess = false;
      this.description =
        this.technicalDetails.showerHeadsAeratorsMoreDetails[0];
    }
  }

  marketPlaceAPI(): void {
    this.dashboardAPI
      .getMarketplaceDetails("aerators")
      .subscribe((response: MarketPlaceDetailsModel) => {
        this.marketplaceDetails = response;
        this.adviceCardStatus = response.expertAdvice;
        this.displayMarketPlace = this.marketplaceDetails.displayBox;
        this.linkId = this.marketplaceDetails.linkId;
        this.marketPlaceLink = this.marketplaceDetails.buttonLink;
        if (this.marketplaceDetails.linkId === "") {
          this.linkId = "";
        } else {
          this.linkId = this.marketplaceDetails.linkId;
        }
      });
  }

  handleFAQClick(): void {
    this.dashboardAPI.dashboardDetailsAnalytics(
      "moreInfo",
      "showerHeadsAerators"
    );
    window.open(
      "https://www.yellowtin.com/clean-energy-information-center-es/showerheads-and-aerators-faq-es/",
      "_blank"
    );
  }

  tabChanged(event: any) {
    this.tabLabel = event.value;
    this.tabIndex = event.index;
  }

  scrollToTop(): void {
    document.querySelector(".mat-sidenav-content")?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  onFavorite(): void {
    const ownPlan = this.favStatus ? "noPlan" : "yesPlan";
    this.overviewService
      .updateTechSettingInfo("showerHeadsAerators", ownPlan)
      .subscribe((response) => {
        this.favStatus = !this.favStatus;
        this.favIcon = this.LearnMoreService.updateFavoriteIcon(this.favStatus);
        this.getShowerHeadsDetails();

        const action = this.favStatus ? "add" : "remove";
        const info = this.dashboardAPI.techMessage(
          "showerHeadsAerators",
          action
        );
        this.snackBar.open(info, "OK", {
          duration: 8000,
        });
      });
  }
}
