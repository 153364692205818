<!-- <div class="header">
  {{ "homeOwenerDashboard.dashboard.incentivesCard.header" | translate }}
</div>
<div class="sub_text">
  {{ "homeOwenerDashboard.dashboard.incentivesCard.subHeader" | translate }}
</div>
<div class="value">${{ totalIncentives | number }}</div>
<div class="button_wrapper" *ngIf="totalIncentives">
  <button mat-raised-button color="accent" class="btn" (click)="onDetails()">
    {{ "homeOwenerDashboard.dashboard.incentivesCard.button" | translate }}
  </button>
</div> -->

<div class="incentives_wrapper" *ngIf="totalIncentives">
  <div class="details_wrapper">
    <div class="header">Incentives</div>
    <p class="sub_text">
      You are eligible for potential tax credits and rebates up to
      <strong class="value">${{ totalIncentives | number }}</strong> from all
      our recommendations.
    </p>
    <div class="button_wrapper">
      <button
        mat-raised-button
        color="accent"
        class="btn"
        (click)="onDetails()"
      >
        Explore
      </button>
    </div>
  </div>
  <div class="img_wrapper">
    <img
      src="assets/dashboard/overview/incentives.png"
      alt=""
      width="100%"
      height="100%"
    />
  </div>
</div>
