import {
  Component,
  ViewChild,
  ElementRef,
  AfterContentChecked,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit,
} from "@angular/core";
import * as Highcharts from "highcharts";
import { MatDialog } from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { HelpMeChooseComponent } from "../../shared/help-me-choose/help-me-choose.component";
import { TimeFrameEnum } from "../learn-more.enum";
import {
  ICDetailsModel,
  ICFinancialModel,
  ICTechnicalModel,
  ICAssumptionsModel,
  ICLearnMoreModel,
  ICSavingsModel,
} from "./ic-details.model";
import {
  BarChartDataModel,
  LearnMoreRequestModel,
  MarketPlaceDetailsModel,
} from "../learn-more.model";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";
import { LearnMoreService } from "../learn-more.service";
import {
  MNFAndModel,
  MNFAndModelListResponse,
} from "../../../homeowner-product/energy-choices/energy-choices.model";
import { RestAPIService } from "../../../rest-api/rest-api.service";
import { DropDownModel } from "../../../rest-api/rest-api.model";
import { AppService } from "../../../app.service";
import { SectionListModel } from "../../../rest-api/dashboard/dashboard-api.model";
import { DataModel } from "../../overview/model/dashboard.model";
import { OverviewService } from "../../overview/overview.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "yt-ic-details",
  templateUrl: "ic-details.component.html",
  styleUrls: ["../common-learn-more.scss"],
})
export class ICDetailsComponent
  implements OnInit, AfterContentChecked, AfterViewInit
{
  showHelpMeChoose: boolean;
  ic_Savings: number;
  icon = false;
  icon1 = false;
  panelOpenState = false;
  icViewDetails: ICDetailsModel;
  financialDetails: ICFinancialModel;
  technicalDetails: ICTechnicalModel;
  assumptionDetails: ICAssumptionsModel;
  marketPlaceDetails: MarketPlaceDetailsModel;
  selected: TimeFrameEnum;
  twentyYear: boolean;
  oneYear: boolean;
  oneMonth: boolean;
  ICType: string;
  yAxisLabel: string;
  yAxisLabel1: string;
  chartOptions1: Highcharts.Options = {};
  textUnderChart: string;
  showTooltipZero: boolean;
  icForm: FormGroup;
  showIncentivesStatus: boolean;
  loader = true;
  totalIncentives: number;
  displayMarketPlaceBox: boolean;
  marketPlaceLink: string;
  haveResponse = false;
  buildingType: string;
  header: string;
  isPortable: boolean;
  imageSrc: string;
  upfrontTooltip: string;
  icInfoModel: DataModel;
  showIncentiveBreakdown: boolean;
  mnfAndModel: string;
  vendorName: string;
  linkId: string;
  makeAndModelList: MNFAndModel[];
  typeOfGas: DropDownModel[];
  techType: string = "ic";
  timeFrame: string;
  adviceCardStatus: boolean;
  techParameter: string;
  timeSegmentList: DropDownModel[];
  @ViewChild("loaderButton", { read: ElementRef }) saveButton: ElementRef;
  @ViewChild("container", { read: ElementRef }) container: ElementRef;
  activeIndex: number = 0;
  tabIndex: number = 0;
  tabLabel: string;
  isLangSpanish: boolean;
  selectedSection: string = "aboutTechnology";
  FAQTabsList: DropDownModel[];
  isTechnicalExpanded: boolean;
  isFAQExpanded: boolean;
  selectedICType: string;
  cooktopTypeList: DropDownModel[];
  showToggle: boolean = false;
  favStatus: boolean;
  favIcon: string;
  learnMoreDetails: ICLearnMoreModel;
  financeIcSavings: ICSavingsModel;

  constructor(
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private dashboardService: DashboardAPIService,
    private learnMoreService: LearnMoreService,
    private restAPIService: RestAPIService,
    private cdRef: ChangeDetectorRef,
    private appService: AppService,
    private overviewService: OverviewService
  ) {
    this.typeOfGas = this.learnMoreService.typeOfGas;
    this.timeSegmentList = this.learnMoreService.timeSegmentList;
    this.icForm = this.fb.group({
      howManyPeople: [
        "3",
        [
          Validators.required,
          Validators.pattern("^([1-9]|10)$"),
          Validators.min(1),
          Validators.max(12),
        ],
      ],
      currentRange: ["Natural gas", Validators.required],
      model: ["", Validators.required],
    });
  }

  ngAfterViewInit(): void {
    this.getMarketplaceDetails(this.selectedICType);
  }

  ngAfterContentChecked(): void {
    this.isLangSpanish = this.appService.selectedLanguage.value === "sp";
    this.cdRef.detectChanges(); // TO avoid expression checking error in console NG100
    this.cooktopTypeList = this.learnMoreService.cooktopTypeList;
    this.selectedICType = this.dashboardService.icType;
    this.isPortable = this.selectedICType === "portable";
    this.getICInfo();
  }

  handleActiveValue(data: string): void {
    if (data === "details") {
      this.isTechnicalExpanded = true;
      this.icon = true;
    } else if (data === "faq") {
      this.isFAQExpanded = true;
      this.icon1 = true;
    }
  }

  expansionPanelClick(): void {
    this.icon = !this.icon;
  }

  faqExpansionClick(): void {
    this.icon1 = !this.icon1;
  }

  getICViewDetails(val: TimeFrameEnum): void {
    this.learnMoreService
      .learnMoreInfo<ICDetailsModel>("ic")
      .subscribe((response: ICDetailsModel) => {
        this.loader = false;
        this.haveResponse = true;
        this.icViewDetails = response;
        this.financialDetails = this.icViewDetails.icFinancialDetails;
        this.technicalDetails = this.icViewDetails.icTechnicalDetails;
        this.totalIncentives = this.financialDetails.icCost.incentives;
        this.assumptionDetails = this.icViewDetails.assumptionsDetails;
        this.selectedICType = this.financialDetails.icSavings.icType;
        this.learnMoreDetails = this.icViewDetails.learnMoreDetails;
        this.favStatus = this.icViewDetails.learnMoreDetails.favoriteStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
        this.showIncentivesStatus = this.financialDetails.icCost.showIncentives;
        this.icFormDetails = this.technicalDetails;
        this.mnfAndModel = this.icViewDetails.learnMoreDetails.mnfAndModel;
        this.ICType = this.technicalDetails.currentIc.currentRange;
        this.financeIcSavings = this.financialDetails.icSavings;
        this.textUnderChart =
          "Even if an induction cooktop doesn’t save you any (or much) money, it can often reduce pollution – most importantly toxic indoor air pollution.";
        this.ICType = this.ICType === "Natural gas" ? "gas" : this.ICType;
        this.yAxisLabel = "Energy cost with";
        this.yAxisLabel1 = "induction cooktop";
        this.updateChart(val);
        this.showTooltipZero = this.ic_Savings <= 0;
      });
  }

  updateChart(val: TimeFrameEnum): void {
    let energyCost;
    let dataInputs: BarChartDataModel[] = [];

    this.setTimeSelector(val);
    energyCost = this.financeIcSavings.dollarSavings.withInductionCooktop[val];
    energyCost = energyCost <= 0 ? 0.0001 : energyCost;
    this.ic_Savings = this.financeIcSavings.dollarSavings.total[val];
    dataInputs = [
      { y: energyCost, color: "#FEC372" },
      {
        y: this.financeIcSavings.dollarSavings.withoutInductionCooktop[val],
        color: "#414141",
      },
    ];
    this.onUpdateChartOptions(dataInputs, this.yAxisLabel, this.yAxisLabel1);
  }

  getIcMakeAndModelList() {
    this.restAPIService
      .getMakeAndModelList("ic")
      .subscribe(
        (response: MNFAndModelListResponse) =>
          (this.makeAndModelList = response.mnfDropDownList)
      );
  }

  ngOnInit(): void {
    this.vendorName = this.dashboardService.getUserDetails().solarVendorCode;
    this.dashboardService.faqDetails().subscribe((list: SectionListModel) => {
      this.FAQTabsList = list.ic.faqList!;
    });
    this.showHelpMeChoose =
      this.dashboardService.vendorDetails.helpMeChoose.ic!;
    this.setTimeSelector(this.dashboardService.timeFrame);
    this.getICViewDetails(this.selected);
    if (!this.isPortable) this.getIcMakeAndModelList();
    this.getICInfo();
    this.getIcMakeAndModelList();
  }

  isMNFAndModelListAbsent(): boolean {
    return this.makeAndModelList === null;
  }

  setTimeSelector(val: TimeFrameEnum): void {
    this.selected = val;
    if (this.selected) {
      this.timeFrame = this.learnMoreService
        .renderTimeFrame(this.selected)
        .toLowerCase();
    }
  }

  getICInfo(): void {
    this.upfrontTooltip =
      "Includes equipment, labor, permits, etc. Excludes charges for main service panel upgrade (which is sometimes needed).";
    this.buildingType = this.dashboardService.buildType;
    this.showToggle = !this.overviewService.updateEnergyChoiceInfo(
      this.buildingType
    );
    this.dashboardService.getICInfo(this.selectedICType);
    this.icInfoModel = this.dashboardService.icInfo;
    this.header = this.icInfoModel.title;
    if (this.isPortable) {
      this.upfrontTooltip =
        "Equipment cost only. There is no installation cost.";
    }
    this.techParameter = this.isPortable ? "portableIC" : "ic";
    this.imageSrc = this.icInfoModel.image;
  }

  onUpdateChartOptions(inputData: BarChartDataModel[], yAxis, yAxis1): void {
    let filteredInputData: BarChartDataModel[] = [];
    let i = 0;
    for (let entry of inputData) {
      if (entry.y != 0) {
        filteredInputData[i] = entry;
        i++;
      }
    }
    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
      },
    });
    this.chartOptions1 = {
      credits: {
        enabled: false,
      },
      chart: {
        type: "bar",
      },
      title: {
        text: "",
      },
      xAxis: {
        labels: {
          style: {
            color: "#333333",
            fontSize: "16px",
            fontFamily: "Open sans",
          },
        },
        categories: [
          `${yAxis} <br> ${yAxis1}`,
          `Energy cost with <br> ${this.ICType.toLowerCase()} cooktop`,
        ],
        gridLineWidth: 0,
        lineWidth: 1,
        tickWidth: 0,
      },
      yAxis: {
        tickAmount: 8,
        tickWidth: 0,
        gridLineWidth: 0,
        lineWidth: 1,
        title: {
          text: null,
        },
        labels: {
          style: {
            color: "#333333",
            fontSize: "12px",
            fontFamily: "Open sans",
          },
          formatter: function (this) {
            if (Number(this.value) >= 1000) {
              return "$" + Number(this.value) / 1000 + "K";
            } else {
              return "$" + this.value;
            }
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          pointWidth: 50,
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "16px",
              fontWeight: "normal",
              fontFamily: "Open sans",
            },
            formatter: function (this) {
              if (this.point.y && this.point.y < 1) {
                return "$0";
              } else {
                return (
                  "$" +
                  this.point.y?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                );
              }
            },
            animation: {
              duration: 20,
            },
          },
        },
        series: {
          borderWidth: 0,
          animation: {
            duration: 1500,
          },
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              chart: {
                className: "small-chart",
              },
            },
          },
        ],
      },
      series: [
        {
          type: "bar",
          showInLegend: false,
          data: filteredInputData,
        },
      ],
    };
    Highcharts.chart(this.container.nativeElement, this.chartOptions1);
  }

  get isNoOfPeopleOutOfRange(): boolean {
    return (
      this.icForm.controls["howManyPeople"].hasError("pattern") ||
      this.icForm.controls["howManyPeople"].hasError("min") ||
      this.icForm.controls["howManyPeople"].hasError("max")
    );
  }

  get isNoOfPeopleRequired(): boolean {
    return this.icForm.controls["howManyPeople"].hasError("required");
  }

  get isCurrentRangeRequired(): boolean {
    return this.icForm.controls["currentRange"].hasError("required");
  }

  get icData(): LearnMoreRequestModel {
    return {
      ic: {
        howManyPeople: this.icForm.get("howManyPeople")?.value,
        currentRange: this.icForm.get("currentRange")?.value,
        mnfAndModel: this.icForm.get("model")?.value,
      },
    };
  }

  set icFormDetails(details: ICTechnicalModel) {
    this.icForm
      .get("howManyPeople")
      ?.setValue(this.learnMoreDetails.howManyPeople);
    this.icForm
      .get("currentRange")
      ?.setValue(this.learnMoreDetails.currentRange);
    this.mnfAndModel = this.icViewDetails.learnMoreDetails.mnfAndModel;
    this.icForm.get("model")?.setValue(this.mnfAndModel);
  }

  onSaveDetails(isDetails: boolean): void {
    this.haveResponse = false;
    if (
      this.learnMoreService.isFormEditted(
        this.icData.ic!,
        this.learnMoreDetails
      )
    ) {
      if (isDetails) {
        this.saveButton.nativeElement.innerHTML =
          'Saving... &nbsp;<i class="fa fa-spinner fa-spin"></i>';
      }
      this.dashboardService.dashboardDetailsAnalytics(
        "save",
        this.techParameter
      );
      this.learnMoreService
        .updateLearnMoreInfo("ic", this.icData)
        .subscribe((data: any) => {
          setTimeout(() => {
            this.saveButton.nativeElement.innerHTML = "SAVE DETAILS";
            this.getICViewDetails(this.selected);
          }, 800);
        });
    }
  }

  openHelpMeChoose(): void {
    let dialogRef = this.dialog.open(HelpMeChooseComponent, {
      width: "90%",
      height: "75%",
      autoFocus: false,
      backdropClass: "dark-backdrop",
      panelClass: "custom-dialog-container",
      data: { tech: "ic" },
    });
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result !== undefined) {
        this.icForm.get("model")?.setValue(result);
        this.onSaveDetails(false);
      }
    });
  }

  handleFAQClick(): void {
    this.dashboardService.dashboardDetailsAnalytics(
      "moreInfo",
      this.techParameter
    );
    if (this.techParameter === "portableIC") {
      window.open(
        "https://www.yellowtin.com/clean-energy-information-center-es/portable-cooktop-faq-es/",
        "_blank"
      );
    } else {
      window.open(
        "https://www.yellowtin.com/clean-energy-information-center-es/induction-cooktop-faq-es/",
        "_blank"
      );
    }
  }

  tabChanged(event: any) {
    this.tabLabel = event.value;
    this.tabIndex = event.index;
  }

  scrollToTop(): void {
    document.querySelector(".mat-sidenav-content")?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  getSectionView(elementId: string) {
    const el = document.querySelector(".highChart_card_mobile");
    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
      });
    }
    if (document.getElementById(elementId)) {
      document.getElementById(elementId)?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  onSelectICType(event): void {
    this.dashboardService.icType = event.value;
    this.getICViewDetails(this.selected);
    this.getICInfo();
    this.getMarketplaceDetails(event.value);
  }

  getMarketplaceDetails(tech: string): void {
    this.techType = tech === "standard" ? "ic" : "portableIC";
    this.dashboardService
      .getMarketplaceDetails(this.techType)
      .subscribe((response) => {
        this.marketPlaceDetails = response;
        this.adviceCardStatus = response.expertAdvice;
        this.displayMarketPlaceBox = this.marketPlaceDetails.displayBox;
        this.marketPlaceLink = this.marketPlaceDetails.buttonLink;
        if (this.marketPlaceDetails.linkId === "") {
          this.linkId = "";
        } else {
          this.linkId = this.marketPlaceDetails.linkId;
        }
      });
  }

  onFavorite(): void {
    const techKey = this.isPortable ? "portableIC" : "ic";
    const ownPlan = this.favStatus ? "noPlan" : "yesPlan";
    this.overviewService
      .updateTechSettingInfo(techKey, ownPlan)
      .subscribe((response) => {
        this.favStatus = !this.favStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
        this.getICViewDetails(this.selected);
        const action = this.favStatus ? "add" : "remove";
        const info = this.dashboardService.techMessage(techKey, action);
        this.snackBar.open(info, "OK", {
          duration: 8000,
        });
      });
  }
}
