import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  AfterContentChecked,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatRadioChange } from "@angular/material/radio";
import { MNFAndModel } from "../../../../../../homeowner-product/energy-choices/energy-choices.model";
import { RadioOptionsModel } from "../../../../../../rest-api/dashboard/dashboard-api.model";
import { DashboardAPIService } from "../../../../../../rest-api/dashboard/dashboard-api.service";
import { DropDownModel } from "../../../../../../rest-api/rest-api.model";
import { RestAPIService } from "../../../../../../rest-api/rest-api.service";
import { Utils } from "../../../../../../utils/util";
import { LearnMoreService } from "../../../../../learn-more/learn-more.service";
import { HelpMeChooseComponent } from "../../../../../shared/help-me-choose/help-me-choose.component";
import { EVDataModel } from "../../../../model/userdata.model";
import {
  SettingsDialogData,
  ValidationStatusModel,
} from "../../setting-overlay.component.model";
import { SettingsOverlayService } from "../../settings-overlay.service";
import { Subject } from "rxjs";

@Component({
  selector: "yt-ev",
  templateUrl: "ev.component.html",
  styleUrls: ["../../common-tech.scss"],
})
export class EVComponent implements OnInit, AfterContentChecked {
  eVForm: FormGroup;
  panelOpenState = false;
  hideAddAnotherEVButton: boolean;
  disableBoughtAndDatePurchased: boolean[] = [];
  updateMode: boolean;
  disableSave = false;
  disableDatePicker: any[] = [];
  ev0bought: string;
  ev1bought: string;
  ev1Data: string;
  ev2Data: string;
  ev: FormArray;
  showAddEV = true;
  monthList: string[];
  charges: string[];
  lastTwelthMonth: string;
  ownValue: string[] = [];
  showAnnualMilesPopup = false;
  radioButtonOptions: RadioOptionsModel[];
  evDataModel: EVDataModel[];
  mnfDropDownList: MNFAndModel[][] = [];
  selectedRadioButton: string[] = [];
  financeList: DropDownModel[];
  evSettings: EVDataModel[];
  haveInputError: boolean;
  isDatePurchaseEmpty: boolean[] = [];
  subject: Subject<any> = new Subject();
  @Input() makeAndModelList1: MNFAndModel[];
  @Input() makeAndModelList2: MNFAndModel[];
  @Input() activeIcon: string;
  @Input() techParameter: string;
  @Input() evDetails;
  @Output() techSave: EventEmitter<SettingsDialogData> = new EventEmitter();

  constructor(
    private dashboardAPIService: DashboardAPIService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private restAPIService: RestAPIService,
    private utils: Utils,
    private learnMoreService: LearnMoreService,
    private settingService: SettingsOverlayService,
    private cdRef: ChangeDetectorRef
  ) {
    this.financeList = this.learnMoreService.financeList;
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.dashboardAPIService.getRadioButtonDetails().subscribe((data) => {
      this.radioButtonOptions = data.radioButtons;
    });
    this.monthList = this.utils.monthList;
    this.lastTwelthMonth = this.utils.lastTwelthMonth;
    this.charges = this.utils.chargesList;
    this.haveInputError = this.settingService.checkSettingsInfoError("ev");
    this.validationState();
    this.eVForm = this.fb.group({
      ev: this.fb.array([this.createItem()]),
    });
    this.evDataModel = this.haveInputError
      ? this.settingService.settingsInfoErrors.transportation?.ev!
      : this.settingService.checkSettingsInfo("ev")
      ? this.settingService.settingsInfo.transportation?.ev!
      : this.dashboardAPIService.energyChoicesData.transportation.ev!;
    if (this.evDataModel[0] && this.evDataModel[1]) {
      this.showAddEV = false;
      const controls = <FormArray>this.eVForm.controls["ev"];
      controls.push(this.createItem());
      this.setSelectedRadioValues(
        this.evDataModel[0].own,
        this.evDataModel[1].own
      );
    } else {
      this.setSelectedRadioValues(this.evDataModel[0].own);
    }

    this.evDataModel.forEach((ev: EVDataModel, index: number) => {
      this.ownValue.push(ev.own);
      if (ev.own === "yes") {
        this.eVForm.get(`ev.${index}`)?.get("own")?.setValue(ev.own);
        this.eVForm.get(`ev.${index}`)?.get("model")?.setValue(ev.model);
        this.eVForm.get(`ev.${index}`)?.get("charging")?.setValue(ev.charging);
        this.eVForm.get(`ev.${index}`)?.get("finance")?.setValue(ev.financial);
        this.eVForm.get(`ev.${index}`)?.get("bought")?.setValue(ev.bought);
        this.eVForm
          .get(`ev.${index}`)
          ?.get("annualMiles")
          ?.setValue(ev.annualMiles);
        if (ev.bought) {
          this.eVForm
            .get(`ev.${index}`)
            ?.get("datePurchased")
            ?.setValue(ev.datePurchased);
        } else {
          this.eVForm
            .get(`ev.${index}`)
            ?.get("datePurchased")
            ?.setValue(this.lastTwelthMonth);
        }
        this.disableBoughtAndDatePurchased[index] = true;
        this.showAddEV = true;
      } else if (ev.own === "yesPlan") {
        this.eVForm.get(`ev.${index}`)?.get("own")?.setValue(ev.own);
        this.eVForm.get(`ev.${index}`)?.get("model")?.setValue(ev.model);
        this.eVForm.get(`ev.${index}`)?.get("charging")?.setValue(ev.charging);
        this.eVForm.get(`ev.${index}`)?.get("finance")?.setValue(ev.financial);
        this.eVForm.get(`ev.${index}`)?.get("bought")?.setValue(ev.bought);
        this.eVForm
          .get(`ev.${index}`)
          ?.get("annualMiles")
          ?.setValue(ev.annualMiles);
        this.eVForm.get(`ev.${index}`)?.get("bought")?.disable();
        this.eVForm.get(`ev.${index}`)?.get("datePurchased")?.disable();
        this.disableDatePicker[index] = true;
        this.disableBoughtAndDatePurchased[index] = false;
        this.showAddEV = true;
      } else if (ev.own === "noPlan") {
        this.eVForm.get(`ev.${index}`)?.get("own")?.setValue(ev.own);
        this.eVForm.get(`ev.${index}`)?.get("model")?.setValue(ev.model);
        this.eVForm
          .get(`ev.${index}`)
          ?.get("annualMiles")
          ?.setValue(ev.annualMiles);
        this.eVForm.get(`ev.${index}`)?.get("charging")?.setValue(ev.charging);
        this.eVForm.get(`ev.${index}`)?.get("finance")?.setValue(ev.financial);
        this.eVForm.get(`ev.${index}`)?.enable();
        this.disableDatePicker[index] = true;
        this.showAddEV = false;
        this.disableBoughtAndDatePurchased[index] = false;
      }
      this.eVForm.get(`ev.${index}`)?.get("own")?.enable();
    });

    this.mnfDropDownList = this.restAPIService.updateMakeAndModel();
    if (this.activeIcon) {
      const evIndex = Number(this.techParameter.substring(2)) - 1;

      if (this.activeIcon === "favorite") {
        this.eVForm.get(`ev.${evIndex}`)?.get("own")?.setValue("yesPlan");
      } else if (this.activeIcon === "alreadyHave") {
        this.eVForm.get(`ev.${evIndex}`)?.get("own")?.setValue("yes");
      }
      if (evIndex === 0) {
        this.setSelectedRadioValues(
          this.eVForm.get(`ev.${0}`)?.get("own")?.value
        );
      } else {
        this.setSelectedRadioValues(
          this.eVForm.get(`ev.${0}`)?.get("own")?.value,
          this.eVForm.get(`ev.${1}`)?.get("own")?.value
        );
      }
      this.onValueChanges();
      this.onSelectOfQuestion(evIndex);
    } else {
      this.onValueChanges();
    }
  }

  onRadioChange(event: MatRadioChange, index: number) {
    //whether tech is ev2
    if (index === 1) {
      this.setSelectedRadioValues(this.selectedRadioButton[0], event.value);
    } else {
      this.setSelectedRadioValues(event.value);
    }
  }

  setSelectedRadioValues(value: string, value_2?: string): void {
    this.selectedRadioButton[0] = value;
    if (value_2) {
      this.selectedRadioButton[1] = value_2;
    }
  }

  onPopUp(index) {
    this.showAnnualMilesPopup = true;
  }

  onClosePopup() {
    this.showAnnualMilesPopup = false;
  }

  isDatePurchasedEmpty() {
    if (this.evArray.length === 1) {
      if (this.ownValue[0] === "yes") {
        if (this.eVForm.get(`ev.${0}`)?.get("datePurchased")?.value === null) {
          return true;
        }
      }
    }
    if (this.evArray.length > 1) {
      if (this.ownValue[0] === "yes") {
        if (this.eVForm.get(`ev.${0}`)?.get("datePurchased")?.value === null) {
          return true;
        }
      }
      if (this.ownValue[1] === "yes") {
        if (this.eVForm.get(`ev.${1}`)?.get("datePurchased")?.value === null) {
          return true;
        }
      }
    }
    return false;
  }

  isMNFAndModelListAbsent(): boolean {
    return this.mnfDropDownList.length === 0;
  }

  isDatePurchasedSelected(index: number): boolean {
    const datePurchased = this.eVForm.get(`ev.${index}`)?.get("datePurchased")!;
    return datePurchased.touched && datePurchased.invalid;
  }

  createItem(): FormGroup {
    return this.fb.group({
      own: ["", Validators.required],
      model: ["", Validators.required],
      annualMiles: [
        "15000",
        [
          Validators.required,
          Validators.pattern(
            "^([0-9][0-9][0-9][0-9]|[0-4][0-9][0-9][0-9][0-9]|)$"
          ),
          Validators.min(1000),
          Validators.max(40000),
        ],
      ],
      charging: ["100", Validators.required],
      finance: ["cash", Validators.required],
      bought: [""],
      datePurchased: [""],
    });
  }

  onSelectOfQuestion(i) {
    this.ownValue[i] = this.eVForm.get(`ev.${i}`)?.get("own")?.value;
    if (this.ownValue[i] === "yes") {
      this.eVForm.get(`ev.${i}`)?.get("model")?.enable();
      this.eVForm.get(`ev.${i}`)?.get("annualMiles")?.enable();
      this.eVForm.get(`ev.${i}`)?.get("charging")?.enable();
      this.eVForm.get(`ev.${i}`)?.get("finance")?.enable();
      this.eVForm.get(`ev.${i}`)?.get("bought")?.enable();
      this.eVForm.get(`ev.${i}`)?.get("datePurchased")?.enable();
      this.showAddEV = true;
      this.disableBoughtAndDatePurchased[i] = true;
    } else if (this.ownValue[i] === "yesPlan") {
      this.eVForm.get(`ev.${i}`)?.get("model")?.enable();
      this.eVForm.get(`ev.${i}`)?.get("annualMiles")?.enable();
      this.eVForm.get(`ev.${i}`)?.get("charging")?.enable();
      this.eVForm.get(`ev.${i}`)?.get("finance")?.enable();
      this.eVForm.get(`ev.${i}`)?.get("bought")?.disable();
      this.eVForm.get(`ev.${i}`)?.get("datePurchased")?.disable();
      this.showAddEV = true;
      this.disableBoughtAndDatePurchased[i] = false;
    } else if (this.ownValue[i] === "noPlan") {
      this.eVForm.get(`ev.${i}`)?.get("bought")?.disable();
      this.eVForm.get(`ev.${i}`)?.get("datePurchased")?.disable();
      this.eVForm.get(`ev.${i}`)?.get("model")?.enable();
      this.eVForm.get(`ev.${i}`)?.get("annualMiles")?.enable();
      this.eVForm.get(`ev.${i}`)?.get("charging")?.enable();
      this.eVForm.get(`ev.${i}`)?.get("finance")?.enable();
      this.showAddEV = false;
      this.disableBoughtAndDatePurchased[i] = false;
    }
  }

  public get evArray(): FormArray {
    return this.eVForm.get("ev") as FormArray;
  }

  onAddAnotherEV(): void {
    this.hideAddAnotherEVButton = true;
    this.showAddEV = false;
    const controls = <FormArray>this.eVForm.controls["ev"];
    controls.push(this.createItem());
    this.setDefaultValueForCarModel(controls.length - 1);
    this.disableBoughtAndDatePurchased[1] = false;
    this.eVForm.get(`ev.${1}`)?.get("own")?.setValue("yesPlan");
    this.ownValue.push("yesPlan");
    this.setSelectedRadioValues(this.ownValue[0], this.ownValue[1]);
  }

  removeEV(): void {
    this.eVForm.get(`ev.${1}`)?.reset();
    if (this.eVForm.get(`ev.${0}`)?.get("own")?.value === null) {
      this.showAddEV = false;
    } else {
      this.showAddEV = true;
    }
    if (this.eVForm.value.ev.length > 1) {
      this.evArray.removeAt(1);
    }
  }

  setDefaultValueForCarModel(index: number) {
    var arrayControl = this.eVForm.get("ev") as FormArray;
    arrayControl.at(index).get("model")?.setValue("Nissan Leaf S");
  }

  onValueChanges(): void {
    this.eVForm.valueChanges.subscribe((data) => {
      this.subject.pipe().subscribe(() => {
        this.evSettings = data.ev.map((element) => {
          if (element.own === "yes") {
            const datePurchased = element.datePurchased;
            const bought = this.utils.isDatePurchaseSelected(datePurchased);
            return {
              own: element.own,
              model: element.model,
              annualMiles: element.annualMiles,
              charging: element.charging,
              financial: element.finance,
              bought: bought,
              datePurchased: bought ? datePurchased : null,
            };
          } else if (element.own === "yesPlan" || element.own === "noPlan") {
            return {
              own: element.own,
              model: element.model,
              annualMiles: element.annualMiles,
              charging: element.charging,
              financial: element.finance,
            };
          }
          return;
        });
        if (this.eVForm.valid) {
          this.settingService.updateSettingsInfo(
            this.dashboardAPIService.energyChoicesData.transportation,
            this.evSettings,
            "transportation",
            "ev"
          );
        } else {
          this.settingService.updateSettingsInfoErrors(
            this.dashboardAPIService.energyChoicesData.transportation,
            this.evSettings,
            "transportation",
            "ev"
          );
        }
      });
      this.subject.next();
    });
  }

  onSaveEV() {
    this.settingService.putSettingsInfo(this.getSettingsInfo);
  }

  getSettingsInfo = () => {
    if (this.activeIcon) {
      const evIndex = Number(this.techParameter.substring(2)) - 1;
      this.techSave.emit({ own: this.ownValue[evIndex], tech: "ev" });
    }
  };

  onCarTypeChange(value: string, index: number): boolean {
    if (index === 0) {
      if (this.makeAndModelList1) {
        let carType = this.makeAndModelList1
          .filter((list: MNFAndModel) => list.mnfAndModel === value)
          .map((list: MNFAndModel) => list.carType)[0];
        return carType === "PHEV";
      }
    } else if (index === 1) {
      if (this.makeAndModelList2) {
        let carType = this.makeAndModelList2
          .filter((list: MNFAndModel) => list.mnfAndModel === value)
          .map((list: MNFAndModel) => list.carType)[0];
        return carType === "PHEV";
      }
    }
    return false;
  }

  openDialogStatus(evStatus) {
    if (evStatus === 0) {
      this.openDialog1();
    }
    if (evStatus === 1) {
      this.openDialog2();
    }
  }

  openDialog1(): void {
    let dialogRef = this.dialog.open(HelpMeChooseComponent, {
      width: "90%",
      height: "75%",
      autoFocus: false,
      backdropClass: "dark-backdrop",
      panelClass: "custom-dialog-container",
      data: { tech: "ev1" },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.ev1Data = result;
        this.setDefaultValueForCarModel1(0);
      }
    });
  }

  openDialog2(): void {
    let dialogRef = this.dialog.open(HelpMeChooseComponent, {
      width: "90%",
      height: "75%",
      autoFocus: false,
      backdropClass: "dark-backdrop",
      panelClass: "custom-dialog-container",
      data: { tech: "ev2" },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.ev2Data = result;
        this.setDefaultValueForCarModel2(1);
      }
    });
  }

  setDefaultValueForCarModel1(index: number) {
    var arrayControl = this.eVForm.get("ev") as FormArray;
    arrayControl.at(index)?.get("model")?.setValue(this.ev1Data);
  }

  setDefaultValueForCarModel2(index: number) {
    var arrayControl = this.eVForm.get("ev") as FormArray;
    arrayControl.at(index)?.get("model")?.setValue(this.ev2Data);
  }

  validationState(): void {
    if (this.haveInputError) {
      const evErrors =
        this.settingService.settingsInfoErrors.transportation?.ev!;
      for (const evError of evErrors) {
        this.isDatePurchaseEmpty.push(
          "datePurchased" in evError && !evError.datePurchased
        );
      }
    }
  }

  getValidationMessage(control: string, index: number): ValidationStatusModel {
    const currentControl = this.eVForm.get(`ev.${index}.${control}`)!;
    const error: ValidationStatusModel = { message: "", status: false };
    if (currentControl.hasError("required")) {
      error.status = true;
      error.message = "Required!";
    } else if (
      currentControl.hasError("pattern") ||
      currentControl.hasError("min") ||
      currentControl.hasError("max")
    ) {
      error.status = true;
      error.message = "Please enter a value between 1000 and 40000";
    }
    return error;
  }

  onDatePurchaseChange(i): void {
    const datePurchased = this.eVForm.get(`ev.${i}`)?.get("datePurchased")!;
    this.isDatePurchaseEmpty[i] = datePurchased.invalid;
  }

  getValue(key: string, index: number): string {
    return this.eVForm.get(`ev.${index}`)?.get(key)?.value;
  }
}
