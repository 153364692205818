<div class="faq_container">
  <div class="about_technology" *ngIf="showAboutTech">
    <div class="header">Overview</div>
    <p>
      <strong>Simply Saving:</strong> It’s simple to place weather stripping
      around leaking doors and windows, and it really helps to cut heating and
      cooling costs. It’s also one of the most convenient ways to trim your
      carbon footprint.
      <span
        class="link_text"
        *ngIf="showReadMore"
        (click)="onReadContent('readMore')"
      >
        Read more...
      </span>
    </p>

    <div *ngIf="showReadLess">
      <p class="pt3">
        <strong>How it Helps:</strong> While it may not seem like much, the
        amount of heated and cooled air leaking from your home over the course
        of a year adds up. With weather stripping, you can limit your losses and
        make your home more comfortable.
      </p>

      <p class="pt3">
        <strong>Cost:</strong> The typical cost of weather stripping for an
        average sized house should be under $200. No specialized skills are
        needed for installation, so it’s a great do-it-yourself project.
      </p>

      <p class="pt3">
        <strong>Increased Comfort:</strong> Reducing air leakage will reduce
        drafts and help your home maintain an even temperature. You’ll be more
        comfortable in both the winter and summer.
        <span class="link_text" (click)="onReadContent('readLess')">
          Read less
        </span>
      </p>
    </div>
  </div>
  <div *ngIf="!showAboutTech">
    <div *ngIf="isActive(0)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. What types of weather stripping are available?
        </h5>
        <p class="answer_text">
          Almost any hardware store will have various types that can be easily
          installed around the perimeter of doors and windows. For example,
          you’ll likely be able to find self-stick vinyl, reinforced foam,
          tubular rubber, and other types. Around stationary window panes,
          caulking is often the best choice for sealing out cold (or hot) air.
          Don’t forget to install foam gaskets behind the cover plates of
          electrical outlets and switches as an added measure.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">2. How does weather stripping work?</h5>
        <p class="answer_text">
          Weather stripping cuts heating and cooling costs by reducing the
          amount of conditioned air that leaks out of a home through the gaps
          around doors and windows. This may not seem like a lot of leakage at
          first, but just a 1/16” gap all the way around a door is equivalent to
          a 14 in2 hole in the door! If you can see daylight coming in around
          the edges of a door or window, you can probably save with weather
          stripping.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(1)" hideToggle="true">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. What will it cost to weather strip my home?
        </h5>
        <p class="answer_text">
          The cost will depend on the size of your home and the type of weather
          stripping you buy, but in most cases it should be less than $200. Some
          types, such as tubular rubber, may cost more, but will likely last
          longer.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">2. How much can I save?</h5>
        <p class="answer_text">
          Your particular savings will depend on a lot of factors, but the US
          Department of Energy suggests that a typical home can cut about 5% -
          10% from their heating and cooling costs with weather stripping. This
          can be significant since space conditioning generally consumes 40% -
          50% of a household’s energy (excluding electric vehicles).
        </p>
      </div>
    </div>
    <div *ngIf="isActive(2)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. How does weather stripping help the environment?
        </h5>
        <p class="answer_text">
          Since heating and cooling represents a sizeable portion of a home’s
          energy usage, cutting even 5% of this usage can help cut your carbon
          footprint. Also, since wildfires are increasingly affecting air
          quality in various parts of the country, weather stripping can be a
          good way to improve your indoor air environment by keeping smoke out
          of your house.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(3)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. Can I install weather stripping myself?</h5>
        <p class="answer_text">
          Yes, the materials you’ll need are available at almost any hardware
          store and you won’t need specialized tools. For example, weather
          stripping a door is pretty straightforward. It’s as simple as cutting
          strips for the top and sides of the door, and then positioning the
          strips in place. Some weather stripping comes with adhesive backing
          and some will require the use of tacks or small nails. For the bottom
          of a door, you can use a door sweep, which is a metal band attached to
          a strip of flexible rubber that can bend back and forth as you open
          and close the door.
        </p>
      </div>
    </div>
  </div>
</div>
