<div class="yt_border" [ngClass]="{ 'active-box': default === value }">
  <div class="image-box">
    <div>
      <img [src]="cardImage" [alt]="viewValue" width="40px" height="40px" />
    </div>
    <div class="techName">
      {{ viewValue }}
    </div>
  </div>
</div>
