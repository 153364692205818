import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "yt-error-popup",
  templateUrl: "./error-popup.component.html",
  styleUrls: ["./error-popup.component.scss"],
})
export class ErrorPopupComponent implements OnInit {
  @Input() cityStatus: boolean;
  @Input() loginAccess: boolean;
  @Input() duplicationUserError: boolean;
  @Input() showErrorDialogueBox: boolean;
  @Input() forgotPasswordLink: string;
  @Input() loginLink: string;
  @Output() isDialogBoxOpened = new EventEmitter<any>();
  @ViewChild("errorDialogueOverlay", { read: ElementRef }) modal: ElementRef;
  constructor() {}

  @HostListener("document:keydown", ["$event"])
  onKeyDown(event) {
    if (event.key == "Escape") {
      this.closeErrorDialogBox();
    }
  }
  @HostListener("document:click", ["$event"])
  onDocumentClick(event) {
    if (this.modal) {
      if (event.target === this.modal.nativeElement) {
        this.closeErrorDialogBox();
      }
    }
  }

  ngOnInit(): void {}

  closeErrorDialogBox(): void {
    this.isDialogBoxOpened.emit();
    this.showErrorDialogueBox = false;
  }
}
