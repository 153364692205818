import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MarketPlaceCardComponent } from "./market-place-card.component";

@NgModule({
  imports: [CommonModule, MatButtonModule],
  exports: [MarketPlaceCardComponent],
  declarations: [MarketPlaceCardComponent],
})
export class MarketPlaceCardModule {}
