<div>
  <div class="tech__summary">
    <div class="heading">{{ header }}</div>
    <div
      *ngIf="!isPortable && !isLoadShift"
      class="paragraph"
      [innerHTML]="
        'homeOwenerDashboard.learnMore.battery.paragraph' | translate
      "
    ></div>
    <div
      *ngIf="!isPortable && isLoadShift"
      class="paragraph"
      [innerHTML]="
        'homeOwenerDashboard.learnMore.batteryLoadShifting.paragraph'
          | translate
      "
    ></div>
    <div
      *ngIf="isPortable"
      class="paragraph"
      [innerHTML]="
        'homeOwenerDashboard.learnMore.portableBattery.paragraph' | translate
      "
    ></div>
  </div>
</div>

<form [formGroup]="batteryForm" class="form__container">
  <div class="form__wrapper">
    <div>
      <mat-radio-group
        class="radioButton-flex"
        formControlName="own"
        (change)="condition()"
      >
        <div class="cards" *ngFor="let radioOptions of radioButtonOptions">
          <label>
            <yt-radio-buttons
              [viewValue]="radioOptions.viewValue"
              [icon]="radioOptions.icon"
              [default]="selectedRadioButton"
              [value]="radioOptions.value"
            ></yt-radio-buttons>
            <mat-radio-button
              class="hidden_button absolute top-0"
              [value]="radioOptions.value"
              (change)="onRadioChange($event)"
            >
            </mat-radio-button>
          </label>
        </div>
      </mat-radio-group>
      <div class="form__field-wrapper" *ngIf="hideFormField">
        <section class="form__field-section">
          <div class="field__label">
            {{ backupHourLabel }}
            <mat-icon
              [matTooltip]="backupHourToolTip"
              matTooltipPosition="right"
            >
              help_outline
            </mat-icon>
          </div>
          <mat-form-field
            hideRequiredMarker="true"
            floatLabel="auto"
            appearance="outline"
          >
            <input
              matInput
              type="text"
              class="quantity"
              placeholder="Enter 1-35"
              formControlName="hrs"
              maxlength="3"
            />
            <mat-error
              *ngIf="
                batteryForm.controls['hrs'].hasError('pattern') ||
                batteryForm.controls['hrs'].hasError('min') ||
                batteryForm.controls['hrs'].hasError('max')
              "
            >
              Please enter value between 1-35
            </mat-error>
            <mat-error *ngIf="batteryForm.controls['hrs'].hasError('required')">
              Required!
            </mat-error>
          </mat-form-field>
        </section>

        <section class="form__field-section">
          <div
            class="field__label"
            [ngStyle]="{
              color:
                batteryForm.controls['own'].value !== 'yes'
                  ? '#333333'
                  : '#c7c7c7'
            }"
          >
            Financing
          </div>
          <mat-form-field floatLabel="auto" appearance="outline">
            <mat-select placeholder="Financing" formControlName="finance">
              <mat-option
                *ngFor="let finance of financeList"
                [value]="finance.value"
                >{{ finance.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="batteryForm.controls['finance'].hasError('required')"
            >
              Required!
            </mat-error>
          </mat-form-field>
        </section>
      </div>
    </div>
  </div>

  <div class="action__wrapper">
    <button
      mat-raised-button
      color="accent"
      (click)="onSaveBATTERY()"
      [disabled]="batteryForm.invalid"
      class="primary__button"
    >
      SAVE
    </button>
  </div>

  <div class="note__message">
    NOTE: The above fields have been pre-filled with default values.
  </div>
</form>
