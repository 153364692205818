<div class="yt__info-header yt_header-margin" *ngIf="header">
  {{ header }}
</div>

<mat-radio-group [formControl]="buldingType" class="relative">
  <div
    class="yt__w-46 dib yt__first-child-ml0 yt__last-child-mr0 yt__mr1-625rem"
    *ngFor="let type of buildingTypeDisplayModel"
  >
    <label>
      <div *ngIf="!pngImage">
        <yt-answer-box
          [image]="imagePath ? imagePath + type.value + '.svg' : ''"
          [displayValue]="type.viewValue!"
          [selectedValue]="defaultValue === type.value"
        >
        </yt-answer-box>
      </div>
      <div *ngIf="pngImage">
        <yt-answer-box
          [image]="imagePath ? imagePath + type.value + '.png' : ''"
          [displayValue]="type.viewValue!"
          [selectedValue]="defaultValue === type.value"
        >
        </yt-answer-box>
      </div>

      <mat-radio-button
        class="yt__visibility-hidden absolute top-0 pointer"
        [value]="type.value"
        color="accent"
        (change)="radioChange($event)"
      >
      </mat-radio-button>
    </label>
  </div>
</mat-radio-group>
