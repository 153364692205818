import { Injectable } from "@angular/core";
import { RestAPIService } from "../../rest-api/rest-api.service";
import { HttpClient } from "@angular/common/http";
import { ElectricVehiclesListModel } from "../../homeowner-product/energy-choices/energy-choices.model";
import { Observable } from "rxjs/Observable";
import { DashboardAPIService } from "../../rest-api/dashboard/dashboard-api.service";

@Injectable()
export class DialogOverviewService {
  public readonly evModelUrl;

  constructor(
    private restAPI: RestAPIService,
    private httpClient: HttpClient,
    private dashboardAPI: DashboardAPIService
  ) {
    this.evModelUrl = this.restAPI.API_END_POINT + "evCatalog/detail/";
  }

  getEvModel(
    queryParameter: string,
    application: string
  ): Observable<ElectricVehiclesListModel> {
    const email =
      this.restAPI.homeOwnerEmail || this.dashboardAPI.userDetails.userName;
    return this.httpClient.get<ElectricVehiclesListModel>(
      `${this.evModelUrl}${application}/${email}${queryParameter}`
    );
  }
}
