<div class="faq_container">
  <div class="about_technology" *ngIf="showAboutTech">
    <div class="header">Overview</div>
    <p>
      <strong>How it works:</strong> Electromagnetic coils under the cooktop
      surface transfer magnetic energy into the metal at the base of the
      cookware, which then converts the energy to heat. As a result, the heat is
      generated at the base of the cookware itself. Induction cooking requires
      the use of cookware with a high iron content in the base. Non-metallic
      objects will not be heated even if placed on the cooktop. The energy
      transfer is very fast and so heat modulation is very precise and better
      than a gas cooktop.
      <span
        class="link_text"
        *ngIf="showReadMore"
        (click)="onReadContent('readMore')"
      >
        Read more...
      </span>
    </p>
    <div *ngIf="showReadLess">
      <p class="pt3">
        <strong>Types of Systems:</strong> They come in various shapes and sizes
        from 12” single burner models to 40” 6-burner models. They can be
        portable or built-in units.
      </p>

      <p class="pt3">
        <strong>Special Requirements:</strong> Only metal cookware that has a
        high iron content in its base will work with induction. All cast iron
        pots and pans work well, as does a large fraction of stainless-steel
        cookware. With stainless steel, the outer (bottom-most) surface of the
        pot or pan should be made with nickel-free stainless steel. Sometimes a
        magnetic base is slapped onto the utensil so that it can work on
        induction cooktops.
      </p>

      <p class="pt3">
        <strong>Economics:</strong> In general induction cooktops tend to cost a
        bit more than other types, but the differences in performance and safety
        can be significant. Inexpensive sets of standard pots and pans that work
        on induction are available in the market.
      </p>

      <p class="pt3">
        <strong>Maintenance:</strong> This involves simply wiping the surface
        with a cloth that won’t scratch the surface. A cleaner suitable for
        ceramic or glass can be used.
      </p>

      <p class="pt3">
        <strong>Environment:</strong> If you replace a gas cooktop with an
        electric cooktop (such as induction), there can be a significant
        reduction in toxic indoor air pollution. If you currently cook with
        electricity, there will be a small reduction in your carbon footprint as
        induction cooktops are more efficient.
        <span class="link_text" (click)="onReadContent('readLess')">
          Read less
        </span>
      </p>
    </div>
  </div>

  <div *ngIf="!showAboutTech">
    <div *ngIf="isActive(0)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How does an induction cooktop work?</h5>
        <p class="answer_text">
          The technology behind induction cooking is electromagnetic.
          Electromagnetic coils under the glass cooktop surface transfer
          magnetic energy into the metal at the base of the cookware, which then
          converts the energy to heat. As a result, the heat is generated in the
          cookware itself. Induction cooking requires the use of cookware with a
          high iron content. Non-metallic objects will not be heated even if
          placed on the cooktop.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. How different is an induction cooktop from a traditional electric
          cooktop?
        </h5>
        <p class="answer_text">
          Induction cooktops are a lot different. Induction offers much faster
          cooking times, better control over heat levels, and greater safety
          than the traditional “coil-type” or “smooth-top” electric cooktops. In
          the traditional cooktops, heat is generated in the heating element and
          then transferred to the base of the cookware.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">3. Is it as good as a gas cooktop?</h5>
        <p class="answer_text">
          An induction cooktop is the fastest cooking technology currently
          available. It also provides greater temperature control and easier
          cleanup than cooking with natural gas. Perhaps the most important
          benefits of induction cooking relate to safety. Since gas cooking
          involves an open flame, it represents a fire hazard, introduces
          pollution into your home that can be unhealthy, and also contributes
          more to your carbon footprint.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          4. Are special types of pots and pans required?
        </h5>
        <p class="answer_text">
          Yes. Only metal cookware that has a high iron content in its base will
          work with induction. All cast iron pots and pans work well, as does a
          large fraction of stainless-steel cookware. With stainless steel, the
          outer (bottom-most) surface of the pot or pan should be made with
          nickel-free stainless steel. If the pot or pan is not expressly
          marketed as induction-ready, you can use the “magnet test”. If a
          magnet clings well to the outside bottom of the pot or pan, it will
          work with induction. If it does not, or clings only very weakly, the
          cookware will not work.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(1)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. Are induction cooktops expensive?</h5>
        <p class="answer_text">
          Prices for induction cooktops and ranges have been dropping, with some
          induction units selling for under $1,000. Induction cooktops still
          tend to cost more than other types, but the differences in performance
          and safety can be significant.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. Do they save any money over gas cooktops in the long run?
        </h5>
        <p class="answer_text">
          Induction cooktops are more efficient, with about 85% of the
          electrical energy converted to heat in the cookware. This can be
          compared to an efficiency of about 35% – 40% efficiency for a gas
          cooktop. However, whether they will save money depends on your local
          electricity and gas costs. In general, cooking with induction will be
          more expensive than cooking with gas, but many households are finding
          that the non-economic advantages of induction, such as cleaner indoor
          air, outweigh the additional cost.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          3. Are induction ready pots and pans expensive?
        </h5>
        <p class="answer_text">
          No. A whole set of standard pots and pans is usually under $200.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(2)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How do I clean an induction cooktop?</h5>
        <p class="answer_text">
          This involves simply wiping the surface with a cloth that won’t
          scratch the surface. A cleaner suitable for ceramic or glass can be
          used.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. Will the cooktop surface scratch or crack?
        </h5>
        <p class="answer_text">
          Yes. It is possible for the surface of an induction cooktop to scratch
          or crack, but it consists of a special type of glass that is highly
          resistant to such problems. The material is very strong and tolerates
          very high temperatures and sudden temperature changes. Care should be
          taken to use a non-scratch cloth while cleaning.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(3)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. Will my carbon footprint go down if I switch to induction cooking?
        </h5>
        <p class="answer_text">
          That depends largely on what you’re switching from. If you currently
          cook with electricity, there will be a small reduction in your carbon
          footprint. If you currently cook with natural gas, there’s likely to
          be a much larger reduction. (This depends on how much of the
          electricity in your area is generated with fossil fuels.)
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. Is there a significant health advantage with induction cooking?
        </h5>
        <p class="answer_text">
          If you replace a gas cooktop with an electric cooktop (such as
          induction), there can be a significant reduction in toxic indoor air
          pollution. Though most people do not realize this, cooking with
          natural gas can be a major source of nitrogen dioxide, carbon
          monoxide, formaldehyde, and other materials that are harmful to humans
          (and especially children). If you still use gas for cooking, it’s
          advisable to run the fan in your range hood while cooking and open
          some windows if you can.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(4)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. Will copper and aluminum pots work with an induction cooktop?
        </h5>
        <p class="answer_text">
          No. Only cookware with a high iron content will work. That means
          cookware made of copper, aluminum, ceramic, and glass will not work.
          Some people use “induction interface disks” if they wish to continue
          using their incompatible cookware on an induction cooktop. These are
          basically flat iron disks with a handle that you put directly on the
          cooktop. You then place your cookware on top of the disk, and as the
          disk heats, it transfers heat to the cookware. This approach works,
          but some of induction’s advantages are reduced. For example, both the
          efficiency of the cooktop and the ability to quickly adjust the
          cooking temperature are degraded.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. Are ranges available with an induction cooktop and a conventional
          electric oven?
        </h5>
        <p class="answer_text">
          Yes, integrated electric ranges of this type are available.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">3. Are induction cooktops safe?</h5>
        <p class="answer_text">
          Induction cooktops are very safe. The electromagnetic field that
          induction units use has a very short range. Moreover, units do not
          generate any field unless they detect ferrous (high iron content)
          metal directly above them and virtually all of the energy is absorbed
          in the metal. Therefore, a cooktop shuts off automatically when the
          cookware is removed and never gets very hot since all the heat is
          generated at the base of the cookware.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">4. Are pressure cookers available?</h5>
        <p class="answer_text">
          Yes, pressure cookers with bottoms that are compatible with induction
          cooktops are widely available in all sizes.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">5. What electrical connections are needed?</h5>
        <p class="answer_text">
          Typically a 30A, 240V connection is needed – same as a traditional
          electric cooktop or range.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(5)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. What types of incentives are available?</h5>
        <p class="answer_text">
          Utilities, counties, Community Choice Aggregators, etc. sometimes
          offer rebates of various types on induction cooktops.
        </p>
      </div>
    </div>
  </div>
</div>
