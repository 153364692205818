<div class="overlay" *ngIf="showErrorDialogueBox" #errorDialogueOverlay>
  <div id="text">
    <div
      class="w-100 flex items-center justify-center yt_popup-padding yt_exclamation"
    >
      <div class="dib yt_matIcon">
        <img src="assets/icons-error.png" loading="lazy" alt="warning" />
      </div>
      &nbsp;
      <div class="dib yt_heading fw6">Error</div>
    </div>
    <div class="yt_popup-padding yt_text yt_lh-18">
      <div *ngIf="duplicationuserror">
        <span>We already have a user with that email address.</span><br />
        <span *ngIf="loginaccess"
          >Already a user?
          <a
            [href]="loginLink"
            target="_blank"
            class="yt_link pointer no-underline"
            >Login here</a
          > </span
        ><br />
        <span *ngIf="loginaccess"
          >Forgot your password?
          <a
            [href]="forgotPasswordLink"
            target="_blank"
            class="yt_link pointer no-underline"
            >Reset here</a
          >
        </span>
      </div>
      <div *ngIf="cityStatus">
        Unfortunately, your address is outside our supported service area.
      </div>
    </div>
  </div>
</div>
