<div class="detail-card">
  <div class="heading">Browse options</div>
  <div class="yt-box">
    <ng-template ngFor let-card [ngForOf]="techCard">
      <div class="detail-box margin_right" *ngIf="card.linkId === 'DEALER'">
        <div class="sub-heading">
          {{ header }}
        </div>
        <div class="paragraph">
          {{ description }}
        </div>
        <div class="form_label">
          {{ selectLabel }}
        </div>
        <mat-form-field
          class="w-100 form_padding"
          floatLabel="auto"
          appearance="outline"
        >
          <div>
            <mat-select
              placeholder="Select"
              [(value)]="modelSelected"
              (selectionChange)="onChangeOfMake($event.value)"
            >
              <mat-option value="default">Select</mat-option>
              <mat-option
                *ngFor="let models of manufactureList || []"
                [value]="models"
              >
                {{ models }}
              </mat-option>
            </mat-select>
          </div>
        </mat-form-field>
        <div class="width-button" *ngIf="!anyMnfStatus">
          <button
            mat-raised-button
            color="accent"
            class="w-100"
            [disabled]="isPrimaryLinkButtonDisabled()"
            (click)="onPrimaryButton()"
          >
            {{ getButtonText("DEALERS") }}
          </button>
        </div>
        <div class="width-button schedule-button">
          <button
            mat-raised-button
            color="accent"
            class="w-100"
            [disabled]="isSecondaryLinkButtonDisabled()"
            (click)="onSecondaryButton()"
          >
            {{ getButtonText("LOCATION") }}
          </button>
        </div>
        <div *ngIf="anyMnfStatus" class="secondary_link">
          <span>Powered by</span>
          <a (click)="onEnergyStar()">EnergyStar</a>
        </div>
      </div>

      <div class="detail-box margin_right" *ngIf="card.linkId !== 'DEALER'">
        <div class="tc logo-padding">
          <img
            *ngIf="!!card.logo; else noImage"
            [src]="card.logo"
            alt="logo"
            height="40px"
          />
          <ng-template #noImage
            ><p class="yt_noImage-text">
              {{ card.name }}
            </p></ng-template
          >
        </div>
        <p class="paragraph">
          {{ card.boxText }}
        </p>
        <ul class="bullet-points" *ngIf="card.boxList">
          <li *ngFor="let list of card.boxList">{{ list }}</li>
        </ul>
        <div class="getStarted-button">
          <button
            mat-raised-button
            color="accent"
            class="w-100"
            (click)="onShopping(card.link, card.linkId)"
          >
            {{ card.buttonText }}
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
