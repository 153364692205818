import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { DashboardAPIService } from "../../../../../../rest-api/dashboard/dashboard-api.service";
import { MarketPlaceAnalyticsModel } from "../../../../../../rest-api/dashboard/dashboard-api.model";
import { MarketPlaceService } from "src/app/homeowner-dashboard/market-place/market-place.service";
@Component({
  selector: "info-card-simple-low-cost",
  templateUrl: "./simple-low-cost.component.html",
  styleUrls: ["../info-card.component.scss"],
})
export class InfoCardSimpleLowCostComponent {
  isLowe: boolean;
  imagePath = "assets/lifestyle/clean-choices/details/";
  @Input() activeTech: string;

  constructor(
    private dashboardAPI: DashboardAPIService,
    private router: Router,
    private marketplaceService: MarketPlaceService
  ) {}

  ngOnInit(): void {
    this.isLowe = this.dashboardAPI.getUserDetails().solarVendorCode === "LOWE";
  }

  onLearnMoreLink() {
    this.router.navigateByUrl("/dashboard/details/led-bulbs");
  }

  onStartShopping(tech_name: string, linkId: string, link: string): void {
    if (this.isLowe) {
      this.marketplaceService.openShopNowTab(link);
    } else {
      if (tech_name === "hvacFilters") {
        this.router.navigateByUrl(
          `dashboard/market-place/energyEfficientDevices/homeComfortDevices`
        );
      } else if (tech_name === "motionSensors") {
        this.router.navigateByUrl(
          `dashboard/market-place/energyEfficientDevices/lightingElectricDevices`
        );
      } else {
        const details: MarketPlaceAnalyticsModel = {
          tech: tech_name,
          linkId: linkId,
        };
        this.dashboardAPI.marketplaceDetailsAnalytics(details);
        this.marketplaceService.openShopNowTab(link);
      }
    }
  }
}
