import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { DashboardAPIService } from "../../../../../../../rest-api/dashboard/dashboard-api.service";
import { CommunitySolarDataModel } from "../../../../../model/userdata.model";
import { SettingsOverlayService } from "../../../settings-overlay.service";
import { Subject } from "rxjs";
import { SettingsDialogData } from "../../../setting-overlay.component.model";

@Component({
  selector: "yt-community-solar",
  templateUrl: "./community-solar.component.html",
  styleUrls: ["../../../simpleLow-cost/simpleLow-cost.component.scss"],
})
export class CommunitySolarComponent implements OnInit {
  communitySolarForm: FormGroup;
  communitySolarData: CommunitySolarDataModel[];
  communitySolarSettings: CommunitySolarDataModel[];
  @Input() activeIcon: string;
  subject: Subject<any> = new Subject();
  @Output() techSave: EventEmitter<SettingsDialogData> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private dashboardAPIService: DashboardAPIService,
    private settingService: SettingsOverlayService
  ) {
    this.communitySolarForm = this.fb.group({
      communitySolar: ["yesPlan", Validators.required],
    });
  }

  ngOnInit(): void {
    this.communitySolarData = this.settingService.checkSettingsInfoError(
      "communitySolar"
    )
      ? this.settingService.settingsInfoErrors.solarBattery?.communitySolar!
      : this.settingService.checkSettingsInfo("communitySolar")
      ? this.settingService.settingsInfo.solarBattery?.communitySolar!
      : this.dashboardAPIService.energyChoicesData.solarBattery.communitySolar!;
    this.communitySolarData.forEach((tech: CommunitySolarDataModel) => {
      this.communitySolarForm.get("communitySolar")?.setValue(tech.own);
    });
    if (this.activeIcon) {
      if (this.activeIcon === "favorite") {
        this.communitySolarForm.get("communitySolar")?.setValue("yesPlan");
      } else if (this.activeIcon === "alreadyHave") {
        this.communitySolarForm.get("communitySolar")?.setValue("yes");
      }
      this.onValueChanges();
    } else {
      this.onValueChanges();
    }
  }

  onValueChanges(): void {
    this.communitySolarForm
      .get("communitySolar")
      ?.valueChanges.subscribe((ownValue) => {
        this.subject.pipe().subscribe(() => {
          this.communitySolarSettings = [
            {
              own: ownValue,
            },
          ];
          if (this.communitySolarForm.valid) {
            this.settingService.updateSettingsInfo(
              this.dashboardAPIService.energyChoicesData.solarBattery,
              this.communitySolarSettings,
              "solarBattery",
              "communitySolar"
            );
          } else {
            this.settingService.updateSettingsInfoErrors(
              this.dashboardAPIService.energyChoicesData.solarBattery,
              this.communitySolarSettings,
              "solarBattery",
              "communitySolar"
            );
          }
        });
        this.subject.next();
      });
  }

  onSave(): void {
    this.settingService.putSettingsInfo(this.getSettingsInfo);
  }

  getSettingsInfo = () => {
    if (this.activeIcon) {
      this.dialog.closeAll();
    }
  };
}
