import { Component, Input } from '@angular/core';

@Component({
  selector: 'yt-lifestyle-box',
  templateUrl: 'lifestyle-box.component.html',
  styleUrls: ['./lifestyle-box.component.scss']
})

export class LifestyleBoxComponent {
  @Input() image: string;
  @Input() tagline: string;
  @Input() technologyName: string;
  @Input() savings: string;
  @Input() visited: string;

  ngOnInit() {
  }
 }
