import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { DialogBoxComponent } from "./Dialog-box.component";

@NgModule({
  imports: [CommonModule, MatButtonModule, MatDialogModule],
  declarations: [DialogBoxComponent],
  providers: [],
  exports: [DialogBoxComponent],
})
export class DialogBoxModule {}
