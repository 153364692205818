<div>
  <div class="tech__summary">
    <div class="heading">Electric Vehicle</div>
    <div
      class="paragraph"
      [innerHTML]="'homeOwenerDashboard.learnMore.ev.paragraph' | translate"
    ></div>
  </div>
</div>

<form [formGroup]="eVForm" class="form__container">
  <div
    *ngFor="let item of evArray.controls; let i = index"
    formArrayName="ev"
    class="form__wrapper"
    [ngClass]="{ 'padding-margin0': i === 1 }"
  >
    <div [formGroupName]="i">
      <mat-radio-group
        class="radioButton-flex"
        formControlName="own"
        (change)="onSelectOfQuestion(i)"
      >
        <div class="cards" *ngFor="let radioOptions of radioButtonOptions">
          <label>
            <yt-radio-buttons
              [viewValue]="radioOptions.viewValue"
              [icon]="radioOptions.icon"
              [default]="selectedRadioButton[i]"
              [value]="radioOptions.value"
            ></yt-radio-buttons>
            <mat-radio-button
              class="hidden_button"
              [value]="radioOptions.value"
              (change)="onRadioChange($event, i)"
            >
            </mat-radio-button>
          </label>
        </div>
      </mat-radio-group>

      <div class="form__field-wrapper">
        <section class="form__field-section">
          <div class="field__label">
            <span>Make & Model</span
            ><span class="link__text" (click)="openDialogStatus(i)"
              >Help me choose</span
            >
          </div>
          <mat-form-field floatLabel="auto" appearance="outline">
            <mat-select placeholder="Make & Model" formControlName="model">
              <mat-option
                *ngFor="let makeAndModel of mnfDropDownList[i]"
                [value]="makeAndModel.mnfAndModel"
                [matTooltip]="makeAndModel.mnfAndModel"
                >{{ makeAndModel.mnfAndModel }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="getValidationMessage('model', i).status">
              {{ getValidationMessage("model", i).message }}
            </mat-error>
          </mat-form-field>
        </section>

        <section class="form__field-section">
          <div class="field__label">Financing</div>
          <mat-form-field floatLabel="auto" appearance="outline">
            <mat-select placeholder="Financing" formControlName="finance">
              <mat-option
                *ngFor="let finance of financeList"
                [value]="finance.value"
                >{{ finance.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="getValidationMessage('finance', i).status">
              {{ getValidationMessage("finance", i).message }}
            </mat-error>
          </mat-form-field>
        </section>

        <section class="form__field-section">
          <div class="field__label">
            <span>Annual miles</span
            ><mat-icon
              matTooltipPosition="right"
              matTooltip="Total miles driven on electricity in a year. This should not include the number of miles a plug-in hybrid electric vehicle (PHEV) is driven on gasoline."
              class="tooltip__label"
            >
              help_outline
            </mat-icon>
            <span class="link__text" (click)="onPopUp(i)">Calculate</span>
          </div>
          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker="true"
            appearance="outline"
          >
            <input
              matInput
              type="text"
              formControlName="annualMiles"
              placeholder="Enter 1000-40000"
              autocomplete="off"
              required
              maxlength="6"
            />
            <span
              matSuffix
              class="suffix_color"
              *ngIf="onCarTypeChange(getValue('model', i), i)"
            >
              miles driven by electricity
            </span>
            <span
              matSuffix
              class="suffix_color"
              *ngIf="!onCarTypeChange(getValue('model', i), i)"
            >
              miles
            </span>
            <mat-error *ngIf="getValidationMessage('annualMiles', i).status">
              {{ getValidationMessage("annualMiles", i).message }}
            </mat-error>
          </mat-form-field>
        </section>

        <section class="form__field-section">
          <div class="field__label">
            <span>Home charging (%)</span
            ><mat-icon
              matTooltip="% of charging the electric vehicle at your home"
              matTooltipPosition="right"
              class="tooltip__label"
            >
              help_outline
            </mat-icon>
          </div>
          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker="true"
            appearance="outline"
          >
            <mat-select
              formControlName="charging"
              placeholder="Home charging"
              required
            >
              <mat-option *ngFor="let charge of charges" [value]="charge"
                >{{ charge }}%</mat-option
              >
            </mat-select>
            <mat-error *ngIf="getValidationMessage('charging', i).status">
              {{ getValidationMessage("charging", i).message }}
            </mat-error>
          </mat-form-field>
        </section>

        <section
          class="form__field-section"
          *ngIf="disableBoughtAndDatePurchased[i]"
        >
          <div
            class="field__label"
            [ngClass]="{
              disabled__label: getValue('own', i) !== 'yes'
            }"
          >
            Purchased/leased month & year
          </div>
          <mat-form-field
            floatLabel="auto"
            hideRequiredMarker="true"
            appearance="outline"
          >
            <mat-select
              placeholder="Select"
              formControlName="datePurchased"
              required
              (selectionChange)="onDatePurchaseChange(i)"
            >
              <mat-option *ngFor="let month of monthList" [value]="month"
                >{{ month | titlecase }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="isDatePurchasedSelected(i) || isDatePurchaseEmpty[i]"
            >
              Required!
            </mat-error>
          </mat-form-field>
        </section>

        <div class="remove__ev2" *ngIf="i === 1">
          <span class="link__text" (click)="removeEV()">Remove vehicle #2</span>
        </div>
      </div>
    </div>
  </div>

  <div
    class="link__text secondary__button"
    *ngIf="showAddEV && 2 > evArray.controls.length"
    (click)="onAddAnotherEV()"
  >
    + Add Another Vehicle
  </div>

  <div class="action__wrapper">
    <button
      mat-raised-button
      color="accent"
      (click)="onSaveEV()"
      [disabled]="
        eVForm.invalid || isDatePurchasedEmpty() || isMNFAndModelListAbsent()
      "
      class="primary__button"
    >
      SAVE
    </button>
  </div>

  <div class="note__message">
    NOTE: The above fields have been pre-filled with default values.
  </div>
</form>

<!-- Calculate Miles -->
<yt-calculate-miles
  *ngIf="showAnnualMilesPopup"
  (onClose)="onClosePopup()"
  [isDashboard]="true"
></yt-calculate-miles>
