import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { ACSettingModel } from "../energy-choices.model";
import { LifestyleService } from "../../../rest-api/lifestyle.service";
import { DropDownModel } from "../../../rest-api/rest-api.model";
import { Utils } from "../../../utils/util";

@Component({
  selector: "yt-ac",
  templateUrl: "ac.component.html",
  styleUrls: ["../energy-choices-common.scss"],
})
export class ACComponent implements OnInit {
  panelOpenState: boolean = false;
  acForm: FormGroup;
  showAddAC: boolean = true;
  monthList: string[];
  hideAddAnotherACButton: boolean;
  disableBoughtAndDatePurchased: boolean[] = [];
  updateMode: boolean;
  ACData: ACSettingModel[];
  ac: FormArray;
  lastTwelthMonth: string;
  @Output() saveAC = new EventEmitter<any>();
  acTypes: DropDownModel[] = [
    { value: "central", viewValue: "Central" },
    { value: "room", viewValue: "Room" },
  ];

  constructor(
    private fb: FormBuilder,
    private lifeStyleService: LifestyleService,
    private utils: Utils
  ) {}

  ngOnInit(): void {
    this.monthList = this.utils.monthList;
    this.lastTwelthMonth = this.utils.lastTwelthMonth;
    this.acForm = this.fb.group({
      ac: this.fb.array([this.createItem()]),
    });
    if (this.lifeStyleService.getACInfo() !== undefined) {
      this.ACData = this.lifeStyleService.getACInfo();
      this.updateMode = true;
      this.checkUpdatedAC(this.ACData);
    }
    if (this.acForm.get(`ac.${0}`)?.get("own")?.value === "yesPlan") {
      this.acForm.get(`ac.${0}`)?.get("datePurchased")?.disable();
    }
  }

  createItem(): FormGroup {
    return this.fb.group({
      own: ["yesPlan", Validators.required],
      type: ["central", Validators.required],
      days: [
        "30",
        [
          Validators.required,
          Validators.pattern(
            "^([1-9]|[0-2][0-9][0-9]|3[0-5][0-9]|3[6][0-6]|[0-9][0-9])$"
          ),
          Validators.min(1),
          Validators.max(366),
        ],
      ],
      hrs: [
        "5",
        [
          Validators.required,
          Validators.pattern("^(2[0-4]|1[0-9]|[1-9])$"),
          Validators.min(1),
          Validators.max(24),
        ],
      ],
      bought: [""],
      datePurchased: ["", Validators.required],
    });
  }

  checkUpdatedAC(data) {
    const acFormItems = <FormArray>this.acForm.get("ac");
    for (let i = 0; i < acFormItems.length; i++) {
      acFormItems.removeAt(i);
    }

    data.forEach((x) => {
      acFormItems.push(
        this.fb.group({
          own: x.own,
          type: x.type,
          days: x.days,
          hrs: x.hrs,
          bought: x.bought,
          datePurchased: x.bought ? x.datePurchased : this.lastTwelthMonth,
        })
      );
    });
    for (let index in data) {
      this.onSelectOfQuestion(index);
    }
  }

  onAddAnotherAC(): void {
    this.hideAddAnotherACButton = true;
    this.showAddAC = false;
    this.ac = this.acForm.get("ac") as FormArray;
    this.ac.push(this.createItem());
    this.disableBoughtAndDatePurchased[1] = false;
    this.acForm.get(`ac.${1}`)?.get("datePurchased")?.disable();
  }

  onSelectOfQuestion(i): void {
    if (this.acForm.get(`ac.${i}`)?.get("own")?.value === "yes") {
      this.acForm.get(`ac.${i}`)?.get("datePurchased")?.enable();
      this.acForm.get(`ac.${i}`)?.get("type")?.enable();
      this.acForm.get(`ac.${i}`)?.get("days")?.enable();
      this.acForm.get(`ac.${i}`)?.get("hrs")?.enable();
      this.acForm.get(`ac.${i}`)?.get("bought")?.enable();
      this.showAddAC = true;
      this.disableBoughtAndDatePurchased[i] = true;
    } else if (this.acForm.get(`ac.${i}`)?.get("own")?.value === "yesPlan") {
      this.acForm.get(`ac.${i}`)?.get("datePurchased")?.disable();
      this.acForm.get(`ac.${i}`)?.get("type")?.enable();
      this.acForm.get(`ac.${i}`)?.get("days")?.enable();
      this.acForm.get(`ac.${i}`)?.get("hrs")?.enable();
      this.acForm.get(`ac.${i}`)?.get("bought")?.disable();
      this.acForm.get(`ac.${i}`)?.get("datePurchased")?.disable();
      this.showAddAC = true;
      this.disableBoughtAndDatePurchased[i] = false;
    } else if (this.acForm.get(`ac.${i}`)?.get("own")?.value === "no") {
      this.acForm.get(`ac.${i}`)?.get("datePurchased")?.disable();
      this.acForm.get(`ac.${i}`)?.get("type")?.disable();
      this.acForm.get(`ac.${i}`)?.get("days")?.disable();
      this.acForm.get(`ac.${i}`)?.get("hrs")?.disable();
      this.acForm.get(`ac.${i}`)?.get("bought")?.disable();
      this.acForm.get(`ac.${i}`)?.get("datePurchased")?.disable();
      this.showAddAC = false;
      this.disableBoughtAndDatePurchased[i] = false;
    }
  }

  public get acArray(): FormArray {
    return this.acForm.get("ac") as FormArray;
  }

  removeAC() {
    this.showAddAC = true;
    if (this.acForm.value.ac.length > 1) {
      this.acArray.removeAt(1);
    }
  }

  onSaveAC() {
    const acInfo: {
      energyChoices: {
        ac: {
          own: string;
          type?: string;
          hrs?: string;
          days?: string;
          bought?: string;
          datePurchased?: string;
        }[];
      };
    } = {
      energyChoices: {
        ac: [],
      },
    };
    this.acForm.get("ac")?.value.forEach((element, i) => {
      if (this.acForm.get(`ac.${i}`)?.get("own")?.value === "yes") {
        const datePurchased = this.acForm
          .get(`ac.${i}`)
          ?.get("datePurchased")?.value;
        const bought = this.utils.isDatePurchaseSelected(datePurchased);
        this.acForm.get(`ac.${i}`)?.get("bought")?.setValue(bought);
        acInfo.energyChoices.ac[i] = {
          own: this.acForm.get(`ac.${i}`)?.get("own")?.value,
          type: this.acForm.get(`ac.${i}`)?.get("type")?.value,
          days: this.acForm.get(`ac.${i}`)?.get("days")?.value,
          hrs: this.acForm.get(`ac.${i}`)?.get("hrs")?.value,
          bought: this.acForm.get(`ac.${i}`)?.get("bought")?.value,
          datePurchased: !this.acForm.get(`ac.${i}`)?.get("bought")?.value
            ? null
            : this.acForm.get(`ac.${i}`)?.get("datePurchased")?.value,
        };
      } else if (this.acForm.get(`ac.${i}`)?.get("own")?.value === "yesPlan") {
        acInfo.energyChoices.ac[i] = {
          own: this.acForm.get(`ac.${i}`)?.get("own")?.value,
          type: this.acForm.get(`ac.${i}`)?.get("type")?.value,
          days: this.acForm.get(`ac.${i}`)?.get("days")?.value,
          hrs: this.acForm.get(`ac.${i}`)?.get("hrs")?.value,
        };
      } else if (this.acForm.get(`ac.${i}`)?.get("own")?.value === "no") {
        acInfo.energyChoices.ac[i] = {
          own: this.acForm.get(`ac.${i}`)?.get("own")?.value,
        };
      }
    });
    this.lifeStyleService.onSaveLifestyleInfo(acInfo).subscribe((response) => {
      if (response.status === 200) {
        this.lifeStyleService.postACInfo(acInfo);
        this.saveAC.emit();
      }
    });
  }

  isDatePurchasedEmpty(): boolean {
    return this.acForm.get("ac")?.value.forEach((element, index: number) => {
      if (this.acForm.get(`ac.${index}`)?.get("own")?.value === "yes") {
        if (this.acForm.get(`ac.${index}`)?.get("datePurchased")?.invalid) {
          return true;
        }
      }
      return false;
    });
  }

  isDatePurchasedSelected(index: number): boolean {
    const datePurchased = this.acForm.get(`ac.${index}`)?.get("datePurchased")!;
    return datePurchased.touched && datePurchased.invalid;
  }

  getValue(key: string, index: number): string {
    return this.acForm.get(`ac.${index}`)?.get(key)?.value;
  }

  isRequired(key: string, index: number): boolean {
    return this.acForm.get(`ac.${index}`)?.get(key)?.hasError("required")!;
  }

  isFieldValid(key: string, index: number): boolean {
    return (
      this.acForm.get(`ac.${index}`)?.get(key)?.hasError("pattern")! ||
      this.acForm.get(`ac.${index}`)?.get(key)?.hasError("min")! ||
      this.acForm.get(`ac.${index}`)?.get(key)?.hasError("max")!
    );
  }
}
