import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import {
  CCEDetailsModel,
  ElectricityProvidersModel,
  HPJourneyDetailsPUTModel,
} from "./homeowner-info.model";
import { RestAPIService } from "../rest-api/rest-api.service";
import { AuthenticationService } from "../auth/auth.service";
import { DashboardAPIService } from "../rest-api/dashboard/dashboard-api.service";
import { Router } from "@angular/router";
import { SolarVendorModel } from "../rest-api/rest-api.model";
@Injectable()
export class HomeOwnerInfoService {
  addressUrl: string;
  userInfo: HPJourneyDetailsPUTModel;
  vendorInfo: SolarVendorModel;
  energyProviderList: ElectricityProvidersModel[];
  selectedProviderId: number;
  selectedProviderName: string;
  private readonly API_END_POINT = environment.API_URL;
  private readonly cceDetailsUrl = "cceDetails/";

  constructor(
    private httpClient: HttpClient,
    private restAPI: RestAPIService,
    private authService: AuthenticationService,
    private dashboardAPI: DashboardAPIService,
    private router: Router
  ) {
    this.addressUrl = `registration/details`;
  }

  postUserDetails(
    userDetails: HPJourneyDetailsPUTModel
  ): Observable<HPJourneyDetailsPUTModel> {
    const payload = userDetails;
    if (this.restAPI.responseId) {
      payload["id"] = this.restAPI.responseId;
    }
    return this.httpClient.put<HPJourneyDetailsPUTModel>(
      `${this.API_END_POINT}` +
        this.addressUrl +
        `/${this.restAPI.homeOwnerEmail}`,
      payload
    );
  }

  getProviderInfo(utilityId) {
    return this.httpClient.get<CCEDetailsModel>(
      `${this.API_END_POINT}` +
        this.cceDetailsUrl +
        `${this.restAPI.homeOwnerEmail}` +
        `?electricUtilityId=${utilityId}`
    );
  }

  getCceDetails(userId) {
    return this.httpClient.get<CCEDetailsModel>(
      this.API_END_POINT +
        this.cceDetailsUrl +
        this.restAPI.homeOwnerEmail +
        `?electricUtilityId=${userId}`
    );
  }

  checkAutoLogin(): void {
    const vendorCode = this.restAPI.getVendorName();
    if (this.vendorInfo.accessConfig.autoLogin) {
      this.authService.getAutoLoginDetails().subscribe(
        (data) => {
          this.authService.setJWTToken(data.token);
          // Reset existing journey data on successful completion of journey
          this.userInfo = {} as unknown as HPJourneyDetailsPUTModel;
          this.dashboardAPI.getVendorDetails(vendorCode, true);
        },
        (error) => {
          this.restAPI.showErrorMessage(error.error.message);
        }
      );
    } else {
      this.router.navigateByUrl("sign-up/thank-you");
    }
  }
}
