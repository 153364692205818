import { AuthenticationService } from "./auth.service";
import { Injectable } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

@Injectable()
export class AuthGuard {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}
  canActivate(
    activeRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    this.authenticationService.redirectUrl = state.url;
    let token: string = this.authenticationService.getJwtToken();
    if (token) {
      return true;
    } else {
      this.router.navigate(["/dashboard/login"]);
      return false;
    }
  }
}
