<div class="logo">
  <nav
    class="flex justify-between background-color-white height-10-nav menu_text"
  >
    <div class="padding_left flex items-center">
      <img
        class="yt-cursor-hand yltn_logo"
        [src]="logo.url"
        (click)="onClickLogo(logo.href)"
        *ngIf="logo.customer && !logo.customer.poweredByUrl"
        alt="dashboardlogo"
        loading="lazy"
        width="100px"
      />
      <img
        class="yt-cursor-hand logo-margin_right logo_width"
        [src]="!logo.customer ? logo.url : logo.customer.url"
        (click)="onClickLogo()"
        alt="dashboardLogo"
        [ngClass]="{
          'work-logo_width': vendorCode === 'WORK',
          'wsp-logo_width': vendorCode === 'WSPP'
        }"
        loading="lazy"
      />
    </div>
    <div class="signUp_section">
      <div
        class="mr4 lang_wrapper yt-cursor-hand"
        *ngIf="language.length > 1"
        [matMenuTriggerFor]="languageMenu"
      >
        {{ this.currentLanguage }}
        <mat-icon class="cursor-pointer-logo">expand_more</mat-icon>
      </div>
      <span
        class="mr4 yt-cursor-hand show_link"
        routerLink="/incentives"
        routerLinkActive="fw6"
      >
        Rebates & Incentives
      </span>
      <span
        class="mr4 yt-cursor-hand show_link"
        (click)="onLearningHub()"
        routerLinkActive="fw6"
        >Learning Hub</span
      >
      <span
        class="mr4 yt-cursor-hand show_link"
        routerLinkActive="fw6"
        routerLink="/dashboard/login"
        >Login</span
      >
      <span
        class="yt-cursor-hand show_link"
        routerLinkActive="fw6"
        routerLink="/register"
        >Sign up</span
      >
      <!-- Hamburger for mobile -->
      <div class="yt_mobile_view ml4">
        <span class="material-icons" [matMenuTriggerFor]="dropDownMenu"
          >menu</span
        >
      </div>
    </div>
  </nav>
  <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
    <div
      class="menu-subheading_padding"
      (click)="onLanguageChange(lang.value)"
      *ngFor="let lang of language"
      [ngClass]="{ font_wt: selectedLanguage === lang.value }"
    >
      {{ lang.displayValue }}
    </div>
  </mat-menu>
  <!-- HamBurger Menu Drop Down unauthorized top nav-->
  <mat-menu #dropDownMenu="matMenu" [overlapTrigger]="false">
    <div class="menu-subheading_padding" routerLink="/incentives">
      <img
        src="assets/dashboard/top-nav/incentives.svg"
        alt="incentives"
        height="18px"
        width="18px"
        loading="lazy"
      />
      <span class="yt-subHeading-style icon_padding_left"
        >Rebates & Incentives</span
      >
    </div>
    <div class="menu-subheading_padding" (click)="onLearningHub()">
      <img
        src="assets/dashboard/top-nav/Electrify.svg"
        alt="learning hub"
        height="18px"
        width="18px"
        loading="lazy"
      />
      <span class="yt-subHeading-style icon_padding_left">Learning Hub</span>
    </div>
    <div class="menu-subheading_padding" routerLink="/register">
      <img
        src="assets/dashboard/top-nav/signup.svg"
        alt="signup"
        height="18px"
        width="18px"
        loading="lazy"
      />
      <span class="yt-subHeading-style icon_padding_left">Sign up</span>
    </div>
    <div class="menu-subheading_padding" routerLink="/dashboard/login">
      <img
        src="assets/dashboard/top-nav/login.svg"
        alt="login"
        height="18px"
        width="18px"
        loading="lazy"
      />
      <span class="yt-subHeading-style icon_padding_left">Login</span>
    </div>
  </mat-menu>
</div>
