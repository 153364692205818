<div class="tech__summary">
  <div class="heading">EV Charger</div>
  <div
    class="paragraph"
    [innerHTML]="
      'homeOwenerDashboard.learnMore.evChargers.paragraph' | translate
    "
  ></div>
</div>
<form [formGroup]="evChargerForm" class="form__container">
  <div class="form__wrapper">
    <div>
      <mat-radio-group
        class="radioButton-flex"
        formControlName="own"
        (change)="condition()"
      >
        <div class="cards" *ngFor="let radioOptions of radioButtonOptions">
          <label>
            <yt-radio-buttons
              [viewValue]="radioOptions.viewValue"
              [icon]="radioOptions.icon"
              [default]="selectedRadioButton"
              [value]="radioOptions.value"
            ></yt-radio-buttons>
            <mat-radio-button
              class="hidden_button"
              [value]="radioOptions.value"
              (change)="onRadioChange($event)"
            ></mat-radio-button>
          </label>
        </div>
      </mat-radio-group>
      <div class="form__field-wrapper" *ngIf="hideFormField">
        <section class="form__field-section">
          <div class="field__label">
            <span>Make & model</span
            ><span class="link__text" (click)="openDialogStatus()"
              >Help me choose</span
            >
          </div>
          <mat-form-field floatLabel="auto" appearance="outline">
            <mat-select placeholder="Make & Model" formControlName="model">
              <mat-option
                *ngFor="let makeAndModel of mnfDropDownList"
                [value]="makeAndModel.mnfAndModel"
                [matTooltip]="makeAndModel.mnfAndModel"
                >{{ makeAndModel.mnfAndModel }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="evChargerForm.controls['model'].hasError('required')"
              >Required!</mat-error
            >
          </mat-form-field>
        </section>
      </div>
    </div>
  </div>
  <div class="action__wrapper">
    <button
      mat-raised-button
      color="accent"
      (click)="onEvChargerDetails()"
      [disabled]="evChargerForm.invalid"
      class="primary__button"
    >
      SAVE
    </button>
  </div>
</form>
