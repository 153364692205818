import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HPSCSettingModel } from "../energy-choices.model";
import { RestAPIService } from "../../../rest-api/rest-api.service";
import { LifestyleService } from "../../../rest-api/lifestyle.service";
import { DropDownModel } from "../../../rest-api/rest-api.model";
import { LearnMoreService } from "../../../homeowner-dashboard/learn-more/learn-more.service";
import { Utils } from "../../../utils/util";

@Component({
  selector: "yt-hpsc",
  templateUrl: "hpsc.component.html",
  styleUrls: ["../energy-choices-common.scss"],
})
export class HPSCComponent implements OnInit {
  panelOpenState: boolean = false;
  hpscForm: FormGroup;
  updateMode: boolean;
  homeOwnerEmail: string;
  hpscData: HPSCSettingModel[];
  planacText: boolean;
  @Output() saveHPSC = new EventEmitter<any>();
  disableDatePicker: boolean;
  monthList: string[];
  lastTwelthMonth: string;
  squareFoot: string;
  currentHeaterCooler: DropDownModel[];
  ducts: DropDownModel[];

  constructor(
    private fb: FormBuilder,
    private restAPIService: RestAPIService,
    private lifeStyleService: LifestyleService,
    private utils: Utils,
    private learnMoreService: LearnMoreService
  ) {
    this.currentHeaterCooler = this.learnMoreService.hpscType;
    this.ducts = this.learnMoreService.ducts;
    this.hpscForm = this.fb.group({
      own: ["yesPlan", Validators.required],
      ducts: ["have", Validators.required],
      sqFoot: [
        "",
        [
          Validators.required,
          Validators.pattern("^([2-9][0-9][0-9]|[0-5][0-9][0-9][0-9]|)$"),
          Validators.min(200),
          Validators.max(5000),
        ],
      ],
      hpscType: ["Natural gas", Validators.required],
      age: [
        "15",
        [
          Validators.required,
          Validators.pattern(/^(?:[0-9]|0[1-9]|1[0-9]|2[0-9]|3[0-9]|4[0-5])$/),
          Validators.min(0),
          Validators.max(45),
        ],
      ],
      bought: [""],
      datePurchased: ["", Validators.required],
    });
  }

  ngOnInit() {
    this.monthList = this.utils.monthList;
    let vendorType = this.restAPIService.solarVendorInformation.solutionType;
    let buildingType = this.restAPIService.getBuildingType();
    if (vendorType === "vendor" && buildingType === "singleFamilyHome") {
      this.squareFoot = "1200";
    } else if (vendorType === "vendor" && buildingType === "townHome") {
      this.squareFoot = "1000";
    } else {
      this.squareFoot = "1600";
    }
    this.hpscForm.get("sqFoot")?.setValue(this.squareFoot);
    this.lastTwelthMonth = this.utils.lastTwelthMonth;
    if (this.hpscForm.get("own")?.value === "yes") {
      this.hpscForm.get("hpscType")?.disable();
      this.hpscForm.get("hpscType")?.setValue("Electric");
    }
    if (this.hpscForm.get("own")?.value === "yesPlan") {
      this.hpscForm.get("hpscType")?.setValue("Natural gas");
    }
    if (this.lifeStyleService.getHpscInfo() !== undefined) {
      this.hpscData = this.lifeStyleService.getHpscInfo();
      this.checkInUpdateView(this.hpscData[0]);
    }
    if (this.hpscForm.get("own")?.value !== "yes") {
      this.hpscForm.get("datePurchased")?.disable();
    }
  }

  isDatePurchasedEmpty() {
    if (this.hpscForm.get("own")?.value === "yes") {
      if (!this.hpscForm.get("datePurchased")?.value) {
        return true;
      }
    }
    return false;
  }

  checkInUpdateView(data: HPSCSettingModel) {
    this.updateMode = true;
    this.hpscForm.controls["own"].setValue(data.own);
    this.hpscForm.controls["sqFoot"].setValue(
      this.lifeStyleService.userProvidedSqFoot
    );
    this.hpscForm.controls["ducts"].setValue(data.ducts);
    data.hpscType
      ? this.hpscForm.controls["hpscType"].setValue(data.hpscType)
      : this.hpscForm.get("hpscType")?.disable();
    data.age
      ? this.hpscForm.controls["age"].setValue(data.age)
      : this.hpscForm.get("age")?.disable();
    data.bought
      ? this.hpscForm.controls["bought"].setValue(data.bought)
      : this.hpscForm.get("bought")?.disable();
    data.bought
      ? this.hpscForm.controls["datePurchased"].setValue(data.datePurchased)
      : this.hpscForm.get("datePurchased")?.setValue(this.lastTwelthMonth);
    data.own === "yes"
      ? this.hpscForm.controls["datePurchased"].enable()
      : this.hpscForm.controls["datePurchased"].disable();
    this.disableDatePicker = data.own === "yes";
  }

  condition(): void {
    if (this.hpscForm.get("own")?.value === "yes") {
      this.hpscForm.get("hpscType")?.disable();
      this.hpscForm.get("bought")?.enable();
      this.hpscForm.get("age")?.enable();
      this.hpscForm.get("datePurchased")?.enable();
      this.disableDatePicker = true;
    } else if (this.hpscForm.get("own")?.value === "yesPlan") {
      this.hpscForm.get("hpscType")?.enable();
      this.hpscForm.get("bought")?.enable();
      this.hpscForm.get("age")?.enable();
      this.hpscForm.get("bought")?.setValue(false);
      this.hpscForm.get("datePurchased")?.disable();
      this.disableDatePicker = false;
    } else if (this.hpscForm.get("own")?.value === "no") {
      this.hpscForm.get("hpscType")?.disable();
      this.hpscForm.get("age")?.disable();
      this.hpscForm.get("bought")?.disable();
      this.hpscForm.get("datePurchased")?.disable();
      this.disableDatePicker = false;
    }
  }

  onSaveHPSC() {
    const hpscInfo: {
      energyChoices: {
        sqFoot: string;
        hpsc: {
          own: string;
          ducts?: string;
          age?: string;
          hpscType?: string;
          bought?: string;
          datePurchased?: string;
        }[];
      };
    } = {
      energyChoices: {
        sqFoot: this.hpscForm.get("sqFoot")?.value,
        hpsc: [],
      },
    };
    if (this.hpscForm.get("own")?.value === "yes") {
      const datePurchased = this.hpscForm.get("datePurchased")?.value;
      const bought = this.utils.isDatePurchaseSelected(datePurchased);
      this.hpscForm.get("bought")?.setValue(bought);
      hpscInfo.energyChoices.hpsc[0] = {
        own: this.hpscForm.get("own")?.value,
        ducts: this.hpscForm.get("ducts")?.value,
        age: this.hpscForm.get("age")?.value,
        bought: this.hpscForm.get("bought")?.value,
        datePurchased: !this.hpscForm.get("bought")?.value
          ? null
          : this.hpscForm.get("datePurchased")?.value,
      };
    } else if (this.hpscForm.get("own")?.value === "yesPlan") {
      hpscInfo.energyChoices.hpsc[0] = {
        own: this.hpscForm.get("own")?.value,
        ducts: this.hpscForm.get("ducts")?.value,
        hpscType: this.hpscForm.get("hpscType")?.value,
        age: this.hpscForm.get("age")?.value,
      };
    } else {
      hpscInfo.energyChoices.hpsc[0] = {
        own: this.hpscForm.get("own")?.value,
        ducts: this.hpscForm.get("ducts")?.value,
      };
    }
    this.lifeStyleService
      .onSaveLifestyleInfo(hpscInfo)
      .subscribe((response) => {
        if (response.status === 200) {
          this.lifeStyleService.postHpscInfo(hpscInfo);
          this.saveHPSC.emit();
        }
      });
  }
}
