import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "yt-star-rating",
  templateUrl: "./star-rating.component.html",
  styleUrls: ["../info-card.component.scss"],
})
export class StarRatingComponent implements OnInit {
  @Input() starCount: number;
  @Input() easeOfProjectText: String;
  starCountArray: number[];
  constructor() {}

  ngOnInit(): void {
    this.starCountArray = this.getStarRange(this.starCount);
  }

  getEaseOfProjectText(count: number): string {
    if (count === 1) {
      return "Easy";
    } else if (count === 2) {
      return "Medium";
    } else if (count === 3) {
      return "Difficult";
    } else {
      return "";
    }
  }

  getStarRange(starCount: number): number[] {
    return Array.from({ length: starCount });
  }
}
