import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { LMNavigatorComponent } from "./scroll-link-toggle.component";
import { MatIconModule } from "@angular/material/icon";
@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
  ],
  declarations: [LMNavigatorComponent],
  exports: [LMNavigatorComponent],
  providers: [],
})
export class LMNavigatorModule {}
