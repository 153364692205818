import { Component, Input } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { IncentivesOverlayComponent } from "./incentives-overlay/incentives-overlay.component";
import { DashboardAPIService } from "../../../../rest-api/dashboard/dashboard-api.service";

@Component({
  selector: "yt-incentives",
  templateUrl: "./incentives.component.html",
  styleUrls: ["./incentives.component.scss"],
})
export class IncentivesComponent {
  @Input() totalIncentives: number;
  constructor(
    private dialog: MatDialog,
    private dashboardAPI: DashboardAPIService
  ) {}

  onDetails(): void {
    this.dashboardAPI.dashboardDetailsAnalytics("incentives");
    const dialogConfig = new MatDialogConfig();
    dialogConfig.position = { right: "0" };
    let dialogRef = this.dialog.open(IncentivesOverlayComponent, {
      width: "70%",
      height: "100%",
      autoFocus: false,
      backdropClass: "dark-backdrop",
      panelClass: "homeSetting_container",
      data: { tech: "" },
      exitAnimationDuration: 0,
    });
    dialogRef.afterClosed().subscribe(() => {});
  }
}
