<div class="form_wrapper mb_35" [formGroup]="userAddress">
  <div class="container">
    <h1 class="header">
      {{ "homeOwnerInfo.homeInfo.header" | translate }}
    </h1>
    <p class="subHeader">
      {{ "homeOwnerInfo.homeInfo.subHeader" | translate }}
    </p>
    <form [formGroup]="userAddress">
      <div class="margin_bottom">
        <mat-form-field appearance="outline" hideRequiredMarker="true" floatLabel="auto">
          <mat-label>Select Building Type and Ownership</mat-label>
          <mat-select matInput formControlName="buildingType" required id="buildingType" (focusout)="onSubmit(false)">
            <mat-option [value]="type.value" *ngFor="let type of buildingTypes">{{
              type.displayValue
              }}</mat-option>
          </mat-select>
          <mat-error *ngIf="isFieldValid('buildingType', true)">
            Required!
          </mat-error>
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline" hideRequiredMarker="true" floatLabel="auto">
        <mat-label>Address</mat-label>
        <input id="placeAddress" matInput autocomplete="noneaddress" placeholder=" " type="search" #address required
          formControlName="address" (input)="onValidateAddress(address.value)" />
        <mat-hint align="end" *ngIf="!(invalidAddress && !isFieldValid('address', true))">We don't store your address
          into our system.</mat-hint>
        <mat-error *ngIf="isFieldValid('address', true)">
          Required!
        </mat-error>
        <div *ngIf="invalidAddress && !isFieldValid('address', true)" class="yt-error-address">
          Select value from dropdown
        </div>
      </mat-form-field>

      <div class="button_wrapper">
        <button mat-raised-button class="button_width" color="accent" (click)="onSubmit(true)"
          [disabled]="isButtonDisabled()">
          NEXT
        </button>
      </div>
    </form>

    <yt-error-popup [showErrorDialogueBox]="showErrorDialogBox" [duplicationUserError]="duplicationUserError"
      [cityStatus]="cityStatus" (isDialogBoxOpened)="closeErrorDialogBox()"></yt-error-popup>
  </div>
</div>