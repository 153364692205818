export class LearnMoreUtils {
  toggleBasicInfoAnswers(questionId: string): void {
    let element = document.querySelector(`#${questionId}`)!;
    if (element.className.indexOf("answer_show") === -1) {
      element.className += " answer_show";
    } else {
      element.className = element.className.replace(" answer_show", "");
    }
  }
}
