<scroll-top></scroll-top>
<div class="page_container">
  <div class="yt_widthSize">
    <div class="mb4 tl-ns tc">
      <div class="yt_pt55 yt__info-header tl">{{ header }}</div>
      <div class="yt_pt2 tl yt__info-sub-header">{{ subHeader }}</div>
      <div class="yt__open-sans yt_mt60-26">
        <div class="cf yt_with_continue yt__my-info">
          <div class="fr yt_formwidth w-50-m">
            <form class="w-100" [formGroup]="userDetailsForm">
              <div class="w-100 flex-ns justify-between">
                <mat-form-field
                  appearance="outline"
                  class="yt_input-width50 yt_mb-45 pb0"
                  floatLabel="auto"
                  hideRequiredMarker="true"
                >
                  <mat-label>First Name</mat-label>
                  <input
                    matInput
                    formControlName="firstName"
                    id="firstName"
                    type="text"
                    autocomplete="firstName"
                    (focusout)="triggerApi(false)"
                    required
                  />
                  <mat-error *ngIf="userDetailsFormValidation.firstName"
                    >{{ userDetailsFormValidation.firstName }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field
                  appearance="outline"
                  class="yt_input-width50 yt_mb-45 pb0"
                  floatLabel="auto"
                  hideRequiredMarker="true"
                >
                  <mat-label>Last Name</mat-label>
                  <input
                    matInput
                    formControlName="lastName"
                    id="lastName"
                    type="text"
                    autocomplete="endName"
                    (focusout)="triggerApi(false)"
                    required
                  />
                  <mat-error *ngIf="userDetailsFormValidation.lastName"
                    >{{ userDetailsFormValidation.lastName }}
                  </mat-error>
                </mat-form-field>
              </div>
              <mat-form-field
                appearance="outline"
                class="w-100 pb0"
                floatLabel="auto"
                hideRequiredMarker="true"
                [ngClass]="{
                  'yt_mb-45':
                    (!invalidMail && !isWorkDayUser) ||
                    userDetailsForm.controls['emailId'].invalid
                }"
              >
                <mat-label>Email Address</mat-label>
                <input
                  matInput
                  formControlName="emailId"
                  autocomplete="nonemail"
                  id="email"
                  type="email"
                  (focusout)="triggerApi(true)"
                  required
                />
                <mat-error *ngIf="userDetailsFormValidation.emailId.required"
                  >{{ userDetailsFormValidation.emailId.required }}
                </mat-error>
                <mat-error
                  *ngIf="
                    userDetailsFormValidation.emailId.pattern &&
                    !userDetailsFormValidation.emailId.required
                  "
                >
                  {{ userDetailsFormValidation.emailId.pattern }}
                </mat-error>
              </mat-form-field>
              <div
                class="z-2 tl yt_mat-error"
                *ngIf="
                  isWorkDayUser && userDetailsForm.controls['emailId'].valid
                "
              >
                Please use your personal email address to sign up
              </div>
              <div
                class="z-2 tl yt_mat-error"
                *ngIf="
                  invalidMail &&
                  !isWorkDayUser &&
                  userDetailsForm.controls['emailId'].valid
                "
              >
                Invalid email!
              </div>
              <mat-form-field
                appearance="outline"
                class="w-100 pb0"
                hideRequiredMarker="true"
                floatLabel="auto"
                [ngClass]="{
                  'yt_mb-45': !inValidAddress && userDetailsFormValidation,
                  'yt_pb-45': userDetailsFormValidation.address || inValidAddress,
                }"
              >
                <mat-label>Address</mat-label>
                <input
                  matInput
                  formControlName="address"
                  id="placeAddress"
                  autocomplete="noneaddress"
                  type="search"
                  #address
                  (input)="onValidateAddress(address.value)"
                  required
                />
                <mat-error *ngIf="userDetailsFormValidation.address">{{
                  userDetailsFormValidation.address
                }}</mat-error>
                <mat-error
                  *ngIf="inValidAddress && !userDetailsFormValidation.address"
                >
                  Select address from dropdown
                </mat-error>
              </mat-form-field>
              <mat-form-field
                appearance="outline"
                class="yt_mb-45 w-100 pb0"
                hideRequiredMarker="true"
                floatLabel="auto"
              >
                <mat-label>Select Building Type and Ownership</mat-label>
                <mat-select
                  matInput
                  formControlName="buildingType"
                  (focusout)="triggerApi(false)"
                  id="buildingType"
                  required
                >
                  <mat-option
                    [value]="type.value"
                    *ngFor="let type of building_type"
                    >{{ type.displayValue }}</mat-option
                  >
                </mat-select>
                <mat-error *ngIf="userDetailsFormValidation.buildingType"
                  >{{ userDetailsFormValidation.buildingType }}
                </mat-error>
                <mat-error
                  *ngIf="
                    userDetailsForm.controls['buildingType'].hasError(
                      'required'
                    )
                  "
                >
                  Required!
                </mat-error>
              </mat-form-field>
              <mat-form-field
                appearance="outline"
                class="yt_mb-30 w-100 pb0"
                floatLabel="auto"
                hideRequiredMarker="true"
              >
                <mat-label>Phone Number {{ mobilePlaceHolder }}</mat-label>
                <input
                  matInput
                  formControlName="mobile"
                  type="text"
                  maxlength="12"
                  #mobileNumber
                  (input)="formatMobileNumber(mobileNumber.value)"
                  id="mobile"
                  autocomplete="none"
                  (focusout)="triggerApi(false)"
                  [required]="mobileNumberMandatory"
                />
                <mat-error
                  *ngIf="
                    userDetailsForm.controls['mobile'].hasError('required') &&
                    userDetailsForm.controls['mobile'].touched
                  "
                >
                  Required
                </mat-error>
                <mat-error
                  *ngIf="
                    userDetailsForm.controls['mobile'].hasError('minlength') &&
                    !userDetailsForm.controls['mobile'].hasError('required')
                  "
                >
                  Invalid phone number!
                </mat-error>
              </mat-form-field>
              <div
                class="mr-auto yt_disclaimer-width w-100 w-50-m w-50-l yt_disclaimer mr3-ns tl yt_topPadding"
              >
                <span>
                  <span>{{ footer }}</span>
                  By clicking "Continue", you agree to YellowTin's
                </span>
                <a
                  class="pointer yt__tundora"
                  href="https://www.yellowtin.com/termsofuse/"
                  target="_blank"
                >
                  Terms of Use </a
                >and
                <a
                  class="pointer yt__tundora"
                  href="https://www.yellowtin.com/privacy_policy/"
                  target="_blank"
                >
                  Privacy Policy.</a
                >
              </div>
            </form>
            <div class="flex-ns justify-end yt_pt-12" style="max-width: 1000px">
              <button
                mat-raised-button
                color="accent"
                [disabled]="
                  userDetailsForm.invalid ||
                  inValidAddress ||
                  (isWorkDayUser && userDetailsForm.controls['emailId'].valid)
                "
                (click)="checkForEmailChange(true)"
              >
                CONTINUE
              </button>
            </div>
            <div class="haveAccount tc w-100" *ngIf="alreadyUser">
              <div class="haveAccount__text">Already have an account?</div>
              <button
                mat-raised-button
                color="accent"
                (click)="navigateToLogin()"
              >
                LOGIN
              </button>
            </div>
          </div>
          <div class="fl yt_map-width tc dn db-ns">
            <mat-card>
              <div id="map_canvas" class="w-100 yt__h-20"></div>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
  <yt-footer class="footer_container"></yt-footer>
</div>

<yt-error-dialog
  [showErrorDialogueBox]="showErrorDialogueBox"
  [duplicationuserror]="duplicationuserror"
  [loginaccess]="loginaccess"
  [cityStatus]="cityStatus"
  [loginLink]="loginLink"
  [forgotPasswordLink]="forgotPasswordLink"
  (isDialogboxOpened)="closeErrorDialogBox()"
></yt-error-dialog>

<div id="popUp" *ngIf="useremailId">
  <div id="text">
    <div class="bg-white">
      <div
        class="w-100 flex items-center justify-center yt_popup-padding yt_exclamation"
      >
        <div class="dib yt_heading fw6">Confirm email update</div>
      </div>
      <div class="yt_popup-padding yt_text lh-copy">
        <div>
          This action will clear all your information. Are you sure you want to
          continue?
        </div>
        <div class="popup-button">
          <div class="dialogBox">
            <button
              mat-raised-button
              color="accent"
              (click)="closePopup()"
              class="cancel-button"
            >
              NO
            </button>
          </div>
          <div class="dialogBox">
            <button
              mat-raised-button
              color="accent"
              (click)="onYes()"
              class="continue-button"
            >
              YES
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
