import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PoolSettingModel } from "../energy-choices.model";
import { LifestyleService } from "../../../rest-api/lifestyle.service";
import { Utils } from "../../../utils/util";

@Component({
  selector: "yt-pool",
  templateUrl: "pool.component.html",
  styleUrls: ["../energy-choices-common.scss"],
})
export class PoolComponent implements OnInit {
  panelOpenState: boolean = false;
  spForm: FormGroup;
  monthList: string[];
  lastTwelthMonth: string;
  updateMode: boolean;
  poolData: PoolSettingModel[];
  @Output() savePOOL = new EventEmitter<any>();
  disableDatePicker: boolean;

  constructor(
    private fb: FormBuilder,
    private lifeStyleService: LifestyleService,
    private utils: Utils
  ) {
    this.spForm = this.fb.group({
      own: ["yesPlan", Validators.required],
      months: [
        "4",
        [
          Validators.required,
          Validators.pattern("^(1[0-2]|[1-9])$"),
          Validators.min(1),
          Validators.max(12),
        ],
      ],
      bought: [""],
      datePurchased: ["", Validators.required],
    });
  }

  ngOnInit() {
    this.monthList = this.utils.monthList;
    this.lastTwelthMonth = this.utils.lastTwelthMonth;
    if (this.lifeStyleService.getPoolInfo() !== undefined) {
      this.poolData = this.lifeStyleService.getPoolInfo();
      this.checkInUpdateView(this.poolData[0]);
    }
    if (this.spForm.get("own")?.value !== "yes") {
      this.spForm.get("datePurchased")?.disable();
    }
  }

  isDatePurchasedEmpty() {
    if (this.spForm.get("own")?.value === "yes") {
      if (!this.spForm.get("datePurchased")?.value) {
        return true;
      }
    }
    return false;
  }

  condition() {
    if (this.spForm.get("own")?.value === "yes") {
      this.spForm.get("datePurchased")?.enable();
      this.spForm.get("bought")?.enable();
      this.spForm.get("months")?.enable();
      this.disableDatePicker = true;
    } else if (this.spForm.get("own")?.value === "yesPlan") {
      this.spForm.get("bought")?.disable();
      this.spForm.get("datePurchased")?.disable();
      this.spForm.get("months")?.enable();
      this.disableDatePicker = false;
    } else if (this.spForm.get("own")?.value === "no") {
      this.spForm.get("bought")?.disable();
      this.spForm.get("datePurchased")?.disable();
      this.spForm.get("months")?.disable();
      this.disableDatePicker = false;
    }
  }

  checkInUpdateView(data: PoolSettingModel) {
    this.updateMode = true;
    this.spForm.controls["own"].setValue(data.own);
    if (data.months) {
      this.spForm.controls["months"].setValue(data.months);
    } else {
      this.spForm.get("months")?.disable();
    }
    if (data.bought) {
      this.spForm.controls["bought"].setValue(data.bought);
      this.spForm.controls["datePurchased"].setValue(data.datePurchased);
    } else {
      if (data.own === "yes") {
        this.spForm.controls["bought"].setValue(false);
        this.spForm.get("datePurchased")?.enable();
        this.spForm.get("datePurchased")?.setValue(this.lastTwelthMonth);
      } else {
        this.spForm.get("bought")?.disable();
        this.spForm.get("datePurchased")?.disable();
      }
    }
    this.disableDatePicker = data.own === "yes";
  }

  onSavePOOL() {
    const poolInfo: {
      energyChoices: {
        pool: {
          own: string;
          months?: string;
          bought?: string;
          datePurchased?: string;
        }[];
      };
    } = {
      energyChoices: {
        pool: [],
      },
    };
    if (this.spForm.get("own")?.value === "yes") {
      const datePurchased = this.spForm.get("datePurchased")?.value;
      const bought = this.utils.isDatePurchaseSelected(datePurchased);
      this.spForm.get("bought")?.setValue(bought);
      poolInfo.energyChoices.pool[0] = {
        own: this.spForm.get("own")?.value,
        months: this.spForm.get("months")?.value,
        bought: this.spForm.get("bought")?.value,
        datePurchased: !this.spForm.get("bought")?.value
          ? null
          : this.spForm.get("datePurchased")?.value,
      };
    } else if (this.spForm.get("own")?.value === "yesPlan") {
      poolInfo.energyChoices.pool[0] = {
        own: this.spForm.get("own")?.value,
        months: this.spForm.get("months")?.value,
      };
    } else if (this.spForm.get("own")?.value === "no") {
      poolInfo.energyChoices.pool[0] = {
        own: this.spForm.get("own")?.value,
      };
    }
    this.lifeStyleService
      .onSaveLifestyleInfo(poolInfo)
      .subscribe((response) => {
        if (response.status === 200) {
          this.lifeStyleService.postPoolInfo(poolInfo);
          this.savePOOL.emit();
        }
      });
  }
}
