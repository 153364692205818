<mat-sidenav-container class="h-100 yt__open-sans">
  <!-- Authorized page -->
  <nav
    *ngIf="routeInfo.topnav.showLink"
    class="flex justify-between background-color-white height-10-nav menu_text"
  >
    <div class="padding_left flex items-center">
      <div class="margin_left" *ngIf="!logo">
        <i class="fa fa-spinner fa-pulse fa-2x logo-margin_right"></i>
      </div>
      <div *ngIf="logo" class="flex items-center">
        <img
          class="yt-cursor-hand yltn_logo"
          [src]="logo.url"
          (click)="onClickLogo(logo.href)"
          *ngIf="logo.customer && !logo.customer.poweredByUrl"
          alt="dashboard-logo"
          loading="lazy"
          width="100px"
        />
        <img
          [src]="logo.customer ? logo.customer.url : logo.url"
          alt="dashboard-logo"
          (click)="onClickLogo(logo.customer ? logo.customer.href : logo.href)"
          class="logo-margin_right yt-cursor-hand logo_width"
          [ngClass]="{
            'work-logo_width': vendorCode === 'WORK',
            'wsp-logo_width': vendorCode === 'WSPP'
          }"
          loading="lazy"
        />
      </div>
      <div class="yt_desktop-view">
        <span
          id="myDashboard"
          class="mr4 yt-cursor-hand"
          routerLink="/dashboard/overview"
          routerLinkActive="fw6"
          >Dashboard</span
        >
        <span
          *ngIf="settinginfo"
          [hidden]="!settinginfo.usage"
          routerLink="/dashboard/energy-usage"
          routerLinkActive="fw6"
          class="mr4 yt-cursor-hand"
        >
          Usage
        </span>
        <span
          class="mr4 yt-cursor-hand"
          routerLink="/dashboard/my-list"
          (click)="onMyList()"
          routerLinkActive="fw6"
          >My List</span
        >
        <span
          *ngIf="settinginfo"
          [hidden]="!settinginfo.marketplace"
          class="yt-cursor-hand"
          routerLink="/dashboard/market-place"
          (click)="onMarketplace()"
          routerLinkActive="fw6"
          >Marketplace</span
        >
      </div>
    </div>
    <div class="lang_wrapper">
      <span
        class="ml4 yt-cursor-hand"
        *ngIf="haveLanguageDropDown"
        [matMenuTriggerFor]="languageMenu"
      >
        {{ this.currentLanguage }}
        <mat-icon class="cursor-pointer-logo">expand_more</mat-icon>
      </span>
    </div>
    <!-- Hamburger for Mobile -->
    <div class="yt_mobile-view padding_right yt-cursor-hand">
      <span class="material-icons" [matMenuTriggerFor]="UserProfile">
        menu
      </span>
    </div>
    <!-- User Name for Desktop -->
    <div class="display-none padding_right flex-ns dn items-center">
      <span
        class="yt-cursor-hand mr4"
        (click)="onLearningHub()"
        id="learning-hub-menu"
        >Learning Hub</span
      >
      <span
        class="mr4 yt-cursor-hand"
        routerLinkActive="fw6"
        *ngIf="settinginfo"
        [hidden]="!settinginfo.notification"
        routerLink="/dashboard/notification-center"
        (click)="onNotifications()"
        routerLinkActive="fw6"
      >
        Notifications
      </span>
      <span
        class="mr4 yt-cursor-hand"
        *ngIf="showReferFriend && vendorCode !== 'LOWE'"
        (click)="onReferFriend()"
        matTooltip="Refer your friends"
      >
        Refer your friends
      </span>
      <span
        *ngIf="jwtToken"
        id="person"
        class="yt-cursor-hand"
        [matMenuTriggerFor]="UserProfile"
      >
        {{ name }}
        <mat-icon class="yt-cursor-hand">expand_more</mat-icon>
      </span>
      <!-- <span *ngIf="logo">
        <span
          class="ml4 pointer flex items-center"
          *ngIf="logo.customer && logo.customer.poweredByUrl"
          (click)="onClickLogo(logo.href)"
        >
          <img
            [src]="logo.customer.poweredByUrl"
            loading="lazy"
            alt="yellowtin"
            width="90px"
          />
        </span>
      </span> -->
    </div>
  </nav>

  <!-- Unauthorized page -->
  <nav
    *ngIf="routeInfo.topnavlogin.showLink"
    class="flex justify-between background-color-white height-10-nav menu_text"
  >
    <div class="padding_left flex items-center w-80">
      <div class="logo_section">
        <div class="margin_left" *ngIf="!logo">
          <i class="fa fa-spinner fa-pulse fa-2x"></i>
        </div>
        <div *ngIf="logo" class="flex items-center">
          <img
            class="yt-cursor-hand yltn_logo"
            [src]="logo.url"
            (click)="onClickLogo(logo.href)"
            *ngIf="logo.customer && !logo.customer.poweredByUrl"
            alt="dashboard-logo"
            loading="lazy"
            width="100px"
          />
          <img
            class="yt-cursor-hand logo-margin_right logo_width"
            [src]="logo.customer ? logo.customer.url : logo.url"
            [ngClass]="{
              'work-logo_width': vendorCode === 'WORK',
              'wsp-logo_width': vendorCode === 'WSPP'
            }"
            (click)="
              onClickLogo(logo.customer ? logo.customer.href : logo.href)
            "
            alt="dashboard-logo"
          />
        </div>
      </div>
    </div>
    <div class="login_section">
      <div
        class="mr4 lang_wrapper yt-cursor-hand"
        *ngIf="haveLanguageDropDown"
        [matMenuTriggerFor]="languageMenu"
      >
        {{ this.currentLanguage }}
        <mat-icon class="cursor-pointer-logo">expand_more</mat-icon>
      </div>
      <span
        class="yt-cursor-hand show_link mr4"
        routerLink="/incentives"
        routerLinkActive="fw6"
      >
        Rebates & Incentives
      </span>
      <span
        class="mr4 yt-cursor-hand show_link"
        (click)="onLearningHub()"
        routerLinkActive="fw6"
        id="learning-hub-menu"
        >Learning Hub</span
      >
      <span
        class="mr4 yt-cursor-hand show_link"
        (click)="onHelpCenter()"
        *ngIf="isLoggedIn"
        >Help Center</span
      >
      <span
        class="mr4 yt-cursor-hand show_link"
        routerLinkActive="fw6"
        routerLink="/dashboard/login"
        >Login</span
      >
      <span
        class="yt-cursor-hand show_link"
        routerLink="/register"
        routerLinkActive="fw6"
        >Sign up</span
      >
      <!-- HamBurger MenuFor Unauthorized page -->
      <div class="yt_mobile_view ml4">
        <span class="material-icons" [matMenuTriggerFor]="UserProfile">
          menu
        </span>
      </div>
    </div>
  </nav>
  <ng-content></ng-content>
</mat-sidenav-container>

<!-- Menu Drop-down -->
<mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
  <div
    class="menu-item menu-subheading_padding"
    (click)="onLanguageChange(lang.value)"
    *ngFor="let lang of language"
    [ngClass]="{ font_wt: selectedLanguage === lang.value }"
  >
    {{ lang.displayValue }}
  </div>
</mat-menu>

<mat-menu #UserProfile="matMenu" [overlapTrigger]="false">
  <div class="display menu-subheading_padding" *ngIf="isLoggedIn">
    <img
      src="assets/dashboard/top-nav/MyDashboard.svg"
      alt="My Dashboard"
      height="18px"
      width="18px"
      loading="lazy"
    />
    <span
      class="icon_padding_left yt-subHeading-style"
      routerLink="/dashboard/overview"
      >Dashboard</span
    >
  </div>
  <div
    class="display menu-subheading_padding"
    *ngIf="settinginfo && isLoggedIn"
    [hidden]="!settinginfo.usage"
    routerLink="/dashboard/energy-usage"
  >
    <img
      src="assets/dashboard/top-nav/HistoricalUsage.svg"
      alt="Usage"
      height="18px"
      width="18px"
      loading="lazy"
    />
    <span class="yt-subHeading-style icon_padding_left"> Usage </span>
  </div>
  <div
    class="display menu-subheading_padding"
    routerLink="/incentives"
    *ngIf="!isLoggedIn"
  >
    <img
      src="assets/dashboard/top-nav/incentives.svg"
      height="18px"
      width="18px"
      alt="incentives"
      loading="lazy"
    />
    <span class="yt-subHeading-style icon_padding_left"
      >Rebates & Incentives</span
    >
  </div>
  <div
    *ngIf="isLoggedIn"
    class="display menu-subheading_padding"
    routerLink="/dashboard/my-list"
    (click)="onMyList()"
  >
    <img
      src="assets/dashboard/top-nav/myList.png"
      height="18px"
      width="18px"
      alt="my list"
      loading="lazy"
    />
    <span class="yt-subHeading-style icon_padding_left">My List</span>
  </div>
  <div
    *ngIf="settinginfo && isLoggedIn"
    [hidden]="!settinginfo.marketplace"
    class="display menu-subheading_padding"
    (click)="onMarketplace()"
    routerLink="/dashboard/market-place"
  >
    <img
      src="assets/dashboard/top-nav/marketcart.svg"
      height="18px"
      width="18px"
      alt="marketplace"
      loading="lazy"
    />
    <span class="yt-subHeading-style icon_padding_left">Marketplace</span>
  </div>
  <div class="display menu-subheading_padding" (click)="onLearningHub()">
    <img
      src="assets/dashboard/top-nav/Electrify.svg"
      alt="LearningHub"
      height="18px"
      width="18px"
      loading="lazy"
    />
    <span class="yt-subHeading-style icon_padding_left"> Learning Hub </span>
  </div>
  <div
    class="display menu-subheading_padding"
    (click)="onNotifications()"
    routerLink="/dashboard/notification-center"
    *ngIf="settinginfo && isLoggedIn"
    [hidden]="!settinginfo.notification"
  >
    <mat-icon matTooltip="Notification">notifications_none</mat-icon>
    <span class="yt-subHeading-style icon_padding_left"> Notifications </span>
  </div>
  <div
    class="display menu-subheading_padding"
    (click)="onReferFriend()"
    *ngIf="showReferFriend && isLoggedIn && vendorCode !== 'LOWE'"
  >
    <mat-icon class="cursor-pointer-logo"> group_add </mat-icon>
    <span class="yt-subHeading-style icon_padding_left"
      >Refer your friends</span
    >
  </div>
  <div class="flex subheading_padding" *ngIf="isLoggedIn">
    <img
      src="assets/dashboard/top-nav/setting.svg"
      alt="Settings"
      height="18px"
      width="18px"
      loading="lazy"
    />
    <span class="sub-heading-menu icon_padding_left">Accounts & Settings</span>
  </div>
  <div class="menu-item" routerLink="/dashboard/my-info" *ngIf="isLoggedIn">
    My Information
  </div>
  <div
    class="menu-item"
    routerLink="/dashboard/change-password"
    *ngIf="isLoggedIn"
  >
    Change Password
  </div>

  <div class="flex subheading_padding" *ngIf="isLoggedIn">
    <img
      src="assets/dashboard/top-nav/HelpCenter.svg"
      alt="Help Center"
      height="18px"
      width="18px"
      loading="lazy"
    />
    <span class="sub-heading-menu icon_padding_left">Support & Help</span>
  </div>
  <div class="menu-item" (click)="onHelpCenter()" *ngIf="isLoggedIn">
    Help Center
  </div>
  <div class="menu-item" (click)="onContact()" *ngIf="isLoggedIn">
    Contact Us
  </div>

  <div class="display menu-subheading_padding" *ngIf="!isLoggedIn">
    <img
      src="assets/dashboard/top-nav/login.svg"
      height="18px"
      width="18px"
      alt="login"
      loading="lazy"
    />
    <span
      class="yt-subHeading-style icon_padding_left"
      routerLink="/dashboard/login"
      >Login</span
    >
  </div>
  <div
    class="display menu-subheading_padding"
    *ngIf="!isLoggedIn"
    routerLink="/register"
  >
    <img
      src="assets/dashboard/top-nav/signup.svg"
      height="18px"
      width="18px"
      alt="signup"
      loading="lazy"
    />
    <span class="yt-subHeading-style icon_padding_left">Sign up</span>
  </div>
  <div
    class="flex sub-heading-menu menu-subheading_padding"
    (click)="onLogOut()"
    *ngIf="isLoggedIn"
  >
    <img
      src="assets/dashboard/top-nav/logout.svg"
      alt="Logout"
      height="18px"
      width="18px"
      loading="lazy"
    />
    <span class="icon_padding_left yt-subHeading-style">Logout</span>
  </div>

  <!-- <div *ngIf="logo">
    <div
      class="tc display justify-center pointer menu-subheading_padding"
      (click)="poweredByLogo()"
      *ngIf="logo.customer && logo.customer.poweredByUrl"
    >
      <img
        [src]="logo.customer.poweredByUrl"
        loading="lazy"
        alt="yellowtin"
        width="90px"
      />
    </div>
  </div> -->
</mat-menu>

<!-- Refer A Friend -->
<yt-refer-friend
  [referFriendPopup]="referFriendPopup"
  [referAFriendText]="referFriendText"
  (friendMail)="friendMail($event)"
></yt-refer-friend>

<!-- Contact Us -->
<yt-contact-us
  [showPopup]="contactUsPopup"
  (closePopup)="closeContactPopup($event)"
></yt-contact-us>
