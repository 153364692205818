<div class="container">
  <h1 class="header">{{ "homeOwnerInfo.goal.header" | translate }}</h1>
  <p class="subHeader">{{ "homeOwnerInfo.goal.subHeader" | translate }}</p>
  <div class="checkbox_group">
    <div *ngFor="let option of categoriesList; let i = index">
      <label>
        <mat-card
          class="checkbox_card"
          [ngClass]="{ card_border: option.selected }"
        >
          <div class="img_container">
            <img [src]="option.img" [alt]="option.altText" width="100%" />
          </div>
          <p class="card_header">{{ option.viewValue }}</p>
          <p class="card_subHeader">{{ option.subHeader }}</p>
        </mat-card>
        <mat-checkbox (change)="onSelectGoal($event, option.value, i)" class="checkbox_label">
        </mat-checkbox>
      </label>
    </div>
  </div>
  <div class="button_wrapper">
    <button
      id="loaderButton"
      mat-raised-button
      class="button_width"
      color="accent"
      (click)="onSubmit(true)"
    >
      Let's Go!
    </button>
  </div>
</div>
