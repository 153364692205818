<div>
  <mat-radio-group class="radioButton-flex">
    <div class="cards" *ngFor="let techList of displaySolarBattery">
      <label>
        <yt-techradio-boxes
          [cardImage]="techList.techImage"
          [viewValue]="techList.viewValue"
          [default]="defaultValue"
          [value]="techList.value"
        ></yt-techradio-boxes>
        <mat-radio-button
          class="hidden_button absolute top-0"
          [value]="techList.value"
          (change)="onTechChange($event)"
        >
        </mat-radio-button>
      </label>
    </div>
  </mat-radio-group>
</div>

<div>
  <yt-battery
    *ngIf="defaultValue === 'battery'"
    [activeIcon]="activeIcon"
    [batteryDetails]="solarBatteryTechDetails.battery"
    (techSave)="emitTech($event)"
  ></yt-battery>
</div>

<div>
  <yt-portable-battery
    *ngIf="defaultValue === 'portableBattery'"
    [activeIcon]="activeIcon"
    [portableBatteryDetails]="solarBatteryTechDetails.portableBattery"
    (techSave)="emitTech($event)"
  ></yt-portable-battery>
</div>

<div>
  <yt-solar
    *ngIf="defaultValue === 'solar'"
    [activeIcon]="activeIcon"
    [solarDetails]="solarBatteryTechDetails.solar"
    (techSave)="emitTech($event)"
  ></yt-solar>
</div>

<div>
  <yt-community-solar
    *ngIf="defaultValue === 'communitySolar'"
    [activeIcon]="activeIcon"
    (techSave)="emitTech($event)"
  ></yt-community-solar>
</div>
