import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CleanChoicesComponent } from "./clean-choices.component";
import { CleanChoiceCardsComponent } from "./tech-cards/clean-choice-cards.component";
import { MatIconModule } from "@angular/material/icon";
import { CleanChoiceCardsInitial } from "./tech-cards/clean-choice-cards.init";
import { MatButtonModule } from "@angular/material/button";
import { InfoCardSimpleLowCostComponent } from "./info-card/simple-low-cost/simple-low-cost.component";
import { InfoCardTransportationComponent } from "./info-card/transportation/transportation.component";
import { InfoCardAppliancesComponent } from "./info-card/appliances/appliances.component";
import { StarRatingComponent } from "./info-card/star-rating/star-rating.component";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule, MatTooltipModule],
  exports: [CleanChoicesComponent],
  declarations: [
    CleanChoicesComponent,
    CleanChoiceCardsComponent,
    InfoCardSimpleLowCostComponent,
    InfoCardTransportationComponent,
    InfoCardAppliancesComponent,
    StarRatingComponent,
  ],
  providers: [CleanChoiceCardsInitial],
})
export class CleanChoicesModule {}
