<div class="tour-guide-backdrop"></div>
<div class="tour-guide-overlay">
  <div class="tour-guide" (window:resize)="checkSelector()">
    <div class="tour-guide__header header_display"></div>
    <div class="tour-guide__body pv3"></div>
    <div class="tour-guide__footer">
      <span>{{ currentStep + 1 }} of {{ totalStep }}</span>
      <button (click)="onNext()" *ngIf="!onLastStep">{{ buttonText }}</button>
      <div *ngIf="onLastStep" class="button_section">
        <button *ngIf="!isSUNW" (click)="openHomeSettings()">
          Personalize now
        </button>
        <span (click)="ngOnDestroy()" *ngIf="!isSUNW">{{ buttonText }}</span>
        <button (click)="ngOnDestroy()" *ngIf="isSUNW">Done</button>
      </div>
    </div>
  </div>
</div>
