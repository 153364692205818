<div>
  <div class="yt_text">
    <div class="heading">Community Solar</div>
    <div
      class="paragraph"
      [innerHTML]="
        'homeOwenerDashboard.learnMore.communitySolar.paragraph' | translate
      "
    ></div>
  </div>

  <div class="container" [formGroup]="communitySolarForm">
    <div class="label_container">
      <div class="image-box">
        <div class="icon_section">
          <mat-icon class="radio-icons mat-symbol-oulined">
            check_circle
          </mat-icon>
        </div>
        <div class="yt__font pl0">ALREADY HAVE</div>
      </div>

      <div class="image-box">
        <div class="icon_section">
          <mat-icon class="radio-icons mat-symbol-oulined"> favorite </mat-icon>
        </div>
        <div class="yt__font pl0">ADD TO FAVORITES</div>
      </div>

    </div>

    <div class="tech_container">
      <div class="techLabel">Community Solar</div>
      <mat-radio-group class="radio_wrapper" formControlName="communitySolar">
        <mat-radio-button value="yes"></mat-radio-button>
        <mat-radio-button value="yesPlan"></mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="yt_form-button w-100">
    <button
      mat-raised-button
      color="accent"
      (click)="onSave()"
      class="fr yt__mb-12 w-100 w-30-ns hover-save"
    >
      SAVE
    </button>
  </div>
</div>
