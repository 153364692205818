import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "yt-confirm-popup",
  templateUrl: "./confirm-popup.component.html",
  styleUrls: ["./confirm-popup.component.scss"],
})
export class ConfirmPopupComponent implements OnInit {
  @Input() header: string;
  @Input() subheader: string;
  @Input() primaryButton: string;
  @Input() secondaryButton: string;
  @Output() selectedNo: EventEmitter<boolean> = new EventEmitter();
  @Output() selectedYes: EventEmitter<boolean> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  onSelectNo(): void {
    this.selectedNo.emit();
  }

  onSelectYes(): void {
    this.selectedYes.emit();
  }
}
