<mat-card
  class="example-card pointer"
  [ngClass]="{ 'yt__mat-border': selectedValue }"
  [ngStyle]="{ 'padding-bottom': !subValuePadding ? '24px' : '45px' }"
>
  <div *ngIf="image">
    <img
      mat-card-image
      [src]="image"
      [alt]="displayValue"
      height="95"
      loading="lazy"
      class="yt__mat-card-img"
    />
  </div>
  <div class="flex justify-center flex-column tc">
    <div class="yt__f4 fw6 nowrap">{{ displayValue }}</div>
    <div class="yt__fontsize yt__text-mv">{{ subDisplayValue }}</div>
  </div>
</mat-card>
