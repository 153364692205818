<div id="assumptions" *ngIf="assumptionDetails">
  <div
    class="assumption_card"
    [ngClass]="{ assumption__mt0: showHelpMeChoose === false }"
  >
    <div class="w-100 assumption_header">ASSUMPTIONS</div>
    <div
      class="w-100 assumption_row"
      *ngIf="assumptionDetails.naturalGasEscalationRate"
    >
      <div class="assumption_label">Natural gas escalation rate*</div>
      <div class="assumption_value">
        {{ assumptionDetails.naturalGasEscalationRate }}%
      </div>
    </div>
    <div
      class="w-100 assumption_row"
      *ngIf="assumptionDetails.gasolinePricePerGal"
    >
      <div class="assumption_label">Gasoline price per gal</div>
      <div class="assumption_value">
        ${{ assumptionDetails.gasolinePricePerGal }}
      </div>
    </div>
    <div
      class="w-100 assumption_row"
      *ngIf="assumptionDetails.gasolineVehicleMPG"
    >
      <div class="assumption_label">Gasoline vehicle MPG</div>
      <div class="assumption_value">
        {{ assumptionDetails.gasolineVehicleMPG }}
      </div>
    </div>
    <div
      class="w-100 assumption_row"
      *ngIf="assumptionDetails.gasolineEscalationRate"
    >
      <div class="assumption_label">Gasoline escalation rate*</div>
      <div class="assumption_value">
        {{ assumptionDetails.gasolineEscalationRate }}%
      </div>
    </div>
    <div
      class="w-100 assumption_row"
      *ngIf="assumptionDetails.electricityEscalationRate"
    >
      <div class="assumption_label">Electricity escalation rate*</div>
      <div class="assumption_value">
        {{ assumptionDetails.electricityEscalationRate }}%
      </div>
    </div>
    <div
      class="w-100 assumption_row"
      *ngIf="assumptionDetails.propaneEscalationRate"
    >
      <div class="assumption_label">Propane escalation rate*</div>
      <div class="assumption_value">
        {{ assumptionDetails.propaneEscalationRate }}%
      </div>
    </div>
    <div
      class="w-100 assumption_row"
      *ngIf="assumptionDetails.fuelOilEscalationRate"
    >
      <div class="assumption_label">Fuel oil escalation rate*</div>
      <div class="assumption_value">
        {{ assumptionDetails.fuelOilEscalationRate }}%
      </div>
    </div>
    <div class="w-100 assumption_row" *ngIf="assumptionDetails.salesTax">
      <div class="assumption_label">Sales Tax</div>
      <div class="assumption_value">{{ assumptionDetails.salesTax }}%</div>
    </div>
    <ng-container *ngIf="showLoanLease">
      <div
        class="w-100 assumption_row"
        *ngIf="assumptionDetails.downPaymentPercent"
      >
        <div class="assumption_label">Down payment</div>
        <div class="assumption_value">
          {{ assumptionDetails.downPaymentPercent }}%
        </div>
      </div>
      <div class="w-100 assumption_row" *ngIf="assumptionDetails.rate">
        <div class="assumption_label">{{ assumptionLabel }} interest rate</div>
        <div class="assumption_value">{{ assumptionDetails.rate }}%</div>
      </div>
      <div class="w-100 assumption_row" *ngIf="assumptionDetails.term">
        <div class="assumption_label">{{ assumptionLabel }} term</div>
        <div class="assumption_value">{{ assumptionDetails.term }} years</div>
      </div>
    </ng-container>
    <div class="w-100 assumption_row annual_rate">
      * Annual rates applied only 10-year and 20-year analyses
    </div>
  </div>
</div>
