<ng-container *ngFor="let item of techList; let i = index">
  <div *ngIf="item.showTech && !item.error2" class="tech_card">
    <div class="top_box">
      <div class="image_section">
        <img [src]="item.img" width="100%" height="100%" />
        <div
          class="leaf_section"
          [matTooltip]="getLeafTooltip(item.techParameter, item.carbonSavings)"
        >
          <div *ngFor="let leaf of leafs; let ind = index">
            <mat-icon
              class="leaf_icon"
              [ngClass]="{
                leaf_checked: ind < item.leaves
              }"
              >eco</mat-icon
            >
          </div>
        </div>
      </div>
      <div class="info_section">
        <div class="tech_text">
          <div
            class="tech_name_label"
            [ngClass]="{ wrap_unset: item.techParameter === 'everGreen' }"
          >
            <span
              *ngIf="item.systemSize"
              class="system_size"
              [ngClass]="{ 'system_size red_border_bottom': item.panelStatus }"
              [matTooltip]="
                item.panelStatus
                  ? 'Your estimate for system size is larger than average. A site survey can verify that sufficient roof space is available.'
                  : ''
              "
              >{{ item.systemSize }}kW
            </span>
            <span title="{{ item.techName }}">{{ item.techName }}</span>
          </div>
          <span class="tech_net_cost"
            >{{ item.netCostLabel }}
            <span *ngIf="isString(item.priceAfterIncentives)"
              >${{ item.priceAfterIncentives }}</span
            >
            <span *ngIf="!isString(item.priceAfterIncentives)"
              >${{ item.priceAfterIncentives | number }}</span
            >
          </span>
          <span
            class="tech_net_cost rebates_text_color"
            *ngIf="item.rebateStatus"
            (click)="incentivesClick(item.techParameter)"
            >Incentive(s) Available</span
          >
        </div>
        <div class="tech_text">
          <div class="savings_text">
            <div
              class="savings_value"
              [ngClass]="{ value_color: item.isNegative }"
            >
              {{ item.dollarSavings | currency : "$" : "symbol" : "1.0-0" }}
            </div>
            <span
              class="tech_net_cost"
              [ngClass]="{
                negativeTooltip: item.isNegative
              }"
              [matTooltip]="item.isNegative ? item.negativeTooltip : ''"
              >{{ getTimeFrame(timeFrame) }} savings
              <span *ngIf="item.techParameter === 'solar' && !item.error2">
                <mat-icon class="toolinfo_icon" (click)="onSavings()"
                  >help_outline</mat-icon
                >
              </span></span
            >
          </div>
        </div>
      </div>

      <div class="lm_section">
        <div class="icon_section">
          <mat-icon
            *ngIf="item.own === 'yesPlan'"
            class="favorite_icon"
            (click)="onRemoveTech(item)"
            matTooltip="Remove from favorites"
            [ngClass]="{ isDisabled: favoriteLoading === item.techParameter }"
          >
            favorite
          </mat-icon>
          <mat-icon
            class="favorite_icon"
            *ngIf="item.own !== 'yesPlan'"
            (click)="onFavorites(item, i)"
            matTooltip="Add to favorites"
            [ngClass]="{ isDisabled: favoriteLoading === item.techParameter }"
            >favorite_border</mat-icon
          >
          <mat-icon
            class="check_circle"
            (click)="onAlreadyHave(item, i)"
            matTooltip="Already have this? Click here"
            [ngClass]="{
              isDisabled: alreadyHaveLoading === item.techParameter
            }"
          >
            check_circle_outline
          </mat-icon>
        </div>
        <button
          mat-raised-button
          class="shopNow_btn"
          color="accent"
          *ngIf="item.shopNow"
          (click)="onShopNow(item)"
        >
          SHOP NOW
        </button>
        <button mat-raised-button class="lm_btn" (click)="onLearnMore(item)">
          {{
            "homeOwenerDashboard.dashboard.recommendation.lmButton" | translate
          }}
        </button>
      </div>
    </div>
    <div class="tech_info">{{ translateText(item) }}</div>
  </div>
</ng-container>
<div class="info_text" *ngIf="checkTechListLength()">
  No recommendations to show
</div>
