import { Component, ViewChild, ElementRef, Input } from "@angular/core";
import * as Highcharts from "highcharts";
import { HttpClient } from "@angular/common/http";
import {
  BillChartModel,
  CarbonOverviewModel,
} from "../energy-usage-overview.model";
import { AuthenticationService } from "../../../auth/auth.service";
import { Router } from "@angular/router";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";

@Component({
  selector: "yt-carbon-overview",
  templateUrl: "carbon-overview.component.html",
  styleUrls: ["../common-overview.scss"],
})
export class CarbonOverviewComponent {
  electricity: boolean;
  gas: boolean;
  showElectricityChart: boolean;
  showGasChart: boolean;
  showElectricityChartError: boolean;
  showGasChartError: boolean;
  selected = "Electricity";
  electricEmissionChartModel: BillChartModel[];
  gasEmissionChartModel: BillChartModel[];
  carbonBillOverviewUrl: string;
  chartOptions1: Highcharts.Options = {};
  highCharts1 = Highcharts;
  totalBill: string;
  annualElectricEmission: string;
  annualGasEmission: string;
  notAuthorizedStatus: boolean;
  gasNotAuthorizedStatus: boolean;
  electricAuthorizedStatus: boolean;
  authorizedStatus: boolean;
  loader: boolean = true;
  vendor: string;
  @Input() usageChart: string;
  @Input() usageChartHover: string;
  @ViewChild("container", { read: ElementRef }) container: ElementRef;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    public restAPIService: DashboardAPIService,
    private authenticationService: AuthenticationService
  ) {
    this.carbonBillOverviewUrl =
      restAPIService.API_END_POINT +
      "ho/dashboard/carbonEmissionOverview/" +
      "{consumerId}";
    this.carbonBillOverviewUrl = this.carbonBillOverviewUrl.replace(
      "{consumerId}",
      this.authenticationService.jwtTokenInfo.userName
    );
  }

  ngOnInit() {
    this.vendor = this.restAPIService.solarVendorDetails.code;
    this.electricity = true;
    this.showElectricityChart = true;
    this.getCarbonOverview(this.selected);
  }

  getCarbonOverview(val) {
    this.httpClient
      .get<CarbonOverviewModel>(this.carbonBillOverviewUrl)
      .subscribe((response) => {
        this.loader = false;
        this.totalBill = response.totalEmissionValue;
        this.annualElectricEmission = response.annualElectricEmission;
        this.annualGasEmission = response.annualGasEmission;
        this.electricEmissionChartModel = response.electricEmissionChart;
        this.gasEmissionChartModel = response.gasEmissionChart;
        this.notAuthorizedStatus =
          this.totalBill === "Not Authorized" ? true : false;
        this.gasNotAuthorizedStatus =
          this.annualGasEmission === "Not Available" ? true : false;
        this.electricAuthorizedStatus =
          this.annualElectricEmission === "Not Authorized" ? true : false;
        this.authorizedStatus =
          this.annualElectricEmission === "0" ? true : false;
        if (val == "Electricity") {
          if (response.showElectricChart) {
            this.showElectricityChart = true;
            this.showElectricityChartError = false;
            let electricityCoordinatesValue: number[] = [],
              electricityCoordinates: string[] = [],
              startDate: string[] = [],
              endDate: string[] = [];
            this.electricEmissionChartModel.forEach((element) => {
              electricityCoordinates.push(element.month);
              electricityCoordinatesValue.push(element.emission!);
              startDate.push(element.startDate);
              endDate.push(element.endDate);
            });
            this.updateChartOptions(
              electricityCoordinatesValue,
              electricityCoordinates,
              startDate,
              endDate
            );
          } else {
            this.showElectricityChart = false;
            this.showElectricityChartError = true;
          }
        } else {
          if (response.showGasChart) {
            this.showGasChart = true;
            this.showGasChartError = false;
            let gasCoordinatesValue: number[] = [],
              gasCoordinates: string[] = [],
              startDate: string[] = [],
              endDate: string[] = [];
            this.gasEmissionChartModel.forEach((element) => {
              gasCoordinates.push(element.month);
              gasCoordinatesValue.push(element.emission!);
              startDate.push(element.startDate);
              endDate.push(element.endDate);
            });
            this.updateChartOptions(
              gasCoordinatesValue,
              gasCoordinates,
              startDate,
              endDate
            );
          } else {
            this.showGasChart = false;
            this.showGasChartError = true;
          }
        }
      });
  }

  onElectricity() {
    this.electricity = true;
    this.gas = false;
    this.showGasChart = false;
    this.showElectricityChart = true;
  }

  onGas() {
    this.electricity = false;
    this.gas = true;
    this.showGasChart = true;
    this.showElectricityChart = false;
  }

  updateChartOptions(coordinatesValue, coordinates, startDate, endDate) {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
      },
    });

    const style = {
      color: "#333333",
      fontSize: "14px",
      fontFamily: "Open sans",
      lineHeight: "17px",
    };

    this.chartOptions1 = {
      credits: {
        enabled: false,
      },
      chart: {
        type: "column",
      },
      title: {
        text: undefined,
      },
      xAxis: {
        categories: coordinates,
        gridLineWidth: 0,
        lineWidth: 1,
        tickWidth: 0,
        labels: {
          style,
        },
      },
      yAxis: {
        tickAmount: 8,
        gridLineWidth: 0,
        lineWidth: 1,
        tickWidth: 0,
        title: {
          text: "lbs",
          style,
        },
        labels: {
          formatter: function (this) {
            return this.value.toString();
          },
          style,
        },
      },
      tooltip: {
        formatter: function (this) {
          return (
            startDate[this.point.index] +
            " - " +
            endDate[this.point.index] +
            ": <b>" +
            this.point.y +
            " lbs </b>"
          );
        },
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: false,
          },
        },
        series: {
          color: this.usageChart,
          animation: {
            duration: 1500,
          },
          states: {
            hover: {
              color: this.usageChartHover,
            },
          },
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              chart: {
                className: "small-chart",
              },
            },
          },
        ],
      },
      series: [
        {
          showInLegend: false,
          data: coordinatesValue,
          type: "column",
        },
      ],
    };
    Highcharts.chart(this.container.nativeElement, this.chartOptions1);
  }

  onPopUp(): void {
    const overlayElement = document.getElementById("overlay");
    if (overlayElement) overlayElement.style.display = "block";
  }

  offPopUp() {
    const overlayElement = document.getElementById("overlay");
    if (overlayElement) overlayElement.style.display = "none";
  }

  onEnergyUsageSetting() {
    this.router.navigate(["/dashboard/overview"]);
    this.restAPIService.energyInfoData = true;
  }
}
