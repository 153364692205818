import { NgModule, Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { TechKeys, TechName } from "./tech.enum";
@Pipe({
  name: "techName",
})
export class TechNamePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}
  public transform(value: string): string {
    switch (value) {
      case TechKeys.THERMOSTAT:
        return TechName.THERMOSTAT;
      case TechKeys.WEATHER_STRIPPING:
        return TechName.WEATHER_STRIPPING;
      case TechKeys.AERATORS_SHOWER_HEADS:
        return TechName.AERATORS_SHOWER_HEADS;
      case TechKeys.LM_AERATORS_SHOWER_HEADS:
        return TechName.LM_AERATORS_SHOWER_HEADS;
      case TechKeys.POWER_STRIPS:
        return TechName.POWER_STRIPS;
      case TechKeys.LED_BULBS:
        return TechName.LED_BULBS;
      case TechKeys.ELECTRIC_VEHICLE:
        return TechName.ELECTRIC_VEHICLE;
      case TechKeys.ELECTRIC_VEHICLE_1:
        return TechName.ELECTRIC_VEHICLE_1;
      case TechKeys.ELECTRIC_VEHICLE_2:
        return TechName.ELECTRIC_VEHICLE_2;
      case TechKeys.ELECTRIC_BIKE:
        return TechName.ELECTRIC_BIKE;
      case TechKeys.EV_CHARGERS:
        return TechName.EV_CHARGERS;
      case TechKeys.SOLAR:
        return TechName.SOLAR;
      case TechKeys.BATTERY:
        return TechName.BATTERY;
      case TechKeys.P_BATTERY:
        return TechName.P_BATTERY;
      case TechKeys.HEATING_COOLING:
        return TechName.HEATING_COOLING;
      case TechKeys.HP_HEATING_COOLING:
        return TechName.HP_HEATING_COOLING;
      case TechKeys.WATER_HEATER:
        return TechName.WATER_HEATER;
      case TechKeys.HP_WATER_HEATER:
        return TechName.HP_WATER_HEATER;
      case TechKeys.CLOTHES_DRYER:
        return TechName.CLOTHES_DRYER;
      case TechKeys.HP_CLOTHES_DRYER:
        return TechName.HP_CLOTHES_DRYER;
      case TechKeys.IC:
        return TechName.IC;
      case TechKeys.COOKTOP:
        return TechName.COOKTOP;
      case TechKeys.P_COOKTOP:
        return TechName.P_COOKTOP;
      case TechKeys.P_I_COOKTOP:
        return TechName.P_I_COOKTOP;
      // Categories
      case TechKeys.APPLIANCES:
        return TechName.APPLIANCES;
      case TechKeys.TRANSPORTATION:
        return TechName.TRANSPORTATION;
      case TechKeys.SOLAR_BATTERY:
        return TechName.SOLAR_BATTERY;
      case TechKeys.SIMPLE_LOW_COST:
        return TechName.SIMPLE_LOW_COST;
      default:
        return value;
    }
  }
}

@NgModule({
  declarations: [TechNamePipe],
  exports: [TechNamePipe],
})
export class TechNamePipeModule {}
