import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppService } from "../app.service";
import { RebateDetailsModel } from "../homeowner-dashboard/learn-more/learn-more.model";
import { ElectricityProvidersModel } from "../homeowner-info/homeowner-info.model";
import { LogoModel, RoofTypesModel } from "../rest-api/rest-api.model";
import { RestAPIService } from "../rest-api/rest-api.service";
import {
  IncentivesDetailsRequest,
  InfoRequest,
  InfoResponse,
  TechIncentivesModel,
  TechnologyIncentivesModel,
  TechnologyModel,
} from "./incentives.model";
import { IncentivesAPIService } from "./incentives.service";
import { environment } from "../../environments/environment";
import { Title } from "@angular/platform-browser";
import { AnalyticsModel } from "../rest-api/dashboard/dashboard-api.model";
import { DashboardAPIService } from "../rest-api/dashboard/dashboard-api.service";
import { MatAccordion } from "@angular/material/expansion";

@Component({
  selector: "yt-incentives",
  templateUrl: "./incentives.component.html",
  styleUrls: ["./incentives.component.scss"],
})
export class IncentivesComponent implements OnInit {
  incentivesForm: FormGroup;
  logo: LogoModel;
  vendorCode: string;
  loader: boolean = true;
  buildingTypeList: RoofTypesModel[];
  zipCodeList: string[];
  electricUtilityNameList: ElectricityProvidersModel[];
  utilityId: number;
  outOfRange: boolean;
  technologyIncentives: TechnologyModel;
  technologyIncentivesList: [string, TechIncentivesModel];
  showSpecialCriteria: boolean = false;
  totalIncentives: string;
  haveEmptyRebateDetails: boolean;
  vendorLink: string;
  haveGetStartedButton: boolean;
  header: string;
  subHeader: string;
  emptyProviderList: boolean = true;
  invalidAddress: boolean = false;
  @ViewChild("searchButton", { read: ElementRef })
  searchButtonElement: ElementRef;
  logoStatus: boolean;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(
    private fb: FormBuilder,
    private incentivesAPIService: IncentivesAPIService,
    private restAPI: RestAPIService,
    private dashboardAPI: DashboardAPIService,
    private appService: AppService,
    private titleService: Title
  ) {
    this.incentivesForm = this.fb.group({
      address: [""],
      country: [""],
      state: [""],
      zipCode: [""],
      buildingType: ["", Validators.required],
      electricUtilityName: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.header = "Your Incentives";
    this.subHeader =
      "Know the savings you are eligible for. Check the incentives applicable to your house location and building type.";
    this.vendorCode = this.appService.getVendorName();
    if (this.restAPI.logo) {
      this.logo = this.restAPI.logo;
      this.vendorLink = this.restAPI.getSolarVendorInformation().vendorLink;
      this.logoStatus = !!this.restAPI.logo.customer;
    } else {
      this.appService.solarVendorModel$.subscribe((data) => {
        this.logo = data.logo;
        this.vendorLink = data.vendorLink;
        this.logoStatus = !!data.logo.customer;
      });
    }
    this.titleService.setTitle(
      `Rebates & Incentives | ${
        this.appService.vendorsList[this.vendorCode].title
      }`
    );
    this.incentivesAPIService
      .getBuildingTypeList()
      .subscribe((response: RoofTypesModel[]) => {
        this.buildingTypeList = response;
        this.incentivesForm.get("electricUtilityName")?.disable();
        this.loader = false;
      });
    this.getGoogleMapAddress();
  }

  onSelectUtilityProvider(event): void {
    this.utilityId = event.value;
  }

  onSave(): void {
    this.searchButtonElement.nativeElement.innerHTML =
      'Searching...  &nbsp;<i class="fa fa-spinner fa-spin"></i>';
    this.incentiveAnalytics("search");
    const request: IncentivesDetailsRequest = {
      incentivesInfo: {
        state: this.incentivesForm.get("state")?.value,
        zipCode: this.incentivesForm.get("zipCode")?.value,
        electricUtilityCode: this.utilityId,
        buildingType: this.incentivesForm.get("buildingType")?.value,
        vendorCode: this.vendorCode,
      },
    };
    this.incentivesAPIService.getIncentivesDetails(request).subscribe(
      (response: TechnologyIncentivesModel) => {
        this.haveGetStartedButton = true;
        this.searchButtonElement.nativeElement.innerHTML = "SEARCH";
        this.updateTechnologyIncentives(response.technologyIncentives);
      },
      (error) => {
        this.searchButtonElement.nativeElement.innerHTML = "SEARCH";
        if (error.status === 406) {
          this.haveEmptyRebateDetails = true;
          this.haveGetStartedButton = true;
        }
      }
    );
  }

  updateTechnologyIncentives(technologyIncentives: TechnologyModel): void {
    this.technologyIncentives = technologyIncentives;
    this.technologyIncentivesList = Object.entries(
      this.technologyIncentives
    ) as unknown as [string, TechIncentivesModel];
    const specialCriteriaList = this.technologyIncentivesList.map(
      (tech: string | TechIncentivesModel) =>
        tech[1].rebateDetails.some(
          (rebate: RebateDetailsModel) => rebate.specialCriteria === "Yes"
        )
    );
    const emptyRebateDetails = this.technologyIncentivesList.map(
      (tech: string | TechIncentivesModel) => tech[1].rebateDetails.length === 0
    );
    this.haveEmptyRebateDetails = emptyRebateDetails.every(
      (status) => status === true
    );
    this.showSpecialCriteria = specialCriteriaList.some((item) => item);
    this.loader = false;
  }

  closeOverlay(): void {
    window.parent.postMessage("close", "*");
  }

  onGetStarted(): void {
    const isProd = environment.production;
    this.incentiveAnalytics("getStarted");
    if (this.vendorCode === "YSLR") {
      window.open(`${this.vendorLink}sign-up/?vendor=YSLR`, "_blank");
    } else if (isProd) {
      if (this.vendorCode === "SUNW") {
        window.open(`${this.vendorLink}sign-up`, "_blank");
      } else {
        window.open(`${this.vendorLink}register`, "_blank");
      }
    } else {
      if (this.vendorCode === "SUNW") {
        window.open(`${this.vendorLink}sign-up?vendor=SUNW`, "_blank");
      } else {
        window.open(
          `${this.vendorLink}register?vendor=${this.vendorCode}`,
          "_blank"
        );
      }
    }
  }

  onClickLogo(link?: string) {
    if (link) {
      window.open(link, "_blank");
    } else {
      window.open(
        !this.logo.customer ? this.logo.href : this.logo.customer.href
      );
    }
  }

  onEnergyCenter() {
    window.open("https://www.yellowtin.com/yt_faq_preview/", "_blank");
  }

  onContact() {
    window.open("https://www.yellowtin.com/yt-contact-us", "_blank");
  }

  incentiveAnalytics(detail: string): void {
    const details: AnalyticsModel = {
      incentiveAnalytics: {
        detail,
        vendorCode: this.vendorCode,
      },
    };
    this.dashboardAPI.getAnalyticsAPI(details).subscribe();
  }

  getGoogleMapAddress(): void {
    const input = <HTMLInputElement>document.getElementById("placeAddress");
    const places = new google.maps.places.Autocomplete(input);
    google.maps.event.addListener(places, "place_changed", () => {
      const result = places.getPlace();
      const address = result.address_components;
      if (address) {
        address.forEach((component) => {
          const type = component.types[0];
          const name = component.long_name;
          if (type === "postal_code") {
            this.incentivesForm.controls["zipCode"].setValue(name);
          }
          if (type === "administrative_area_level_1") {
            this.incentivesForm.controls["state"].setValue(name);
          }
          if (type === "country") {
            this.incentivesForm.controls["country"].setValue(
              component.short_name
            );
          }
        });
        this.invalidAddress = false;
        this.onZipCode();
      } else {
        this.invalidAddress = true;
      }
    });
  }

  onValidateAddress(address: string) {
    this.invalidAddress = true;
  }

  onZipCode(): void {
    if (this.incentivesForm.get("electricUtilityName")?.value) {
      this.incentivesForm.get("electricUtilityName")?.setValue("");
    }
    const request: InfoRequest = {
      incentivesInfo: {
        state: this.incentivesForm.get("state")?.value,
        zipCode: +this.incentivesForm.get("zipCode")?.value,
        country: this.incentivesForm.get("country")?.value,
      },
    };
    if (!this.invalidAddress) {
      this.incentivesAPIService.getIncentivesInfo(request).subscribe(
        (data: InfoResponse) => {
          this.electricUtilityNameList = data.electricUtilityName!;
          this.outOfRange = false;
          if (this.electricUtilityNameList.length === 1) {
            this.loader = false;
            this.emptyProviderList = false;
            this.incentivesForm.get("electricUtilityName")?.enable();
            this.utilityId = this.electricUtilityNameList[0].utilityId;
            this.incentivesForm.controls["electricUtilityName"].setValue(
              this.utilityId
            );
            document.getElementById("buildingType")?.focus();
          } else if (!this.electricUtilityNameList.length) {
            this.loader = false;
            this.emptyProviderList = true;
            this.electricUtilityNameList = [];
            this.incentivesForm.get("electricUtilityName")?.disable();
          } else {
            this.loader = false;
            this.emptyProviderList = false;
            this.incentivesForm.get("electricUtilityName")?.enable();
            document.getElementById("utilityProvider")?.focus();
          }
        },
        (error) => {
          this.incentivesForm.get("electricUtilityName")?.disable();
          if (error.error.message === "Zip Code Not Supported") {
            this.outOfRange = true;
            document.getElementById("placeAddress")?.focus();
          }
        }
      );
    }
  }

  isZipCodeValid(required: boolean): boolean {
    const isEmpty = this.incentivesForm.controls["zipCode"].value === "";
    if (required)
      return isEmpty && this.incentivesForm.controls["zipCode"].touched;
    return isEmpty;
  }

  isFieldValid(field: string, required: boolean): boolean {
    const isEmpty = this.incentivesForm.controls[field].value === "";
    if (required) return isEmpty && this.incentivesForm.controls[field].touched;
    return isEmpty;
  }
}
