<div class="w-100 margin__bottom energy-choices" [formGroup]="acForm">
  <div>
    <div formArrayName="ac">
      <div *ngFor="let address of acArray.controls; let i = index">
        <div
          [ngStyle]="{ 'padding-top': i === 1 ? '30px' : '40px' }"
          [formGroupName]="i"
        >
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
            [expanded]="true"
          >
            <mat-expansion-panel-header>
              <mat-panel-title class="yt__panel-header"
                >Air Conditioner #{{ i + 1 }}</mat-panel-title
              >
            </mat-expansion-panel-header>
            <div class="cf">
              <div class="w-100">
                <div
                  class="w-100 w-50-l fl"
                  [ngClass]="{
                    yt_height_ac: i === 1 && !disableBoughtAndDatePurchased[1],
                    yt_height_ac_remove:
                      i === 1 && disableBoughtAndDatePurchased[i]
                  }"
                >
                  <div class="radio-buttons">
                    <mat-radio-group
                      class="example-radio-group"
                      (change)="onSelectOfQuestion(i)"
                      formControlName="own"
                    >
                      <div class="yt-Btn-Font">
                        Tell us about your air conditioner
                      </div>
                      <div class="radio_text">
                        <div class="dib yt_ques-padding">
                          <mat-radio-button value="yes">
                            <span class="yt_option">Already have one</span>
                          </mat-radio-button>
                        </div>
                        <div class="dib yt_answer-padding-2">
                          <mat-radio-button value="yesPlan">
                            <span class="yt_option"
                              >Interested in getting one</span
                            >
                          </mat-radio-button>
                        </div>
                        <div class="dib yt_answer-padding-2">
                          <mat-radio-button value="no">
                            <span class="yt_option">Not interested</span>
                          </mat-radio-button>
                        </div>
                      </div>
                    </mat-radio-group>
                  </div>
                  <div
                    class="radio-buttons pointer remove-ev"
                    *ngIf="i === 1"
                    (click)="removeAC()"
                  >
                    Remove Air Conditioner #2
                  </div>
                </div>

                <div
                  class="w-100 w-100-m w-50-l yt_paddingtop-42 fl yt_border-left"
                >
                  <div
                    class="yt__f4 yt__pt-20 yt__pt-45 yt_pb-7"
                    [ngStyle]="{
                      color: getValue('own', i) === 'no' ? '#c7c7c7' : '#333333'
                    }"
                  >
                    Type of air conditioner
                  </div>
                  <div class="yt__pt0-7">
                    <mat-form-field
                      appearance="outline"
                      floatLabel="auto"
                      hideRequiredMarker="true"
                      [ngClass]="{
                        'disable-field': getValue('own', i) === 'no'
                      }"
                    >
                      <mat-select
                        placeholder="Type"
                        formControlName="type"
                        required
                      >
                        <mat-option
                          *ngFor="let type of acTypes"
                          [value]="type.value"
                        >
                          {{ type.viewValue }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="isRequired('type', i)">
                        Required!
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div>
                    <div
                      class="yt__f4 yt_pt1-75"
                      [ngStyle]="{
                        color:
                          getValue('own', i) === 'no' ? '#c7c7c7' : '#333333'
                      }"
                    >
                      Total days used in a year
                    </div>
                    <div class="yt__pt0-7">
                      <mat-form-field
                        appearance="outline"
                        floatLabel="auto"
                        hideRequiredMarker="true"
                        [ngClass]="{
                          'disable-field': getValue('own', i) === 'no'
                        }"
                      >
                        <input
                          matInput
                          class="quantity"
                          type="text"
                          placeholder="30"
                          formControlName="days"
                          required
                          maxlength="4"
                        />
                        <mat-error *ngIf="isFieldValid('days', i)">
                          Please enter value between 1-366
                        </mat-error>
                        <mat-error *ngIf="isRequired('days', i)">
                          Required!
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div>
                    <div
                      class="yt__f4 yt_pt1-75"
                      [ngStyle]="{
                        color:
                          getValue('own', i) === 'no' ? '#c7c7c7' : '#333333'
                      }"
                    >
                      Hours used during those days
                    </div>
                    <div
                      class="yt__pt0-7"
                      [ngClass]="{
                        'yt__pb-20': !disableBoughtAndDatePurchased[i]
                      }"
                    >
                      <mat-form-field
                        appearance="outline"
                        floatLabel="auto"
                        hideRequiredMarker="true"
                        [ngClass]="{
                          'disable-field': getValue('own', i) === 'no'
                        }"
                      >
                        <input
                          matInput
                          class="quantity"
                          type="text"
                          placeholder="1"
                          formControlName="hrs"
                          required
                          maxlength="3"
                        />
                        <mat-error *ngIf="isFieldValid('hrs', i)">
                          Please enter value between 1-24
                        </mat-error>
                        <mat-error *ngIf="isRequired('hrs', i)">
                          Required!
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div *ngIf="disableBoughtAndDatePurchased[i]">
                    <article class="cf">
                      <div
                        class="fl w-60-c yt__f4 yt_pt1-75"
                        [ngStyle]="{
                          color:
                            getValue('own', i) === 'yes' ? '#333333' : '#c7c7c7'
                        }"
                      >
                        Purchased month & year
                      </div>
                    </article>
                    <div class="yt__pb-20 yt__pt0-7">
                      <mat-form-field
                        appearance="outline"
                        floatLabel="auto"
                        hideRequiredMarker="true"
                        [ngClass]="{
                          'disable-field':
                            getValue('own', i) === 'no' ||
                            getValue('own', i) === 'yesPlan'
                        }"
                      >
                        <mat-select
                          matInput
                          placeholder="Select"
                          formControlName="datePurchased"
                          required
                        >
                          <mat-option
                            *ngFor="let month of monthList"
                            [value]="month"
                            >{{ month | titlecase }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <div
                        class="cb w-100 yt_error-message"
                        *ngIf="isDatePurchasedSelected(i)"
                      >
                        Required!
                      </div>
                    </div>
                  </div>
                  <div
                    class="remove-ev2 pointer"
                    *ngIf="i === 1"
                    (click)="removeAC()"
                  >
                    Remove Air Conditioner #2
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </div>
      </div>
    </div>
  </div>

  <div
    class="w-100 yt__add-ev-btn"
    [ngStyle]="{ 'padding-top': showAddAC ? '30px' : '0px' }"
    *ngIf="2 > acArray.controls.length"
  >
    <button
      *ngIf="showAddAC"
      mat-raised-button
      class="tc w-100 b"
      (click)="onAddAnotherAC()"
    >
      + Add Air Conditioner #2
    </button>
  </div>
  <div class="pt_30 w-100">
    <button
      mat-raised-button
      color="accent"
      (click)="onSaveAC()"
      [disabled]="acForm.invalid || isDatePurchasedEmpty()"
      class="fr w-100 yt__mb-12"
    >
      SAVE
    </button>
  </div>
</div>
