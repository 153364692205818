<ng-container>
  <yt-top-nav
    [routeInfo]="routeInfo"
    [hideSidenav]="hideSidenav"
    [smdLink]="smdLink"
    [logo]="logoModel"
    [jwtToken]="jwtTokenInfo"
    [address]="address"
    [name]="name"
    [settinginfo]="lifeStyleModel"
    [vendorCode]="code"
    [language]="languageTranslator"
  >
    <ng-content select="[content]" class="parent-div"></ng-content>
    <yt-dashboard-footer class="footer-container"></yt-dashboard-footer>
  </yt-top-nav>
</ng-container>
