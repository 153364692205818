import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  DialogOverviewComponent,
  DialogOverview1Component,
} from "./dialog-overview.component";
import { MatDialogModule } from "@angular/material/dialog";
import { PopupLoaderModule } from "../popup-loader/index";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { DialogOverviewService } from "./dialog-overview.service";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    PopupLoaderModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
  ],
  declarations: [DialogOverviewComponent, DialogOverview1Component],
  exports: [DialogOverview1Component, DialogOverviewComponent],
  providers: [DialogOverviewService],
})
export class DialogOverviewModule {}
