<div class="dialogBox-container">
  <div *ngIf="Yellowtin">
    <div class="yt_header yt_padding tc fw6">About YellowTin</div>
    <div class="yt_padding">
      <p class="f4 a" *ngIf="yellowtin">
        <a
          href="https://www.yellowtin.com"
          target="_blank"
          class="link link_text pointer yt__outline-none"
          >YellowTin</a
        >
        is an authorized vendor for PG&E's Share My Data service. YellowTin is
        not provided access to log-ins, or any payment information and we do not
        store your PG&E credentials.
      </p>
      <p class="f4 a" *ngIf="solarInstallers">
        <a
          href="https://www.yellowtin.com"
          target="_blank"
          class="link link_text pointer yt__outline-none"
          >YellowTin</a
        >
        is our trusted technology partner and they are an authorized vendor for
        PG&E's Share My Data service. YellowTin is not provided access to
        log-ins, or any payment information and they do not store your PG&E
        credentials.
      </p>
    </div>
  </div>
  <div *ngIf="PGEPopUp">
    <div class="yt_header yt_padding tc fw6">PG&E Share My Data</div>
    <div class="yt_padding">
      <p class="f4 a">
        Share My Data is a streamlined, safe way for PG&E customers to share
        energy usage, billing and account information with authorized companies.
        For more information please refer to
        <a
          href="https://www.pge.com/en_US/residential/save-energy-money/analyze-your-usage/your-usage/view-and-share-your-data-with-smartmeter/reading-the-smartmeter/share-your-data/share-my-data-for-residential-customers.page"
          target="_blank"
          class="link link_text pointer yt__outline-none"
          >PG&E Share My Data</a
        >.
      </p>
    </div>
  </div>

  <div *ngIf="confirmPopup">
    <div class="yt_header yt_padding tc fw6">Confirmation</div>
    <p class="f4 yt_padding">
      You will be now redirected to the PG&E Share My Data platform for
      authorizing your energy data. You will be required to enter your PG&E
      username and password.
    </p>
    <div class="popup-button">
      <div class="yt_cancelButton dialogBox">
        <button
          mat-raised-button
          mat-dialog-close
          color="accent"
          class="cancel-button"
        >
          CANCEL
        </button>
      </div>
      <div class="yt_continueButton dialogBox">
        <button
          mat-raised-button
          color="accent"
          (click)="onSubmit()"
          class="continue-button"
        >
          CONTINUE
        </button>
      </div>
    </div>
  </div>
</div>
