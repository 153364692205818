import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatRadioChange } from "@angular/material/radio";
import { DropDownModel } from "../../../rest-api/rest-api.model";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "yt-roof-type-QA",
  templateUrl: "roof-type-QA.component.html",
  styleUrls: ["./roof-type-QA.component.scss"],
})
export class RoofTypeQAComponent implements OnInit, OnDestroy {
  @Input() selectedValue: string;
  @Input() roofTypeList: DropDownModel[];
  @Input() imagePath: string;
  @Input() header: string;
  @Output() roofTypeSelect = new EventEmitter<string>();

  buildingType: FormControl;
  private alive = true;

  constructor() {}

  ngOnInit() {
    this.buildingType = new FormControl(this.selectedValue);
    this.buildingType.valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {
        this.roofTypeSelect.emit(data);
      });
  }

  radioChange(event: MatRadioChange) {
    this.selectedValue = event.value;
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
