<div class="tc" id="loader">
  <div *ngIf="logo" class="padding_left flex items-center">
    <img
      [src]="logo.url"
      class="yltn_logo"
      alt="logo"
      *ngIf="logo.customer && !logo.customer.poweredByUrl"
      width="100px"
    />
    <img
      [src]="logo.customer ? logo.customer.url : logo.url"
      [alt]="vendorCode + ' logo'"
      class="logo_width"
      [ngClass]="{
        'work-logo_width': vendorCode === 'WORK',
        'wsp-logo_width': vendorCode === 'WSPP'
      }"
    />
  </div>
  <div class="padding_left flex items-center" *ngIf="!logo">
    <mat-spinner color="accent" [diameter]="50"></mat-spinner>
  </div>
  <div class="mt6">
    <mat-icon class="done_icon pa2 flex center">done</mat-icon>
    <div class="tc fw7 yt_sigin_msg">Thanks for signing up!</div>
  </div>
  <div class="loader-text">
    <span *ngIf="defaultTextStatus">Analyzing your data</span>
    <span *ngIf="!defaultTextStatus">
      Building your personalized dashboard
    </span>
  </div>
  <div class="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
