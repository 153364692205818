<div class="faq_container">
  <div class="about_technology" *ngIf="showAboutTech">
    <div class="header">Overview</div>
    <p>
      <strong>Simple Option for Going Green:</strong> Why not feel great about
      where your electricity comes from? Many electric utilities offer a
      voluntary green pricing option that lets you buy all or a portion of your
      power from renewable sources. You’ll pay a small premium for this option,
      but it’s a very simple way to commit to renewable energy without the need
      to install solar panels or any other equipment.
      <span
        class="link_text"
        *ngIf="showReadMore"
        (click)="onReadContent('readMore')"
      >
        Read more...
      </span>
    </p>

    <div *ngIf="showReadLess">
      <p class="pt3">
        <strong>Sources of Green Power:</strong> The electricity sources differ
        by program. Many programs directly use power from wind, solar,
        geothermal, or a combination of these. In other cases, utilities use
        renewable energy certificates, which represent the environmental
        attributes of a block of renewable power. These certificates are
        purchased from those generating renewable power and then retired by the
        utility on behalf of program participants.
      </p>

      <p class="pt3">
        <strong>Cost:</strong> The premium for electricity from a green pricing
        program is generally about 1 or 2 cents/kWh. Accordingly, if you use
        about 700 kWh/month, you would pay an extra $7 - $14/month to cover all
        of your electricity needs with green power.
      </p>

      <p class="pt3">
        <strong>Environment:</strong> Standard utility-supplied electricity
        generally consists of a mixture of cleaner power from renewable sources
        and dirtier power from fossil-fueled plants using coal or natural gas.
        With a green power option, you can shift your electricity usage toward
        renewable sources, reducing your carbon footprint while simultaneously
        encouraging the further development of clean energy.
        <span class="link_text" (click)="onReadContent('readLess')">
          Read less
        </span>
      </p>
    </div>
  </div>
  <div *ngIf="!showAboutTech">
    <div *ngIf="isActive(0)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How does a green pricing program work?</h5>
        <p class="answer_text">
          If your utility offers green pricing as an option, it’s a convenient
          way to embrace renewable energy. Instead of installing solar panels on
          your roof, green pricing involves voluntary participation in a program
          that provides you with the equivalent of electricity from renewable
          sources in return for a slightly higher cost of electricity on your
          monthly bill. This option can quickly reduce your carbon footprint.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. What are the benefits of a green pricing program?
        </h5>
        <p class="answer_text">
          Green pricing helps to promote the development of renewable energy
          resources such as solar, wind, and geothermal, and this reduces the
          need for fossil-fueled power, limiting the release of greenhouse gases
          into the atmosphere. Another benefit is that green pricing opens the
          availability of green electricity to a broader number of households.
          These programs enable everyone to make use of green electricity,
          including renters, those living in heavily shaded homes, and others
          that would find it impractical to install rooftop solar panels.
          Finally, green pricing programs offer convenience in that there is no
          upfront fee and generally no long-term commitment for participation.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          3. Are green pricing programs available everywhere in the US?
        </h5>
        <p class="answer_text">
          No, while many utilities currently offer them, they are not yet
          available everywhere. Your personalized YellowTin Dashboard will
          indicate if you have the option of participating in a green pricing
          program, and it’ll provide an estimate of potential dollar and carbon
          savings if you choose this approach.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(1)" hideToggle="true">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How much does participation cost?</h5>
        <p class="answer_text">
          Green pricing programs generally add from 1 to 2 cents per kilowatt to
          your normal electricity costs. In many cases, homeowners can select
          how much of their electricity – such as 25%, 50%, or 100% -- will be
          covered by the program. For example, if you include 50% of your
          average electricity usage of 700 kWh/month, and the "green premium" is
          1 cent/kWh, you would pay an additional $3.50/month. In some cases,
          utilities instead charge a flat fee per month that varies with the
          percentage of renewable energy chosen, such as $5/month to include 75%
          of your usage. In other cases, you can purchase blocks – typically 100
          kWh -- of renewable electricity for a set premium per block.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. Is there an upfront cost for participation?
        </h5>
        <p class="answer_text">
          There’s no upfront cost for participation. Similarly, there’s
          generally no fee for canceling participation.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(2)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. How do green pricing programs benefit the environment?
        </h5>
        <p class="answer_text">
          Participation in a green pricing program encourages the further
          development of renewable energy that adds carbon-free electricity to
          the grid, displacing fossil-fueled electricity which is a major
          contributor to carbon emissions and air pollution.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. What renewable resources are used to generate a green pricing
          program’s electricity?
        </h5>
        <p class="answer_text">
          The resources used vary from program to program, but often include
          solar, wind, and geothermal. In most cases, the utility (or other
          entity) offering the green pricing program will not actually own or
          operate the facility that generates the power. Instead, they often buy
          Renewable Energy Certificates (RECs) from those that operate renewable
          power plants. RECs represent the environmental attributes of renewable
          power and can be bought and sold in markets that connect renewable
          generators with organizations that wish to provide green electricity
          to end-users such as homeowners.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(3)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. How difficult is signing up or cancelling?
        </h5>
        <p class="answer_text">
          Signing up for a green pricing program is simple. There’s no initial
          cost, no cancellation fee, and you can generally sign up online
          without a long-term commitment.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. Do I need to buy all of my electricity through the program?
        </h5>
        <p class="answer_text">
          Some green pricing programs are designed to always cover 100% of your
          usage. However, often you can designate a specific fraction of your
          monthly electrical usage to be covered by the program and this limits
          how much of a premium that you’ll need to spend. In some cases,
          utilities offer blocks of green energy for a particular price, such as
          100 kWh of electricity for $2.00. With this program design you can
          sign up to buy as many blocks per month as you wish.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          3. Can I participate if I have rooftop solar?
        </h5>
        <p class="answer_text">
          This depends on the policies in your area. In most areas, those with
          rooftop solar are allowed to participate as a way to cover that
          portion of their electricity usage that’s not supplied by their own
          generation. In other areas, those with rooftop solar are not allowed
          to participate.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          4. Is 100% green power actually delivered to my household?
        </h5>
        <p class="answer_text">
          No. Since electricity is distributed through a grid that mixes power
          from both renewable and non-renewable sources, the power reaching your
          home is a reflection of this mix. Through participation in a green
          pricing program, however, you’re able to encourage the inclusion of
          additional renewable resources into the electricity mix. This is
          especially true if the sponsoring utility operates the renewable power
          plants or purchases renewable energy certificates associated with its
          regional power grid.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          5. What if I move while participating in a green pricing program?
        </h5>
        <p class="answer_text">
          If you move within the same electric utility service territory, you
          can generally continue your participation without interruption. If you
          move out of the service territory, you would have to cancel your
          subscription.
        </p>
      </div>
    </div>
  </div>
</div>
