<div class="faq_container">
  <div class="about_technology" *ngIf="showAboutTech">
    <div class="header">Overview</div>
    <p>
      <strong>How it works:</strong> A portable unit lets anyone enjoy the
      benefits of induction cooking. Just place it on your countertop, plug it
      in, and you’re ready to start cooking in a safer and more convenient way.
      Electromagnetic coils under the glass cooktop surface transfer magnetic
      energy directly into the metal cookware, increasing efficiency. Also,
      since non-metallic objects placed on the cooktop aren’t heated, induction
      cooking is safer. Most importantly, however, if you’re avoiding cooking
      with natural gas, you’ll dramatically reduce the level of toxic air
      pollution in your home.
      <span
        class="link_text"
        *ngIf="showReadMore"
        (click)="onReadContent('readMore')"
      >
        Read more...
      </span>
    </p>

    <div *ngIf="showReadLess">
      <p class="pt3">
        <strong>Types of Systems:</strong> Portable induction cooktops plug into
        a 120V circuit and generally have one or two burners that can differ in
        diameter and power level. They can be found with various features such
        delayed starting, timed shutoff, up to 45 temperature settings, etc.
      </p>

      <p class="pt3">
        <strong>Special Requirements:</strong> Only metal cookware with a high
        iron content can be used. This includes cast iron cookware and a large
        fraction of stainless-steel cookware.
      </p>

      <p class="pt3">
        <strong>Economics:</strong> Prices for portable induction cooktops vary,
        depending on the number of burners and other features. However, most
        units fall within the range of $75 - $250.
      </p>

      <p class="pt3">
        <strong>Environment:</strong> If you use any type of electric cooktop
        (such as induction) instead of a gas cooktop, there can be a significant
        reduction in toxic indoor air pollution. If you currently cook with
        electricity, there will be a small reduction in your carbon footprint as
        induction cooktops are more efficient.
        <span class="link_text" (click)="onReadContent('readLess')">
          Read less
        </span>
      </p>
    </div>
  </div>

  <div *ngIf="!showAboutTech">
    <div *ngIf="isActive(0)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. How does a portable induction cooktop work?
        </h5>
        <p class="answer_text">
          Electromagnetic coils under the cooktop surface transfer magnetic
          energy into the metal at the base of the cookware, which then converts
          the energy to heat. As a result, the heat is generated at the base of
          the cookware itself. Induction cooking requires the use of cookware
          with a high iron content in the base. Non-metallic objects will not be
          heated even if placed on the cooktop. The energy transfer is very fast
          and you can precisely control the temperature, making for a better
          experience compared to cooking with natural gas.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. How different is an induction cooktop from a traditional electric
          cooktop?
        </h5>
        <p class="answer_text">
          Induction cooktops use a completely different approach.  Induction
          offers much faster cooking times, better control of heat levels, and
          greater safety than the traditional “coil-type” or “smooth-top”
          electric cooktops. In the traditional cooktops, heat is generated in
          the heating element and then transferred to the base of the cookware.
          As a result, the heat is generated in the cookware itself. Induction
          cooking requires the use of cookware with a high iron content.
          Non-metallic objects will not be heated even if placed on the cooktop.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">3. Are they as good as gas cooktops?</h5>
        <p class="answer_text">
          An induction cooktop is the fastest cooking technology currently
          available. It also provides greater temperature control and easier
          cleanup than cooking with natural gas. Perhaps the most important
          benefits of induction cooking relate to safety.  Since gas cooking
          involves an open flame, it represents a fire hazard, introduces
          pollution into your home that can be unhealthy, and contributes more
          to your carbon footprint.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          4. Are special types of pots and pans required?
        </h5>
        <p class="answer_text">
          Yes. Only metal cookware that has a high iron content in its base will
          work with induction. All cast iron pots and pans work well, as does a
          large fraction of stainless-steel cookware. With stainless steel, the
          bottom surface of the pot or pan should be made with nickel-free
          stainless steel. If the pot or pan is not expressly marketed as
          induction-ready, you can use the “magnet test”. If a magnet clings
          well to the outside bottom of the pot or pan, it will work with
          induction. If it does not, or clings only very weakly, the cookware
          will not work.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(1)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. Are portable induction cooktops expensive?
        </h5>
        <p class="answer_text">
          In general, portable induction units will cost between $75 and $250 --
          a bit more than other types of portable electric units. However, the
          differences in performance and safety can be significant. Inexpensive
          sets of standard pots and pans that work on induction are available in
          the market, often for under $200.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. Do they save any money over gas cooktops in the long run?
        </h5>
        <p class="answer_text">
          Induction cooktops are more efficient, with about 85% of the
          electrical energy converted to heat in the cookware. This can be
          compared to an efficiency of about 35% – 40% efficiency for a gas
          cooktop. However, whether they will save money depends on your local
          electricity and gas costs.  In general, cooking with induction will
          slightly increase your monthly utility bills, but many households are
          finding that the non-economic advantages of induction, such as cleaner
          indoor air and shorter cooking times, outweigh the additional cost.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(2)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How do I clean an induction cooktop?</h5>
        <p class="answer_text">
          This involves simply wiping the surface with a cloth that won’t
          scratch the surface.  A cleaner suitable for ceramic or glass can be
          used.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. Will the cooktop surface scratch or crack?
        </h5>
        <p class="answer_text">
          Yes. It is possible for the surface of an induction cooktop to scratch
          or crack, but it consists of a special type of glass that is highly
          resistant to such problems. The material is very strong and tolerates
          very high temperatures and sudden temperature changes.  Care should be
          taken to use a non-scratch cloth while cleaning.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(3)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. Will my carbon footprint go down if I switch to induction cooking?
        </h5>
        <p class="answer_text">
          That depends largely on what you’re switching from.  If you currently
          cook with electricity, there will be a very small reduction in your
          carbon footprint.  If you currently cook with natural gas, you’ll
          likely have a larger reduction.  This depends on how much of the
          electricity in your area is generated with fossil fuels.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. Is there a significant health advantage with induction cooking?
        </h5>
        <p class="answer_text">
          If you replace a gas cooktop with an electric cooktop (such as
          induction), there can be a significant reduction in toxic indoor air
          pollution. Cooking with natural gas can be a major source of nitrogen
          dioxide, carbon monoxide, formaldehyde, and other materials that are
          harmful (especially to children).  If you still use gas for cooking,
          it’s advisable to run the fan in your range hood while cooking and
          open some windows if you can.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(4)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. Will copper and aluminum pots work with an induction cooktop?
        </h5>
        <p class="answer_text">
          No.  Only cookware with a high iron content will work. That means
          cookware made of copper, aluminum, ceramic, and glass will not work.
          Some people use “induction interface disks” if they wish to continue
          using their incompatible cookware on an induction cooktop.  These are
          basically flat iron disks with a handle that you put directly on the
          cooktop.  You then place your cookware on top of the disk, and as the
          disk heats, it transfers heat to the cookware.  This approach works,
          but some of induction’s advantages are reduced.  For example, both the
          efficiency of the cooktop and the ability to quickly adjust the
          cooking temperature are degraded.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">2. Are induction cooktops safe?</h5>
        <p class="answer_text">
          Induction cooktops are very safe. The electromagnetic field produced
          by an induction unit has a very short range. Moreover, units do not
          generate any field unless they detect ferrous (high iron content)
          metal directly above them and virtually all of the energy is absorbed
          in the metal. Therefore, a cooktop shuts off automatically when the
          cookware is removed and never gets very hot since all the heat is
          generated at the base of the cookware.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          3. Can pressure cookers be used with an induction cooktop?
        </h5>
        <p class="answer_text">
          Yes, pressure cookers with bottoms that are compatible with induction
          cooktops are widely available in all sizes.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          4. What electrical connections are needed for a portable induction
          cooktop?
        </h5>
        <p class="answer_text">
          These units simply plug into a 120V wall outlet.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(5)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. What types of incentives are available for portable induction
          cooktops?
        </h5>
        <p class="answer_text">
          Some electric utilities, cities, and other entities have free loaner
          programs where you can borrow a portable induction cooktop for two or
          three weeks. This provides you with an opportunity to try out
          induction cooking before buying a portable or permanently installed
          unit. Rebates are sometimes available for permanent induction units.
        </p>
      </div>
    </div>
  </div>
</div>
