import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTooltipModule } from "@angular/material/tooltip";
import { IncentivesComponent } from "./incentives.component";
import { IncentivesRoutingModule } from "./incentives.routing.module";
import { IncentivesAPIService } from "./incentives.service";
import { TechNamePipeModule } from "../utils/tech.pipe";
import { LoaderModule } from "../homeowner-dashboard/shared/loader";
import { MatMenuModule } from "@angular/material/menu";
import { DashboardFooterModule } from "../homeowner-dashboard/shared/dashboard-footer";

@NgModule({
  imports: [
    CommonModule,
    IncentivesRoutingModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatExpansionModule,
    MatTooltipModule,
    LoaderModule,
    TechNamePipeModule,
    MatMenuModule,
    DashboardFooterModule,
  ],
  declarations: [IncentivesComponent],
  providers: [IncentivesAPIService],
  exports: [IncentivesComponent],
})
export class IncentivesModule {}
