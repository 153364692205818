import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RedirectLoaderComponent } from "./redirect-loader.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule, MatIconModule],
  exports: [RedirectLoaderComponent],
  declarations: [RedirectLoaderComponent],
})
export class RedirectLoaderModule {}
