import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { CaptchaComponent } from "./captcha.component";

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatButtonModule, MatIconModule],
  declarations: [CaptchaComponent],
  exports: [CaptchaComponent],
})
export class CaptchaModule {}
