import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  HelpMeChooseRequestModel,
  HelpMeChooseResponseModel,
  MakeAndModel,
} from "./help-me-choose.model";
import { DropDownModel } from "../../../rest-api/rest-api.model";
import { HelpMeChooseService } from "./help-me-choose.service";
import { LearnMoreService } from "../../learn-more/learn-more.service";

@Component({
  selector: "yt-help-me-choose",
  templateUrl: "./help-me-choose.component.html",
  styleUrls: ["./help-me-choose.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class HelpMeChooseComponent implements OnInit, OnDestroy {
  sortBy = "alpha";
  filterBy = "none";
  title: string;
  techName: string;
  isHPWH = false;
  isEV = false;
  isIC = false;
  isEVCharger = false;
  isEE = false;
  sortByMsg: string;
  loader = true;
  sortByList: DropDownModel[];
  filterGroup: DropDownModel[] = [{ viewValue: "Show All", value: "none" }];
  makeAndModelsList: MakeAndModel[] = [];

  constructor(
    private learnMoreService: LearnMoreService,
    private helpMeChooseService: HelpMeChooseService,
    public dialogRef: MatDialogRef<HelpMeChooseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.sortByList = this.learnMoreService.sortByList;
  }

  ngOnInit(): void {
    this.techName = this.data.tech;
    if (this.techName === "ic") {
      this.isIC = true;
      this.title = "induction cooktop";
      this.sortByMsg = "Net cost:";
    } else if (this.techName === "hpwh") {
      this.isHPWH = true;
      this.title = "heat pump water heater";
      this.sortByMsg = "Net cost:";
    } else if (this.techName === "ev1" || this.techName === "ev2") {
      this.isEV = true;
      this.title = "electric vehicle";
      this.sortByMsg = "Price after incentives:";
    } else if (this.techName === "evCharger") {
      this.isEVCharger = true;
      this.title = "ev charger";
      this.sortByMsg = "Net cost:";
    } else if (this.techName === "thermostat") {
      this.isEE = true;
      this.title = "thermostat";
      this.sortByMsg = "Net cost:";
    }
    this.getHelpMeChooseResponse();
  }

  onSortChange(sort: string): void {
    this.sortBy = sort;
    this.getHelpMeChooseResponse();
  }

  onFilterChange(filter: string): void {
    this.filterBy = filter;
    this.getHelpMeChooseResponse();
  }

  getHelpMeChooseResponse(): void {
    const request: HelpMeChooseRequestModel = {
      techName: this.techName,
      filterBy: this.filterBy,
      sortBy: this.sortBy,
    };
    this.loader = true;
    this.makeAndModelsList.splice(0, this.makeAndModelsList.length);
    this.filterGroup.splice(1, this.filterGroup.length);
    if (this.isEV) {
      this.evHelpMeChooseAPI(request);
    } else if (this.isHPWH || this.isIC || this.isEVCharger || this.isEE) {
      this.helpMeChooseAPI(request);
    }
  }

  evHelpMeChooseAPI(request: HelpMeChooseRequestModel): void {
    this.helpMeChooseService
      .getEvHelpMeChooseData(request)
      .subscribe((response: HelpMeChooseResponseModel) => {
        this.loader = false;
        response.electricVehicles.forEach((ev: MakeAndModel) => {
          this.makeAndModelsList.push({
            modelMake: ev.modelMake,
            modelType: ev.modelType,
            carType: ev.carType,
            drivingRange: ev.drivingRange,
            msrp: ev.msrp,
            afterIncentives: ev.afterIncentives,
          });
        });
        response.filters.forEach((filter: string) => {
          if (filter !== "none") {
            this.filterGroup.push({
              viewValue: `Type: ${filter}`,
              value: filter,
            });
          }
        });
      });
  }

  helpMeChooseAPI(request: HelpMeChooseRequestModel): void {
    this.helpMeChooseService
      .getHelpMeChooseData(request)
      .subscribe((response: HelpMeChooseResponseModel) => {
        this.loader = false;
        if (this.isIC) {
          response.icModels?.forEach((ic: MakeAndModel) => {
            this.makeAndModelsList.push({
              mnf: ic.mnf,
              model: ic.model,
              netCost: ic.netCost,
              type: ic.type,
              upfrontCost: ic.upfrontCost,
            });
          });
          response.filters.forEach((filter: string) => {
            if (filter !== "none") {
              const filterSplit = filter.split(" ");
              let prefix;
              prefix = filterSplit.map((value: string) => {
                switch (value) {
                  case "lte":
                    return "<=";
                  case "gte":
                    return ">=";
                  case "lt":
                    return "<";
                  case "gt":
                    return ">";
                  default:
                    return value;
                }
              });
              if (prefix.length > 2) {
                prefix.splice(2, 0, "inch &");
              }
              prefix = prefix.join(" ");
              this.filterGroup.push({
                viewValue: `Size: ${prefix} inch`,
                value: filter,
              });
            }
          });
        }
        if (this.isHPWH) {
          response.hpwhModels?.forEach((hpwh: MakeAndModel) => {
            this.makeAndModelsList.push({
              mnf: hpwh.mnf,
              model: hpwh.model,
              netCost: hpwh.netCost,
              capacity: hpwh.capacity,
              upfrontCost: hpwh.upfrontCost,
            });
          });
          response.filters.forEach((filter: string) => {
            if (filter !== "none") {
              const filterSplit = filter.split(" ");
              let prefix;
              if (filterSplit[0] === "lte") {
                prefix = "<=";
              } else if (filterSplit[0] === "gte") {
                prefix = ">=";
              } else if (filterSplit[0] === "lt") {
                prefix = "<";
              } else if (filterSplit[0] === "gt") {
                prefix = ">";
              }
              this.filterGroup.push({
                viewValue: `Capacity ${prefix} ${filterSplit[1]} gallons`,
                value: filter,
              });
            }
          });
        }
        if (this.isEVCharger) {
          response.evChargerModels?.forEach((evCharger: MakeAndModel) => {
            this.makeAndModelsList.push({
              mnf: evCharger.mnf,
              model: evCharger.model,
              netCost: evCharger.netCost,
              chargingPower: evCharger.chargingPower,
              upfrontCost: evCharger.upfrontCost,
            });
          });
          response.filters.forEach((filter: string) => {
            if (filter !== "none") {
              this.filterGroup.push({
                viewValue: `Type: ${filter}`,
                value: filter,
              });
            }
          });
        }
        if (this.isEE) {
          response.thermostatModels?.forEach((thermostat: MakeAndModel) => {
            this.makeAndModelsList.push({
              mnf: thermostat.mnf,
              model: thermostat.model,
              netCost: thermostat.netCost,
              warranty: thermostat.warranty,
              upfrontCost: thermostat.upfrontCost,
            });
          });
          response.filters.forEach((filter: string) => {
            if (filter !== "none") {
              this.filterGroup.push({
                viewValue: `Make: ${filter}`,
                value: filter,
              });
            }
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.loader = true;
  }
}
