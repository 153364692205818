import { Component, HostListener } from "@angular/core";

@Component({
  selector: "yt-homeowner-product",
  templateUrl: "./homeowner-product.component.html",
})
export class HomeOwnerProductComponent {
  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    event.returnValue = false;
  }
}
