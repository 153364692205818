<scroll-top></scroll-top>
<div class="page_container" [formGroup]="utilityForm">
  <div class="yt_width energy-info">
    <div class="">
      <div class="yt__info-header">{{ header }}</div>
      <div class="yt__info-sub-header">{{ subHeader }}</div>
      <div class="utility_fields">
        <div class="yt__pt0-7 flex-l items-center">
          <div class="utility_provider pr3-ns">
            Tell us your electric utility
          </div>
          <mat-form-field
            appearance="outline"
            floatLabel="auto"
            hideRequiredMarker="true"
          >
            <mat-select
              matInput
              placeholder="Select provider"
              formControlName="utilityId"
            >
              <mat-option
                *ngFor="let name of utilityProvidersModel | async"
                [value]="name.utilityName"
              >
                {{ name.utilityName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <mat-card *ngIf="isUtilitySelected">
        <mat-tab-group class="demo-tab-group" mat-stretch-tabs>
          <mat-tab *ngIf="!smdLink && isSkyTech">
            <ng-template mat-tab-label>
              <div class="header">
                Manual Estimate
                <p class="yt_hidden subheader">
                  Provide a rough estimate of electricity costs
                </p>
              </div>
            </ng-template>
            <div class="demo-tab-content">
              <yt-manual-entry
                [isDisableButton]="isDisableButton"
                [utilityName]="utilityName"
                [utilityId]="utilityId"
                [cceOptOut]="utilityForm.controls['checkCceOptOut'].value"
                [haveAutoLogin]="haveAutoLogin"
              >
              </yt-manual-entry>
            </div>
          </mat-tab>
          <mat-tab *ngIf="energyProviderGbc">
            <ng-template mat-tab-label>
              <div class="header">
                Share My Data
                <p class="yt_hidden subheader">
                  Authorize PG&E to share your energy data
                </p>
              </div>
            </ng-template>
            <div class="demo-tab-content">
              <yt-energy-sync
                [authorizeEnergyUsageUrl]="authorizeEnergyUsageUrl"
                [smdTabStatus]="authorizationSectionPGE"
                [haveResponse]="haveResponse"
              >
              </yt-energy-sync>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-card>
    </div>
    <div class="checkbox-padding" *ngIf="cceOptOut">
      <mat-checkbox
        class="checkbox-text"
        Value="checked"
        formControlName="checkCceOptOut"
      >
        I have <strong>opted out</strong> of {{ cceFullForm }} as my electricity
        provider.
        <mat-icon class="mat-icon" [matTooltip]="cceTooltip"
          >help_outline</mat-icon
        >
      </mat-checkbox>
    </div>
  </div>
  <yt-footer class="footer_container"></yt-footer>
</div>
