import { LifestyleModel } from "../../overview/model/solarvendordetails.model";
import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  Input,
  OnChanges,
} from "@angular/core";
import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { MatSidenav } from "@angular/material/sidenav";
import { JWTTokenInfoModel } from "../../../auth/auth.model";
import { DashboardLayoutService } from "../dashboard-layout/dashboard-layout.service";
import { LogoModel, RoofTypesModel } from "../../../rest-api/rest-api.model";
import { AuthenticationService } from "../../../auth/auth.service";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { RestAPIService } from "../../../rest-api/rest-api.service";
import { AppService } from "../../../app.service";
import { Utils } from "../../../utils/util";

@Component({
  selector: "yt-top-nav",
  templateUrl: "top-nav.component.html",
  styleUrls: ["./top-nav.component.scss"],
})
export class TopNavComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild("drawer") sidenav: MatSidenav;
  referFriendText: string;
  showReferFriend: boolean;
  referFriendPopup: boolean = false;
  @Input() routeInfo;
  @Input() hideSidenav: boolean;
  @Input() smdLink: boolean;
  @Input() logo: LogoModel;
  @Input() dashboardlogo: LogoModel;
  @Input() settinginfo: LifestyleModel;
  @Input() jwtToken: JWTTokenInfoModel;
  @Input() address: String;
  @Input() name: string;
  @Input() vendorCode: String;
  @Input() language: RoofTypesModel[];
  selectedLanguage: string;
  isLoggedIn: boolean;
  currentLanguage: string;
  poweredByLogoStatus: boolean;
  contactUsPopup: boolean = false;
  alive = true;
  showPoweredBy = false;
  isIntuit: boolean;
  haveLanguageDropDown: boolean;
  isHandset: Observable<BreakpointState>;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private layoutService: DashboardLayoutService,
    private authService: AuthenticationService,
    private dashboardAPI: DashboardAPIService,
    private restAPIService: RestAPIService,
    private translate: TranslateService,
    private router: Router,
    private utils: Utils,
    private appService: AppService
  ) {
    this.isHandset = this.breakpointObserver.observe(Breakpoints.Handset);
  }

  ngOnChanges(): void {
    this.showReferFriend = this.dashboardAPI.referFriendStatus;
    if (this.language) {
      this.haveLanguageDropDown = this.language.length > 1;
      this.selectedLanguage = sessionStorage.getItem("language")!;
      if (!this.selectedLanguage) {
        if (this.language[0]) {
          this.selectedLanguage = this.language[0].value;
          sessionStorage.setItem("language", this.selectedLanguage);
        }
      }
      const lang = this.language.find(
        (lang) => lang.value === this.selectedLanguage
      );
      this.selectedLanguage = lang ? lang.value : "en";
      this.translate.use(this.selectedLanguage);
      this.currentLanguage = this.utils.updateCurrentLanguage(
        this.language,
        this.selectedLanguage
      );
    }
    this.isLoggedIn = !!this.authService.jwtTokenInfo.userName;
  }

  ngOnInit() {
    if (!this.jwtToken && this.authService.jwtTokenInfo) {
      this.jwtToken = this.authService.jwtTokenInfo;
    }
    this.layoutService.change.subscribe((closeSideBar) => {
      if (closeSideBar) {
        if (this.sidenav.mode == "side") {
          this.sidenav.close();
        }
      } else {
        if (this.sidenav.mode == "side") {
          this.sidenav.open();
        }
      }
    });
  }

  onClickLogo(link: string): void {
    if (link) {
      window.open(link, "_blank");
    } else {
      window.open(
        !this.logo.customer ? this.logo.href : this.logo.customer.href
      );
    }
  }

  onLogOut() {
    this.router.navigateByUrl("/dashboard/logout");
  }

  poweredByLogo(): void {
    window.open(`https://www.yellowtin.com/`, "_blank");
  }

  onHelpCenter() {
    window.open("https://www.yellowtin.com/yt-help-center", "_blank");
  }

  onContact() {
    this.contactUsPopup = true;
  }

  onReferFriend() {
    this.referFriendPopup = true;
    this.referFriendText = this.dashboardAPI.referFriendText;
    this.dashboardAPI.dashboardDetailsAnalytics("referFriend");
  }

  friendMail(event) {
    this.referFriendPopup = event;
  }

  onLanguageChange(lang: string): void {
    this.appService.selectedLanguage.next(lang);
    sessionStorage.setItem("language", lang);
    this.selectedLanguage = lang;
    this.currentLanguage = this.utils.updateCurrentLanguage(
      this.language,
      this.selectedLanguage
    );
    this.translate.use(lang);
    if (this.restAPIService.homeOwnerEmail) {
      this.restAPIService.updateLanguage(lang).subscribe(
        (data) => {},
        (error) => {
          console.error(error);
        }
      );
    }
  }

  onLearningHub() {
    if (this.isLoggedIn) {
      this.dashboardAPI.dashboardDetailsAnalytics("learningHub");
    }
    if (this.haveLanguageDropDown) {
      if (this.selectedLanguage === "sp") {
        window.open(
          "https://www.yellowtin.com/clean-energy-information-center-es/",
          "_blank"
        );
      } else {
        this.router.navigateByUrl("/dashboard/learning-hub");
      }
    } else {
      this.router.navigateByUrl("/dashboard/learning-hub");
    }
  }

  onNotifications(): void {
    this.dashboardAPI.dashboardDetailsAnalytics("notification");
  }

  onMarketplace(): void {
    this.dashboardAPI.dashboardDetailsAnalytics("marketplace");
  }

  onMyList(): void {
    this.dashboardAPI.dashboardDetailsAnalytics("myList");
  }

  closeContactPopup(event) {
    this.contactUsPopup = false;
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
