import {
  AnalyticsModel,
  EverGreenModel,
  MarketPlaceAnalyticsModel,
  SectionListModel,
  SettingRadioButtonModel,
} from "./dashboard-api.model";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { SolarVendorDetailsModel } from "../../homeowner-dashboard/overview/model/solarvendordetails.model";
import { JWTTokenInfoModel } from "../../auth/auth.model";
import { DropDownModel, SolarVendorModel } from "../rest-api.model";
import {
  RoofTopPostModel,
  RoofTopPageModel,
  CommunitySolarPageModel,
} from "../../homeowner-product/roof-top/roof-top.model";
import { MarketPlaceDetailsModel } from "../../homeowner-dashboard/learn-more/learn-more.model";
import { ReferFriendDTO } from "../../homeowner-dashboard/refer-friend/refer-friend.model";
import { environment } from "../../../environments/environment";
import { AuthenticationService } from "../../auth/auth.service";
import {
  DataModel,
  errorCheckingDetailsModel,
} from "../../homeowner-dashboard/overview/model/dashboard.model";
import { HomeSettingModel } from "../../homeowner-dashboard/overview/dashboard-v2/setting-overlay/setting-overlay.component.model";
import { UserDataModel } from "../../homeowner-dashboard/overview/model/userdata.model";
import { Router } from "@angular/router";
import { AppService } from "../../app.service";
import {
  SavingsTypeEnum,
  TimeFrameEnum,
} from "src/app/homeowner-dashboard/learn-more/learn-more.enum";
import { SavingsDetailsResponseType } from "src/app/homeowner-dashboard/overview/dashboard-v3/dashboard-v3.types";

@Injectable()
export class DashboardAPIService {
  readonly API_END_POINT = environment.API_URL;
  readonly DASHBOARDAPI_END_POINT = environment.BASE_URL;
  readonly HOME_OWNERS = "homeOwners/";
  readonly SOLAR_VENDORS = "solarVendors/";
  readonly updateUserDetailsUrl = this.API_END_POINT + "v2/" + this.HOME_OWNERS;
  readonly API_BASE_URL = `${this.API_END_POINT}ho/dashboard/`;
  readonly MULTI_FILE_UPLOAD = "files/multiUpload/";
  readonly updateLearnMoreUrl = this.API_BASE_URL + "lmUpdate/";
  readonly ReferAFriendUrl = this.API_BASE_URL + "referAFriend/";
  readonly putEvergreenSettingUrl = this.API_BASE_URL + "everGreenUpdate/";
  readonly getEvergreenSettingUrl = this.API_BASE_URL + "everGreenSetting/";
  readonly getMarketplaceDetailsUrl = this.API_BASE_URL + "marketplaceDetails/";
  readonly marketplaceInteractionsUrl =
    this.API_BASE_URL + "marketplaceInteractions/";
  readonly getRequestQuoteUrl = this.API_BASE_URL + "quote/";
  readonly savingsUrl = this.API_BASE_URL + "savings/";
  readonly settingsAPI = `${this.API_END_POINT}ho/dashboard/settings/`;
  readonly analyticsUrl = this.API_BASE_URL + "analytics";
  readonly errorCheckingDetailsUrl = `${this.API_END_POINT}ho/dashboard/errorCheckingDetails/`;

  ROOFTOPQA_API_URL = "assets/json/roof-top-QA.json";
  homeSettingURL = "assets/json/home-setting.json";
  radioButtonURL = "assets/json/radio-button.json";
  faqURL = "assets/json/faq.json";
  userProvidedRoofTopInfo: RoofTopPostModel;
  userProvidedLifeStyle: any;
  userProvidedLifeStyleV2: any;
  referAFriendEmail: ReferFriendDTO;
  coustmerComments: string;
  solarVendorDescription: string;
  solarVendorInformation;
  solarVendorDetailsURL: string;
  destination: number;
  solarVendorDetails: SolarVendorModel;
  buildingType: string;
  utilityProvider: string;
  buildType: string;
  timeFrame: TimeFrameEnum = TimeFrameEnum.OneYear;
  displayFrame: SavingsTypeEnum;
  smdlink: boolean;
  geniallyLink: string;
  vendorName: string;
  roadmapTerm: string;
  roadmapType: string;
  roadmapPurchase: string;
  everGreenApplicable: boolean;
  userDetails: JWTTokenInfoModel;
  renterApproach: string;
  condoApproach: string;
  showTourGuide = true;
  showErrorAlertStatus = true;
  showEmailAlertStatus = true;
  showPgeAlertStatus = true;
  showPgeUsageAlertStatus = true;
  loggedInCount: number;
  feedbackLink: string;
  energyChoicesData: UserDataModel;
  haveEverGreen: boolean;
  activeRecommendationTab: string;
  referFriendStatus: boolean;
  referFriend: boolean;
  referFriendText: string;
  energyInfoData: boolean = false;
  vendorDetails: SolarVendorDetailsModel;
  rewardBonus: boolean;
  userSettingInfo = new Subject<any>();
  userSettingInfo$ = this.userSettingInfo.asObservable();
  icType: string;
  icInfo: DataModel;
  alreadyHaveLoading = new Subject<string>();
  favoriteLoading = new Subject<string>();
  removeAlreadyHave = new Subject<string>();
  acTypes: DropDownModel[] = [
    { value: "dontHaveAC", viewValue: "I don’t have AC" },
    { value: "haveAC", viewValue: "I have Central AC" },
    { value: "have1AC", viewValue: "I have 1 window AC" },
    { value: "haveMoreAC", viewValue: "I have 2 or more window ACs" },
  ];
  homeSettingTabsList: DropDownModel[] = [
    {
      value: "general",
      viewValue: "General",
      image: "assets/dashboard/dashboard_icons_v2/general.svg",
    },
    {
      value: "simpleLowCost",
      viewValue: "Simple/Low cost",
      image: "assets/dashboard/dashboard_icons_v2/simpleCost.svg",
    },
    {
      value: "transportation",
      viewValue: "Transportation",
      image: "assets/dashboard/dashboard_icons_v2/transport.png",
    },
    {
      value: "solarBattery",
      viewValue: "Solar & Battery",
      image: "assets/dashboard/dashboard_icons_v2/solar.png",
    },
    {
      value: "appliances",
      viewValue: "Appliances",
      image: "assets/dashboard/dashboard_icons_v2/appliances.svg",
    },
  ];
  conventionalTypeRange: DropDownModel[] = [
    { value: "1", viewValue: "New (< 2 years)" },
    { value: "3", viewValue: "Somewhat New (2 - 4 years)" },
    { value: "6", viewValue: "Mid Age (5 - 7 years)" },
    { value: "9", viewValue: "Somewhat Old (8 - 10 years)" },
    { value: "11", viewValue: "Old (> 10 years)" },
  ];
  nonElectricTypeRange: DropDownModel[] = [
    { value: "2", viewValue: "New (< 3 years)" },
    { value: "6", viewValue: "Somewhat New (3 - 8 years)" },
    { value: "11", viewValue: "Mid Age (9 - 13 years)" },
    { value: "16", viewValue: "Somewhat Old (14 - 18 years)" },
    { value: "20", viewValue: "Old (> 18 years)" },
  ];
  electricTypeRange: DropDownModel[] = [
    { value: "3", viewValue: "New (< 5 years)" },
    { value: "8", viewValue: "Somewhat New (5 - 11 years)" },
    { value: "16", viewValue: "Mid Age (12 - 20 years)" },
    { value: "24", viewValue: "Somewhat Old (21 - 27 years)" },
    { value: "30", viewValue: "Old (> 27 years)" },
  ];

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private appService: AppService,
    private authService: AuthenticationService
  ) {
    this.solarVendorDetailsURL =
      this.API_END_POINT + "solarVendorsDashboard/{vendorcode}/{consumerId}";
  }

  homeSettingTechDetails(): Observable<HomeSettingModel> {
    return this.httpClient.get<HomeSettingModel>(this.homeSettingURL);
  }

  faqDetails(): Observable<SectionListModel> {
    return this.httpClient.get<SectionListModel>(this.faqURL);
  }

  setEnergyChoiceResponse(response) {
    this.energyChoicesData = response;
  }

  getRadioButtonDetails(): Observable<SettingRadioButtonModel> {
    return this.httpClient.get<SettingRadioButtonModel>(this.radioButtonURL);
  }

  getAllRoofTopQA(vedorName: string): Observable<RoofTopPageModel> {
    return this.httpClient.get<RoofTopPageModel>(
      this.ROOFTOPQA_API_URL + `?vendor=${vedorName}`
    );
  }

  getCommunitySolarQA(vendorName: string): Observable<CommunitySolarPageModel> {
    return this.httpClient.get<CommunitySolarPageModel>(
      this.ROOFTOPQA_API_URL + `?vendor=${vendorName}`
    );
  }

  getDashboardDetails() {
    return this.httpClient.get(this.DASHBOARDAPI_END_POINT);
  }

  getSolarVendorDetails(vendorCode: string): Observable<SolarVendorModel> {
    return this.httpClient.get<SolarVendorModel>(
      this.API_END_POINT + this.SOLAR_VENDORS + vendorCode
    );
  }

  getSolarVendorDashboardDetails(
    vendorCode: string,
    userEmail: string
  ): Observable<SolarVendorDetailsModel> {
    return this.httpClient.get<SolarVendorDetailsModel>(
      `${this.API_END_POINT}solarVendorsDashboard/${vendorCode}/${userEmail}`
    );
  }

  getSavingsDetails(
    timeSpan: string,
    query?: string
  ): Observable<SavingsDetailsResponseType> {
    if (query) {
      return this.httpClient.get<SavingsDetailsResponseType>(
        this.savingsUrl +
          this.getUserDetails().userName +
          `?timeSpan=${timeSpan}&` +
          query
      );
    }
    return this.httpClient.get<any>(
      this.savingsUrl + this.getUserDetails().userName + `?timeSpan=${timeSpan}`
    );
  }

  setSolarVendorDetails(data: SolarVendorModel): void {
    this.solarVendorDetails = data;
  }

  setLoggedInCount(count) {
    this.loggedInCount = count;
  }

  getSVD() {
    return this.solarVendorDetails;
  }

  setVendorName(name: string) {
    this.vendorName = name;
  }

  getVendorName() {
    return this.vendorName;
  }

  setBuildingType(type: string) {
    this.buildType = type;
  }

  getBuildingType() {
    return this.buildType;
  }

  setTimeFrame(timeFrame: TimeFrameEnum) {
    this.timeFrame = timeFrame;
  }

  setDisplayFrame(displayFrame: SavingsTypeEnum) {
    this.displayFrame = displayFrame;
  }

  getDisplayFrame(): SavingsTypeEnum {
    return this.displayFrame;
  }

  setSmdLink(data) {
    this.smdlink = data;
    return null;
  }

  getSmdLink() {
    return this.smdlink;
  }

  async postAuthCode(data, email): Promise<any> {
    try {
      await this.httpClient
        .put(this.updateUserDetailsUrl + email, data)
        .toPromise();
    } catch (error) {}
  }

  postRoofTopQA(data) {
    this.userProvidedRoofTopInfo = data;
    let roofTop = {
      roof: {
        solar: data.solar,
        age: data.roofAge,
        reRoof: data.planForReRoof,
        slopes: [data.roofSlopes],
        shade: data.roofShade,
        types: [data.roofTypes],
      },
    };
    return this.httpClient.put(
      this.updateUserDetailsUrl + this.getUserDetails().userName,
      roofTop,
      { observe: "response" }
    );
  }

  postAvgMonthlyBill(avgMonthlyBill: string) {
    let bill = { avgMonthlyBill };
    return this.httpClient.put(
      this.updateUserDetailsUrl + this.getUserDetails().userName,
      bill,
      { observe: "response" }
    );
  }

  getUserProvidedRoofTopInfo() {
    return this.userProvidedRoofTopInfo;
  }

  setDestination(data: number) {
    this.destination = data;
  }

  getDestination() {
    return this.destination;
  }

  setSolarVendorDescription(data: string) {
    this.solarVendorDescription = data;
  }

  getSolarVendorDescrption() {
    return this.solarVendorDescription;
  }

  setSolarVendorInformation(data) {
    this.solarVendorInformation = data;
  }

  getSolarVendorInformation() {
    return this.solarVendorInformation;
  }

  setGeniallyLink(link: string) {
    this.geniallyLink = link;
  }

  setRoadmapSettings(term, type, purchase) {
    this.roadmapTerm = term;
    this.roadmapType = type;
    this.roadmapPurchase = purchase;
  }

  referFriendsAPI(toList: ReferFriendDTO) {
    return this.httpClient.put<ReferFriendDTO>(
      this.ReferAFriendUrl + this.getUserDetails().userName,
      toList,
      { observe: "response" }
    );
  }

  setUserDetails(userData: JWTTokenInfoModel | null): void {
    this.userDetails = userData as unknown as JWTTokenInfoModel;
  }

  getUserDetails(): JWTTokenInfoModel {
    if (!this.userDetails) {
      this.userDetails = this.authService.jwtTokenInfo;
    }
    return this.userDetails;
  }

  getEvergreenUpdate() {
    return this.httpClient.get<EverGreenModel>(
      this.getEvergreenSettingUrl + this.getUserDetails().userName
    );
  }

  putEvergreenUpdate(everGreenInfo) {
    return this.httpClient.put(
      this.putEvergreenSettingUrl + this.getUserDetails().userName,
      everGreenInfo,
      { observe: "response" }
    );
  }

  getMarketplaceDetails(
    tech_name: string
  ): Observable<MarketPlaceDetailsModel> {
    return this.httpClient.get<MarketPlaceDetailsModel>(
      this.getMarketplaceDetailsUrl +
        `${this.getUserDetails().userName}?tech=` +
        tech_name
    );
  }

  getRequestQuote(linkId: string): Observable<{ token: string }> {
    return this.httpClient.get<{ token: string }>(
      this.getRequestQuoteUrl +
        this.userDetails.userName +
        `?installerName=${linkId}`
    );
  }

  getUserSettingInfo(): Observable<UserDataModel> {
    return this.httpClient.get<UserDataModel>(
      this.settingsAPI + this.userDetails.userName
    );
  }

  getVendorDetails(solarVendorCode: string, autoLogin: boolean): void {
    this.getSolarVendorDashboardDetails(
      solarVendorCode,
      this.getUserDetails().userName
    ).subscribe((response: SolarVendorDetailsModel) => {
      this.vendorDetails = response;
      this.appService.dashboardlogoModel.next({
        url: response.logo.url,
        href: response.logo.href,
        customer: response.logo.customer && response.logo.customer,
      });
      this.referFriendStatus = response.dashboard.referAFriend;
      this.referFriendText = response.dashboard.referAFriendText;
      this.feedbackLink = response.feedbackLink;
      this.appService.languageModel.next(response.languageTranslator);
      this.appService.currentVendorName.next(solarVendorCode);
      this.appService.lifeStyleModel.next({
        marketplace: response.dashboard.marketplace,
        usage: response.dashboard.usage,
        notification: response.dashboard.notification,
      });
      this.setSolarVendorDescription(response.description);
      this.setLoggedInCount(response.loggedInCount);
      this.setGeniallyLink(response.geniallyLink);
      this.getSettingsInfo(autoLogin);
    });
  }

  getSettingsInfo(autoLogin: boolean): void {
    this.getUserSettingInfo().subscribe((response: UserDataModel) => {
      this.userSettingInfo.next(response);
      this.setBuildingType(response.buildingType);
      this.setEnergyChoiceResponse(response);
      const name = `${response.myInfo.firstName} ${response.myInfo.lastName}`;
      this.appService.name.next(name);
      this.appService.address.next(response.myInfo.address);
      let url = "/dashboard/overview";
      if (!autoLogin) {
        url = !!this.authService.redirectUrl
          ? this.authService.redirectUrl
          : url;
      }
      if (url.includes("market-place")) {
        sessionStorage.setItem("redirectToDashboard", "true");
      }
      this.router.navigateByUrl(url, {
        state: {
          data: autoLogin ? "autoLogin" : "",
          fromService: true,
        },
      });
    });
  }

  marketplaceDetailsAnalytics(techDetails: MarketPlaceAnalyticsModel): void {
    if (!!techDetails.detail || !!techDetails.linkId) {
      const marketplaceDetails: AnalyticsModel = {
        marketplaceAnalytics: techDetails,
      };
      this.getAnalyticsAPI(marketplaceDetails).subscribe(
        (data) => {},
        (error) => console.error(error)
      );
    }
  }

  dashboardDetailsAnalytics(detail: string, tech?: string) {
    let dashboardDetails = {
      dashboardAnalytics: {
        detail,
        tech,
      },
    };
    this.getAnalyticsAPI(dashboardDetails).subscribe(
      (data) => {},
      (error) => console.error(error)
    );
  }

  getAnalyticsAPI(details: AnalyticsModel): Observable<{ message: string }> {
    const URL = !this.getUserDetails()
      ? this.analyticsUrl
      : this.analyticsUrl + `/${this.getUserDetails().userName}`;
    return this.httpClient.put<{ message: string }>(URL, details);
  }

  getPaymentTypeLabel(type: string): string {
    switch (type) {
      case "loan":
        return "Monthly loan payment";
      case "lease":
        return "Monthly lease payment";
      default:
        return "Net cost";
    }
  }

  getICInfo(type: string): void {
    if (type === "standard") {
      this.icInfo = {
        title: "Induction Cooking",
        tooltip:
          "Professional chefs as well as consumers are turning to induction cooking because of its speed and accurate temperature control. Also, when replacing natural gas, induction dramatcally reduces indoor air pollution.",
        tagline: "Enjoy Cooking",
        image: "assets/lifestyle/cooktop.png",
      };
    } else if ((type = "portable")) {
      this.icInfo = {
        title: "Portable Induction Cooktop",
        tooltip:
          "Professional chefs as well as consumers are turning to induction cooking because of its speed and accurate temperature control. Also, when replacing natural gas, induction dramatcally reduces indoor air pollution.",
        tagline: "Enjoy Cooking",
        image: "assets/lifestyle/portable-cooktop.png",
      };
    }
  }

  techMessage(techName: string, status: string): string {
    switch (techName) {
      case "solar":
        if (status === "add") {
          return "Savings Increased! Your solar system will now help power your selected energy choices, reducing the need to buy energy from your utility.";
        } else if (status === "remove") {
          return "Savings Decreased! Solar removed -- This will result in increased utility bills.";
        }
      case "battery":
        if (status === "add") {
          return "Battery added! Safeguard your home and power-critical appliances during a power outage.";
        } else if (status === "remove") {
          return "Battery removed! Fossil-fueled backup power sources are noisy and less eco-friendly.";
        }
      case "hpsc":
        if (status === "add") {
          return "Heating and cooling added! Your air source heat pump will keep your home comfortable all year round and cut your carbon footprint.";
        } else if (status === "remove") {
          return "Heating and cooling removed! Conventional heating systems will likely increase your carbon footprint.";
        }
      case "hpd":
        if (status === "add") {
          return "Clothes dryer added! Heat pump dryers are gentler on your clothes and easy to install with no venting needed.";
        } else if (status === "remove") {
          return "Clothes dryer removed! Conventional dryers use more energy, making them less eco-friendly.";
        }
      case "hpwh":
        if (status === "add") {
          return "Water heater added! Save money and reduce emissions with your all-electric heat pump water heater.";
        } else if (status === "remove") {
          return "Water heater removed! Conventional water heaters can cost you more.";
        }
      case "ic":
        if (status === "add") {
          return " Standard Cooktop added! Switching to an induction cooktop will reduce toxic indoor air pollution.";
        } else if (status === "remove") {
          return " Standard Cooktop removed! Gas cooktops generate unsafe levels of indoor air pollution.";
        }
      case "portableIC":
        if (status === "add") {
          return "Portable Cooktop added! Switching to an induction cooktop will reduce toxic indoor air pollution.";
        } else if (status === "remove") {
          return "Portable Cooktop removed! Gas cooktops generate unsafe levels of indoor air pollution.";
        }
      case "ev":
      case "ev1":
      case "ev2":
        if (status === "add") {
          return "Electric vehicle added! Bid goodbye to gas station visits, oil changes, and transmission troubles.";
        } else if (status === "remove") {
          return "Electric vehicle removed! Gasoline-powered vehicles increase your total cost of ownership.";
        }
      case "eBike":
        if (status === "add") {
          return "Electric bike added! Start riding into a healthier and greener lifestyle.";
        } else if (status === "remove") {
          return "Electric bike removed! Spending more time in a car commute leads to an unhealthy lifestyle.";
        }
      case "ledCflBulbs":
        if (status === "add") {
          return "LED bulbs added! Good job on taking a low-cost step toward a more-efficient home.";
        } else if (status === "remove") {
          return "LED bulbs removed! A missed opportunity to cut your electricity bills.";
        }
      case "smartThermostat":
        if (status === "add") {
          return "Smart thermostat added! Good job on taking a low-cost step toward a more-efficient home.";
        } else if (status === "remove") {
          return "Smart thermostat removed! A missed opportunity to cut your energy bills.";
        }
      case "communitySolar":
        if (status === "add") {
          return "Community solar added! Your electricity will cost a little less and it’ll be carbon free.";
        } else if (status === "remove") {
          return "Community solar removed! This will increase your carbon footprint.";
        }
      case "evChargers":
        if (status === "add") {
          return "EV charger added! Enjoy the convenience of home charging.";
        } else if (status === "remove") {
          return "EV charger removed! You can still charge up at a local public charging station.";
        }
      case "weatherStripping":
        if (status === "add") {
          return "Weather stripping added! This will help keep your home comfortable all year long.";
        } else if (status === "remove") {
          return "Weather stripping removed! Your home might be a little less comfortable.";
        }
      case "smartPowerStrips":
        if (status === "add") {
          return "Smart power strips added! This will reduce the 'vampire' loads that suck electricity from your home.";
        } else if (status === "remove") {
          return "Smart power strips removed! This will result in greater electricity use by computers, TVs, etc. in standby mode.";
        }
      case "showerHeadsAerators":
        if (status === "add") {
          return "Low-flow showerheads and aerators added! This will reduce both your energy usage and your water usage.";
        } else if (status === "remove") {
          return "Low-flow showerheads and aerators removed! This is a missed opportunity for saving energy and water.";
        }
      case "everGreen":
        if (status === "add") {
          return "Green electricity added! Your electricity will cost a little more, but you'll be reducing your carbon footprint.";
        } else if (status === "remove") {
          return "Green electricity removed! This will increase your carbon footprint.";
        }
      case "portableBattery":
        if (status === "add") {
          return "Portable battery added! Safeguard your home and power-critical appliances during a power outage.";
        } else if (status === "remove") {
          return "Portable battery removed! Fossil-fueled backup power sources are noisy and less eco-friendly.";
        }
      default:
        return "";
    }
  }

  get isApproach2RC(): boolean {
    const buildingTypes = ["renter", "condominium"];
    return (
      buildingTypes.includes(this.getBuildingType()) &&
      (this.vendorDetails.renterApproach === "approach2" ||
        this.vendorDetails.condominiumApproach === "approach2")
    );
  }

  getErrorCheckingDetails(): Observable<errorCheckingDetailsModel> {
    return this.httpClient.get<errorCheckingDetailsModel>(
      this.errorCheckingDetailsUrl + this.userDetails.userName
    );
  }
}
