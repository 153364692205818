import { AuthenticationService } from "../../auth/auth.service";
import { JWTTokenInfoModel } from "../../auth/auth.model";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { DashboardLayoutService } from "../shared/dashboard-layout/dashboard-layout.service";
import { EnergyStatusModel } from "../../shared/energy-info/energy-info.model";
import { UserDataModel } from "../overview/model/userdata.model";
import { RestAPIService } from "../../rest-api/rest-api.service";
import { OverviewService } from "../overview/overview.service";

@Component({
  selector: "yt-energy-info",
  templateUrl: "energy-info.component.html",
  styleUrls: ["./energy-info.component.scss"],
})
export class EnergyInfoComponent implements OnInit, OnDestroy {
  authorizationSection = true;
  authorizationSectionPGE = true;
  haveResponse: boolean = false;
  authorizeEnergyUsageUrl;
  userDataModel: UserDataModel;
  EnergyUsageScreen: boolean;
  pgeSuccess: boolean;
  pgeInprogress: boolean;
  authorizeLink: boolean = true;
  loader: boolean = false;
  energyStatusModel: EnergyStatusModel;
  authorizedClicked: boolean;
  revokedAuth: boolean = true;
  utilityName: string;
  utilityForm: FormGroup;
  jwtTokenInfo: JWTTokenInfoModel;

  constructor(
    private router: Router,
    private layoutServiceV2: DashboardLayoutService,
    private authenticationService: AuthenticationService,
    private overviewService: OverviewService,
    private restAPI: RestAPIService,
    private fb: FormBuilder
  ) {
    this.jwtTokenInfo = this.authenticationService.jwtTokenInfo;
  }

  ngOnInit() {
    const personElement = document.getElementById("person");
    if (personElement) personElement.style.fontWeight = "bold";
    this.overviewService.userSettingInfo.subscribe((response) => {
      this.userDataModel = response;
      this.userDataModel.buildingType = response.buildingType;
      this.userDataModel.userStatus = response.userStatus;
      this.utilityName = response.elecUtilityName;
      this.utilityForm = this.fb.group({
        utilityName: [this.utilityName, Validators.required],
      });
      this.getEnergyStatus();
      this.getUtilityProvider();
    });

    this.layoutServiceV2.hideSidenavSubject.next(false);
  }

  onTabChange(hi) {
    switch (hi) {
      case 0:
        this.authorizeLink = true;
        break;
      case 1:
        this.authorizeLink = false;
        break;
      default:
        break;
    }
  }

  getUtilityProvider() {
    this.restAPI.getUtilityProvider().subscribe((data) => {
      this.haveResponse = true;
      if (data.code === "PGE") {
        if (data.website.oauth) {
          this.authorizationSectionPGE = true;
          this.authorizeEnergyUsageUrl =
            data.website.oauth +
            this.jwtTokenInfo.userName +
            ";" +
            this.jwtTokenInfo.solarVendorCode +
            ";" +
            this.userDataModel.buildingType;
        } else {
          this.authorizationSectionPGE = false;
        }
      }
    });
  }

  onRefresh() {
    this.restAPI.energyStatusAPI.subscribe((response: EnergyStatusModel) => {
      if (response.message === "NO AUTH") {
        this.pgeInprogress = false;
        this.pgeSuccess = false;
        this.EnergyUsageScreen = true;
        this.authorizedClicked = false;
        this.revokedAuth = true;
      } else if (response.message === "IN PROGRESS") {
        this.pgeInprogress = true;
        this.pgeSuccess = false;
        this.EnergyUsageScreen = false;
        this.authorizedClicked = true;
      } else if (response.message === "DATA RETRIEVED") {
        this.pgeInprogress = false;
        this.pgeSuccess = true;
        this.EnergyUsageScreen = false;
        this.authorizedClicked = true;
      } else if (
        response.message === "REVOKED AUTH" ||
        response.message === "SINGLE DATA"
      ) {
        this.pgeInprogress = false;
        this.pgeSuccess = false;
        this.EnergyUsageScreen = true;
        this.authorizedClicked = false;
        this.revokedAuth = false;
      }
      if (this.authorizedClicked) {
        this.loader = true;
        const loaderElement = document.getElementById("loader");
        if (loaderElement) loaderElement.style.display = "block";
      } else {
        const noAuthElement = document.getElementById("noAuth");
        if (noAuthElement) noAuthElement.style.display = "block";
      }
      setTimeout(() => {
        this.loader = false;
        this.offRefresh();
      }, 5000);
    });
  }

  offRefresh() {
    const loaderElement = document.getElementById("loader");
    const noAuthElement = document.getElementById("noAuth");
    if (loaderElement) loaderElement.style.display = "none";
    if (noAuthElement) noAuthElement.style.display = "none";
  }

  getEnergyStatus() {
    this.restAPI.energyStatusAPI.subscribe((response: EnergyStatusModel) => {
      this.energyStatusModel = response;
      this.energyStatusModel.message = response.message;
      if (this.energyStatusModel.message === "NO GBC") {
        this.authorizationSection = false;
        this.EnergyUsageScreen = true;
        this.revokedAuth = true;
        this.pgeInprogress = false;
        this.pgeSuccess = false;
      } else if (this.energyStatusModel.message === "DATA RETRIEVED") {
        this.pgeInprogress = false;
        this.pgeSuccess = true;
        this.EnergyUsageScreen = false;
        this.revokedAuth = true;
      } else if (this.energyStatusModel.message === "IN PROGRESS") {
        this.pgeInprogress = true;
        this.pgeSuccess = false;
        this.EnergyUsageScreen = false;
        this.revokedAuth = true;
      } else if (this.energyStatusModel.message === "NO AUTH") {
        this.pgeInprogress = false;
        this.pgeSuccess = false;
        this.EnergyUsageScreen = true;
        this.revokedAuth = true;
      } else if (
        response.message === "REVOKED AUTH" ||
        response.message === "SINGLE DATA"
      ) {
        this.pgeInprogress = false;
        this.pgeSuccess = false;
        this.EnergyUsageScreen = true;
        this.authorizedClicked = false;
        this.revokedAuth = false;
      }
    });
  }

  onMydashboard() {
    this.router.navigateByUrl("/dashboard/overview");
  }

  ngOnDestroy(): void {
    this.haveResponse = false;
    const personElement = document.getElementById("person");
    if (personElement) personElement.style.fontWeight = "normal";
  }
}
