import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DashboardAPIService } from "../../../../../../../rest-api/dashboard/dashboard-api.service";
import { DialogBoxComponent } from "../../../../../../../shared/energy-info/Dialog-box/Dialog-box.component";

@Component({
  selector: "yt-share-my-data",
  templateUrl: "./share-my-data.component.html",
  styleUrls: ["./share-my-data.component.scss"],
})
export class ShareMyDataComponent implements OnInit {
  vendorName: string;
  vendorCode: string;
  solutionType: string;
  disableText: boolean;
  yellowTinInfo: boolean;
  solarInstaller: boolean;
  haveResponse: boolean;
  certificateLink: string;
  @Input() authorizedURL: string;
  @Input() isDashboard: boolean;
  @Input() authorizationSectionPGE: boolean;
  @Input() isPGE: boolean;
  @Input() revokedAuth: boolean;
  @Output() pgeStartStatus: EventEmitter<void> = new EventEmitter();

  constructor(
    private dashboardAPI: DashboardAPIService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.certificateLink =
      "https://seal.godaddy.com/verifySeal?sealID=abhS69r7UJ5LjuP0qBzd1r49UwOVkeVXipTFPM4Jwp3bk8k4MGWVEYTSDe4q";
    this.vendorName = this.dashboardAPI.getSolarVendorDescrption();
    if (!this.vendorName) {
      this.vendorName = this.dashboardAPI.getSolarVendorDescrption();
    }
    this.vendorCode = this.dashboardAPI.userDetails.solarVendorCode;
    this.solutionType = this.dashboardAPI.vendorDetails.solutionType;
    if (this.solutionType === "broader" || this.solutionType === "cce") {
      this.disableText = false;
      this.yellowTinInfo = true;
      this.solarInstaller = false;
    } else if (this.solutionType === "vendor") {
      this.disableText = true;
      this.yellowTinInfo = false;
      this.solarInstaller = true;
    }
  }

  openCertificate() {
    window.open(
      "https://seal.godaddy.com/verifySeal?sealID=abhS69r7UJ5LjuP0qBzd1r49UwOVkeVXipTFPM4Jwp3bk8k4MGWVEYTSDe4q",
      "newwindow",
      "width=700,height=500"
    );
    return false;
  }

  openDialogBox(label: string) {
    let dialogRef = this.dialog.open(DialogBoxComponent, {
      width: "650px",
      height: "auto",
      autoFocus: false,
      backdropClass: "dark-backdrop",
      data: {
        link: label,
        link2: this.authorizedURL,
        dashboard: this.isDashboard,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === "pgeStart") {
        this.pgeStartStatus.emit();
      }
    });
  }
}
