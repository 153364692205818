import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { IncentivesAPIService } from "../../../incentives/incentives.service";
import { RedirectLoaderModule } from "../../../shared/redirect-loader";
import { ScrollTopModule } from "../../../shared/scroll-top";
import { DashboardFooterModule } from "../../shared/dashboard-footer";
import { LoaderModule } from "../../shared/loader";
import { TechCardInitial } from "../../shared/mini-cards/tech-cards/tech-card.init";
import { TourGuideModule } from "../../shared/tour-guide";
// import { AlertCarouselComponent } from "../dashboard-v3/alert-carousel/alert-carousel.component";
import { AlreadyHaveComponent } from "./already-have/already-have.component";
import { AlreadyTechCardComponent } from "./already-have/already-tech-card/already-tech-card.component";
import { DashboardV2Component } from "./dashboard-v2.component";
import { HomeSettingsComponent } from "./home-settings/home-settings.component";
// import { IncentivesComponent } from "./incentives/incentives.component";
import { RecommendationComponent } from "./recommendation/recommendation.component";
import { SettingOverlayModule } from "./setting-overlay";
import { TranslateModule } from "@ngx-translate/core";
import { GridSavvyComponent } from "./grid-savvy/grid-savvy.component";
import { AdviceCardModule } from "../../shared/electrification-advice-card";
import { CleanChoicesModule } from "./clean-choices";
// import { IncentivesOverlayModule } from "./incentives/incentives-overlay";
// import { TechCardsComponent } from "../../shared/mini-cards/tech-cards/tech-cards.component";
@NgModule({
  imports: [
    ScrollTopModule,
    DashboardFooterModule,
    MatTabsModule,
    CommonModule,
    MatButtonModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatIconModule,
    MatTooltipModule,
    TourGuideModule,
    LoaderModule,
    RedirectLoaderModule,
    SettingOverlayModule,
    TranslateModule,
    AdviceCardModule,
    CleanChoicesModule,
    // IncentivesOverlayModule,
  ],
  exports: [DashboardV2Component],
  declarations: [
    DashboardV2Component,
    RecommendationComponent,
    AlreadyHaveComponent,
    // IncentivesComponent,
    HomeSettingsComponent,
    // AlertCarouselComponent,
    AlreadyTechCardComponent,
    GridSavvyComponent,
    // TechCardsComponent,
  ],
  providers: [TechCardInitial, IncentivesAPIService],
})
export class DashboardV2Module {}
