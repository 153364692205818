import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MatRadioChange } from "@angular/material/radio";
import { DashboardAPIService } from "../../../../../rest-api/dashboard/dashboard-api.service";
import { SolarBatteryDataModel } from "../../../model/userdata.model";
import {
  HomeSettingDataModel,
  SettingsDialogData,
} from "../setting-overlay.component.model";
import { OverviewService } from "../../../overview.service";

@Component({
  selector: "yt-solar-battery",
  templateUrl: "solar-battery.component.html",
  styleUrls: ["../common-setting-overlay.scss"],
})
export class SolarBatteryComponent implements OnInit {
  displaySolarBattery: HomeSettingDataModel[] = [];
  defaultValue: string;
  buildingType: string;
  @Input() activeTech: string;
  @Input() activeIcon: string;
  @Input() solarBatteryTechDetails: SolarBatteryDataModel;
  @Input() solarBatteryJSONDetails: HomeSettingDataModel[];
  @Output() techSave: EventEmitter<SettingsDialogData> = new EventEmitter();

  constructor(
    private dashboardAPIService: DashboardAPIService,
    private overviewService: OverviewService
  ) {}

  ngOnInit(): void {
    this.buildingType = this.dashboardAPIService.buildType;
    Object.keys(this.solarBatteryTechDetails).forEach((tech) =>
      this.solarBatteryJSONDetails.find((solarBattery) => {
        if (tech === solarBattery.value) {
          const temp = solarBattery;
          if (
            solarBattery.value === "solar" &&
            this.overviewService.updateEnergyChoiceInfo(this.buildingType)
          ) {
            temp.viewValue = "Community Solar";
          }
          this.displaySolarBattery.push(temp);
        }
      })
    );
    this.setDefaultValues(this.activeTech || this.displaySolarBattery[0].value);
  }

  setDefaultValues(value: string): void {
    this.defaultValue = value;
  }

  onTechChange(event: MatRadioChange): void {
    this.activeIcon = "";
    this.setDefaultValues(event.value);
  }

  emitTech(value: SettingsDialogData): void {
    this.techSave.emit(value);
  }
}
