import { Component, Input, OnInit } from "@angular/core";
import { DashboardAPIService } from "../../../rest-api/dashboard/dashboard-api.service";

@Component({
  selector: "yt-electrification-advice-card",
  templateUrl: "./electrification-advice-card.component.html",
  styleUrls: ["./electrification-advice-card.component.scss"],
})
export class ElectrificationAdviceCardComponent implements OnInit {
  mailText: string;
  @Input() tech: string;
  cardStatus: boolean;
  showPopup: boolean = false;
  constructor(private dashboardAPI: DashboardAPIService) {}

  ngOnInit(): void {
    this.cardStatus = this.tech === undefined ? false : true;
  }

  onConnect(): void {
    this.showPopup = true;
    if (this.tech) {
      this.dashboardAPI.dashboardDetailsAnalytics("expertAdvice", this.tech);
    } else {
      this.dashboardAPI.dashboardDetailsAnalytics("expertAdvice");
    }
  }

  onClosePopup(): void {
    this.showPopup = false;
  }
}
