import { Component, OnDestroy, OnInit } from "@angular/core";
import { AppService } from "../app.service";
import { SolarVendorModel } from "../rest-api/rest-api.model";
import { RestAPIService } from "../rest-api/rest-api.service";
import { HomeOwnerInfoService } from "./homeowner-info.service";

@Component({
  selector: "app-hp-journey",
  templateUrl: "./homeowner-info.component.html",
})
export class HomeOwnerInfoComponent implements OnInit, OnDestroy {
  vendorInfo: SolarVendorModel;

  constructor(
    private appService: AppService,
    private restAPI: RestAPIService,
    private homeownerInfoService: HomeOwnerInfoService
  ) {}

  ngOnInit(): void {
    if (this.restAPI.solarVendorInformation) {
      this.vendorInfo = this.restAPI.solarVendorInformation;
      this.homeownerInfoService.vendorInfo = this.vendorInfo;
    } else {
      this.appService.solarVendorModel$.subscribe((response) => {
        this.vendorInfo = response;
        this.homeownerInfoService.vendorInfo = this.vendorInfo;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.restAPI.homeOwnerEmail) {
      this.restAPI.homeOwnerEmail = "";
    }
    if (this.restAPI.responseId) {
      this.restAPI.responseId = "";
    }
  }
}
