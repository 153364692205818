import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { environment } from "../../environments/environment";
import {
  IncentivesDetailsRequest,
  InfoRequest,
  InfoResponse,
  TechnologyIncentivesModel,
} from "./incentives.model";
import { AppService } from "../app.service";
import { DashboardAPIService } from "../rest-api/dashboard/dashboard-api.service";
import { RoofTypesModel } from "../rest-api/rest-api.model";

@Injectable()
export class IncentivesAPIService {
  vendorCode: string;
  public readonly API_BASE_URL = environment.API_URL;
  public readonly dasboardIncentivesUrl = `${this.API_BASE_URL}ho/dashboard/incentives/`;
  public readonly API_END_POINT = `${this.API_BASE_URL}lp/incentives/`;
  private readonly incentivesInfoUrl = this.API_END_POINT + "info";
  private readonly incentivesDetailsUrl = this.API_END_POINT + "details";

  constructor(
    private httpClient: HttpClient,
    private appService: AppService,
    private dashboardAPI: DashboardAPIService
  ) {
    this.vendorCode = this.appService.getVendorName();
  }

  getBuildingTypeList(): Observable<RoofTypesModel[]> {
    return this.httpClient.get<RoofTypesModel[]>(
      `${this.API_END_POINT}${this.vendorCode}`
    );
  }

  getIncentivesInfo(payload: InfoRequest): Observable<InfoResponse> {
    return this.httpClient.put<InfoResponse>(this.incentivesInfoUrl, payload);
  }

  getIncentivesDetails(
    payload: IncentivesDetailsRequest
  ): Observable<TechnologyIncentivesModel> {
    return this.httpClient.put<TechnologyIncentivesModel>(
      this.incentivesDetailsUrl,
      payload
    );
  }

  getDashboardIncentivesDetails(
    techName: string
  ): Observable<TechnologyIncentivesModel> {
    const userEmail = this.dashboardAPI.getUserDetails().userName;
    return this.httpClient.get<TechnologyIncentivesModel>(
      `${this.dasboardIncentivesUrl}${userEmail}?tech=${techName}`
    );
  }
}
