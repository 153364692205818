import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterContentChecked,
} from "@angular/core";
import { TechDetailsModel } from "../../model/dashboard.init.model";

@Component({
  selector: "yt-already-have",
  templateUrl: "./already-have.component.html",
  styleUrls: ["./already-have.component.scss"],
})
export class AlreadyHaveComponent implements OnInit, AfterContentChecked {
  barPoints = new Array(5);
  @Input() techList: TechDetailsModel[];
  @Output() removeTech: EventEmitter<string> = new EventEmitter();
  @Input() progressValue: number;

  constructor() {}

  ngAfterContentChecked(): void {
    this.updateBars();
  }

  ngOnInit(): void {}

  onRemove(value: string): void {
    this.removeTech.emit(value);
  }

  updateBars(): void {
    const completeBars = Math.floor(
      (this.progressValue / 100) * this.barPoints.length
    );
    const remainder =
      (this.progressValue / 100) * this.barPoints.length - completeBars;
    const listElements = document.querySelectorAll(
      ".bar_point"
    ) as NodeListOf<HTMLElement>;

    for (let i = 0; i < listElements.length; i++) {
      listElements[i].style.background = "";
    }

    for (let i = 0; i < completeBars; i++) {
      listElements[i].style.background = `linear-gradient(to right, #73ae61 ${
        completeBars * 100
      }%, #ddd ${completeBars * 100}% )`;
    }

    if (completeBars < listElements.length) {
      const lastBar = listElements[completeBars];
      lastBar.style.background = `linear-gradient(to right, #73ae61 ${
        remainder * 100
      }%, #ddd ${remainder * 100}% )`;
    }
  }
}
