<scroll-top></scroll-top>
<div class="page-container yt_market-place">
  <div class="back_button w-100 tl">
    <span class="fa fa-chevron-left pointer" (click)="onBackClick()"></span>
    <span class="back_text" (click)="onBackClick()">Back</span>
  </div>
  <div class="padding-back-button w-100 center">
    <div class="yt_maxWidth w-100 center">
      <div class="header">Marketplace</div>
      <div class="subHeader">
        Welcome to your one-stop shop for all of your home clean energy
        solutions.
      </div>

      <yt-customized-tab
        *ngIf="marketPlaceTabsList"
        [tabHeadersList]="marketPlaceTabsList"
        (tabValue)="onTabChange($event)"
        [selectedIndex]="tabIndex"
        minWidth="270px"
      ></yt-customized-tab>
      <div class="content-wrapper">
        <div *ngIf="checkTabStatus('solarBattery') && solarBattery">
          <div class="mp_link" *ngIf="guidanceLink">
            <a (click)="onViewBuyingGuide()">View buying guide</a>
          </div>
          <div
            class="bg-white w-100 padding-3rem"
            *ngIf="!isPortable && !showList && !isLowe"
          >
            <div class="energySage-padding">
              <img [src]="solarLogo" alt="energy-sage" width="200px" />
            </div>
            <div class="yltn-subHeader">
              YellowTin has partnered with EnergySage to help you go solar.
            </div>
            <p class="energySage-content">
              EnergySage will help you compare solar options for your home.
            </p>
            <ul class="energySage-content energySage-list">
              <li>Learn and explore your options, all in one place</li>
              <li>Get free, expert advice from a dedicated Energy Advisor</li>
              <li>No phone calls unless you want them</li>
              <li>
                Save money on your electric bill while helping fight climate
                change!
              </li>
            </ul>
            <div class="width-button">
              <button
                mat-raised-button
                color="accent"
                class="w-100"
                (click)="
                  onRequestQuote(
                    communitySolar,
                    this.isPortable ? 'communitySolar' : 'solar'
                  )
                "
              >
                REQUEST QUOTES
              </button>
            </div>
            <div class="w-100 disclaimer_text">
              <div class="disclaimer_width">
                Information Disclosure: To prepare an accurate quote, EnergySage
                will use information from YellowTin, such as your basic
                information and historical electricity usage. By clicking
                "Request Quotes", you agree to EnergySage's
                <a
                  class="privacyPolicy-link"
                  href="https://www.energysage.com/terms-of-use"
                  target="_blank"
                  >Terms of Use</a
                >
                and
                <a
                  class="privacyPolicy-link"
                  href="https://www.energysage.com/privacy-policy"
                  target="_blank"
                  >Privacy Policy</a
                >.
              </div>
            </div>
          </div>

          <div *ngIf="isPortable && !showList">
            <div class="bg-white w-100 padding-3rem">
              <div class="energySage-padding">
                <img [src]="solarLogo" alt="energy-sage" width="200px" />
              </div>
              <div class="yltn-subHeader">
                YellowTin has partnered with EnergySage to help get your
                electricity from a local solar farm.
              </div>
              <p class="energySage-content">
                EnergySage will help you enjoy the benefits of solar without
                installing any equipment.
              </p>
              <ul class="energySage-content energySage-list">
                <li>Save up to 5-15% on your annual electricity costs</li>
                <li>Doesn’t matter whether you own or rent</li>
                <li>Lock in savings for 20 years</li>
                <li>Cancel anytime</li>
              </ul>
              <div class="button-padding">
                <button
                  mat-raised-button
                  color="accent"
                  class="w-100"
                  (click)="
                    onRequestQuote(
                      communitySolar,
                      this.isPortable ? 'communitySolar' : 'solar'
                    )
                  "
                >
                  SEARCH PROJECTS
                </button>
              </div>
            </div>
          </div>

          <div class="bg-white w-100 padding-3rem" *ngIf="isLowe">
            <div class="energySage-padding">
              <img [src]="solarLogo" alt="energy-sage" width="200px" />
            </div>
            <div class="yltn-subHeader">
              Savings powered by the sun with Lowe’s
            </div>
            <p class="energySage-content">
              Lowe’s is with you every step of the way. How it works:
            </p>
            <ul class="energySage-content energySage-list">
              <li>
                <span class="yltn-subHeader"
                  >Free Consultation & Estimate:</span
                >
                Fill out the form and schedule a consultation
              </li>
              <li>
                <span class="yltn-subHeader">Get Your Quote:</span> An
                assessment will be performed on the unique needs of your home. A
                quote will be provided including financing options with product
                and labor costs
              </li>
              <li>
                <span class="yltn-subHeader"> Installation Day:</span> Schedule
                an installation at your convenience and enjoy the benefits or
                solar
              </li>
            </ul>
            <div class="width-button">
              <button
                mat-raised-button
                color="accent"
                class="w-100"
                (click)="onRequestQuote(communitySolar, 'solar')"
              >
                REQUEST QUOTES
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="checkTabStatus('homeAppliances')">
          <yt-homeAppliances-tab
            *ngIf="homeAppliances && homeAppliancesJSON"
            [homeAppliances]="homeAppliances"
            [homeAppliancesJSON]="homeAppliancesJSON"
            [activeTech]="activeTech"
          ></yt-homeAppliances-tab>
        </div>

        <div *ngIf="checkTabStatus('energyEfficientDevices')">
          <yt-efficientDevices-tab
            *ngIf="energyEfficientDevices && energyEfficientJSON"
            [energyEfficientJSON]="energyEfficientJSON"
            [energyEfficientDevices]="energyEfficientDevices"
            [activeTech]="activeTech"
          ></yt-efficientDevices-tab>
        </div>

        <div
          class="disclosure-text"
          *ngIf="checkTabStatus('energyEfficientDevices') && showDisclosureText"
        >
          Disclosure: YellowTin is a participant in the Amazon Services LLC
          Associates Program, an affiliate advertising program designed to
          provide a way for websites to earn advertising revenues by advertising
          and linking to Amazon.com.
        </div>

        <div *ngIf="checkTabStatus('transportationAndAccessories')">
          <yt-transportation-tab
            *ngIf="transportationAndAccessories && transportationJSON"
            [transportationAndAccessories]="transportationAndAccessories"
            [activeTech]="activeTech"
            [transportationJSON]="transportationJSON"
          ></yt-transportation-tab>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="overlay" *ngIf="showSuccessPopup" (click)="offSuccessPopup()">
  <div id="text">
    <div class="yt_bg-white">
      <div class="yt_popup-header padding_all">Success!</div>
      <div class="padding_all">
        <div class="f4 yt_text">
          Your request for quote has been sent successfully. You should see an
          email from EnergySage with your next steps.
        </div>
        <div class="yt_spam-info">
          Don’t see email? Check your junk/ spam folder.
        </div>
      </div>
    </div>
  </div>
</div>

<!-- loader -->
<yt-loader *ngIf="loader"></yt-loader>
