import { LayoutService } from "./../shared/layout/layout.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  DropDownModel,
  SolarVendorModel,
  UserDetailsAPIPutModel,
} from "../../rest-api/rest-api.model";
import { AppService } from "../../app.service";
import { RestAPIService } from "../../rest-api/rest-api.service";
import { LifestyleService } from "../../rest-api/lifestyle.service";
import { UserDetailsDataModel } from "./my-info.model";
import { takeWhile } from "rxjs/operators";
import { RouterInfoModel } from "../shared/side-nav/router-info.model";
import { environment } from "../../../environments/environment";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "yt-my-info",
  templateUrl: "my-info.component.html",
  styleUrls: ["./my-info.component.scss"],
})
export class MyInfoComponent implements OnInit, OnDestroy {
  DEFAULT_MAP_LAT = 39.38198205951234;
  DEFAULT_MAP_LNG = -95.52742920692448;
  DEFAULT_MAP_ZOOM_LEVEL = 3.5;
  DEFAULT_MAP_TYPE_ID = google.maps.MapTypeId.ROADMAP;
  EMAIL_PATTERN =
    "^[_a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,4})$";
  formatedNumber: string;
  formatedAddress: string;
  address: FormControl;
  userDetailsForm: FormGroup;
  userDetails: UserDetailsDataModel;
  solarVendorModel: SolarVendorModel;
  building_type: DropDownModel[];
  addressFormatting = false;
  inValidAddress: boolean;
  mobilePlaceHolder: string;
  mobileNumberMandatory: boolean;
  header: string;
  subHeader: string;
  footer: string;
  loginaccess = false;
  cityStatus = false;
  stateStatus: boolean;
  public alive = true;
  updateMode = true;
  disableAdditionalInfo: boolean;
  hidePoweredBy: boolean = true;
  errorData;
  error: string;
  duplicationuserror = false;
  alreadyUser: boolean;
  loginLink: string;
  forgotPasswordLink: string;
  useremailId: boolean;
  emailChange: boolean;
  isDialogboxOpened: boolean = false;
  currentEmail: string;
  showErrorDialogueBox = false;
  invalidMail: boolean = false;
  mapArea: string;
  vendorLink: string;
  isWorkDayUser: boolean = false;
  solutionType: string;
  routeInfo: RouterInfoModel;
  initialUserDetailsValue: UserDetailsDataModel = {
    address: "",
    firstName: "",
    lastName: "",
    emailId: "",
    mobile: "",
    city: "",
    state: "",
    county: "",
    country: "",
    zipCode: "",
    latitude: "",
    longitude: "",
    buildingType: "",
    onContinue: false,
    sendMail: false,
    id: "",
  };
  userDetailsFormValidation = {
    address: "",
    firstName: "",
    lastName: "",
    mobile: "",
    emailId: {
      required: "",
      pattern: "",
    },
    buildingType: "",
  };
  validationErrorMessages = {
    address: {
      required: "Required!",
    },
    firstName: {
      required: "Required!",
    },
    lastName: {
      required: "Required!",
    },
    mobile: {
      required: "Required!",
    },
    emailId: {
      required: "Required!",
      pattern: "Invalid email!",
    },
    buildingType: {
      required: "Required!",
    },
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService,
    private layoutServiceV2: LayoutService,
    private restAPIService: RestAPIService,
    private lifeStyleService: LifestyleService,
    public dialog: MatDialog,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.inValidAddress = false;
    if (this.restAPIService.getUserProvidedInformation() === undefined) {
      this.restAPIService.setDestination(1);
      this.restAPIService.setBuildingType(
        this.initialUserDetailsValue.buildingType!
      );
      this.updateMode = false;
    }

    this.userDetailsForm = this.fb.group({
      address: ["", [Validators.required]],
      city: [""],
      state: [""],
      county: [""],
      country: [""],
      zipCode: [""],
      latitude: [""],
      longitude: [""],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      mobile: ["", Validators.minLength(10)],
      emailId: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      buildingType: ["", Validators.required],
      onContinue: [false],
      sendMail: [false],
      id: [""],
    });
    let vendorCode = this.appService.getVendorName();
    this.alreadyUser = vendorCode !== "SKYT" ? true : false;
    this.restAPIService.setVendorName(vendorCode);
    this.restAPIService
      .getSolarVendorDetails(vendorCode)
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {});
    this.appService.getSMDLink() && this.noRoofTop();
    this.titleService.setTitle(
      `Sign Up | ${this.appService.vendorsList[vendorCode].title}`
    );
    this.restAPIService
      .getSolarVendorDetails(vendorCode)
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {
        this.solarVendorModel = data;
        this.building_type = this.solarVendorModel.buildingTypes;
        this.solutionType = this.solarVendorModel.solutionType;
        this.restAPIService.setSolutionType(this.solutionType);
        if (this.solutionType === "broader" || this.solutionType === "cce") {
          this.header = "Explore clean energy savings";
          this.subHeader =
            "Begin by providing information about your home, energy choices and usage.";
        } else if (this.solutionType === "vendor") {
          this.header = "Get a free, customized solar estimate";
          this.subHeader =
            "Begin by providing information about your home, energy choices and usage.";
        }
        this.DEFAULT_MAP_LAT = this.solarVendorModel.map.mapLat;
        this.DEFAULT_MAP_LNG = this.solarVendorModel.map.mapLong;
        this.DEFAULT_MAP_ZOOM_LEVEL = this.solarVendorModel.map.mapZoom;
        this.footer = this.solarVendorModel.tagLine_en;
        this.vendorLink = this.solarVendorModel.vendorLink;
        let login = "dashboard/login";
        let forgot = "dashboard/forgot-password";
        const isProd = environment.production;

        if (vendorCode === "YSLR" || !isProd) {
          this.loginLink = `${this.vendorLink}${login}/?vendor=${vendorCode}`;
          this.forgotPasswordLink = `${this.vendorLink}${forgot}/?vendor=${vendorCode}`;
        } else {
          this.loginLink = `${this.vendorLink}${login}`;
          this.forgotPasswordLink = `${this.vendorLink}${forgot}`;
        }
        this.defaultGoogleMap();
        this.restAPIService.setSolarVendorDescription(
          this.solarVendorModel.description
        );
        this.restAPIService.setSolarVendorInformation(this.solarVendorModel);
        this.mobileNumberMandatory = this.solarVendorModel.phoneNumber;
        this.modifyMobileFormField(this.mobileNumberMandatory);
      });

    this.userDetailsForm.valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {
        this.onFormValueChanges(data);
      });
    let userEnteredDetails = this.restAPIService.getUserProvidedInformation();
    this.userDetails =
      userEnteredDetails !== undefined
        ? userEnteredDetails
        : this.initialUserDetailsValue;
    this.userDetailsForm.setValue(this.userDetails);
    this.retainCoordinates(this.userDetails.address!);
  }

  modifyMobileFormField(data) {
    this.mobilePlaceHolder = data ? "" : "(Optional)";
    if (data) {
      this.userDetailsForm.controls["mobile"].setValidators([
        Validators.required,
        Validators.minLength(10),
      ]);
      this.userDetailsForm.controls["mobile"].updateValueAndValidity();
    }
  }

  validateAddress(): boolean {
    return this.inValidAddress;
  }

  formatMobileNumber(mobile: string) {
    const numberLength = mobile.length;
    const mobileValue = mobile;
    if (mobileValue.match(/^\d+$/)) {
      let s2 = ("" + mobileValue).replace(/\D/g, "");
      let m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
      this.formatedNumber = !m ? "" : m[1] + "-" + m[2] + "-" + m[3];
      if (numberLength === 10) {
        this.userDetailsForm.controls["mobile"].setValue(this.formatedNumber);
      }
    } else {
      this.userDetailsForm.controls["mobile"].reset();
    }
  }

  onFormValueChanges(data: any) {
    if (!this.userDetailsForm) {
      return;
    }
    this.checkForErrorValidations(data);
    this.restAPIService.setBuildingType(
      this.userDetailsForm.controls["buildingType"].value
    );
    this.noRoofTop();
  }

  noRoofTop() {
    let smd = this.appService.getSMDLink();
    let building = this.restAPIService.getBuildingType();
    let showLifeStyleButton: boolean = true;
    let showUpgradesButton: boolean = false;
    let menuNumber: string;
    let showEnergyChoices: boolean;
    if (building === "nonProfit") {
      showLifeStyleButton = false;
      showUpgradesButton = true;
    } else {
      showLifeStyleButton = true;
      showUpgradesButton = false;
    }
    this.disableAdditionalInfo = this.solutionType !== "vendor";
    showEnergyChoices = this.solutionType !== "broader";
    menuNumber = this.disableAdditionalInfo ? "3" : "5";
    menuNumber = showEnergyChoices ? menuNumber : "2";
    if (smd) {
      this.layoutServiceV2.navRouterSubject.next({
        personalScreen: {
          showLink: true,
          activatedLink: true,
          disabledLink: false,
          menuNumber: "1",
        },
        roofTop: {
          showLink: false,
          activatedLink: false,
          disabledLink: true,
          menuNumber: "",
        },
        lifeStyle: {
          showLink: false,
          activatedLink: false,
          disabledLink: true,
          menuNumber: "",
        },
        additionalinfo: {
          showLink: false,
          activatedLink: false,
          disabledLink: true,
          menuNumber: "",
        },
        upgrades: {
          showLink: false,
          activatedLink: false,
          disabledLink: true,
          menuNumber: "",
        },
        energyUsage: {
          showLink: true,
          activatedLink: false,
          disabledLink: true,
          menuNumber: "2",
        },
        overview: {
          showLink: false,
          activatedLink: false,
          disabledLink: true,
          menuNumber: "",
        },
        logo1: {
          showLink: true,
          activatedLink: false,
          disabledLink: false,
          menuNumber: "",
        },
        logo2: {
          showLink: false,
          activatedLink: false,
          disabledLink: false,
          menuNumber: "",
        },
        usermenu: {
          showLink: false,
          activatedLink: false,
          disabledLink: false,
          menuNumber: "",
        },
        logout: {
          showLink: false,
          activatedLink: false,
          disabledLink: false,
          menuNumber: "",
        },
        poweredbyYt: {
          showLink: !this.hidePoweredBy,
          activatedLink: false,
          disabledLink: false,
          menuNumber: "",
        },
      });
    } else {
      this.layoutServiceV2.navRouterSubject.next({
        personalScreen: {
          showLink: true,
          activatedLink: true,
          disabledLink: false,
          menuNumber: "1",
        },
        roofTop: {
          showLink: !this.disableAdditionalInfo,
          activatedLink: false,
          disabledLink: true,
          menuNumber: "2",
        },
        lifeStyle: {
          showLink: showLifeStyleButton && showEnergyChoices,
          activatedLink: false,
          disabledLink: true,
          menuNumber: this.disableAdditionalInfo ? "2" : "3",
        },
        additionalinfo: {
          showLink: !this.disableAdditionalInfo,
          activatedLink: false,
          disabledLink: true,
          menuNumber: "4",
        },
        upgrades: {
          showLink: showUpgradesButton,
          activatedLink: false,
          disabledLink: true,
          menuNumber: "3",
        },
        energyUsage: {
          showLink: true,
          activatedLink: false,
          disabledLink: true,
          menuNumber: menuNumber,
        },
        overview: {
          showLink: false,
          activatedLink: false,
          disabledLink: true,
          menuNumber: "",
        },
        logo1: {
          showLink: true,
          activatedLink: false,
          disabledLink: false,
          menuNumber: "",
        },
        logo2: {
          showLink: false,
          activatedLink: false,
          disabledLink: false,
          menuNumber: "",
        },
        usermenu: {
          showLink: false,
          activatedLink: false,
          disabledLink: false,
          menuNumber: "",
        },
        logout: {
          showLink: false,
          activatedLink: false,
          disabledLink: false,
          menuNumber: "",
        },
        poweredbyYt: {
          showLink: !this.hidePoweredBy,
          activatedLink: false,
          disabledLink: false,
          menuNumber: "",
        },
      });
    }
  }

  checkForErrorValidations(data: any) {
    const form = this.userDetailsForm;
    const formErrors = this.userDetailsFormValidation;
    const validationmsg = this.validationErrorMessages;
    for (const field in formErrors) {
      if (field === "emailId") {
        formErrors[field] = {
          required: "",
          pattern: "",
        };
      } else {
        formErrors[field] = "";
      }
      const control = form.get(field);
      if (control && control.dirty && control.invalid) {
        const messages = validationmsg[field];
        for (const key in control.errors) {
          if (field === "emailId") {
            formErrors[field][key] += messages[key];
          } else {
            formErrors[field] += messages[key];
          }
        }
      }
    }
  }

  defaultGoogleMap() {
    this.configGoogleMap1(
      this.DEFAULT_MAP_LAT,
      this.DEFAULT_MAP_LNG,
      this.DEFAULT_MAP_ZOOM_LEVEL,
      this.DEFAULT_MAP_TYPE_ID
    );
    this.getGoogleMap();
  }

  configGoogleMap(latitude: number, longitude: number, zoomLevel: number) {
    const cordinates = new google.maps.LatLng(latitude, longitude);
    const mapOptions = {
      zoom: zoomLevel,
      center: cordinates,
      mapTypeId: google.maps.MapTypeId.SATELLITE,
      zoomControl: true,
      disableDefaultUI: true, // this will disable all map controls.
    };
    const map = new google.maps.Map(
      document.getElementById("map_canvas")!,
      mapOptions
    );
    map.setTilt(0);
    return { cordinates, map };
  }

  configGoogleMap1(
    latitude: number,
    longitude: number,
    zoomLevel: number,
    mapTypeId1: any
  ) {
    const cordinates = new google.maps.LatLng(latitude, longitude);
    const mapOptions = {
      zoom: zoomLevel,
      center: cordinates,
      mapTypeId: mapTypeId1,
      zoomControl: true,
      disableDefaultUI: true, // this will disable all map controls.
    };
    const map = new google.maps.Map(
      document.getElementById("map_canvas")!,
      mapOptions
    );
    map.setTilt(0);
    return { cordinates, map };
  }

  onValidateAddress(address: string) {
    this.inValidAddress = true;
    this.addressFormatting = false;
  }

  getGoogleMap() {
    const input = <HTMLInputElement>document.getElementById("placeAddress");
    const places = new google.maps.places.Autocomplete(input);
    google.maps.event.addListener(places, "place_changed", () => {
      const place = places.getPlace();
      const city = place.vicinity;
      let state: string = "",
        county: string = "",
        country: string = "",
        zipCode: string = "",
        latitude: number = 0,
        longitude: number = 0;
      place.address_components?.forEach((element) => {
        if (element.types[0] === "administrative_area_level_1") {
          state = element.long_name;
        }
        if (element.types[0] === "administrative_area_level_2") {
          county = element.long_name;
          let length = county.length;
          let countyLength = county.substring(length - 7, length);
          if (countyLength === " County") {
            county = county.substring(0, length - 7);
          }
        }
        if (element.types[0] === "country") {
          country = element.short_name;
        }
        if (element.types[0] === "postal_code") {
          zipCode = element.long_name;
        }
        latitude = place.geometry?.location?.lat()!;
        longitude = place.geometry?.location?.lng()!;
      });
      this.userDetailsForm.controls["city"].setValue(city);
      this.userDetailsForm.controls["state"].setValue(state);
      this.userDetailsForm.controls["county"].setValue(county);
      this.userDetailsForm.controls["country"].setValue(country);
      this.userDetailsForm.controls["zipCode"].setValue(zipCode);
      this.userDetailsForm.controls["latitude"].setValue(latitude);
      this.userDetailsForm.controls["longitude"].setValue(longitude);
      this.inValidAddress = false;
      this.formatedAddress = place.formatted_address!;
      this.addressFormatting = true;
      this.addressFormatting
        ? this.userDetailsForm.controls["address"].setValue(
            this.formatedAddress
          )
        : null;
      document.getElementById("buildingType")?.focus();
      this.triggerApi(false);
      const lat = place.geometry?.location?.lat()!;
      const lng = place.geometry?.location?.lng()!;
      const zoomLevel = 20;
      const updatedMap = this.configGoogleMap(lat, lng, zoomLevel);
      new google.maps.Marker({
        position: updatedMap.cordinates,
        map: updatedMap.map,
        title: place.formatted_address,
        icon: "assets/marker.png",
      });
    });
  }

  retainCoordinates(address: string) {
    let geo = new google.maps.Geocoder();
    geo.geocode({ address: address }, function (results, status) {
      if (status == google.maps.GeocoderStatus.OK && results) {
        const p = results[0].geometry.location;
        const lat = p.lat();
        const lng = p.lng();
        const cordinates = new google.maps.LatLng(lat, lng);
        const mapOptions = {
          zoom: 20,
          center: cordinates,
          mapTypeId: google.maps.MapTypeId.SATELLITE,
          zoomControl: true,
          disableDefaultUI: true,
        };
        const map = new google.maps.Map(
          document.getElementById("map_canvas")!,
          mapOptions
        );
        map.setTilt(0);
        new google.maps.Marker({
          position: cordinates,
          map: map,
        });
        return { cordinates, map };
      }
      return;
    });
    this.getGoogleMap();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  checkForEmailChange(onClick) {
    this.emailChange = onClick;
    let existEmail = this.restAPIService.homeOwnerEmail;
    this.currentEmail = this.userDetailsForm.controls["emailId"].value;
    this.currentEmail = this.currentEmail.toLowerCase();
    this.checkForWorkdayEmail(this.currentEmail);
    if (!this.isWorkDayUser) {
      if (existEmail !== undefined) {
        if (existEmail === this.currentEmail) {
          this.useremailId = false;
          this.onSubmit();
        } else {
          this.useremailId = true;
          this.openPopup();
        }
      } else {
        this.onSubmit();
      }
    }
  }

  triggerApi(sendMail: boolean) {
    if (
      this.userDetailsForm.controls["emailId"].value &&
      this.userDetailsForm.controls["emailId"].valid
    ) {
      if (sendMail) {
        this.invalidMail = false;
        this.userDetailsForm.controls["sendMail"].setValue(true);
      } else {
        this.userDetailsForm.controls["sendMail"].setValue(false);
      }
      this.checkForEmailChange(false);
    }
  }

  checkForWorkdayEmail(currentEmail: string): boolean {
    let index = this.currentEmail.lastIndexOf("@");
    let ind = this.currentEmail.lastIndexOf(".");
    let domainName = this.currentEmail.slice(index + 1, ind);
    if (domainName === "workday") {
      this.isWorkDayUser = true;
    } else {
      this.isWorkDayUser = false;
    }
    return this.isWorkDayUser;
  }

  sideNavForSMD() {
    this.layoutServiceV2.navRouterSubject.next({
      personalScreen: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "1",
      },
      roofTop: {
        showLink: false,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "2",
      },
      lifeStyle: {
        showLink: false,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "3",
      },
      additionalinfo: {
        showLink: false,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "4",
      },
      upgrades: {
        showLink: false,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "3",
      },
      energyUsage: {
        showLink: true,
        activatedLink: true,
        disabledLink: false,
        menuNumber: "2",
      },
      overview: {
        showLink: false,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "",
      },
      logo1: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      logo2: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      usermenu: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      logout: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      poweredbyYt: {
        showLink: !this.hidePoweredBy,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
    });
  }

  sideNavForNonSMD(menuNumber, building) {
    let showLifeStyleButton: boolean = true;
    let showUpgradesButton: boolean = false;
    if (building === "nonProfit") {
      showLifeStyleButton = false;
      showUpgradesButton = true;
    } else {
      showLifeStyleButton = true;
      showUpgradesButton = false;
    }
    menuNumber = this.disableAdditionalInfo ? "3" : "5";
    menuNumber = this.solutionType === "broader" ? "2" : "3";
    this.layoutServiceV2.navRouterSubject.next({
      personalScreen: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "1",
      },
      roofTop: {
        showLink: !this.disableAdditionalInfo,
        activatedLink: true,
        disabledLink: false,
        menuNumber: "2",
      },
      lifeStyle: {
        showLink: showLifeStyleButton && this.solutionType !== "broader",
        activatedLink: this.disableAdditionalInfo,
        disabledLink: !this.disableAdditionalInfo,
        menuNumber: this.disableAdditionalInfo ? "2" : "3",
      },
      additionalinfo: {
        showLink: !this.disableAdditionalInfo,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "4",
      },
      upgrades: {
        showLink: showUpgradesButton,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "3",
      },
      energyUsage: {
        showLink: true,
        activatedLink: this.solutionType === "broader",
        disabledLink: this.solutionType !== "broader",
        menuNumber: menuNumber,
      },
      overview: {
        showLink: false,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "",
      },
      logo1: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      logo2: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      usermenu: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      logout: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      poweredbyYt: {
        showLink: !this.hidePoweredBy,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
    });
  }

  onSubmit() {
    let building = this.restAPIService.getBuildingType();
    let menuNumber: string;
    this.inValidAddress = this.validateAddress();
    if (this.emailChange === true) {
      this.userDetailsForm.controls["onContinue"].setValue(true);
    } else {
      this.userDetailsForm.controls["onContinue"].setValue(false);
    }
    if (this.restAPIService.responseId) {
      this.userDetailsForm.get("id")?.setValue(this.restAPIService.responseId);
    }
    if (!this.isDialogboxOpened) {
      const userInfo = this.userDetailsForm.value;
      this.restAPIService
        .postUserDetailsForm(userInfo, this.addressFormatting)
        .subscribe(
          (data: UserDetailsAPIPutModel) => {
            if (!this.restAPIService.responseId) {
              this.restAPIService.responseId = data.id!;
            }
            if (
              !this.userDetailsForm.invalid &&
              this.emailChange &&
              data.validStatus
            ) {
              if (this.restAPIService.getSmdLink()) {
                this.router.navigate(["energy-info"], {
                  relativeTo: this.route,
                });
              }
              if (
                this.disableAdditionalInfo &&
                this.solutionType !== "broader"
              ) {
                this.router.navigate(["energy-choices"], {
                  relativeTo: this.route,
                });
                this.sideNavForNonSMD(menuNumber, building);
              } else if (
                (!this.disableAdditionalInfo && this.solutionType === "cce") ||
                this.solutionType === "vendor"
              ) {
                this.router.navigate(["roof-top"], {
                  relativeTo: this.route,
                });
                this.sideNavForNonSMD(menuNumber, building);
              } else {
                this.router.navigate(["energy-info"], {
                  relativeTo: this.route,
                });
                this.sideNavForNonSMD(menuNumber, building);
              }
            }
          },
          (error) => {
            if (error.status === 409) {
              this.openErrorDialogBox(error);
            }
          }
        );
    }
  }

  openErrorDialogBox(error) {
    this.error = error.error.message;
    if (this.error === "City Not Supported") {
      this.cityStatus = true;
      this.duplicationuserror = false;
      this.loginaccess = false;
    } else if (this.error === "State Not Supported") {
      this.cityStatus = true;
      this.duplicationuserror = false;
      this.loginaccess = false;
    } else if (this.error === "HO LOGIN") {
      this.loginaccess = true;
      this.duplicationuserror = true;
      this.cityStatus = false;
    } else if (this.error === "HO NO LOGIN") {
      this.loginaccess = false;
      this.duplicationuserror = true;
      this.cityStatus = false;
    }
    if (this.error === "INVALID" || this.error === "DO NOT MAIL") {
      if (this.emailChange) {
        this.invalidMail = true;
      }
      this.showErrorDialogueBox = false;
      this.isDialogboxOpened = false;
    } else {
      this.showErrorDialogueBox = true;
      this.isDialogboxOpened = true;
      this.invalidMail = false;
    }
  }

  closeErrorDialogBox() {
    this.isDialogboxOpened = false;
    this.showErrorDialogueBox = false;
  }

  openPopup() {
    this.isDialogboxOpened = true;
  }

  closePopup() {
    this.isDialogboxOpened = false;
    this.useremailId = false;
  }

  onYes() {
    this.isDialogboxOpened = false;
    this.restAPIService.resetCompleteData();
    this.lifeStyleService.resetLifestyleData();
    this.useremailId = false;
    this.onSubmit();
  }

  navigateToLogin(): void {
    window.open(this.loginLink, "_blank");
  }
}
