import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeOwnerDashboardComponent } from "./homeowner-dashboard.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { LoginComponent } from "./login/login.component";
import { LogoutComponent } from "./logout/logout.component";
import { AuthGuard } from "../auth/auth-guard.service";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { EnergyUsageOverviewComponent } from "./energy-usage-overview/energy-usage-overview.component";
import { MyInfoComponent } from "./my-info/my-info.component";
import { NotificationHomeComponent } from "./notification-center/notification-home/notification-setting.component";
import { NotificationSettingComponent } from "./notification-center/notification-setting/notification-setting.component";
import { NotificationCenterComponent } from "./notification-center/notification-center.component";
import { EnergyInfoComponent } from "./energy-info/energy-info.component";
import { SolarDetailsComponent } from "./learn-more/solar-details/solar-details.component";
import { BatteryDetailsComponent } from "./learn-more/battery-details/battery-details.component";
import { EBikeDetailsComponent } from "./learn-more/e-bike-details/e-bike-details.component";
import { EV1DetailsComponent } from "./learn-more/ev1-details/ev1-details.component";
import { EV2DetailsComponent } from "./learn-more/ev2-details/ev2-details.component";
import { LCEDetailsComponent } from "./learn-more/lce-details/lce-details.component";
import { LearnMoreComponent } from "./learn-more/learn-more.component";
import { HPWHDetailsComponent } from "./learn-more/hpwh-details/hpwh-details.component";
import { HPSCDetailsComponent } from "./learn-more/hpsc-details/hpsc-details.component";
import { ICDetailsComponent } from "./learn-more/ic-details/ic-details.component";
import { HPDDetailsComponent } from "./learn-more/hpd-details/hpd-details.component";
import { EVChargerDetailsComponent } from "./learn-more/ev-chargers-details/ev-chargers-details.component";
import { MarketPlaceComponent } from "./market-place/market-place.component";
import { CommunityDetailsComponent } from "./learn-more/solar-details/community-details/community-details.component";
import { LEDBulbsComponent } from "./learn-more/led-bulbs/led-bulbs.component";
import { SmartThermostatDetailsComponent } from "./learn-more/smart-thermostat-details/smart-thermostat-details.component";
import { WeatherStripsDetailsComponent } from "./learn-more/weather-strips-details/weather-strips-details.component";
import { SmartPowerStripDetailsComponent } from "./learn-more/smart-power-strip-details/smart-power-strip-details.component";
import { ShowerHeadsAeratorsDetailsComponent } from "./learn-more/shower-heads-aerators-details/shower-heads-aerators-details.component";
import { LearningHubComponent } from "./learning-hub/learning-hub.component";
import { EvergreenDetailsComponent } from "./learn-more/evergreen-details/evergreen-details.component";
import { PortableBatteryDetailsComponent } from "./learn-more/portable-battery-details/portable-battery-details.component";
import { DashboardV3Component } from "./overview/dashboard-v3/dashboard-v3.component";
import { MyListComponent } from "./my-list/my-list.component";

const routes: Routes = [
  {
    path: "",
    component: HomeOwnerDashboardComponent,
    data: { title: "Dashboard" },
    children: [
      {
        path: "",
        redirectTo: "/dashboard/login",
        pathMatch: "full",
        data: { title: "Login" },
      },
      { path: "login", component: LoginComponent, data: { title: "Login" } },
      {
        path: "forgot-password",
        component: ForgotPasswordComponent,
        data: { title: "Forgot Password" },
      },
      {
        path: "change-password",
        component: ChangePasswordComponent,
        canActivate: [AuthGuard],
        data: { title: "Change Password" },
      },
      {
        path: "my-list",
        component: MyListComponent,
        canActivate: [AuthGuard],
        data: { title: "My List" },
      },
      {
        path: "market-place",
        component: MarketPlaceComponent,
        canActivate: [AuthGuard],
        data: { title: "Marketplace" },
      },
      {
        path: "market-place/:details",
        component: MarketPlaceComponent,
        canActivate: [AuthGuard],
        data: { title: "MarketPlace-Details" },
      },
      {
        path: "market-place/:details/:tech",
        component: MarketPlaceComponent,
        canActivate: [AuthGuard],
        data: { title: ":tech" },
      },
      {
        path: "energy-usage",
        component: EnergyUsageOverviewComponent,
        canActivate: [AuthGuard],
        data: { title: "Usage" },
      },
      {
        path: "my-info",
        component: MyInfoComponent,
        canActivate: [AuthGuard],
        data: { title: "My Info" },
      },
      {
        path: "energy-info",
        component: EnergyInfoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "overview",
        canActivate: [AuthGuard],
        data: { title: "Dashboard" },
        children: [
          {
            path: "",
            component: DashboardV3Component,
          },
        ],
      },
      {
        path: "notification-center",
        component: NotificationCenterComponent,
        canActivate: [AuthGuard],
        data: { title: "Notifications" },
        children: [
          {
            path: "",
            component: NotificationHomeComponent,
          },
          {
            path: "setting",
            component: NotificationSettingComponent,
          },
        ],
      },
      {
        path: "details",
        component: LearnMoreComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "solar",
            component: SolarDetailsComponent,
            data: { title: "Rooftop Solar" },
          },
          {
            path: "community-solar",
            component: CommunityDetailsComponent,
            data: { title: "Community Solar" },
          },
          {
            path: "battery",
            component: BatteryDetailsComponent,
            data: { title: "Battery" },
          },
          {
            path: "portable-battery",
            component: PortableBatteryDetailsComponent,
            data: { title: "Portable Battery" },
          },
          {
            path: "electric-vehicle-1",
            component: EV1DetailsComponent,
            data: { title: "Electric Vehicle" },
          },
          {
            path: "electric-vehicle-2",
            component: EV2DetailsComponent,
            data: { title: "Electric Vehicle" },
          },
          {
            path: "water-heater",
            component: HPWHDetailsComponent,
            data: { title: "Heat Pump Water Heater" },
          },
          {
            path: "heating-cooling",
            component: HPSCDetailsComponent,
            data: { title: "Heat Pump Heating & Cooling" },
          },
          {
            path: "cooktop",
            component: ICDetailsComponent,
            data: { title: "Induction Cooking" },
          },
          {
            path: "dryer",
            component: HPDDetailsComponent,
            data: { title: "Heat Pump Clothes Dryer" },
          },
          {
            path: "electric-bike",
            component: EBikeDetailsComponent,
            data: { title: "Electric Bike" },
          },
          {
            path: "low-cost-efficiency",
            component: LCEDetailsComponent,
          },
          {
            path: "led-bulbs",
            component: LEDBulbsComponent,
            data: { title: "LED Bulbs" },
          },
          {
            path: "smart-thermostat",
            component: SmartThermostatDetailsComponent,
            data: { title: "Smart Thermostat" },
          },
          {
            path: "weather-stripping",
            component: WeatherStripsDetailsComponent,
            data: { title: "Weather Stripping" },
          },
          {
            path: "smart-power-strips",
            component: SmartPowerStripDetailsComponent,
            data: { title: "Smart Power Strips" },
          },
          {
            path: "showerheads-aerators",
            component: ShowerHeadsAeratorsDetailsComponent,
            data: { title: "Showerheads and Aerators" },
          },
          {
            path: "ev-chargers",
            component: EVChargerDetailsComponent,
            data: { title: "EV Charger" },
          },
          {
            path: "renewable-electricity",
            component: EvergreenDetailsComponent,
          },
        ],
      },
      {
        path: "learning-hub",
        data: { title: "Learning Hub" },
        children: [
          {
            path: "",
            component: LearningHubComponent,
          },
        ],
      },
      { path: "logout", component: LogoutComponent, data: { title: "Logout" } },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeOwnerDashboardRoutingModule {}
