import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { TourGuideComponent } from "./tour-guide.component";

@NgModule({
    imports: [CommonModule, MatIconModule],
    declarations: [TourGuideComponent,],
    providers: [],
    exports: [TourGuideComponent]
})
export class TourGuideModule {}
