<scroll-top></scroll-top>
<div class="yt__dashboard page-container">
  <div class="padding-class">
    <div class="yt_maxWidth">
      <div
        *ngIf="showMaxPanelError || showMinPanelError || showErrorMessage"
        class="flex center justify-center vh-75 flex-column items-center error_padding"
      >
        <div *ngIf="showMaxPanelError" class="w-100 tc">
          <div class="error_subheading_text">{{ errorPhrase1 }}</div>
        </div>
        <div *ngIf="showMinPanelError" class="w-100 tc">
          <div class="error_subheading_text">{{ errorPhrase2 }}</div>
        </div>
        <div *ngIf="showErrorMessage" class="w-100 tc">
          <img src="assets/dashboard/overview/error1.png" loading="lazy" />
          <div class="error_heading_text">Unexpected error!</div>
          <div class="error_subheading_text">
            Report the error to
            <a
              href="mailto:support@yellowtin.com"
              class="link_text no-underline pointer"
              >YellowTin support</a
            >
            team.
          </div>
        </div>
      </div>
      <div *ngIf="showDashboard">
        <yt-alert-carousel
          [showEmailAlert]="!selectedCategory && showEmailAlert"
          [showCategorySelectedAlert]="
            !!selectedCategory && showCategorySelectedAlert
          "
          [incentivesAmount]="incentivesAmount"
          [showErrorAlert]="!!selectedCategory && showErrorAlert"
          [errorTechnologiesList]="errorTechnologiesList"
          [showAlert]="!!selectedCategory && showAlert"
          [sliderAlert]="!!selectedCategory && sliderAlert"
          [revokedAlert]="!!selectedCategory && revokedAlert"
          [partialAlert]="!!selectedCategory && partialAlert"
          (closeEmail)="onCloseOfEmailAlert()"
          (closeAlert)="onAlertClose()"
          (closeOutcomeAlert)="onCloseOfErrorAlert()"
          (energyInfoStatus)="onEnergyUsage($event)"
        ></yt-alert-carousel>
        <div>
          <yt-incentives [totalIncentives]="incentivesAmount"></yt-incentives>
        </div>
        <div class="category-container">
          <div class="header">
            Choose the most important home improvement category
            <div class="yt_text_to_lcase">
              Time Frame
              <mat-form-field appearance="outline" class="select-field">
                <mat-select
                  [value]="timeSelected"
                  (selectionChange)="onValueChange($event.value)"
                >
                  <mat-option
                    *ngFor="let time of timeFrameOptions"
                    [value]="time.value"
                  >
                    {{ time.viewValue?.toLowerCase() }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <mat-radio-group class="category-list">
            <div class="category" *ngFor="let category of categoriesList">
              <label>
                <div
                  class="category-item"
                  [ngClass]="{
                    'active-box': selectedCategory === category.value
                  }"
                >
                  <img [src]="category.image" alt="" class="item-image" />
                  <div class="item-name">{{ category.name }}</div>
                  <div class="savings-text" *ngIf="savingsDetails">
                    Save up to
                    <span class="savings-value">
                      ${{
                        savingsDetails.categorySavings[category.value]
                          .dollarSavings | number
                      }}/
                      <span *ngIf="twentyYear">20yr</span>
                      <span *ngIf="tenYear">10yr</span>
                      <span *ngIf="oneYear">yr</span>
                      <span *ngIf="oneMonth">mo</span>
                    </span>
                  </div>
                </div>
                <mat-radio-button
                  (change)="onSelectGoal($event)"
                  [value]="category.value"
                  class="hidden_button absolute top-0"
                >
                </mat-radio-button>
              </label>
            </div>
          </mat-radio-group>
        </div>
        <div
          class="recommendation-container"
          *ngIf="!showCategoryLoader && selectedCategory"
        >
          <div class="header">
            View your {{ selectedCategoryLabel }} recommendations
            <div class="flex flex-column flex-row-ns">
              <button class="personalize-button" (click)="openPersonalize()">
                <mat-icon>settings</mat-icon> Personalize
              </button>
              <span
                class="renterText"
                *ngIf="showWhatChoices"
                (click)="openWhatChoices()"
                >&nbsp; What choices apply to me?</span
              >
            </div>
          </div>
          <ng-container
            *ngIf="recommendationList && recommendationList[selectedCategory]"
          >
            <ng-container
              *ngIf="
                selectedCategory === 'solarBattery' ||
                selectedCategory === 'appliances' ||
                selectedCategory === 'transportation'
              "
            >
              <div
                class="card_container"
                *ngIf="getApproach1Status()"
                [ngClass]="{ mt_24: isTechListAvailable() }"
              >
                <div class="image_container">
                  <img src="assets/lifestyle/MSP.png" />
                </div>
                <div class="content_wrapper">
                  <div class="mspHeader">Electrical Service Panel</div>
                  <div class="text_content">
                    As electricity-using devices (such as electric vehicles,
                    water heaters, etc.) are adopted, it is possible that older
                    homes might reach the capacity of their electrical service
                    panels. In such cases, steps need to be taken to either
                    replace the service panel with a higher capacity or
                    introduce power-sharing devices that sometimes produce the
                    same result at a lower cost.
                  </div>
                  <div class="read_more_link">
                    <span (click)="showServicePanelInfo()">Learn more</span>
                  </div>
                </div>
              </div>
              <div
                class="solar_alert"
                *ngIf="
                  showErrorMessageTab2 && selectedCategory === 'solarBattery'
                "
              >
                <div class="flex justify-between popUp_padding">
                  {{ solarErrorMessage }}
                </div>
              </div>
            </ng-container>
            <yt-tech-cards
              [tabLabel]="selectedCategory"
              [techList]="recommendationList[selectedCategory]"
              [timeFrame]="timeSelected"
              (onAdd)="onAdd($event)"
              (onRemove)="onRemove($event)"
              (alreadyHaveTech)="onAlreadyHave($event)"
              (updateSavings)="updateSavingsDetails()"
            ></yt-tech-cards>
          </ng-container>

          <ng-container *ngIf="!isApproach2RC">
            <yt-clean-choices
              *ngIf="selectedCategory === 'appliances'"
              [activeTab]="selectedCategory"
              [section]="'energyEfficiencyAppliances'"
              [activeTech]="activeTech"
            ></yt-clean-choices>
          </ng-container>

          <yt-clean-choices
            *ngIf="
              selectedCategory === 'simpleLowCost' ||
              selectedCategory === 'transportation' ||
              selectedCategory === 'appliances'
            "
            [activeTab]="selectedCategory"
            [section]="section"
            [activeTech]="activeTech"
          ></yt-clean-choices>

          <ng-container *ngIf="!isApproach2RC">
            <yt-clean-choices
              *ngIf="selectedCategory === 'appliances'"
              [activeTab]="selectedCategory"
              [section]="'yardAppliances'"
              [activeTech]="activeTech"
            ></yt-clean-choices>
          </ng-container>
        </div>
        <div class="category-loader" *ngIf="showCategoryLoader">
          <i class="fa fa-spinner fa-pulse fa-3x fa-fw" id="loader"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- recommendations popup for renter and condo user -->
<div id="renterPopup" (click)="closeWhatChoices()">
  <div id="text">
    <div class="yt_popup-header padding_all">What choices apply to me?</div>
    <div class="padding_all">
      <span *ngIf="buildingTypeRenter">
        Many of the choices listed below would require landlord support and may
        not be feasible for renters. We show these choices for educational
        purposes.
      </span>
      <span *ngIf="buildingTypeCondo">
        Many of the choices listed below would require Homeowners Association
        support and may not be feasible for condominiums or mobile homes. We
        show these choices for educational purposes.
      </span>
      <ul>
        <li>Battery</li>
        <li>Heat Pump Clothes Dryer</li>
        <li>Heat Pump Heating & Cooling</li>
        <li>Heat Pump Water Heater</li>
        <li>Induction Cooking</li>
        <li>Rooftop Solar</li>
      </ul>
      Please click on
      <mat-icon class="material-icons favorite_icon">favorite_border</mat-icon>
      to add the choice to 'favorites' or click on
      <mat-icon class="material-icons already_icon"
        >check_circle_outline</mat-icon
      >
      if you already have.
    </div>
  </div>
</div>

<!-- loader -->
<yt-loader
  [blackLoader]="showBlackLoader"
  *ngIf="loader || (showBlackLoader && isSavingsAPIDispatched)"
  top="0"
  [relative]="true"
></yt-loader>

<yt-redirect-loader
  [vendorCode]="vendorCode"
  *ngIf="showRedirectLoader"
></yt-redirect-loader>

<!-- Tour guide -->
<yt-tour-guide
  *ngIf="showTourGuide && !showRedirectLoader"
  (closeTourGuide)="closeTourGuide()"
  [vendorCode]="vendorCode"
  [stepObject]="tourGuideObject"
  (showHomeSettings)="openPersonalize()"
>
</yt-tour-guide>
