<div>
  <div class="yt_text">
    <div class="heading">
      {{ techName }}
      <span class="mp_link desktop_display" *ngIf="marketPlaceLink">
        <a (click)="onBuyingGuide()">View buying guide</a>
      </span>
    </div>

    <div class="paragraph">
      <span [innerHTML]="paragraphKey | translate"></span>
      <span>
        {{ "homeOwenerDashboard.marketPlace.naviagtion.left" | translate
        }}<span class="link" (click)="onLearnMore()">
          {{
            "homeOwenerDashboard.marketPlace.naviagtion.center" | translate
          }} </span
        >{{
          "homeOwenerDashboard.marketPlace.naviagtion.right" | translate
        }}</span
      >
    </div>

    <span class="mp_link mobile_display" *ngIf="marketPlaceLink">
      <a (click)="onBuyingGuide()">View buying guide</a>
    </span>

    <!-- <div class="flex padding_top">
      <img
        class="iconImage"
        src="assets/marketplace/price-tag.png"
        width="15px"
        height="25px"
      />
      <div class="paragraph ml3">
        Save up to $500 with incentives.<span class="link"> Click here </span>to
        know more.
      </div>
    </div> -->
  </div>
</div>
