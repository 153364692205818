<scroll-top></scroll-top>
<div class="page-container yt__learn_more">
  <yt-back [navigateTo]="'overview'"></yt-back>
  <div class="padding-back-button w-100 center">
    <div class="yt_maxWidth w-100 center">
      <div class="header_text tc">Battery</div>
      <yt-scroll-link-toggle
        *ngIf="!isLangSpanish"
        [activeLink]="selectedSection"
        [isAboutTech]="true"
        [isSavings]="isLoadShift"
        [isDescription]="!isLoadShift"
        [isSettings]="true"
        [isDetails]="true"
        [isAssumptions]="!!assumptionDetails"
        [isFaq]="true"
        [techParameter]="'battery'"
        (activeValue)="handleActiveValue($event)"
      ></yt-scroll-link-toggle>
      <div>
        <div
          class="yt-text battery-text"
          *ngIf="!isLoadShift"
          [innerHTML]="
            'homeOwenerDashboard.learnMore.battery.paragraph' | translate
          "
        ></div>
        <div
          class="yt-text battery-text"
          *ngIf="isLoadShift"
          [innerHTML]="
            'homeOwenerDashboard.learnMore.batteryLoadShifting.paragraph'
              | translate
          "
        ></div>
        <div *ngIf="!isLangSpanish" id="aboutTechnology">
          <yt-battery-FAQ [showAboutTech]="true"></yt-battery-FAQ>
        </div>
        <div class="faq_mobile">
          <button
            (click)="onFavorite()"
            class="favorite_btn"
            mat-raised-button
            color="accent"
          >
            <mat-icon
              class="favorite_icon"
              [ngClass]="{ favorite_icon_color: favStatus }"
              >{{ favIcon }}</mat-icon
            >FAVORITE
          </button>

          <button
            *ngIf="isLangSpanish"
            class="faq_button"
            mat-raised-button
            color="accent"
            (click)="handleFAQClick()"
          >
            MORE INFO
          </button>
        </div>
      </div>

      <div class="top_section w-100">
        <div class="faq_section">
          <div class="image_box">
            <img
              src="assets/lifestyle/battery.svg"
              alt="technology icon"
              [ngStyle]="{ height: !displayMarketPlaceBox ? '160px' : '250px' }"
            />
          </div>
          <button
            (click)="onFavorite()"
            class="favorite_btn mt_20"
            mat-raised-button
            color="accent"
          >
            <mat-icon
              class="favorite_icon"
              [ngClass]="{ favorite_icon_color: favStatus }"
              >{{ favIcon }}</mat-icon
            >FAVORITE
          </button>
          <button
            *ngIf="isLangSpanish"
            class="faq_button mt_20"
            mat-raised-button
            color="accent"
            (click)="handleFAQClick()"
          >
            MORE INFO
          </button>
        </div>

        <div class="width-box" id="cost">
          <div class="main_values_box">
            <div class="details_header pb_14">Cost</div>
            <div class="top_values">
              <div *ngIf="showCash">
                <div class="main_value_text" *ngIf="batteryViewDetails">
                  ${{ financialDetails.batteryCost.netCost | number }}
                  <div class="value_text">
                    <span
                      class="tooltip_text pointer"
                      matTooltip="Net cost = Upfront cost - Incentives"
                    >
                      {{ paymentTypeLabel }}
                    </span>
                  </div>
                </div>
              </div>
              <div *ngIf="showLoan">
                <div class="main_value_text">
                  ${{ financialDetails.batteryCost.monthlyPayment | number }}
                  <div class="value_text">{{ paymentTypeLabel }}</div>
                </div>
              </div>
            </div>

            <div class="bottom_values">
              <div class="left_bottom_value">
                <div *ngIf="showCash">
                  <div class="value" *ngIf="batteryViewDetails">
                    ${{ financialDetails.batteryCost.upfrontCost | number }}
                  </div>
                  <div class="value_label">
                    <span
                      class="tooltip_text pointer"
                      matTooltip="Includes equipment, labor, permits, etc."
                    >
                      Upfront cost
                    </span>
                  </div>
                </div>
                <div *ngIf="showLoan">
                  <div class="value">
                    ${{ financialDetails.batteryCost.downPayment | number }}
                  </div>
                  <div class="value_label">Down payment</div>
                </div>
              </div>
              <div class="right_bottom_value">
                <div class="value" *ngIf="batteryViewDetails">
                  ${{ financialDetails.batteryCost.incentives | number }}
                </div>
                <div class="value_label">
                  <mat-icon class="hidden_tooltip">help_outline</mat-icon>
                  <span
                    *ngIf="showIncentivesStatus"
                    class="link_text pointer"
                    (click)="showIncentiveBreakdown = true"
                    >Incentives
                  </span>
                  <span *ngIf="!showIncentivesStatus">Incentives </span>
                  <mat-icon
                    class="tooltip_icon pointer"
                    matTooltip="Available tax credits and rebates."
                  >
                    help_outline
                  </mat-icon>
                </div>
              </div>
            </div>
          </div>
          <yt-market-place
            [tech_name]="'battery'"
            [linkId]="linkId"
            [marketPlaceDetails]="marketPlaceDetails"
            *ngIf="marketPlaceDetails && displayMarketPlaceBox"
          ></yt-market-place>
          <div class="main_values_box advice_box" *ngIf="adviceCardStatus">
            <yt-electrification-advice-card
              [tech]="'battery'"
            ></yt-electrification-advice-card>
          </div>
        </div>
      </div>

      <div class="battery_bottom_section w-100">
        <div class="left_section">
          <div class="highChart_card highChart_card_mobile" id="savingsMobile">
            <div *ngIf="isLoadShift">
              <div class="details_header pb_14">Savings</div>
              <div class="w-100 toggle_buttons">
                <mat-button-toggle-group
                  [(value)]="selected"
                  (change)="updateChart($event.value)"
                >
                  <mat-button-toggle
                    *ngFor="let time of timeSegmentList"
                    [value]="time.value"
                  >
                    <span class="toggle_label">
                      {{ time.viewValue?.toUpperCase() }}
                    </span>
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="w-100 savings_text">
                Your
                {{ timeFrame }}
                projected
                <span
                  class="tooltip_text_border pointer"
                  matTooltip="The energy savings does not include the upfront cost of the solar system."
                >
                  energy savings
                </span>
                is
                <span
                  class="savings_value"
                  [ngClass]="{ value_color: battery_Savings < 0 }"
                  >{{
                    battery_Savings | currency : "$" : "symbol" : "1.0-0"
                  }}</span
                >
                <span *ngIf="displayPayback">
                  with a
                  <span
                    class="tooltip_text_border pointer"
                    matTooltip="Simple payback period = Net cost / Annual energy cost savings."
                  >
                    payback
                  </span>
                  of <span>{{ payBackYear }}</span>
                </span>
              </div>
              <div class="w-100 chart_section">
                <div class="mobile_chart_text">
                  To view and compare graphs of energy costs, please login to
                  your dashboard using a desktop.
                </div>
              </div>
            </div>
            <div *ngIf="!isLoadShift" class="w-100" id="descriptionMobile">
              <div class="details_header">DESCRIPTION</div>
              <div class="yt_text">
                If you’re looking to help the grid, reduce your utility bills,
                and add resiliency to your home or business, solar plus battery
                storage may be the fit for you! With solar plus battery systems,
                you can charge the battery during the day when your solar system
                is generating clean energy, and then have your home or business
                draw power from your battery during the evening hours when grid
                power is dirty and more expensive.
                <span
                  class="read_link"
                  *ngIf="showReadMore"
                  (click)="onReadContent('readMore')"
                >
                  Read more...
                </span>
              </div>
              <div class="yt_text battery-pt" *ngIf="showReadLess">
                Installing batteries while having solar panels allows you to
                store your excess solar energy in batteries rather than selling
                it back to the grid at wholesale prices. This gives you more
                control over how you use your energy and where your excess
                energy goes all while reducing your carbon footprint and
                increasing your personal resiliency.
                <span class="read_link" (click)="onReadContent('readLess')">
                  Read less
                </span>
              </div>
            </div>
          </div>

          <div class="details_card" [formGroup]="batteryForm" id="settings">
            <div class="details_header">PERSONALIZE</div>
            <div class="form_label baseline">
              {{ backupHourLabel }}
              <mat-icon class="tooltip_icon" [matTooltip]="backupHourToolTip">
                help_outline
              </mat-icon>
            </div>
            <mat-form-field
              class="w-100 formfield_padding"
              floatLabel="auto"
              appearance="outline"
            >
              <input
                matInput
                formControlName="hrs"
                placeholder="Enter 1-35"
                autocomplete="off"
                maxlength="3"
              />
              <mat-error *ngIf="isHoursOutOfRange">
                Please enter value between 1-35
              </mat-error>
              <mat-error *ngIf="isHoursRequired"> Required! </mat-error>
            </mat-form-field>

            <div class="form_label flex justify-between items-baseline">
              Financing
              <span class="help_me_find" (click)="showCompareFinancial = true">
                Compare
              </span>
            </div>
            <mat-form-field
              class="w-100 disabled_formfield"
              floatLabel="auto"
              appearance="outline"
            >
              <mat-select formControlName="finance">
                <mat-option
                  *ngFor="let finance of financeList"
                  [value]="finance.value"
                >
                  {{ finance.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div
              class="save_button"
              [ngClass]="{
                '': !displayMarketPlaceBox,
                'ghost-button': displayMarketPlaceBox,
                disable_button: batteryForm.invalid
              }"
            >
              <button
                mat-raised-button
                color="accent"
                #loaderButton
                [disabled]="batteryForm.invalid"
                (click)="onSaveDetails()"
              >
                SAVE DETAILS
              </button>
            </div>
          </div>
          <yt-assumption-card
            [assumptionDetails]="assumptionDetails"
            [assumptionLabel]="'Loan'"
            [showLoanLease]="showLoan"
          ></yt-assumption-card>
        </div>

        <div class="right_section">
          <div *ngIf="isLoadShift" class="highChart_card" id="savingsDesktop">
            <div class="details_header pb_14">Savings</div>
            <app-Time-Rendering
              
              [selected]="selected"           
              (selectionChange)="updateChart($event)"> 
            </app-Time-Rendering>


          


            <div class="w-100 savings_text">
              Your
              <span>{{ timeFrame }}</span>
              projected
              <span
                class="tooltip_text_border pointer"
                matTooltip="The energy savings does not include the upfront cost of the battery."
              >
                energy savings
              </span>
              is
              <span
                class="savings_value"
                [ngClass]="{ value_color: battery_Savings < 0 }"
                >{{
                  battery_Savings | currency : "$" : "symbol" : "1.0-0"
                }}</span
              >
              <span *ngIf="displayPayback">
                with a
                <span
                  class="tooltip_text_border pointer"
                  matTooltip="Simple payback period = Net cost / Annual energy cost savings."
                >
                  payback
                </span>
                of <span>{{ payBackYear }}</span>
              </span>
            </div>
            <div class="formula_text">
              Energy savings &nbsp; = &nbsp; Electricity cost without battery -
              Electricity cost with battery
            </div>
            <div class="w-100 chart_section">
              <div class="hide_chart">
                <div class="chart" #container></div>
              </div>
            </div>
          </div>
          <div
            *ngIf="!isLoadShift"
            class="highChart_card"
            id="descriptionDesktop"
          >
            <div class="details_header">DESCRIPTION</div>
            <div class="yt_text">
              If you’re looking to help the grid, reduce your utility bills, and
              add resiliency to your home or business, solar plus battery
              storage may be the fit for you! With solar plus battery systems,
              you can charge the battery during the day when your solar system
              is generating clean energy, and then have your home or business
              draw power from your battery during the evening hours when grid
              power is dirty and more expensive.
              <span
                class="read_link"
                *ngIf="showReadMore"
                (click)="onReadContent('readMore')"
              >
                Read more...
              </span>
            </div>
            <div class="yt_text battery-pt" *ngIf="showReadLess">
              Installing batteries while having solar panels allows you to store
              your excess solar energy in batteries rather than selling it back
              to the grid at wholesale prices. This gives you more control over
              how you use your energy and where your excess energy goes all
              while reducing your carbon footprint and increasing your personal
              resiliency.
              <span class="read_link" (click)="onReadContent('readLess')">
                Read less
              </span>
            </div>
          </div>

          <div class="summary_card w-100" id="details">
            <mat-expansion-panel
              (closed)="isTechnicalExpanded = false"
              [expanded]="isTechnicalExpanded"
              hideToggle="true"
            >
              <mat-expansion-panel-header (click)="expansionPanelClick()">
                <mat-panel-title class="flex items-center">
                  Technical Details &nbsp;
                  <mat-icon class="pointer mat_color">
                    {{
                      !icon ? "keyboard_arrow_down" : "keyboard_arrow_up"
                    }}</mat-icon
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel_body w-100">
                <div class="left_side_values center">
                  <div class="label_heading w-100">Summary</div>
                  <div class="left_label_content_box">
                    <div class="label_content_row">
                      <div>Manufacturer</div>
                      <div *ngIf="batteryViewDetails">
                        {{ technicalDetails.batterySummary.manufacturer }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Model</div>
                      <div *ngIf="batteryViewDetails">
                        {{ technicalDetails.batterySummary.batteryModel }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <span
                        class="tooltip_text pointer"
                        matTooltip="This is the capacity the battery is theoretically able to store."
                      >
                        Total capacity
                      </span>
                      <div *ngIf="batteryViewDetails">
                        {{
                          technicalDetails.batterySummary.totalCapacity | number
                        }}
                        kWh
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <span
                        class="tooltip_text pointer"
                        matTooltip="This is the capacity the battery is able to store after factoring in depth of discharge, efficiency and charge/discharge rate restrictions."
                      >
                        Usable capacity
                      </span>
                      <div *ngIf="batteryViewDetails">
                        {{
                          technicalDetails.batterySummary.usableCapacity
                            | number
                        }}
                        kWh
                      </div>
                    </div>
                  </div>
                </div>
                <div class="right_side_values center">
                  <div class="w-100 label_heading"><span> &nbsp; </span></div>
                  <div class="left_label_content_box">
                    <div class="label_content_row">
                      <div>No. of units</div>
                      <div *ngIf="batteryViewDetails">
                        {{ technicalDetails.batterySummary.units }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Weight</div>
                      <div *ngIf="batteryViewDetails">
                        {{ technicalDetails.batterySummary.weight }}
                        lbs
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Warranty</div>
                      <div *ngIf="batteryViewDetails">
                        {{ technicalDetails.batterySummary.warranty }}
                        years
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </div>

          <div class="faq_card w-100" id="faq" *ngIf="!isLangSpanish">
            <mat-expansion-panel
              (closed)="isFAQExpanded = false"
              [expanded]="isFAQExpanded"
              hideToggle="true"
            >
              <mat-expansion-panel-header (click)="faqExpansionClick()">
                <mat-panel-title class="flex items-center">
                  Frequently Asked Questions &nbsp;
                  <mat-icon class="pointer mat_color">
                    {{
                      !icon1 ? "keyboard_arrow_down" : "keyboard_arrow_up"
                    }}</mat-icon
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel_body w-100">
                <yt-customized-tab
                  [tabHeadersList]="FAQTabsList"
                  (tabValue)="tabChanged($event)"
                ></yt-customized-tab>
                <yt-battery-FAQ
                  [activeIndex]="tabIndex"
                  [showAboutTech]="false"
                ></yt-battery-FAQ>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </div>
      <div
        class="link_text back_to_top"
        (click)="scrollToTop()"
        *ngIf="!isLangSpanish"
      >
        <mat-icon class="pointer mat_color">keyboard_arrow_up</mat-icon>
        <span class="pointer">Back to Top</span>
      </div>
      <div class="disclaimer_text" [ngClass]="{ pt_70: isLangSpanish }">
        The results and projections are estimates based on available data.
      </div>
    </div>
  </div>
</div>

<!-- Incentives Breakdown Dialogue box-->
<yt-breakdown-popup
  *ngIf="haveResponse && showIncentiveBreakdown"
  [techName]="'battery'"
  [heading]="'Incentives Breakdown'"
  [netCost]="'Total'"
  (closeIncentiveBreakdown)="showIncentiveBreakdown = false"
>
</yt-breakdown-popup>

<!-- Compare Financial Dialogue box-->
<yt-compare-financial
  *ngIf="haveResponse && showCompareFinancial"
  [battery]="financial"
  [batteryDetails]="financialDetails"
  [financeValue]="financeValue"
  (inputFinance)="onSaveDetails($event)"
  (compare)="showCompareFinancial = false"
></yt-compare-financial>

<!-- loader -->
<yt-loader *ngIf="loader"></yt-loader>
