<div class="yt-p-40">
  <p class="yt__info-text" *ngIf="informationforyellowtin">
    {{ solarVendorName }} recommends sharing your energy data via the
    <span
      (click)="openPopup('PGE')"
      class="link yt_dark-blue pointer yt__outline-none"
      >PG&E Share My Data</span
    >
    platform.
  </p>
  <p class="yt__info-text yt__padding-top" *ngIf="informationforyellowtin">
    Your detailed energy data will be used to more accurately calculate your
    savings and recommend optimal clean energy choices.
  </p>

  <p class="yt__info-text" *ngIf="informationforSolarinstaller">
    {{ solarVendorName }} recommends sharing your energy data via the
    <span
      (click)="openPopup('PGE')"
      class="link yt_dark-blue pointer yt__outline-none"
      >PG&E Share My Data</span
    >
    platform.
  </p>

  <p class="yt__info-text yt__padding-top" *ngIf="informationforSolarinstaller">
    The detailed energy data we receive will allow us to calculate your system
    size, solar savings and payback more accurately.
  </p>
  <p
    *ngIf="!smdTabStatus && haveResponse"
    class="yt_error-info"
    style="color: red"
  >
    Note: PG&E Share My Data platform is currently under maintenance. You can
    authorize the release of your energy usage data once the maintenance is
    complete. Please choose the Manual Estimate option at this time.
  </p>
</div>

<div class="yt_paddingleftright">
  <div class="fl w-100 w-70-l w-100-m mb2 mb0-ns yt_w-125 yt-pr-10 yt__pt-15">
    <li class="flex items-center yt_lh-5 ph0-l b--black-10">
      <div class="yt-pl-40">
        <a
          href="https://seal.godaddy.com/verifySeal?sealID=abhS69r7UJ5LjuP0qBzd1r49UwOVkeVXipTFPM4Jwp3bk8k4MGWVEYTSDe4q"
        >
          <img
            (click)="openCertificate()"
            height="32px"
            width="131px"
            src="assets/energy-usage/Godaddy_Seal.gif"
          />
        </a>
      </div>
      <div class="pl3-l pl-m pl0 flex-auto">
        <span class="db yt_secure-text"
          >Secure link to share your data only with
          <a (click)="openPopup('YT')" class="link yt_dark-blue pointer"
            >YellowTin</a
          >
          <span *ngIf="!informationforSolarinstaller">.</span>
          <span *ngIf="informationforSolarinstaller">
            and {{ solarVendorName }}</span
          >
        </span>
      </div>
    </li>
  </div>
  <div class="w-100 w-100-m w-auto-l mb3 mb3-ns mb-ns">
    <button
      mat-raised-button
      color="accent"
      [disabled]="!smdTabStatus"
      class="fl"
      (click)="openPopup('confirm')"
    >
      LOGIN TO PG&E
      <mat-icon class="yt_mat-icon">launch</mat-icon>
    </button>
  </div>
</div>
