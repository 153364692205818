import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { ICDataModel } from "../../../../model/userdata.model";
import { DashboardAPIService } from "../../../../../../rest-api/dashboard/dashboard-api.service";
import { LearnMoreService } from "../../../../../learn-more/learn-more.service";
import { RadioOptionsModel } from "../../../../../../rest-api/dashboard/dashboard-api.model";
import { MatRadioChange } from "@angular/material/radio";
import { SettingsDialogData } from "../../setting-overlay.component.model";
import { SettingsOverlayService } from "../../settings-overlay.service";
import { Utils } from "../../../../../../utils/util";
import { Subject } from "rxjs";
import { DropDownModel } from "../../../../../../rest-api/rest-api.model";
import { DataModel } from "src/app/homeowner-dashboard/overview/model/dashboard.model";

@Component({
  selector: "yt-ic",
  templateUrl: "ic.component.html",
  styleUrls: ["../../common-tech.scss"],
})
export class ICComponent implements AfterContentChecked {
  panelOpenState: boolean = false;
  icForm: FormGroup;
  disableDatePicker: boolean = false;
  icDataModel: ICDataModel[];
  icbought: string;
  monthList: string[];
  lastTwelthMonth: string;
  ownValue: string;
  buildingType: string;
  icInfoModel: DataModel;
  isPortable: boolean;
  header: string;
  selectedRadioButton: string;
  hideFormField: boolean;
  radioButtonOptions: RadioOptionsModel[];
  cooktopSettings: ICDataModel[];
  haveInputError: boolean;
  @Input() ICDetails;
  @Input() activeIcon: string;
  subject: Subject<any> = new Subject();
  @Output() techSave: EventEmitter<SettingsDialogData> = new EventEmitter();
  current_range: DropDownModel[];

  constructor(
    private fb: FormBuilder,
    private dashboardAPIService: DashboardAPIService,
    private utils: Utils,
    private learnMoreService: LearnMoreService,
    private settingService: SettingsOverlayService
  ) {
    this.current_range = this.learnMoreService.typeOfGas;
    this.icForm = this.fb.group({
      own: ["", Validators.required],
      currentRange: ["Natural gas", Validators.required],
      bought: [""],
      datePurchased: ["", Validators.required],
    });
  }

  ngAfterContentChecked(): void {
    const datePurchased = this.icForm.get("datePurchased")!;
    if (this.haveInputError) this.haveInputError = datePurchased.invalid;
  }

  ngOnInit() {
    this.getICInfo();
    this.dashboardAPIService.getRadioButtonDetails().subscribe((data) => {
      this.radioButtonOptions = data.radioButtons;
    });
    this.monthList = this.utils.monthList;
    this.lastTwelthMonth = this.utils.lastTwelthMonth;
    this.haveInputError = this.settingService.checkSettingsInfoError("ic");
    this.icDataModel = this.haveInputError
      ? this.settingService.settingsInfoErrors.appliances?.ic!
      : this.settingService.checkSettingsInfo("ic")
      ? this.settingService.settingsInfo.appliances?.ic!
      : this.dashboardAPIService.energyChoicesData.appliances.ic!;

    this.icDataModel.forEach((ic: ICDataModel) => {
      this.ownValue = ic.own!;
      this.setSelectedRadioValues(this.ownValue);
      this.icForm.patchValue(ic);
      if (ic.own === "yes") {
        this.hideFormField = false;
        this.icForm.get("currentRange")?.setValue("Electric");
        if (!ic.bought) {
          this.icForm.get("datePurchased")?.setValue(this.lastTwelthMonth);
        }
        this.disableDatePicker = true;
      } else if (ic.own === "yesPlan") {
        this.hideFormField = true;
        this.icForm.enable();
        this.icForm.get("bought")?.disable();
        this.icForm.get("datePurchased")?.disable();
        this.disableDatePicker = false;
      } else if (ic.own === "noPlan") {
        this.hideFormField = true;
        this.icForm.enable();
        this.icForm.get("bought")?.disable();
        this.icForm.get("datePurchased")?.disable();
        this.disableDatePicker = false;
      }
      this.icForm.get("own")?.enable();
    });
    if (this.activeIcon) {
      if (this.activeIcon === "favorite") {
        this.icForm.get("own")?.setValue("yesPlan");
      } else if (this.activeIcon === "alreadyHave") {
        this.icForm.get("own")?.setValue("yes");
      }
      this.onValueChanges();
      this.setSelectedRadioValues(this.icForm.get("own")?.value);
      this.condition();
    } else {
      this.onValueChanges();
    }
  }

  onRadioChange(event: MatRadioChange) {
    this.setSelectedRadioValues(event.value);
  }

  setSelectedRadioValues(value: string): void {
    this.selectedRadioButton = value;
  }

  getICInfo(): void {
    this.buildingType = this.dashboardAPIService.buildType;
    this.icInfoModel = this.dashboardAPIService.icInfo;
    this.isPortable = this.ICDetails[0].type === "portable";
    this.header = this.icInfoModel.title;
  }

  condition() {
    this.ownValue = this.icForm.get("own")?.value;
    const ic = this.dashboardAPIService.energyChoicesData.appliances.ic![0];
    if (this.ownValue === "yes") {
      this.hideFormField = false;
      this.icForm.get("bought")?.enable();
      this.icForm.get("datePurchased")?.enable();
      this.disableDatePicker = true;
    } else if (this.ownValue === "yesPlan") {
      this.hideFormField = true;
      this.icForm.get("currentRange")?.enable();
      this.icForm.get("currentRange")?.setValue(ic.currentRange);
      this.icForm.get("bought")?.disable();
      this.icForm.get("datePurchased")?.disable();
      this.disableDatePicker = false;
    } else if (this.ownValue === "noPlan") {
      this.hideFormField = true;
      this.icForm.get("currentRange")?.enable();
      this.icForm.get("currentRange")?.setValue(ic.currentRange);
      this.icForm.get("bought")?.disable();
      this.icForm.get("datePurchased")?.disable();
      this.disableDatePicker = false;
    }
  }

  isDatePurchasedEmpty() {
    if (this.ownValue === "yes") {
      if (!this.icForm.get("datePurchased")?.value) {
        return true;
      }
    }
    return false;
  }

  onValueChanges(): void {
    this.icForm.valueChanges.subscribe((data) => {
      this.subject.pipe().subscribe(() => {
        if (data.own === "yes") {
          const datePurchased = data.datePurchased;
          const bought = this.utils.isDatePurchaseSelected(datePurchased);
          this.cooktopSettings = [
            {
              own: data.own,
              bought: bought,
              datePurchased: bought ? datePurchased : null,
            },
          ];
        } else if (data.own === "yesPlan" || data.own === "noPlan") {
          this.cooktopSettings = [
            {
              own: data.own,
              currentRange: data.currentRange,
            },
          ];
        }
        if (this.icForm.valid) {
          this.settingService.updateSettingsInfo(
            this.dashboardAPIService.energyChoicesData.appliances,
            this.cooktopSettings,
            "appliances",
            "ic"
          );
        } else {
          this.settingService.updateSettingsInfoErrors(
            this.dashboardAPIService.energyChoicesData.appliances,
            this.cooktopSettings,
            "appliances",
            "ic"
          );
        }
      });
      this.subject.next();
    });
  }

  onSaveIC() {
    this.settingService.putSettingsInfo(this.getSettingsInfo);
  }

  getSettingsInfo = () => {
    if (this.activeIcon) {
      this.techSave.emit({ own: this.ownValue, tech: "ic" });
    }
  };
}
