<scroll-top></scroll-top>
<div class="page-container">
  <div class="padding-class">
    <div class="yt_maxWidth">
      <div class="notification-box">
        <div class="notify-settings">
          <div class="heading">Notification</div>
          <div
            class="flex items-center pointer"
            *ngIf="!haveNotification"
            (click)="openNotificationSetting()"
          >
            <div class="mr2">
              <mat-icon class="cursor-pointer-logo font-color mat_icon"
                >settings</mat-icon
              >
            </div>
            <div class="leftwidth display-none">
              <span class="menu_text">Settings</span>
            </div>
          </div>
        </div>
        <div *ngIf="haveNotification" class="noNotification-box">
          <div class="padding_top tc">
            <img src="assets/dashboard/overview/noNotification.png" />
          </div>
          <div class="noNotification_msg tc">You’re all caught up!</div>
          <div class="text-padding noNotification_text">
            No new notifications. Check this section for new updates on
            Incentives.
          </div>
        </div>
        <div class="formSection" *ngFor="let notification of NotificationModel">
          <div class="sub-heading">{{ notification.header }}</div>
          <div *ngIf="notification.link !== ''">
            <a
              class="flex items-center pointer link_text"
              [href]="notification.link"
              target="_blank"
            >
              <span>{{ notification.subHeader }}!</span>
              <span class="ml1">
                <mat-icon class="cursor-pointer-logo font-color link_color"
                  >launch_icon</mat-icon
                >
              </span>
            </a>
          </div>
          <div
            class="flex items-center link_text"
            *ngIf="notification.link === ''"
          >
            <div>{{ notification.subHeader }}!</div>
          </div>
          <p class="menu_text padding-top">{{ notification.content }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- loader -->
<yt-loader *ngIf="loader"></yt-loader>