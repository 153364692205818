<div class="w-100 margin__bottom energy-choices" [formGroup]="eBikeForm">
  <div>
    <div class="yt-text">
      <strong>Electric Bikes</strong> are a fun, low-carbon transportation
      option that will get you moving again. Electric bikes will help you go
      farther with less effort. They are a great way to save money, avoid
      parking hassles and decrease your carbon footprint.
    </div>
    <div class="yt_p_top">
      <mat-expansion-panel
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        [expanded]="true"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="yt__panel-header"
            >Your Choices</mat-panel-title
          >
        </mat-expansion-panel-header>
        <div class="cf">
          <div class="w-100">
            <div
              class="w-100 w-50-l fl radio-buttons yt_border-right"
              [ngClass]="{ 'yt_border-right': !showDatePurchased }"
            >
              <mat-radio-group
                (change)="condition()"
                formControlName="own"
                class="example-radio-group"
              >
                <div class="yt-Btn-Font">Tell us about your electric bike</div>
                <div class="radio_text">
                  <div class="dib yt_ques-padding">
                    <mat-radio-button value="yes">
                      <span class="yt_option">Already have one</span>
                    </mat-radio-button>
                  </div>
                  <div class="dib yt_answer-padding-2">
                    <mat-radio-button value="yesPlan">
                      <span class="yt_option">Interested in getting one</span>
                    </mat-radio-button>
                  </div>
                  <div class="dib yt_answer-padding-2">
                    <mat-radio-button value="no">
                      <span class="yt_option">Not interested</span>
                    </mat-radio-button>
                  </div>
                </div>
              </mat-radio-group>
            </div>
            <div
              class="w-100 w-100-m w-50-l fl yt_paddingtop-42 yt_pd-left"
              [ngClass]="{ 'yt_border-left-pool': !!showDatePurchased }"
            >
              <div>
                <div
                  class="yt__f4 yt_pb-7 lh-13"
                  [ngStyle]="{
                    color:
                      eBikeForm.controls['own'].value === 'no'
                        ? '#c7c7c7'
                        : '#333333'
                  }"
                >
                  Planning to use electric bike instead of your car?
                  <span
                    class="gray-text"
                    [ngStyle]="{
                      color:
                        eBikeForm.controls['own'].value === 'no'
                          ? '#c7c7c7'
                          : '#707070'
                    }"
                  >
                    If yes, provide displaced vehicle miles per week</span
                  >
                  <mat-icon
                    class="pointer"
                    matTooltip="This is the number of miles ridden per week on an electric bike that would have 
									been driven by a car instead (commuting, running errands, etc.)."
                  >
                    help_outline
                  </mat-icon>
                </div>
                <div
                  class="yt__pt0-7"
                  [ngClass]="{ 'yt__pb-20': !showDatePurchased }"
                >
                  <mat-form-field
                    appearance="outline"
                    floatLabel="auto"
                    [ngClass]="{
                      'disable-field': eBikeForm.controls['own'].value === 'no'
                    }"
                  >
                    <input
                      matInput
                      type="text"
                      class="quantity"
                      placeholder="Enter 0-75"
                      formControlName="displacedMiles"
                      maxlength="3"
                    />
                    <mat-error
                      *ngIf="
                        eBikeForm.controls['displacedMiles'].hasError(
                          'pattern'
                        ) ||
                        eBikeForm.controls['displacedMiles'].hasError('min') ||
                        eBikeForm.controls['displacedMiles'].hasError('max')
                      "
                    >
                      Please enter value between 0-75
                    </mat-error>
                    <mat-error
                      *ngIf="
                        eBikeForm.controls['displacedMiles'].hasError(
                          'required'
                        )
                      "
                    >
                      Required!
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div *ngIf="showDatePurchased">
                <article class="cf">
                  <div
                    class="fl w-60-c yt__f4 yt_pt1-75"
                    [ngStyle]="{
                      color:
                        eBikeForm.controls['own'].value === 'no' ||
                        eBikeForm.controls['own'].value === 'yesPlan'
                          ? '#c7c7c7'
                          : '#333333'
                    }"
                  >
                    Purchased month & year
                  </div>
                </article>
                <div class="yt__pb-20 yt__pt0-7">
                  <mat-form-field
                    appearance="outline"
                    floatLabel="auto"
                    [ngClass]="{
                      'disable-field':
                        eBikeForm.controls['own'].value === 'no' ||
                        eBikeForm.controls['own'].value === 'yesPlan'
                    }"
                  >
                    <mat-select
                      matInput
                      placeholder="Select"
                      formControlName="datePurchased"
                    >
                      <mat-option
                        *ngFor="let month of monthList"
                        [value]="month"
                        >{{ month | titlecase }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                  <div
                    class="cb w-100 yt_error-message"
                    *ngIf="
                      isDatePurchasedEmpty() &&
                      eBikeForm.controls['datePurchased'].touched
                    "
                  >
                    Required!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
  <div class="pt_30 w-100">
    <button
      mat-raised-button
      color="accent"
      (click)="onSaveEBike()"
      [disabled]="eBikeForm.invalid || isDatePurchasedEmpty()"
      class="fr w-100 yt__mb-12"
    >
      SAVE
    </button>
  </div>
</div>
