<scroll-top></scroll-top>
<div class="page-container yt__learn_more">
  <yt-back [navigateTo]="'overview'"></yt-back>
  <div class="padding-back-button w-100 center">
    <div class="yt_maxWidth w-100 center">
      <div class="header_text tc">Portable Battery</div>
      <yt-scroll-link-toggle
        *ngIf="!isLangSpanish"
        [activeLink]="selectedSection"
        [isAboutTech]="true"
        [isDescription]="true"
        [isSettings]="true"
        [isDetails]="true"
        [isFaq]="true"
        [techParameter]="'portableBattery'"
        (activeValue)="handleActiveValue($event)"
      ></yt-scroll-link-toggle>
      <div>
        <div
          class="yt-text battery-text"
          [innerHTML]="
            'homeOwenerDashboard.learnMore.portableBattery.paragraph'
              | translate
          "
        ></div>

        <div *ngIf="!isLangSpanish" id="aboutTechnology">
          <yt-portable-battery-FAQ
            [showAboutTech]="true"
          ></yt-portable-battery-FAQ>
        </div>

        <div class="faq_mobile">
          <button
            class="favorite_btn"
            mat-raised-button
            color="accent"
            (click)="onFavorite()"
          >
            <mat-icon
              class="favorite_icon"
              [ngClass]="{ favorite_icon_color: favStatus }"
              >{{ favIcon }}</mat-icon
            >FAVORITE
          </button>
          <button
            *ngIf="isLangSpanish"
            class="faq_button"
            mat-raised-button
            color="accent"
            (click)="handleFAQClick()"
          >
            MORE INFO
          </button>
        </div>
      </div>

      <div class="top_section w-100">
        <div class="faq_section">
          <div class="image_box">
            <img
              src="assets/lifestyle/portable-battery.png"
              alt="technology icon"
              [ngStyle]="{ height: !displayMarketPlaceBox ? '160px' : '250px' }"
            />
          </div>
          <button
            (click)="onFavorite()"
            class="favorite_btn mt_20"
            mat-raised-button
            color="accent"
          >
            <mat-icon
              class="favorite_icon"
              [ngClass]="{ favorite_icon_color: favStatus }"
              >{{ favIcon }}</mat-icon
            >FAVORITE
          </button>

          <button
            *ngIf="isLangSpanish"
            class="faq_button mt_20"
            mat-raised-button
            color="accent"
            (click)="handleFAQClick()"
          >
            MORE INFO
          </button>
        </div>

        <div class="width-box" id="cost">
          <div class="main_values_box">
            <div class="details_header pb_14">Cost</div>
            <div class="top_values">
              <div class="main_value_text" *ngIf="portableBatteryDetails">
                ${{ financialDetails.portableBatteryCost.netCost | number }}
                <div class="value_text">
                  <span
                    class="tooltip_text pointer"
                    matTooltip="Net cost = Upfront cost - Incentives"
                  >
                    Net cost
                  </span>
                </div>
              </div>
            </div>

            <div class="bottom_values">
              <div class="left_bottom_value">
                <div class="value" *ngIf="portableBatteryDetails">
                  ${{
                    financialDetails.portableBatteryCost.upfrontCost | number
                  }}
                </div>
                <div class="value_label">
                  <span
                    class="tooltip_text pointer"
                    matTooltip="Equipment cost only. There is no installation cost."
                  >
                    Upfront cost
                  </span>
                </div>
              </div>
              <div class="right_bottom_value">
                <div class="value" *ngIf="portableBatteryDetails">
                  ${{
                    financialDetails.portableBatteryCost.incentives | number
                  }}
                </div>
                <div class="value_label">
                  <mat-icon class="hidden_tooltip">help_outline</mat-icon>
                  <span
                    *ngIf="showIncentivesStatus"
                    class="link_text pointer"
                    (click)="showIncentiveBreakdown = true"
                    >Incentives
                  </span>
                  <span *ngIf="!showIncentivesStatus">Incentives </span>
                  <mat-icon
                    class="tooltip_icon pointer"
                    matTooltip="Available tax credits and rebates."
                  >
                    help_outline
                  </mat-icon>
                </div>
              </div>
            </div>
          </div>
          <yt-market-place
            [tech_name]="'portableBattery'"
            [linkId]="linkId"
            [marketPlaceDetails]="marketPlaceDetails"
            *ngIf="marketPlaceDetails && displayMarketPlaceBox"
          ></yt-market-place>
          <div class="main_values_box advice_box" *ngIf="adviceCardStatus">
            <yt-electrification-advice-card
              [tech]="'portableBattery'"
            ></yt-electrification-advice-card>
          </div>
        </div>
      </div>

      <div class="battery_bottom_section w-100">
        <div class="left_section">
          <div
            class="w-100 highChart_card highChart_card_mobile"
            id="descriptionMobile"
          >
            <div class="details_header">DESCRIPTION</div>
            <div class="yt_text">
              If you’re looking to help the grid, reduce your utility bills, and
              add resiliency to your home or business, solar plus battery
              storage may be the fit for you! With solar plus battery systems,
              you can charge the battery during the day when your solar system
              is generating clean energy, and then have your home or business
              draw power from your battery during the evening hours when grid
              power is dirty and more expensive.
              <span
                class="read_link"
                *ngIf="showReadMore"
                (click)="onReadContent('readMore')"
              >
                Read more...
              </span>
            </div>
            <div class="yt_text battery-pt" *ngIf="showReadLess">
              Installing batteries while having solar panels allows you to store
              your excess solar energy in batteries rather than selling it back
              to the grid at wholesale prices. This gives you more control over
              how you use your energy and where your excess energy goes all
              while reducing your carbon footprint and increasing your personal
              resiliency.
              <span class="read_link" (click)="onReadContent('readLess')">
                Read less
              </span>
            </div>
          </div>

          <div
            class="details_card"
            [formGroup]="portableBatteryForm"
            id="settings"
          >
            <div class="details_header">PERSONALIZE</div>
            <div class="form_label baseline">
              Backup hours
              <mat-icon
                class="tooltip_icon"
                matTooltip="Number of hours a limited number of small appliances (modem, computer, lights, etc.) can be powered in a blackout."
              >
                help_outline
              </mat-icon>
            </div>
            <mat-form-field
              class="w-100 formfield_padding"
              floatLabel="auto"
              appearance="outline"
            >
              <input
                matInput
                formControlName="hrs"
                placeholder="Enter 1-18"
                autocomplete="off"
                maxlength="3"
              />
              <mat-error *ngIf="isHoursOutOfRange">
                Please enter value between 1-18
              </mat-error>
              <mat-error *ngIf="isHoursRequired"> Required! </mat-error>
            </mat-form-field>

            <div
              class="save_button"
              [ngClass]="{
                'ghost-button': displayMarketPlaceBox,
                disable_button: portableBatteryForm.invalid
              }"
            >
              <button
                mat-raised-button
                color="accent"
                #loaderButton
                [disabled]="portableBatteryForm.invalid"
                (click)="onSaveDetails()"
              >
                SAVE DETAILS
              </button>
            </div>
          </div>
        </div>

        <div class="right_section">
          <div class="highChart_card" id="descriptionDesktop">
            <div class="details_header">DESCRIPTION</div>
            <div class="yt_text">
              If you’re looking to help the grid, reduce your utility bills, and
              add resiliency to your home or business, solar plus battery
              storage may be the fit for you! With solar plus battery systems,
              you can charge the battery during the day when your solar system
              is generating clean energy, and then have your home or business
              draw power from your battery during the evening hours when grid
              power is dirty and more expensive.
              <span
                class="read_link"
                *ngIf="showReadMore"
                (click)="onReadContent('readMore')"
              >
                Read more...
              </span>
            </div>
            <div class="yt_text battery-pt" *ngIf="showReadLess">
              Installing batteries while having solar panels allows you to store
              your excess solar energy in batteries rather than selling it back
              to the grid at wholesale prices. This gives you more control over
              how you use your energy and where your excess energy goes all
              while reducing your carbon footprint and increasing your personal
              resiliency.
              <span class="read_link" (click)="onReadContent('readLess')">
                Read less
              </span>
            </div>
          </div>

          <div class="summary_card w-100" id="details">
            <mat-expansion-panel
              (closed)="isTechnicalExpanded = false"
              [expanded]="isTechnicalExpanded"
              hideToggle="true"
            >
              <mat-expansion-panel-header (click)="expansionPanelClick()">
                <mat-panel-title class="flex items-center">
                  Technical Details &nbsp;
                  <mat-icon class="pointer mat_color">
                    {{
                      !icon ? "keyboard_arrow_down" : "keyboard_arrow_up"
                    }}</mat-icon
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel_body w-100">
                <div class="left_side_values center">
                  <div class="label_heading w-100">Summary</div>
                  <div class="left_label_content_box">
                    <div class="label_content_row">
                      <div>Manufacturer</div>
                      <div *ngIf="portableBatteryDetails">
                        {{
                          technicalDetails.portableBatterySummary.manufacturer
                        }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Model</div>
                      <div *ngIf="portableBatteryDetails">
                        {{
                          technicalDetails.portableBatterySummary.batteryModel
                        }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <span
                        class="tooltip_text pointer"
                        matTooltip="This is the capacity the battery is theoretically able to store."
                      >
                        Total capacity
                      </span>
                      <div *ngIf="portableBatteryDetails">
                        {{
                          technicalDetails.portableBatterySummary.totalCapacity | number
                        }}
                        kWh
                      </div>
                    </div>
                  </div>
                </div>
                <div class="right_side_values center">
                  <div class="w-100 label_heading"><span> &nbsp; </span></div>
                  <div class="left_label_content_box">
                    <div class="label_content_row">
                      <div>No. of units</div>
                      <div *ngIf="portableBatteryDetails">
                        {{ technicalDetails.portableBatterySummary.units | number }}
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Weight</div>
                      <div *ngIf="portableBatteryDetails">
                        {{ technicalDetails.portableBatterySummary.weight | number }}
                        lbs
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <span
                        class="tooltip_text pointer"
                        matTooltip="Number of hours a limited number of small appliances (modem, computer, lights, etc.) can be powered in a blackout."
                      >
                        Back up
                      </span>
                      <div *ngIf="portableBatteryDetails">
                        {{ technicalDetails.portableBatterySummary.backup | number }}
                        hrs
                      </div>
                    </div>
                    <div class="label_content_row row_paddingtop">
                      <div>Warranty</div>
                      <div *ngIf="portableBatteryDetails">
                        {{ technicalDetails.portableBatterySummary.warranty | number }}
                        years
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </div>

          <div class="faq_card w-100" id="faq" *ngIf="!isLangSpanish">
            <mat-expansion-panel
              (closed)="isFAQExpanded = false"
              [expanded]="isFAQExpanded"
              hideToggle="true"
            >
              <mat-expansion-panel-header (click)="faqExpansionClick()">
                <mat-panel-title class="flex items-center">
                  Frequently Asked Questions &nbsp;
                  <mat-icon class="pointer mat_color">
                    {{
                      !icon1 ? "keyboard_arrow_down" : "keyboard_arrow_up"
                    }}</mat-icon
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel_body w-100">
                <yt-customized-tab
                  [tabHeadersList]="FAQTabsList"
                  (tabValue)="tabChanged($event)"
                ></yt-customized-tab>
                <yt-portable-battery-FAQ
                  [activeIndex]="tabIndex"
                  [showAboutTech]="false"
                ></yt-portable-battery-FAQ>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </div>
      <div
        class="link_text back_to_top"
        (click)="scrollToTop()"
        *ngIf="!isLangSpanish"
      >
        <mat-icon class="pointer mat_color">keyboard_arrow_up</mat-icon>
        <span class="pointer">Back to Top</span>
      </div>
      <div class="disclaimer_text" [ngClass]="{ pt_70: isLangSpanish }">
        The results and projections are estimates based on available data.
      </div>
    </div>
  </div>
</div>

<!-- Incentives Breakdown Dialogue box-->
<yt-breakdown-popup
  *ngIf="haveResponse && showIncentiveBreakdown"
  [techName]="'portableBattery'"
  [heading]="'Incentives Breakdown'"
  [netCost]="'Total'"
  (closeIncentiveBreakdown)="showIncentiveBreakdown = false"
>
</yt-breakdown-popup>

<!-- loader -->
<yt-loader *ngIf="loader"></yt-loader>
