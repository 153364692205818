<div *ngFor="let tech of techList">
  <div class="productsBox padding_top-24">
    <div class="imageBox">
      <figure>
        <img
          [src]="tech.techImage"
          class="tech_card_image"
          [alt]="tech.value"
          width="100%"
        />
      </figure>
      <a class="link" (click)="onLearnMore(tech)">Learn more</a>
    </div>
    <div class="boxContent">
      <div
        class="header"
        [ngClass]="{ header_flex: !tech.partnerList?.guidanceLink! }"
      >
        <div class="flex items-center">
          <b>{{ tech.techName }}</b>
          <span *ngIf="rewardBonus && tech.rewardStatus"
            ><img
              src="assets/marketplace/dollar-sign.png"
              alt="dollar Sign"
              class="dollar_img"
          /></span>
        </div>
        <span
          class="mp_link desktop_view"
          *ngIf="tech.partnerList?.guidanceLink"
        >
          <a (click)="onBuyingGuide(tech)">View buying guide</a>
        </span>
      </div>
      <div class="techDetails">
        {{ getTechParagraph(tech.value) | translate }}
      </div>
      <div class="mp_link mobile_view" *ngIf="tech.partnerList?.guidanceLink">
        <a (click)="onBuyingGuide(tech)">View buying guide</a>
      </div>
      <div class="sub-header">Purchase and start saving</div>
      <div
        class="flex-box"
        *ngFor="let techName of tech.partnerList?.installers"
      >
        <div class="techImage">
          <img
            *ngIf="!!techName.logo; else noImage"
            [src]="techName.logo"
            alt="logo"
          />
          <ng-template class="yt_noImage-text" #noImage
            ><p class="yt_noImage-text">
              {{ techName.name }}
            </p></ng-template
          >
        </div>
        <div>
          <button
            mat-raised-button
            color="accent"
            class="width-button"
            (click)="onShopping(tech.value, techName.link, techName.linkId)"
          >
            {{ techName.buttonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
