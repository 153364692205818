import { Component, OnInit, Input, ViewChild, OnChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import {
  DropDownModel,
  JourneyContentModel,
  LogoModel,
  SolarVendorModel,
} from "../../rest-api/rest-api.model";
import { StepperEnum } from "../../utils/enum";

@Component({
  selector: "yt-main-section",
  templateUrl: "./main-section.component.html",
  styleUrls: ["./main-section.component.scss"],
})
export class MainSectionComponent implements OnInit, OnChanges {
  buildingTypes: DropDownModel[];
  autoLogin: boolean;
  barValue: number;
  stepOne: FormGroup;
  stepTwo: FormGroup;
  stepThree: FormGroup;
  stepFour: FormGroup;
  logoModel: LogoModel;
  utilityID: number;
  utilityName: string;
  cceOptOut: boolean;
  addressFormatting: boolean = false;
  showMyInfo: boolean = false;
  showHomeInfo: boolean = false;
  showEnergyInfo: boolean = false;
  showGoalInfo: boolean = false;
  journeyContentDetails: JourneyContentModel;
  heading: string;
  stepperIndex: number = 0;
  StepperEnum = StepperEnum;
  isSolutionCCE: boolean;
  askExpertPopup: boolean = false;
  @Input() vendorInfo: SolarVendorModel;
  @ViewChild("stepper", { static: false }) stepper: MatStepper;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.stepOne = this.fb.group({
      visit: ["", Validators.required],
    });
    this.stepTwo = this.fb.group({
      visit: ["", Validators.required],
    });
    this.stepThree = this.fb.group({
      visit: ["", Validators.required],
    });
    this.stepFour = this.fb.group({
      visit: ["", Validators.required],
    });
  }

  ngOnChanges(): void {
    this.isSolutionCCE = this.vendorInfo.solutionType === "cce";
    this.logoModel = this.vendorInfo.logo;
    setTimeout(() => {
      this.barValue = 20;
      this.buildingTypes = this.vendorInfo.buildingTypes;
    }, 100);
    this.journeyContentDetails = this.vendorInfo.journeyStepsContent;
    this.heading = this.journeyContentDetails.myInfo.heading;
    this.updateImage(this.journeyContentDetails.myInfo.image);
  }

  onPopup() {
    this.askExpertPopup = true;
  }

  toggleHomeInfo(): void {
    this.stepOne.controls["visit"].setValue("homeInfo");
    this.showHomeInfo = true;
    this.stepper.next();
  }

  toggleEnergyInfo(): void {
    this.stepTwo.controls["visit"].setValue("energyInfo");
    this.showHomeInfo = false;
    this.showEnergyInfo = true;
    this.stepper.next();
  }

  toggleDashboard(): void {
    if (this.isSolutionCCE) {
      this.stepThree.controls["visit"].setValue("goalInfo");
    } else {
      this.stepThree.controls["visit"].setValue("goalInfo");
      this.showHomeInfo = false;
      this.showEnergyInfo = false;
      this.showGoalInfo = true;
      this.stepper.next();
    }
  }

  selectionChange(event) {
    this.showMyInfo = event.selectedIndex < 1;
    this.showHomeInfo = event.selectedIndex < 2;
    this.showEnergyInfo = event.selectedIndex < 3;
    if (!this.isSolutionCCE) this.showGoalInfo = event.selectedIndex < 4;
    this.stepperIndex = event.selectedIndex;
    if (this.showMyInfo) {
      this.stepOne.controls["visit"].setValue("");
      this.stepTwo.controls["visit"].setValue("");
      this.stepThree.controls["visit"].setValue("");
    } else if (this.showHomeInfo) {
      this.stepTwo.controls["visit"].setValue("");
      this.stepThree.controls["visit"].setValue("");
    } else if (this.showEnergyInfo) {
      this.stepThree.controls["visit"].setValue("");
      if (!this.isSolutionCCE) this.stepFour.controls["visit"].setValue("");
    } else if (this.showGoalInfo) {
      this.stepFour.controls["visit"].setValue("");
    }
    // integrating image and header values
    if (StepperEnum.MyInfo === this.stepperIndex) {
      this.updateImage(this.journeyContentDetails.myInfo.image);
      this.heading = this.journeyContentDetails.myInfo.heading;
    } else if (StepperEnum.HomeInfo === this.stepperIndex) {
      this.updateImage(this.journeyContentDetails.homeInfo.image);
      this.heading = this.journeyContentDetails.homeInfo.heading;
    } else if (StepperEnum.EnergyInfo === this.stepperIndex) {
      this.updateImage(this.journeyContentDetails.energyInfo.image);
      this.heading = this.journeyContentDetails.energyInfo.heading;
    } else if (StepperEnum.Goal === this.stepperIndex) {
      this.updateImage(this.journeyContentDetails.goalInfo.image);
      this.heading = this.journeyContentDetails.goalInfo.heading;
    }
  }

  updateImage(path: string): void {
    const imageElement =
      document.querySelector<HTMLImageElement>(".image_section");
    if (imageElement) imageElement.style.backgroundImage = `url(${path})`;
  }

  onClosePopup() {
    this.askExpertPopup = false;
  }
}
