import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatRadioChange } from "@angular/material/radio";
import { RadioOptionsModel } from "../../../../../../rest-api/dashboard/dashboard-api.model";
import { DashboardAPIService } from "../../../../../../rest-api/dashboard/dashboard-api.service";
import { Utils } from "../../../../../../utils/util";
import { EBikeDataModel } from "../../../../model/userdata.model";
import { SettingsDialogData } from "../../setting-overlay.component.model";
import { SettingsOverlayService } from "../../settings-overlay.service";
import { Subject } from "rxjs";

@Component({
  selector: "yt-ebike",
  templateUrl: "ebike.component.html",
  styleUrls: ["../../common-tech.scss"],
})
export class EBikeComponent implements OnInit, OnDestroy, AfterContentChecked {
  eBikeForm: FormGroup;
  showDatePicker = false;
  monthList: string[];
  lastTwelthMonth: string;
  ownValue: string;
  eBikeDataModel: EBikeDataModel[];
  selectedRadioButton: string;
  radioButtonOptions: RadioOptionsModel[];
  eBikeSettings: EBikeDataModel[];
  haveInputError: boolean;
  @Input() activeIcon: string;
  @Input() eBikeDetails;
  subject: Subject<any> = new Subject();
  @Output() techSave: EventEmitter<SettingsDialogData> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private utils: Utils,
    private dashboardAPIService: DashboardAPIService,
    private settingService: SettingsOverlayService
  ) {
    this.eBikeForm = this.fb.group({
      own: ["yesPlan", Validators.required],
      displacedMiles: [
        0,
        [
          Validators.required,
          Validators.pattern("^([0-9]|[0-7][0-9])$"),
          Validators.min(0),
          Validators.max(75),
        ],
      ],
      bought: [false],
      datePurchased: ["", Validators.required],
    });
  }

  ngAfterContentChecked(): void {
    const datePurchased = this.eBikeForm.get("datePurchased")!;
    if (this.haveInputError) this.haveInputError = datePurchased.invalid;
  }

  ngOnInit(): void {
    this.dashboardAPIService.getRadioButtonDetails().subscribe((data) => {
      this.radioButtonOptions = data.radioButtons;
    });
    this.monthList = this.utils.monthList;
    this.lastTwelthMonth = this.utils.lastTwelthMonth;
    this.haveInputError = this.settingService.checkSettingsInfoError("eBike");
    this.eBikeDataModel = this.haveInputError
      ? this.settingService.settingsInfoErrors.transportation?.eBike!
      : this.settingService.checkSettingsInfo("eBike")
      ? this.settingService.settingsInfo.transportation?.eBike!
      : this.dashboardAPIService.energyChoicesData.transportation.eBike!;

    this.eBikeDataModel.forEach((eBike: EBikeDataModel) => {
      this.ownValue = eBike.own;
      this.setSelectedRadioValues(this.ownValue);
      this.eBikeForm.patchValue(eBike);
      if (eBike.own === "yes") {
        if (!eBike.bought) {
          this.eBikeForm.get("datePurchased")?.setValue(this.lastTwelthMonth);
        }
        this.eBikeForm.enable();
        this.showDatePicker = true;
      } else if (eBike.own === "yesPlan") {
        this.eBikeForm.enable();
        this.eBikeForm.get("datePurchased")?.disable();
        this.eBikeForm.get("bought")?.disable();
      } else if (eBike.own === "noPlan") {
        this.eBikeForm.enable();
        this.eBikeForm.get("datePurchased")?.disable();
        this.eBikeForm.get("bought")?.disable();
      }
      this.eBikeForm.get("own")?.enable();
    });
    if (this.activeIcon) {
      if (this.activeIcon === "favorite") {
        this.eBikeForm.get("own")?.setValue("yesPlan");
      } else if (this.activeIcon === "alreadyHave") {
        this.eBikeForm.get("own")?.setValue("yes");
      }
      this.onValueChanges();
      this.setSelectedRadioValues(this.eBikeForm.get("own")?.value);
      this.condition();
    } else {
      this.onValueChanges();
    }
  }

  onRadioChange(event: MatRadioChange) {
    this.setSelectedRadioValues(event.value);
  }

  setSelectedRadioValues(value: string): void {
    this.selectedRadioButton = value;
  }

  isDatePurchasedEmpty() {
    if (this.ownValue === "yes") {
      if (!this.eBikeForm.get("datePurchased")?.value) {
        return true;
      }
    }
    return false;
  }

  condition() {
    this.ownValue = this.eBikeForm.get("own")?.value;
    const eBike =
      this.dashboardAPIService.energyChoicesData.transportation.eBike![0];
    if (this.ownValue === "yes") {
      this.eBikeForm.enable();
      this.showDatePicker = true;
    } else if (this.ownValue === "yesPlan") {
      this.eBikeForm.get("displacedMiles")?.enable();
      this.eBikeForm.get("displacedMiles")?.setValue(eBike.displacedMiles);
      this.eBikeForm.get("bought")?.disable();
      this.eBikeForm.get("datePurchased")?.disable();
      this.showDatePicker = false;
    } else if (this.ownValue === "noPlan") {
      this.eBikeForm.enable();
      this.eBikeForm.get("displacedMiles")?.setValue(eBike.displacedMiles);
      this.eBikeForm.get("bought")?.disable();
      this.eBikeForm.get("datePurchased")?.disable();
      this.showDatePicker = false;
    }
  }

  onValueChanges(): void {
    this.eBikeForm.valueChanges.subscribe((data) => {
      this.subject.pipe().subscribe(() => {
        if (data.own === "yes") {
          const datePurchased = data.datePurchased;
          const bought = this.utils.isDatePurchaseSelected(datePurchased);
          this.eBikeSettings = [
            {
              own: data.own,
              displacedMiles: data.displacedMiles,
              bought: bought,
              datePurchased: bought ? datePurchased : null,
            },
          ];
        } else if (data.own === "yesPlan" || data.own === "noPlan") {
          this.eBikeSettings = [
            {
              own: data.own,
              displacedMiles: data.displacedMiles,
            },
          ];
        }
        if (this.eBikeForm.valid) {
          this.settingService.updateSettingsInfo(
            this.dashboardAPIService.energyChoicesData.transportation,
            this.eBikeSettings,
            "transportation",
            "eBike"
          );
        } else {
          this.settingService.updateSettingsInfoErrors(
            this.dashboardAPIService.energyChoicesData.transportation,
            this.eBikeSettings,
            "transportation",
            "eBike"
          );
        }
      });
      this.subject.next();
    });
  }

  onSaveEBikeSettings() {
    this.settingService.putSettingsInfo(this.getSettingsInfo);
  }

  getSettingsInfo = () => {
    if (this.activeIcon) {
      this.techSave.emit({ own: this.ownValue, tech: "eBike" });
    }
  };

  ngOnDestroy(): void {}
}
