import { Component, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: "tf-lifestyle-overlay",
  templateUrl: "lifestyle-overlay.component.html",
  styleUrls: ["./lifestyle-overlay.component.scss"],
})
export class LifeStyleOverlayComponent {
  @Input() header: string;
  @Input() image: string;
  @Input() subHeader: string;
  @Input() back: boolean;
  @Output() closeOverlay = new EventEmitter<void>();
  @Output() openHPSC = new EventEmitter<void>();

  closeNav() {
    this.back ? this.openHPSC.emit() : this.closeOverlay.emit();
  }
}
