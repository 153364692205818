import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ManualEntryComponent } from "./manual-entry/manual-entry.component";
import { EnergySyncComponent } from "./energy-sync/energy-sync.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatSliderModule } from "@angular/material/slider";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { DialogBoxModule } from "./Dialog-box";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatSliderModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    DialogBoxModule,
  ],
  declarations: [ManualEntryComponent, EnergySyncComponent],
  exports: [ManualEntryComponent, EnergySyncComponent],
})
export class EnergyInfoModule {}
