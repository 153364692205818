<mat-radio-group class="radioButton-flex">
  <div class="cards" *ngFor="let tech of energyEfficientCategory">
    <label>
      <yt-techradio-button
        [cardImage]="tech.techImage"
        [viewValue]="tech.viewValue"
        [default]="defaultValue"
        [value]="tech.value"
      ></yt-techradio-button>
      <mat-radio-button
        class="hidden_button absolute top-0"
        [value]="tech.value"
        (change)="onTechChange($event)"
      >
      </mat-radio-button>
    </label>
  </div>
</mat-radio-group>
<div class="reward_link" *ngIf="rewardBonusStatus">
  <img src="assets/marketplace/dollar-sign.png" class="dollar_img" />
  <a
    href="mailto:support@yellowtin.com?Subject=Claim your rebate!"
    target="_blank"
    >Claim your $5 rebate!</a
  >
  <mat-icon
    class="tooltip_icon"
    matTooltip="The first 100 employees purchasing the simple/low cost items (marked with $ icon) are eligible for this rebate. Click the link and submit your proof of purchase to claim your rebate."
  >
    help_outline
  </mat-icon>
</div>
<yt-energyEfficientDetail-card
  [techList]="energyEfficientCategory[defaultIndex].techList"
  [rewardBonus]="rewardBonusStatus"
></yt-energyEfficientDetail-card>
