import { ElectricVehicleModel } from "../../homeowner-product/energy-choices/energy-choices.model";
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogOverviewService } from "./dialog-overview.service";
import { DropDownModel } from "../../rest-api/rest-api.model";

@Component({
  selector: "dialog-overview",
  templateUrl: "dialog-overview.component.html",
  styleUrls: ["./dialog-overview.component.scss"],
})
export class DialogOverviewComponent {
  space = " ";
  sortBy: string = "alpha";
  filterBy: string = "none";
  electricVehicleModel: ElectricVehicleModel[];
  loader: boolean;
  filterGroup: DropDownModel[] = [{ viewValue: "Show All", value: "none" }];

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogOverviewService: DialogOverviewService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.get_filtersortData(this.filterBy, this.sortBy);
  }

  onFilterChange(filter) {
    this.filterBy = filter;
    this.get_filtersortData(this.filterBy, this.sortBy);
    this.loader = true;
  }
  onSortChange(sort) {
    this.sortBy = sort;
    this.get_filtersortData(this.filterBy, this.sortBy);
  }
  get_filtersortData(filter, sort) {
    this.loader = true;
    this.filterGroup.splice(1, this.filterGroup.length);
    const tempUrl = `?filterParam=${filter}&sortParam=${sort}&ev=ev1`;
    this.dialogOverviewService
      .getEvModel(tempUrl, this.data.app)
      .subscribe((response) => {
        response.filters.forEach((filter: string) => {
          if (filter !== "none") {
            this.filterGroup.push({
              viewValue: `Type: ${filter}`,
              value: filter,
            });
          }
        });
        this.electricVehicleModel = response.electricVehicles;
        this.loader = false;
      });
  }
}

@Component({
  selector: "dialog-overview-1",
  templateUrl: "dialog-overview.component.html",
  styleUrls: ["./dialog-overview.component.scss"],
})
export class DialogOverview1Component {
  space = " ";
  sortBy: string = "alpha";
  filterBy: string = "none";
  electricVehicleModel: ElectricVehicleModel[];
  loader: boolean;
  filterGroup: DropDownModel[] = [{ viewValue: "Show All", value: "none" }];

  constructor(
    public dialogRef: MatDialogRef<DialogOverview1Component>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogOverviewService: DialogOverviewService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.get_filtersortData(this.filterBy, this.sortBy);
  }

  onFilterChange(filter) {
    this.filterBy = filter;
    this.get_filtersortData(this.filterBy, this.sortBy);
    this.loader = true;
  }
  onSortChange(sort) {
    this.sortBy = sort;
    this.get_filtersortData(this.filterBy, this.sortBy);
  }
  get_filtersortData(filter, sort) {
    this.loader = true;
    const tempUrl = `?filterParam=${filter}&sortParam=${sort}&ev=ev2`;
    this.dialogOverviewService
      .getEvModel(tempUrl, this.data.app)
      .subscribe((response) => {
        this.electricVehicleModel = response.electricVehicles;
        this.loader = false;
      });
  }
}
