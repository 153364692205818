import { Component, OnChanges, Input, SimpleChange } from '@angular/core';

@Component({
  selector: 'yt-password-meter',
  templateUrl: 'password-meter.component.html',
  styleUrls: ['./password-meter.component.scss'],
})
export class PasswordMeterComponent implements OnChanges {
  @Input() passwordToCheck: string;
  @Input() barLabel: string;
  bar0: string;
  bar1: string;
  bar2: string;
  msg: string;
  private colors = ['#F40B0B', ' #FFE27A', '#4CAF50'];
  private text = ['Weak', 'Average', 'Strong'];

  private static measureStrength(pass: string): number {
    let variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      special: /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/.test(pass),
    };
    let passwordStatus = Object.values(variations).filter((pattern) => pattern);
    return passwordStatus.length;
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    let password = changes['passwordToCheck'].currentValue;
    this.setBarColors(3, '#E6E6E6', '');
    if (password) {
      let checkedPassword = this.getColor(
        PasswordMeterComponent.measureStrength(password),
        password.length
      );
      this.setBarColors(
        checkedPassword.index,
        checkedPassword.color,
        checkedPassword.textMsg
      );
    }
  }

  private getColor(score: number, passwordLength: number) {
    let index = 0;
    if (score > 2 && passwordLength > 7) {
      index = 2;
    } else if (score > 1 && passwordLength > 4) {
      index = 1;
    } else if (score > 0) {
      index = 0;
    }
    return {
      index: index + 1,
      color: this.colors[index],
      textMsg: this.text[index],
    };
  }

  private setBarColors(index: number, color: string, textMsg: string): void {
    for (let item = 0; item < index; item++) {
      this['bar' + item] = color;
      this['msg'] = textMsg;
    }
  }
}
