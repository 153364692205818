import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RestAPIService } from "../../rest-api/rest-api.service";
import { RequestMessageModel } from "../../rest-api/rest-api.model";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "yt-expert-popup",
  templateUrl: "./expert-popup.component.html",
  styleUrls: ["./expert-popup.component.scss"],
})
export class ExpertPopupComponent {
  userMessage: FormGroup;
  @Output() closePopup: EventEmitter<boolean> = new EventEmitter();
  @ViewChild("expertOverlay", { read: ElementRef }) modal: ElementRef;

  constructor(
    private restAPI: RestAPIService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar
  ) {
    this.userMessage = this.fb.group({
      userText: ["", Validators.required],
    });
  }

  @HostListener("document:keydown", ["$event"])
  onKeyDown(event) {
    if (event.key == "Escape") {
      this.onClose();
    }
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event): void {
    if (this.modal.nativeElement) {
      if (this.modal.nativeElement === event.target) {
        this.onClose();
      }
    }
  }

  onClose() {
    this.closePopup.emit();
  }

  sendRequest(): void {
    const userRequest: RequestMessageModel = {
      message: this.userMessage.get("userText")?.value,
    };
    this.restAPI.requestMessage(userRequest, "expertAdvice").subscribe(
      (data) => {
        if (data.message === "Mailed") {
          this.snackBar.open(
            "Your message has been sent. Our team will reach out to you shortly.",
            "OK",
            {
              duration: 2000,
            }
          );
          this.onClose();
        }
      },
      (error) => console.error(error.error.message)
    );
  }
}
