import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import {
  HomeSettingDataModel,
  SettingsDialogData,
} from "../setting-overlay.component.model";
import { MatRadioChange } from "@angular/material/radio";
import { TransportationDataModel } from "../../../model/userdata.model";
import { MNFAndModel, MNFAndModelListResponse } from "../../../../../homeowner-product/energy-choices/energy-choices.model";
import { RestAPIService } from "../../../../../rest-api/rest-api.service";

@Component({
  selector: "yt-transportation",
  templateUrl: "transportation.component.html",
  styleUrls: ["../common-setting-overlay.scss"],
})
export class TransportationComponent implements OnInit {
  defaultValue: string;
  makeAndModelList1: MNFAndModel[];
  makeAndModelList2: MNFAndModel[];
  displayTransportation: HomeSettingDataModel[];
  evList: string[] = ["ev", "ev1", "ev2"];
  @Input() activeTech: string;
  @Input() activeIcon: string;
  @Input() transportationTechDetails: TransportationDataModel;
  @Input() transportationJSONDetails: HomeSettingDataModel[];
  @Output() techSave: EventEmitter<SettingsDialogData> = new EventEmitter();

  constructor(private restAPIService: RestAPIService) {}

  ngOnInit(): void {
    this.displayTransportation = this.transportationJSONDetails.filter(
      (tech, i) =>
        Object.keys(this.transportationTechDetails).includes(tech.value)
    );
    this.restAPIService
      .getMakeAndModelList("ev1")
      .subscribe((response: MNFAndModelListResponse) => {
        this.makeAndModelList1 = response.mnfDropDownList;
      });
      this.restAPIService
      .getMakeAndModelList("ev2")
      .subscribe((response: MNFAndModelListResponse) => {
        this.makeAndModelList2 = response.mnfDropDownList;
      });
    // Updating active tech incase of ev
    let tech = this.activeTech;
    if (this.evList.includes(tech)) {
      tech = "ev";
    }
    this.setDefaultValues(tech || this.displayTransportation[0].value);
  }

  emitTech(value: SettingsDialogData): void {
    this.techSave.emit(value);
  }

  setDefaultValues(value: string): void {
    this.defaultValue = value;
  }

  onTechChange(event: MatRadioChange): void {
    this.activeIcon = "";
    this.setDefaultValues(event.value);
  }
}
