import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "yt-answer-box",
  templateUrl: "answer-box.component.html",
  styleUrls: ["./answer-box.component.scss"],
})
export class AnswerBoxComponent implements OnInit {
  @Input() image: string;
  @Input() displayValue: string;
  @Input() selectedValue: boolean;
  @Input() visited: boolean;

  ngOnInit() {}
}
