import { Component, Input } from "@angular/core";

@Component({
  selector: "yt-loader",
  templateUrl: "loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent {
  @Input() blackLoader: boolean;
  @Input() top: string;
  @Input() relative: boolean;
}
