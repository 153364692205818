<div class="w-100 margin__bottom energy-choices" [formGroup]="hpwhForm">
  <div>
    <div class="yt-text">
      Most homes still have a conventional natural gas or electric water heater,
      but many consumers are now selecting super-efficient electric water
      heaters -- <strong>heat pump water heaters</strong>. These are the best
      options for low energy costs & low impacts on climate.
    </div>
    <div class="yt_p_top">
      <mat-expansion-panel
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        [expanded]="true"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="yt__panel-header"
            >Your Choices</mat-panel-title
          >
        </mat-expansion-panel-header>
        <div class="cf">
          <div class="w-100">
            <div class="w-100 w-50-l fl radio-buttons">
              <mat-radio-group
                class="example-radio-group"
                (change)="condition()"
                formControlName="own"
              >
                <div class="yt-Btn-Font">
                  Tell us about your heat pump water heater
                </div>
                <div class="radio_text">
                  <div class="dib yt_ques-padding">
                    <mat-radio-button value="yes">
                      <span class="yt_option">Already have one</span>
                    </mat-radio-button>
                  </div>
                  <div class="dib yt_answer-padding-2">
                    <mat-radio-button value="yesPlan">
                      <span class="yt_option">Interested in getting one</span>
                    </mat-radio-button>
                  </div>
                  <div class="dib yt_answer-padding-2">
                    <mat-radio-button value="no">
                      <span class="yt_option">Not interested</span>
                    </mat-radio-button>
                  </div>
                </div>
              </mat-radio-group>
            </div>
            <div
              class="w-100 w-100-m w-50-l fl yt_paddingtop-42 yt_border-left"
            >
              <div>
                <div
                  class="yt__f4 yt_pb-7"
                  [ngStyle]="{
                    color:
                      hpwhForm.controls['own'].value === 'no'
                        ? '#c7c7c7'
                        : '#333333'
                  }"
                >
                  Number of people in household
                </div>
                <div class="yt__pt0-7">
                  <mat-form-field
                    appearance="outline"
                    floatLabel="auto"
                    [ngClass]="{
                      'disable-field': hpwhForm.controls['own'].value === 'no'
                    }"
                  >
                    <input
                      matInput
                      type="text"
                      class="quantity"
                      placeholder="Enter 1-10"
                      formControlName="howManyPeople"
                      maxlength="3"
                    />
                    <mat-error
                      *ngIf="
                        hpwhForm.controls['howManyPeople'].hasError(
                          'pattern'
                        ) ||
                        hpwhForm.controls['howManyPeople'].hasError('min') ||
                        hpwhForm.controls['howManyPeople'].hasError('max')
                      "
                    >
                      Please enter value between 1-10
                    </mat-error>
                    <mat-error
                      *ngIf="
                        hpwhForm.controls['howManyPeople'].hasError('required')
                      "
                    >
                      Required!
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div
                class="yt__f4 yt_pt1-75"
                [ngStyle]="{
                  color:
                    hpwhForm.controls['own'].value === 'no' ||
                    hpwhForm.controls['own'].value === 'yes'
                      ? '#c7c7c7'
                      : '#333333'
                }"
              >
                Type of current water heater
              </div>
              <div class="yt__pt0-7">
                <mat-form-field
                  appearance="outline"
                  floatLabel="auto"
                  [ngClass]="{
                    'disable-field':
                      hpwhForm.controls['own'].value === 'no' ||
                      hpwhForm.controls['own'].value === 'yes'
                  }"
                  hideRequiredMarker="true"
                >
                  <mat-select
                    placeholder="Appliance"
                    formControlName="currentHeater"
                  >
                    <mat-option
                      *ngFor="let type of hpwhTypes"
                      [value]="type.value"
                    >
                      {{ type.viewValue }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      hpwhForm.controls['currentHeater'].hasError('required')
                    "
                  >
                    Required!
                  </mat-error>
                </mat-form-field>
              </div>
              <div>
                <div
                  class="yt__f4 yt_pt1-75"
                  [ngStyle]="{
                    color:
                      hpwhForm.controls['own'].value === 'no' ||
                      hpwhForm.controls['own'].value === 'yes'
                        ? '#c7c7c7'
                        : '#333333'
                  }"
                >
                  Age of current water heater
                </div>
                <div
                  class="yt__pt0-7"
                  [ngClass]="{ 'yt__pb-20': !disableDatePicker }"
                >
                  <mat-form-field
                    appearance="outline"
                    floatLabel="auto"
                    [ngClass]="{
                      'disable-field':
                        hpwhForm.controls['own'].value === 'no' ||
                        hpwhForm.controls['own'].value === 'yes'
                    }"
                  >
                    <input
                      matInput
                      type="text"
                      class="quantity"
                      placeholder="Enter 0-30"
                      formControlName="age"
                      maxlength="3"
                    />
                    <mat-error
                      *ngIf="
                        hpwhForm.controls['age'].hasError('pattern') ||
                        hpwhForm.controls['age'].hasError('min') ||
                        hpwhForm.controls['age'].hasError('max')
                      "
                    >
                      Please enter value between 0-30
                    </mat-error>
                    <mat-error
                      *ngIf="hpwhForm.controls['age'].hasError('required')"
                    >
                      Required!
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div *ngIf="disableDatePicker">
                <article class="cf">
                  <div
                    class="fl w-60-c yt__f4 yt_pt1-75"
                    [ngStyle]="{
                      color:
                        hpwhForm.controls['own'].value === 'no' ||
                        hpwhForm.controls['own'].value === 'yesPlan'
                          ? '#c7c7c7'
                          : '#333333'
                    }"
                  >
                    Purchased month & year
                  </div>
                </article>
                <div class="yt__pb-20 yt__pt0-7">
                  <mat-form-field
                    appearance="outline"
                    floatLabel="auto"
                    [ngClass]="{
                      'disable-field':
                        hpwhForm.controls['own'].value === 'no' ||
                        hpwhForm.controls['own'].value === 'yesPlan'
                    }"
                  >
                    <mat-select
                      matInput
                      placeholder="Select"
                      formControlName="datePurchased"
                    >
                      <mat-option
                        *ngFor="let month of monthList"
                        [value]="month"
                        >{{ month | titlecase }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                  <div
                    class="cb w-100 yt_error-message"
                    *ngIf="
                      isDatePurchasedEmpty() &&
                      hpwhForm.controls['datePurchased'].touched
                    "
                  >
                    Required!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
  <div class="pt_30 w-100">
    <button
      mat-raised-button
      color="accent"
      (click)="onSaveHPWH()"
      [disabled]="hpwhForm.invalid || isDatePurchasedEmpty()"
      class="fr w-100 yt__mb-12"
    >
      SAVE
    </button>
  </div>
</div>
