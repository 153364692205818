import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { TimeFrameEnum } from "../../learn-more.enum";
import * as Highcharts from "highcharts";
import { DashboardAPIService } from "../../../../rest-api/dashboard/dashboard-api.service";
import {
  BarChartDataModel,
  MarketPlaceDetailsModel,
} from "../../learn-more.model";
import { LearnMoreService } from "../../learn-more.service";
import {
  CommunitySolarAssumptionModel,
  CommunitySolarDetailsModel,
  CommunitySolarFinancialModel,
  CommunitySolarLearnMoreModel,
  CommunitySolarTechnicalModel,
} from "./community-details.model";
import { SectionListModel } from "../../../../rest-api/dashboard/dashboard-api.model";
import { DropDownModel } from "../../../../rest-api/rest-api.model";
import { AppService } from "../../../../app.service";
import { OverviewService } from "src/app/homeowner-dashboard/overview/overview.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "yt-community-details",
  templateUrl: "./community-details.component.html",
  styleUrls: ["../../common-learn-more.scss"],
})
export class CommunityDetailsComponent implements OnInit, AfterContentChecked {
  loader = true;
  icon = false;
  icon1 = false;
  panelOpenState = false;
  selected: TimeFrameEnum;
  twentyYear = false;
  oneYear = true;
  oneMonth = false;
  haveResponse = false;
  displayMarketPlaceBox: boolean;
  marketPlaceLink: string;
  communitySolarSavings: number;
  showTooltipZero: boolean;
  chartOptions1: Highcharts.Options = {};
  communitySolarForm: FormGroup;
  timeFrame: string;
  communitySolarViewDetails: CommunitySolarDetailsModel;
  financialDetails: CommunitySolarFinancialModel;
  technicalDetails: CommunitySolarTechnicalModel;
  assumptionDetails: CommunitySolarAssumptionModel;
  marketPlaceDetails: MarketPlaceDetailsModel;
  adviceCardStatus: boolean;
  isLangSpanish: boolean;
  tabIndex: number = 0;
  tabLabel: string;
  selectedSection: string = "aboutTechnology";
  FAQTabsList: DropDownModel[];
  isTechnicalExpanded: boolean;
  isFAQExpanded: boolean;
  favStatus: boolean;
  timeSegmentList: DropDownModel[];
  favIcon: string;
  learnMoreDetails: CommunitySolarLearnMoreModel;
  @ViewChild("container", { read: ElementRef }) container: ElementRef;
  @ViewChild("loaderButton", { read: ElementRef }) saveButton: ElementRef;

  constructor(
    private snackBar: MatSnackBar,
    private overviewService: OverviewService,
    private fb: FormBuilder,
    private learnMoreService: LearnMoreService,
    private dashboardAPIService: DashboardAPIService,
    private cdRef: ChangeDetectorRef,
    private appService: AppService
  ) {
    this.timeSegmentList = this.learnMoreService.timeSegmentList;
    this.communitySolarForm = this.fb.group({
      electricBill: [""],
    });
  }
  ngAfterContentChecked(): void {
    this.isLangSpanish = this.appService.selectedLanguage.value === "sp";
    this.cdRef.detectChanges(); // TO avoid expression checking error in console NG100
  }

  ngOnInit(): void {
    this.setTimeSelector(this.dashboardAPIService.timeFrame);
    this.getCommunitySolarViewDetails(this.selected);
    this.dashboardAPIService
      .getMarketplaceDetails("communitySolar")
      .subscribe((response: MarketPlaceDetailsModel) => {
        this.marketPlaceDetails = response;
        this.adviceCardStatus = response.expertAdvice;
        this.displayMarketPlaceBox = this.marketPlaceDetails.displayBox;
        this.marketPlaceLink = this.marketPlaceDetails.buttonLink;
      });
  }

  getCommunitySolarViewDetails(period: TimeFrameEnum): void {
    this.learnMoreService
      .learnMoreInfo<CommunitySolarDetailsModel>("cs")
      .subscribe((response: CommunitySolarDetailsModel) => {
        this.loader = false;
        this.haveResponse = true;
        this.communitySolarViewDetails = response;
        this.dashboardAPIService
          .faqDetails()
          .subscribe((list: SectionListModel) => {
            this.FAQTabsList = list.communitySolar.faqList!;
          });
        this.technicalDetails = this.communitySolarViewDetails.technicalDetails;
        this.financialDetails = this.communitySolarViewDetails.financialDetails;
        this.assumptionDetails =
          this.communitySolarViewDetails.assumptionsDetails;
        this.favStatus =
          this.communitySolarViewDetails.learnMoreDetails.favoriteStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
        this.updateChart(period);
        this.showTooltipZero = this.communitySolarSavings <= 0;
      });
  }

  updateChart(period: TimeFrameEnum): void {
    this.setTimeSelector(period);

    if (period === TimeFrameEnum.TwentyYear) {
      let electricityCostWOCS_TY;
      let electricityCostWCS_TY;
      let solarSavings_TY;

      electricityCostWCS_TY = this.setEnergyCostValue(
        this.financialDetails.csSavings.withCommunitySolar.twentyYear
      );
      electricityCostWOCS_TY = this.setEnergyCostValue(
        this.financialDetails.csSavings.withoutCommunitySolar.twentyYear
      );
      solarSavings_TY = this.financialDetails.csSavings.total.twentyYear;

      let dataInputs: BarChartDataModel[] = [
        { y: electricityCostWCS_TY, color: "#DFE154" },
        { y: electricityCostWOCS_TY, color: "#414141" },
      ];
      this.communitySolarSavings = solarSavings_TY;
      this.updateChartOptions(dataInputs);
    }
    if (period === TimeFrameEnum.OneYear) {
      let electricityCostWOCS_OY;
      let electricityCostWCS_OY;
      let solarSaviings_OY;

      electricityCostWCS_OY = this.setEnergyCostValue(
        this.financialDetails.csSavings.withCommunitySolar.oneYear
      );
      electricityCostWOCS_OY = this.setEnergyCostValue(
        this.financialDetails.csSavings.withoutCommunitySolar.oneYear
      );
      solarSaviings_OY = this.financialDetails.csSavings.total.oneYear;

      let dataInputs: BarChartDataModel[] = [
        { y: electricityCostWCS_OY, color: "#DFE154" },
        { y: electricityCostWOCS_OY, color: "#414141" },
      ];
      this.communitySolarSavings = solarSaviings_OY;
      this.updateChartOptions(dataInputs);
    }
    if (period === TimeFrameEnum.TenYear) {
      let electricityCostWOCS_TENY;
      let electricityCostWCS_TENY;
      let solarSaviings_TENY;

      electricityCostWCS_TENY = this.setEnergyCostValue(
        this.financialDetails.csSavings.withCommunitySolar.tenYear
      );
      electricityCostWOCS_TENY = this.setEnergyCostValue(
        this.financialDetails.csSavings.withoutCommunitySolar.tenYear
      );
      solarSaviings_TENY = this.financialDetails.csSavings.total.tenYear;

      let dataInputs: BarChartDataModel[] = [
        { y: electricityCostWCS_TENY, color: "#DFE154" },
        { y: electricityCostWOCS_TENY, color: "#414141" },
      ];
      this.communitySolarSavings = solarSaviings_TENY;
      this.updateChartOptions(dataInputs);
    }
    if (period === TimeFrameEnum.OneMonth) {
      let electricityCostWOCS_OM;
      let electricityCostWCS_OM;
      let solarSavings_OM;

      electricityCostWCS_OM = this.setEnergyCostValue(
        this.financialDetails.csSavings.withCommunitySolar.oneMonth
      );
      electricityCostWOCS_OM = this.setEnergyCostValue(
        this.financialDetails.csSavings.withoutCommunitySolar.oneMonth
      );
      solarSavings_OM = this.financialDetails.csSavings.total.oneMonth;

      let dataInputs: BarChartDataModel[] = [
        { y: electricityCostWCS_OM, color: "#DFE154" },
        { y: electricityCostWOCS_OM, color: "#414141" },
      ];
      this.communitySolarSavings = solarSavings_OM;
      this.updateChartOptions(dataInputs);
    }
  }

  setEnergyCostValue(value: Number): Number {
    return value === 0 ? 0.0001 : value;
  }

  setTimeSelector(period: TimeFrameEnum): void {
    this.selected = period;
    if (this.selected) {
      this.timeFrame = this.learnMoreService
        .renderTimeFrame(this.selected)
        .toLowerCase();
    }
  }

  updateChartOptions(inputData: BarChartDataModel[]): void {
    let filteredInputData: BarChartDataModel[] = [];
    let i = 0;
    for (let entry of inputData) {
      if (entry.y != 0) {
        filteredInputData[i] = entry;
        i++;
      }
    }
    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
      },
    });

    this.chartOptions1 = {
      credits: {
        enabled: false,
      },
      chart: {
        type: "bar",
      },
      title: {
        text: "",
      },
      xAxis: {
        labels: {
          style: {
            color: "#333333",
            fontSize: "16px",
            fontFamily: "Open sans",
          },
        },
        categories: [
          `Electricity cost <br> with community solar`,
          `Electricity cost <br> without community solar`,
        ],
        gridLineWidth: 0,
        lineWidth: 1,
        tickWidth: 0,
      },
      yAxis: {
        tickAmount: 8,
        tickWidth: 0,
        gridLineWidth: 0,
        lineWidth: 1,
        title: {
          text: null,
        },
        labels: {
          style: {
            color: "#333333",
            fontSize: "12px",
            fontFamily: "Open sans",
          },
          formatter: function (this) {
            if (Number(this.value) >= 1000) {
              return "$" + Number(this.value) / 1000 + "K";
            } else {
              return "$" + this.value;
            }
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          pointWidth: 50,
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "16px",
              fontWeight: "normal",
              fontFamily: "Open sans",
            },
            formatter: function (this) {
              if (this.point.y && this.point.y < 1) {
                return "$0";
              } else {
                return (
                  "$" +
                  this.point.y?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                );
              }
            },
            animation: {
              duration: 20,
            },
          },
        },
        series: {
          borderWidth: 0,
          animation: {
            duration: 1500,
          },
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              chart: {
                className: "small-chart",
              },
            },
          },
        ],
      },
      series: [
        {
          type: "bar",
          showInLegend: false,
          data: filteredInputData,
        },
      ],
    };
    Highcharts.chart(this.container.nativeElement, this.chartOptions1);
  }

  handleActiveValue(data: string): void {
    if (data === "details") {
      this.isTechnicalExpanded = true;
      this.icon = true;
    } else if (data === "faq") {
      this.isFAQExpanded = true;
      this.icon1 = true;
    }
  }

  expansionPanelClick(): void {
    this.icon = !this.icon;
  }

  basicInfoPanelClick(): void {
    this.icon1 = !this.icon1;
  }

  tabChanged(event: any) {
    this.tabLabel = event.value;
    this.tabIndex = event.index;
  }

  scrollToTop(): void {
    document.querySelector(".mat-sidenav-content")?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  handleFAQClick(): void {
    this.dashboardAPIService.dashboardDetailsAnalytics(
      "moreInfo",
      "communitySolar"
    );
    window.open(
      "https://www.yellowtin.com/clean-energy-information-center-es/community-solar-faq-es/",
      "_blank"
    );
  }

  onFavorite(): void {
    const ownPlan = this.favStatus ? "noPlan" : "yesPlan";
    this.overviewService
      .updateTechSettingInfo("communitySolar", ownPlan)
      .subscribe((response) => {
        this.favStatus = !this.favStatus;
        this.favIcon = this.learnMoreService.updateFavoriteIcon(this.favStatus);
        this.getCommunitySolarViewDetails(this.selected);

        const action = this.favStatus ? "add" : "remove";
        const info = this.dashboardAPIService.techMessage(
          "communitySolar",
          action
        );
        this.snackBar.open(info, "OK", {
          duration: 8000,
        });
      });
  }
}
