import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  OnChanges,
} from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DashboardAPIService } from "../../../../rest-api/dashboard/dashboard-api.service";
import { SettingOverlayComponent } from "../setting-overlay/setting-overlay.component";
import { SettingsOverlayService } from "../setting-overlay/settings-overlay.service";

@Component({
  selector: "yt-home-settings",
  templateUrl: "./home-settings.component.html",
  styleUrls: ["./home-settings.component.scss"],
})
export class HomeSettingsComponent implements OnInit, OnChanges {
  userEmail: string;
  savingsURL: string;
  @Input() showHomeSettings: boolean;
  @Input() everGreenStatus: boolean;
  @Output() updateSavings: EventEmitter<void> = new EventEmitter();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    private dashboardAPIService: DashboardAPIService,
    private settingService: SettingsOverlayService
  ) {}
  ngOnChanges(): void {
    if (this.showHomeSettings) {
      this.onUpdate();
    }
  }

  ngOnInit(): void {
    this.userEmail = this.dashboardAPIService.getUserDetails().userName;
    this.savingsURL = this.dashboardAPIService.savingsUrl;
    if (this.dashboardAPIService.energyInfoData) {
      this.onUpdate();
    }
  }

  onUpdate(): void {
    this.dashboardAPIService.dashboardDetailsAnalytics("updateSetting");
    const dialogConfig = new MatDialogConfig();
    this.updateSavings.emit();
    dialogConfig.position = { right: "0" };
    let dialogRef = this.dialog.open(SettingOverlayComponent, {
      width: "70%",
      height: "100%",
      autoFocus: false,
      backdropClass: "dark-backdrop",
      panelClass: "homeSetting_container",
      data: { tech: "" },
      disableClose: true,
      exitAnimationDuration: 0,
    });

    dialogRef.backdropClick().subscribe(() => {
      if (
        !!Object.keys(this.settingService.settingsInfo).length ||
        !!Object.keys(this.settingService.settingsInfoErrors).length
      ) {
        dialogRef.componentInstance.settingStatus = true;
      } else {
        dialogRef.close();
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.onClose.emit(false);
      this.dashboardAPIService.energyInfoData = false;
      this.settingService.settingsInfo = {};
      this.updateSavings.emit();
    });
  }
}
