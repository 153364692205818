import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { DropDownModel } from "../../../rest-api/rest-api.model";

@Component({
  selector: "yt-customized-tab",
  templateUrl: "./customized-tab.component.html",
  styleUrls: ["./customized-tab.component.scss"],
})
export class CustomizedTabComponent implements AfterViewChecked {
  scrollOffSet: number = 200;
  tabIndex: number = 0;
  showScrollButton: boolean = false;
  tabHeaderElem: HTMLDivElement;
  @Input() tabHeadersList: DropDownModel[];
  @Input() minWidth: string;
  @Input() selectedIndex: number;
  @Output() tabValue: EventEmitter<{ value: string; index: number }> =
    new EventEmitter();
  constructor() {}

  ngAfterViewChecked(): void {
    this.tabHeaderElem =
      document.querySelector<HTMLDivElement>(".tab-headers")!;
    this.onResize();
    this.tabIndex = this.selectedIndex ?? this.tabIndex;
  }

  tabChanged(value: string, i: number): void {
    this.tabValue.emit({ value: value, index: i });
    this.tabIndex = i;
  }

  handleScroll(button: string): void {
    let left = this.tabHeaderElem.scrollLeft;
    if (button === "prev") {
      left -= this.scrollOffSet;
    } else if (button === "next") {
      left += this.scrollOffSet;
    }
    this.tabHeaderElem.scrollTo({ left, behavior: "smooth" });
  }

  isButtonDisabled(button: string): boolean {
    if (button === "next") {
      return (
        this.tabHeaderElem.scrollLeft + this.tabHeaderElem.clientWidth ===
        this.tabHeaderElem.scrollWidth
      );
    } else {
      return this.tabHeaderElem.scrollLeft === 0;
    }
  }

  onResize(): void {
    this.showScrollButton =
      this.tabHeaderElem.scrollWidth > this.tabHeaderElem.clientWidth;
  }
}
