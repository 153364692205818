<div class="faq_container">
  <div class="about_technology" *ngIf="showAboutTech">
    <div class="header">Overview</div>
    <p>
      <strong>Vanquish Vampire Loads:</strong> Smart power strips provide an
      inexpensive and convenient way to cut down on your home’s “vampire loads”.
      These are standby uses of electricity that suck electricity from always-on
      devices like computers and TVs even when they’re not being used.<span
        class="link_text"
        *ngIf="showReadMore"
        (click)="onReadContent('readMore')"
      >
        Read more...
      </span>
    </p>

    <div *ngIf="showReadLess">
      <p class="pt3">
        <strong>Savings Around the Clock:</strong> While it may not seem like a
        lot, standby loads can add up. A single device using just 1 Watt all the
        time can cost you $1 - $3 each year (depending on your electricity
        rate). When you multiply that by the number of always-on devices in your
        home and the older devices that use well over 1 Watt, standby losses can
        be 5% or more of your overall electricity use. A smart power strip is a
        simple way to trim down these losses.
      </p>

      <p class="pt3">
        <strong>Cost:</strong> Various types of smart power strips are available
        for under $30. Two or three of them are sufficient to cover a typical
        home.
      </p>

      <p class="pt3">
        <strong>Ultimate DIY Project:</strong> Setting up a smart power strip is
        quite straightforward. It’s as easy as plugging your current devices
        into the appropriate outlets on the strip.
        <span class="link_text" (click)="onReadContent('readLess')">
          Read less
        </span>
      </p>
    </div>
  </div>
  <div *ngIf="!showAboutTech">
    <div *ngIf="isActive(0) || activeIndex">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. What is a smart power strip?</h5>
        <p class="answer_text">
          Smart power strips may look like ordinary power strips, but they make
          it easier to reduce the energy wasted by electrical devices that use
          power while in standby mode. They contain a primary electrical outlet,
          multiple secondary outlets, and electronics that make them
          intelligent. In a typical mode of operation, they monitor the primary
          outlet continuously and when the appliance connected to it is switched
          off, they cut off power through the secondary outlets, thus removing
          the power leaching due to standby power losses. In your home office,
          you might plug the computer into the primary outlet and the
          peripherals (such as printer, monitor, etc.) into the secondary
          outlets. You can also use a smart power strip to cut standby losses
          from a home entertainment system.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">
          2. Are there different types of smart power strips?
        </h5>
        <p class="answer_text">
          Yes, they come with different functionality. As noted just above, some
          control secondary loads based on the condition of the primary load.
          Other smart power strips have USB ports, power surge protection,
          motion sensors that turn the power on depending on whether someone is
          in the room, or timers that control when the power is available. Still
          others can be connected to the Internet, allowing for control through
          an app or a smart assistant such as Amazon Alexa.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(1)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How much do smart power strips cost?</h5>
        <p class="answer_text">
          Most smart power strips cost under $30. However, you can often find
          basic ones for less than $20, while more-advanced ones (with
          additional features) can cost more than $50. Note that you’re likely
          to require more than one for your home.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">2. How much can I save?</h5>
        <p class="answer_text">
          This will depend on the cost of electricity in your area, and the
          characteristics of your energy use and your energy-using devices.
          However, smart power strips can sometimes help you save $100 or more
          each year. In general, smart power strips can pay for themselves
          pretty quickly.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(2)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. How do smart power strips help the environment?
        </h5>
        <p class="answer_text">
          Cutting back on your electricity usage will also cut your carbon
          footprint. While power strips won’t dramatically impact your
          footprint, every step helps, and this one is pretty easy.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(3)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. Where should I use a smart power strip?</h5>
        <p class="answer_text">
          One typical use is in a home office where there is a desktop computer
          system. The desktop CPU can be connected to the primary outlet and all
          peripherals and monitors to the secondary outlets. Another use is a
          TV/home entertainment system where the TV could tie into the
          controlling outlet and the media players, game console, sound system,
          etc. can be connected to the secondary outlets.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(4)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. What incentives are available?</h5>
        <p class="answer_text">
          Smart power strips are sometimes provided for free along with home
          energy-efficiency surveys. You might check the websites of your local
          utility and state/local government energy agency to see if they offer
          such a program. A survey can be a great way to learn about numerous
          ways to save on your energy bill.
        </p>
      </div>
    </div>
  </div>
</div>
