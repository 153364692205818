<div class="w-100 margin__bottom energy-choices" [formGroup]="icForm">
  <div>
    <div class="yt-text">
      Professional chefs as well as consumers are turning to induction cooking
      because of its speed and accurate temperature control. As an added
      benefit, when they replace natural gas,<strong>
        induction cooktops</strong
      >
      also provide a dramatic reduction in indoor air pollution.
    </div>
    <div class="yt_p_top">
      <mat-expansion-panel
        [expanded]="true"
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="yt__panel-header"
            >Your Choices</mat-panel-title
          >
        </mat-expansion-panel-header>
        <div class="cf">
          <div class="w-100">
            <div class="w-100 w-50-l fl radio-buttons">
              <mat-radio-group
                class="example-radio-group"
                (change)="condition()"
                formControlName="own"
              >
                <div class="yt-Btn-Font">
                  Tell us about your
                  <span *ngIf="buildType">portable</span> induction cooktop
                </div>
                <div class="radio_text">
                  <div class="dib yt_ques-padding">
                    <mat-radio-button value="yes">
                      <span class="yt_option">Already have one</span>
                    </mat-radio-button>
                  </div>
                  <div class="dib yt_answer-padding-2">
                    <mat-radio-button value="yesPlan">
                      <span class="yt_option">Interested in getting one</span>
                    </mat-radio-button>
                  </div>
                  <div class="dib yt_answer-padding-2">
                    <mat-radio-button value="no">
                      <span class="yt_option">Not interested</span>
                    </mat-radio-button>
                  </div>
                </div>
              </mat-radio-group>
            </div>

            <div
              class="w-100 w-100-m w-50-l fl yt_paddingtop-42 yt_border-left"
            >
              <div>
                <div
                  class="yt__f4 yt_pb-7"
                  [ngStyle]="{
                    color:
                      icForm.controls['own'].value === 'no' ||
                      icForm.controls['own'].value === 'noPlan'
                        ? '#c7c7c7'
                        : '#333333'
                  }"
                >
                  Number of people in household
                </div>
                <div class="yt__pt0-7">
                  <mat-form-field
                    appearance="outline"
                    floatLabel="auto"
                    [ngClass]="{
                      'disable-field':
                        icForm.controls['own'].value === 'no' ||
                        icForm.controls['own'].value === 'noPlan'
                    }"
                  >
                    <input
                      matInput
                      type="text"
                      class="quantity"
                      formControlName="howManyPeople"
                      maxlength="3"
                      placeholder="Enter 1-10"
                    />
                    <mat-error
                      *ngIf="
                        icForm.controls['howManyPeople'].hasError('pattern') ||
                        icForm.controls['howManyPeople'].hasError('min') ||
                        icForm.controls['howManyPeople'].hasError('max')
                      "
                    >
                      Please enter value between 1-10
                    </mat-error>
                    <mat-error
                      *ngIf="
                        icForm.controls['howManyPeople'].hasError('required')
                      "
                    >
                      Required!
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div>
                <div
                  class="yt__f4 yt_pt1-75"
                  [ngStyle]="{
                    color:
                      icForm.controls['own'].value !== 'yesPlan'
                        ? '#c7c7c7'
                        : '#333333'
                  }"
                >
                  Current cooktop type
                </div>
                <div
                  class="yt__pt0-7"
                  [ngClass]="{ 'yt__pb-20': !disableDatePicker }"
                >
                  <mat-form-field
                    appearance="outline"
                    floatLabel="auto"
                    [ngClass]="{
                      'disable-field':
                        icForm.controls['own'].value !== 'yesPlan'
                    }"
                    hideRequiredMarker="true"
                  >
                    <mat-select
                      placeholder="Current cooktop type"
                      formControlName="currentRange"
                    >
                      <mat-option
                        [value]="range.value"
                        *ngFor="let range of currentRanges"
                      >
                        {{ range.viewValue }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        icForm.controls['currentRange'].hasError('required')
                      "
                    >
                      Required!
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div *ngIf="disableDatePicker">
                <div
                  class="yt__f4 yt_pt1-75"
                  [ngStyle]="{
                    color:
                      icForm.controls['own'].value === 'no' ||
                      icForm.controls['own'].value === 'yesPlan'
                        ? '#c7c7c7'
                        : '#333333'
                  }"
                >
                  Purchased month & year
                </div>
                <div class="yt__pb-20 yt__pt0-7">
                  <mat-form-field
                    appearance="outline"
                    floatLabel="auto"
                    [ngClass]="{
                      'disable-field':
                        icForm.controls['own'].value === 'no' ||
                        icForm.controls['own'].value === 'yesPlan'
                    }"
                  >
                    <mat-select
                      matInput
                      placeholder="Select"
                      formControlName="datePurchased"
                    >
                      <mat-option
                        *ngFor="let month of monthList"
                        [value]="month"
                        >{{ month | titlecase }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div
                    class="cb w-100 yt_error-message"
                    *ngIf="
                      isDatePurchasedEmpty() &&
                      icForm.controls['datePurchased'].touched
                    "
                  >
                    Required!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
  <div class="pt_30 w-100">
    <button
      mat-raised-button
      color="accent"
      (click)="onSaveIC()"
      [disabled]="icForm.invalid || isDatePurchasedEmpty()"
      class="fr w-100 yt__mb-12"
    >
      SAVE
    </button>
  </div>
</div>
