export enum TechName {
  THERMOSTAT = "Smart Thermostat",
  WEATHER_STRIPPING = "Weather Stripping",
  AERATORS_SHOWER_HEADS = "Low-Flow Showerheads and Aerators",
  LM_AERATORS_SHOWER_HEADS = "Showerheads and Aerators",
  POWER_STRIPS = "Smart Power Strips",
  LED_BULBS = "LED Bulbs",
  ELECTRIC_VEHICLE = "Electric Vehicle",
  ELECTRIC_VEHICLE_1 = "Electric Vehicle 1",
  ELECTRIC_VEHICLE_2 = "Electric Vehicle 2",
  ELECTRIC_BIKE = "Electric Bike",
  EV_CHARGERS = "EV Charger",
  EVERGREEN = "Evergreen",
  SOLAR = "Rooftop Solar",
  BATTERY = "Battery",
  P_BATTERY = "Portable Battery",
  HEATING_COOLING = "Heating & Cooling",
  HP_HEATING_COOLING = "Heat Pump Heating & Cooling",
  WATER_HEATER = "Water Heater",
  HP_WATER_HEATER = "Heat Pump Water Heater",
  IC = "Induction Cooking",
  COOKTOP = "Cooktop",
  P_COOKTOP = "Portable Cooktop",
  P_I_COOKTOP = "Portable IC",
  CLOTHES_DRYER = "Clothes Dryer",
  HP_CLOTHES_DRYER = "Heat Pump Clothes Dryer",
  // Categories
  SIMPLE_LOW_COST = 'Simple/low cost',
  APPLIANCES = 'Appliances',
  TRANSPORTATION = 'Transportation',
  SOLAR_BATTERY = 'Solar & Battery'
}

export enum TechKeys {
  THERMOSTAT = "smartThermostat",
  WEATHER_STRIPPING = "weatherStripping",
  LM_AERATORS_SHOWER_HEADS = "LM_showerHeadsAerators",
  AERATORS_SHOWER_HEADS = "showerHeadsAerators",
  POWER_STRIPS = "smartPowerStrips",
  LED_BULBS = "ledCflBulbs",
  ELECTRIC_VEHICLE = "ev",
  ELECTRIC_VEHICLE_1 = "ev1",
  ELECTRIC_VEHICLE_2 = "ev2",
  ELECTRIC_BIKE = "eBike",
  EV_CHARGERS = "evChargers",
  EVERGREEN = "everGreen",
  SOLAR = "solar",
  BATTERY = "battery",
  P_BATTERY = "portableBattery",
  HEATING_COOLING = "hpsc",
  HP_HEATING_COOLING = "HP_hpsc",
  WATER_HEATER = "hpwh",
  HP_WATER_HEATER = "HP_hpwh",
  IC = "icing",
  COOKTOP = "ic",
  P_COOKTOP = "portableCooktop",
  P_I_COOKTOP = "portableIc",
  CLOTHES_DRYER = "hpd",
  HP_CLOTHES_DRYER = "HP_hpd",
  // Categories
  SIMPLE_LOW_COST = 'simpleLowCost',
  APPLIANCES = 'appliances',
  TRANSPORTATION = 'transportation',
  SOLAR_BATTERY = 'solarBattery'
}
