import { LifestyleService } from "./lifestyle.service";
import { RestAPIService } from "./rest-api.service";
import { NgModule } from "@angular/core";
import { DashboardAPIService } from "./dashboard/dashboard-api.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { OverviewService } from "../homeowner-dashboard/overview/overview.service";

@NgModule({
  providers: [
    RestAPIService,
    LifestyleService,
    MatSnackBar,
    DashboardAPIService,
    OverviewService,
  ],
})
export class RestAPIModule {}
