import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  Input,
  HostListener,
  Output,
  EventEmitter,
  OnChanges,
} from "@angular/core";
import { DashboardAPIService } from "../../../../rest-api/dashboard/dashboard-api.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { IncentivesOverlayComponent } from "../../dashboard-v2/incentives/incentives-overlay/incentives-overlay.component";

@Component({
  selector: "yt-alert-carousel",
  templateUrl: "alert-carousel.component.html",
  styleUrls: ["alert-carousel.component.scss"],
})
export class AlertCarouselComponent implements OnInit, OnChanges {
  showErrorMsgPopup = false;
  @Input() userName: string;
  @Input() showEmailAlert: boolean;
  @Input() incentivesAmount: number;
  @Input() showCategorySelectedAlert: boolean;
  @Input() showErrorAlert: boolean;
  @Input() showAlert: boolean;
  @Input() sliderAlert: boolean;
  @Input() revokedAlert: boolean;
  @Input() partialAlert: boolean;
  @Input() errorTechnologiesList: Array<string> = [];
  @Output() closeEmail: EventEmitter<void> = new EventEmitter();
  // @Output() closeGreet: EventEmitter<void> = new EventEmitter();
  @Output() closeAlert: EventEmitter<void> = new EventEmitter();
  @Output() closeOutcomeAlert: EventEmitter<void> = new EventEmitter();
  @Output() energyInfoStatus: EventEmitter<boolean> = new EventEmitter();
  @ViewChild("errorAlertOverlay", { read: ElementRef }) modal: ElementRef;
  isSingleAlert: boolean;
  alertCardsList;
  activeAlertCardIndex = 0;

  constructor(public dashboardAPI: DashboardAPIService,  private dialog: MatDialog,) {}

  @HostListener("document:keydown", ["$event"])
  onKeyDown(event) {
    if (event.key == "Escape") {
      this.offErrorAlertLearnMorePopup();
    }
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event): void {
    if (this.modal && this.showErrorMsgPopup) {
      if (event.target === this.modal.nativeElement) {
        this.offErrorAlertLearnMorePopup();
      }
    }
  }

  ngOnInit(): void {
    setInterval(() => {
      this.onNextSwiper();
    }, 8000);
  }

  onPrevSwiper(): void {
    if (this.activeAlertCardIndex < 1) {
      this.activeAlertCardIndex = this.alertCardsList.length - 1;
    } else {
      this.activeAlertCardIndex -= 1;
    }
  }

  onNextSwiper(): void {
    if (this.activeAlertCardIndex < this.alertCardsList.length - 1) {
      this.activeAlertCardIndex += 1;
    } else {
      this.activeAlertCardIndex = 0;
    }
  }

  handleDotClick(value: number): void {
    this.activeAlertCardIndex = value;
  }

  ngOnChanges(): void {
    this.alertCardsList = [
      {
        value: "congrats",
        status: this.showEmailAlert,
      },
      // {
      //   value: "categorySelectedSavings",
      //   status: this.showCategorySelectedAlert,
      // },
      // {
      //   value: "categorySelectedPersonalize",
      //   status: this.showCategorySelectedAlert,
      // },
      {
        value: "pge",
        status: this.showAlert,
      },
      {
        value: "techError",
        status: this.showErrorAlert,
      },
    ].filter((alert) => alert.status);
  }

  onCloseOfEmailAlert(): void {
    this.closeEmail.emit();
  }

  onAlertClose(): void {
    this.closeAlert.emit();
  }

  onCloseOfOutcomeErrorAlert(): void {
    this.closeOutcomeAlert.emit();
  }

  onErrorAlertLearnMorePopup(): void {
    this.showErrorMsgPopup = true;
  }

  offErrorAlertLearnMorePopup(): void {
    this.showErrorMsgPopup = false;
  }

  onEnergyUsage(): void {
    this.energyInfoStatus.emit(true);
  }

  onIncentives(): void {
    this.dashboardAPI.dashboardDetailsAnalytics("incentives");
    const dialogConfig = new MatDialogConfig();
    dialogConfig.position = { right: "0" };
    let dialogRef = this.dialog.open(IncentivesOverlayComponent, {
      width: "70%",
      height: "100%",
      autoFocus: false,
      backdropClass: "dark-backdrop",
      panelClass: "homeSetting_container",
      data: { tech: "" },
      exitAnimationDuration: 0,
    });
    dialogRef.afterClosed().subscribe(() => {});
  }
}
