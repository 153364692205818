<yt-popup-loader *ngIf="loader"></yt-popup-loader>
<div *ngIf="!loader" class="w-100 h-100 dialog-container yt__dialog_overview">
  <div class="w-100">
    <div class="header ma0">Choose your electric vehicle</div>
    <div class="flex-dropdown">
      <div class="filterBy_group">
        <div class="filerBy_text nowrap">Filter by</div>
        <mat-form-field class="filter_by" appearance="outline">
          <mat-select
            placeholder="Show All"
            [value]="filterBy"
            (selectionChange)="onFilterChange($event.value)"
          >
            <mat-option
              *ngFor="let filter of filterGroup"
              [value]="filter.value"
              >{{ filter.viewValue }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="w-50-l w-100-m w-100 flex-sortBy">
        <div class="yt_sort-by nowrap">Sort by</div>
        <mat-form-field class="sort_by" appearance="outline">
          <mat-select
            placeholder="Alphabetical"
            [value]="sortBy"
            (selectionChange)="onSortChange($event.value)"
          >
            <mat-option value="alpha">Alphabetical</mat-option>
            <mat-option value="price_lh"
              >Price After Incentives: Low to High</mat-option
            >
            <mat-option value="price_hl"
              >Price After Incentives: High to Low</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="list-container">
    <ng-container *ngFor="let type of electricVehicleModel" mat-dialog-actions>
      <button
        mat-dialog-close="{{ type.modelMake }} {{ type.modelType }}"
        class="model-button"
        cdkFocusInitial
      >
        <div class="model-name">{{ type.modelMake }}</div>
        <div class="model-type">{{ type.modelType }}</div>
        <div class="model-incentives">${{ type.afterIncentives | number }}</div>
        <div class="model-incentives__label">Price after incentives</div>
        <div class="model-row">
          <span>Electric Range</span>
          <span>{{ type.drivingRange }} miles</span>
        </div>
        <div class="model-row">
          <span>Price</span>
          <span>${{ type.msrp | number }}</span>
        </div>
      </button>
    </ng-container>
  </div>
</div>
