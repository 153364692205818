import { BehaviorSubject, Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { JWTTokenInfoModel } from "./auth/auth.model";
import { LifestyleModel } from "./homeowner-dashboard/overview/model/solarvendordetails.model";
import {
  SolarVendorModel,
  LogoModel,
  RoofTypesModel,
} from "./rest-api/rest-api.model";

@Injectable()
export class AppService {
  private vendorName: string = "YLTN";
  private SMDLink: boolean;
  private solarVendorDetails: SolarVendorModel;
  bodyElement: HTMLElement = <HTMLElement>document.querySelector("#hp_theming");
  currentTheme: string;
  newTheme: string;

  readonly vendorsList = {
    YLTN: { id: 1048459, title: "YellowTin", favIcon: "YLTN.png" },
    SUNW: { id: 1651305, title: "Sunwork", favIcon: "SUNW.png" },
    YSLR: { id: 0, title: "YellowTin", favIcon: "YLTN.png" },
    SKYT: { id: 1651311, title: "Skytech Solar", favIcon: "SKYT.png" },
    EVMA: {
      id: 2805752,
      title: "Electrify with EVmatch",
      favIcon: "EVMA.ico",
    },
    USGB: {
      id: 2805753,
      title: "Electrify with USGBC-CA",
      favIcon: "USGB.ico",
    },
    WORK: {
      id: 2856918,
      title: "Electrify with Workday",
      favIcon: "WORK.png",
    },
    WSPP: { id: 2870597, title: "Electrify with WSP", favIcon: "WSPP.ico" },
    ACTE: {
      id: 3340231,
      title: "Electrify with Acterra",
      favIcon: "ACTE.png",
    },
    EART: {
      id: 3371337,
      title: "Electrify with Earth911",
      favIcon: "EART.png",
    },
    UBER: {
      id: 3441484,
      title: "Electrify with Uber",
      favIcon: "YLTN.png",
    },
    THER: {
      id: 3454582,
      title: "Electrify with The RealReal",
      favIcon: "YLTN.png",
    },
    LIME: {
      id: 3544190,
      title: "Electrify with Lime",
      favIcon: "YLTN.png",
    },
    HUBS: {
      id: 3724841,
      title: "Electrify with HubSpot",
      favIcon: "YLTN.png",
    },
    HUMA: {
      id: 3724838,
      title: "Electrify with Humanscale",
      favIcon: "YLTN.png",
    },
    LIFE: {
      id: 3801890,
      title: "Electrify with LifePath",
      favIcon: "YLTN.png",
    },
    KYND: {
      id: 3897108,
      title: "Electrify with Kyndryl",
      favIcon: "YLTN.png",
    },
    LOWE: {
      id: 4954862,
      title: "Electrify with Lowe's",
      favIcon: "YLTN.png",
    },
  };

  updateTheme(themeClass: string): void {
    this.currentTheme = this.bodyElement.classList[0];
    this.bodyElement.classList.remove(this.currentTheme);
    this.newTheme = themeClass || "reep_theme";
    this.bodyElement.classList.add(this.newTheme);
  }

  setVendorName(name: string) {
    this.vendorName = name;
  }

  getVendorName(): string {
    return this.vendorName;
  }

  setSolarVendorDetails(data: SolarVendorModel) {
    this.solarVendorDetails = data;
  }

  getSolarVendorDetails() {
    return this.solarVendorDetails;
  }

  setSMDLink(data: boolean) {
    this.SMDLink = data;
  }

  getSMDLink() {
    return this.SMDLink;
  }

  public solarVendorModel = new Subject<SolarVendorModel>();
  public solarVendorModel$ = this.solarVendorModel.asObservable();

  public currentVendorName = new BehaviorSubject<string>("YLTN");
  public currentVendorName$ = this.currentVendorName.asObservable();

  public dashboardlogoModel = new BehaviorSubject<LogoModel>({} as LogoModel);
  public dashboardlogoModel$ = this.dashboardlogoModel.asObservable();

  public languageModel = new BehaviorSubject<RoofTypesModel[]>([]);
  public languageModel$ = this.languageModel.asObservable();

  public selectedLanguage = new BehaviorSubject<string>("en");
  public selectedLanguage$ = this.selectedLanguage.asObservable();

  public jwtToken = new Subject<JWTTokenInfoModel>();
  public jwtToken$ = this.jwtToken.asObservable();

  public address = new Subject<string>();
  public address$ = this.address.asObservable();

  public name = new BehaviorSubject<string>("");
  public name$ = this.name.asObservable();

  public lifeStyleModel = new BehaviorSubject<LifestyleModel>({
    marketplace: false,
    usage: false,
    notification: false,
  });
  public lifeStyleModel$ = this.lifeStyleModel.asObservable();
}
