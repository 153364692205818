<div id="referOverlay" #referFriendOverlay *ngIf="referFriendPopup">
  <div class="refertext yt__refer_friend">
    <div id="text">
      <div class="bg-white" [formGroup]="referFriendForm">
        <img
          src="assets/dashboard/overview/referFriend.png"
          loading="lazy"
          alt="referFriend"
          class="referFriend-img_width"
        />
        <div class="yt_heading">Refer friends</div>
        <div class="sub_text">
          <span>{{ referAFriendText }}</span>
        </div>
        <div class="yt_field">
          <mat-form-field
            hideRequiredMarker="true"
            floatLabel="auto"
            appearance="outline"
          >
            <input
              matInput
              autocomplete="off"
              placeholder="Email"
              formControlName="referEmail"
              type="email"
              [matChipInputFor]="chipGrid"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="onMailAdd($event)"
            />
            <!-- <mat-chip-list #chipList></mat-chip-list> -->
            <mat-error *ngIf="invalidEmail"> Invalid email!</mat-error>
          </mat-form-field>
          <div class="chip-alignment">
            <mat-chip-grid #chipGrid aria-label="friends mails">
              <mat-chip-row
                *ngFor="let mails of friendMails"
                (removed)="onMailRemove(mails)"
                [removable]="removable"
              >
                {{ mails.mail }}
                <mat-icon
                  class="cancel-icon"
                  [attr.aria-label]="'remove ' + mails.mail"
                  matChipRemove
                  *ngIf="removable"
                  >close</mat-icon
                >
              </mat-chip-row>
            </mat-chip-grid>
          </div>
        </div>
        <div class="dialogBox">
          <button
            mat-raised-button
            [disabled]="disableSend"
            class="yt_button"
            color="accent"
            (click)="onSubmit()"
          >
            SEND
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
