import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { EnergyChoiceTypes, EnergyChoicesModel } from "./energy-choices.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SolarComponent } from "./solar/solar.component";
import { DropDownModel, SolarVendorModel } from "../../rest-api/rest-api.model";
import { RestAPIService } from "../../rest-api/rest-api.service";
import { LifestyleService } from "../../rest-api/lifestyle.service";
import { LayoutService } from "../shared/layout/layout.service";
import { AppService } from "../../app.service";
import { LearnMoreService } from "../../homeowner-dashboard/learn-more/learn-more.service";
import { takeWhile } from "rxjs/operators";
import { DashboardModel } from "../../homeowner-dashboard/overview/model/solarvendordetails.model";

@Component({
  selector: "yt-energy-choices",
  templateUrl: "energy-choices.component.html",
  styleUrls: ["./energy-choices.component.scss"],
})
export class EnergyChoicesComponent implements OnInit, OnDestroy {
  showEV: boolean;
  showAC: boolean;
  showSPA: boolean;
  showPOOL: boolean;
  showHPWH: boolean;
  showBATTERY: boolean;
  showIC: boolean;
  showHPD: boolean;
  showHPSC: boolean;
  showSolar: boolean;
  showCommunitySolar: boolean;
  showEBike: boolean;
  lifestyleHeader: string;
  lifestyleSubHeader: string;
  onACBack: boolean;
  evVisited: string = "noPlan";
  spaVisited: string = "noPlan";
  spVisited: string = "noPlan";
  hpwhVisited: string = "noPlan";
  batteryVisited: string = "noPlan";
  icVisited: string = "noPlan";
  hpdVisited: string = "noPlan";
  hpscVisited: string = "noPlan";
  ebikeVisited: string = "noPlan";
  solarVisited: string = "noPlan";
  communitySolarVisited: string = "noPlan";
  acVisited = "noPlan";
  public alive = true;
  showPopup: boolean;
  buildingTypeRenter: boolean;
  buildingTypeCondo: boolean;
  powerGeneration: boolean = true;
  transportation: boolean;
  homeAppliances: boolean;
  poolSpa: boolean;
  isSUNW: boolean;
  appliancesForm: FormGroup;
  showICType: boolean;
  showHPDType: boolean;
  homeOwnerEmail: string;
  subheadertext: string;
  vendorLifeStyle: DashboardModel;
  steppar: boolean;
  screenWidth: number;
  disableTooltip = false;
  vendorCode: string;
  techName_bat = "Battery";
  techName_ic = "Cooktop";
  ic_image = "assets/lifestyle/cooktop.png";
  bat_image = "assets/lifestyle/battery.svg";
  haveAcPoolSpa = true;
  appliancesButtonText = "NEXT CHOICE";
  renterSteppar: boolean;
  buildingTypePopup: boolean = false;
  appliancesPopup: boolean = false;
  initScrollId: string = "1";
  applianceType: DropDownModel[];
  @ViewChild(SolarComponent) solarOverlay: SolarComponent;
  @ViewChild("userApplicableChoices", { read: ElementRef }) modal: ElementRef;
  energyChoiceTypes: EnergyChoiceTypes = {
    powerGeneration: false,
    transportation: false,
    homeAppliances: false,
    poolSpa: false,
  };

  constructor(
    private fb: FormBuilder,
    private restAPIService: RestAPIService,
    private lifeStyleService: LifestyleService,
    private layoutServiceV2: LayoutService,
    public dialog: MatDialog,
    private router: Router,
    private appService: AppService,
    private route: ActivatedRoute,
    private learnMoreService: LearnMoreService
  ) {
    this.applianceType = this.learnMoreService.typeOfGas;
    this.appliancesForm = this.fb.group({
      icType: ["Natural gas", Validators.required],
      hpdType: ["Natural gas", Validators.required],
    });
  }

  @HostListener("document:keydown", ["$event"])
  onKeyDown(event) {
    if (event.key == "Escape") {
      this.closePopup();
    }
  }
  @HostListener("document:click", ["$event"])
  onDocumentClick(event) {
    if (this.modal && this.buildingTypePopup) {
      if (event.target === this.modal.nativeElement) {
        this.closePopup();
      }
    }
  }

  ngOnInit() {
    this.homeOwnerEmail = this.restAPIService.homeOwnerEmail;
    this.screenWidth = window.innerWidth;
    this.disableTooltip = this.screenWidth < 800;
    this.vendorCode = this.appService.getVendorName();
    this.buildingTypeRenter = this.buildingTypeValue === "renter";
    this.buildingTypeCondo = this.buildingTypeValue === "condominium";
    this.layoutServiceV2.hideSidenavSubject.next(false);
    this.restAPIService.setVendorName(this.vendorCode);
    this.restAPIService
      .getSolarVendorDetails(this.vendorCode)
      .pipe(takeWhile(() => this.alive))
      .subscribe((data: SolarVendorModel) => {
        this.vendorLifeStyle = data.dashboard;
      });
    this.isSUNW = this.vendorCode === "SUNW";
    if (this.lifeStyleService.getEnergyChoices() === undefined) {
      this.energyChoiceTypes.powerGeneration = !this.isSUNW;
      this.energyChoiceTypes.transportation = this.isSUNW;
      this.initScrollId = this.isSUNW ? "2" : "1";
      this.lifeStyleService.setEnergyChoices(this.energyChoiceTypes);
    } else {
      this.energyChoiceTypes = this.lifeStyleService.getEnergyChoices();
      this.checkVisitedInfo();
      let vendorType =
        this.restAPIService.getSolarVendorInformation().solutionType;
      let number = vendorType === "vendor" ? 3 : 2;
      this.restAPIService.setDestination(number);
    }
    setTimeout(() => {
      const scroller = document.getElementById(this.initScrollId);
      if (scroller)
        scroller.addEventListener("click", () =>
          this.restAPIService.scrollIntoElement(this.initScrollId)
        );
    }, 100);
    this.steppar = this.isSUNW;
    if (this.buildingTypeRenter || this.buildingTypeCondo) {
      this.checkRenterCondo();
    }
  }

  checkRenterCondo(): void {
    if (this.lifeStyleService.isRenterCondoUser) {
      this.haveAcPoolSpa = false;
      this.renterSteppar = true;
      this.appliancesButtonText = "CONTINUE";
      this.techName_bat = "Portable Battery";
      this.techName_ic = "Portable Cooktop";
      this.bat_image = "assets/lifestyle/portable-battery.png";
      this.ic_image = "assets/lifestyle/portable-cooktop.png";
      this.showPopup = false;
    } else {
      this.haveAcPoolSpa = true;
      this.renterSteppar = false;
      this.appliancesButtonText = "NEXT CHOICE";
      this.techName_bat = "Battery";
      this.techName_ic = "Cooktop";
      this.bat_image = "assets/lifestyle/battery.svg";
      this.ic_image = "assets/lifestyle/cooktop.png";
      this.showPopup = true;
    }
  }

  onResize() {
    this.screenWidth = window.innerWidth;
    this.disableTooltip = this.screenWidth < 800 ? true : false;
  }

  get buildingTypeValue(): string {
    return this.restAPIService.getBuildingType();
  }

  openPopup() {
    this.buildingTypePopup = true;
  }

  closePopup() {
    this.buildingTypePopup = false;
  }

  offPopup() {
    if (this.showICType && this.showHPDType) {
      const userInfo: {
        energyChoices: {
          ic: {
            own: string;
            currentRange: string;
          }[];
          hpd: {
            own: string;
            currentDryer: string;
          }[];
        };
      } = {
        energyChoices: {
          ic: [],
          hpd: [],
        },
      };
      userInfo.energyChoices.ic[0] = {
        currentRange: this.appliancesForm.get("icType")?.value,
        own: "noPlan",
      };
      userInfo.energyChoices.hpd[0] = {
        currentDryer: this.appliancesForm.get("hpdType")?.value,
        own: "noPlan",
      };
      this.lifeStyleService.postICInfo(userInfo);
      this.lifeStyleService.postHPDInfo(userInfo);
      this.savePopupData(userInfo);
    } else if (this.showICType) {
      const userInfo: {
        energyChoices: {
          ic: {
            own: string;
            currentRange: string;
          }[];
        };
      } = {
        energyChoices: {
          ic: [],
        },
      };
      userInfo.energyChoices.ic[0] = {
        currentRange: this.appliancesForm.get("icType")?.value,
        own: "noPlan",
      };
      this.lifeStyleService.postICInfo(userInfo);
      this.savePopupData(userInfo);
    } else if (this.showHPDType) {
      const userInfo: {
        energyChoices: {
          hpd: {
            own: string;
            currentDryer: string;
          }[];
        };
      } = {
        energyChoices: {
          hpd: [],
        },
      };
      userInfo.energyChoices.hpd[0] = {
        currentDryer: this.appliancesForm.get("hpdType")?.value,
        own: "noPlan",
      };
      this.lifeStyleService.postHPDInfo(userInfo);
      this.savePopupData(userInfo);
    }
  }

  savePopupData(userInfo) {
    this.restAPIService
      .onSavePopupData(userInfo)
      .subscribe((response: EnergyChoicesModel) => {
        this.appliancesPopup = false;
        this.energyChoiceTypes.poolSpa
          ? this.onContinue()
          : this.onNextAppliances();
      });
  }

  checKPopupHPWHStatus() {
    let energyChoices = this.lifeStyleService.getEnergyChoicesInfo();
    if (
      energyChoices.hpwh !== undefined &&
      energyChoices.hpwh[0].own === "yesPlan" &&
      energyChoices.hpwh[0].currentHeater === "Natural gas" &&
      (energyChoices.ic === undefined || energyChoices.hpd === undefined)
    ) {
      this.showICType = energyChoices.ic === undefined ? true : false;
      this.showHPDType = energyChoices.hpd === undefined ? true : false;
      this.onPopup();
    } else {
      this.onContinue();
    }
  }

  onContinue() {
    let vendorCode = this.restAPIService.solarVendorInformation.solutionType;
    let disableAdditionalInfo;
    let hidePoweredBy: boolean = true;
    let menuNumber: string;
    if (
      vendorCode === "broader" ||
      vendorCode === "cce" ||
      vendorCode === undefined
    ) {
      disableAdditionalInfo = true;
    } else {
      disableAdditionalInfo = false;
    }
    hidePoweredBy =
      vendorCode === "broader" || vendorCode === undefined ? true : false;
    menuNumber = disableAdditionalInfo ? "3" : "5";
    this.layoutServiceV2.navRouterSubject.next({
      personalScreen: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "1",
      },
      roofTop: {
        showLink: !disableAdditionalInfo,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "2",
      },
      lifeStyle: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: !disableAdditionalInfo ? "3" : "2",
      },
      additionalinfo: {
        showLink: !disableAdditionalInfo,
        activatedLink: !disableAdditionalInfo,
        disabledLink: false,
        menuNumber: "4",
      },
      upgrades: {
        showLink: false,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "3",
      },
      energyUsage: {
        showLink: true,
        activatedLink: disableAdditionalInfo,
        disabledLink: !disableAdditionalInfo,
        menuNumber: menuNumber,
      },
      overview: {
        showLink: false,
        activatedLink: false,
        disabledLink: true,
        menuNumber: "",
      },
      logo1: {
        showLink: true,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      logo2: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      usermenu: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      logout: {
        showLink: false,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
      poweredbyYt: {
        showLink: !hidePoweredBy,
        activatedLink: false,
        disabledLink: false,
        menuNumber: "",
      },
    });
    let userInfo = {
      energyChoices: {
        onContinue: true,
      },
    };
    this.restAPIService
      .onSavePopupData(userInfo)
      .subscribe((response: EnergyChoicesModel) => {
        let vendorCode =
          this.restAPIService.solarVendorInformation.solutionType;
        if (
          vendorCode === "cce" ||
          vendorCode === "broader" ||
          vendorCode === undefined
        ) {
          window.scrollTo(0, -500);
          this.router.navigate(["energy-info"], {
            relativeTo: this.route.parent,
          });
        } else {
          this.router.navigate(["additional-info"], {
            relativeTo: this.route.parent,
          });
        }
      });
  }

  onPowerGeneration() {
    this.energyChoiceTypes.transportation = true;
    this.lifeStyleService.setEnergyChoices(this.energyChoiceTypes);
    this.powerGeneration = false;
  }

  onTransportation() {
    this.energyChoiceTypes.homeAppliances = true;
    this.lifeStyleService.setEnergyChoices(this.energyChoiceTypes);
    this.transportation = false;
    if (!this.haveAcPoolSpa) {
      this.appliancesButtonText = "CONTINUE";
    }
  }

  onAppliances() {
    let energyChoices = this.lifeStyleService.getEnergyChoicesInfo();
    if (!this.vendorLifeStyle.hpwhTab && !this.vendorLifeStyle.spaTab) {
      this.onContinue();
    } else if (
      energyChoices.hpwh !== undefined &&
      energyChoices.hpwh[0].own === "yesPlan" &&
      energyChoices.hpwh[0].currentHeater === "Natural gas" &&
      (energyChoices.ic === undefined || energyChoices.hpd === undefined)
    ) {
      this.showICType = energyChoices.ic === undefined ? true : false;
      this.showHPDType = energyChoices.hpd === undefined ? true : false;
      this.onPopup();
    } else {
      this.onNextAppliances();
    }
  }

  onPopup() {
    this.appliancesPopup = true;
  }

  onNextAppliances() {
    this.energyChoiceTypes.poolSpa = true;
    this.lifeStyleService.setEnergyChoices(this.energyChoiceTypes);
    this.homeAppliances = false;
    if (this.energyChoiceTypes.poolSpa) {
      let scroll = document.getElementById("final-continue");
      if (scroll !== null) {
        scroll.scrollIntoView({});
        scroll = null;
      }
    }
  }

  onSolar() {
    this.lifestyleHeader = "";
    this.lifestyleSubHeader = "";
    this.closeAllLifestyle();
    this.showSolar = true;
    this.onACBack = false;
    this.solarOverlay.ngOnInit();
  }

  onCommunitySolar() {
    this.lifestyleHeader = "";
    this.lifestyleSubHeader = "";
    this.closeAllLifestyle();
    this.showCommunitySolar = true;
    this.onACBack = false;
  }

  onEV() {
    this.lifestyleHeader = "Electric Vehicle";
    this.lifestyleSubHeader =
      "This information will help us size your system properly.";
    this.closeAllLifestyle();
    this.showEV = true;
    this.onACBack = false;
  }

  onAC() {
    this.lifestyleHeader = "Air Conditioner";
    this.lifestyleSubHeader =
      "This information will help us size your system properly.";
    this.closeAllLifestyle();
    this.onACBack = false;
    this.showAC = true;
  }

  onSPA() {
    this.lifestyleHeader = "Spa";
    this.lifestyleSubHeader =
      "This information will help us size your system properly.";
    this.closeAllLifestyle();
    this.showSPA = true;
    this.onACBack = false;
  }

  onSP() {
    this.lifestyleHeader = "Swimming Pool";
    this.lifestyleSubHeader =
      "This information will help us size your system properly.";
    this.closeAllLifestyle();
    this.showPOOL = true;
    this.onACBack = false;
  }

  onHPWH() {
    this.lifestyleHeader = "Water Heater";
    this.lifestyleSubHeader =
      "This information will help us size your system properly.";
    this.closeAllLifestyle();
    this.showHPWH = true;
    this.onACBack = false;
  }

  onBATTERY() {
    this.lifestyleHeader = this.techName_bat;
    this.lifestyleSubHeader =
      "This information will help us size your system properly.";
    this.closeAllLifestyle();
    this.showBATTERY = true;
    this.onACBack = false;
  }

  onIC() {
    this.lifestyleHeader = this.techName_ic;
    this.lifestyleSubHeader =
      "This information will help us size your system properly.";
    this.closeAllLifestyle();
    this.showIC = true;
    this.onACBack = false;
  }

  onHPD() {
    this.lifestyleHeader = "Clothes Dryer";
    this.lifestyleSubHeader =
      "This information will help us size your system properly.";
    this.closeAllLifestyle();
    this.showHPD = true;
    this.onACBack = false;
  }

  onHPSC() {
    this.lifestyleHeader = "Heating & Cooling";
    this.lifestyleSubHeader =
      "This information will help us size your system properly.";
    this.closeAllLifestyle();
    this.showHPSC = true;
    this.onACBack = false;
  }

  onEBike() {
    this.lifestyleHeader = "Electric Bike";
    this.lifestyleSubHeader = "";
    this.closeAllLifestyle();
    this.showEBike = true;
    this.onACBack = false;
  }

  onSaveSolar() {
    this.solarVisited =
      this.lifeStyleService.getEnergyChoicesInfo().solar![0].own;
    this.closeNav();
  }

  onSaveCommunitySolar() {
    this.communitySolarVisited =
      this.lifeStyleService.getEnergyChoicesInfo().communitySolar![0].own;
    this.closeNav();
  }

  onSaveEv() {
    let ev = this.lifeStyleService.getEnergyChoicesInfo().ev;
    if (ev && ev.length > 1) {
      if (ev[0].own === "yes" && ev[1].own === "yesPlan") {
        this.evVisited = "yesPlan";
      } else if (ev[0].own === "no" && ev[1].own === "yesPlan") {
        this.evVisited = "yesPlan";
      } else if (ev[0].own === "no" && ev[1].own === "yes") {
        this.evVisited = "yes";
      } else {
        this.evVisited =
          this.lifeStyleService.getEnergyChoicesInfo().ev![0].own;
      }
    } else {
      this.evVisited = this.lifeStyleService.getEnergyChoicesInfo().ev![0].own;
    }
    this.closeNav();
  }

  onSaveSPA() {
    this.spaVisited = this.lifeStyleService.getEnergyChoicesInfo().spa![0].own;
    this.closeNav();
  }

  onSavePOOL() {
    this.spVisited = this.lifeStyleService.getEnergyChoicesInfo().pool![0].own;
    this.closeNav();
  }

  onSaveBATTERY() {
    this.batteryVisited =
      this.lifeStyleService.getEnergyChoicesInfo().battery![0].own;
    this.closeNav();
  }

  onSaveHPWH() {
    this.hpwhVisited =
      this.lifeStyleService.getEnergyChoicesInfo().hpwh![0].own;
    this.closeNav();
  }

  onSaveIC() {
    this.icVisited = this.lifeStyleService.getEnergyChoicesInfo().ic![0].own;
    this.closeNav();
  }

  onSaveHPD() {
    this.hpdVisited = this.lifeStyleService.getEnergyChoicesInfo().hpd![0].own;
    this.closeNav();
  }

  onSaveHPSC() {
    this.hpscVisited =
      this.lifeStyleService.getEnergyChoicesInfo().hpsc![0].own;
    this.closeNav();
  }

  onSaveEBike() {
    this.ebikeVisited =
      this.lifeStyleService.getEnergyChoicesInfo().eBike![0].own;
    this.closeNav();
  }

  onSaveAC() {
    this.acVisited = this.lifeStyleService.getEnergyChoicesInfo().ac![0].own;
    this.closeNav();
  }

  closeNav() {
    const navElement = document.getElementById("myNav");
    if (navElement) navElement.style.width = "0%";
  }

  closeAllLifestyle(): void {
    this.showEV =
      this.showAC =
      this.showSPA =
      this.showPOOL =
      this.showBATTERY =
      this.showHPWH =
      this.showIC =
      this.showHPD =
      this.showHPSC =
      this.showEBike =
      this.showSolar =
      this.showCommunitySolar =
        false;
    const navElement = document.getElementById("myNav");
    if (navElement) navElement.style.width = "100%";
  }

  onEnergyChoiceOwnStatus() {
    let ec = this.lifeStyleService.getEnergyChoicesInfo();
    this.solarVisited = ec.solar![0].own;
    this.communitySolarVisited = ec.communitySolar![0].own;
    this.evVisited = ec.ev![0].own;
    this.batteryVisited = ec.battery![0].own;
    this.hpdVisited = ec.hpd![0].own;
    this.hpscVisited = ec.hpsc![0].own;
    this.hpwhVisited = ec.hpwh![0].own;
    this.icVisited = ec.ic![0].own;
    this.spaVisited = ec.spa![0].own;
    this.spVisited = ec.pool![0].own;
    this.ebikeVisited = ec.eBike![0].own;
  }

  checkVisitedInfo() {
    if (this.lifeStyleService.userProvidedSolarInfo === undefined) {
      this.solarVisited = "noPlan";
    } else {
      this.solarVisited = this.lifeStyleService.userProvidedSolarInfo[0].own;
    }
    if (this.lifeStyleService.userProvidedCommunitySolarInfo === undefined) {
      this.communitySolarVisited = "noPlan";
    } else {
      this.communitySolarVisited =
        this.lifeStyleService.userProvidedCommunitySolarInfo[0].own;
    }
    if (this.lifeStyleService.userProvidedBatteryInfo === undefined) {
      this.batteryVisited = "noPlan";
    } else {
      this.batteryVisited =
        this.lifeStyleService.userProvidedBatteryInfo[0].own;
    }
    if (this.lifeStyleService.userProvidedEVInfo === undefined) {
      this.evVisited = "noPlan";
    } else {
      let ev = this.lifeStyleService.getEnergyChoicesInfo().ev;
      if (ev && ev.length > 1) {
        if (ev[0].own === "yes" && ev[1].own === "yesPlan") {
          this.evVisited = "yesPlan";
        } else if (ev[0].own === "no" && ev[1].own === "yesPlan") {
          this.evVisited = "yesPlan";
        } else if (ev[0].own === "no" && ev[1].own === "yes") {
          this.evVisited = "yes";
        } else {
          this.evVisited =
            this.lifeStyleService.getEnergyChoicesInfo().ev![0].own;
        }
      } else {
        this.evVisited =
          this.lifeStyleService.getEnergyChoicesInfo().ev![0].own;
      }
    }
    if (this.lifeStyleService.userProvidedHPWHInfo === undefined) {
      this.hpwhVisited = "noPlan";
    } else {
      this.hpwhVisited = this.lifeStyleService.userProvidedHPWHInfo[0].own;
    }
    if (this.lifeStyleService.userProvidedHPSCInfo === undefined) {
      this.hpscVisited = "noPlan";
    } else {
      this.hpscVisited = this.lifeStyleService.userProvidedHPSCInfo[0].own;
    }
    if (this.lifeStyleService.userProvidedICInfo === undefined) {
      this.icVisited = "noPlan";
    } else {
      this.icVisited = this.lifeStyleService.userProvidedICInfo[0].own;
    }
    if (this.lifeStyleService.userProvidedHPDInfo === undefined) {
      this.hpdVisited = "noPlan";
    } else {
      this.hpdVisited = this.lifeStyleService.userProvidedHPDInfo[0].own;
    }
    if (this.lifeStyleService.userProvidedSpaInfo === undefined) {
      this.spaVisited = "noPlan";
    } else {
      this.spaVisited = this.lifeStyleService.userProvidedSpaInfo[0].own;
    }
    if (this.lifeStyleService.userProvidedPoolInfo === undefined) {
      this.spVisited = "noPlan";
    } else {
      this.spVisited = this.lifeStyleService.userProvidedPoolInfo[0].own;
    }
    if (this.lifeStyleService.userProvidedEBikeInfo === undefined) {
      this.ebikeVisited = "noPlan";
    } else {
      this.ebikeVisited = this.lifeStyleService.userProvidedEBikeInfo[0].own;
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
