<yt-popup-loader *ngIf="loader"></yt-popup-loader>
<div class="overlay_top_bg">
  <div class="pa4">
    <mat-icon class="mat-icon" (click)="onClose()">close</mat-icon>
    <div class="header">
      {{ "homeOwenerDashboard.settingsOverlay.header" | translate }}
    </div>
    <div class="subHeader">
      {{ "homeOwenerDashboard.settingsOverlay.subHeader" | translate }}
    </div>
  </div>
</div>

<div class="tabs-group" (window:resize)="onResize()">
  <div class="headers-wrapper">
    <div
      class="prev-button"
      (click)="handleScroll('prev')"
      *ngIf="showScrollButton"
      [ngClass]="{ 'disable-button': isButtonDisabled('prev') }"
    >
      <mat-icon>chevron_left</mat-icon>
    </div>
    <div class="tab-headers">
      <div *ngFor="let tabsList of homeSettingTabsList; index as i">
        <div
          class="tab-header"
          [ngClass]="{ 'active-header': i === tabIndex }"
          (click)="tabChanged(tabsList.value, i)"
        >
          <img [src]="tabsList.image" width="33px" height="35px" />
          <div class="tab-label">{{ tabsList.viewValue }}</div>
        </div>
      </div>
    </div>
    <div
      class="next-button"
      (click)="handleScroll('next')"
      *ngIf="showScrollButton"
      [ngClass]="{ 'disable-button': isButtonDisabled('next') }"
    >
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
  <div class="content-wrapper">
    <div *ngIf="checkTabStatus('general')">
      <yt-general *ngIf="haveResponse"></yt-general>
    </div>

    <div *ngIf="checkTabStatus('simpleLowCost')">
      <yt-simpleLow-cost
        [activeTech]="activeTech"
        [activeIcon]="activeIcon"
      ></yt-simpleLow-cost>
    </div>

    <div *ngIf="checkTabStatus('transportation')">
      <yt-transportation
        *ngIf="transportationTechDetails"
        [transportationTechDetails]="transportationTechDetails"
        [transportationJSONDetails]="transportationJSONDetails"
        [activeTech]="activeTech"
        [activeIcon]="activeIcon"
        (techSave)="emitTech($event)"
      ></yt-transportation>
    </div>

    <div *ngIf="checkTabStatus('solarBattery')">
      <yt-solar-battery
        *ngIf="solarBatteryTechDetails"
        [solarBatteryTechDetails]="solarBatteryTechDetails"
        [solarBatteryJSONDetails]="solarBatteryJSONDetails"
        [activeTech]="activeTech"
        [activeIcon]="activeIcon"
        (techSave)="emitTech($event)"
      ></yt-solar-battery>
    </div>

    <div *ngIf="checkTabStatus('appliances')">
      <yt-appliances
        *ngIf="appliancesTechDetails"
        [appliancesTechDetails]="appliancesTechDetails"
        [appliancesJSONDetails]="appliancesJSONDetails"
        [activeTech]="activeTech"
        [activeIcon]="activeIcon"
        (techSave)="emitTech($event)"
      ></yt-appliances>
    </div>
  </div>
</div>

<!--  Confirmation Popup -->
<yt-confirm-popup
  *ngIf="settingStatus"
  header="Confirm Changes"
  subheader="Do you want to save your changes in settings?"
  primaryButton="SAVE"
  secondaryButton="CANCEL"
  (selectedNo)="onSelectedNo()"
  (selectedYes)="onSelectedYes()"
></yt-confirm-popup>

<!-- loader -->
<yt-loader
  [blackLoader]="true"
  *ngIf="showSavingLoader"
  top="0"
  [relative]="true"
></yt-loader>