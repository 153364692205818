<router-outlet></router-outlet>
<div class="page-container main_container">
  <div class="padding-class w-100 center">
    <div class="yt_maxWidth">
      <div class="header">What is Electrification?</div>
      <div class="paragraph">
        Electrification refers to the process of replacing technologies that use
        fossil fuels (especially natural gas and oil) with technologies that use
        electricity as a source of energy. Depending on the resources used to
        generate electricity, electrification can substantially reduce
        greenhouse gas emissions from the transportation, building and
        industrial sectors, which account for over half of all emissions in much
        of the world. Addressing emissions from these sectors is critical to
        decarbonizing the economy and, ultimately, mitigating the impacts of
        climate change.
      </div>
      <div id="imageMapping" class="image-container">
        <iframe class="w-100 image-container__mapping" frameborder="0"></iframe>
      </div>
      <div class="disclaimerNote">
        Note: Please rotate the device until it is horizontal to view the screen
        in landscape mode for optimal experience.
      </div>
      <div>
        <div class="header">So, Why electrify?</div>
        <div class="paragraph">
          Electrification replaces fossil fuels with cleaner, lower-carbon
          electricity and it’s one of the key ways that we can fight climate
          change. It’s also a way that we can cut our energy bills, especially
          if we combine efficient electric options such as EVs and heat pumps
          with rooftop solar.
        </div>
      </div>
      <div *ngIf="!dashboardStatus">
        <div class="signup_button">
          <div class="signup_header">Sign up to learn more</div>
          <div>
            <button mat-raised-button color="accent" (click)="onGetStarted()">
              GET STARTED
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- loader -->
<yt-loader *ngIf="loader"></yt-loader>
