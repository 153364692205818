import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { HPWHSettingModel } from "../energy-choices.model";
import { LifestyleService } from "../../../rest-api/lifestyle.service";
import { LearnMoreService } from "../../../homeowner-dashboard/learn-more/learn-more.service";
import { Utils } from "../../../utils/util";
import { DropDownModel } from "../../../rest-api/rest-api.model";

@Component({
  selector: "yt-hpwh",
  templateUrl: "hpwh.component.html",
  styleUrls: ["../energy-choices-common.scss"],
})
export class HPWHComponent implements OnInit {
  panelOpenState: boolean = false;
  hpwhForm: FormGroup;
  monthList: string[];
  updateMode: boolean;
  hpwhData: HPWHSettingModel[];
  @Output() saveHPWH = new EventEmitter<any>();
  disableDatePicker: boolean;
  lastTwelthMonth: string;
  hpwhTypes: DropDownModel[];

  constructor(
    private fb: FormBuilder,
    private lifeStyleService: LifestyleService,
    private utils: Utils,
    private learnMoreService: LearnMoreService
  ) {
    this.hpwhTypes = this.learnMoreService.energyChoiceType;
    let countofPeople = this.lifeStyleService.peopleCount;
    this.hpwhForm = this.fb.group({
      own: ["yesPlan", Validators.required],
      howManyPeople: [
        countofPeople,
        [
          Validators.required,
          Validators.pattern("^(1[0-2]|[1-9])$"),
          Validators.min(1),
          Validators.max(10),
        ],
      ],
      currentHeater: ["Natural gas", Validators.required],
      age: [
        "5",
        [
          Validators.required,
          Validators.pattern(/^(?:[0-9]|0[1-9]|1[0-9]|2[0-9]|30)$/),
          Validators.min(0),
          Validators.max(30),
        ],
      ],
      bought: [""],
      datePurchased: ["", Validators.required],
    });
  }

  ngOnInit() {
    this.monthList = this.utils.monthList;
    this.lastTwelthMonth = this.utils.lastTwelthMonth;
    if (this.hpwhForm.get("own")?.value === "yes") {
      this.hpwhForm.get("currentHeater")?.disable();
      this.hpwhForm.get("age")?.disable();
    }
    if (this.lifeStyleService.getHpwhInfo() !== undefined) {
      this.hpwhData = this.lifeStyleService.getHpwhInfo();
      this.checkInUpdateView(this.hpwhData[0]);
    }
    if (this.hpwhForm.get("own")?.value !== "yes") {
      this.hpwhForm.get("datePurchased")?.disable();
    }
    this.hpwhForm.get("howManyPeople")?.valueChanges.subscribe(() => {
      this.lifeStyleService.setPeopleCount(
        this.hpwhForm.get("howManyPeople")?.value
      );
    });
  }

  isDatePurchasedEmpty() {
    if (this.hpwhForm.get("own")?.value === "yes") {
      if (!this.hpwhForm.get("datePurchased")?.value) {
        return true;
      }
    }
    return false;
  }

  condition() {
    if (this.hpwhForm.get("own")?.value === "yes") {
      this.hpwhForm.get("howManyPeople")?.enable();
      this.hpwhForm.get("currentHeater")?.disable();
      this.hpwhForm.get("age")?.disable();
      this.hpwhForm.get("bought")?.enable();
      this.hpwhForm.get("datePurchased")?.enable();
      this.disableDatePicker = true;
    } else if (this.hpwhForm.get("own")?.value === "yesPlan") {
      this.hpwhForm.get("howManyPeople")?.enable();
      this.hpwhForm.get("currentHeater")?.enable();
      this.hpwhForm.get("age")?.enable();
      this.hpwhForm.get("bought")?.enable();
      this.hpwhForm.get("datePurchased")?.disable();
      this.disableDatePicker = false;
    } else if (this.hpwhForm.get("own")?.value === "no") {
      this.hpwhForm.get("howManyPeople")?.disable();
      this.hpwhForm.get("currentHeater")?.disable();
      this.hpwhForm.get("age")?.disable();
      this.hpwhForm.get("bought")?.disable();
      this.hpwhForm.get("datePurchased")?.disable();
      this.disableDatePicker = false;
    }
  }

  checkInUpdateView(data: HPWHSettingModel) {
    this.updateMode = true;
    this.hpwhForm.controls["own"].setValue(data.own);
    data.age
      ? this.hpwhForm.controls["age"].setValue(data.age)
      : this.hpwhForm.get("age")?.disable();
    data.age
      ? this.hpwhForm.get("age")?.enable()
      : this.hpwhForm.get("age")?.disable();
    this.hpwhForm.get("howManyPeople")?.value
      ? this.hpwhForm.controls["howManyPeople"].setValue(
          this.hpwhForm.get("howManyPeople")?.value
        )
      : this.hpwhForm.get("howManyPeople")?.disable();
    if (data.currentHeater) {
      this.hpwhForm.controls["currentHeater"].setValue(data.currentHeater);
      this.hpwhForm.get("currentHeater")?.enable();
    } else {
      this.hpwhForm.get("own")?.value === "yesPlan"
        ? this.hpwhForm.get("currentHeater")?.enable()
        : this.hpwhForm.get("currentHeater")?.disable();
    }
    if (data.bought) {
      this.hpwhForm.controls["bought"].setValue(data.bought);
      this.hpwhForm.controls["datePurchased"].setValue(data.datePurchased);
    } else {
      data.own === "yes"
        ? this.hpwhForm.controls["datePurchased"].setValue(this.lastTwelthMonth)
        : this.hpwhForm.get("datePurchased")?.disable();
    }
    this.disableDatePicker = data.own === "yes";
  }

  onSaveHPWH() {
    const hpwhInfo: {
      energyChoices: {
        howManyPeople: string;
        hpwh: {
          own: string;
          bought?: string;
          datePurchased?: string;
          currentHeater?: string;
          age?: string;
        }[];
      };
    } = {
      energyChoices: {
        howManyPeople: this.hpwhForm.get("howManyPeople")?.value,
        hpwh: [],
      },
    };
    if (this.hpwhForm.get("own")?.value === "yes") {
      const datePurchased = this.hpwhForm.get("datePurchased")?.value;
      const bought = this.utils.isDatePurchaseSelected(datePurchased);
      this.hpwhForm.get("bought")?.setValue(bought);
      hpwhInfo.energyChoices.hpwh[0] = {
        own: this.hpwhForm.get("own")?.value,
        bought: this.hpwhForm.get("bought")?.value,
        datePurchased: !this.hpwhForm.get("bought")?.value
          ? null
          : this.hpwhForm.get("datePurchased")?.value,
      };
    } else if (this.hpwhForm.get("own")?.value === "yesPlan") {
      if (this.hpwhForm.get("currentHeater")?.value === "") {
        this.hpwhForm.get("currentHeater")?.reset();
      }
      hpwhInfo.energyChoices.hpwh[0] = {
        own: this.hpwhForm.get("own")?.value,
        currentHeater: this.hpwhForm.get("currentHeater")?.value,
        age: this.hpwhForm.get("age")?.value,
      };
    } else if (this.hpwhForm.get("own")?.value === "no") {
      hpwhInfo.energyChoices.hpwh[0] = {
        own: this.hpwhForm.get("own")?.value,
      };
    }
    this.lifeStyleService
      .onSaveLifestyleInfo(hpwhInfo)
      .subscribe((response) => {
        if (response.status === 200) {
          this.lifeStyleService.postHpwhInfo(hpwhInfo);
          this.saveHPWH.emit();
        }
      });
  }

  get datePurchased(): FormControl {
    return this.hpwhForm.controls["datePurchased"] as FormControl;
  }
}
