<div class="w-100 margin__bottom energy-choices" [formGroup]="spaForm">
  <div class="yt_p_top">
    <mat-expansion-panel
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
      [expanded]="true"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="yt__panel-header">Your Choices</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="cf">
        <div class="w-100">
          <div
            class="w-100 w-50-l fl radio-buttons"
            [ngClass]="{ 'yt_border-right': !disableDatePicker }"
          >
            <mat-radio-group
              class="example-radio-group"
              (change)="condition()"
              formControlName="own"
            >
              <div class="yt-Btn-Font">Tell us about your spa</div>
              <div class="radio_text">
                <div class="dib yt_ques-padding">
                  <mat-radio-button value="yes">
                    <span class="yt_option">Already have one</span>
                  </mat-radio-button>
                </div>
                <div class="dib yt_answer-padding-2">
                  <mat-radio-button value="yesPlan">
                    <span class="yt_option">Interested in getting one</span>
                  </mat-radio-button>
                </div>
                <div class="dib yt_answer-padding-2">
                  <mat-radio-button value="no">
                    <span class="yt_option">Not interested</span>
                  </mat-radio-button>
                </div>
              </div>
            </mat-radio-group>
          </div>
          <div
            class="w-100 w-100-m w-50-l fl yt_paddingtop-42 yt_pd-left"
            [ngClass]="{ 'yt_border-left-pool': !!disableDatePicker }"
          >
            <div>
              <div
                class="yt__f4 yt_pb-7"
                [ngStyle]="{
                  color:
                    spaForm.controls['own'].value === 'no'
                      ? '#c7c7c7'
                      : '#333333'
                }"
              >
                Total hours used in a year
              </div>
              <div class="yt__pt0-7">
                <mat-form-field
                  appearance="outline"
                  floatLabel="auto"
                  [ngClass]="{
                    'disable-field': spaForm.controls['own'].value === 'no'
                  }"
                >
                  <input
                    matInput
                    type="text"
                    class="quantity"
                    placeholder="Enter the number"
                    formControlName="hrs"
                    maxlength="5"
                  />
                  <mat-error
                    *ngIf="
                      spaForm.controls['hrs'].hasError('pattern') ||
                      spaForm.controls['hrs'].hasError('min') ||
                      spaForm.controls['hrs'].hasError('max')
                    "
                  >
                    Please enter valid numeric value
                  </mat-error>
                  <mat-error
                    *ngIf="spaForm.controls['hrs'].hasError('required')"
                  >
                    Required!
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="disableDatePicker">
              <article class="cf">
                <div
                  class="fl w-60-c yt__f4 yt_pt1-75"
                  [ngStyle]="{
                    color:
                      spaForm.controls['own'].value === 'no' ||
                      spaForm.controls['own'].value === 'yesPlan'
                        ? '#c7c7c7'
                        : '#333333'
                  }"
                >
                  Purchased month & year
                </div>
              </article>
              <div class="yt__pb-20 yt__pt0-7">
                <mat-form-field
                  appearance="outline"
                  floatLabel="auto"
                  [ngClass]="{
                    'disable-field':
                      spaForm.controls['own'].value === 'no' ||
                      spaForm.controls['own'].value === 'yesPlan'
                  }"
                >
                  <mat-select
                    matInput
                    placeholder="Select"
                    formControlName="datePurchased"
                  >
                    <mat-option
                      *ngFor="let month of monthList"
                      [value]="month"
                      >{{ month | titlecase }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <div
                  class="cb w-100 yt_error-message"
                  *ngIf="
                    isDatePurchasedEmpty() &&
                    spaForm.controls['datePurchased'].touched
                  "
                >
                  Required!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
  <div class="pt_30 w-100">
    <button
      mat-raised-button
      color="accent"
      (click)="onSaveSPA()"
      [disabled]="spaForm.invalid || isDatePurchasedEmpty()"
      class="fr w-100 yt__mb-12"
    >
      SAVE
    </button>
  </div>
</div>
