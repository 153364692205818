import { Component, Input, OnInit } from "@angular/core";
import { DashboardAPIService } from "../../../../../../rest-api/dashboard/dashboard-api.service";
import { Router } from "@angular/router";
import { MarketPlaceAnalyticsModel } from "../../../../../../rest-api/dashboard/dashboard-api.model";
import { MarketPlaceService } from "src/app/homeowner-dashboard/market-place/market-place.service";
@Component({
  selector: "info-card-appliances",
  templateUrl: "./appliances.component.html",
  styleUrls: ["../info-card.component.scss"],
})
export class InfoCardAppliancesComponent implements OnInit {
  imagePath = "assets/lifestyle/clean-choices/details/";
  isLowe: boolean;
  @Input() activeTech: string;
  @Input() section: string;

  constructor(
    private dashboardAPI: DashboardAPIService,
    private router: Router,
    private marketplaceService: MarketPlaceService
  ) {}

  ngOnInit(): void {
    this.isLowe = this.dashboardAPI.getUserDetails().solarVendorCode === "LOWE";
  }

  onStartShopping(tech_name: string, linkId: string, link: string): void {
    const techList = ["electricKettle", "instantPot"];
    const yardTechList = [
      "electricLawnMower",
      "electricGrassTrimmer",
      "electricLeafBlower",
    ];
    if (this.isLowe) {
      this.marketplaceService.openShopNowTab(link);
    } else if (tech_name === "smartWaterMonitor") {
      this.router.navigateByUrl(
        `dashboard/market-place/energyEfficientDevices/waterSavingDevices`
      );
    } else {
      if (tech_name === "electricFireplace") {
        this.router.navigateByUrl(
          `dashboard/market-place/homeAppliances/${tech_name}`
        );
      } else if (techList.includes(tech_name)) {
        this.router.navigateByUrl(
          `dashboard/market-place/energyEfficientDevices/kitchenAppliances`
        );
      } else if (yardTechList.includes(tech_name)) {
        this.router.navigateByUrl(
          `dashboard/market-place/energyEfficientDevices/yardCareAppliances`
        );
      } else {
        const details: MarketPlaceAnalyticsModel = {
          tech: tech_name,
          linkId: linkId,
        };
        this.dashboardAPI.marketplaceDetailsAnalytics(details);
        this.marketplaceService.openShopNowTab(link);
      }
    }
  }
}
