<div class="container w-100" (window:resize)="onResize()">
  <mat-button-toggle-group
    #group="matButtonToggleGroup"
    [value]="activeLink"
    (change)="toggle = true"
    class="nav_buttons"
  >
    <div
      class="prev-button"
      *ngIf="showScrollButton"
      (click)="handleScroll('prev')"
    >
      <mat-icon>chevron_left</mat-icon>
    </div>
    <div class="button-header">
      <mat-button-toggle
        value="aboutTechnology"
        *ngIf="isAboutTech"
        (click)="onClick(group)"
      >
        OVERVIEW
      </mat-button-toggle>

      <mat-button-toggle value="cost" *ngIf="isCost" (click)="onClick(group)">
        COST
      </mat-button-toggle>

      <mat-button-toggle
        value="savings"
        *ngIf="isSavings"
        (click)="onClick(group)"
      >
        SAVINGS
      </mat-button-toggle>

      <mat-button-toggle
        value="description"
        *ngIf="isDescription"
        (click)="onClick(group)"
      >
        DESCRIPTION
      </mat-button-toggle>

      <mat-button-toggle
        value="settings"
        *ngIf="isSettings"
        (click)="onClick(group)"
      >
        PERSONALIZE
      </mat-button-toggle>

      <mat-button-toggle
        value="assumptions"
        *ngIf="isAssumptions"
        (click)="onClick(group)"
      >
        ASSUMPTIONS
      </mat-button-toggle>

      <mat-button-toggle
        value="details"
        *ngIf="isDetails"
        (click)="onClick(group)"
      >
        TECHNICAL DETAILS
      </mat-button-toggle>

      <mat-button-toggle value="faq" *ngIf="isFaq" (click)="onClick(group)">
        FREQUENTLY ASKED QUESTIONS
      </mat-button-toggle>
    </div>

    <div
      class="next-button"
      *ngIf="showScrollButton"
      (click)="handleScroll('next')"
    >
      <mat-icon>chevron_right</mat-icon>
    </div>
  </mat-button-toggle-group>
</div>
