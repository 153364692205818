<div id="contact_popup" #contact_popup *ngIf="showPopup">
  <div id="popup_text">
    <div class="header pb_13">
      Contact us<mat-icon class="close_icon" (click)="onClosePopup()"
        >close</mat-icon
      >
    </div>
    <div class="sub_text">
      The YellowTin support team ensures that you receive the best assistance
      for any platform related queries.
    </div>
    <form [formGroup]="descriptionText">
      <textarea
        class="description_text"
        rows="10"
        placeholder="Draft your message here..."
        formControlName="userText"
      ></textarea>
      <div class="w-100 submit_btn">
        <button
          mat-raised-button
          color="accent"
          (click)="onSubmit()"
          [disabled]="descriptionText.invalid"
        >
          SEND YOUR QUESTION
        </button>
      </div>
    </form>
  </div>
</div>
