import { Component, Input } from "@angular/core";

@Component({
  selector: "info-card-transportation",
  templateUrl: "./transportation.component.html",
  styleUrls: ["../info-card.component.scss"],
})
export class InfoCardTransportationComponent {
  imagePath = "assets/lifestyle/clean-choices/details/";
  @Input() activeTech: string;
}
