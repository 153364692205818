<div class="faq_container">
  <div class="about_technology" *ngIf="showAboutTech">
    <div class="header">Overview</div>
    <p>
      <strong>Save Water and Energy:</strong> As water becomes a more precious
      commodity in many areas of the country, water conservation is taking on
      new importance. And when it’s hot water that you’re saving, you also get
      an energy-saving benefit.
      <span
        class="link_text"
        *ngIf="showReadMore"
        (click)="onReadContent('readMore')"
      >
        Read more...
      </span>
    </p>

    <div *ngIf="showReadLess">
      <p class="pt3">
        <strong>How it Helps Save Energy:</strong> Low-flow showerheads and
        faucet aerators can often save 20% - 30% of the water for your showers
        and your kitchen and bathroom faucets. This can lead to big reductions
        in your water heating costs.
      </p>

      <p class="pt3">
        <strong>Cost:</strong> Most low-flow showerheads are in the $30 - $90
        range. Faucet aerators that consume under 1 gallon/minute typically
        range from $2 - $10.
      </p>

      <p class="pt3">
        <strong>Easy Installation:</strong> In most cases, it’s a do-it-yourself
        project that simply involves unscrewing the old device and screwing in
        the new one.
        <span class="link_text" (click)="onReadContent('readLess')">
          Read less
        </span>
      </p>
    </div>
  </div>

  <div *ngIf="!showAboutTech">
    <div *ngIf="isActive(0)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. How do they work?</h5>
        <p class="answer_text">
          Low-flow aerators and showerheads reduce the flow of water through
          faucets and showers to a steady, comfortable level, saving both water
          and energy (in the form of hot water). In older homes a lot of water
          can be wasted through showers and faucets because the rate of flow is
          much higher than what is needed for normal use. In general, a
          showerhead doesn’t need a flow of more than 1.5 gallon/minute for a
          comfortable shower, and faucets don’t need a flow greater than 1
          gallon/minute for most purposes.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">2. What else can I do to save hot water?</h5>
        <p class="answer_text">
          Look for an ENERGY STAR certified dishwasher or clothes washer when
          you need to replace one of those appliances. These will help you save
          water and energy with each load. Also, you can become more mindful
          about how you use hot water. For example, taking shorter showers, and
          running full loads in your dishwasher and clothes washer can save a
          lot of energy. Finally, keep an eye out for leaks because even small
          ones can add up to surprisingly large amounts of waste over time. A
          leak of just one drip per second from a faucet results in almost six
          gallons of water going down the drain each day!
        </p>
      </div>
    </div>
    <div *ngIf="isActive(1)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. What will it cost to outfit my home with low-flow showerheads and
          aerators?
        </h5>
        <p class="answer_text">
          The cost will depend on the size of your home and the types of
          low-flow devices you buy, but in most cases it should be well under
          $200 if you do it yourself.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">2. How much can I save?</h5>
        <p class="answer_text">
          Your particular savings will depend on a number of factors, such as
          how you heat your water, how you use hot water, and what types of
          equipment you currently have. However, it’s likely that you can save
          $50 - $100 per year (sometimes even more) by the use of low-flow
          devices.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(2)">
      <div class="questionAnswer_box">
        <h5 class="questionText">
          1. How do low-flow showerheads and aerators help the environment?
        </h5>
        <p class="answer_text">
          Saving hot water provides a double environmental benefit. Water
          conservation is very beneficial by itself in much of the country, but
          cutting back on the energy consumed by your water heater provides
          another benefit no matter where you are.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(3)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. Where can I find low-flow devices?</h5>
        <p class="answer_text">
          Faucet aerators and low-flow showerheads are available in any home
          improvement store.  Look for devices that are labeled as being
          certified by the US Environmental Protection Agency’s “WaterSense”
          Program. This is similar to the ENERGY STAR labeling program for
          energy-consuming devices.
        </p>
      </div>
      <div class="questionAnswer_box">
        <h5 class="questionText">2. Do I need a plumber to install them?</h5>
        <p class="answer_text">
          Generally, no. In most cases, installation just involves unscrewing
          the old device and screwing in the new one, maybe with some plumbing
          tape. Most folks can do it themselves.
        </p>
      </div>
    </div>
    <div *ngIf="isActive(4)">
      <div class="questionAnswer_box">
        <h5 class="questionText">1. What incentives are available?</h5>
        <p class="answer_text">
          Low-flow devices are sometimes provided as part of free home water
          conservation kits offered by water districts, cities, and other
          entities.  EPA’s WaterSense program has a Rebate Finder on its website
          that might be helpful:
          <a
            class="link_text wrap_pre"
            target="_blank"
            href="https://lookforwatersense.epa.gov/rebates/"
            >https://lookforwatersense.epa.gov/rebates/</a
          >
        </p>
      </div>
    </div>
  </div>
</div>
